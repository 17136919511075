$max-mobile-width: 767px;
$responsive-mid: 860px;
$mid-break: 853px;
$mid-mobile-break: 630px;
$color-dark-navy: #0e122a;
$color-gray: #ccc;
$color-mid-gray: #999;
$rgba-white: rgba(255, 255, 255, 1);
$rgba-white-third: rgba(255, 255, 255, 0.3);
$rgba-white-half: rgba(255, 255, 255, 0.5);
$OptimaDisplayBook: 'OptimaDisplayBook';
$AkzidenzGroteskW1G: 'AkzidenzGrotesk W1G';
$AkzidenzGroteskBQBOL: 'AkzidenzGroteskBQBOL';

@keyframes pproFadeFromRight {
  0% {
    transform: translate3d(6%, 0, 0);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

@keyframes pproBlink {
  45% {
    opacity: 0.3;
  }
}

@keyframes pproFadedBlink {
  0% {
    opacity: 0.4;
  }
  45% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.4;
  }
}

@keyframes pproFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pproMoveToRight {
  0% {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(36%, 0, 0);
  }
}

@keyframes pproCloseToLeft {
  0% {
    transform: translate3d(36%, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}

@keyframes pproMoveToLeft {
  0% {
    transform: translate3d(72%, 0, 0);
  }
  to {
    transform: translate3d(36%, 0, 0);
  }
}

@keyframes pproCloseToRight {
  0% {
    transform: translate3d(36%, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(72%, 0, 0);
    opacity: 0;
  }
}

.sp19-perfectionist-formatter-v1 {
  .perfectionist_pro_mpp_header {
    @media screen and (max-width: $max-mobile-width) {
      padding-top: 0;
    }
    padding-top: 2.2%;
  }
  .perfectionist_pro_mpp_header .pp_mpp_header_h3 {
    @media screen and (max-width: $max-mobile-width) {
      font-size: 9.0625vw;
      letter-spacing: -0.018em;
    }
  }
  .perfectionist_pro_mpp_header .pp_mpp_header_copy {
    @media screen and (max-width: $max-mobile-width) {
      padding-top: 1vw;
      line-height: 1.15;
      font-size: 4.375vw;
    }
    padding-top: 1.5%;
  }
  .mpp {
    @media screen and (max-width: $max-mobile-width) {
      margin: 3vw 0 1.5vw 0;
    }
    padding: 5px 0 0 0;
    .mpp__header {
      display: none;
    }
  }
  .mpp__product-sort-container {
    display: none;
  }
  .scroll-to-section {
    border-bottom: 2px solid $color-dark-navy;
    display: inline-block;
    line-height: 1;
    cursor: pointer;
  }
  .olapic-widget {
    @media screen and (max-width: $max-mobile-width) {
      padding-top: 0;
    }
  }
}

.sp19-ppro {
  &__container {
    position: relative;
  }
  &__spp-carousel {
    li.product-brief {
      @media screen and (max-width: $max-mobile-width) {
        height: auto !important;
        float: none;
        width: 100%;
        padding-bottom: 0;
        list-style: none;
        .button__quickshop,
        .product-brief__button-quickshop--close {
          top: 6.2vw;
        }
        .product-brief__price,
        .product-brief__reviews,
        .product-brief__image,
        .product-brief__misc-flag,
        .product-brief__header,
        .product-brief__sub-header {
          display: none;
        }
      }
    }
    .mpp.mpp_qs_detached {
      @media screen and (max-width: $max-mobile-width) {
        padding-bottom: 0;
        .mpp__header,
        .mpp__product-sort-container {
          display: none;
        }
        .mpp__product-grid {
          background-color: $color-white;
          margin-top: 0;
        }
        .quickshop-inline-top {
          #{$ldirection}: 48.5%;
        }
        .quickshop-inline {
          margin-bottom: 0;
        }
      }
    }
    &-v1 {
      margin-bottom: 30px;
      @media screen and (max-width: $max-mobile-width) {
        margin-bottom: 0;
      }
      .el_boutique_carousel_slide {
        @media screen and (max-width: $max-mobile-width) {
          width: 100%;
        }
      }
    }
    &.hideModules {
      opacity: 0;
      padding-top: 5%;
    }
    .el_boutique_carouselWrapper {
      @media screen and (max-width: $max-width) {
        margin-bottom: 2.78388vw;
        @media screen and (max-width: $max-mobile-width) {
          margin-bottom: 0;
        }
      }
      margin-#{$ldirection}: 0;
      margin-bottom: 38px;
    }
    .el_boutique_carousel_pagination {
      bottom: 0;
      @media screen and (max-width: $max-mobile-width) {
        display: none;
      }
    }
    .el_boutique_carouselnav {
      @media screen and (max-width: $max-mobile-width) {
        display: none;
      }
    }
    .el_boutique_carouselnav.el_boutique_carouselnav_1 {
      #{$ldirection}: 45.4%;
    }
    .sp19-ppro__mod2 {
      &-mob-control {
        display: none;
        @media screen and (max-width: $max-mobile-width) {
          position: absolute;
          width: 6.40625vw;
          #{$rdirection}: 5.1vw;
          vertical-align: middle;
          height: 100%;
        }
      }
      &-mob-control--1 {
        @media screen and (max-width: $max-mobile-width) {
          display: block;
        }
      }
      &-mob-placeholder {
        @media screen and (max-width: $max-mobile-width) {
          display: block;
          font-family: $OptimaDisplayBook;
          text-align: #{$ldirection};
          letter-spacing: -0.035em;
          padding: 0 7.5vw;
          font-size: 5vw;
          height: 100%;
          p {
            line-height: 14.9vw;
            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ p {
              line-height: 5.1vw;
              position: relative;
              transform: translateY(48%);
            }
          }
        }
        display: none;
      }
      &-wrap {
        @media screen and (max-width: $max-mobile-width) {
          padding-bottom: 11.9%;
        }
      }
    }
  }
  &__mod1 {
    @media screen and (max-width: $max-mobile-width) {
      margin-top: 0;
      padding-bottom: 3.6vw;
    }
    margin-top: 4px;
    .boutique_vid_play_cta,
    .boutique_vid_play_img {
      cursor: pointer;
      position: absolute;
      z-index: 3;
      &:hover {
        opacity: 0.7;
      }
    }
    .boutique_vid_play_cta {
      @media screen and (max-width: $max-mobile-width) {
        font-size: 3.4375vw;
        bottom: 65.7vw;
      }
      bottom: 15.6%;
      color: $color-navy;
      border-bottom: 1.8px solid $color-navy;
      #{$ldirection}: 2.8%;
    }
    .boutique_vid_play_img {
      @media screen and (max-width: $max-width) {
        width: 90px;
        @media screen and (max-width: $max-mobile-width) {
          width: 21.563vw;
          bottom: 70.6vw;
          #{$ldirection}: 5.5vw;
        }
      }
      bottom: 19.1%;
      width: 6.662%;
      #{$ldirection}: 2.9%;
    }
    &-copy {
      @media screen and (max-width: $max-mobile-width) {
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        top: 96.5vw;
      }
      @include swap_direction(#{$ldirection}, 43.4%);
      position: absolute;
      top: 9.2%;
      #{$ldirection}: 47.6%;
      z-index: 1;
      &--1 {
        @media screen and (max-width: $max-mobile-width) {
          font-family: $OptimaDisplayBook;
          font-size: 5.9375vw;
          line-height: 1.16;
          letter-spacing: -0.06em;
        }
        letter-spacing: -0.03em;
        line-height: 0.97;
      }
      &--2 {
        @media screen and (max-width: $max-mobile-width) {
          font-family: $OptimaDisplayBook;
          font-size: 4.6875vw;
          letter-spacing: -0.056em;
          padding-top: 4vw;
        }
        padding-top: 5.5%;
      }
      &--3 {
        @media screen and (max-width: $max-mobile-width) {
          font-size: 9.375vw;
          padding-top: 1.3%;
          line-height: 1;
        }
        padding-top: 0.3%;
      }
      &--4 {
        @media screen and (max-width: $max-mobile-width) {
          padding: 1.3% 0 0 0;
          font-size: 4.375vw;
          line-height: 1.17;
          letter-spacing: 0.05em;
        }
        padding: 5.3% 0 0 0.5%;
        white-space: nowrap;
      }
    }
    &-qs {
      .quick_shop {
        @media screen and (max-width: $max-width) {
          font-size: 0.87912vw;
        }
        opacity: 0;
        position: absolute;
        filter: drop-shadow(0 0 8px $color-white);
        z-index: 2;
        box-shadow: 0 0 14px 6px $rgba-white-half;
        background-color: $rgba-white-half;
        outline: none;
        body.isFF & {
          box-shadow: 0 0 14px 12px $rgba-white-half;
        }
        body.isSafari & {
          box-shadow: 0 0 10px 11px $rgba-white-half;
        }
        &:nth-of-type(1) {
          #{$rdirection}: 18.5%;
          bottom: 36.6%;
        }
        &:nth-of-type(2) {
          #{$rdirection}: 9.15%;
          bottom: 24.8%;
        }
        &.show_elem {
          opacity: 1;
        }
      }
    }
    .sp19-ppro__svg-wrapper {
      @media screen and (max-width: $max-mobile-width) {
        display: none;
      }
      position: absolute;
      z-index: 1;
      width: 100%;
      body.ie & {
        height: 94.772%;
      }
      svg {
        polygon {
          cursor: pointer;
          fill: transparent;
          opacity: 0;
        }
      }
    }
    .sp19-ppro__prod {
      position: absolute;
      opacity: 0;
      &.show_elem {
        opacity: 1;
      }
    }
  }
  &__mod2 {
    @media screen and (max-width: $max-width) {
      height: 49.74354vw;
      @media screen and (max-width: $max-mobile-width) {
        height: unset;
        margin-bottom: 0;
      }
    }
    height: 679px;
    overflow: hidden;
    margin-bottom: 5.3%;
    &.hide-hover {
      pointer-events: none;
    }
    &-bg {
      @media screen and (max-width: $max-mobile-width) {
        height: 82.5vw;
      }
    }
    &-carouselcopy {
      color: $color-dark-navy;
      @media screen and (max-width: $max-mobile-width) {
        padding-bottom: 23.5vw;
        &.removePadding {
          padding-bottom: 0;
        }
      }
    }
    &-copylanding {
      @media screen and (max-width: $max-mobile-width) {
        padding-top: 3.9%;
      }
      position: absolute;
      width: 100%;
      text-align: center;
      padding-top: 2.2%;
      h2 {
        @media screen and (max-width: $max-mobile-width) {
          font-size: 9.375vw;
        }
        letter-spacing: -0.03em;
      }
      .el_boutique_body {
        @media screen and (max-width: $max-mobile-width) {
          font-size: 4.375vw;
          padding-top: 1%;
        }
        padding-top: 0.5%;
      }
    }
    &-prod {
      @media screen and (max-width: $max-mobile-width) {
        width: 18.907%;
        opacity: 1;
        position: relative;
        display: inline-block;
        margin-top: 0;
      }
      width: 15.374%;
      position: absolute;
      opacity: 0;
      margin-top: 5%;
      &.fadeElem {
        opacity: 0.6;
      }
      &.fadeIn {
        opacity: 1;
        margin-top: 0;
        &.fadeElem {
          opacity: 0.6;
        }
      }
    }
    &-prod:nth-of-type(2) {
      @media screen and (max-width: $max-mobile-width) {
        width: 18.75%;
        margin-#{$ldirection}: -0.7vw;
      }
      margin-#{$ldirection}: 19.1%;
    }
    &-prod:nth-of-type(3) {
      @media screen and (max-width: $max-mobile-width) {
        width: 22.188%;
        margin-#{$ldirection}: -0.8vw;
      }
      @media screen and (max-width: $mid-mobile-break) {
        margin-#{$ldirection}: -1.5vw;
      }
      margin-#{$ldirection}: 38.39%;
    }
    &-prod:nth-of-type(4) {
      @media screen and (max-width: $max-mobile-width) {
        width: 22.344%;
        margin-#{$ldirection}: -0.8vw;
      }
      @media screen and (max-width: $mid-mobile-break) {
        margin-#{$ldirection}: -2vw;
      }
      margin-#{$ldirection}: 58.74%;
    }
    &-prod:nth-of-type(5) {
      @media screen and (max-width: $max-mobile-width) {
        width: 17.813%;
        margin-#{$ldirection}: -0.8vw;
      }
      @media screen and (max-width: $mid-mobile-break) {
        margin-#{$ldirection}: -2vw;
      }
      margin-#{$ldirection}: 75.86%;
    }
    &-expandcopy {
      @media screen and (max-width: $max-mobile-width) {
        display: none;
        position: relative;
        width: 100%;
        margin: 0 auto;
        height: 71vw;
        background-color: $color-white;
        .node-spp-related-content-item-set & {
          margin-bottom: -20vw;
        }
        & ~ .mpp {
          margin: 0;
          padding: 0;
          .mpp__header,
          .mpp__product-sort-container {
            display: none;
          }
          .quickshop-inline-top {
            #{$ldirection}: 48.5%;
          }
          .quickshop-inline {
            margin-bottom: 5vw;
          }
        }
        .sp19-ppro__mod2- {
          &copyexpand {
            &:nth-of-type(2) {
              .sp19-ppro__mod2 {
                &-header {
                  font-size: 8.75vw;
                  line-height: 1.06;
                  padding-top: 1.3vw;
                }
                &-headerinfo {
                  padding-top: 2vw;
                }
              }
            }
            &:nth-of-type(3) {
              .sp19-ppro__mod2 {
                &-header {
                  font-size: 8.75vw;
                  line-height: 1.06;
                }
              }
            }
            &:nth-of-type(5) {
              .sp19-ppro__mod2 {
                &-copy-1 {
                  padding-top: 3.2vw;
                }
              }
            }
            .shop_now_centered {
              top: 6.1vw;
            }
            &.showElem {
              opacity: 1;
              visibility: visible;
            }
            position: absolute;
            width: 100%;
            text-align: center;
            opacity: 0;
            visibility: hidden;
            .sp19-ppro__mod2 {
              &-eyebrow {
                padding-top: 3.7vw;
              }
              &-eyebrow,
              &-subhead {
                font-family: $OptimaDisplayBook;
                font-size: 4.6875vw;
                &:empty {
                  display: none;
                }
              }
              &-subhead {
                padding-top: 4.9vw;
              }
              &-header {
                font-size: 9.375vw;
                padding-top: 1.6vw;
                &:empty {
                  display: none;
                }
              }
              &-headerinfo {
                padding-top: 1.5vw;
                line-height: 1.15;
              }
              &-readmore,
              &-close {
                position: relative;
                text-align: right;
                text-decoration: underline;
                margin-right: 5vw;
                padding-top: 3.5vw;
                font-size: 2.5vw;
                span {
                  cursor: pointer;
                }
              }
              &-readmore {
                &.hide_elem {
                  display: none;
                }
              }
              &-close,
              &-furthercontents {
                display: none;
                &.show_elem {
                  display: block;
                }
              }
              &-headerinfo,
              &-copy {
                font-size: 4.375vw;
                &:empty {
                  display: none;
                }
              }
              &-copy {
                line-height: 1.15;
                padding-top: 0.5vw;
              }
              &-caveat {
                color: $color-dark-navy;
                font-size: 3.90625vw;
                padding-top: 5.1vw;
                letter-spacing: 0.05em;
                &:empty {
                  display: none;
                }
              }
            }
            li.product-brief {
              height: auto !important;
              float: none;
              width: 100%;
              padding-bottom: 0;
              list-style: none;
              a:nth-of-type(1),
              .product-brief__price,
              .product-brief__reviews {
                display: none;
              }
            }
          }
        }
      }
    }
    &-prodcopy {
      @include swap_direction(direction, ltr);
      position: absolute;
      z-index: 2;
      width: 100%;
      margin-top: 42.7%;
      #{$ldirection}: 9%;
      .sp19-ppro__mod2-copyhov {
        @media screen and (max-width: $max-width) {
          height: 3.663vw;
          line-height: 4.6;
          font-size: 0.87912vw;
        }
        @include swap_direction(direction, rtl);
        display: inline-block;
        opacity: 0;
        height: 36px;
        font-size: 12px;
        line-height: 4.2;
        border-bottom: none;
        &:nth-of-type(2) {
          margin-#{$ldirection}: 11.05%;
        }
        &:nth-of-type(3) {
          margin-#{$ldirection}: 11.6%;
        }
        &:nth-of-type(4) {
          margin-#{$ldirection}: 12.75%;
        }
        &:nth-of-type(5) {
          margin-#{$ldirection}: 9.5%;
        }
        &.showElem {
          opacity: 1;
        }
        span {
          border-bottom: 1.8px solid $color-white;
        }
      }
    }
    &-prodwrapper,
    &-prodhovs {
      @media screen and (max-width: $max-mobile-width) {
        margin-top: 23.7vw;
        #{$ldirection}: 0;
      }
      @include swap_direction(direction, ltr);
      position: absolute;
      width: 100%;
      margin-top: 9.65%;
      #{$ldirection}: 4.56%;
    }
    &-prodhovs {
      img {
        opacity: 0;
        margin-top: 0;
        &.showElem {
          opacity: 1;
          z-index: 1;
        }
      }
    }
    .sp19-ppro {
      &__svg-wrapper {
        position: absolute;
        width: 100%;
        z-index: 2;
        svg {
          body.ie & {
            @media screen and (max-width: $max-width) {
              height: 49.67028vw;
            }
            height: 678px;
          }
        }
        polygon {
          fill: transparent;
          cursor: pointer;
          opacity: 0;
        }
      }
    }
    &-carousel {
      position: absolute;
      width: 100%;
      display: none;
      .sp19-ppro__spp-carousel & {
        @media screen and (max-width: $max-mobile-width) {
          position: relative;
          text-align: center;
        }
        display: block;
        top: 0;
        #{$ldirection}: 0;
        text-align: #{$ldirection};
      }
      .sp19-ppro__mod2-carousel {
        &img {
          position: absolute;
          margin-top: 9.65%;
          margin-#{$ldirection}: 9.65%;
          width: 15.374%;
          opacity: 0;
          html[dir='rtl'] & {
            margin-#{$ldirection}: 0;
            margin-#{$rdirection}: 75.8%;
          }
          &.moveToRight {
            opacity: 1;
            animation-name: pproMoveToRight;
            animation-fill-mode: forwards;
            animation-duration: 0.7s;
          }
          &.moveToLeft {
            opacity: 1;
            animation-name: pproMoveToLeft;
            animation-fill-mode: forwards;
            animation-duration: 0.7s;
          }
          &.closeToLeft {
            animation-name: pproCloseToLeft;
            animation-fill-mode: forwards;
            animation-duration: 0.7s;
          }
          &.closeToRight {
            animation-name: pproCloseToRight;
            animation-fill-mode: forwards;
            animation-duration: 0.7s;
          }
          &:nth-of-type(2) {
            margin-top: 9.9%;
          }
          &:nth-of-type(3) {
            margin-top: 8.6%;
            margin-#{$ldirection}: 8.6%;
          }
          &:nth-of-type(4) {
            margin-top: 8.85%;
            margin-#{$ldirection}: 9.3%;
          }
        }
        &content {
          @media screen and (max-width: $max-width) {
            height: 49.74354vw;
          }
          position: absolute;
          width: 54.613%;
          height: 679px;
          #{$rdirection}: 0;
          top: 0;
          .sp19-ppro__spp-carousel & {
            @media screen and (max-width: $max-mobile-width) {
              height: unset;
              width: 100%;
              position: relative;
            }
          }
          .el_boutique_carouselnav {
            position: absolute;
            z-index: 2;
            width: 7%;
            top: 0;
            cursor: pointer;
            text-align: center;
            height: 100%;
            display: none;
            img {
              margin: auto;
              height: 100%;
              width: 44%;
            }
          }
          .el_boutique_carouselnav_1 {
            #{$ldirection}: 0;
          }
          .el_boutique_carouselnav_2 {
            #{$rdirection}: 0;
          }
        }
        &close {
          position: relative;
          z-index: 3;
          display: none;
          img {
            position: absolute;
            width: 7.105%;
            margin-top: 0.7%;
            #{$rdirection}: 3.9%;
            z-index: 3;
            cursor: pointer;
          }
        }
        &info {
          position: absolute;
          z-index: 1;
          #{$ldirection}: 15.7%;
          width: 84.3%;
          display: none;
          html[dir='rtl'] & {
            #{$ldirection}: -6.6%;
          }
          .sp19-ppro__spp-carousel & {
            @media screen and (max-width: $responsive-mid) {
              #{$ldirection}: 13.7%;
              @media screen and (max-width: $max-mobile-width) {
                position: relative;
                #{$ldirection}: 0;
                width: 100%;
                height: 14.84375vw;
                overflow: hidden;
                border-bottom: 1px solid $color-gray;
                cursor: pointer;
              }
            }
            display: block;
            &.expandSection {
              @media screen and (max-width: $max-mobile-width) {
                height: unset;
                .sp19-ppro__mod2 {
                  &-mob-control--1,
                  &-mob-placeholder {
                    display: none;
                  }
                  &-mob-control--2 {
                    display: block;
                    bottom: 3.4vw;
                    height: unset;
                  }
                }
              }
            }
          }
          &:nth-of-type(1) {
            top: 11.6%;
            .sp19-ppro__spp-carousel--uvfluid & {
              @media screen and (max-width: $responsive-mid) {
                top: 4.6%;
              }
            }
            .sp19-ppro__spp-carousel--peel & {
              @media screen and (max-width: $responsive-mid) {
                top: 3.6%;
              }
              .sp19-ppro__mod2-header {
                @media screen and (max-width: $max-mobile-width) {
                  font-size: 8.75vw;
                }
              }
              .sp19-ppro__mod2-headerinfo {
                @media screen and (max-width: $max-mobile-width) {
                  padding-top: 2.6vw;
                }
              }
              .sp19-ppro__mod2-caveat {
                @media screen and (max-width: $max-mobile-width) {
                  padding-top: 5.1vw;
                }
              }
            }
          }
          &:nth-of-type(2) {
            top: 9.3%;
            .sp19-ppro__spp-carousel--retinol & {
              top: 32.6%;
              .sp19-ppro__mod2-headerinfo {
                @media screen and (max-width: $max-mobile-width) {
                  line-height: 115%;
                }
                padding-top: 3%;
                line-height: 145%;
                padding-bottom: 0.7%;
              }
            }
            .sp19-ppro__spp-carousel--uvfluid & {
              top: 12.3%;
              .sp19-ppro__mod2-headerinfo {
                @media screen and (max-width: $max-mobile-width) {
                  padding-top: 3vw;
                }
              }
            }
            .sp19-ppro__spp-carousel--firmlift & {
              top: 17.8%;
              .sp19-ppro__mod2-headerinfo {
                padding-top: 3%;
              }
            }
            .sp19-ppro__spp-carousel--peel & {
              top: 15%;
              .sp19-ppro__mod2-headerinfo {
                padding-top: 3%;
              }
            }
          }
          &:nth-of-type(3) {
            top: 7.2%;
            .sp19-ppro__spp-carousel--retinol & {
              top: 5.2%;
              @media screen and (max-width: $responsive-mid) {
                top: 0;
              }
              .sp19-ppro__mod2-header {
                @media screen and (max-width: $max-mobile-width) {
                  padding-top: 5.3vw;
                  margin-bottom: -0.4vw;
                }
              }
              .sp19-ppro__mod2-headerinfo {
                @media screen and (max-width: $responsive-mid) {
                  line-height: 133%;
                  @media screen and (max-width: $max-mobile-width) {
                    line-height: 115%;
                  }
                }
                padding-top: 3%;
              }
            }
            .sp19-ppro__spp-carousel--uvfluid & {
              top: 12.25%;
              .sp19-ppro__mod2-header {
                @media screen and (max-width: $max-mobile-width) {
                  padding-top: 5.3vw;
                }
              }
              .sp19-ppro__mod2-headerinfo {
                @media screen and (max-width: $max-mobile-width) {
                  padding-top: 3.1vw;
                  line-height: 115%;
                }
                padding-top: 3.2%;
                line-height: 139%;
              }
              .sp19-ppro__mod2-subhead-1 {
                @media screen and (max-width: $max-mobile-width) {
                  padding: 2.7% 0 0.4% 0;
                }
                padding: 3.5% 0 0.4% 0;
              }
            }
            .sp19-ppro__spp-carousel--firmlift & {
              top: 21.4%;
              .sp19-ppro__mod2-header {
                @media screen and (max-width: $max-mobile-width) {
                  padding-top: 5.3vw;
                }
              }
              .sp19-ppro__mod2-headerinfo {
                padding-top: 2%;
              }
            }
          }
          &:nth-of-type(4) {
            top: 17.5%;
          }
          &:nth-of-type(5) {
            top: 20.6%;
            .sp19-ppro__mod2-header {
              padding-top: 1.5%;
              letter-spacing: 0.025em;
            }
            .sp19-ppro__mod2-headerinfo {
              padding-top: 1%;
              line-height: 1.25;
            }
            .sp19-ppro__mod2-qs {
              margin: 4.7% 0 2.1% 0.3%;
            }
          }
          &.fadeFromRight {
            animation-name: pproFadeFromRight;
            animation-fill-mode: forwards;
            animation-duration: 0.7s;
          }
          &.hideContent {
            opacity: 0;
            visibility: hidden;
            .sp19-ppro__spp-carousel & {
              @media screen and (max-width: $max-mobile-width) {
                opacity: 1;
                visibility: visible;
              }
            }
          }
          .sp19-ppro__mod2 {
            &-eyebrow {
              @media screen and (max-width: $max-mobile-width) {
                font-family: $OptimaDisplayBook;
                font-size: 4.6875vw;
                padding-top: 3.7vw;
              }
            }
            &-copy,
            &-headerinfo {
              @media screen and (max-width: $max-mobile-width) {
                font-size: 4.375vw;
                line-height: 1.15;
              }
              line-height: 1.35;
              &:empty {
                display: none;
              }
            }
            &-header {
              @media screen and (max-width: $max-mobile-width) {
                font-size: 9.375vw;
                padding-top: 1.8vw;
              }
              padding-top: 1%;
              &:empty {
                display: none;
              }
            }
            &-headerinfo {
              @media screen and (max-width: $max-mobile-width) {
                font-size: 4.375vw;
                padding-top: 1.5vw;
                line-height: 1.15;
                padding-bottom: 2.5vw;
              }
              padding-top: 1.4%;
            }
            &-subhead {
              @media screen and (max-width: $max-mobile-width) {
                font-size: 4.6875vw;
                font-family: $OptimaDisplayBook;
                letter-spacing: -0.045em;
              }
            }
            &-subhead-1 {
              padding: 2.4% 0 0.4% 0;
              &:empty {
                display: none;
              }
            }
            &-subhead-2 {
              @media screen and (max-width: $max-mobile-width) {
                padding: 4.9% 0 0.4% 0;
              }
              padding: 3.4% 0 0.4% 0;
              &:empty {
                display: none;
              }
            }
            &-subhead-3 {
              @media screen and (max-width: $max-mobile-width) {
                padding: 4.85% 0 0.5% 0;
              }
              padding: 3.5% 0 0.3% 0;
              &:empty {
                display: none;
              }
            }
            &-qs {
              @media screen and (max-width: $max-width) {
                height: 3.00366vw;
                line-height: 3.6;
                @media screen and (max-width: 1098px) {
                  height: 33px;
                  line-height: 2.85;
                }
              }
              height: 41px;
              line-height: 3.5;
              padding: 0 5.1%;
              display: inline-block;
              margin: 4% 0 2.1% 0.3%;
              &:empty {
                display: none;
              }
              .sp19-ppro__spp-carousel & {
                @media screen and (max-width: $max-mobile-width) {
                  display: none;
                }
              }
            }
            &-caveat {
              @media screen and (max-width: $max-mobile-width) {
                color: $color-navy;
                font-size: 3.90625vw;
                letter-spacing: 0.05em;
              }
              color: $color-mid-gray;
              opacity: 1;
              letter-spacing: 0em;
              margin-#{$ldirection}: 0.5%;
              &:empty {
                display: none;
              }
            }
          }
        }
        &bg {
          @media screen and (max-width: $max-width) {
            height: 49.74354vw;
          }
          position: absolute;
          background-color: $color-white;
          width: 0;
          height: 679px;
          #{$rdirection}: 0;
          overflow: hidden;
        }
      }
    }
    .sp19-ppro__mod2-carouselpag {
      @media screen and (max-width: 1080px) {
        bottom: 3%;
        @media screen and (max-width: $max-mobile-width) {
          bottom: 0;
          position: relative;
          padding-top: 0.75vw;
          display: block;
        }
      }
      position: absolute;
      bottom: 4.7%;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      z-index: 2;
      display: none;
      &-dot {
        @media screen and (max-width: $max-mobile-width) {
          width: 3.125vw;
          height: 3.125vw;
          margin: 0 1.22vw;
        }
        display: inline-block;
        zoom: 1;
        width: 9px;
        height: 9px;
        cursor: pointer;
        margin: 0 3px 0 3px;
        border: 1px solid $color-navy;
        border-radius: 50%;
        &.pagDotSelected {
          background-color: $color-navy;
        }
      }
    }
  }
  &__mod3 {
    @media screen and (max-width: $max-width) {
      height: 68.93766vw;
      @media screen and (max-width: $max-mobile-width) {
        height: unset;
        margin-bottom: 6.5625vw;
        margin-top: 5vw;
      }
    }
    height: 941px;
    overflow: hidden;
    .sp19-ppro {
      &__ellipses {
        position: absolute;
        z-index: 1;
        width: 100%;
        svg {
          @media screen and (max-width: $max-mobile-width) {
            @include swap_direction(margin-#{$rdirection}, -32.3vw);
            height: 112.65625vw;
            margin-#{$ldirection}: -31.2vw;
            margin-top: -1.1vw;
          }
          body.ie & {
            @media screen and (max-width: $max-width) {
              height: 68.93766vw;
            }
            height: 941px;
          }
          circle.sp19-ppro__ellipse {
            fill: transparent;
            stroke: $color-white;
            stroke-width: 1px;
            &.sp19-ppro__ellipseinfo {
              z-index: 2;
              cursor: pointer;
              fill: $rgba-white-third;
              &.active {
                fill: $rgba-white;
              }
            }
          }
        }
        &copy {
          &-header {
            @media screen and (max-width: $max-mobile-width) {
              margin-top: 45.2vw;
            }
            position: absolute;
            width: 100%;
            text-align: center;
            margin-top: 31.2%;
            .el_boutique_h2_lg {
              @media screen and (max-width: $max-mobile-width) {
                font-size: 9.375vw;
              }
            }
            .el_boutique_body {
              @media screen and (max-width: $max-mobile-width) {
                padding-top: 1.5vw;
                font-size: 4.375vw;
              }
              padding-top: 0.75%;
            }
            &.hide_elem {
              opacity: 0;
            }
          }
          &-initial {
            .sp19-ppro {
              &__initialcopy {
                @media screen and (max-width: $max-width) {
                  font-size: 1.17216vw;
                  @media screen and (max-width: $max-mobile-width) {
                    font-size: 2.5vw;
                  }
                }
                position: absolute;
                font-size: 16px;
                opacity: 0.6;
                letter-spacing: 0.12em;
                text-align: center;
                line-height: 1;
                z-index: 2;
                cursor: pointer;
                color: $color-dark-navy;
                &:nth-of-type(1) {
                  margin: 22.35% 0 0 24.7%;
                  html[dir='rtl'] & {
                    margin: 22.35% 69.75% 0 0;
                  }
                  @media screen and (max-width: $max-mobile-width) {
                    margin: 35.6% 0 0 8.65%;
                    html[dir='rtl'] & {
                      margin: 35.6% 80.9% 0 0;
                    }
                  }
                }
                &:nth-of-type(2) {
                  margin: 35.3% 0 0 22.5%;
                  html[dir='rtl'] & {
                    margin: 35.3% 72.4% 0 0;
                  }
                  @media screen and (max-width: $max-mobile-width) {
                    margin: 56.2% 0 0 3.7%;
                    html[dir='rtl'] & {
                      margin: 56.2% 83.65% 0 0;
                    }
                  }
                }
                &:nth-of-type(3) {
                  margin: 48.4% 0 0 26.35%;
                  html[dir='rtl'] & {
                    margin: 48.4% 66.95% 0 0;
                  }
                  @media screen and (max-width: $max-mobile-width) {
                    margin: 78% 0 0 10.4%;
                    html[dir='rtl'] & {
                      margin: 78% 75.4% 0 0;
                    }
                  }
                }
                &:nth-of-type(4) {
                  margin: 56.4% 0 0 40.15%;
                  html[dir='rtl'] & {
                    margin: 56.4% 55.64% 0 0;
                  }
                  @media screen and (max-width: $max-mobile-width) {
                    margin: 91.3% 0 0 33.6%;
                    html[dir='rtl'] & {
                      margin: 91.3% 57.4% 0 0;
                    }
                  }
                }
                &:nth-of-type(5) {
                  margin: 56.1% 0 0 56.45%;
                  html[dir='rtl'] & {
                    margin: 56.1% 37.64% 0 0;
                  }
                  @media screen and (max-width: $max-mobile-width) {
                    margin: 90.2% 0 0 59.2%;
                    html[dir='rtl'] & {
                      margin: 90.2% 27.8% 0 0;
                    }
                  }
                }
                &:nth-of-type(6) {
                  margin: 44.3% 0 0 67.1%;
                  html[dir='rtl'] & {
                    margin: 44.3% 22.4% 0 0;
                  }
                  @media screen and (max-width: $max-mobile-width) {
                    margin: 69.8% 0 0 80%;
                    html[dir='rtl'] & {
                      margin: 69.8% 5.4% 0 0;
                    }
                  }
                }
                &.show_elem {
                  opacity: 1;
                }
              }
            }
          }
          &-reveal {
            .sp19-ppro {
              &__revealcopy {
                @media screen and (max-width: $max-width) {
                  font-size: 1.17216vw;
                  @media screen and (max-width: $mid-break) {
                    font-size: 10px;
                    @media screen and (max-width: $max-mobile-width) {
                      font-size: 7.03125vw;
                      font-family: $optimalight;
                      letter-spacing: -0.01em;
                      width: 100%;
                    }
                  }
                }
                font-size: 16px;
                position: absolute;
                text-align: center;
                line-height: 1.5;
                width: 30.5%;
                opacity: 0;
                margin: 0 auto;
                left: 0;
                right: 0;
                &:nth-of-type(1) {
                  @media screen and (max-width: $max-mobile-width) {
                    margin-top: 49%;
                  }
                  margin-top: 29.65%;
                }
                &:nth-of-type(2) {
                  @media screen and (max-width: $max-mobile-width) {
                    margin-top: 49%;
                  }
                  margin-top: 25.85%;
                }
                &:nth-of-type(3) {
                  @media screen and (max-width: $max-mobile-width) {
                    margin-top: 49%;
                  }
                  margin-top: 25.2%;
                }
                &:nth-of-type(4) {
                  @media screen and (max-width: $max-mobile-width) {
                    margin-top: 49%;
                  }
                  margin-top: 26.1%;
                }
                &:nth-of-type(5) {
                  @media screen and (max-width: $max-mobile-width) {
                    margin-top: 49%;
                  }
                  margin-top: 26%;
                }
                &:nth-of-type(6) {
                  @media screen and (max-width: $max-mobile-width) {
                    margin-top: 49%;
                  }
                  margin-top: 24.9%;
                }
                &.show_elem {
                  opacity: 1;
                  z-index: 2;
                }
                &-inner {
                  a.quick_shop {
                    position: relative;
                    z-index: 3;
                    cursor: pointer;
                    color: $color-white;
                    border-bottom: 1px solid $color-white;
                  }
                }
              }
            }
          }
          &-expand {
            @media screen and (max-width: $max-mobile-width) {
              display: none;
              position: relative;
              width: 100%;
              margin: 0 auto;
              height: 72.5vw;
              background-color: $color-white;
              .sp19-ppro__revealcopy {
                font-size: 4.6875vw;
                margin: 0 auto;
                position: absolute;
                text-align: center;
                line-height: 1.09;
                width: 100%;
                opacity: 0;
                margin-top: 5.8vw;
                letter-spacing: 0.015em;
                &-inner {
                  a {
                    position: relative;
                    z-index: 3;
                    cursor: pointer;
                    color: $color-navy;
                    text-decoration: underline;
                  }
                }
                &.show_elem {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      &_skincare-ellipseglow,
      &_skincare-ellipseactive {
        position: absolute;
      }
      &_skincare-ellipseactive {
        &.blinking--fade {
          -moz-animation: pproFadedBlink 1.5s infinite alternate;
          -o-animation: pproFadedBlink 1.5s infinite alternate;
          animation: pproFadedBlink 1.5s infinite alternate;
        }
        &.fade-in {
          animation-name: pproFadeIn, pproBlink;
          animation-delay: 0s, 0.8s;
          animation-duration: 0.8s, 1.5s;
          animation-timing-function: ease-in, ease-in;
          animation-iteration-count: 1, infinite;
          animation-direction: normal, alternate;
        }
      }
      &_skincare-ellipseglow {
        &.blinking--full {
          -moz-animation: pproBlink 1.5s infinite alternate;
          -o-animation: pproBlink 1.5s infinite alternate;
          animation: pproBlink 1.5s infinite alternate;
        }
      }
    }
    &-bg {
      height: 112.65625vw;
    }
  }
  &__mod4 {
    &-copy {
      @media screen and (max-width: $max-mobile-width) {
        top: 70.2vw;
        width: 100%;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        text-align: center;
      }
      position: absolute;
      top: 6.2%;
      #{$ldirection}: 6.4%;
      z-index: 1;
      .el_boutique_h3 {
        @media screen and (max-width: $max-mobile-width) {
          font-size: 4.6875vw;
          font-family: $OptimaDisplayBook;
          letter-spacing: -0.055em;
          padding-bottom: 0.7vw;
        }
        letter-spacing: -0.01em;
      }
      .el_boutique_h2_lg {
        @media screen and (max-width: $max-mobile-width) {
          font-size: 9.375vw;
          line-height: 1;
          padding-top: 0;
        }
        padding-top: 2.6%;
        letter-spacing: -0.034em;
        margin-bottom: -0.3%;
      }
      .sp19-ppro__service-block:nth-of-type(2) {
        @media screen and (max-width: $max-mobile-width) {
          font-size: 4.6875vw;
          font-family: $OptimaDisplayBook;
          letter-spacing: -0.07em;
          padding: 1.4vw 0 1.8vw 0;
        }
      }
      .sp19-ppro__service-block:nth-of-type(3) {
        @media screen and (max-width: $max-mobile-width) {
          font-family: $AkzidenzGroteskW1G;
          font-size: 4.6875vw;
          padding-top: 0;
          line-height: 1.08;
          letter-spacing: 0.035em;
        }
        padding-top: 2%;
        letter-spacing: -0.012em;
        line-height: 1.2;
      }
      .el_boutique_caveat {
        @media screen and (max-width: 1050px) {
          margin: 2% 0 0 0.5%;
          @media screen and (max-width: $max-mobile-width) {
            margin: 4.4vw 0 0 0;
          }
        }
        color: $color-navy;
        margin: 5.1% 0 0 0.5%;
        letter-spacing: 0em;
      }
      .sp19-ppro {
        &__storebtn,
        &__dlbtn {
          @media screen and (max-width: 1050px) {
            height: 33px;
            line-height: 2.8;
            @media screen and (max-width: $max-mobile-width) {
              padding: 0 0;
              width: 84%;
              margin: 0 auto;
              #{$ldirection}: 0;
              #{$rdirection}: 0;
              height: 14.063vw;
              background: $color-navy;
              font-family: $akzidenz;
              font-weight: bold;
              font-size: 3.75vw;
              line-height: 3.75;
              letter-spacing: 0.12em;
              color: $color-white;
            }
          }
          display: block;
          height: 41px;
          line-height: 3.4;
          border: 1px solid $color-white;
        }
        &__storebtn {
          @media screen and (max-width: 1050px) {
            margin-top: 1%;
            @media screen and (max-width: $max-mobile-width) {
              margin-top: 5.7vw;
              max-width: unset;
            }
          }
          max-width: 161px;
          margin-top: 3.9%;
        }
        &__dlbtn {
          @media screen and (max-width: 1050px) {
            margin-top: 1%;
            @media screen and (max-width: $max-mobile-width) {
              margin-top: 3.1vw;
              max-width: unset;
            }
          }
          max-width: 254px;
          margin-top: 3.3%;
          body.ie & {
            max-width: 260px;
          }
          img {
            @media screen and (max-width: $max-mobile-width) {
              padding-#{$rdirection}: 4.2%;
              margin-top: -2%;
              width: 10vw;
              max-width: unset;
            }
            vertical-align: middle;
            padding-#{$rdirection}: 1.6%;
            margin-top: -2.7%;
            width: 2.19vw;
            max-width: 25px;
          }
        }
      }
    }
  }
}
