///
/// @file drupal_modules/esteelauder_boutique/_boutique.scss
///
/// \brief Shared styles for template field modules at:
///    esteelauder_boutique/templates/boutique_embed_v1
///    esteelauder_boutique/templates/boutique_formatter_largebg_v1
///    esteelauder_boutique/templates/boutique_formatter_mobilebg_v1
///    esteelauder_boutique/templates/boutique_spp_tile_v1
///    esteelauder_boutique/templates/spp_boutique_ref_v1
///

.boutique-brick {
  position: relative;
}

// spp_boutique_ref_v1

// @todo add media queries and stuff
// padding is temporary for demo

.spp__boutique {
  position: relative;
  width: 1024px;
  height: 515px;
  @include swap_direction(padding, 80px);
}

.spp__boutique__content--right {
  text-align: $rdirection;
}

.spp__boutique__content--center {
  text-align: center;
}

.subhead--bold {
  font-weight: bold;
}

// boutique_spp_tile_v1
//boutique__tile

// boutique_formatter_largebg_v1
.boutique_formatter {
  max-width: 1366px;
  position: relative;
  @include swap_direction(margin, 0 auto);
  margin-bottom: 27px;
}

.colorbox__boutique {
  #cboxContent {
    background: $color-navy;
    @include swap_direction(padding, 0);
  }
  .cboxIframe {
    background: $color-navy;
    @include swap_direction(border, 0 none);
    display: block;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
  #cboxClose {
    top: 5px;
    #{$rdirection}: 5px;
  }
}

// boutique_tout_v1
.boutique-tout {
  width: 1100px;
  position: relative;
  @include swap_direction(margin, 100px auto 100px auto);
  clear: both;
  overflow: visible;
  min-height: 780px;
  .boutique-tout-image-wrapper {
    text-align: center;
    width: 100%;
    margin-#{$rdirection}: auto;
    margin-#{$ldirection}: auto;
    margin-top: 100px;
    position: relative;
    overflow: visible;
    .boutique-tout-image {
      width: 1100px;
      height: 650px;
      margin-#{$rdirection}: auto;
      margin-#{$ldirection}: auto;
      text-align: center;
    }
  }
  .boutique-tout-eyebrow-wrapper {
    text-align: center;
    margin-bottom: 30px;
    &.empty-eyebrow {
      margin-bottom: 0;
      margin-top: -10px;
      display: block;
      height: 1px;
    }
    .boutique-tout-eyebrow {
      font-size: 12px;
      text-align: center;
      font-family: $akzidenz;
      color: #000;
      &.dark {
        color: #000;
      }
      &.light {
        color: $color-white;
      }
    }
  }
  .boutique-tout-main-headline-wrapper {
    text-align: center;
    position: absolute;
    top: -90px;
    width: 100%;
    z-index: 99;
    .boutique-tout-main-headline {
      font-size: 136.6px;
      line-height: 136.6px;
      text-align: center;
      font-family: $optimalight;
      color: #000;
      &.dark {
        color: #000;
      }
      &.light {
        color: $color-white;
      }
    }
  }
  .boutique-tout-copy-block-wrapper {
    text-align: center;
    position: absolute;
    bottom: 35px;
    width: 100%;
    display: block;
    z-index: 99;
    &.align-left {
      text-align: $ldirection;
      padding-#{$ldirection}: 30px;
      bottom: 20px;
    }
    &.align-center {
      text-align: center;
      bottom: 35px;
      .boutique-tout-copy-block > span {
        text-align: center;
      }
    }
    &.align-right {
      text-align: $rdirection;
      padding-#{$rdirection}: 30px;
      bottom: 20px;
    }
    .boutique-tout-copy-block {
      display: inline-block;
      position: relative;
      color: $color-navy;
      &.dark {
        color: $color-navy;
      }
      &.light {
        color: $color-white;
      }
      & > span {
        display: block;
        text-align: $ldirection;
        font-family: $akzidenz;
        font-size: 12px;
        line-height: 24px;
      }
      .boutique-tout-copy-block-header {
        font-weight: bold;
      }
    }
  }
  .boutique-tout-button-wrapper {
    margin-top: 50px;
    text-align: center;
    position: absolute;
    bottom: -20px;
    width: 100%;
    z-index: 99;
  }
  &.boutique-tout-size-small {
    width: 800px;
    min-height: 590px;
    @media #{$medium-only} {
      width: 100%;
    }
    .boutique-tout-image-wrapper {
      margin-top: 60px;
      .boutique-tout-image {
        width: 800px;
        height: 460px;
        @media #{$medium-only} {
          width: 100%;
        }
      }
    }
    .boutique-tout-eyebrow-wrapper {
      .boutique-tout-eyebrow {
        font-size: 12px;
        text-align: center;
        font-family: $akzidenz;
        color: $color-navy;
        &.dark {
          color: $color-navy;
        }
      }
    }
    .boutique-tout-main-headline-wrapper {
      top: -65px;
      .boutique-tout-main-headline {
        font-family: $optimalight;
        letter-spacing: -0.02em;
        font-size: 94px;
        line-height: 94px;
        color: $color-navy;
        &.dark {
          color: $color-navy;
        }
      }
    }
    .boutique-tout-copy-block-wrapper {
      bottom: 20px;
      &.align-left {
        text-align: $ldirection;
        padding-#{$ldirection}: 20px;
        bottom: 20px;
      }
      &.align-center {
        text-align: center;
        bottom: 35px;
        .boutique-tout-copy-block > span {
          text-align: center;
        }
      }
      &.align-right {
        text-align: $rdirection;
        padding-#{$rdirection}: 20px;
        bottom: 20px;
      }
      .boutique-tout-copy-block {
        display: inline-block;
        position: relative;
        color: $color-navy;
        &.dark {
          color: $color-navy;
        }
        &.light {
          color: $color-white;
        }
        & > span {
          display: block;
          text-align: $ldirection;
          font-family: $akzidenz;
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
    .boutique-tout-button-wrapper {
      margin-top: 50px;
      bottom: -20px;
    }
  }
}

.field-elc-mpp-content {
  .boutique-tout {
    @include swap_direction(margin, 0 auto 100px auto);
  }
}

.mpp + div {
  .boutique-tout {
    @include swap_direction(margin, -10px auto 100px auto);
    .boutique-tout-eyebrow-wrapper.empty-eyebrow {
      margin-top: -27px;
    }
  }
}

// buttons
.boutique-tout-button {
  @include button-plus;
  @include text--short--plain;
  text-indent: 0;
  font-weight: bold;
  position: relative;
  @include swap_direction(margin, 0 auto);
  &.dark {
    color: $color-white;
    background-color: $color-navy;
  }
  &.light {
    color: $color-navy;
    background-color: $color-white;
  }
  &.plus-sign {
    width: 150px;
    padding-#{$ldirection}: 60px;
    &:before {
      content: '';
      position: absolute;
      #{$ldirection}: 40px;
      height: 40px;
      border-#{$rdirection}: 1px solid $color-navy;
    }
  }
}

// ANR Oculus Blue Out V1
@import 'oculus-blue-out_v1';
