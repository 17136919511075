@if $loyalty-redesign {
  .loyalty_mrkt_ldng {
    max-width: 1366px;
    margin: 0 auto;
    &::before {
      background: none;
    }
    @media #{$medium-up} {
      margin-top: -$header-collapsed-height;
    }
    .loyalty_join_signin {
      margin: 35px 0 35px 65px;
      .form-submit {
        width: 125px;
        display: inline;
        margin-#{$rdirection}: 10px;
        padding: 0 10px;
        &.btn_sign_in {
          border: 1px solid $color-navy;
          background-color: $color-white;
          color: $color-navy;
        }
      }
      .offer-percent {
        @media #{$medium-up} {
          letter-spacing: -0.01em;
          margin: 0;
        }
        color: $color-navy;
        letter-spacing: 0.05em;
        font-size: 30px;
        font-family: $font-bauerbodoni-roman;
        margin: 10px 0;
      }
      .offer-percent-number {
        margin-top: 0;
        text-transform: lowercase;
        letter-spacing: 0.05em;
      }
      .offer-order {
        font-family: $akzidenz;
        text-transform: none;
        font-weight: normal;
        font-size: 15px;
      }
      .offer-signin {
        margin-bottom: 0;
        font-size: 12px;
      }
    }
    .btn_sign_in {
      display: block;
    }
    .loyalty_tiers {
      max-width: 1090px;
      margin: 0 auto;
      padding: 0 20px;
      .tier {
        width: 100%;
        float: #{$ldirection};
        color: $color-navy;
        @media #{$large-up} {
          width: 33%;
        }
        .loyalty-catgory-name {
          font-size: 30px;
          text-transform: uppercase;
          border-bottom: 1px solid $color-navy;
          width: 180px;
          padding-bottom: 10px;
          text-align: #{$ldirection};
          font-family: $font-bauerbodoni-roman;
          @media #{$large-up} {
            font-size: 30px;
            width: 82%;
            letter-spacing: 1px;
          }
          &.second-catgory {
            width: 270px;
            @media #{$large-up} {
              width: 92%;
            }
          }
          &.final-catgory {
            width: 165px;
            @media #{$large-up} {
              width: 100%;
            }
          }
        }
        .tier_left {
          width: 100%;
          &.benefits_hover_tier_left_off {
            color: $color-navy;
          }
          .points-range {
            text-align: #{$ldirection};
            display: block;
            width: 100%;
            padding-top: 15px;
            font-size: 12px;
            letter-spacing: 0.15em;
            &.points-benefits {
              font-size: 15px;
              letter-spacing: 0.05em;
              line-height: 1.3;
            }
          }
        }
        .tier_right {
          width: 100%;
          @media #{$medium-up} {
            padding: 8px 3px 0;
          }
          .tier_bold {
            font-size: 14px;
            font-weight: bold;
          }
          &.benefits_hover_tier_left_off {
            color: $color-navy;
          }
          .loyal-benfit {
            @include swap_direction(padding, 0 25px 0 15px);
          }
          .loyal-catgory-benfits {
            list-style-type: disc;
            list-style-position: outside;
            padding-#{$rdirection}: 15px;
            &:before {
              display: none;
            }
          }
        }
      }
      .sub_head {
        font-size: 15px;
        text-transform: capitalize;
        margin-#{$ldirection}: 50px;
        letter-spacing: 0.05em;
      }
      .loyal-love-head {
        @media #{$medium-up} {
          font-size: 40px;
          letter-spacing: -0.01em;
        }
        text-transform: uppercase;
        font-size: 39px;
        letter-spacing: 0;
        font-family: $bb-italic;
        margin-#{$ldirection}: 50px;
      }
    }
    .loyalty_points {
      clear: both;
      background-color: $color-white;
      color: $color-navy;
      .points_earn {
        @media #{$medium-up} {
          letter-spacing: -0.01em;
        }
        font-style: italic;
        text-transform: uppercase;
        letter-spacing: 0em;
        font-size: 40px;
        font-family: $font-bauerbodoni-roman;
        &__decscription {
          font-size: 15px;
          letter-spacing: 0.05em;
        }
      }
      .loyalty_question {
        .loyalty_learn_more {
          color: $color-navy;
        }
      }
    }
    .beauity-header {
      @media #{$medium-up} {
        letter-spacing: -0.01em;
      }
      font-style: italic;
      text-transform: uppercase;
      font-size: 40px;
      color: $color-navy;
      margin-top: 25px;
      font-family: $font-bauerbodoni-roman;
      line-height: 1;
    }
    .beauity-content {
      color: $color-navy;
      margin-bottom: 0;
    }
    .elist-banner {
      height: 30vw;
      background-repeat: no-repeat;
      background-size: cover;
      max-width: 1366px;
      max-height: 410px;
      @if $loyalty-banner-cr24update {
        background-image: url('/media/loyalty/cr24/elist-banner.jpg');
      } @else {
        background-image: url('/media/loyalty/elist-banner.jpg');
      }
      .elist-banner {
        &-header {
          @include swap_direction(padding, 14% 0 0 4%);
          font-size: 59px;
          color: $color-white;
          font-family: $font-bauerbodoni-roman;
          letter-spacing: 0.01em;
        }
        &-content {
          margin: 10px;
          font-size: 18px;
          color: $color-white;
          font-weight: 500;
          letter-spacing: 0.15em;
        }
      }
    }
    .elist-content {
      margin-#{$ldirection}: 50px;
    }
  }
  .field-mobile-template {
    .loyalty_market {
      .loyalty_market {
        &__btn__signin {
          border: 1px solid $color-navy;
          background-color: $color-white;
          color: $color-navy;
        }
        &__cta {
          background: none;
        }
        &__points {
          @include swap_direction(padding, 18px 5px 28px);
          background-color: $color-white;
          color: $color-navy;
          &__container {
            background: none;
          }
          &__offer {
            text-transform: initial;
            font-size: 15px;
            letter-spacing: 0.05em;
            line-height: 1.6;
            br {
              display: none;
            }
          }
          &__list {
            line-height: 1.6;
          }
          &__link {
            font-size: 15px;
          }
        }
        &__tiers {
          padding: 0 25px;
        }
        &__tier__content {
          padding: 6px 0 25px;
          .excited-benefits {
            margin-bottom: 5px;
          }
        }
        &__tier__controller {
          padding: 0;
        }
        &__tier__header {
          text-transform: uppercase;
          font-size: 28px;
          letter-spacing: 0.02em;
          font-family: $font-bauerbodoni-roman;
        }
        &__tier__points {
          font-weight: bold;
          font-size: 12px;
        }
        &__tiers__text {
          text-transform: initial;
          font-size: 15px;
          letter-spacing: 0.05em;
          br {
            display: block;
          }
        }
        &__tiers__header {
          letter-spacing: -0.02em;
          font-size: 40px;
          text-transform: uppercase;
          font-style: italic;
          font-family: $font-bauerbodoni-roman;
        }
        &__cta__offer_discount {
          margin: 0;
          font-size: 28px;
          font-family: $font-bauerbodoni-roman;
          letter-spacing: -0.01em;
          line-height: 1.2;
        }
        &__cta__offer_member {
          font-weight: 100;
          text-transform: lowercase;
          font-size: 15px;
          letter-spacing: 0.05em;
        }
        &__cta__offer {
          font-size: 12px;
          letter-spacing: 0.15em;
          .disclaimer {
            text-transform: none;
            margin: 0;
          }
        }
        &__header {
          margin: 0 15px;
          font-size: 40px;
          line-height: 1;
          text-transform: uppercase;
          font-style: italic;
          font-family: $font-bauerbodoni-roman;
        }
        &__points__header {
          font-size: 40px;
          font-family: $bb-italic;
          line-height: 1.25;
          text-transform: uppercase;
          letter-spacing: -0.01em;
        }
        &__text {
          font-size: 15px;
          line-height: 1.6;
          margin: 15px 0;
          br {
            display: block;
          }
        }
        &__tier__list {
          padding-#{$ldirection}: 15px;
          &__item {
            list-style-position: outside;
            line-height: 1.75;
          }
        }
        &__excited__benefits,
        &__excited__enthusiastic__benefits {
          font-size: 15px;
          text-transform: inherit;
        }
      }
    }
    .elist-banner {
      height: 100vw;
      background-repeat: no-repeat;
      background-size: cover;
      @if $loyalty-banner-cr24update {
        background-image: url('/media/loyalty/cr24/account-login.jpg');
      } @else {
        background-image: url('/media/loyalty/account-login.jpg');
      }
      .elist-banner {
        &-header {
          font-size: 10vw;
          color: $color-white;
          letter-spacing: 0.03em;
          padding-top: 54vw;
          font-family: $font-bauerbodoni-roman;
        }
        &-content {
          letter-spacing: 0.15em;
          margin: 4px;
          font-size: 4vw;
          color: $color-white;
          font-family: $akzidenz;
        }
      }
    }
    .loyalty_multi_image_tout {
      .loyalty_multi_image_tout {
        &_left {
          position: static;
        }
        &_right {
          padding-top: 0;
        }
      }
    }
  }
  #cboxOverlay {
    &.signin-overlay-loyalty,
    &.loyalty-offer-overlay {
      background: transparent;
    }
  }
  .loyalty-offer-overlay {
    border: 1px solid $color-medium-gray;
    @if $loyalty-popup-cr23update {
      @media #{$medium-up} {
        border: none;
      }
    }
    #cboxContent {
      padding: 0;
      background: $color-white;
      @if $loyalty-popup-cr23update {
        @media #{$medium-up} {
          border: 1px solid $color-medium-gray;
        }
      }
      .loyalty_popover {
        @media #{$medium-up} {
          padding: 56px 52px 0;
        }
        padding: 30px 0;
        color: $color-navy;
        &.signin-overlay-loyalty {
          @media #{$medium-up} {
            padding-bottom: 50px;
          }
        }
        .loyalty_popover {
          &__input {
            .form-submit {
              border: 1px solid $color-navy;
              color: $color-white;
              background: $color-navy;
            }
            .form-text {
              margin-bottom: 10px;
            }
            .form-text,
            .form-password {
              @include input-placeholder {
                color: $color-medium-gray;
              }
              @media #{$medium-up} {
                width: 270px;
              }
              width: 100%;
              border: 1px solid $color-gray;
              color: $color-navy;
            }
          }
          &__password {
            .password-field {
              &__rules {
                li {
                  color: $color-black;
                }
              }
            }
          }
          &__link,
          &__checkbox-link,
          &__terms,
          &__privacy {
            color: $color-navy;
          }
          &__checkbox-container {
            width: auto;
            margin-top: 15px;
            .loyalty_popover__checkbox {
              &-label {
                @include swap_direction(padding, 0 0 0 24px);
                &:before {
                  background: $color-white;
                  border: 1px solid $color-navy;
                }
                &:after {
                  background-image: url('/media/loyalty/crossmark.png');
                  border: 1px solid $color-navy;
                }
              }
              &-link {
                color: $color-navy;
              }
            }
          }
          &__button {
            .button {
              border: 1px solid $color-navy;
              color: $color-white;
              background: $color-navy;
            }
          }
          &__checkbox-label {
            a {
              color: $color-navy;
            }
          }
          &__terms {
            clear: both;
            margin-top: 10px;
            letter-spacing: 0.05em;
            a {
              color: $color-navy;
            }
          }
          &__header {
            font-size: 40px;
            font-style: italic;
            font-family: $font-bauerbodoni-roman;
            text-transform: uppercase;
            letter-spacing: -0.01em;
            line-height: 1;
          }
          &__choose {
            @media #{$medium-up} {
              width: 80%;
              margin: 20px 0 15px;
            }
            text-transform: initial;
            font-size: 15px;
            letter-spacing: 0.05em;
          }
          &__promo-header {
            @media #{$medium-up} {
              margin-top: 22px;
            }
            font-size: 15px;
            font-family: $akzidenz;
            letter-spacing: 0.05em;
            margin-top: 0;
            line-height: 0.83;
          }
          &__promo {
            text-transform: initial;
            letter-spacing: 0.05em;
            margin: 15px 0;
          }
          &__sub-header {
            font-size: 12px;
            font-family: $akzidenz;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            margin-top: 15px;
            line-height: 1.2;
          }
          &__status {
            letter-spacing: 0.15em;
            padding-top: 5px;
            font-size: 12px;
            margin-bottom: 0;
          }
          &__offer-note {
            margin-top: 10px;
            letter-spacing: 0.05em;
            @if $loyalty-popup-cr23update {
              @media #{$medium-up} {
                padding-bottom: 30px;
              }
            }
          }
          &__submit-container {
            margin-bottom: 20px;
          }
        }
      }
    }
    #cboxClose {
      background-image: url('/media/images/pro_active_popup/close.jpg') !important;
      margin: 20px;
    }
  }
  /* Sign in popup thank you message */
  .signin-overlay-wrapper {
    .sign-in-component {
      &--loyalty {
        &.registration-confirmation {
          padding: 20px 10px;
        }
      }
      .sign-in-component {
        &__confirm {
          .sign-in-component {
            &__header {
              margin-bottom: 15px;
            }
            &__registration-confirmation-copy {
              display: block;
              font-size: 15px;
              letter-spacing: 0.05em;
              font-family: $akzidenz;
              font-style: italic;
              font-weight: 100;
            }
            &__registration-confirmation-header {
              font-family: $font-bauerbodoni-roman;
              font-size: 32px;
              font-style: italic;
              font-weight: 100;
            }
            &__confirm-registration-fields {
              display: flex;
              flex-direction: column;
              max-width: 540px;
              border: 0;
              margin-bottom: 0;
              padding-bottom: 0;
            }
            &__birthday-program {
              float: none;
              width: 100%;
              padding: 0;
              order: 1;
              .select-month,
              .select-day {
                &.error {
                  ~ .select-month,
                  ~ .select-day {
                    border: 1px solid $color-red;
                  }
                }
              }
            }
            &__confirm-options {
              max-width: 540px;
              .button {
                float: #{$ldirection};
                margin-top: 10px;
                width: 115px;
              }
            }
            &__sms-opt-in {
              width: 100%;
              float: none;
              padding: 0;
              order: 2;
              margin-bottom: 0;
            }
            &__completion-error {
              color: $color-red;
              padding-top: 30px;
              line-height: 1.2;
            }
            &__mobile-conditions {
              line-height: 1.5;
            }
          }
          .error_messages_display {
            li {
              color: $color-red;
              font-size: 12px;
              line-height: 1.5;
              font-weight: 100;
              letter-spacing: 0.05em;
              font-family: $akzidenz;
            }
          }
        }
      }
    }
  }
  .offer-code__form {
    .offer-code {
      &__input {
        @media #{$medium-up} {
          margin-bottom: 0;
          float: #{$ldirection};
          width: 60%;
        }
      }
      &__submit {
        .form-submit {
          @media #{$medium-up} {
            @include swap_direction(margin, 0 0 0 10px);
            width: 36%;
            height: 44px;
          }
          @media #{$large-up} {
            width: 30%;
          }
        }
      }
    }
  }
  .social-login__terms {
    .social-terms {
      font-style: initial;
      letter-spacing: 0.05em;
    }
  }
  .samples-overlay {
    .samples-offer-carousel-panel {
      display: none;
    }
  }
  .user-loyalty-state {
    @media #{$medium-up} {
      display: block;
      position: absolute;
      font-size: 12px;
      font-weight: normal;
      text-align: #{$rdirection};
      #{$rdirection}: 20px;
      min-width: 375px;
      @media #{$large-down} {
        @media #{$portrait} {
          top: 38px;
        }
      }
      .user-logged-out,
      .user-logged-in {
        display: none;
        &__link {
          margin-#{$ldirection}: 9px;
          color: $color-navy;
          font-weight: normal;
          text-decoration: none;
          text-transform: capitalize;
          letter-spacing: 1px;
          .js-loyalty-points-value {
            font-weight: bold;
          }
        }
      }
    }
    @media #{$large-up} {
      bottom: 0;
    }
    @media #{$medium-only} {
      #{$rdirection}: 100px;
    }
    @media #{$large-only} {
      #{$rdirection}: 10px;
    }
  }
  .page-utilities__loyalty {
    .user-greeting {
      margin-#{$ldirection}: 9px;
      color: $color-navy;
      font-weight: normal;
      text-transform: none;
      text-decoration: none;
    }
    .sign-out-link {
      margin-#{$ldirection}: 7px;
      color: $color-darker-gray;
      font-weight: normal;
      text-transform: none;
      text-decoration: none;
    }
    .user-loyalty-state {
      @media #{$medium-up} {
        z-index: 1;
        top: 35px;
      }
    }
  }
  .account-mobile-landing-page {
    .account-landing {
      &-menu {
        .account-landing-menu__link {
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: 1px;
          font-family: $akzidenz;
          background-position: 100% -502px;
        }
      }
      &-profile {
        &__link {
          height: 45px;
          line-height: 1.25;
          width: 100%;
        }
        .user {
          &__photo {
            &__upload {
              font-weight: bold;
              font-size: 12px;
              text-align: center;
              letter-spacing: 0.15em;
            }
          }
          &__recommendations {
            text-align: center;
          }
        }
      }
    }
  }
  .page-sticky-footer__left {
    a {
      font-weight: 700;
    }
  }
  .loyalty-account-page__about {
    &:before {
      width: auto;
    }
    .loyalty_mrkt_ldng {
      .loyalty_tiers {
        padding: 0;
        .loyalty-catgory-name {
          &.final-catgory {
            @media #{$medium-up} {
              font-size: 24px;
              width: 145px;
            }
            @media #{$large-up} {
              font-size: 22px;
              width: 85%;
            }
          }
          @media #{$medium-up} {
            font-size: 24px;
            width: 160px;
            letter-spacing: 0.05em;
          }
          @media #{$large-up} {
            font-size: 22px;
            width: 85%;
          }
        }
      }
      .elist-banner {
        height: 25vw;
      }
      .beauity-header {
        font-size: 40px;
      }
      .loyalty_points {
        @media #{$medium-up} {
          width: auto;
          padding: 0;
        }
        @media #{$large-up} {
          width: 700px;
          padding: 30px;
        }
      }
    }
  }
  .device-mobile {
    #colorbox {
      &.loyalty-offer-overlay {
        opacity: 1 !important;
      }
    }
  }
  .user-login-state {
    @media #{$medium-up} {
      #{$rdirection}: 20px;
      min-width: 200px;
    }
    .sign-out-link {
      line-height: 3.5;
    }
  }
  #loyalty__content {
    .is-member {
      .loyalty_tiers {
        a {
          display: block;
        }
      }
    }
  }
  .elc-user-state-logged-in {
    .page-utilities {
      .page-utilities,
      .utility-nav {
        &__signin-text {
          display: none;
        }
      }
    }
    #colorbox {
      &.signin-overlay-wrapper {
        &.new-user-registration {
          height: 0 !important;
          border: 0;
        }
      }
    }
    &.elc-user-state-loyalty {
      #colorbox {
        &.signin-overlay-wrapper {
          &.new-user-registration {
            height: 100% !important;
            border: 1px solid $color-medium-gray;
            @media #{$medium-up} {
              height: 500px !important;
            }
          }
        }
      }
    }
  }
}
