// Note: The main theme styles are managed inside Olapic admin
$modal_bg_color: rgba(4, 10, 43, 0.9);

.olapic-widget {
  max-width: $max-width;
  margin: 0 auto;
  padding: 20px 0;
  @media #{$medium-up} {
    padding: 50px 0 0;
  }
}

.olapic-widget__container {
  position: relative;
  .olapic-tools {
    .olapic-upload {
      color: inherit;
    }
  }
  &.font--futurabook,
  &.font--futura-now-medium {
    .olapic-widget {
      &__header {
        font-family: $futura_now_md;
      }
      &__subhead {
        font-family: $futura_now_md;
      }
      &__heading {
        font-family: $futura_now_md;
      }
    }
    .olapic-tools {
      .olapic-upload {
        font-family: $futura_now_md;
      }
    }
  }
  
  &.font--essonnes {
    .olapic-widget {
      &__header {
        font-family: $essonnes;
      }
      &__subhead {
        font-family: $essonnes;
      }
      &__heading {
        font-family: $essonnes;
      }
    }
    .olapic-tools {
      .olapic-upload {
        font-family: $essonnes;
      }
    }
  }
  &.font--helvetica-neue {
    .olapic-widget {
      &__header {
        font-family: $roboto-condensed;
      }
      &__subhead {
        font-family: $roboto-condensed;
      }
      &__heading {
        font-family: $roboto-condensed;
      }
    }
    .olapic-tools {
      .olapic-upload {
        font-family: $roboto-condensed;
      }
    }
  }
  &.font--bb-roman {
    .olapic-widget {
      &__header {
        font-family: $bb-roman;
      }
      &__subhead {
        font-family: $bb-roman;
      }
      &__heading {
        font-family: $bb-roman;
      }
    }
    .olapic-tools {
      .olapic-upload {
        font-family: $bb-roman;
      }
    }
  }
}

.olapic-widget__header {
  text-align: center;
  margin: 0 0 20px 0;
  padding: 0 20px;
  position: relative;
  z-index: 2;
  @media #{$medium-up} {
    margin-bottom: 20px;
  }
}

.olapic-widget__heading {
  line-height: 0.85;
  letter-spacing: -0.05em;
  .olapic-widget-font & {
    @include headline--primary;
    font-size: 35px;
    @media #{$medium-up} {
      font-size: 50px;
    }
  }
}

.olapic-widget__subhead {
  font-size: 12px;
  letter-spacing: 0.15em;
  font-weight: 700;
  .olapic-widget-font & {
    @include text--bold;
  }
  margin: 0 0 10px 0;
  &--bottom {
    margin: 20px 0 10px 0;
  }
}

.olapic-widget__body {
  > p {
    margin: 0;
  }
}

.olapic-widget__content {
  // If template has copy do not offset image
  .olapic-widget--has-copy & {
    margin-top: 0;
  }
  .olapic {
    .olapic-footer-button {
      a:link {
        font-family: inherit;
      }
      a:visited {
        font-family: inherit;
      }
    }
  }
  &.button-navy--white-font {
    .olapic {
      .olapic-footer-button {
        a:link {
          background-color: $color-navy;
          color: $color-white !important; // important required as color set by Olpaic uses important also.
        }
      }
    }
    .olapic-loadmore-container {
      .olapic-loadmore {
        background-color: $color-navy;
        color: $color-white;
      }
    }
  }
  &.button-white--navy-font-border {
    .olapic {
      .olapic-footer-button {
        a:link {
          background-color: $color-white;
          color: $color-navy !important; // important required as color set by Olpaic uses important also.
          border: 1px solid $color-navy;
        }
      }
    }
    .olapic-loadmore-container {
      .olapic-loadmore {
        background-color: $color-white;
        color: $color-navy;
        border: 1px solid $color-navy;
      }
    }
  }
  &.button-gray--white-font {
    .olapic {
      .olapic-footer-button {
        a:link {
          background-color: $color-periwinkle-blue--aerin;
          color: $color-white !important; // important required as color set by Olpaic uses important also.
        }
      }
    }
    .olapic-loadmore-container {
      .olapic-loadmore {
        background-color: $color-periwinkle-blue--aerin;
        color: $color-white;
      }
    }
    .olapic:not(.olapic-mobile):not(.olapic-tablet) .olapic-footer-button a:hover {
      background-color: $color-periwinkle-blue--aerin;
    }
  }
  &.button-white--grey-font-border {
    .olapic {
      .olapic-footer-button {
        a:link {
          background-color: $color-white;
          color: $color-periwinkle-blue--aerin-v2 !important; // important required as color set by Olpaic uses important also.
          border: 1px solid $color-periwinkle-blue--aerin;
        }
      }
    }
    .olapic-loadmore-container {
      .olapic-loadmore {
        background-color: $color-white;
        color: $color-periwinkle-blue--aerin-v2;
        border: 1px solid $color-periwinkle-blue--aerin;
      }
    }
    .olapic:not(.olapic-mobile):not(.olapic-tablet) .olapic-footer-button a:hover {
      background-color: $color-white;
    }
  }
  &.button-black--gradient-border {
    .olapic {
      .olapic-footer-button {
        a:link {
          background-color: $color-black;
          color: $color-white !important; // important required as color set by Olpaic uses important also.
          border: 1px solid;
          border-image-slice: 1;
          font-weight: 500;
          border-image-source: linear-gradient(to left, $color-gold--renutriv, $color-gold--renutriv-v2);
        }
      }
    }
    .olapic-loadmore-container {
      .olapic-loadmore {
        background-color: $color-black;
        color: $color-white;
        border: 1px solid;
        border-image-slice: 1;
        font-weight: 500;
        border-image-source: linear-gradient(to left, $color-gold--renutriv, $color-gold--renutriv-v2);
      }
    }
    .olapic:not(.olapic-mobile):not(.olapic-tablet) .olapic-footer-button a:hover {
      background-color: $color-black;
    }
  }
  &.font--optima-light {
    .olapic-loadmore-container {
      .olapic-loadmore {
        font-family: $optimalight;
      }
    }
  }
  &.font--akzidenzregular {
    .olapic-loadmore-container {
      .olapic-loadmore {
        font-family: $akzidenz;
      }
    }
  }
  &.font--akzidenzregularbq,
  &.font--akzidenz-bold {
    .olapic-loadmore-container {
      .olapic-loadmore {
        font-family: $akzidenz;
        font-weight: bold;
      }
    }
  }
  &.font--futurabook,
  &.font--futura-now-medium {
    .olapic-loadmore-container {
      .olapic-loadmore {
        font-family: $futura_now_md;
      }
    }
  }
  &.font--essonnes {
    .olapic-loadmore-container {
      .olapic-loadmore {
        font-family: $essonnes;
      }
    }
  }
  &.font--helvetica-neue {
    .olapic-loadmore-container {
      .olapic-loadmore {
        font-family: $roboto-condensed;
      }
    }
  }
  &.font--bb-roman {
    .olapic-loadmore-container {
      .olapic-loadmore {
        font-family: $bb-roman;
      }
    }
  }
  &.font--optima-regular {
    .olapic-loadmore-container {
      .olapic-loadmore {
        font-family: $optimaregular;
      }
    }
  }
}

// Olapic overrides
// These styles are generated inline by olapic so we need to use !important declaration to override
.olapic .modal {
  background: $modal_bg_color !important;
}

.olapic .close {
  font-size: 50px !important;
  font-weight: 100 !important;
  color: $color-navy !important;
  opacity: 0.3 !important;
}

.olapic .modal-header .close {
  margin-top: -8px !important;
  margin-right: 1px !important;
}

.olapic a,
.olapic a:hover,
.olapic a:focus {
  color: $color-navy;
}

.olapic-widget.olapic-widget-v1 .olapic-loadmore-container {
  z-index: 10;
}
