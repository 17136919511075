///
/// @file components/template_fields/_home_product_v1.scss
/// homepage template styles a, c, d, e
///
/// \brief Styling for template field module at:
///    esteelauder_editorial/templates/drupal/home_product_v1
///

///
/// Share product center style
///
/// Full width so image can center via inline-block
///

.brick {
  width: 100%;
  position: relative;
  max-width: $max-width;
  //min-height: 200px;
  @include swap_direction(margin, 0 auto 100px auto);
  clear: both;
  overflow: hidden;
  &.brick--last {
    margin-bottom: 0;
    .brick__container {
      bottom: 0 !important;
    }
  }
  .headline--large {
    line-height: 130px;
    @include swap_direction(margin, 0);
  }
  .promo-header-text {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    .subhead {
      font-weight: bold;
      line-height: 1.8em;
      @include swap_direction(margin, 0 0 3px 0);
    }
    .subcopy {
      margin-bottom: 17px;
      line-height: 1.8em;
    }
  }
}

/*
*  Variations
*/

// Template A

.brick--a {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  .brick__container--a {
    position: absolute;
    #{$ldirection}: 0;
    width: 100%;
    bottom: 25%;
    bottom: $sticky-footer-height;
  }

  // Headline
  .homepage__headline {
    text-align: center;
    position: relative;
    //margin-bottom: -52px;
    margin-bottom: -46px;
  }

  // Foreground
  .homepage-product__promo--a {
    text-align: center;
    position: absolute;
    bottom: -20px;
    #{$ldirection}: 0;
    width: 100%;
    z-index: 1;

    // Foreground Copy
    .homepage-product__promo-copy {
      .button {
        margin-bottom: 25px;
      }
      .boutique-custom__link {
        .button {
          @include swap_direction(margin, 0 auto);
        }
      }
    }
  }

  // Background
  .homepage__hero--a {
    //width: 75%;
    max-width: 858px;
    @include swap_direction(margin, 0 auto);
    width: 66.8%;
    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  // media queries
  @media (max-width: 768px) {
    .homepage__hero--a {
      width: 80%;
    }
  }
  @media (min-width: 769px) and (max-height: 935px) {
    .brick__container--a {
      //background: orange;
    }
    .homepage__hero--a {
      width: 59.8%;
      @media (max-width: 1250px) {
        width: 60.8%;
      }
      @media (max-width: 1024px) {
        width: 66.8%;
      }
    }
  }
  @media (min-width: 769px) and (max-height: 880px) {
    .brick__container--a {
      //background: blue;
    }
    .homepage__hero--a {
      width: 56.8%;
      @media (max-width: 1250px) {
        width: 56.8%;
      }
      @media (max-width: 1150px) {
        width: 60.8%;
      }
      @media (max-width: 1024px) {
        width: 66.8%;
      }
    }
  }
  @media (min-width: 769px) and (max-height: 850px) {
    .brick__container--a {
      //background: purple;
    }
    .homepage__hero--a {
      width: 53.8%;
      @media (max-width: 1250px) {
        width: 56.8%;
      }
      @media (max-width: 1150px) {
        width: 60.8%;
      }
      @media (max-width: 1024px) {
        width: 66.8%;
      }
    }
  }
  @media (min-width: 769px) and (max-height: 810px) {
    .brick__container--a {
      //background: green;
    }
    .homepage__hero--a {
      width: 48.8%;
      @media (max-width: 1250px) {
        width: 50.8%;
      }
      @media (max-width: 1150px) {
        width: 54.8%;
      }
      @media (max-width: 1024px) {
        width: 64.8%;
      }
      @media (max-width: 820px) {
        width: 66.8%;
      }
    }
  }
  @media (min-width: 769px) and (max-height: 720px) {
    .brick__container--a {
      //background: cyan;
    }
    .homepage__hero--a {
      width: 46.8%;
      @media (max-width: 1150px) {
        width: 50.8%;
      }
      @media (max-width: 1024px) {
        width: 60.8%;
      }
      @media (max-width: 820px) {
        width: 66.8%;
      }
    }
  }
  @media (min-width: 769px) and (max-height: 660px) {
    .brick__container--a {
      //background: pink;
      position: relative;
      padding-top: $header-collapsed-height + 40;
    }
    .homepage__hero--a {
      width: 66.8%;
    }
  }
} //.brick--a

// Template B

.brick--b {
  .brick__container--b {
    position: absolute;
    bottom: $sticky-footer-height; // height of site footer
    //padding-top: $header-collapsed-height;
    //top: 82px; // height of site header
    #{$ldirection}: 0;
    width: 100%;
    //height: 100%;
    @include clearfix;
  }

  // Headline
  .homepage__headline {
    text-align: center;
    margin-bottom: -46px;
    position: relative;
  }

  // Foreground
  .homepage-product__promo--b {
    // Foreground Product Image
    .product-promo-image--b {
      position: absolute;
      margin-top: -6%;
      @include swap_direction(padding, 0 75% 0 5%);
    }
    .product-promo-image--b__link {
      max-width: 310px;
      display: block;
      .product-promo-image--b__image {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    // Foreground Copy
    .homepage-product__promo-copy {
      @include swap_direction(padding, 0 7% 0 73%);
      width: 100%;
      position: absolute;
      #{$ldirection}: 0;
      z-index: 10;
      margin-top: 9%;
      text-align: $ldirection;
      .promo-header-text {
        max-width: 246px;
        //color: $brand-blue;
        .subcopy {
          margin-bottom: 19px;
        }
      }
      .button {
        margin-bottom: 25px;
        white-space: nowrap;
      }
    }
  }

  // Background
  .homepage__hero--b {
    width: 76%;
    max-width: 975px;
    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  // media queries

  @media (min-width: 769px) and (max-height: 880px) {
    .brick__container--b {
      //background: blue;
    }
    .homepage__hero--b {
      width: 65%;
      @media (max-width: 1200px) {
        width: 73%;
      }
      @media (max-width: 1024px) {
        width: 76%;
      }
    }
    .homepage-product__promo--b .homepage-product__promo-copy {
      @include swap_direction(padding, 0 18% 0 62%);
      @media (max-width: 1200px) {
        @include swap_direction(padding, 0 10% 0 70%);
      }
      @media (max-width: 1024px) {
        @include swap_direction(padding, 0 7% 0 73%);
      }
    }
  }
  @media (min-width: 769px) and (max-height: 780px) {
    .brick__container--b {
      //background: green;
    }
    .homepage__hero--b {
      width: 56%;
      @media (max-width: 1200px) {
        width: 65%;
      }
      @media (max-width: 1024px) {
        width: 70%;
      }
    }
    .homepage-product__promo--b .homepage-product__promo-copy {
      @include swap_direction(padding, 0 27% 0 53%);
      @media (max-width: 1200px) {
        @include swap_direction(padding, 0 18% 0 62%);
      }
      @media (max-width: 1024px) {
        @include swap_direction(padding, 0 14% 0 66%);
      }
    }
  }
  @media (min-width: 769px) and (max-height: 720px) {
    .brick__container--b {
      //background: cyan;
    }
    .homepage__hero--b {
      width: 51%;
      @media (max-width: 1200px) {
        width: 56%;
      }
      @media (max-width: 1024px) {
        width: 70%;
      }
    }
    .homepage-product__promo--b .homepage-product__promo-copy {
      @media (min-width: 769px) {
        @include swap_direction(padding, 0 31% 0 49%);
      }
      @media (max-width: 1200px) {
        @include swap_direction(padding, 0 27% 0 53%);
      }
      @media (max-width: 1024px) {
        @include swap_direction(padding, 0 14% 0 66%);
      }
    }
  }
  @media (min-width: 769px) and (max-height: 660px) {
    .brick__container--b {
      //background: pink;
      position: relative;
      padding-top: $header-collapsed-height + 40;
    }
    .homepage__hero--b {
      width: 76%;
    }
    .homepage-product__promo--b .homepage-product__promo-copy {
      @include swap_direction(padding, 0 7% 0 73%);
    }
  }
} //.brick--b

// Template C

.brick--c {
  .brick__container--c {
  }

  // Headline
  .homepage__headline {
    position: absolute;
    margin-top: 13%;
    width: 100%;
    text-align: center;
    z-index: 11;
  }

  // Foreground
  .homepage-product__promo--c {
    // Foreground Product Image
    .product-promo-image--c {
      position: absolute;
      margin-top: 14.25%;
      @include swap_direction(padding, 0 14% 0 62%);
      z-index: 10;
    }
    .product-promo-image--c__link {
      max-width: 310px;
      display: block;
      .product-promo-image--c__image {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    // Foreground Copy
    .homepage-product__promo-copy {
      @include swap_direction(padding, 0 14% 0 62%);
      width: 100%;
      position: absolute;
      #{$ldirection}: 0;
      z-index: 11;
      margin-top: 40%;
      text-align: center;
      .promo-header-text {
        max-width: 310px;
        //color: $brand-blue;
      }
      .button {
        margin-bottom: 25px;
        white-space: nowrap;
      }
      .boutique-custom__link {
        .button {
          @include swap_direction(margin, 0 auto);
        }
      }
    }
  }

  // Background
  .homepage__hero--c {
    // position: absolute;
    position: relative;
    top: 0;
    #{$ldirection}: 0;
    width: 76%;
    max-width: 956px;
    z-index: 1;
    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  // media queries

  @media (min-width: 1200px) and (max-height: 800px) {
    .brick__container--c {
      //background: blue;
    }
    .homepage__hero--c {
      width: 61%;
      @media (max-width: 1200px) {
        width: 73%;
      }
      @media (max-width: 1024px) {
        width: 76%;
      }
    }
    .homepage-product__promo--c .product-promo-image--c,
    .homepage-product__promo--c .homepage-product__promo-copy {
      @include swap_direction(padding, 0 27% 0 49%);
      @media (max-width: 1200px) {
        @include swap_direction(padding, 0 10% 0 70%);
      }
      @media (max-width: 1024px) {
        @include swap_direction(padding, 0 7% 0 73%);
      }
    }
  }
  @media (min-width: 1370px) {
    .homepage-product__promo--c .homepage-product__promo-copy {
      margin-top: 518px;
    }
  }
} //.brick--c

// Template D

.brick--d {
  float: $rdirection;
  .brick__container--d {
    position: absolute;
    //padding-top: $header-collapsed-height;
    bottom: $sticky-footer-height; // height of site footer
    #{$ldirection}: 0;
    width: 100%;
    //height: 100%;
    @include clearfix;
  }

  // Headline
  .homepage__headline {
    text-align: center;
    margin-bottom: -46px;
    position: relative;
    z-index: 11;
  }

  // Foreground
  .homepage-product__promo--d {
    // Foreground Product Image
    .product-promo-image--d {
      position: absolute;
      margin-top: -4%;
      @include swap_direction(padding, 0 75% 0 5%);
      @media (max-width: 768px) {
        margin-top: -6%;
      }
    }
    .product-promo-image--d__link {
      max-width: 310px;
      display: block;
      .product-promo-image--d__image {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    // Foreground Copy
    .homepage-product__promo-copy {
      @include swap_direction(padding, 0 75% 0 5%);
      width: 100%;
      position: absolute;
      #{$ldirection}: 0;
      z-index: 10;
      margin-top: 24%;
      text-align: $ldirection;
      .promo-header-text {
        max-width: 246px;
        //color: $brand-blue;
      }
      .button {
        margin-bottom: 40px;
        white-space: nowrap;
      }
      .boutique-custom__link {
        .button {
          @include swap_direction(margin, 0);
          position: absolute;
          @media (min-width: 1200px) {
            top: 169px;
          }
        }
      }
    }
  }

  // Background
  .homepage__hero--d {
    width: 73%;
    float: $rdirection;
    margin-#{$rdirection}: 11%;
    max-width: 937px;
    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  // media queries

  @media (min-width: 769px) and (max-height: 880px) {
    .brick__container--d {
      //background: blue;
    }
    .homepage__hero--d {
      width: 62%;
      @media (max-width: 1200px) {
        width: 69%;
      }
      @media (max-width: 1024px) {
        width: 73%;
      }
    }
    .homepage-product__promo--d .product-promo-image--d,
    .homepage-product__promo--d .homepage-product__promo-copy {
      @include swap_direction(padding, 0 64% 0 16%);
      @media (max-width: 1200px) {
        @include swap_direction(padding, 0 75% 0 7%);
      }
      @media (max-width: 1024px) {
        @include swap_direction(padding, 0 75% 0 5%);
      }
    }
  }
  @media (min-width: 769px) and (max-height: 780px) {
    .brick__container--d {
      //background: green;
    }
    .homepage__hero--d {
      width: 56%;
      @media (max-width: 1200px) {
        width: 62%;
      }
      @media (max-width: 1024px) {
        width: 73%;
      }
    }
    .homepage-product__promo--d .product-promo-image--d,
    .homepage-product__promo--d .homepage-product__promo-copy {
      @include swap_direction(padding, 0 59% 0 21%);
      @media (max-width: 1200px) {
        @include swap_direction(padding, 0 68% 0 14%);
      }
      @media (max-width: 1024px) {
        @include swap_direction(padding, 0 75% 0 5%);
      }
    }
  }
  @media (min-width: 769px) and (max-height: 720px) {
    .brick__container--d {
      //background: cyan;
    }
    .homepage__hero--d {
      width: 51%;
      @media (max-width: 1200px) {
        width: 59%;
      }
      @media (max-width: 1024px) {
        width: 65%;
      }
      @media (max-width: 800px) {
        width: 73%;
      }
    }
    .homepage-product__promo--d .product-promo-image--d,
    .homepage-product__promo--d .homepage-product__promo-copy {
      @include swap_direction(padding, 0 54% 0 26%);
      @media (max-width: 1200px) {
        @include swap_direction(padding, 0 62% 0 20%);
      }
      @media (max-width: 1024px) {
        @include swap_direction(padding, 0 71% 0 10%);
      }
      @media (max-width: 1024px) {
        @include swap_direction(padding, 0 71% 0 10%);
      }
      @media (max-width: 800px) {
        @include swap_direction(padding, 0 75% 0 5%);
      }
    }
  }
  @media (min-width: 769px) and (max-height: 660px) {
    .brick__container--d {
      //background: pink;
      position: relative;
      padding-top: $header-collapsed-height + 40;
    }
    .homepage__hero--d {
      width: 73%;
    }
    .homepage-product__promo--d .product-promo-image--d,
    .homepage-product__promo--d .homepage-product__promo-copy {
      @include swap_direction(padding, 0 75% 0 5%);
    }
  }
} //.brick--d

// Template E

.brick--e {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  .brick__container--e {
  }

  // Headline
  .homepage__headline {
    text-align: center;
    position: absolute;
    top: 18%;
    width: 100%;
    z-index: 10;
  }

  // Foreground
  .homepage-product__promo--e {
    text-align: center;
    position: absolute;
    top: 24.5%;
    #{$ldirection}: 0;
    width: 100%;
    z-index: 1;

    // Foreground Product Image
    .product-promo-image--e {
      @include swap_direction(padding, 0 37%);
      @media (max-width: 768px) {
        padding-top: 2%;
      }
    }
    .product-promo-image--e__link {
      max-width: 330px;
      display: block;
      .product-promo-image--e__image {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    // Foreground Copy
    .homepage-product__promo-copy {
      position: absolute;
      text-align: $rdirection;
      #{$ldirection}: 18%;
      top: 37%;
      .button {
        margin-bottom: 25px;
      }
      .boutique-custom__link {
        .button {
          float: $rdirection;
        }
      }
    }
  }

  // Background
  .homepage__hero--e {
    max-width: 956px;
    @include swap_direction(margin, 0 auto);
    padding-top: 4%;
    @media (max-width: 768px) {
      padding-top: 15%;
    }
    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }
}

@media (orientation: portrait) {
  .brick {
    min-height: 200px !important;
    margin-bottom: 150px;
    &.brick--last {
      margin-bottom: 150px;
      .brick__container {
        bottom: auto !important;
      }
    }
  }
  .brick__container {
    position: relative !important;
    bottom: auto !important;
    top: auto !important;
    @include swap_direction(padding, 0 !important);
  }
  .homepage__headline {
    margin-bottom: -52px !important;
  }
}

@media (min-width: 768px) {
  .boutique-custom__link {
    a.button-explore.button-explore-label {
      text-indent: 0;
      font-family: 'AkzidenzGrotesk W1G';
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.15em;
      width: 150px;
      height: 40px;
      text-transform: uppercase;
      padding-#{$ldirection}: 60px;
      background-size: auto auto;
      background: url('../img-local/sprites-s59c8df9059.png') -7px -3269px no-repeat;
      width: 53px;
      height: 53px;
      background-position: -7px -1946px;
      #{$ie8} & {
        position: relative;
      }
      background-size: auto auto;
      background: url('/media/images/global/sprites-s38a9885fce.png') -7px -3269px no-repeat;
      width: 53px;
      height: 53px;
      &:before {
        border-#{$rdirection}: 1px solid $color-navy;
        margin-#{$ldirection}: -20px;
        height: 38px;
        display: inline-block;
        content: '';
        position: absolute;
      }
    }
  }
}

@media (min-width: 1340px) {
  #brick__new-advanced-night-repair-eye {
    .promo-header-text {
      margin-top: -27px;
    }
  }
}
