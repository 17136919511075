// Common styles used by all icons in HTML and CSS
@mixin icon {
  display: block;
  width: 35px;
  height: 35px;
}

@mixin svg-icon-inline-mask($filename) {
  -webkit-mask: url('#{$base-theme-image-path}icons/src/#{$filename}') no-repeat;
  mask: url('#{$base-theme-image-path}icons/src/#{$filename}') no-repeat;
}
