.beauty-chat-tray {
  text-align: center;
  padding-top: 24px;
  -webkit-font-smoothing: antialiased;
  .beauty-chat-tray__title {
    @include headline--quinary--plain;
    margin-bottom: 10px;
  }
  .beauty-chat-tray__unavailable-copy,
  .beauty-chat-tray__main-copy {
    letter-spacing: -0.02em;
    a {
      text-decoration: underline;
      color: $color-white;
      &.cta {
        text-decoration: none;
      }
      #{$aerin} & {
        color: $color-white;
        background: transparent;
      }
    }
  }
  .beauty-chat-tray__main-copy {
    width: 620px;
    @include swap_direction(margin, 0 auto);
  }
  .beauty-chat-tray__hours-of-availability,
  .beauty-chat-tray__hours-of-availability p {
    @include text--bold;
  }
  .beauty-chat-tray__unavailable-copy {
    display: none;
  }
  &.beauty-chat-tray--unavailable {
    padding-top: 64px;
    .beauty-chat-tray__unavailable-copy {
      display: block;
    }
    .beauty-chat-tray__main-copy,
    .button {
      display: none;
    }
    .beauty-chat-tray__learn-more {
      margin-top: 10px;
    }
  }
  .button {
    @include swap_direction(margin, 1em 0 17px);
  }
  .beauty-chat-tray__learn-more {
    @include link--bold;
  }
  p {
    @include swap_direction(margin, 0);
  }
}

.beauty-chat-drawer .drawer-formatter__trigger {
  background-size: auto auto;
  background-position: 0 -795px;
  padding-#{$ldirection}: 30px;
  &.active {
    background-size: auto auto;
    background-position: 0 -839px;
  }
}
