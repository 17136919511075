$gwp-min-width: 630px;

.gwp-fall {
  &__container {
    @media #{$xlarge-up} {
      max-width: $max-width;
      margin: 0 auto;
      padding-bottom: 60px;
    }
    &--banner,
    &--products {
      position: relative;
    }
    &--bleed {
      @media #{$xlarge-up} {
        margin-top: -$header-collapsed-height;
      }
    }
    &--products {
      color: $color-navy;
    }
  }
  &__banner {
    &--image {
      width: 100%;
    }
    &--small {
      @media #{$medium-up} {
        display: none;
      }
    }
    &--large {
      display: none;
      @media #{$medium-up} {
        min-height: 225px;
        display: block;
      }
    }
    &-content {
      max-width: $product-wide;
      width: 100%;
      margin: 0 auto;
      position: absolute;
      top: 0;
      height: 100%;
      #{$rdirection}: 0;
      #{$ldirection}: 0;
      padding-#{$ldirection}: 0;
      padding-top: 0;
      text-align: center;
      color: $color-white;
      @media #{$medium-up} {
        padding-#{$ldirection}: 100px;
        text-align: #{$ldirection};
      }
      @media #{$large-up} {
        padding-top: 110px;
        padding-#{$ldirection}: 130px;
      }
      @media #{$xlarge-up} {
        padding-top: 8.25%;
        padding-#{$ldirection}: 9.5%;
        min-width: $gwp-min-width;
      }
      &--eyebrow,
      &--heading {
        text-transform: uppercase;
        margin: 0;
        line-height: 1;
      }
      &--eyebrow {
        font-weight: bold;
        margin-bottom: 9px;
        @include text-promo;
        font-size: 12px;
        letter-spacing: 0.27em;
        @media #{$large-up} {
          margin-bottom: 10px;
          letter-spacing: 0.14em;
        }
      }
      .headline--large {
        font-size: 58px;
        letter-spacing: -0.03em;
        margin-bottom: 3px;
        line-height: 0.85;
        @media #{$medium-up} {
          margin-bottom: 0;
          text-indent: -0.05em;
          font-size: 40px;
        }
        @media #{$xlarge-up} {
          font-size: 58px;
          text-indent: -5px;
        }
      }
      .headline--medium {
        font-size: 31px;
        margin-bottom: 8px;
        letter-spacing: 0.05em;
        @media #{$medium-up} {
          font-size: 20px;
          margin-bottom: 4px;
          letter-spacing: -0.75px;
        }
        @media #{$large-up} {
          margin-bottom: 6px;
        }
        @media #{$xlarge-up} {
          font-size: 42px;
          text-indent: -4px;
          margin-bottom: 24px;
        }
      }
      &--description {
        font-size: 14px;
        @media #{$medium-up} {
          font-size: 12px;
        }
        line-height: 1.7;
        letter-spacing: 1.9px;
        margin: 0;
      }
      &--promo {
        margin-top: 20px;
        position: absolute;
        bottom: 20%;
        width: 35%;
        @media #{$medium-up} {
          position: static;
          width: auto;
          margin-top: 40px;
          text-indent: -3px;
        }
        @media #{$large-up} {
          margin-top: 35px;
        }
      }
      &--image-wrapper {
        width: 100%;
        position: absolute;
        bottom: 0;
        text-align: center;
      }
    }
  }
  &__product-details {
    .gwp-fall__product-cta--oos {
      width: auto;
      display: block;
      font-size: 12px;
      margin-top: 1px;
      text-align: center;
      @media #{$medium-up} {
        text-align: #{$ldirection};
      }
    }
    &--link {
      line-height: 1.2;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      color: $color-navy;
      display: inline-block;
      margin-top: 7px;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      border-bottom: solid 1px $color-navy;
      @media #{$medium-up} {
        margin-top: 0;
        letter-spacing: 0.1em;
        font-size: 12px;
      }
    }
    &--overlay {
      @include swap_direction(padding, 27px 20px 19px 41px);
      z-index: 999;
      bottom: 50px;
      width: 100%;
      border: 1px solid $color-black;
      background: $color-white;
      position: absolute;
      color: $color-navy;
      #{$rdirection}: 0;
      #{$ldirection}: 0;
      text-align: #{$ldirection};
      @media #{$medium-up} {
        width: 100%;
        #{$rdirection}: auto;
        #{$ldirection}: 48px;
        min-width: 349px;
      }
    }
    &--close {
      position: absolute;
      bottom: 0;
      text-indent: -9999px;
      background: url('/media/images/global/sprites-s38a9885fce.png') no-repeat 0 -972px;
      text-shadow: none;
      color: transparent;
      background-size: auto auto;
      height: 28px;
      width: 28px;
      top: 10px;
      z-index: 100;
      border: none;
      -webkit-appearance: none;
      #{$rdirection}: 10px;
    }
  }
  &__product--options {
    width: 100%;
    position: relative;
    top: 0;
    max-width: 84.375vw;
    margin: auto;
    padding-bottom: 23px;
    clear: both;
    z-index: 2;
    @media #{$xlarge-up} {
      padding: 0 126px;
      max-width: $product-wide;
    }
    &-content {
      width: 84.375vw;
      margin: auto;
      @media #{$medium-up} {
        width: 53%;
        float: #{$ldirection};
      }
    }
    &-content-inner {
      text-align: center;
      @media #{$medium-up} {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-#{$ldirection}: 61px;
        text-align: #{$ldirection};
      }
    }
    &-copy {
      color: $color-navy;
      @media #{$xlarge-up} {
        max-width: 360px;
      }
    }
    .gwp-fall__product-option--label {
      font-size: 23px;
      font-family: $optimalight;
      line-height: 1;
      letter-spacing: 0;
      display: inline-block;
      text-indent: -2px;
      @media #{$medium-up} {
        font-size: 30px;
      }
    }
  }
  &__products {
    &--header {
      font-size: 32px;
      font-family: $optimalight;
      text-align: center;
      letter-spacing: -0.3px;
      text-indent: -3px;
      width: 84.375vw;
      margin: auto;
      padding: 28px 0 12px;
      clear: both;
      @media #{$medium-up} {
        margin: 32px auto 21px;
        width: auto;
        padding: 0;
        font-size: 40px;
      }
    }
  }
  &__product-option {
    &--subline {
      margin: 8px 0 0;
      font-size: 14px;
      line-height: 1.5;
      @media #{$medium-up} {
        margin: 10px 0 0;
        width: auto;
        font-size: 15px;
        line-height: 1.45;
      }
      @media #{$xlarge-up} {
        width: 100%;
      }
    }
    &--sub-title {
      text-transform: none;
      font-size: 15px;
    }
  }
  &__product-alignment--right {
    .gwp-fall__product {
      &--options-content {
        &-inner {
          @media #{$medium-up} {
            width: 48%;
          }
          @media #{$xlarge-up} {
            width: auto;
          }
        }
        @media #{$medium-up} {
          float: #{$rdirection};
          padding-#{$ldirection}: 57px;
          width: 47%;
        }
      }
      &-options--image-wrapper {
        @media #{$medium-up} {
          float: #{$rdirection};
          width: 53%;
          padding-#{$ldirection}: 61px;
        }
      }
      &-option--subline {
        @media #{$xlarge-up} {
          min-width: 305px;
          width: 80%;
        }
      }
      &-details--overlay {
        #{$ldirection}: auto;
      }
    }
  }
  &__product-options--image-wrapper {
    padding-bottom: 5px;
    @media #{$medium-up} {
      text-align: #{$ldirection};
      width: 47%;
      float: #{$ldirection};
      padding-bottom: 9px;
    }
    img {
      width: 100%;
    }
  }
  &__product {
    &-details {
      position: relative;
      padding-bottom: 4px;
      @media #{$medium-up} {
        padding-bottom: 16px;
        padding-top: 18px;
      }
    }
    &-cta {
      padding-#{$ldirection}: 0;
      text-align: center;
      margin-top: 12px;
      @media #{$medium-up} {
        text-align: #{$ldirection};
        margin-bottom: 36px;
      }
    }
    &-details--text {
      text-transform: none;
      letter-spacing: 0.1em;
      img {
        height: auto;
        max-width: 100%;
      }
      .non-breaking-word {
        display: inline-block;
      }
      .gwp-fall__details-list--header {
        line-height: 18px;
        font-size: 13px;
        letter-spacing: 1.1px;
        padding-bottom: 20px;
      }
      .gwp-fall--title {
        text-transform: uppercase;
      }
      dt {
        font-family: $akzidenz;
        font-weight: bold;
        display: list-item;
        list-style-image: url(/media/export/cms_2.0/offers/gwp_fall_2019/list_disc_image.jpg);
        text-transform: none;
        line-height: 1;
        margin-bottom: 5px;
        font-size: 12px;
        letter-spacing: 1.6px;
      }
      dd {
        text-transform: none;
        font-size: 15px;
        margin-bottom: 5px;
        letter-spacing: 0.05em;
        line-height: 1.4;
      }
    }
    &-cta--add,
    &-cta--remove {
      font-weight: bold;
      width: 84.375vw;
      height: 50px;
      font-size: 12px;
      line-height: 50px;
      margin-top: 4px;
      @media #{$medium-up} {
        margin-top: 0;
        width: auto;
        height: 40px;
        line-height: 40px;
      }
      &.button--inverted {
        background: $color-white;
        color: $color-navy;
        @media #{$medium-up} {
          background: none;
        }
      }
    }
    &--sold-out {
      padding-bottom: 30px;
      .gwp-fall__product-cta--add {
        cursor: default;
        display: none;
      }
      .gwp-fall__product-cta--oos {
        display: block;
        text-align: center;
        font-size: 14px;
        @media #{$medium-up} {
          text-align: #{$ldirection};
          font-size: 12px;
        }
      }
      &.gwp-fall__product--options {
        .gwp-fall__product-option--label {
          cursor: default;
        }
      }
      .gwp-fall__product-details {
        padding-bottom: 0;
      }
      .gwp-fall__product-cta {
        margin-top: 0;
      }
      @media #{$medium-up} {
        padding-bottom: 0;
      }
    }
    &-cta--oos {
      display: none;
      border: 0;
      width: 84.375vw;
      margin: 26px auto 0;
      text-align: #{$ldirection};
      font-weight: normal;
      padding: 0;
      color: $color-red;
      line-height: normal;
      height: auto;
      cursor: default;
      text-transform: uppercase;
      font-size: 12px;
      background: none;
      letter-spacing: 0.15em;
      @media #{$medium-up} {
        width: auto;
        margin-#{$ldirection}: 0;
        display: block;
      }
    }
    &-cta--loading {
      @include swap_direction(margin, 0 0 0 80px);
      display: none;
      position: absolute;
      font-size: 12px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      cursor: default;
      font-weight: bold;
      height: 45px;
      line-height: 45px;
      padding-#{$ldirection}: 38px;
      background: url(/media/images/common/ajax-loader.gif) no-repeat 0 0 transparent;
      #{$ldirection}: 0.5%;
    }
    &-cta--loader {
      position: relative;
      padding-bottom: 40px;
      width: 84.375vw;
      margin: 12px auto 36px;
      @media #{$medium-up} {
        width: auto;
      }
      .gwp-fall__product-cta--loading {
        display: block;
        #{$ldirection}: 0;
        margin: auto;
        @media #{$medium-up} {
          #{$ldirection}: 0.5%;
        }
      }
      .gwp-fall__product-cta--add,
      .gwp-fall__product-cta--remove,
      .gwp-fall__product-cta--oos {
        display: none;
      }
    }
  }
  &__notice-container {
    width: 100%;
    text-align: center;
    font-size: 12px;
    padding: 0 0 36px;
    clear: both;
    @media #{$medium-up} {
      font-size: 15px;
      padding: 0 0 56px;
    }
    &--copy {
      line-height: 1;
    }
  }
  &__overlay-container--success-overlay {
    #cboxClose {
      #{$rdirection}: 20px !important;
      top: 20px;
    }
  }
  &__overlay-content--success {
    text-align: center;
    @media #{$medium-up} {
      position: absolute;
      top: 50%;
      #{$ldirection}: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    width: 100%;
    &-header {
      font-size: 38px;
      font-family: $optimalight;
      line-height: 1;
      letter-spacing: 0;
      font-weight: bold;
    }
    &-copy {
      text-transform: uppercase;
      font-size: 13px;
      margin: 10px 0;
      line-height: 20px;
      letter-spacing: 0.15em;
      @media #{$medium-up} {
        margin: 10px 40px;
      }
    }
    &-button {
      font-size: 12px;
      font-family: $akzidenz;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;
      font-weight: bold;
      display: block;
      margin-top: 14px;
    }
  }
  &__overlay-content--continue-button {
    margin-top: 0;
  }
  &__continue-gwp-shop {
    font-family: $akzidenz;
    font-weight: bold;
    text-decoration: underline;
  }
  &--add-to-gift {
    font-family: $akzidenz;
    padding-top: 0;
    margin-bottom: 0;
    .gwp-fall__product-option--offer-code strong {
      font-family: $akzidenz;
    }
    .gwp-fall__product-options--image-wrapper {
      padding-bottom: 0;
      @media #{$medium-up} {
        padding-bottom: 5px;
      }
      @media #{$xlarge-up} {
        padding-bottom: 10px;
      }
    }
    .gwp-fall__product--options-copy {
      @media #{$medium-up} {
        margin-top: 4px;
      }
      @media #{$xlarge-up} {
        max-width: 405px;
      }
    }
    &.gwp-fall__product {
      &-alignment--right .gwp-fall__product--options-content {
        padding-#{$ldirection}: 3px;
      }
    }
    .gwp-fall__product {
      &-option--subline {
        margin-top: 0;
        line-height: 1.5;
        font-family: $akzidenz;
        @media #{$medium-up} {
          margin-top: 4px;
          line-height: 1.45;
          margin-bottom: 8px;
        }
        @media #{$large-up} {
          width: 100%;
        }
      }
      &-option--bold {
        margin-bottom: 13px;
        display: inline-block;
      }
      &--options-content-inner {
        padding-#{$ldirection}: 0;
        @media #{$medium-up} {
          padding-#{$ldirection}: 61px;
        }
        @media #{$xlarge-up} {
          width: auto;
        }
      }
    }
    .gwp-fall__product-option--label {
      display: none;
      @media #{$medium-up} {
        display: block;
      }
      @media #{$large-up} {
        margin-bottom: 12px;
      }
    }
  }
  &__engraving {
    min-height: 424px;
    position: relative;
    color: $color-white;
    &-disclaimer {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 12px;
      @media #{$medium-up} {
        margin-bottom: 51px;
      }
      &-content {
        color: $color-navy;
        margin: auto;
        width: 85%;
        max-width: 520px;
        line-height: 1.45;
      }
    }
    @media #{$large-up} {
      border: solid 1px $color-navy;
    }
    &-background-large {
      display: none;
      @media #{$medium-up} {
        display: block;
      }
    }
    &-background-small {
      display: block;
      @media #{$medium-up} {
        display: none;
      }
    }
    &-background-images {
      width: 100%;
      @media #{$medium-up} {
        position: absolute;
        top: auto;
        #{$ldirection}: 0;
        bottom: 0;
        #{$rdirection}: 0;
        margin: auto;
      }
    }
    &-container {
      position: absolute;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      top: 50%;
      margin: auto;
      transform: translateY(-50%);
      max-width: 84.375vw;
      text-align: center;
      @media #{$medium-up} {
        text-align: #{$ldirection};
      }
      @media #{$large-up} {
        max-width: $product-wide;
        padding: 0 126px;
      }
    }
    &--eyebrow {
      display: block;
      font-family: $akzidenz;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      margin: 0;
      line-height: 1.5;
      font-weight: bold;
      margin-bottom: 4px;
      font-size: 12px;
      @media #{$medium-up} {
        line-height: 1;
        margin-bottom: 10px;
      }
      @media #{$large-up} {
        margin-top: 6px;
      }
    }
    &--title {
      display: block;
      font-size: 32px;
      font-family: $optimalight;
      letter-spacing: 0;
      @media #{$large-up} {
        font-size: 40px;
      }
    }
    &-input {
      margin-bottom: 14px;
      @media #{$medium-up} {
        margin-top: 20px;
        margin-bottom: 18px;
      }
      .gwp-fall__engraving-input--field {
        border: solid 1px $color-white;
        background: none;
        box-shadow: none;
        width: 47px;
        height: 47px;
        margin-#{$rdirection}: 13px;
        text-transform: uppercase;
        text-align: center;
        font-size: 15px;
        font-family: $roman-l-monog-abc;
        color: $color-white;
        padding: 5px;
        @media #{$medium-up} {
          width: 60px;
          height: 60px;
          font-size: 32px;
          margin-#{$rdirection}: 15px;
        }
        &-3 {
          margin-#{$rdirection}: 0;
        }
      }
      &--desc {
        display: block;
        font-size: 15px;
        letter-spacing: 1px;
      }
    }
    &-form--submit {
      font-weight: bold;
      width: 84.375vw;
      margin-top: 16px;
      color: $color-white;
      border-color: $color-white;
      cursor: default;
      height: 50px;
      font-size: 12px;
      line-height: 50px;
      @media #{$medium-up} {
        width: auto;
        height: 40px;
        font-size: 12px;
        line-height: 40px;
        margin-top: 25px;
      }
      @media #{$large-up} {
        margin-bottom: 7px;
      }
      &-active {
        background-color: $color-white;
        cursor: pointer;
        color: $color-navy;
        pointer-events: auto;
      }
    }
    &-panel--left,
    &-panel--right {
      @media #{$medium-up} {
        width: 50%;
        float: #{$ldirection};
      }
    }
    &-panel--left {
      .gwp-fall__engraving--bag-description,
      .gwp-fall__engraving-image-container {
        display: block;
        @media #{$medium-up} {
          display: none;
        }
      }
    }
    &-panel--right {
      .gwp-fall__engraving--bag-description,
      .gwp-fall__engraving-image-container {
        display: none;
        @media #{$medium-up} {
          display: block;
        }
      }
    }
    &-image-container {
      text-align: center;
      margin-top: 10px;
      position: relative;
      width: 230px;
      margin: 31px auto 0;
      @media #{$medium-up} {
        margin-top: 10px;
        width: auto;
      }
      img {
        width: 69%;
        @media #{$medium-up} {
          width: auto;
        }
      }
    }
    &-copy--preview {
      position: absolute;
      color: $color-gold;
      padding: 5px 10px;
      text-align: center;
      font-size: 24px;
      text-transform: uppercase;
      top: 50%;
      transform: translateY(-50%);
      font-family: $roman-l-monog-abc;
      letter-spacing: 0.4em;
      text-indent: 4px;
      width: 90px;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      margin: auto;
      @media #{$medium-up} {
        width: 100%;
      }
    }
    &--bag-description {
      display: block;
      font-size: 25px;
      font-family: $optimalight;
      letter-spacing: -0.6px;
      text-align: center;
      @media #{$medium-up} {
        font-size: 26px;
        margin-top: 6px;
      }
      @media #{$large-up} {
        font-size: 32px;
        margin-top: 11px;
      }
    }
    &--disabled {
      .gwp-fall__engraving-input--field,
      .gwp-fall__engraving-form--submit {
        border-color: $color-medium-gray;
        color: $color-medium-gray;
        pointer-events: none;
      }
    }
  }
  &__add-gift--title {
    margin-top: 0;
  }
  &__product-option--offer-code {
    font-size: 12px;
    letter-spacing: 1.5px;
  }
  &__product-img-cta,
  &__product-cta-link {
    cursor: pointer;
  }
  &__product-image {
    visibility: hidden;
  }
  &__product--faded {
    display: none;
  }
  &__product--disabled {
    .gwp-fall__product--initial {
      display: none;
    }
    .gwp-fall__product-cta--add,
    .gwp-fall__product-cta-link {
      cursor: default;
    }
    .gwp-fall__product-cta--add {
      background-color: $color-medium-gray;
      border-color: $color-medium-gray;
    }
    .gwp-fall__product--faded {
      display: block;
    }
  }
  &__engraving-form--error-message {
    color: $color-red;
    padding-bottom: 10px;
  }
}

.gwp-horoscope {
  text-align: center;
  width: 100%;
  position: relative;
  top: 0;
  margin: auto;
  margin-bottom: 53px;
  clear: both;
  color: $color-white;
  @media #{$medium-up} {
    margin-bottom: 100px;
  }
  @media #{$xlarge-up} {
    padding: 0 126px;
    max-width: $product-wide;
    margin-bottom: 40px;
  }
  &__image {
    width: 100%;
  }
  &__image--small {
    @media #{$medium-up} {
      display: none;
    }
  }
  &__image--large {
    display: none;
    @media #{$medium-up} {
      display: block;
    }
  }
  &__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    @media #{$xlarge-up} {
      padding: 0 126px;
      max-width: $product-wide;
    }
  }
  &__content-eyebrow {
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    margin: 0 0 14px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
  &__content-wrapper {
    &--top {
      position: absolute;
      top: 24px;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      @media #{$medium-up} {
        top: 20px;
      }
      @media #{$large-up} {
        top: 40px;
      }
      @media #{$xlarge-up} {
        top: 60px;
      }
    }
    &--bottom {
      position: absolute;
      bottom: 24px;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      @media #{$medium-up} {
        bottom: 20px;
      }
      @media #{$large-up} {
        bottom: 40px;
      }
      @media #{$xlarge-up} {
        bottom: 60px;
      }
    }
  }
  &__content-headline {
    &--large {
      font-size: 28px;
      letter-spacing: -0.3px;
      width: 68%;
      margin: auto;
      line-height: 0.95;
      @media #{$medium-up} {
        font-size: 40px;
        width: auto;
        line-height: 0.85;
      }
    }
    &--medium {
      font-size: 19px;
      margin: 6px 0 0;
      letter-spacing: 0.2px;
      @media #{$medium-up} {
        font-size: 31px;
        margin: 14px 0 0;
        letter-spacing: -0.3px;
      }
    }
  }
  &__content-description {
    font-size: 13px;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: 1.1px;
    @media #{$medium-up} {
      font-size: 15px;
      margin-bottom: 18px;
      letter-spacing: 0.8px;
    }
  }
  &__button--read-now {
    border-color: $color-white;
    background-color: $color-white;
    cursor: pointer;
    color: $color-navy;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    height: 45px;
    letter-spacing: 2px;
    line-height: 45px;
    margin-top: 17px;
    text-transform: uppercase;
    width: 84.375vw;
    @media #{$medium-up} {
      width: auto;
      height: 40px;
      font-size: 12px;
      line-height: 40px;
      margin-top: 6px;
      margin-bottom: 3px;
    }
  }
}
