// BEGIN: Oculus Blue Overlay Styles
// sass-lint:disable propery-sort-order, leading-zero, no-vendor-prefixes, class-name-format, no-ids

// Colors
$ff-color-estee-blue: #040a2b;
$ff-color-overlay: rgba(4, 10, 43, 0.7);
$ff-color-transparent: transparent;
$ff-color-white: #fff;

// Variables
// Bubble size
$ff-bubble-xsml: 220px;
$ff-bubble-sml: 380px;
$ff-bubble-med: 560px;
$ff-bubble-lrg: 750px;
$ff-bubble-xlrg: 1280px;

$ff-font-akzidenz: 'AkzidenzGrotesk W1G';
$ff-font-optima-light: 'OptimaDisplayLight';

// Media Queries
$ff-mobile-portrait: 'only screen and (max-width: 767px)';
$ff-mobile-landscape: 'only screen and (max-width: 767px) and (orientation: landscape)';

// Mixins
@mixin ff-backface-visibility($property) {
  -moz-backface-visibility: $property;
  -webkit-backface-visibility: $property;
  backface-visibility: $property;
}

@mixin ff-transform($property) {
  -moz-transform: $property;
  -ms-transform: $property;
  -o-transform: $property;
  -webkit-transform: $property;
}

@mixin ff-transform-origin($property) {
  -moz-transform-origin: $property;
  -ms-transform-origin: $property;
  -o-transform-origin: $property;
  -webkit-transform-origin: $property;
}

// BEGIN: Colorbox Overrides
.oculus-blue-out-wrapper {
  #cboxContent {
    background-color: transparent;
  }
  #cboxClose,
  #cboxLoadingOverlay,
  #cboxLoadingGraphic {
    display: none;
  }
}
// END: Colorbox Overrides

.oculus-overlay {
  // BEGIN: Base Styles
  background-color: $ff-color-overlay;
  background-size: cover;
  color: $ff-color-white;
  display: table;
  font-family: $ff-font-akzidenz;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  &,
  * {
    box-sizing: border-box;
    margin: 0;
    outline: none;
    padding: 0;
  }
  &::before {
    background: $ff-color-overlay;
    content: ' ';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  & > div {
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    & > div {
      display: table;
      height: 100%;
      margin: 0 auto;
      max-height: 894px;
      max-width: 1366px;
      position: relative;
      text-align: left;
      width: 100%;
    }
  }
  a {
    color: $ff-color-white;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-decoration: underline;
    text-transform: uppercase;
  }
  h1,
  h2,
  h3,
  h4 {
    -webkit-font-smoothing: antialiased;
    font-family: $ff-font-optima-light;
    font-weight: 100;
  }
  h2 {
    font-size: 60px;
    letter-spacing: 1.5px;
    line-height: 38px;
  }
  h3 {
    font-size: 61px;
    height: 158px;
    letter-spacing: -2px;
    margin-bottom: 4%;
  }
  h4 {
    font-size: 36px;
    line-height: 39px;
    margin-bottom: 3%;
  }
  img {
    max-width: 100%;
    width: 100%;
  }
  p {
    font-size: 18px;
    letter-spacing: 0.1em;
    line-height: 24px;
  }

  // BEGIN: Main Potrait
  @media #{$ff-mobile-portrait} {
    a {
      font-size: 3.25vw;
    }
    h3 {
      font-size: 8.1vw;
      letter-spacing: 0.05vw;
    }
    p {
      font-size: 3vw;
    }
  }
  // END: Main Portrait

  // BEGIN: Main Landscape
  @media #{$ff-mobile-landscape} {
    p {
      font-size: 2vw;
    }
  }
  // END: Main Landscape
  // END: Base Styles

  // BEGIN: Content Styles
  .oculus-overlay__content {
    display: table;
    height: 100%;
    margin: 0 auto;
    vertical-align: middle;
    width: 600px;
    & > div {
      display: table-cell;
      vertical-align: middle;
      & > div {
        display: inline-block;
        position: relative;
      }
    }
    header {
      height: 125px;
      margin-left: -10%;
      overflow: hidden;
      text-align: center;
      h3 {
        color: $ff-color-transparent;
        display: inline-block;
        position: relative;
        img {
          @include ff-backface-visibility(hidden);
          height: 2800%;
          left: -104px;
          max-width: none;
          position: absolute;
          top: 0;
          width: auto;
        }
        .glow-text-start {
          height: 103.7%;
        }
      }
      &.text-only {
        height: auto;
        margin-left: 0;
        text-align: left;
        h3 {
          color: $ff-color-white;
          height: auto;
          img {
            display: none;
          }
        }
      }
    }
    .content__learn-more {
      background: $ff-color-estee-blue;
      border: 1px solid $ff-color-white;
      display: block;
      height: 40px;
      margin: 40px 0;
      padding: 12px 0 0;
      text-align: center;
      text-decoration: none;
      width: 135px;
    }
    .content__close {
      height: 30px;
      position: absolute;
      right: -5%;
      top: -2%;
      width: 30px;
    }

    // Begin: Content Portrait
    @media #{$ff-mobile-portrait} {
      width: 100%;
      & > div {
        padding: 0 7.7%;
        & > div {
          margin-bottom: 22.5%;
          width: 100%;
        }
      }
      header {
        height: 21vw;
        margin-left: -31%;
        width: 150%;
        &.text-only {
          width: 100%;
        }
        h3 {
          height: 117%;
          img {
            left: -20%;
          }
        }
      }
      h4 {
        font-size: 5.25vw;
        line-height: 8vw;
        margin-bottom: 1.8vw;
      }
      .content__text {
        margin-bottom: 8%;
        margin-top: -2.5%;
        width: 105%;
      }
      .content__learn-more {
        height: 14vw;
        margin: 5% 0 4.6%;
        padding: 5vw 0 0;
        width: 100%;
      }
      .content__close {
        height: 10vw;
        right: -5%;
        top: -20%;
        width: 10vw;
      }
    }
    // END: Content Potrait

    // BEGIN: Content Landscape
    @media #{$ff-mobile-landscape} {
      & > div > div {
        margin-bottom: 0;
      }
      header {
        height: 12vw;
        margin-bottom: 1.5vw;
        margin-left: -10%;
        width: 74%;
        h3 {
          img {
            left: 3%;
          }
        }
      }
      h4 {
        font-size: 3.8vw;
        line-height: 4vw;
      }
      .content__text {
        margin-bottom: 3.5%;
      }
      .content__learn-more {
        font-size: 2.25vw;
        height: 8vw;
        margin: 5% 0 2%;
        padding: 2.5vw 0 0;
      }
      .content__close {
        height: 5vw;
        top: 1%;
        width: 5vw;
      }
    }
    // END: Content Landscape
  }
  // END: Content Styles

  // BEGIN: Bubbles
  .oculus-overlay__bubbles {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    > div {
      @include ff-transform(translateX(-50%) translateY(-50%));
      @include ff-transform-origin(50% 50%);
      overflow: hidden;
      position: absolute;
      &.xsml {
        height: $ff-bubble-xsml;
        width: $ff-bubble-xsml;
      }
      &.sml {
        height: $ff-bubble-sml;
        width: $ff-bubble-sml;
      }
      &.med {
        height: $ff-bubble-med;
        width: $ff-bubble-med;
      }
      &.lrg {
        height: $ff-bubble-lrg;
        width: $ff-bubble-lrg;
      }
      &.xlrg {
        height: $ff-bubble-xlrg;
        width: $ff-bubble-xlrg;
      }
      img {
        display: block;
        height: 600%;
        max-width: none;
        position: absolute;
        width: auto;
      }
    }
    #bubble--1 {
      left: 14.5%;
      top: 33.7%;
    }
    #bubble--2 {
      left: 60%;
      top: 80.6%;
    }
    #bubble--3 {
      left: 40.1%;
      top: 11.9%;
    }
    #bubble--4 {
      left: 28.1%;
      top: 75.6%;
    }
    #bubble--5 {
      left: 69.3%;
      top: 70%;
    }
    #bubble--6 {
      left: 23.6%;
      top: 28.7%;
    }
    #bubble--7 {
      left: 39.9%;
      top: 70%;
    }
    #bubble--8 {
      left: 54.1%;
      top: 7.2%;
    }
    #bubble--9 {
      left: 83.5%;
      top: 33.8%;
    }
    #bubble--10 {
      left: 14.7%;
      top: 50.3%;
    }
    #bubble--11 {
      left: 54.5%;
      top: 82.6%;
    }
    #bubble--12 {
      left: 62.3%;
      top: 21.9%;
    }
    #bubble--13 {
      left: 36.8%;
      top: 21.9%;
    }
    #bubble--14 {
      left: 85.2%;
      top: 30.9%;
    }
    #bubble--15 {
      left: 17.6%;
      top: 62%;
    }
    @media #{$ff-mobile-portrait} {
      #bubble--1 {
        left: 0;
        top: 43.6%;
      }
      #bubble--2 {
        left: 78.6%;
        top: 73.9%;
      }
      #bubble--3 {
        left: 29.4%;
        top: 4.9%;
      }
      #bubble--4 {
        left: 0;
        top: 75.7%;
      }
      #bubble--5 {
        left: 105.1%;
        top: 69.8%;
      }
      #bubble--6 {
        left: 2.3%;
        top: 24.6%;
      }
      #bubble--7 {
        left: 29.1%;
        top: 70.4%;
      }
      #bubble--8 {
        left: 65.5%;
        top: 0.9%;
      }
      #bubble--9 {
        left: 105.6%;
        top: 65.6%;
      }
      #bubble--10 {
        left: -4%;
        top: 47.9%;
      }
      #bubble--11 {
        left: 66.6%;
        top: 84%;
      }
      #bubbl--12 {
        left: 100%;
        top: 16.6%;
      }
      #bubble--13 {
        left: 20.6%;
        top: 9%;
      }
    }
  }
  // END: Bubbles
}
// END: Oculus Blue Overlay Styles
