///
/// @file drupal_modules/esteelauder_estee_edit/_ee_spp_lists.scss
/// Styles for products in articles
///
/// \brief Styling for template at:
///   esteelauder/modules/custom/esteelauder_estee_edit/templates/ee_whatyouneed_v1
///   esteelauder/modules/custom/esteelauder_estee_edit/templates/ee_shopthestory_v1
///

.ee-article-products__list {
  max-width: 90%;
  margin: {
    #{$ldirection}: auto;
    #{$rdirection}: auto;
  }
  .sku-brief-editorial {
    width: 226px;
    text-align: center;
    display: block;
  }
  .slick-prev {
    @include hide-text;
    background-size: auto auto;
    background-position: 0 -8820px;
    height: 40px;
    width: 30px;
  }
  .slick-next {
    @include hide-text;
    background-size: auto auto;
    background-position: 0 -8600px;
    height: 40px;
    width: 30px;
  }
  .slick-disabled {
    opacity: 0;
  }
}

.ee-article-products__list-more {
  background-size: auto auto;
  background-position: 0 -3269px;
  height: 53px;
  width: 53px;
  background-color: $color-white;
  width: 53px;
  height: 53px;
  @include swap_direction(margin, 1em auto);
}

// typography
.ee-article-products__header {
  margin-bottom: 20px;
  h2 {
    font-size: 40px;
  }
}
// data layout
.sku-brief-editorial {
  .text-product-listing-names-main {
    margin-bottom: 10px;
  }
  .text-product-listing-names-sub {
    margin-bottom: 5px;
  }
  .sku-brief-editorial__shade {
    margin-bottom: 10px;
    display: inline-block;
    text-align: $ldirection;
    max-width: 80%;
    min-height: 22px;
    line-height: 22px;
  }
  .sku-brief-editorial__swatch-container {
    position: absolute;
    .swatch__container {
      @include swap_direction(margin, 0);
    }
  }
  .sku-brief-editorial__shadename {
    text-align: $ldirection;
    padding-#{$ldirection}: 32px;
  }
}

.sku-brief-editorial__reviews {
  margin-bottom: 10px;
  .reviews-image {
    display: inline;
  }
}
