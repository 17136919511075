.enhanced-cart-page {
  .viewcart {
    .samples-panel {
      border-bottom: 1px solid $color-light-gray;
      border-top: 1px solid $color-light-gray;
      margin-bottom: 15px;
      position: relative;
      @media #{$medium-up} {
        border: 1px solid $color-light-gray;
        margin-bottom: 16px;
      }
      &__header {
        cursor: pointer;
        margin-bottom: 15px;
        padding: 14px 16px;
        @media #{$medium-up} {
          margin-bottom: 6px;
        }
        &.open {
          padding-bottom: 0;
        }
      }
      &__message {
        text-align: $ldirection;
      }
      &__samples {
        margin-top: 19px;
        @media #{$medium-up} {
          margin-top: 17px;
        }
      }
      &__available-samples,
      &__spend-samples,
      &__samples-title {
        color: $color-black;
        font-family: $akzidenz;
        font-size: 15px;
        font-weight: bold;
        line-height: 1.43;
        @media #{$medium-up} {
          font-size: 17px;
          line-height: 1.72;
        }
      }
      &__samples-note {
        color: $color-black;
        font-family: $akzidenz;
        font-size: 15px;
        line-height: 1.43;
        margin-bottom: 6px;
        @media #{$medium-up} {
          font-size: 17px;
          line-height: 1.72;
          margin-bottom: 0;
        }
      }
      &__samples-title {
        text-transform: uppercase;
      }
      &__foot-note {
        color: $color-medium-gray-alt;
        font-size: 12px;
        line-height: 1.33;
        margin-top: 28px;
        text-align: $ldirection;
        @media #{$medium-up} {
          margin-top: 25px;
        }
      }
      .checkout__panel {
        &--header {
          margin: 0;
          position: static;
          .title-icon {
            #{$rdirection}: 16px;
            background: url('../../../../../media/images/cart_enhancement/plus-icon.png') no-repeat;
            background-size: contain;
            height: 24px;
            position: absolute;
            top: 10px;
            width: 24px;
            @media #{$medium-up} {
              top: 15px;
            }
          }
          &.open {
            .title-icon {
              background: url('../../../../../media/images/cart_enhancement/minus-icon.png')
                no-repeat;
              top: 22px;
              @media #{$medium-up} {
                top: 27px;
              }
            }
          }
        }
        &--content {
          #{$ldirection}: 100%;
          opacity: 0;
          padding: 15px 16px 18px;
          position: absolute;
          width: 100%;
          z-index: 0;
          &.expanded {
            opacity: 1;
            position: static;
          }
          @media #{$medium-up} {
            padding: 6px 16px 19px;
          }
        }
      }
    }
    .checkout__panel--heading {
      color: $color-navy;
      font-family: $optimalight;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1;
      text-align: $ldirection;
      @media #{$medium-up} {
        font-size: 26px;
      }
    }
    .sample-list {
      box-sizing: content-box;
      display: flex;
      margin: 0 15px;
      overflow: hidden;
      @media #{$medium-up} {
        margin: 0 26px;
      }
      .slick-list {
        width: 100%;
      }
      &.slick-initialized {
        overflow: visible;
      }
      &.slick-slider {
        touch-action: auto;
      }
      .sample {
        flex: 0 0 auto;
        margin: 0;
        position: relative;
        width: calc(50% - 24px);
        @media #{$medium-up} {
          width: calc(25% - 16px);
        }
        &__image {
          width: 100%;
          img {
            height: 143px;
            margin: auto;
            width: 100px;
          }
        }
        &__description {
          margin: 10px auto 0;
          max-width: 130px;
          padding-bottom: 60px;
        }
        &__name {
          color: $color-black;
          font-family: $optimaregular;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 1.3;
          margin-bottom: 4px;
        }
        &__sub-name {
          font-family: $optimaregular;
          margin-bottom: 5px;
          @media #{$medium-up} {
            font-size: 14px;
          }
        }
        &-select {
          #{$ldirection}: 0;
          bottom: 0;
          position: absolute;
          width: 100%;
          &__label {
            margin: 0;
            padding: 0;
            &::before,
            &::after {
              display: none;
            }
          }
          &__add-to-cart {
            border: 1px solid $color-blue-dark;
            font-size: 12px;
            height: 40px;
            padding: 0 5px;
            width: 100%;
            &.selected {
              background-color: $color-blue-dark;
              color: $color-white;
            }
          }
        }
        &__size {
          #{$ldirection}: 0;
          bottom: 40px;
          font-family: $optimaregular;
          margin-bottom: 8px;
          position: absolute;
          width: 100%;
          @media #{$medium-up} {
            font-size: 14px;
          }
        }
      }
      .slick-slide {
        margin: 0 12px 20px;
        position: relative;
        @media #{$medium-up} {
          margin: 0 8px 20px;
        }
        .sample {
          position: static;
        }
      }
      .slick-track {
        display: flex;
      }
      .slick-prev,
      .slick-next {
        height: 24px;
        margin-top: -20px;
        width: 24px;
        @media #{$medium-up} {
          height: 30px;
          margin-top: -30px;
          width: 30px;
        }
      }
      .slick-prev {
        #{$ldirection}: -19px;
        background: url('../../../../../media/images/cart_enhancement/arrow-previous.png') no-repeat
          center;
        background-size: contain;
        @media #{$medium-up} {
          #{$ldirection}: -30px;
        }
      }
      .slick-next {
        #{$rdirection}: -20px;
        background: url('../../../../../media/images/cart_enhancement/arrow-next.png') no-repeat
          center;
        background-size: contain;
        @media #{$medium-up} {
          #{$rdirection}: -32px;
        }
      }
      .slick-dots {
        #{$ldirection}: 0;
        bottom: -12px;
        line-height: 0;
        li {
          height: 12px;
          margin: 0 8px;
          width: 12px;
          button {
            height: 12px;
            line-height: 0;
            padding: 0;
            width: 12px;
            &::before {
              font-size: 12px;
              height: 12px;
              line-height: 1;
              width: 12px;
            }
          }
        }
      }
    }
  }
}
