.enhanced-cart-page {
  .checkout {
    #sticky-checkout-button {
      border: 0;
      padding: 13px 16px 16px;
      .go-shopping.hide-go-shopping {
        font-weight: bold;
        margin: 0;
      }
      .continue-checkout {
        font-weight: bold;
        margin-bottom: 0;
      }
      .continue-checkout.hide-checkout {
        display: none;
      }
    }
    #sticky-checkout-button-overlay {
      padding: 13px 16px 16px;
      .paypal-checkout-btn {
        background: $color-white url('/media/logos/paypal/paypal.gif') no-repeat center;
        .gwp-paypal-checkout__image {
          height: 0;
        }
      }
      .alipay-checkout-btn {
        background: $color-white url('/media/logos/alipay/alipay.gif') no-repeat center;
      }
      .afterpay-checkout-btn {
        background: $color-white url('/media/logos/afterpay/afterpay-logo-black.png') no-repeat
          center;
      }
      .paypal-checkout-btn,
      .alipay-checkout-btn,
      .afterpay-checkout-btn {
        background-size: 80px;
        border: 1px solid $color-navy;
        display: block;
        height: 48px;
        margin: 0 0 10px;
        width: 100%;
      }
      .checkout-buttons-container {
        & > div,
        #apple-pay-button-container,
        .paypal-buttons {
          margin-bottom: 10px;
        }
        .paypal-buttons {
          display: block;
        }
      }
      .alipay-checkout-btn,
      .afterpay-checkout-btn {
        display: none;
      }
    }
    .continue-button-wrapper {
      box-shadow: 0 -4px 8px 0 $color-dark-black-opacic;
      height: 105px;
      width: auto;
      .sticky-checkout {
        &__estimated {
          margin-bottom: 12px;
        }
        &__estimated-label,
        &__estimated-value {
          color: $color-black;
          font-family: $akzidenz;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }
        &__estimated-label {
          float: $ldirection;
          text-align: $ldirection;
        }
        &__estimated-value {
          float: $rdirection;
          text-align: $rdirection;
        }
        .hide-go-shopping {
          margin: 0;
        }
      }
      #apple-pay-button-container {
        height: 48px;
        margin-bottom: 3px;
        width: 100%;
        .apple-pay-button {
          background-position: center;
          background-size: 80px;
          height: 46px;
          min-width: 100%;
          position: static;
          width: 100%;
        }
      }
      .continue-checkout {
        display: block;
        font-weight: bold;
        height: 48px;
        width: 100%;
        .lock-icon {
          margin-#{$rdirection}: 4px;
          vertical-align: middle;
        }
      }
    }
    &__sidebar#checkout-sidebar {
      background-color: $color-white;
      display: block;
      padding: 0;
      @media #{$medium-up} {
        float: $ldirection;
        height: auto;
        width: 362px;
      }
      @media #{$device-ipad_only} {
        padding: 0;
        width: 100%;
      }
      @media #{$large-only} {
        padding-#{$rdirection}: 30px;
        width: 35%;
      }
      &::after {
        background: $color-white;
        width: 363px;
      }
      .top-viewcart-buttons {
        margin: -15px 0 0;
        @media #{$medium-up} {
          margin: 0;
          padding-bottom: 0;
        }
        .viewcart-buttons-panel {
          border: 1px solid $color-light-gray;
          border-bottom: 0;
          margin: 0;
          padding: 16px;
          .continue-buttons {
            .paypal-checkout-btn {
              background: $color-white url('/media/logos/paypal/paypal.gif') no-repeat center;
              background-size: 80px;
              border: 1px solid $color-navy;
              display: block;
              height: 40px;
              margin: 0 0 10px;
              width: 100%;
            }
            .alipay-checkout-btn,
            .afterpay-checkout-btn {
              display: none;
            }
            .continue-checkout {
              align-items: center;
              display: flex;
              height: 40px;
              justify-content: center;
              margin-top: 10px;
              width: 100%;
              .lock-icon {
                margin-#{$rdirection}: 4px;
                vertical-align: middle;
                width: 24px;
              }
            }
          }
        }
      }
      .order-summary-section {
        margin-top: 0;
        padding: 0;
        @media #{$medium-only} {
          border-top: 1px solid $color-light-gray;
          margin-top: 15px;
        }
        &__header {
          border: 0;
          padding: 0 16px 11px;
          @media #{$device-ipad_only} {
            border: 0;
            padding: 0 30px 11px;
          }
          @media #{$large-up} {
            border-#{$ldirection}: 1px solid $color-light-gray;
            border-#{$rdirection}: 1px solid $color-light-gray;
          }
          @media #{$large-only} {
            padding: 0 16px 11px;
          }
        }
        &__title {
          border-bottom: 0;
          color: $glux-color-submenu-text;
          font-family: $optimalight;
          font-size: 18px;
          font-weight: 600;
          height: auto;
          letter-spacing: 0;
          line-height: 0.7;
          margin: 0;
          padding: 14px 0 0;
          width: auto;
          @media #{$medium-up} {
            font-size: 26px;
            letter-spacing: 1px;
          }
        }
        &__subtitle {
          margin: 11px 0 0;
        }
        .order-summary {
          &__content {
            border-bottom: 1px solid $color-light-gray;
            display: block;
            max-height: 400px;
            overflow: hidden;
            @media #{$device-ipad_only} {
              border: 0;
            }
            @media #{$large-up} {
              border-#{$ldirection}: 1px solid $color-light-gray;
              border-#{$rdirection}: 1px solid $color-light-gray;
              border-bottom: 1px solid $color-light-gray;
            }
            .giftwrap-content {
              margin: 0 16px 8px;
              @media #{$device-ipad_only} {
                margin: 0 30px 7px;
              }
              @media #{$large-only} {
                margin: 0 16px 7px;
              }
            }
            .giftwrap.label,
            .giftwrap.value {
              margin: 0;
              padding: 0;
              width: 50%;
            }
            .giftwrap.label {
              font-family: $akzidenz;
            }
            .giftwrap.value {
              font-size: 14px;
              letter-spacing: 1.8px;
            }
            .afterpay.content {
              background-color: $color-lighter-gray;
              font-family: $akzidenz;
              font-size: 12px;
              letter-spacing: 0.51px;
              line-height: 1.6;
              margin: 0;
              padding: 0 16px 10px;
              text-transform: lowercase;
              width: 100%;
              img {
                vertical-align: middle;
              }
            }
            .afterpay-messages-container {
              @include swap_direction(margin, 18px 26px 16px 27px);
              letter-spacing: 1px;
              line-height: 1.45;
              .single-message {
                img {
                  vertical-align: middle;
                }
              }
            }
            .label.afterpay-messages-container {
              @include swap_direction(padding, 0 15px 14px 20px);
              background-color: $color-lighter-gray;
              margin: 0;
              text-transform: lowercase;
              width: 100%;
              @media #{$device-ipad_only} {
                @include swap_direction(padding, 0 0 10px 30px);
                margin: 0 30px;
                width: calc(100% - 60px);
              }
              @media #{$large-up} {
                @include swap_direction(padding, 0 0 10px 16px);
                font-size: 12px;
              }
              @media #{$large-only} {
                margin: 0;
                width: 100%;
              }
              .afterpay-learnmore {
                margin-#{$ldirection}: 5px;
                &__info {
                  font-size: 14px;
                  height: 20px;
                  line-height: 1.3;
                  width: 20px;
                  .info-icon {
                    vertical-align: middle;
                  }
                }
              }
            }
          }
          &__loyalty-points-description {
            @include swap_direction(padding, 14px 0 0 16px);
            color: $color-black;
            display: block !important;
            letter-spacing: 0;
            line-height: 1.3;
            margin: 0 0 16px;
            max-width: 301px;
            @media #{$device-ipad_only} {
              @include swap_direction(padding, 12px 0 14px 30px);
              margin: 0;
            }
            @media #{$large-up} {
              @include swap_direction(padding, 12px 0 14px 16px);
              letter-spacing: 1px;
              margin: 0;
            }
            .disclaimer {
              display: inline;
            }
          }
          &__subtotal-wrapper,
          &__shipping-wrapper,
          &__tax-wrapper {
            margin: 0 16px 8px;
            @media #{$device-ipad_only} {
              margin: 0 30px 7px;
            }
            @media #{$large-up} {
              margin: 0 16px 7px;
            }
            .tax {
              display: ruby;
              width: 60%;
              text-wrap: balance;
              @media #{$medium-up} {
                width: auto;
              }
            }
          }
          &__tax-wrapper {
            .label,
            .value {
              margin: 0;
              padding: 0;
            }
          }
          &__subtotal-label {
            color: $color-black;
            font-family: $akzidenz;
            font-size: 12px;
            font-weight: normal;
            height: 16px;
            letter-spacing: 1.8px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            width: 50%;
          }
          &__shipping {
            float: none;
            margin-bottom: 15px;
            padding: 0 16px;
            width: 100%;
            @media #{$medium-up} {
              margin-bottom: 16px;
            }
            @media #{$device-ipad_only} {
              padding: 0 30px;
            }
            @media #{$large-only} {
              padding: 0 16px;
            }
            .container {
              .ship-method {
                &__details {
                  @include swap_direction(padding, 13px 25px 11px 16px);
                  background: $color-white
                    url('../../../../../media/images/cart_enhancement/down-arrow.png') no-repeat;
                  background-position: $rdirection 16px center;
                  background-size: 9px;
                  color: $glux-color-submenu-text;
                  font-family: $akzidenz;
                  font-size: 12px;
                  font-weight: normal;
                  height: 44px;
                  letter-spacing: 1.8px;
                  line-height: 1.6;
                  margin-top: 4px;
                  text-overflow: ellipsis;
                  text-transform: uppercase;
                  @media #{$medium-up} {
                    margin-top: 5px;
                    padding-#{$rdirection}: 40px;
                  }
                }
              }
            }
          }
          &__shipping-label {
            clear: $ldirection;
            color: $color-black;
            float: $ldirection;
            font-family: $akzidenz;
            font-size: 12px;
            font-weight: normal;
            height: 16px;
            letter-spacing: 1.8px;
            line-height: 1.4;
            text-transform: uppercase;
            width: 50%;
          }
          &__subtotal-value,
          &__shipping-value {
            font-size: 14px;
            letter-spacing: 1.8px;
            line-height: 1.4;
            margin-bottom: 0;
            padding: 0;
            width: 50%;
          }
          &__total-wrapper {
            @media #{$device-ipad_only} {
              padding: 0 30px;
            }
            @media #{$large-only} {
              padding: 0;
            }
          }
          &__total-label,
          &__total-value {
            margin: 0;
            padding: 14px 0 6px;
            @media #{$medium-up} {
              padding: 12px 0 7px;
            }
          }
          &__total-label {
            width: 60%;
            @media #{$large-only} {
              font-size: 10px;
              width: 56%;
            }
            .total-label__wrapper {
              font-family: $akzidenz;
              font-weight: bold;
              margin-#{$ldirection}: 20px;
              @media #{$medium-up} {
                margin-#{$ldirection}: 16px;
              }
              @media #{$device-ipad_only} {
                margin-#{$ldirection}: 30px;
              }
              @media #{$large-only} {
                margin-#{$ldirection}: 16px;
              }
            }
          }
          &__total-value {
            font-size: 14px;
            width: 40%;
            @media #{$large-only} {
              width: 44%;
            }
            .total-value__wrapper {
              margin-#{$rdirection}: 12px;
              letter-spacing: 1.8px;
              @media #{$medium-up} {
                margin-#{$rdirection}: 16px;
              }
              @media #{$device-ipad_only} {
                margin-#{$rdirection}: 30px;
              }
              @media #{$large-only} {
                margin-#{$rdirection}: 16px;
              }
            }
          }
          &__discount-label {
            padding-#{$ldirection}: 16px;
          }
          &__discount-value {
            padding-#{$rdirection}: 16px;
          }
        }
      }
      .panel--offer-code {
        &__title {
          color: $color-navy;
          font-family: $optimaregular;
          font-size: 16px;
          font-weight: 600;
          height: 16px;
          letter-spacing: 0;
          line-height: 1.3;
          .title-icon {
            display: none;
          }
        }
        &__disclaimer {
          font-size: 12px;
          font-family: $akzidenz;
          color: $color-grey-medium;
          padding: 5px 0;
        }
        &__messages {
          &.success {
            color: $color-success;
            font-size: 12px;
            line-height: 1.5em;
          }
        }
        .loyalty-offer-code-panel {
          @media #{$large-up} {
            border-#{$ldirection}: 1px solid $color-light-gray;
            border-#{$rdirection}: 1px solid $color-light-gray;
            border-top: 0;
            margin-top: 0;
          }
          .panel--offer-code__header {
            padding: 14px 16px 16px;
            @media #{$medium-only} {
              padding: 14px 30px 16px;
            }
          }
        }
        .offer-code-panel__content {
          border-bottom: 1px solid $color-light-gray;
          .offer-code {
            &__byline {
              @include swap_direction(padding, 21px 0 0 26px);
              color: $color-navy;
              display: none;
              font-family: $optimalight;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1;
              .title-icon {
                #{$rdirection}: 8px;
              }
              &.open {
                .title-icon {
                  #{$rdirection}: 20px;
                }
              }
            }
          }
          .checkout__offer-code {
            &__wallet-count {
              display: none;
            }
            &__wallet {
              padding: 0 16px 46px;
              @media #{$medium-only} {
                padding: 0 30px 46px;
              }
              .slick-prev,
              .slick-next {
                bottom: 17px;
                height: 14px;
                top: auto;
                width: 20px;
                &::before {
                  content: '';
                }
              }
              .slick-prev {
                #{$ldirection}: 16px;
                background: url('../../../../../media/images/cart_enhancement/arrow-left.png')
                  center no-repeat;
                background-size: contain;
              }
              .slick-next {
                #{$rdirection}: 16px;
                background: url('../../../../../media/images/cart_enhancement/arrow-right.png')
                  center no-repeat;
                background-size: contain;
              }
              .slick-dots {
                #{$ldirection}: 0;
                #{$rdirection}: 0;
                bottom: 17px;
                display: block !important;
                margin: auto;
                top: auto;
                width: 80%;
                li {
                  height: 12px;
                  margin: 0 8px;
                  width: 12px;
                  button {
                    height: 12px;
                    padding: 0;
                    width: 12px;
                    &::before {
                      #{$ldirection}: 0;
                      font-size: 12px;
                      height: 12px;
                      line-height: 1;
                      margin: auto;
                      width: 12px;
                    }
                  }
                }
              }
              .slick-disabled {
                opacity: 0.32;
              }
            }
            &__wallet-item {
              background-color: $color-white;
              border: 1px solid $color-light-gray;
              border-radius: 0;
              padding: 16px 15px 0;
              &__image-container {
                float: $ldirection;
                height: 47px;
                width: 47px;
              }
              &__image {
                width: 100%;
                height: 47px;
              }
              &__header,
              &__description {
                font-family: $akzidenz;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.4px;
                line-height: 1.3;
                margin-#{$ldirection}: 60px;
                text-align: $ldirection;
              }
              &__header {
                color: $color-black-darker-transparent;
                font-weight: 600;
              }
              &__description {
                color: $color-black-dark-transparent;
                .loyalty__panel__offers__offer-code-wrap {
                  text-align: $ldirection;
                }
              }
              &__button-container {
                margin-top: 0;
                .checkout__offer-code__wallet-apply {
                  height: 40px;
                  line-height: 2;
                  margin-top: 13px;
                  padding: 9px 0 7px;
                  width: 100%;
                }
              }
              &__link-container {
                margin-top: 16px;
              }
            }
          }
        }
        .offer-code__form {
          padding: 21px 16px;
          @media #{$medium-up} {
            border-#{$ldirection}: 1px solid $color-light-gray;
            border-#{$rdirection}: 1px solid $color-light-gray;
            padding: 21px 16px;
          }
          @media #{$device-ipad_only} {
            padding: 16px 0;
          }
          @media #{$medium-only} {
            border: 0;
            border-top: 1px solid $color-light-gray;
            margin-bottom: 22px;
            padding: 12px 30px 0;
          }
          @media #{$large-only} {
            padding: 21px 16px;
          }
          &-copy {
            font-size: 12px;
            letter-spacing: 0.05em;
            color: $color-grey-medium;
            padding-top: 3px;
          }
          #offer_code {
            @media #{$medium-only} {
              padding-top: 10px;
            }
            .offer-code__code {
              padding-#{$rdirection}: 0;
              width: 67%;
              @media #{$medium-up} {
                width: 64%;
              }
              label {
                opacity: 1;
              }
              .offer-code-field {
                height: 48px;
                padding-#{$ldirection}: 15px;
                &:focus + label::before {
                  @include swap_direction(padding, 0 10px 0 6px);
                  margin: 0 10px;
                  transform: translateY(-1.8em) scale(0.8, 0.8);
                  @media #{$medium-up} {
                    transform: translateY(-2em) scale(0.8, 0.8);
                  }
                }
              }
              .cart-label-mode + label::before {
                @include swap_direction(padding, 0 10px 0 6px);
                margin: 0 10px;
                transform: translateY(-1.8em) scale(0.8, 0.8);
                @media #{$medium-up} {
                  transform: translateY(-2em) scale(0.8, 0.8);
                }
              }
            }
            .form-submit {
              background-color: $color-white;
              color: $color-navy;
              height: 48px;
              width: 30%;
              @media #{$medium-up} {
                padding: 0 5px;
                width: 33%;
              }
            }
          }
          .offer-code {
            &__one-offer-only,
            &__see-all-offers {
              display: none;
            }
          }
        }
        &__header {
          @include swap_direction(padding, 14px 0 16px 16px);
          border-top: 1px solid $color-light-gray;
        }
      }
      .links-panel-content {
        border: 0;
        margin-top: 19px;
        padding: 0 16px;
        @media #{$device-ipad_only} {
          margin: 0 0 50px;
          padding: 0 30px;
        }
        @media #{$medium-only} {
          margin: 19px 0 30px;
        }
        @media #{$large-up} {
          margin: 19px 0 0;
          padding: 0;
        }
        &__wrapper {
          &--text {
            color: $color-black-dark-transparent;
            font-family: $akzidenz;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 1.4;
            max-width: 342px;
            @media #{$medium-only} {
              max-width: 100%;
            }
            .links-panel__items {
              color: $color-black-dark-transparent;
              text-decoration: underline;
            }
            .overlay-link {
              color: $color-black-dark-transparent;
              font-size: 14px;
              font-weight: 100;
              letter-spacing: 0.25px;
              text-transform: none;
              width: auto;
            }
          }
        }
      }
    }
    &__sidebar--wrapper {
      border: 0;
    }
  }
  .express-payments-disclaimer {
    display: block;
    color: $color-black-dark-transparent;
    font-size: 12px;
    margin-top: 5px;
    padding-top: 5px;
    text-align: center;
  }
  .payments-icon {
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    margin: 10px auto 0;
    padding: 0 10px;
    @media #{$large-only} {
      padding: 0;
    }
    .master-card {
      background: url('/media/images/cart_enhancement/mastercard.png') no-repeat;
    }
    .visa-card {
      background: url('/media/images/cart_enhancement/visa.png') no-repeat;
    }
    .discover-card {
      background: url('/media/images/cart_enhancement/discover.png') no-repeat;
    }
    .american-express {
      background: url('/media/images/cart_enhancement/ae.png') no-repeat;
    }
    .master-card,
    .visa-card,
    .discover-card,
    .american-express {
      background-position: center;
      background-size: 100%;
      height: 16px;
      margin: 0 7px;
      max-width: 32px;
      width: 24px;
      @media #{$large-only} {
        margin: 0 4px;
      }
    }
    .afterpay {
      margin: 0 7px;
      @media #{$large-only} {
        margin: 0 4px;
      }
      &-img {
        vertical-align: middle;
        width: 58px;
        @media #{$large-only} {
          width: 50px;
        }
      }
    }
    .alipay-img {
      margin: 0 7px;
      width: 44px;
      @media #{$large-only} {
        margin: 0 4px;
        width: 35px;
      }
    }
  }
}

#viewcart {
  .checkout {
    .checkout-sidebar {
      #top-viewcart-buttons {
        display: block;
      }
    }
  }
  .viewcart-empty {
    .checkout__sidebar {
      #top-viewcart-buttons {
        display: none;
      }
    }
    &.viewcart-has-promo {
      .links-panel-content {
        @media #{$large-up} {
          margin: 40% 0 0;
        }
      }
    }
    .links-panel-content {
      border: 0;
      margin: 19px 0 24px;
      padding: 0 16px;
      @media #{$medium-up} {
        margin: 0;
      }
      @media #{$device-ipad_only} {
        margin: 0 0 50px;
        padding: 0 30px;
      }
      @media #{$medium-only} {
        margin: 19px 0 10px;
        padding: 0;
      }
      @media #{$large-up} {
        margin: 19px 0 0;
        padding: 0;
      }
      &__wrapper {
        &--text {
          color: $color-black-dark-transparent;
          font-family: $akzidenz;
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 1.4;
          max-width: 342px;
          @media #{$medium-only} {
            max-width: 100%;
          }
          .links-panel__items {
            color: $color-black-dark-transparent;
            text-decoration: underline;
          }
          .overlay-link {
            color: $color-black-dark-transparent;
            font-size: 14px;
            font-weight: 100;
            letter-spacing: 0.25px;
            text-transform: none;
            width: auto;
          }
        }
      }
    }
  }
}
