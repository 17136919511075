///
/// @file drupal_modules/esteelauder_common/_franchise_catlinks.scss
/// Styles for additional links on mpp franchise pages
///
/// \brief Styling for template at:
///   esteelauder/modules/custom/esteelauder_common/templates/franchise_catlinks_v1
///   styles expect markup eg ul.links-column links-col-1, ul.links-column links-col-2
///

.mpp__category-links {
  @include swap_direction(padding, 1em 2em);
  @media #{$medium-up} {
    width: 800px;
    @include swap_direction(margin, 0 auto);
    @include swap_direction(padding, 3em 0);
  }
  a:hover {
    text-decoration: underline;
  }
}

.mpp__category-links--content {
  font-family: $optimalight;
  font-size: 20px;
  .links-column {
    width: 50%;
    display: block;
    float: $ldirection;
    li {
      @include swap_direction(padding, 1em 1em 0 0);
      @media #{$medium-up} {
        @include swap_direction(padding, 0.5em 1em 0 0);
      }
    }
  }
  @media #{$medium-up} {
    font-size: 50px;
  }
}
