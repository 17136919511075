// spp product
.product-full {
  @include pie-clearfix;
  @include text-product-anchor;
  position: relative;

  #{$estee_edit} & {
    padding-top: 50px; // clear the subnav
  }
  @media #{$large-up} {
    margin-top: -$header-collapsed-height;
  }
  ul.shade-list {
    width: 100%;
    max-width: 343px;
    #{$estee_edit} & {
      width: 100%;
      max-width: 343px;
      margin: 16px auto 0 auto;
    }
    margin-#{$ldirection}: 0;
  }
  #{$renutriv} & {
    font-family: $roboto-condensed;
  }
  #{$aerin} .page-main & {
    font-family: 'Futura Now Medium';
  }
  #{$estee_edit} & {
    font-family: $ffmarkweb;
    background: url(/media/export/cms/products/bg/product_bg_tee_818px.jpg) repeat-x top left transparent;
    &.non-bg {
      background: $color-white;
    }
  }
  .cta {
    #{$renutriv} & {
      font: 14px $helvetica-neue;
    }
  }
  .product-full__image-container.product-full__image-container-white {
    .product-full__image {
      background: white;
    }
  }
}

.spp-with-form-overlay .shade-list li:first-child {
  display: none;
}

.product-full__misc-flag {
  font-size: 12px;
  font-family: 'AkzidenzGrotesk W1G';
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-weight: bold;
  #{$renutriv} & {
    font-family: $roboto-condensed;
  }
  #{$aerin} .page-main & {
    font-family: 'Futura Now Medium';
    font-size: 12px;
    margin-bottom: 10px;
  }
  #{$estee_edit} .page-main & {
    margin-bottom: 4px;
    font-family: $ffmarkweb;
  }
}

.product-full_pre-order-text {
  display: none;
}

.product-full h3,
.product-full__title {
  @include pie-clearfix;
  margin-bottom: 5px;
  line-height: 50px;
  #{$renutriv} & {
    font-family: $bb-roman;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-weight: lighter;
    font-size: 40px;
  }
  #{$estee_edit} .page-main & {
    font-family: $ffmarkweb-medium;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  span {
    display: inline-block;
  }
}

.product-full__subtitle {
  #{$renutriv} & {
    @include renutriv-copy;
    font-size: 20px;
  }
  #{$estee_edit} .page-main & {
    font-family: $ffmarkweb;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    margin-bottom: 4px;
  }
}

.product-full__image-container {
  float: $ldirection;
  width: 47%;
  position: relative;
  overflow: hidden;
  @media #{$large-up} {
    width: 61%;
    margin-#{$rdirection}: 2%;
  }
  @media #{$xlarge-up} {
    width: 67%;
    margin-#{$rdirection}: 0;
  }
  // slick overrides
  .slick-dots {
    z-index: 2;
    bottom: 61px;
    #{$estee_edit} & {
      bottom: 50px;
    }
    li {
      height: auto;
    }
  }
  .slick-dots li button {
    @include swap_direction(padding, 5px);
  }
  .slick-dots li button:before {
    @include border-radius(10px);
    content: '';
    @include swap_direction(margin, 6px);
    width: 8px;
    height: 8px;
    opacity: 1;
    background: #a7a2a6;
    @include swap_direction(border, 1px solid #f7f6fb);
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    background: $color-black;
  }
}

#{$estee-edit} .non-bg .product-full__images .product-full__image {
  background: $color-white;
}

.product-full__images {
  .product-full__image {
    #{$renutriv} & {
      background: black;
    }
    #{$aerin} & {
      background: white;
    }
    #{$estee-edit} & {
      background: url(/media/export/cms/products/bg/product_bg_tee_768px.jpg) repeat-x bottom left transparent;
    }
    &.smoosh {
      background: $color-white;
      #{$renutriv} & {
        background: black;
      }
    }
    &.smoosh-image {
      background: $color-white;
      @media #{$large-up} {
        img {
          max-width: 508px;
        }
      }
    }
    img {
      @include swap_direction(margin, 0 auto);
      display: block;
      max-width: 558px;
      width: 100%;
      height: auto;
      #{$ie8} & {
        height: 768px;
      }
    }
  }
  .product-full__image__thumb {
    width: 40px;
    height: 40px;
    border: 1px solid $color-white;
    margin-left: 6px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 105%;
    background-position: 0 -6px;
    background-color: $color-light-gray;
  }
  .slick-active {
    .product-full__image__thumb {
      border: 1px solid $color-navy;
    }
  }
}

.product-full__description {
  position: relative;
  z-index: 3;
  float: $ldirection;
  width: 52%;
  @include swap_direction(padding, 31px 4% 0 4%);
  color: $color-navy;
  #{$estee_edit} & {
    padding: 31px 4% 120px 4%;
  }
  @media #{$large-up} {
    width: 33%;
    @include swap_direction(padding, 41px 0 0 0);
    margin-top: $header-collapsed-height;
    #{$estee_edit} & {
      height: $spp-pc-height - $header-collapsed-height;
      padding: 41px 0 120px 0;
    }
    position: relative;
  }
  @media #{$xlarge-up} {
    margin-#{$ldirection}: -8%;
  }
  #{$renutriv} & {
    color: white;
  }
  #{$aerin} & {
    color: $color-periwinkle-blue--aerin;
  }
}

.product-full__description-section {
  @include swap_direction(padding, 0);
  .BVRRPrimarySummary {
    margin-top: 10px;
  }

  #{$estee_edit} & {
    text-align: center;
    #BVRRSummaryContainer {
      margin-bottom: 14px;
    }
    .BVRRPrimarySummary {
      margin-top: 0;
    }
  }
}

.product-full__review {
  @include text--short;
  margin-top: 20px;
}

.product-full__price-size {
  @include pie-clearfix;
  #{$estee_edit} & {
    text-align: center;
    padding: 20px 0 6px 0;
  }
  @include swap_direction(padding, 20px 0 10px 0);
}

.product-full__price-size .product__inventory-status {
  float: $ldirection;
  margin-#{$ldirection}: 20px;
  height: 18px;
  padding-top: 2px;
  #{$estee_edit} & {
    float: none;
    height: auto;
  }
}

.product-full__price-text {
  #{$estee_edit} & {
    float: none;
  }
  float: $ldirection;
}

.product-full__attribute {
  margin-top: 25px;
}

.product-full__attribute h3 {
  @include text--bold;
  margin-bottom: 0px;
  #{$renutriv} & {
    font-family: $roboto-condensed;
    font-weight: bold;
  }
}

.product-full__attribute p {
  @include text--short;
  margin-top: 5px;
  #{$renutriv} & {
    font-family: $roboto-condensed;
    text-transform: none;
  }
  #{$estee_edit} & {
    font-family: $devinne-roman;
    font-size: 24px;
    text-transform: none;
    margin: 0;
    letter-spacing: 0;
  }
}

.product-full__shade-filter {
  @include pie-clearfix;
  display: none;
  width: 100%;
  @include swap_direction(padding, 28px 0 8px 0);
  border-bottom: 1px solid $color-light-gray;
  &.is_intensity,
  &.is_misc_flag {
    display: block;
  }
  li {
    text-transform: uppercase;
    float: $ldirection;
    font-size: 12px;
    margin-#{$rdirection}: 22px;
    margin-bottom: 5px;
    cursor: pointer;
    &:last-child {
      margin-#{$rdirection}: 0;
    }
    &.is_selected {
      font-weight: bold;
    }
  }
}

.product-replenishment-select__info {
  text-transform: uppercase;
  margin: 6px 0;
}

.product-replenishment-select__label {
  text-transform: uppercase;
  font-weight: normal;
  white-space: nowrap;
  padding-right: 1em;
}

.product-replenishment-select__more {
  text-decoration: underline;
  font-weight: bold;
}

.product-replenishment-select__item {
  text-transform: uppercase;
}

.product-replenishment-select__select.selectBox {
  border-color: black;
}

.product-full__sku-menu {
  display: block;
  margin-top: 10px;
}

.product-full__button-container {
  @include pie-clearfix;
  margin-top: 11px;
  #{$estee_edit} & {
    text-align: left;
  }
}

.product-full__button-inner {
  #{$estee_edit} & {
    width: 100%;
    max-width: 328px;
    margin: 0 auto;
  }
}

.product-full__add-button {
  float: $ldirection;
  display: block;
  #{$estee_edit} & {
    font-family: $ffmarkweb-medium;
    font-size: 14px;
    letter-spacing: 0.015em;
    width: 48%;
    margin-left: 4%;
  }
  margin-#{$ldirection}: 10px;
  @include swap_direction(border, 0);
}

//custom selects
.product-full__shade-select-container {
  margin-top: 10px;
  #{$estee_edit} & {
    margin-top: 6px;
  }
}

.product-full__shade-select {
  width: 100%;
  max-width: 328px;
}

.product-full__mini-bag-shade-select {
  width: 236px;
}

.product-full__skintype-select-container {
  margin-top: 10px;
}

.product-full__skintype-select {
  width: 328px;
}

.product-full__price-size-select-container {
  margin-top: 11px;
}

.product-full__price-size-select {
  width: 328px;
}

a.selectbox.product-full__quantity .product-full__quantity {
  float: $ldirection;
  width: 125px;
}

body.page-product .product-full__price-size-select-selectBox-dropdown-menu {
  &.selectBox-options li a {
    @include swap_direction(padding, 0 0 0 10px);
    font-size: 14px;
  }
}

body.page-product .product-full__quantity-selectBox-dropdown-menu {
  &.selectBox-options li a {
    @include swap_direction(padding, 0 0 0 10px);
  }
}

.product-full__personal {
  padding-top: 10px;
}

.product-full__detail-link,
.product-full__share,
.product-full__wishlist {
  @include inline-block;
  @include link--bold;
  margin-#{$rdirection}: 30px;
  #{$renutriv} & {
    font-family: $roboto-condensed;
  }
  #{$aerin} .page-main & {
    font-family: 'Futura Now Medium';
  }
  #{$estee_edit} & {
    font-family: $ffmarkweb;
    font-size: 12px;
    font-weight: normal;
    margin-right: 0;
  }
}

.product-full__detail-link {
  #{$estee_edit} & {
    margin: 28px 0 0 0;
  }
}

.product-full select option[disabled] {
  display: none;
}

.product-full__price-size-select-selectBox-dropdown-menu {
  .selectBox-disabled {
    display: none;
  }
}
/* EDD */
.free_standard_shipping_returns {
  color: #040a2b;
  font-family: 'AkzidenzGrotesk W1G';
  font-size: 12px !important;
  font-weight: bold;
  letter-spacing: 0.15em;
  @include swap_direction(padding, 15px 0 0 0);
  text-transform: uppercase;
  #{$estee_edit} & {
    font-family: $ffmarkweb-medium;
    font-weight: normal;
    letter-spacing: 0.015em;
  }
}

.brand-aerin .free_standard_shipping_returns {
  @include swap_direction(padding, 17px 0 0 0);
}

.edd {
  width: 280px;
  #{$estee_edit} & {
    margin: 0 auto;
  }
  a.show_more {
    text-decoration: underline;
  }
  &.hide {
    visibility: hidden;
    #{$estee_edit} & {
      display: none;
    }
  }
  .edd_info {
    p {
      @include swap_direction(margin, 0);
      display: inline;
      line-height: 15px;
      font-size: 14px;
    }
    a.know_more {
      text-decoration: underline;
    }
  }
}

.colorbox_edd_know_more {
  .content {
    h2 {
      font-size: 30px;
      line-height: 32px;
      margin-bottom: 20px;
      letter-spacing: -0.02em;
    }
  }
}

.brand-renutriv .page-wrapper .menu-container a {
  color: #9eafff;
}

body.brand-renutriv {
  .free_standard_shipping_returns {
    color: white;
  }
  p.copy {
    font-family: helvetica-neue;
  }
}
/* END OF EDD */

/* Social Share Icons */
.product-full__personal {
  .social-share-icons {
    .social-share__link {
      @include inline-block;
      margin-#{$rdirection}: 12px;
      &.email {
        background-size: auto auto;
        background-position: 0 -9036px;
        height: 16px;
        width: 22px;
      }
      &.facebook {
        background-size: auto auto;
        background-position: 0 -9227px;
        height: 19px;
        width: 11px;
      }
      &.twitter {
        background-size: auto auto;
        background-position: 0 -9612px;
        height: 19px;
        width: 22px;
      }
      &.pinterest {
        background-size: auto auto;
        background-position: 0 -9421px;
        height: 19px;
        width: 19px;
      }
    }
  }
  #{$estee_edit} & {
    position: absolute;
    bottom: 66px;
    width: 100%;
  }
}
/* SPP POPUP V2 */

.edd a.show_more {
  display: none;
}

.order_arrival {
  #order_arrival_info {
    #{$estee_edit} & {
      font-family: $ffmarkweb;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.05em;
    }
    @include swap_direction(margin, 0);
    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.brand-renutriv {
  .product-full__personal {
    .social-share-icons {
      .social-share__link {
        @include inline-block;
        margin-#{$rdirection}: 12px;
        &.email {
          background-size: auto auto;
          background-position: 0 -8995px;
          height: 16px;
          width: 22px;
        }
        &.facebook {
          background-size: auto auto;
          background-position: 0 -9183px;
          height: 19px;
          width: 11px;
        }
        &.twitter {
          background-size: auto auto;
          background-position: 0 -9568px;
          height: 19px;
          width: 22px;
        }
        &.pinterest {
          background-size: auto auto;
          background-position: 0 -9377px;
          height: 19px;
          width: 19px;
        }
      }
    }
  }
}

.order_arrival_details_overlay {
  display: none;
}

.order_arrival_details {
  .title {
    font-size: 43px;
    color: #06062e;
    @include swap_direction(margin, 0 0 20px 0);
    line-height: 46px;
    font-family: $optimalight;
    text-transform: capitalize;
  }
  .postal_code {
    @include swap_direction(margin, 20px 0 35px 0);
    input {
      width: 216px;
      color: #000 !important;
      margin-#{$rdirection}: 4px;
      height: 45px;
      text-transform: uppercase;
      font-weight: bold;
    }
    .apply {
      width: 108px;
    }
  }
  input[type='text']::-moz-placeholder {
    color: #000;
  }
  .shipment_type {
    float: $ldirection;
    width: 50%;
  }
  .arrival_date {
    float: $ldirection;
    width: 50%;
  }
  .header {
    font-size: 14px;
    font-weight: bold;
    height: 25px;
    display: none;
    text-transform: uppercase;
  }
  .row {
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
  p {
    line-height: 18px;
    @include swap_direction(margin, 0);
    font-size: 14px;
  }
  .order_delivery_info {
    margin-bottom: 22px;
    text-transform: uppercase;
  }
  .invalid_zipcode {
    color: #b50404;
    margin-top: 15px;
  }
  .order_delivery_info .ship_us_territory,
  .order_delivery_info .ship_us_mil_pobox,
  .order_delivery_info.valid_zipcode .ship_us_std,
  .order_delivery_info .ship_us_std_exact {
    display: none;
  }
  .order_delivery_info.valid_zipcode .ship_us_std_exact {
    display: block;
  }
}

#colorbox.edd_popover,
.edd_popover cboxWrapper {
  width: 640px !important;
  height: 590px !important;
}

.edd_popover #cboxContent,
.edd_popover #cboxLoadedContent {
  width: 570px !important;
  height: 490px !important;
}

HTML[data-useragent*='MSIE 8.0'] #colorbox.edd_popover #cboxWrapper #cboxLoadedContent {
  width: 570px !important;
}

#colorbox.edd_popover.empty_zipcode,
.edd_popover.empty_zipcode cboxWrapper {
  height: 650px !important;
}

.edd_popover.empty_zipcode #cboxContent,
.edd_popover.empty_zipcode #cboxLoadedContent {
  height: 550px !important;
}

.edd_popover #cboxContent {
  @include swap_direction(padding, 45px 35px);
}
/* END OF SPP EDD V2 */
/* Mass Relevance */
div.social-shop__content iframe {
  max-width: 1500px;
  @include swap_direction(margin, 0 auto);
}

div.mr-space {
  max-width: 1500px;
}

.product {
  &__price {
    &--non-sale {
      text-decoration: line-through;
      margin-right: 5px;
      font-weight: bold;
      .mpp--brand-estee_edit & {
        font-weight: normal;
      }
    }
    &--sale {
      color: $color-red;
      font-weight: bold;
      margin-right: 5px;
      .mpp--brand-estee_edit & {
        font-weight: normal;
      }
      display: inline-block;
    }
    &--discount-sale {
      font-weight: bold;
    }
  }
}

.selectBox-label.option-discount-sale {
  letter-spacing: 0px;
  padding: 0 5px;
}

.spp-product__mini-bag-price-size .product__price--sale {
  display: inline-block;
}

//Down price MPP
a.selectBox.down-price-selectbox,
ul.down-price-selectbox {
  font-size: 11px;
  .product {
    &__price {
      &--non-sale,
      &--sale {
        font-size: 11px;
      }
    }
  }
}

a.selectBox.down-price-selectbox {
  padding: 0 0 20px 0;
}

a.selectBox.search-down-price-selectbox {
  width: auto;
}

.colorbox__spp-video .cboxIframe {
  padding: 40px;
  text-align: center;
}
/* End Mass Relevance */

/* Engraving Styles Overlay */
.product-full__engraving-button {
  margin-top: 10px;
  .engraving {
    display: inline-block;
  }
  .monogramming {
    display: none;
  }
  #{$aerin} & {
    .engraving {
      display: none;
    }
    .monogramming {
      display: inline-block;
    }
  }
}
//Placeholder mixin for Engraving Overlay
@mixin placeholder-engraving {
  font-size: 12px;
  font-family: $akzidenz;
  color: $color-navy;
  line-height: 40px;
  letter-spacing: 0.15em;
  #{$aerin} & {
    font-size: 12px;
    font-family: $akzidenz;
    color: $color-periwinkle-blue--aerin;
    letter-spacing: 0.15em;
    text-indent: 0;
  }
}

.engraving-preview-popup {
  #cboxContent {
    @include swap_direction(padding, 0);
  }
  #cboxClose,
  #cboxClose:hover {
    #{$rdirection}: 22px !important;
    top: 20px;
    background-position: 0 -973px;
  }
  #cboxClose {
    @include svg-icon-inline-mask('close.svg');
    background: $color-navy;
  }
}

.page-product {
  .add_cart_response {
    #cboxClose,
    #cboxClose:hover {
      background: url('/media/images/global/sprites-s38a9885fce.png') no-repeat 0 -973px;
      #{$rdirection}: 10px;
      top: 10px;
    }
  }
}

.colorbox__youtube {
  #cboxClose {
    @include svg-icon-inline-mask('close.svg');
    background: $color-white;
  }
}

.colorbox__quickshop {
  #cboxClose {
    @include svg-icon-inline-mask('close.svg');
    background: $color-navy;
  }
}

#viewcart {
  .engraving-preview-popup {
    #cboxContent {
      padding: 0 !important;
    }
    .cboxLoadedContent {
      height: 518px !important;
      width: 725px !important;
    }
  }
  .engraving-wrap {
    .monogramming {
      display: none;
    }
  }
}

.section-product {
  .spp-engraving {
    &__engraving--form-heading {
      &.engraving {
        display: none;
      }
    }
    &__engraving-tab--item {
      display: none;
    }
  }
  .engraving-wrap {
    .engraving {
      display: none;
    }
  }
}

.spp-engraving {
  overflow: hidden;
  position: relative;
  max-width: 725px;
  margin: 0 auto;
  .monogramming {
    &.button {
      display: none;
    }
  }
  &__engraving-tab {
    display: none;
  }
  &__engraving--form-font-choice-btn {
    .engraving,
    .engraving-script {
      &.button {
        display: none !important;
      }
    }
  }
  #{$aerin} & {
    .engraving {
      display: none;
    }
    .monogramming {
      display: block;
    }
  }
  .error_messages_display {
    @include swap_direction(padding, 0 0 0 5px);
    @include swap_direction(margin, 10px 0);
    font-size: 12px;
    color: $color-red;
    text-align: #{$ldirection};
  }
  &__background--image {
    height: 518px;
    width: 725px;
    background-size: 100% 100%;
  }
  &__background--content {
    float: #{$ldirection};
  }
  &__engraving--forms {
    text-align: center;
    width: 360px;
    @include swap_direction(margin, 38px 0 0 36px);
  }
  &__engraving--form {
    text-align: #{$ldirection};
  }
  &__engraving--form-heading {
    > h1 {
      font-size: 50px;
      margin-bottom: 15px;
      text-transform: capitalize;
      letter-spacing: -0.03em;
      #{$aerin} & {
        font-family: $futura_now_reg;
        @include swap_direction(padding, 3px 0 0 3px);
        letter-spacing: -0.04em;
        margin-bottom: 18px;
      }
    }
  }
  &__engraving--form-font-choice-btn {
    padding-#{$ldirection}: 3px;
    margin-bottom: 13px;
    .change-font {
      @include swap_direction(padding, 0);
      background: $color-white;
      color: $color-navy;
      border: 1px solid $color-light-gray;
      text-transform: capitalize;
      font-size: 14px;
      height: 39px;
      line-height: 36px;
      @include swap_direction(margin, 0 1%);
      width: 31%;
      letter-spacing: -0.05em;
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(8) {
        @include swap_direction(margin, 0);
      }
      &:nth-child(2) {
        @include swap_direction(margin, 0 3.5%);
      }
      #{$aerin} & {
        color: $color-periwinkle-blue--aerin;
      }
      &.engrave-choice-script {
        font-size: 29px;
        margin-#{$ldirection}: 0px;
        line-height: 34px;
        letter-spacing: -0.03em;
        text-indent: -6px;
      }
      &.engrave-style-chosen {
        border: 2px solid $color-navy;
        #{$aerin} & {
          border: 2px solid $color-periwinkle-blue--aerin;
        }
      }
      &.engraving-roman {
        text-transform: uppercase;
      }
      form.engraving-form-choice-1 &,
      form.engraving-form-choice-41 & {
        &.monogramming {
          display: inline-block;
        }
        .button-copy-capitalise {
          &.monogram-romams,
          &.monogram-script {
            vertical-align: top;
          }
          &.monogram-romams {
            font-size: 17px;
          }
          &.monogram-script {
            font-size: 20px;
            text-transform: uppercase;
          }
        }
        &.engrave-choice-roman,
        &.monogram-choice-lower {
          @include swap_direction(margin, 0 2.66666667% 0 0);
          letter-spacing: 0;
        }
        &.engrave-choice-roman {
          font-family: $roman-l-monog-abc;
          font-size: 13px;
        }
        &.engrave-choice-roman,
        &.engrave-choice-script {
          font-weight: normal;
          letter-spacing: 0.05em;
          width: 23%;
        }
        &.engrave-choice-roman {
          letter-spacing: 0.1em;
        }
      }
    }
  }
  &__engraving--form-line {
    position: relative;
    margin-bottom: 23px;
    padding-#{$ldirection}: 3px;
    #{$aerin} & {
      margin-bottom: 16px;
    }
    .char-counter {
      color: $color-light-gray;
      position: absolute;
      #{$rdirection}: 20px;
      top: 10px;
    }
    .engrave-choice-script,
    .engraving-script {
      .spp-engraving & {
        padding-left: 9px;
        text-indent: 10px;
      }
    }
    .engraving-message {
      background: none;
      border: 1px solid $color-light-gray;
      color: $color-navy;
      display: block;
      font-size: 12px;
      height: 40px;
      line-height: 38px;
      @include swap_direction(padding, 0 45px 0px 19px);
      text-transform: none;
      width: 100%;
      box-shadow: none;
    }
  }
  &__engraving--form-preview {
    .overlay-left-arrow-nav,
    .spp-engraving__engraving--form-heading {
      display: none;
    }
    .engrave-placement {
      position: absolute;
      #{$rdirection}: 0;
      width: 340px;
      text-align: center;
      form.engraving-form-choice-1 &,
      form.engraving-form-choice-41 & {
        bottom: 120px;
      }
      form.engraving-form-choice-6 & {
        bottom: 200px;
      }
      form.engraving-form-choice-2 &,
      form.engraving-form-choice-4 &,
      form.engraving-form-choice-43 & {
        width: 339px;
        bottom: 200px;
      }
      form.engraving-form-choice-5 &,
      form.engraving-form-choice-3 & {
        width: 339px;
        bottom: 200px;
      }
      form.engraving-form-choice-7 &,
      form.engraving-form-choice-8 &,
      form.engraving-form-choice-16 &,
      form.engraving-form-choice-42 & {
        #{$rdirection}: 45px;
        top: 118px;
        width: 180px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        input {
          color: $color-white;
        }
      }
      form.engraving-form-choice-7 &,
      form.engraving-form-choice-16 & {
        #{$rdirection}: 59px;
        top: 64px;
        width: 130px;
      }
      form.engraving-form-choice-16 & {
        top: 95px;
      }
      form.engraving-form-choice-17 &,
      form.engraving-form-choice-40 & {
        width: 339px;
        bottom: 240px;
      }
      form.engraving-form-choice-36 & {
        top: 270px;
        width: 325px;
        .preview-copy {
          color: $color-gold;
          font-size: 24px;
          letter-spacing: 0.4em;
          text-transform: uppercase;
        }
      }
      form.engraving-form-choice-46 & {
        #{$rdirection}: 70px;
        width: 200px;
        .canvas-orig {
          &.engrave-choice-arialosregular,
          &.engrave-choice-optima,
          &.engrave-choice-roman {
            font-size: 20px;
          }
          &.engrave-choice-script {
            font-size: 18px;
          }
        }
        .engraving-size-1 &,
        .engraving-size-1_0 &,
        .engraving-size-30ml & {
          top: 290px;
        }
        .engraving-size-1_7 &,
        .engraving-size-50ml & {
          top: 275px;
        }
        .engraving-size-3_9 &,
        .engraving-size-115ml & {
          #{$rdirection}: 65px;
          top: 250px;
        }
      }
      form.engraving-form-choice-8 & {
        #{$rdirection}: 42px;
        top: 128px;
        .canvas-orig {
          color: $color-white;
        }
      }
      .canvas-orig {
        background: none;
        border: none;
        height: 100%;
        width: 100%;
        color: $color-gold-engrave;
        font-size: 25px;
        letter-spacing: normal;
        @include swap_direction(padding, 0);
        text-align: center;
        opacity: 0;
        &.engrave-choice-script {
          font-size: 28px;
        }
        &.preview-copy {
          opacity: 1;
          &.engrave-choice-optima {
            font-family: $optima;
          }
          &.engrave-choice-arialosregular {
            font-family: $arialosregular;
          }
          &.engrave-choice-roman {
            font-family: $roman;
            #{$aerin} & {
              font-family: $roman-l-monog-abc;
            }
          }
          .preview-copy-capitalise {
            #{$aerin} & {
              font-size: 1.25em;
              text-transform: uppercase;
              vertical-align: bottom;
            }
          }
        }
        //Color for Golden Bottle
        &.engraving-golden-font {
          color: $color-bottle-gold;
        }
        #{$aerin} & {
          display: block;
          text-transform: uppercase;
        }
      }
      form.engraving-form-choice-6 & {
        .canvas-orig {
          color: $color-navy;
        }
      }
      form.engraving-form-choice-7 &,
      form.engraving-form-choice-16 & {
        .canvas-orig {
          font-size: 26px;
          &.large-font {
            font-size: 14px;
          }
        }
      }
      form.engraving-form-choice-8 &,
      form.engraving-form-choice-42 & {
        .canvas-orig {
          font-size: 26px;
          &.large-font {
            font-size: 16px;
          }
        }
      }
      form.engraving-form-choice-16 & {
        .canvas-orig {
          color: $color-gold-engrave-dark;
        }
      }
    }
  }
  &__engraving--form-element-subtotaling {
    font-family: $akzidenz;
    font-size: 12px;
    padding-#{$ldirection}: 4px;
    margin-bottom: 9px;
    text-align: #{$ldirection};
    #{$aerin} & {
      padding-#{$ldirection}: 3px;
    }
    li {
      margin-bottom: 18px;
      font-size: 12px;
      letter-spacing: 0.14em;
      #{$aerin} & {
        margin-bottom: 8px;
      }
      &.engraving-wrap {
        margin-bottom: 13px;
      }
      &.subtotal-wrap {
        margin-bottom: 16px;
      }
      @include swatches(18px, 9px, 6px, 12px, 3px);
      .swatch__container {
        float: $ldirection;
        @include swap_direction(margin, 0 5px 0 0);
      }
    }
    .name {
      float: #{$ldirection};
      width: 80%;
      #{$aerin} & {
        width: 77%;
      }
      &.engraving-text {
        text-transform: uppercase;
        #{$aerin} & {
          letter-spacing: 0.17em;
        }
      }
      .product-title {
        font-size: 22px;
        font-family: $optimaregular;
        margin-bottom: 6px;
        line-height: 1.15em;
        letter-spacing: 0;
        width: 100%;
        #{$aerin} & {
          font-size: 22px;
          padding-#{$ldirection}: 0;
          font-weight: normal;
          letter-spacing: -0.01em;
          margin-bottom: 2px;
        }
      }
      .product-subtitle {
        font-family: $optimaregular;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 1.15em;
        margin-bottom: 13px;
        width: 100%;
        #{$aerin} & {
          font-family: $futura_now_md;
          letter-spacing: 0.01em;
          margin-bottom: 13px;
          font-size: 18px;
          font-weight: normal;
        }
      }
      .product-shadeColor {
        border-radius: 11px;
        width: 19px;
        height: 19px;
        float: #{$ldirection};
      }
      .product-shadeName {
        text-transform: uppercase;
        float: #{$ldirection};
        margin-#{$ldirection}: 6px;
        margin-top: 2px;
        letter-spacing: 0.15em;
      }
    }
    .numbers {
      display: block;
      float: #{$ldirection};
      text-align: #{$rdirection};
      width: 20%;
      font-size: 12px;
      letter-spacing: 0.17em;
      padding-#{$ldirection}: 4px;
      &.product-cost {
        margin-top: 8px;
      }
    }
  }
  &__engraving--form-element-preview {
    display: none;
  }
  &__engraving--form-element-footer {
    font-family: $akzidenz;
    font-size: 10px;
    text-align: #{$ldirection};
    #{$aerin} & {
      font-family: $futura_now_reg;
      font-size: 12px;
    }
    .engraving-cancel {
      display: none;
    }
    .engraving-save {
      @include swap_direction(margin, 0 0 0 5px);
      letter-spacing: 0.15em;
      #{$aerin} & {
        font-family: $futura_now_md;
        font-size: 12px;
        letter-spacing: 0.22em;
        color: $color-white;
        background: $color-periwinkle-blue--aerin;
        @include swap_direction(margin, 0 0 19px 3px);
        @include swap_direction(padding, 0 19px 0 23px);
      }
    }
    .engraving-msg {
      font-size: 13px;
      line-height: 16px;
      clear: both;
      width: 410px;
      padding-#{$ldirection}: 3px;
      letter-spacing: 0.05em;
      position: absolute;
      bottom: 28px;
      #{$aerin} & {
        font-size: 14px;
        font-family: $akzidenz;
        letter-spacing: 0;
        width: 397px;
        bottom: 20px;
      }
    }
  }
  .engrave-choice-arialosregular,
  .engraving-arialosregular {
    font-family: $arialosregular;
    font-size: 17px;
    line-height: 38px;
    letter-spacing: -0.01em;
    -webkit-font-smoothing: subpixel-antialiased;
  }
  .engrave-choice-optima,
  .engraving-optima {
    font-family: $optimalight;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: -0.01em;
    -webkit-font-smoothing: subpixel-antialiased;
  }
  .engrave-choice-roman,
  .engraving-roman {
    font-family: $roman;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 38px;
    -webkit-font-smoothing: subpixel-antialiased;
    #{$aerin} & {
      font-family: $roman-l-monog-abc;
    }
    &.engraving-message {
      font-family: $roman;
      #{$aerin} & {
        font-family: $roman-l-monog-abc;
      }
    }
  }
  .engrave-choice-script,
  .engraving-script {
    font-family: $script;
    font-size: 28px;
    letter-spacing: 0;
    #{$aerin} & {
      font-family: $interlock-m-script-abc;
      font-size: 25px;
      line-height: 38px;
      text-transform: lowercase;
      letter-spacing: 0.08em;
      -webkit-font-smoothing: subpixel-antialiased;
    }
  }
  .engrave-choice-block {
    background: $color-white;
  }
  .engraving-form-custom-msg {
    &.engraving {
      display: none;
    }
    #{$aerin} & {
      line-height: 19px;
      @include swap_direction(padding, 2px 0 0 5px);
      margin-bottom: 7px;
    }
  }
  .engraving-message {
    #{$aerin} & {
      text-transform: inherit;
    }
    &::placeholder {
      @include placeholder-engraving;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      @include placeholder-engraving;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      @include placeholder-engraving;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      @include placeholder-engraving;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      @include placeholder-engraving;
    }
  }
  #{$aerin} & {
    font-family: $futura_now_reg;
  }
  .engraving-choice {
    &--hide-space {
      margin: 0;
    }
  }
  &__engraving--promo-sku {
    .spp-engraving__engraving--form-font-choice-btn {
      display: none;
    }
    .spp-engraving__engraving--form-preview {
      .engrave-placement {
        .canvas-orig {
          &.preview-copy {
            &.engrave-choice-roman {
              font-family: $roman-l-monog-abc;
            }
          }
        }
      }
    }
    .engrave-choice-roman {
      &.engraving-message {
        font-family: $roman-l-monog-abc;
      }
    }
  }
}
/* View cart page */
.cart-items__engraving-sku-item {
  .cart-item__qty-replenishment-group {
    display: none;
  }
  .engraving-view {
    bottom: 190px;
  }
  .cart-item__size {
    .page-wrapper-checkout & {
      font-size: 14px;
      line-height: 14px;
      margin: 0 0 4px;
    }
  }
  &.monogram-product {
    .monogramming {
      display: inline-block;
    }
    .engraving {
      display: none;
    }
  }
  .cart-item__product-subname {
    .page-wrapper-checkout & {
      margin-bottom: 5px;
    }
  }
  .engraving {
    display: inline-block;
  }
  .monogramming {
    display: none;
  }
  .engraving-button-container {
    display: none;
  }
}

.engraving-button-container {
  &.monogram-product {
    .monogramming {
      display: inline-block;
    }
    .engraving {
      display: none;
    }
  }
}

.engraving-large {
  .spp-engraving__engraving--form {
    &-element-footer {
      .engraving-msg {
        bottom: 10px;
      }
    }
    &-line {
      margin-top: 23px;
      #{$aerin} & {
        margin-top: 8px;
      }
    }
    &-font-choice-btn {
      #{$aerin} & {
        display: inline-block;
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
  .change-font.monogramming {
    #{$aerin} & {
      float: #{$ldirection};
    }
  }
}
/* Viewcart Sidebar */
.checkout__sidebar {
  .engraving {
    &-wrapper {
      position: static;
      float: #{$rdirection};
      width: 65%;
    }
    &-view {
      border-top: none;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      top: 0;
      &__message,
      &__message-disclaimer {
        display: none;
      }
      &__price {
        padding-#{$rdirection}: 23px;
      }
    }
  }
}
/* Order History */
.order-details-page .cart-item-table {
  .cart-item-table__engraveable {
    .cart-item__cell {
      height: auto;
      @include swap_direction(padding, 15px 0 0 245px !important);
      span {
        @include fontname--Monog-Engr;
      }
    }
    .order_item_total {
      #order_detail & {
        text-align: #{$rdirection};
        padding-#{$rdirection}: 16px;
      }
    }
  }
}
/* End of Engraving Styles Overlay */

// Notify Me
.product___button--notify-me {
  display: none;
  width: 140px;
  margin-top: 15px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.basic-reorder__sold-out {
  color: $color-red;
  display: none;
}
