// @todo deprecate when SD products are live and landing pages use v3 formatter
// Aerin Brand Styles that don't fit elsewhere. Consider using the method below before placing styles in here:
//
// SCSS:
// .button--dark {
//   background: $color-navy;
//   color: $color-white;
//   #{$renutriv} & { // Renutriv Brand Overrides
//     background: black;
//     border-color: $color-gold--renutriv;
//   }
//   #{$aerin} & { // Aerin Brand Overrides
//     background: $color-periwinkle-blue--aerin;
//   }
// }
//
// This will generate this CSS:
// .button--dark {
//   background: navy;
//   color: white;
// }
// .brand-renutriv .button--dark {
//   background: black;
//   border-color: gold;
// }
// .brand-aerin .button--dark {
//   background: periwinkle;
// }
//

#{$aerin} {
  .page-wrapper {
    padding-top: 85px;
  }
  .page-main {
    font-family: 'Futura Now Regular';
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'Futura Now Regular';
    }
  }
  .mpp__product {
    &--brand-aerin {
      .product_brief__panel {
        .product__inventory-status {
          display: none !important;
        }
      }
    }
  }
}

// WORLD OF AERIN LANDING PAGE
$mq-woa-landing-tile-columns: '#{$screen} and (min-width: 802px)';

.woa-landing {
  max-width: 1024px;
  @include swap_direction(margin, 0 auto);
}

.woa-landing__tile {
  background: $color-muted-gray-aerin;
  @include swap_direction(margin, 20px auto);
  max-width: 380px;
  @media #{$mq-woa-landing-tile-columns} {
    @include clearfix;
    clear: both;
    max-width: 800px;
    max-height: 224px;
  }
}

.woa-landing-tile__img {
  max-width: 100%;
  @media #{$mq-woa-landing-tile-columns} {
    max-width: 50%;
    float: $ldirection;
  }
}

.woa-landing__text {
  @include swap_direction(padding, 30px 28px);
  @media #{$mq-woa-landing-tile-columns} {
    width: 50%;
    float: $rdirection;
  }
  h4 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .aerin-block-link {
    display: inline-block;
    background: $color-muted-teal-aerin;
    @include swap_direction(padding, 10px 2em);
    color: white;
    text-decoration: none;
    margin-top: 1em;
  }
}

// BLOG
.formatter-woa {
  .header-meta {
    @extend %content-width-limit;
  }
  .woa-blog__header-headline {
    font-size: 30px;
  }
  .woa-blog__header-subhead {
    font-size: 18px;
  }
}

.woa-blog__content {
  max-width: $estee-edit-content-max-width;
  @include swap_direction(margin, 0 auto);
  .basic-textarea-v1 {
    @extend %content-width-limit;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  blockquote {
    @extend %content-width-limit;
  }
  .ee-disco-more__label {
    font-weight: normal;
    font-family: 'Futura Now Regular';
  }
}

//landing

.aerin-carousel-slide {
  &__img {
    width: 100%;
  }
  position: relative;
  &__text {
    position: absolute;
    text-align: center;
    width: 100%;
    @include swap_direction(padding, 0 3%);
    top: 3.5em;
    @media #{$medium-up} {
      top: 50%;
      transform: translateY(-50%);
      @include swap_direction(padding, 0 10%);
    }
    &--title {
      font-size: 58px;
      @media #{$medium-up} {
        font-size: 80px;
      }
      @media #{$medium-down} {
        font-size: 48px;
      }
      font-family: 'EssonnesDisplayRegular';
      color: $color-white;
      @include textshadow-aerin(1px, 1px, 3px, rgba($color-black, 0.4));
      text-transform: uppercase;
    }
  }
}

.aerin-image-block-w-overlay,
.aerin-video-block {
  position: relative;
  width: 100%;
  height: 100%;
  &__img {
    width: 100%;
    height: auto;
  }
}

.aerin-video-block {
  &__text {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-family: 'NarzissRegular';
    text-transform: uppercase;
    a {
      color: $color-white;
    }
  }
  &__pretitle {
    font-size: 12px;
    font-family: 'Futura Now Regular';
  }
  &__title {
    font-size: 33px;
    @media #{$medium-up} {
      font-size: 26px;
    }
    @media #{$xlarge-up} {
      font-size: 35px;
    }
    @include swap_direction(margin, 0 5% 0.5em 5%);
    text-transform: uppercase;
    @include textshadow-aerin(1px, 1px, 3px, rgba($color-black, 0.4));
  }
  &__img {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
  }
  &__play-button {
    background: url('/media/boutiques/aerin/images/play_video_button.png');
    background-size: 70px 70px;
    width: 70px;
    height: 70px;
    @include swap_direction(margin, 0 auto);
  }
  &__play-text {
    font-family: 'Futura Now Regular';
    text-transform: uppercase;
    margin-top: 12px;
    display: none;
  }
}

.aerin-image-block-w-overlay {
  background-color: rgba(149, 151, 169, 0.8);
  &:hover &__overlay.enable-overlay {
    background-color: rgba(149, 151, 169, 0.8);
    transition: background 0s;
  }
  &__text {
    position: relative;
    color: $color-white;
    @media #{$medium-up} {
      color: $color-white;
    }
    display: block;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-family: 'NarzissRegular';
    text-transform: uppercase;
    @include swap_direction(margin, 0 2%);
    &--title {
      font-size: 33px;
      @media #{$medium-up} {
        font-size: 26px;
      }
      @media #{$xlarge-up} {
        font-size: 38px;
      }
      @media #{$xxlarge-up} {
        font-size: 48px;
      }
      margin-bottom: 1%;
      text-transform: uppercase;
      font {
        @include textshadow-aerin(1px, 1px, 3px, rgba($color-black, 0.4));
      }
    }
    &--subtitle {
      font-size: 16px;
      font-family: 'Futura Now Medium';
      font {
        @include textshadow-aerin(1px, 1px, 3px, rgba($color-black, 0.4));
      }
    }
  }
  &__overlay {
    background-color: rgba(149, 151, 169, 0);
    @media #{$medium-up} {
      background-color: rgba(149, 151, 169, 0);
      color: blue;
    }
    transition: background 0.4s;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  &__overlay.enable-overlay {
    background-color: rgba(149, 151, 169, 0.8);
    @media #{$medium-up} {
      background-color: rgba(149, 151, 169, 0);
    }
  }
  &__overlay.enable-overlay &__text {
    @media #{$medium-up} {
      display: none;
    }
  }
  &__overlay.enable-overlay &__text {
    font-family: 'Futura Now Regular';
  }
  &:hover &__text {
    display: block;
  }
}

.aerin_headline_block {
  color: $color-periwinkle-blue--aerin;
  text-align: center;
  text-transform: uppercase;
  height: 100%;
  a {
    color: $color-periwinkle-blue--aerin;
  }
  &--text {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  &--title {
    font-size: 33px;
    @media #{$medium-up} {
      font-size: 24px;
    }
    @media #{$xlarge-up} {
      font-size: 35px;
    }
    @media #{$xxlarge-up} {
      font-size: 44px;
    }
    margin-bottom: 10px;
    font-family: 'NarzissRegular';
  }
  &--subtitle {
    font-size: 24px;
    @include swap_direction(margin, 10px 0px 0px 0px);
    font-family: 'Futura Now Regular';
    @media #{$medium-up} {
      font-size: 16px;
    }
    @media #{$xlarge-up} {
      font-size: 18px;
    }
    @media #{$xxlarge-up} {
      font-size: 24px;
    }
  }
}

.aerin_headline_block .aerin-slot-button,
.aerin-carousel-formatter .aerin-slot-button {
  background-color: $color-periwinkle-blue--aerin;
  color: $color-white;
  padding: 15px 30px;
  @include swap_direction(margin, 23px auto 27px auto);
  display: inline-block;
  position: relative;
  @media #{$medium-up} {
    width: auto;
  }
}

.aerin-slot-button {
  &.aerin-slot-button__pc {
    display: none;
    @media #{$medium-up} {
      display: inline-block;
    }
  }
  &.aerin-slot-button__mobile {
    display: block;
    width: 80%;
    text-align: center;
    @include swap_direction(margin, 0 auto);
    top: -21px;
    @media #{$medium-up} {
      display: none;
    }
  }
  text-transform: uppercase;
  font-family: 'Futura Now Regular';
  min-width: 116px;
  padding: 15px 30px;
  background-color: $color-periwinkle-blue--aerin;
  color: $color-white;
  @include swap_direction(margin, 33px auto 27px auto);
  display: inline-block;
}

.aerin-landing-row {
  margin-bottom: 0;
  @media #{$medium-up} {
    margin-bottom: 15px;
  }
  &__slot {
    width: 100%;
    min-height: 320px;
    margin-bottom: 15px;
    @media #{$medium-up} {
      margin-bottom: 0;
      min-height: 0;
      min-height: none;
      float: $ldirection;
      clear: none;
      margin-#{$rdirection}: 1%;
      &:last-child {
        margin-#{$rdirection}: 0;
      }
      &--3-weighted-even {
        width: 32.5%;
      }
      &--3-weighted-right {
        width: 27%;
        &:last-child {
          width: 44%;
        }
      }
      &--3-weighted-middle {
        width: 27%;
        &:nth-child(2) {
          width: 44%;
        }
      }
      &--3-weighted-left {
        width: 27%;
        &:first-child {
          width: 44%;
        }
      }
      &--2-weighted-even {
        width: 49.5%;
      }
      &--2-weighted-right {
        width: 32.5%;
        &:last-child {
          width: 65.5%;
        }
      }
      &--2-weighted-left {
        width: 32.5%;
        &:first-child {
          width: 65.5%;
        }
      }
    }
  }
}

.aerin-carousel-formatter {
  margin-bottom: 80px;
  @media #{$medium-up} {
    margin-bottom: 0;
  }
  .slick-carousel .slick-next,
  .slick-carousel .slick-prev {
    width: 30px;
    height: 60px;
    @media #{$medium-up} {
      width: 41px;
      height: 72px;
    }
  }
  .slick-carousel .slick-next {
    #{$rdirection}: 5%;
    background: url('/media/boutiques/aerin/images/Carousel_Arrow_Right_Mobile.png');
    @media #{$medium-up} {
      background: url('/media/boutiques/aerin/images/Carousel_Arrow_Right.jpg');
      #{$rdirection}: 0;
    }
  }
  .slick-carousel .slick-prev {
    #{$ldirection}: 5%;
    background: url('/media/boutiques/aerin/images/Carousel_Arrow_Left_Mobile.png');
    @media #{$medium-up} {
      background: url('/media/boutiques/aerin/images/Carousel_Arrow_Left.jpg');
      #{$ldirection}: 0;
    }
  }
  .slick-slider {
    margin-bottom: 1%;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: none;
  }
}
