.link-based-offer-ref {
  display: none;
}
// If LBO is enabled then apply the corresponding styles
@if $enable_lbo == true {
  @media #{$small-up} {
    .messages {
      h1,
      h2 {
        font-size: inherit;
        font-family: inherit;
        letter-spacing: inherit;
        line-height: inherit;
      }
      .lbo-msg {
        display: none;
      }
    }
    .lbo {
      &.special-offer {
        .special-offer {
          &__img {
            display: none;
          }
          &__header {
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            padding-bottom: 10px;
            font-family: $akzidenz;
          }
          &__description-container {
            color: $color-navy;
            h1,
            h5 {
              text-transform: capitalize;
              font-family: $optimalight;
              letter-spacing: 0;
              font-size: 38px;
              line-height: normal;
            }
            h2,
            h6 {
              font-size: 20px;
              letter-spacing: 0;
              font-family: $akzidenz;
            }
          }
        }
        .lbo-message-container.cart & {
          text-align: center;
          border: solid 1px $color-light-gray;
          padding: 10px 20px;
          -moz-box-shadow: 0 0 15px -2px $color-comet;
          -webkit-box-shadow: 0 0 15px -2px $color-comet;
          box-shadow: 0 0 15px -2px $color-comet;
          border-bottom: 1px solid $color-comet;
          color: $color-navy;
          .special-offer {
            &__button {
              display: none;
            }
            &__header,
            &__description-container h1,
            &__description-container h5 {
              font-size: 30px;
              font-weight: bold;
              padding-bottom: 0;
              font-family: $optimalight;
            }
          }
        }
        &.special-offer-row-mobile {
          padding-bottom: 0;
          .special-offer {
            &__img {
              display: block;
              width: 226px;
              margin: 10px auto;
            }
            &__container {
              text-align: center;
            }
            &__header {
              font-size: 12px;
              letter-spacing: 0.15em;
              padding-bottom: 15px;
              display: inline-block;
              font-style: normal;
            }
            &__description-container {
              margin-bottom: 15px;
              h1,
              h5 {
                font-size: 70px;
                margin-top: -8px;
                letter-spacing: -0.08em;
                line-height: 0.95;
                z-index: 1;
                margin-bottom: -30px;
                position: relative;
              }
            }
            &__button-container {
              width: 100%;
              text-align: center;
              padding: 0 25px;
              a {
                width: 100%;
                background: $color-navy;
                font-family: $akzidenz;
                letter-spacing: 0.15em;
                cursor: pointer;
                font-weight: bold;
                text-decoration: none;
                color: $color-white;
                height: 40px;
                line-height: 40px;
                padding: 0 20px;
                display: inline-block;
              }
            }
          }
        }
      }
      &__btn--close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 28px;
      }
    }
    .mobile-footer-lbo {
      .lbo {
        &.special-offer {
          position: fixed;
          bottom: 0;
          background-color: $color-navy;
          background-color: rgba($color-navy, 0.9);
          color: $color-white;
          padding: 9%;
          z-index: 1;
          .special-offer {
            &__description-container {
              color: $color-white;
              h2,
              h6 {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
    #lbo-message-container {
      .link-based-offer-ref {
        display: block;
      }
      &.cart {
        margin-bottom: 0;
      }
      .column.right & {
        display: none;
      }
    }
    .lbo-active {
      [id^='error_offer_criteria_met'].single-message {
        display: none;
      }
      &.viewcart {
        #promo-panel {
          display: none;
        }
      }
      &.viewcart.mobile {
        margin-left: 0;
        margin-right: 0;
        .column.left > *,
        .column.right > * {
          margin-left: 25px;
          margin-right: 25px;
        }
        #viewcart-panel {
          margin-left: 0;
          margin-right: 0;
          & > * {
            margin-left: 25px;
            margin-right: 25px;
          }
          .lbo-message-container {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
    .checkout {
      .viewcart-header {
        .messages {
          h1,
          h2,
          h5,
          h6 {
            font-size: 22px;
            line-height: normal;
          }
        }
      }
    }
  }
  @media #{$large-up} {
    #lbo-message-container {
      &.cart {
        margin-bottom: 75px;
      }
    }
    .my-feed-tray--loyalty .my-feed-tray__col.lbo {
      &.special-offer {
        .lbo-confirm-message {
          margin-top: -8px;
          color: $color-white;
          h1,
          h5 {
            font-family: $optimaregular;
            letter-spacing: 0;
            font-size: 22px;
            line-height: 100%;
            margin-top: 5px;
            display: inline-block;
            text-transform: capitalize;
          }
          h2,
          h6 {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0;
            text-transform: lowercase;
          }
        }
        .special-offer {
          &__header {
            margin-top: 0 !important;
            line-height: 1 !important;
          }
          &__container {
            padding-right: 15px;
          }
          &__button-container {
            margin-top: 5px;
            position: static;
          }
          &__img {
            display: block;
          }
        }
      }
    }
    .lbo {
      &__btn--close {
        display: none;
      }
      &.special-offer {
        &.special-offer-row {
          margin-bottom: 50px;
          .special-offer {
            &__img {
              float: right;
              display: block;
              height: auto;
              max-width: 350px;
              margin-top: 18px;
            }
            &__container {
              margin-left: 20%;
              position: absolute;
              max-width: 100%;
              top: 0;
            }
            &__header {
              font-size: 12px;
              color: $color-navy;
              font-weight: bold;
              font-family: $akzidenz;
              letter-spacing: 0.17em;
              text-transform: uppercase;
              margin-bottom: 0;
              padding-bottom: 0;
            }
            &__description-container {
              color: $color-navy;
              width: 67%;
              margin-left: -10px;
              h1,
              h5 {
                font-size: 92px;
                line-height: 1;
                color: $color-navy;
                padding: 0.1em 0 0.2em;
                letter-spacing: -1px;
                font-family: $optimalight;
                text-transform: capitalize;
              }
              h2,
              h6 {
                font-size: 12px;
                color: $color-navy;
                font-weight: bold;
                letter-spacing: 0.17em;
                text-transform: uppercase;
                margin-bottom: 10px;
                margin-left: 10px;
              }
            }
            &__button-container {
              a {
                background: transparent;
                font-size: 12px;
                font-family: $akzidenz;
                letter-spacing: normal;
                text-transform: uppercase;
                text-decoration: underline;
                cursor: pointer;
                font-weight: bold;
                text-decoration: underline;
                color: $color-navy;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                height: 40px;
                line-height: 40px;
                display: inline-block;
                padding: 0;
                position: relative;
              }
            }
          }
        }
        .lbo-message-container.cart & {
          background-color: $color-lighter-gray;
          border-bottom: solid 1px $color-light-gray;
          padding: 0;
          box-shadow: none;
          text-align: left;
          overflow: hidden;
          margin-bottom: 20px;
          min-height: 90px;
          position: relative;
          .special-offer {
            &__img {
              width: 100px;
              height: auto;
              max-height: 100px;
              float: left;
              margin-left: 40px;
              border: 0;
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
            }
            &__header {
              font-size: 12px;
              color: $color-navy;
              font-weight: bold;
              font-family: $akzidenz;
              letter-spacing: 0.07em;
              text-transform: uppercase;
              padding-bottom: 0;
            }
            &__container {
              margin-left: 30px;
              padding: 13px 0;
              position: absolute;
              top: 50%;
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              transform: translateY(-50%);
              &.special-offer__img--available {
                margin-left: 167px;
                padding-right: 13px;
              }
            }
            &__description-container {
              color: $color-navy;
              margin-top: 0;
              h1,
              h5 {
                font-family: $optimalight;
                letter-spacing: 0;
                font-size: 22px;
                line-height: 27px;
                margin-top: 0;
                margin-bottom: 7px;
                display: inline-block;
                text-transform: capitalize;
                color: $color-navy;
                font-weight: bold;
                padding-bottom: 0;
              }
              h2,
              h6 {
                font-size: 16px;
                line-height: 18px;
                letter-spacing: 0;
                text-transform: lowercase;
                color: $color-navy;
              }
            }
            &__button-container {
              display: none;
            }
          }
        }
      }
    }
  }
}
