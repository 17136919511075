/* Engraving View cart page */
.engraving-wrapper {
  width: 68%;
  #{$rdirection}: 0;
  position: absolute;
}

.engraving-view {
  border-top: 1px solid $color-light-gray;
  @include swap_direction(margin, 10px 0 0);
  @include swap_direction(padding, 13px 0 0);
  float: #{$rdirection};
  width: 100%;
  position: relative;
  .loading {
    position: absolute;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
  &__name {
    color: $color-navy;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    float: #{$ldirection};
    padding-#{$ldirection}: 1px;
  }
  &__price {
    font-size: 12px;
    font-weight: bold;
    float: #{$rdirection};
    letter-spacing: 0.15em;
  }
  &__message {
    background: $color-gray-cs-side;
    @include swap_direction(padding, 1px 5px 1px 8px);
    margin-top: 10px;
    &-content {
      float: #{$ldirection};
      font-size: 22px;
      font-weight: normal;
      font-family: $optimaregular;
      letter-spacing: 0.02em;
      line-height: 28px;
      @include fontname--Monog-Engr;
    }
    &-cta {
      float: #{$rdirection};
      margin-top: 7px;
      font-size: 10px;
      letter-spacing: 0.2em;
    }
    &-edit,
    &-delete {
      text-decoration: underline;
      text-transform: uppercase;
    }
    &-delete {
      border-#{$ldirection}: 1px solid $color-navy;
      padding-#{$ldirection}: 11px;
      margin-#{$ldirection}: 5px;
    }
    &-disclaimer {
      font-size: 75%;
      margin-top: 6px;
      padding-#{$ldirection}: 1px;
    }
  }
  &__sku--promo {
    .engraving-view__message-delete {
      display: none;
    }
  }
}
