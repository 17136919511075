.loyalty-account-page__about {
  &:before {
    background: url('/media/images/loyalty/loyalty_ldg_bg.jpg') no-repeat 0 0;
    content: '';
    position: absolute;
    width: 1667px;
    height: 1792px;
    top: 0;
    #{$ldirection}: 50%;
    margin-#{$ldirection}: -610px;
    z-index: -2;
  }
  .loyalty_mrkt_ldng {
    &:before {
      background: transparent;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      #{$ldirection}: 0;
      margin-#{$ldirection}: 0;
      z-index: 0;
    }
    .loyalty_join_signin {
      .not_loyalty_join_now {
        display: block;
        text-transform: initial;
        letter-spacing: 0.05em;
      }
    }
  }
}

.loyalty_mrkt_ldng {
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
  margin-top: -82px;
  background-position: center top;
  overflow: hidden;
  &:before {
    background: url('/media/images/loyalty/loyalty_ldg_bg.jpg') no-repeat 0 0;
    content: '';
    position: absolute;
    width: 1667px;
    height: 1792px;
    top: 0;
    #{$ldirection}: 50%;
    margin-#{$ldirection}: -770px; //half bg image width + half offset, or something like that
    z-index: -2;
  }
  &.is_loyalty_member {
    .loyalty_join_signin,
    .loyalty_tiers a {
      display: none;
    }
    .see_my_rewards {
      display: block;
      position: relative;
      top: -18px;
      margin-bottom: 40px;
    }
  }
  .see_my_rewards {
    display: none;
    .form-submit {
      width: 271px;
      height: 40px;
    }
  }
  .clr {
    clear: both;
  }
  img.loyalty_mrkt_ldng__image {
    display: none;
  }
  .loyalty_mrkt_ldng_title {
    margin-#{$ldirection}: -10px;
    position: absolute;
    top: 83px;
    width: 100%;
    z-index: 99;
  }
  .loyalty_multi_image_tout {
    @include swap_direction(margin, 0);
    @include headline--quaternary;
    .title_main {
      font-size: 110px;
    }
    .loyalty_multi_image_tout_left {
      width: 50%;
      text-align: $rdirection;
      float: $ldirection;
      margin-top: 81px;
      padding-#{$rdirection}: 7px;
      .title_main {
        visibility: hidden;
      }
      p {
        line-height: 22px;
        letter-spacing: 0.05em;
        margin-top: 17px;
      }
    }
    .loyalty_multi_image_tout_right {
      width: 50%;
      text-align: $ldirection;
      float: $rdirection;
      margin-top: 87px;
      padding-#{$ldirection}: 1%;
      position: relative;
      z-index: 2;
      .title_main {
        color: $color-white;
        padding-#{$ldirection}: 15px;
        visibility: hidden;
      }
      img {
        position: absolute;
        top: -5px;
        z-index: -1;
      }
    }
    .title_super {
      text-transform: uppercase;
      font-size: 11px;
      font-family: $akzidenz;
      font-weight: bold;
      @include swap_direction(margin, 0 150px -40px 0);
      letter-spacing: 2px;
      @media #{$medium-up} {
        display: none;
      }
    }
    .title_main {
    }
    .sub_title {
      font-size: 45px;
      line-height: 48px;
      letter-spacing: -0.025em;
      margin-top: 15px;
    }
  }
  .loyalty_join_signin {
    font-weight: bold;
    text-transform: uppercase;
    font-family: $akzidenz;
    font-size: 14px;
    @include swap_direction(margin, 0 0 39px 0);
    .form-submit {
      width: 271px;
      height: 40px;
    }
    .btn_join {
      @include swap_direction(margin, 0 43px 0 -20px);
    }
    p {
      color: $color-navy;
      letter-spacing: 0.15em;
      margin-top: 12px;
    }
    .not_loyalty_join_now {
      display: none;
    }
  }
  .loyalty_tiers {
    h3 {
      font-size: 110px;
      color: $color-navy;
      line-height: 1em;
    }
    .sub_head {
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      color: $color-navy;
      margin-top: 6px;
    }
    a {
      font-size: 12px;
      font-weight: bold;
      text-decoration: underline;
      text-transform: uppercase;
      letter-spacing: 0.15em;
    }
    .tier {
      color: #ccc;
      &.default_tier h4 span {
        background: url('/media/export/cms_2.0/loyalty/loyalty_plus.png') no-repeat scroll 0 0;
        display: inline-block;
        height: 35px;
        width: 35px;
      }
      h4 {
        font-size: 66px;
        span {
          background: url('/media/export/cms_2.0/loyalty/loyalty_plus_blur.png') no-repeat scroll 0 0;
          display: inline-block;
          height: 35px;
          width: 35px;
        }
      }
      b {
        padding-#{$rdirection}: 50px;
      }
      ul,
      li {
        list-style: inherit;
        list-style-position: inside;
        list-style-image: initial;
        list-style-type: square;
      }
      &:hover {
        color: inherit;
        .tier_left h4 span {
          background: url('/media/export/cms_2.0/loyalty/loyalty_plus.png') no-repeat scroll 0 0;
        }
      }
      .tier_left.benefits_hover_tier_left_on h4 span {
        background: url('/media/export/cms_2.0/loyalty/loyalty_plus.png') no-repeat scroll 0 0;
      }
      &.default_tier .tier_left.benefits_hover_tier_left_on h4 span {
        background: url('/media/export/cms_2.0/loyalty/loyalty_plus.png') no-repeat scroll 0 0;
      }
    }
    .tier_left {
      width: 50%;
      float: $ldirection;
      text-align: $rdirection;
    }
    .tier_right {
      width: 35%;
      float: $ldirection;
      text-align: $ldirection;
      @include swap_direction(padding, 0.5em 1% 0);
      line-height: 1.8em;
      .tier_bold {
        font-size: 14px;
        font-weight: bold;
      }
      &.benefits_hover_tier_right {
        color: $color-navy;
      }
      &.default_benefits_hover_tier_right {
        color: $color-navy;
      }
      b.tier_points {
        display: none;
      }
      li {
        list-style-type: none;
        &:before {
          content: '·';
          font-size: 26px;
          line-height: 22px;
          padding-#{$rdirection}: 7px;
          vertical-align: top;
        }
      }
    }
    .default_tier {
      color: $color-navy;
      .tier_left.benefits_hover_tier_left_off {
        color: #ccc;
        h4 span {
          background: url('/media/export/cms_2.0/loyalty/loyalty_plus_blur.png') no-repeat scroll 0 0;
        }
      }
      .tier_left.benefits_hover_tier_left_on {
        color: $color-navy;
      }
    }
  }
  .loyalty_points {
    background-color: $color-navy;
    color: $color-white;
    width: 720px;
    @include swap_direction(margin, 20px auto 100px);
    @include swap_direction(padding, 30px);
    h3 {
      font-size: 66px;
    }
    p span {
      letter-spacing: 0.15em;
      font-size: 14px;
    }
  }
  .loyalty_question {
    a {
      text-decoration: underline;
      color: #9eafff;
    }
  }
}

// mobile
.loyalty_market {
  text-align: center;
  color: $color-navy;
  &.is_loyalty_member {
    .loyalty_market__cta,
    .loyalty_market__tiers__link__join {
      display: none;
    }
    .see_my_rewards {
      display: block;
      margin-bottom: 45px;
      margin-top: 22px;
    }
  }
  .see_my_rewards {
    display: none;
    a {
      width: 100%;
    }
  }
  .loyalty_market__super,
  .loyalty_market__elist {
    display: none; // forced to use image for header
  }
  .loyalty_market__image__container {
  }
  .loyalty_market__image {
    width: 100%;
  }
  .loyalty_market__header {
    font-family: $optimalight;
    font-size: 34px;
    line-height: 31px;
    @include swap_direction(margin, 0px 25px 0 25px);
    letter-spacing: -0.02em;
    @media #{$iphone6-landscape} {
      br {
        display: none;
      }
    }
  }
  .loyalty_market__description {
    @include swap_direction(margin, 0 25px);
  }
  .loyalty_market__text {
    @include swap_direction(margin, 10px 0);
    letter-spacing: 0.05em;
    font-size: 14px;
    line-height: 16px;
    @media #{$iphone6-landscape} {
      font-size: inherit;
      br {
        display: none;
      }
    }
  }
  .loyalty_market__cta {
    background: url(/media/export/cms_2.0/loyalty/mobile/loyalty_marketing_bg01.jpg) no-repeat right bottom;
    @include swap_direction(padding, 0 25px 46px 25px);
  }
  .loyalty_market__cta__offer {
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @include swap_direction(margin, 20px);
  }
  .loyalty_market__cta__btn__container {
  }
  .loyalty_market__btn__join,
  .loyalty_market__btn__signin {
    display: block;
    width: 100%;
    margin-top: 15px;
  }
  .loyalty_market__tiers {
    border-bottom: 1px solid #b2b2b2;
  }
  .loyalty_market__tiers__description {
    @include swap_direction(margin, 0 25px);
    text-align: center;
  }
  .loyalty_market__tiers__header {
    font-family: $optimalight;
    font-size: 62px;
    line-height: 70px;
    letter-spacing: -0.02em;
    @include swap_direction(margin, 0 10px);
  }
  .loyalty_market__tiers__text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    margin-top: 8px;
    @media #{$iphone6-landscape} {
      br {
        display: none;
      }
    }
  }
  .loyalty_market__tiers__link__container {
    @include swap_direction(margin, 18px 0 30px 0);
  }
  .loyalty_market__tiers__link__join {
    font-size: 12px;
    letter-spacing: 0.15em;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: underline;
  }
  .loyalty_market__tier {
    text-align: $ldirection;
    border-top: 1px solid #b2b2b2;
  }
  .loyalty_market__tier__controller {
    position: relative;
    height: 78px;
    @include swap_direction(padding, 0 25px);
  }
  .loyalty_market__tier__toggle {
    background: url('/media/export/cms_2.0/loyalty/loyalty_plus.png') no-repeat scroll center;
    position: absolute;
    top: 4px;
    #{$rdirection}: 4px;
    width: 53px;
    height: 53px;
  }
  .loyalty_market__tier__header {
    font-family: $optimalight;
    font-size: 44px;
    line-height: 46px;
    letter-spacing: -0.05em;
    margin-top: 10px;
  }
  .loyalty_market__tier__points {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-weight: bold;
  }
  .loyalty_market__tier__content {
    display: none;
    @include swap_direction(padding, 6px 25px 25px 25px);
  }
  .loyalty_market__tier__list {
    font-size: 12px;
    letter-spacing: 0.05em;
    line-height: 19px;
    @media #{$iphone6-portrait}, #{$iphone6-landscape} {
      font-size: inherit;
    }
  }
  .loyalty_market__tier__list__item {
    list-style-type: square;
    list-style-position: inside;
  }
  .loyalty_market__tier__list__item--last {
    margin-top: 6px;
    list-style-type: none;
    text-transform: uppercase;
    font-weight: bold;
  }
  .is_open {
    .loyalty_market__tier__toggle {
      background-size: auto auto;
      background-position: 0 -2957px;
      height: 53px;
      width: 53px;
    }
    .loyalty_market__tier__content {
      display: block;
    }
  }
  .loyalty_market__tier--1 {
  }
  .loyalty_market__tier--2 {
  }
  .loyalty_market__tier--3 {
  }
  .loyalty_market__points__container {
    background: url(/media/export/cms_2.0/loyalty/mobile/loyalty_marketing_bg02.jpg) no-repeat 0 0;
    @include swap_direction(padding, 46px 25px 55px 25px);
    .loyalty__panel__about__landing & {
      padding-bottom: 22px;
    }
  }
  .loyalty_market__points {
    background: $color-navy;
    color: $color-white;
    @include swap_direction(padding, 18px 10px 28px 10px);
  }
  .loyalty_market__points__header {
    font-family: $optimalight;
    font-size: 44px;
    line-height: 46px;
    letter-spacing: -0.05em;
  }
  .loyalty_market__points__offer {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @include swap_direction(margin, 18px 0);
    span {
      text-transform: none;
    }
  }
  .loyalty_market__points__list {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15em;
    margin-top: 20px;
  }
  .loyalty_market__points__item {
  }
  .loyalty_market__points__link__container {
    margin-top: 10px;
  }
  .loyalty_market__points__link {
    font-size: 14px;
    text-decoration: underline;
  }
}

.loyalty_market__excited__enthusiastic__benefits,
.loyalty_market__excited__benefits {
  font-size: 14px;
  margin-bottom: 5px;
}
