.hero-block__promo-copy .button.cta.cta__button.button--cta-custom {
  background: none;
  @include swap_direction(padding, 0);
  text-decoration: underline;
  height: auto;
  line-height: unset;
}

#colorbox.magic_spoky_popover {
  top: 0 !important;
  #cboxContent {
    @include swap_direction(padding, 0);
    background: transparent;
    #cboxLoadedContent {
      .flexslider {
        position: static;
        .slides {
          color: white;
          li {
            background: white;
            .header_title {
              position: absolute;
              padding-#{$ldirection}: 1%;
              top: 5%;
              width: 400px;
              @media #{$large-only} {
                padding-#{$ldirection}: 35px;
              }
              @media #{$xlarge-up} {
                top: 19%;
              }
              h1 {
                @media #{$xlarge-up} {
                  font-size: 128px;
                  margin-top: 15px;
                  letter-spacing: -8px;
                }
                span {
                  display: inline-block;
                  @media #{$xlarge-up} {
                    line-height: 1.07;
                  }
                }
              }
              h3 {
                line-height: 1.8;
                width: 300px;
                font-size: 30px;
                @media #{$large-up} {
                  width: 380px;
                  line-height: 2;
                  font-size: 30px;
                }
                @media #{$xlarge-up} {
                  font-size: 55px;
                  width: 630px;
                  line-height: 2em;
                  letter-spacing: -1.4px;
                }
              }
              p {
                font-weight: normal;
                text-transform: uppercase;
                @media #{$xlarge-up} {
                  font-size: 15px;
                  @include swap_direction(margin, 0 0 0 4px);
                  letter-spacing: 1px;
                }
              }
            }
            .header_image img {
            }
            .magic_smoky_steps {
              color: #040a2b;
              @include swap_direction(margin, 12px 0 0 0);
              @media #{$xlarge-up} {
                @include swap_direction(margin, 12px 0 0 57px);
              }
              width: auto;
              height: 184px;
              .steps {
                float: $ldirection;
                width: 33%;
                height: 100%;
                background-size: auto 100%;
                .title {
                  padding-#{$ldirection}: 125px;
                  @media #{$large-only} {
                    padding-#{$ldirection}: 100px !important;
                  }
                  h2 {
                    @media #{$xlarge-up} {
                      font-size: 60px;
                      letter-spacing: -0.05em;
                      line-height: 75px;
                      margin-#{$ldirection}: 7px;
                    }
                  }
                  p {
                    font-size: 18px;
                    width: 90%;
                    margin-top: 6px;
                    margin-#{$ldirection}: 7px;
                    line-height: 26px;
                    position: relative;
                    z-index: 99;
                  }
                }
              }
              .steps.step2 .title {
              }
            }
          }
          li.overlay1_content {
            .magic_smoky_steps {
              @media #{$xlarge-up} {
                @include swap_direction(margin, 12px 0 0 6%);
              }
            }
            .header_title {
              @media #{$xlarge-up} {
                padding-#{$ldirection}: 1%;
              }
            }
            .step1.steps {
              @media #{$xlarge-up} {
                width: 32.5%;
                .title p {
                  width: 95%;
                }
              }
            }
            .step2.steps {
              @media #{$xlarge-up} {
                width: 32.8%;
              }
            }
            .step3.steps {
              @media #{$xlarge-up} {
                width: 31.2%;
              }
            }
          }
          li {
            .steps .title {
            }
          }
          li.overlay2_content {
            .magic_smoky_steps {
              @media #{$xlarge-up} {
                @include swap_direction(margin, 12px 0 0 5%);
              }
            }
            .header_title {
              @media #{$xlarge-up} {
                padding-#{$ldirection}: 1%;
              }
            }
            .step1.steps {
              @media #{$xlarge-up} {
                width: 29.7%;
                .title p {
                  width: 95%;
                }
              }
            }
            .step1.steps .title {
              @media #{$xlarge-up} {
                margin-#{$ldirection}: 10px;
                padding-#{$ldirection}: 130px;
              }
            }
            .steps {
              @media #{$xlarge-up} {
                margin-#{$rdirection}: 0;
              }
            }
            .step2.steps .title {
              @media #{$xlarge-up} {
                margin-#{$ldirection}: 15px;
                padding-#{$ldirection}: 113px;
              }
              p {
                @media #{$xlarge-up} {
                  width: 100%;
                }
              }
            }
          }
          li.overlay3_content {
            .magic_smoky_steps {
              @media #{$xlarge-up} {
                @include swap_direction(margin, 12px 0 0 0.95%);
              }
            }
            .header_title {
              @media #{$xlarge-up} {
                padding-#{$ldirection}: 1%;
              }
            }
            .step1.steps {
              @media #{$xlarge-up} {
                width: 41%;
              }
            }
            .step1.steps .title {
              @media #{$xlarge-up} {
                padding-#{$ldirection}: 190px;
              }
              @media #{$large-only} {
                padding-#{$ldirection}: 140px !important;
              }
            }
          }
        }
        .flex-control-nav {
          display: none;
          margin-top: 60px;
          li {
            @include swap_direction(margin, 0 10px);
            a {
              height: 15px;
              width: 15px;
            }
            a.flex-active {
              background: white;
            }
          }
        }
      }
    }
    #cboxClose {
      display: none;
      #{$rdirection}: 20px;
      top: 100px;
      width: 105px;
      height: 40px;
      color: #7d7c78;
      font: 12px/0 'AkzidenzGrotesk W1G';
      text-indent: -9999px;
      text-transform: uppercase;
      background-position: top right;
      background-image: url('/media/export/cms_2.0/merch-windows/mpp-headers/Fall-2015/magic-smoky/MagicSmokyHeader/close_icon.png');
      letter-spacing: 1px;
      font-weight: normal;
      padding-#{$rdirection}: 30px;
    }
  }
}

#colorbox.magic_spoky_popover #cboxContent #cboxLoadedContent .flexslider .slides li.overlay1_content .step1.steps {
  background-image: url('/media/export/cms_2.0/merch-windows/mpp-headers/Fall-2015/magic-smoky/MagicSmokyHeader/magic_smoky_header_overlay1_step1.jpg');
  background-position: -7px 8px;
  background-repeat: no-repeat;
  width: 33.4%;
}

#colorbox.magic_spoky_popover
  #cboxContent
  #cboxLoadedContent
  .flexslider
  .slides
  li.overlay1_content
  .step1.steps
  .title {
  padding-#{$ldirection}: 126px;
}

#colorbox.magic_spoky_popover #cboxContent #cboxLoadedContent .flexslider .slides li.overlay1_content .step2.steps {
  background-image: url('/media/export/cms_2.0/merch-windows/mpp-headers/Fall-2015/magic-smoky/MagicSmokyHeader/magic_smoky_header_overlay1_step2.jpg');
  background-repeat: no-repeat;
  background-position: 0 8px;
}

#colorbox.magic_spoky_popover #cboxContent #cboxLoadedContent .flexslider .slides li.overlay2_content .step1.steps {
  background-image: url('/media/export/cms_2.0/merch-windows/mpp-headers/Fall-2015/magic-smoky/MagicSmokyHeader/magic_smoky_header_overlay2_step1.jpg');
  background-position: 0 6px;
  /*                background-size: 245px auto; */
  background-repeat: no-repeat;
  width: 30.5%;
}

#colorbox.magic_spoky_popover #cboxContent #cboxLoadedContent .flexslider .slides li.overlay3_content .step1.steps {
  background-image: url('/media/export/cms_2.0/merch-windows/mpp-headers/Fall-2015/magic-smoky/MagicSmokyHeader/magic_smoky_header_overlay3_step1.jpg');
  background-position: -12px 7px;
  background-repeat: no-repeat;
}

#colorbox.magic_spoky_popover #cboxContent #cboxLoadedContent .flexslider .slides li.overlay3_content .step2.steps {
  background-image: url('/media/export/cms_2.0/merch-windows/mpp-headers/Fall-2015/magic-smoky/MagicSmokyHeader/magic_smoky_header_overlay3_step2.jpg');
  background-repeat: no-repeat;
  background-position: -3px 7px;
  width: 28%;
  position: relative;
  #{$rdirection}: 33px;
}

#colorbox.magic_spoky_popover #cboxContent #cboxLoadedContent .flexslider .slides li.overlay2_content .step2.steps {
  background-image: url('/media/export/cms_2.0/merch-windows/mpp-headers/Fall-2015/magic-smoky/MagicSmokyHeader/magic_smoky_header_overlay2_step2.jpg');
  /*                background-size: 205px auto; */
  background-repeat: no-repeat;
  background-position: -5px 8px;
  width: 34.6%;
}

#colorbox.magic_spoky_popover #cboxContent #cboxLoadedContent .flexslider .slides li .step3.steps {
  background-image: url('/media/export/cms_2.0/merch-windows/mpp-headers/Fall-2015/magic-smoky/MagicSmokyHeader/magic_smoky_header_overlay1_step3.jpg');
  background-position: -11px 0;
  background-repeat: no-repeat;
  position: relative;
}

#colorbox.magic_spoky_popover #cboxContent #cboxLoadedContent .flexslider .slides li.overlay3_content .step3.steps {
  width: 30%;
  position: relative;
  #{$rdirection}: 29px;
  background-position: -5px 0;
}

#colorbox.magic_spoky_popover #cboxContent #cboxLoadedContent .flexslider .slides li .step3.steps .title {
  padding-#{$ldirection}: 123px;
}

#colorbox.magic_spoky_popover #cboxContent #cboxLoadedContent .flexslider .slides li.overlay1_content .step2 .title {
  padding-#{$ldirection}: 116px;
}

#colorbox.magic_spoky_popover #cboxContent #cboxLoadedContent .flexslider .slides li .smoky_qv {
  font-weight: bold;
  text-decoration: underline;
}

#cboxOverlay.magic_spoky_popover,
#colorbox.magic_spoky_popover,
#colorbox.magic_spoky_popover #cboxWrapper {
  z-index: 996;
}

#colorbox.magic_spoky_popover .flexslider .flex-direction-nav {
  a {
    opacity: 1;
    top: 59%;
    height: 33px;
  }
  .flex-prev {
    #{$ldirection}: 0;
    background-position: top right;
    background-image: url('/media/export/cms_2.0/merch-windows/mpp-headers/Fall-2015/magic-smoky/MagicSmokyHeader/left_arrow.png');
  }
  .flex-next {
    #{$rdirection}: 0;
    background-position: top left;
    background-image: url('/media/export/cms_2.0/merch-windows/mpp-headers/Fall-2015/magic-smoky/MagicSmokyHeader/right_arrow.png');
  }
  .flex-disabled {
    display: none;
  }
}

#colorbox.magic_spoky_popover .flexslider:hover .flex-direction-nav {
  a {
    opacity: 1;
    top: 59%;
  }
  .flex-prev {
    #{$ldirection}: 0;
  }
  .flex-next {
    #{$rdirection}: 0;
  }
}
