@mixin opc_update__checkout-cart-items {
  ///
  /// Checkout Cart Items
  ///
  .page-wrapper-checkout .checkout {
    .cart-items {
      @include clearfix;
    }
    .cart-header {
      @include hidden;
    }
    .cart-item {
      @include clearfix;
      margin: $checkout-spacing--extra-large 0;
      padding: 0;
      width: 100%;
      border-bottom: none;
      &__thumb {
        float: $checkout-left;
        margin-#{$checkout-right}: 2%;
        padding: 0;
        width: 25%;
        @media #{$large-up} {
          width: 30%;
        }
      }
      &__thumb-image {
        width: 100%;
      }
      &__desc {
        float: $checkout-right;
        position: relative;
        width: 73%;
        font-family: $optimaregular;
        @media #{$large-up} {
          width: 68%;
        }
      }
      &__product-name {
        margin-right: $checkout-spacing--extra-large;
        margin-bottom: $checkout-spacing--extra-small;
        font-size: 15px;
        line-height: 1.5em;
        @media #{$large-up} {
          font-size: 22px;
          line-height: 1.15em;
        }
      }
      &__product-subname {
        margin-right: $checkout-spacing--extra-large;
        margin-bottom: 0;
        font-size: 15px;
        line-height: 1.15em;
        @media #{$large-up} {
          font-size: 16px;
        }
      }
      &__size,
      &__price,
      &__skin_type {
        display: block;
        margin: 0;
        font-size: 15px;
        font-family: $akzidenz;
        @media #{$large-up} {
          font-size: 16px;
        }
      }
      &__color {
        display: block;
        margin: 0;
        margin-bottom: $checkout-spacing--small;
        font-size: 12px;
      }
      &__swatch {
        margin-top: -2px;
      }
      &__price {
        margin-bottom: $checkout-spacing--small;
        font-family: $akzidenz;
        font-size: $checkout-font-size--base;
        letter-spacing: 0.05em;
        @media #{$large-up} {
          width: auto;
          letter-spacing: 0.15em;
        }
      }
      &__price-label {
        @include hidden;
      }
      &__price--old {
        text-decoration: line-through;
      }
      &__remove-form {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
      }
      .remove_kit,
      .remove_link {
        display: block;
        width: 16px;
        height: 16px;
        background-image: url('/media/images/cart_enhancement/close_icon.png');
        background-repeat: no-repeat;
        background-size: contain;
        text-indent: -9999px;
      }
      &__total {
        position: static;
        float: $checkout-right;
        font-family: $akzidenz;
        font-size: $checkout-font-size--base;
        font-weight: bold;
        letter-spacing: 0.05em;
        @media #{$large-up} {
          margin-bottom: $checkout-spacing--small;
          width: auto;
          letter-spacing: 0.15em;
        }
      }
      &__qty-replenishment-group {
        @include clearfix;
        float: $checkout-right;
        width: 73%;
        @media #{$large-up} {
          width: 68%;
        }
      }
      &__qty {
        float: $checkout-left;
        top: 0;
        margin-right: 3%;
        padding: 0;
        .sample_qty {
          display: inline-block;
          padding: 10px 0 0 0;
        }
      }
      &__qty-label,
      &__qty-value {
        display: none;
      }
      &__replenishment {
        float: $checkout-right;
        padding: 0;
        text-align: $checkout-right;
        width: 80%;
        span.selectBox-label {
          @include swap_direction(padding, 0 20px 0 6px);
        }
        .replen_notice {
          width: 100%;
          @media #{$large-up} {
            padding: $checkout-spacing--small 0;
          }
        }
        @media #{$large-up} {
          span.selectBox-label {
            @include swap_direction(padding, 0 20px 0 10px);
          }
        }
      }
      &__error--out-of-stock {
        float: right;
        margin: $checkout-spacing--small 0;
        width: 72%;
        p {
          font-size: $checkout-font-size--base;
          line-height: 1.25em;
        }
      }

      // For the quantity and replenishment dropdowns specifically.
      select,
      .selectbox,
      .selectBox {
        background-position: right -6px center;
        padding-#{$checkout-left}: 8px;
        width: 100%;
      }
    } // .cart-item

    ///
    /// Checkout Sidebar Cart Items
    ///
    &__sidebar {
      .kit-sub__items {
        .cart-item {
          padding: 0;
          &__qty {
            #{$rdirection}: 0;
          }
        }
      }
      .cart-item {
        padding: 0 $checkout-spacing--extra-large;
        &__qty {
          position: absolute;
          top: 24px;
          right: $checkout-spacing--extra-large;
          margin-right: 0;
          padding-right: 0;
          width: auto;
        }
        &__qty-label,
        &__qty-value {
          display: inline-block;
        }
        &__price,
        &__qty-dropdown {
          display: none;
        }
        &__replenishment {
          display: none;
          @media #{$large-up} {
            display: block;
            width: 68%;
            text-align: left;
          }
          .replenishment_description {
            text-align: left;
          }
        }
        &__total {
          position: absolute;
          top: 0;
          right: 0;
        }
      } // .cart-item
    } // &__sidebar
  } // .page-wrapper-checkout .checkout
  #index-checkout .custom-kit,
  #viewcart .custom-kit {
    .kit-item__sub-items {
      .cart-item {
        &__qty-replenishment-group,
        &__remove-form,
        &__total {
          display: none;
          &.custom-kit-total {
            display: block;
          }
        }
      }
      .kit-item__desc {
        display: flex;
        .product_subname {
          display: none;
        }
        .product__price--non-sale {
          text-decoration: none;
          &.discount-applied {
            text-decoration: line-through;
          }
        }
      }
      .cart-item__price {
        word-break: inherit;
        &.cart-item__price--ca-price {
          display: flex;
          flex-direction: column;
          width: auto;
          @media #{$medium-up} {
            flex-direction: row;
          }
        }
      }
      .viewcart-remove__kit--form {
        position: absolute;
        #{$rdirection}: 0;
        top: 10px;
      }
      .cart-item:not(:last-child) {
        margin: 25px 0;
      }
      .kit-sub__items {
        margin: 20px 0 0;
        width: 68%;
        .cart-item__size-shade-qty-group {
          .qty-container {
            display: none;
          }
          .cart-item__size {
            border-right: 0;
          }
        }
        .desc.column.cart-item__desc {
          width: 100%;
        }
        .engraving-button-container,
        .cart-item__price {
          display: none;
        }
      }
    }
    .custom-kit__disclaimer {
      font-size: 14px;
      margin-top: 4px;
    }
  }
  #index-checkout {
    .kit-item__sub-items {
      .viewcart-remove__kit--form {
        display: none;
      }
    }
    .custom-kit {
      .custom-kit__disclaimer {
        @media #{$large-up} {
          float: $rdirection;
          margin-top: 7px;
        }
      }
    }
  }
} // @mixin opc_update__checkout-cart-items
#order_detail {
  .cart-item__sub-items {
    position: absolute;
  }
  .kit-sub__items {
    margin-#{$ldirection}: 105px;
    margin-bottom: 20px;
    width: 75%;
    float: $ldirection;
    .cart-item__thumb {
      display: none;
    }
    .desc.column.cart-item__desc {
      width: 100%;
    }
  }
  .order-details--cart {
    .cart-item {
      img.cart-item__thumb.custom-kit {
        margin-bottom: 0;
      }
    }
  }
  .custom-kit-qty {
    position: absolute;
    bottom: 0;
    #{$ldirection}: 105px;
  }
  .cart-item__cell {
    padding-#{$ldirection}: 0 !important;
  }
}
