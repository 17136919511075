$color-white-dark-opacic: rgba($color-white, 0.65);

@keyframes "moveup" {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 140px;
  }
}
@keyframes "fadein" {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 40px;
    opacity: 1;
  }
}

.product-kit {
  color: $color-navy;
  @media #{$medium-up} {
    margin: -$header-collapsed-height auto 30px;
    @media #{$medium-up} {
      margin-top: 0;
    }
  }
  &__content-wrapper,
  &__selected-product,
  &__selected-product-content,
  &__selected-product-errors,
  &__cart-detail {
    @media #{$medium-up} {
      max-width: 942px;
    }
  }
  &__selected-product-content,
  &__price-atb-wrapper,
  &__price-atb-thumbs {
    display: flex;
  }
  &__selected-product-content {
    justify-content: space-between;
    flex-direction: column;
    @media #{$medium-up} {
      width: 942px;
    }
  }
  &__price-atb-wrapper {
    flex-direction: column;
    align-items: end;
    @media #{$medium-up} {
      flex-direction: row;
      align-items: center;
    }
  }
  &__disclaimer-wrapper {
    margin-top: 16px;
    margin-bottom: 6px;
    @media #{$medium-up} {
      margin-top: 24px;
      width: 100%;
    }
  }
  &__banner-content {
    margin: 0 auto;
  }
  &__banner-image {
    width: 100%;
    margin-bottom: 30px;
    min-height: 200px;
    outline: none;
    @media #{$medium-up} {
      margin-bottom: 0;
      width: 67%;
      min-height: 370px;
    }
  }
  &__banner {
    align-items: center;
    width: 100%;
    @media #{$medium-up} {
      padding-top: 86px;
      display: flex;
      margin-bottom: 0;
    }
    .multi_use_mpp_v1 .module_block {
      margin-bottom: 0;
    }
  }
  &__content {
    max-width: 80%;
    margin: 0 auto;
    @media #{$medium-up} {
      display: inline;
    }
  }
  &__products.slick-slider {
    margin-bottom: 0;
    @media #{$medium-up} {
      padding: 0 9px;
      width: 100%;
      margin-bottom: 12px;
    }
  }
  &__banner-info {
    width: auto;
    margin: 0 30px;
    @media #{$medium-up} {
      @include swap_direction(margin, 82px 0 0 3%);
      text-align: $ldirection;
    }
    @media #{$medium-only} {
      padding-top: 18px;
    }
  }
  &__products-list {
    @media #{$medium-up} {
      max-width: 255px;
      margin-#{$rdirection}: 16px;
    }
    .product-kit__label & {
      @media #{$medium-up} {
        max-width: 100%;
      }
    }
    .slick-slide & {
      display: flex !important;
      flex-direction: column;
      height: 100%;
      @media #{$medium-up} {
        max-height: unset;
      }
    }
  }
  &__image-container {
    position: relative;
    width: 92%;
    height: auto;
    cursor: pointer;
    background: $color-soft-mist;
    @media #{$medium-up} {
      width: 255px;
      height: 255px;
      margin-bottom: 8px;
    }
    .product-kit-small__image {
      visibility: hidden;
      height: 0;
    }
    .product-kit__image {
      @media #{$medium-up} {
        height: 100%;
        width: auto;
        margin: 0 auto;
      }
    }
  }
  &__image {
    width: auto;
    height: 158px;
    margin: 0 auto;
    @media #{$medium-up} {
      width: 100%;
      height: auto;
      margin-bottom: 0;
    }
  }
  &__menu-item {
    padding: 0;
    @media #{$medium-up} {
      border-width: 2px;
      position: relative;
      margin-bottom: 16px;
    }
    &.active {
      border-bottom: 1px solid $color-medium-gray;
      padding-bottom: 20px;
      @media #{$medium-up} {
        padding-bottom: unset;
      }
    }
    &:last-of-type {
      @media #{$medium-up} {
        border-width: 2px;
      }
    }
  }
  .product-oos {
    opacity: 0.6;
    .product-kit__bottom-container {
      pointer-events: none;
    }
  }
  &__selected-product {
    letter-spacing: 1px;
    margin: 20px 30px;
    padding: 0;
    @media #{$medium-up} {
      margin: 20px auto;
    }
    .product-kit__sticky-footer & {
      margin: 0;
      @media #{$medium-up} {
        margin: 20px auto;
      }
    }
  }
  &__banner-name {
    font-size: 35px;
    text-align: center;
    font-weight: normal;
    text-shadow: 0 0 0 $color-black;
    margin: 0 auto 15px;
    padding: 0;
    line-height: 36px;
    @media #{$medium-up} {
      margin: 0 0 15px;
      max-width: 100%;
      text-align: $ldirection;
      font-size: 50px;
      line-height: 45px;
    }
  }
  &__content-wrapper {
    width: auto;
    margin: 0 16px;
    @media #{$medium-up} {
      width: 100%;
      padding-top: 0;
      margin: 0 auto;
    }
    @media #{$device-ipad_mini} {
      width: auto;
      margin: 0 30px;
    }
    .product-kit__error-message {
      display: block;
    }
  }
  &__banner-desc {
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    margin: 0 auto;
    font-family: $akzidenz;
    letter-spacing: 0.025em;
    text-shadow: 0 0 0 $color-black;
    width: 100%;
    margin-bottom: 25px;
    @media #{$medium-up} {
      @include swap_direction(padding, 0 0 0 2px);
      margin: 0;
      text-align: $ldirection;
    }
  }
  &__image-content {
    @media #{$medium-up} {
      display: inline;
    }
  }
  &__label {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-medium-gray;
    height: auto;
    padding-top: 10px;
    margin-bottom: 25px;
    @media #{$medium-up} {
      padding: 0 9px 7px;
      align-items: center;
    }
    .active & {
      border-bottom: none;
    }
    .product-kit__skintypes {
      display: none;
    }
    .button {
      display: none;
    }
  }
  &__label-info {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  &__list-no {
    font-family: $optimaregular;
    display: inline-block;
    padding: 0;
    font-size: 42px;
    background-color: $color-light-gray-white;
    min-width: 75px;
    min-height: 75px;
    width: 75px;
    height: 75px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    line-height: 1;
    @media #{$medium-up} {
      margin: 0;
    }
    &--active {
      background-color: $color-navy;
      color: $color-white;
    }
    .selected & {
      background-color: $color-navy;
      color: $color-white;
    }
  }
  &__first-phrase {
    padding-#{$ldirection}: 16px;
    font-family: $akzidenz;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.15em;
    line-height: 20px;
    text-transform: uppercase;
    @media #{$medium-up} {
      font-size: 20px;
      line-height: 26px;
    }
  }
  a.selectBox,
  select.selectbox {
    &.product-kit__skintype-select,
    &.product-quantity__dropdown {
      width: 100%;
      .selectBox-label {
        font-weight: normal;
      }
      @media #{$medium-up} {
        border-color: $color-medium-gray-blue;
        height: 33px;
        line-height: 2.7;
      }
    }
  }
  .product-kit__skintype-select {
    margin-top: 20px;
    line-height: 3.3;
    height: 40px;
    border-color: $color-navy;
    font-weight: 600;
  }
  &__product-thumb {
    max-width: 100%;
    display: inline-flex;
    @media #{$medium-up} {
      width: auto;
    }
    .product-kit__image {
      display: none;
    }
    .product-kit-small__image {
      visibility: visible;
      width: auto;
      height: 75px;
      position: absolute;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      margin: 0 auto;
    }
    .product-kit {
      &__product-description,
      &__product-reviews,
      &__product-price {
        display: none;
      }
      &__product-size {
        display: block;
      }
      &__products-list {
        position: relative;
        height: auto;
        margin-bottom: 5px;
        display: flex !important;
        @media #{$medium-up} {
          width: auto !important;
          margin-bottom: 0;
        }
        .product-kit__label & {
          margin-bottom: 16px;
          @media #{$medium-up} {
            margin-bottom: 5px;
          }
        }
      }
      .icon-selected {
        display: none;
      }
      &__image-container {
        margin-#{$rdirection}: 10px;
        background-color: $color-light-gray-white;
        min-width: 75px;
        min-height: 75px;
        width: 75px;
        height: 75px;
        display: inline-block;
        border: none;
        vertical-align: top;
        overflow: hidden;
        position: relative;
        @media #{$medium-up} {
          margin-#{$rdirection}: 15px;
        }
        .product-kit__image {
          height: 100%;
          width: 100%;
          vertical-align: inherit;
          @media #{$medium-up} {
            margin-top: 0;
          }
        }
      }
      &__info {
        display: inline-block;
        width: auto;
        @media #{$medium-up} {
          width: auto;
          margin-#{$ldirection}: 0;
        }
        .selected & {
          @media #{$medium-up} {
            margin-top: 8px;
          }
        }
        .product-kit__label & {
          margin-top: -5px;
          @media #{$medium-up} {
            margin-top: unset;
          }
        }
        .product-kit {
          &__first-phrase {
            padding: 0;
          }
          &__product-title {
            font-size: 18px;
            letter-spacing: 0.01em; // design is -0.02em; but pixel perfect match is 0.01em
            @media #{$medium-up} {
              font-size: 22px;
              line-height: 12px;
              margin-bottom: 2px;
            }
            .product-kit__label & {
              margin-top: 4px;
              @media #{$medium-up} {
                margin-top: unset;
              }
            }
          }
          &__product-subtitle {
            font-size: 18px;
            letter-spacing: 0.02em;
            @media #{$medium-up} {
              font-size: 16px;
            }
          }
          &__product-quantity,
          &__product-shades {
            display: none;
          }
        }
      }
    }
  }
  &__kit-selected-toggle {
    @include svg-icon-inline-mask('check_mark.svg');
    mask-size: contain;
    height: 12px;
    width: 18px;
    background-color: $color-black;
    display: none;
    margin-right: 10px;
    .selected & {
      display: block;
    }
  }
  &__product-info {
    margin: 0;
    letter-spacing: -0.05em;
    @media #{$medium-up} {
      margin: 15px 0 12px;
    }
    &.pc-hidden {
      @media #{$medium-up} {
        display: none;
      }
      .hide-oos-size {
        display: none;
      }
      .product-kit__product-size {
        display: none;
      }
    }
  }
  &__product-title {
    font-size: 18px;
    letter-spacing: 0;
    font-family: $optimaregular;
    margin-top: 8px;
    @media #{$medium-up} {
      margin-top: unset;
    }
    .product-kit__products & {
      @media #{$medium-up} {
        padding: 0 8px;
      }
    }
  }
  &__product-subtitle {
    font-size: 18px;
    font-family: $optimaregular;
    letter-spacing: 0;
    .product-kit__products & {
      @media #{$medium-up} {
        padding: 0 8px;
      }
    }
    .product-kit__label & {
      font-family: $akzidenz;
    }
  }
  &__product-reviews {
    .product-kit__products &:not(:empty) {
      margin-top: 8px;
      @media #{$medium-up} {
        padding: 0 8px;
      }
    }
  }
  &__product-description {
    font-family: $akzidenz;
    font-size: 16px;
    letter-spacing: 0.025em;
    margin: 8px 0;
    display: none;
    @media #{$medium-up} {
      display: block;
    }
  }
  &__product-price {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
    @media #{$medium-up} {
      margin-bottom: 8px;
    }
    .product-kit__products & {
      @media #{$medium-up} {
        padding: 0 8px;
      }
    }
  }
  &__product-link {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.15em;
    text-decoration: underline;
    margin-top: 8px;
    @media #{$medium-up} {
      padding: 0 8px;
    }
    &::after {
      content: url($svg-path + 'pdp-link.svg');
      position: absolute;
      width: 12px;
      height: 12px;
      margin-top: 4px;
      padding-#{$ldirection}: 10px;
      @media #{$medium-up} {
        margin-top: 2px;
      }
      @media #{$xlarge-up} {
        margin-top: 1px;
      }
    }
    .product-kit__label & {
      display: none;
    }
    &.product-kit__product-link--desktop {
      display: none;
      @media #{$medium-up} {
        display: inline-block;
      }
      .product-kit__label & {
        @media #{$medium-up} {
          display: none;
        }
      }
    }
    &.product-kit__product-link--mobile {
      @media #{$medium-up} {
        display: none;
      }
    }
  }
  &__product-stars,
  &__star-rating {
    @include svg-icon-inline-mask('el_star.svg');
    display: inline-block;
    position: relative;
    width: 75px;
    height: 14px;
    line-height: 14px;
    -webkit-mask-repeat: repeat-x;
    mask-repeat: repeat-x;
  }
  &__product-stars {
    background: $color-light-gray;
  }
  &__star-rating {
    background: $color-deepcove;
  }
  &__sku-info {
    margin-#{$rdirection}: 5px;
  }
  &__size {
    font-weight: 400;
    margin-right: 8px;
  }
  &__unit-price {
    font-weight: normal;
    margin-#{$rdirection}: 100px;
    text-align: #{$rdirection};
    @media #{$medium-up} {
      flex-grow: 10;
      margin-#{$rdirection}: 5px;
    }
  }
  &__product-size {
    font-size: 15px;
    font-weight: 400;
    display: none;
  }
  &__info {
    letter-spacing: 0.1em;
    width: 88%;
    @media #{$medium-up} {
      margin: 0;
    }
    .disable-selectbox-picker {
      opacity: 0.3;
      pointer-events: none;
    }
  }
  &__number-of-products {
    display: inline;
    text-transform: uppercase;
    font-family: $akzidenz;
    @media #{$medium-up} {
      margin-#{$ldirection}: 1px;
      letter-spacing: 2px;
      font-size: 12px;
    }
  }
  &__total-price {
    display: flex;
    gap: 5px;
    font-weight: 700;
    font-size: 13px;
    .strike-out-price {
      color: $color-dark-gray;
      font-size: 13px;
      text-decoration: line-through;
      @media #{$medium-up} {
        margin-#{$rdirection}: 14px;
      }
    }
  }
  &__discount-price {
    font-size: 13px;
    color: $color-tmo-red;
    @media #{$medium-up} {
      margin-#{$rdirection}: 14px;
    }
  }
  &__disclaimer {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0;
    order: 2;
    text-shadow: 0 0 0 $color-black;
    @media #{$medium-up} {
      display: inline-block;
      letter-spacing: 0.02em;
      padding: 0;
      font-weight: normal;
      word-spacing: -1.4px;
    }
    @media #{$xlarge-up} {
      order: 1;
    }
  }
  &__cta-container {
    order: 1;
    width: unset;
    @media #{$medium-up} {
      width: 25%;
      display: flex;
      align-items: center;
    }
    @media #{$xlarge-up} {
      order: 2;
      width: max-content;
    }
    .product-kit__price-atb-wrapper & {
      @media #{$medium-up} {
        margin-#{$ldirection}: 20px;
      }
    }
    .product-kit__sticky-cart-detail {
      display: flex;
      justify-content: flex-end;
      @media #{$medium-up} {
        display: none;
      }
    }
    .product-kit__sticky-footer & {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      width: 50%;
      position: absolute;
      #{$rdirection}: 16px;
      top: 0;
      @media #{$medium-up} {
        width: unset;
        position: unset;
        #{$rdirection}: unset;
        top: unset;
      }
    }
  }
  &__products {
    touch-action: auto;
    .product-kit {
      &__bottom-container {
        bottom: 0;
        width: 100%;
        margin-top: auto;
        .single-sku-shade {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 12px;
          line-height: 20px;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          @media #{$medium-up} {
            letter-spacing: 0.15em;
          }
        }
      }
      &__info {
        min-height: 304px;
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        @media #{$medium-up} {
          width: 100%;
          min-height: 214px;
        }
      }
      &__product-shades {
        .selectBox {
          width: 100%;
        }
        select {
          width: 100%;
          .selectbox-option {
            color: $color-white;
            font-weight: bold;
            &__default {
              color: $color-black;
            }
          }
        }
      }
      &__button {
        background-color: $color-navy;
        color: $color-white;
        font-size: 13px;
        font-weight: bold;
        margin-top: 0;
        width: 100%;
        &.button--disabled {
          background-color: $color-light-gray-white;
        }
        &.button-selected {
          background: url('/media/images/icons/checkbox.svg') no-repeat 23% 50%;
          border: 1px solid $color-navy;
          color: $color-navy;
          pointer-events: none;
          @media #{$medium-up} {
            background-position: 25% 50%;
          }
          @media #{$xlarge-up} {
            background-position: 32% 50%;
          }
        }
      }
    }
    .product-single {
      .product-kit {
        &__bottom-container {
          bottom: 0;
        }
      }
    }
    .product-kit__first-phrase {
      display: none;
    }
  }
  &__product-shades,
  &__product-quantity {
    margin: 7px 0;
    @media #{$medium-up} {
      margin: 7px;
    }
    .selectBox-label {
      letter-spacing: 0.05em;
      @media #{$medium-up} {
        letter-spacing: 0.15em;
      }
    }
  }
  &__product-shades {
    margin: 0 0 3px;
    @media #{$medium-up} {
      margin: 7px;
    }
    .selectBox-options li a {
      padding: 3px 0;
    }
  }
  &__error-message {
    display: none;
  }
  .sticky-img,
  .selected-img {
    background: $color-light-gray-white;
    width: 30px;
    bottom: 1px;
    position: relative;
    margin: 0 auto;
    @media #{$medium-up} {
      width: 34px;
    }
  }
  &__sticky {
    &-footer {
      @include swap_direction(box-shadow, 0 0 10px $color-sticky-footer-shadow);
      #{$ldirection}: 0;
      background: $color-white;
      bottom: 38px;
      min-height: 82px;
      overflow: hidden;
      position: fixed;
      text-align: #{$ldirection};
      width: 100%;
      z-index: 100;
      @media #{$medium-up} {
        bottom: 42px;
        display: flex;
        justify-content: center;
      }
      &.move-up {
        animation: moveup 2s;
        animation-fill-mode: forwards;
        opacity: 1;
      }
      .selection-info {
        display: none;
      }
    }
    &-inner-wrap {
      max-width: 942px;
      justify-content: space-between;
      padding: 8px 16px 16px;
      position: relative;
      height: 94px;
      @media #{$medium-up} {
        display: flex;
        width: 942px;
        height: 82px;
        padding: 0;
      }
    }
    &-items {
      display: inline-block;
      width: 30px;
      height: 30px;
      position: relative;
      margin-#{$rdirection}: 8px;
      @media #{$medium-up} {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        margin-#{$rdirection}: 10px;
      }
      &--products {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        width: auto;
        position: relative;
        top: 18px;
        @media #{$medium-up} {
          flex-wrap: nowrap;
          justify-content: space-between;
          width: 100%;
          position: unset;
          top: unset;
        }
        .product-kit__sticky-cart-detail {
          display: none;
          @media #{$medium-up} {
            display: block;
            margin-#{$rdirection}: 20px;
          }
        }
        .product-kit__price-atb-thumbs & {
          align-items: baseline;
          margin-top: 16px;
          @media #{$medium-up} {
            margin-top: unset;
          }
        }
      }
      &--container {
        display: flex;
        order: 2;
        width: 100%;
        @media #{$medium-up} {
          order: 1;
          padding-top: 0;
          width: auto;
        }
      }
      &.hidden {
        display: none;
      }
    }
    &-item {
      position: absolute;
      bottom: 0;
      &:nth-child(1) {
        z-index: 2;
        width: 30px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        @media #{$medium-up} {
          width: 50px;
          height: 48px;
        }
      }
      &.selected {
        &:nth-child(1) {
          border: 1px solid rgba(0, 0, 0, 0.25);
          @media #{$medium-up} {
            border: none;
          }
        }
      }
      .progress-bar {
        display: block;
        height: 3px;
        margin-top: 5px;
        width: 90%;
        &.inactive {
          background: $color-light-gray;
        }
        &.active {
          background: $color-navy;
        }
      }
    }
    &-count {
      border-style: dashed;
      border-color: $color-black-opacity-25;
      border-width: 2px;
      background-color: $color-white;
      color: $color-navy;
      display: block;
      height: 30px;
      padding: 0;
      text-align: center;
      width: 30px;
      @media #{$medium-up} {
        width: 50px;
        height: 50px;
      }
      &.mobile-hidden {
        display: none;
        @media #{$medium-up} {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .selected & {
        border-width: 1px;
        border-style: solid;
        background-color: $color-light-gray-white;
      }
    }
    &-cart-detail {
      margin: 10px 0;
      order: 1;
      width: 100%;
      @media #{$medium-up} {
        order: 2;
        width: auto;
      }
    }
  }
  &__selected {
    &-item {
      position: absolute;
      bottom: 0;
      &:nth-child(1) {
        z-index: 2;
        width: 30px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        @media #{$medium-up} {
          width: 50px;
          height: 48px;
        }
      }
      &.selected {
        &:nth-child(1) {
          border: 1px solid rgba(0, 0, 0, 0.25);
          @media #{$medium-up} {
            border: none;
          }
        }
      }
    }
    &-count {
      border-style: dashed;
      border-color: $color-black-opacity-25;
      border-width: 2px;
      background-color: $color-white;
      color: $color-navy;
      display: block;
      height: 30px;
      padding: 0;
      text-align: center;
      width: 30px;
      @media #{$medium-up} {
        width: 50px;
        height: 50px;
      }
      &.mobile-hidden {
        display: none;
        @media #{$medium-up} {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .selected & {
        border-width: 1px;
        border-style: solid;
        background-color: $color-light-gray-white;
      }
    }
    &-items {
      display: inline-block;
      width: 30px;
      height: 30px;
      position: relative;
      margin-#{$rdirection}: 8px;
      @media #{$medium-up} {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        margin-#{$rdirection}: 10px;
      }
      &--products {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        visibility: hidden;
        @media #{$medium-up} {
          flex-wrap: nowrap;
          justify-content: space-between;
          visibility: visible;
        }
      }
      &--container {
        display: flex;
        order: 2;
        width: 100%;
        @media #{$medium-up} {
          order: 1;
          padding-top: 0;
          width: auto;
        }
      }
    }
  }
  &__cart-detail {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 30px 15px;
    padding: 10px 0px;
    width: auto;
    @media #{$medium-up} {
      border-width: 2px;
      justify-content: space-between;
      margin: 20px auto 0;
      padding-bottom: 0;
    }
    @media #{$device-ipad_mini} {
      margin: 20px 17px 15px;
    }
  }
  &__add-to-bag {
    background-color: $color-light-gray-white;
    color: $color-white;
    width: 100%;
    min-width: 132px;
    margin: 6px 0;
    padding: 0;
    line-height: get-line-height(14px, 21px);
    font-size: 14px;
    font-weight: bold;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @media #{$medium-up} {
      font-size: 13px;
      letter-spacing: 2px;
      line-height: get-line-height(12px, 14px);
      padding: 0 16px;
      margin: 19px 0;
      width: max-content;
    }
    &.button--added {
      color: $color-tmo-dark-grey;
    }
    .added-to-bag {
      pointer-events: none;
    }
    .product-kit__sticky-footer & {
      margin: 0;
      max-width: 150px;
      @media #{$medium-up} {
        margin: 19px 0;
        max-width: unset;
      }
    }
  }
  &__add-to-bag.active {
    background-color: $color-navy;
    pointer-events: auto;
    cursor: pointer;
    &.fade-in {
      animation: fadein 2s;
      animation-fill-mode: forwards;
    }
  }
  .slick-arrow {
    width: 32px;
    height: 64px;
    display: block;
    background-image: none;
    background-color: rgba($color-white, 0.5);
    z-index: 91;
    top: 25%;
    &:before {
      content: '';
      display: inline-block;
      border-#{$rdirection}: 2px solid $color-navy;
      border-bottom: 2px solid $color-navy;
      width: 15px;
      height: 15px;
      transform: rotate(135deg);
      opacity: 1;
      color: unset;
      #{$ldirection}: 5px;
      position: relative;
    }
    &.slick-disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
  .slick-prev {
    #{$ldirection}: 0;
    top: 20%;
    @media #{$medium-up} {
      top: 28%;
      #{$ldirection}: 9px;
    }
    &::before {
      content: '';
    }
  }
  .slick-next {
    top: 20%;
    #{$rdirection}: 0;
    @media #{$medium-up} {
      #{$rdirection}: 9px;
      top: 28%;
    }
    &::before {
      transform: rotate(-45deg);
      #{$ldirection}: auto;
      #{$rdirection}: 5px;
    }
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    height: auto;
    display: flex;
    > div {
      height: auto;
    }
  }
  .slick-list {
    padding: 0;
  }
  .icon-edit,
  .icon-select,
  .icon-close {
    --thickness: 2px;
    --color: $color-navy;
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    background: conic-gradient(from 90deg at var(--thickness) var(--thickness), #0000 90deg, #000 0)
      calc(100% + var(--thickness) / 2) calc(100% + var(--thickness) / 2) /
      calc(50% + var(--thickness)) calc(50% + var(--thickness));
    @media #{$medium-up} {
      --thickness: 3px;
      margin: 0 10px;
    }
  }
  .icon-close {
    transform: rotate(45deg);
  }
  .icon-option {
    font-family: $akzidenz;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-#{$rdirection}: 3px;
    display: none;
    @media #{$medium-up} {
      display: inline-block;
    }
  }
  .icon-selected {
    display: none;
  }
  .accordion {
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    margin-top: 4px;
    font-family: $optimaregular;
    letter-spacing: -0.05em;
    @media #{$medium-up} {
      @include swap_direction(padding, 0 0 0 22px);
      position: absolute;
      margin: 0;
      font-size: 22px;
    }
  }
  .mobile-hidden {
    @media #{$small-only} {
      display: none;
    }
  }
  .product-kit__products-list.selected {
    .product-kit__image-container {
      .icon-selected {
        width: 100%;
        height: 100%;
        display: inline-block;
        position: absolute;
        #{$ldirection}: 0;
        top: 0;
        -webkit-box-shadow: inset 0 0 0 2px $color-black;
        -moz-box-shadow: inset 0 0 0 2px $color-black;
        box-shadow: inset 0 0 0 2px $color-black;
      }
    }
  }
  .filtered-swatch {
    border: 1px solid $color-black;
    width: 15px;
    height: 15px;
    border-radius: 8px;
    display: block;
    float: $ldirection;
    margin: 10px 10px 0;
    @media #{$medium-up} {
      @include swap_direction(margin, 8.5px 3px 3px 18px);
    }
    @media #{$xlarge-up} {
      @include swap_direction(margin, 8.5px 0 0 20px);
    }
  }
  .single-sku-shade {
    margin: 0;
    @media #{$medium-up} {
      margin: 0 0 3px;
    }
    .filtered-swatch {
      @include swap_direction(margin, -1px 10px 0 0);
      width: 28px;
      height: 28px;
      padding: 2px;
      border-radius: 50%;
      @media #{$medium-up} {
        @include swap_direction(margin, 2px 10px 3px 0);
      }
      @media #{$xlarge-up} {
        @include swap_direction(margin, 2px 8px 4px 0);
      }
      .swatch-circle {
        display: inline-block;
        border-radius: 50%;
        width: 22px;
        height: 22px;
      }
    }
  }
}
.kit-sku-oos {
  background: #{$color-light-gray};
  opacity: 0.3;
  pointer-events: none;
}
.section-custom-kit {
  @media #{$medium-up} {
    .page-header {
      background: $color-white-opacic;
    }
  }
}

#order_detail {
  .cart-item-table {
    border-bottom: 0;
    tbody {
      border-top: 0;
    }
    td {
      @media #{$large-up} {
        text-align: $ldirection;
      }
    }
    .order_item_qty {
      text-align: center;
    }
    .cart-item {
      border-top: 1px solid $color-light-gray;
    }
  }
  .order-details-page {
    .order-details--cart {
      .cart-item.custom-kit {
        position: relative;
        min-height: 378px;
        .custom-kit__product-subhead {
          margin-bottom: 10px;
        }
      }
    }
  }
}
