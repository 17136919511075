.signup-tout-wrapper {
  display: block;
  @include swap_direction(margin, -60px auto 30px);
  max-width: 1066px;
  position: relative;
  .signup-tout-background-image img {
    width: 100%;
  }
  .signup-tout-content {
    position: absolute;
    width: 55%;
    #{$ldirection}: 24%;
    top: 19%;
    .eyebrow {
      font-family: $akzidenz;
      font-size: 15px;
      display: block;
      margin-#{$ldirection}: 10%;
      letter-spacing: 0.2em;
      color: $color-navy;
      font-weight: bold;
    }
    .headline1,
    .headline2 {
      font-family: $optimalight;
      font-size: 98px;
      line-height: 98px;
      display: block;
      color: #fff;
      letter-spacing: -5px;
      color: $color-navy;
      @media #{$large-only} {
        font-size: 51px; /* general fallback */
        font-size: 6vm; /* IE9 fallback */
        font-size: 6vmin;
        line-height: 43px; /* general fallback */
        line-height: 5vm; /* IE9 fallback */
        line-height: 5vmin;
      }
      @media #{$medium-only} {
        font-size: 51px; /* general fallback */
        font-size: 6vm; /* IE9 fallback */
        font-size: 6vmin;
        line-height: 43px; /* general fallback */
        line-height: 5vm; /* IE9 fallback */
        line-height: 5vmin;
      }
      @media #{$small-only} {
        font-size: 20px; /* general fallback */
        font-size: 6vm; /* IE9 fallback */
        font-size: 6vmin;
        line-height: 16px; /* general fallback */
        line-height: 5vm; /* IE9 fallback */
        line-height: 5vmin;
      }
    }
    .headline2 {
      margin-#{$ldirection}: 10%;
    }
    .subtitle {
      font-family: $akzidenz;
      font-size: 15px;
      display: block;
      margin-#{$ldirection}: 10%;
      letter-spacing: 0.2em;
      color: $color-navy;
    }
    #email-signup__form {
      margin-#{$ldirection}: 10%;
      margin-top: 30px;
      margin-bottom: 40px;
      input.form-text {
        width: 255px;
        @include swap_direction(border, 1px solid $color-light-gray);
        color: #05082b;
        &::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #05082b;
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #05082b;
          opacity: 1;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #05082b;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #05082b;
        }
      }
      input.form-submit {
        height: 33px;
        line-height: 33px;
        margin-top: -2px;
        margin-#{$ldirection}: 15px;
        @include swap_direction(padding, 0 20px);
      }
    }
    .thank_you_text {
      font-family: $akzidenz;
      font-size: 15px;
      display: block;
      margin-#{$ldirection}: 10%;
      margin-top: 30px;
      margin-bottom: 40px;
      color: $color-navy;
    }
  }
}

.device-mobile {
  .signup-tout-wrapper {
    width: 100%;
    @include swap_direction(margin, 10px 0 0 0);
    @include swap_direction(padding, 0);
    min-height: 530px;
    position: relative;
    .signup-tout-background-image img {
      width: 100%;
      margin-top: 60px;
    }
    .signup-tout-content {
      position: absolute;
      top: -30px;
      #{$ldirection}: 0;
      width: 100%;
      padding-top: 40px;
      .eyebrow {
        font-family: $akzidenz;
        font-size: 13px;
        display: block;
        margin-#{$ldirection}: 15%;
        letter-spacing: 0.2em;
        color: $color-navy;
      }
      .headline1,
      .headline2 {
        font-family: $optimalight;
        font-size: 40px;
        line-height: 40px;
        display: block;
        color: #000;
        letter-spacing: -0.06em;
        color: $color-navy;
        @media #{$small-only} {
          font-size: 55px;
          line-height: 55px;
        }
      }
      .headline1 {
        margin-#{$ldirection}: 5px;
      }
      .headline2 {
        margin-#{$ldirection}: 6%;
        margin-bottom: 20px;
      }
      .subtitle {
        font-family: $akzidenz;
        font-size: 13px;
        display: block;
        margin-#{$ldirection}: 15%;
        letter-spacing: 0.2em;
        color: $color-navy;
      }
      #email-signup__form {
        margin-#{$ldirection}: 10%;
        margin-top: 20px;
        margin-bottom: 30px;
        margin-#{$rdirection}: 10%;
        width: 80%;
        input.form-text {
          width: 100%;
          display: block;
          color: #05082b;
        }
        input.form-submit {
          width: 100%;
          line-height: 33px;
          margin-top: 15px;
          margin-#{$ldirection}: 0;
          display: block;
          height: 43px;
        }
      }
      .thank_you_text {
        font-family: $akzidenz;
        font-size: 13px;
        display: block;
        margin-#{$ldirection}: 10%;
        margin-top: 20px;
        margin-bottom: 30px;
        color: #000;
      }
    }
  }
}

// Flexible Signup Tout
.signup-tout-flexible-wrapper {
  display: block;
  @include swap_direction(margin, -60px auto 30px);
  max-width: 1366px;
  position: relative;
  .signup-tout-background-image img {
    width: 100%;
  }
  .signup-tout-content {
    position: absolute;
    display: block;
    width: 100%;
    #{$ldirection}: 0;
    top: 0;
    .eyebrow {
      font-family: $akzidenz;
      font-size: 15px;
      display: block;
      letter-spacing: 0.2em;
      color: $color-navy;
      font-weight: bold;
      position: absolute;
    }
    .headline1,
    .headline2 {
      font-family: $optimalight;
      font-size: 130px;
      line-height: 130px;
      display: block;
      color: #fff;
      letter-spacing: -5px;
      color: $color-navy;
      position: absolute;
      @media #{$large-only} {
        font-size: 51px; /* general fallback */
        font-size: 6vm; /* IE9 fallback */
        font-size: 6vmin;
        line-height: 43px; /* general fallback */
        line-height: 5vm; /* IE9 fallback */
        line-height: 5vmin;
      }
      @media #{$medium-only} {
        font-size: 51px; /* general fallback */
        font-size: 6vm; /* IE9 fallback */
        font-size: 6vmin;
        line-height: 43px; /* general fallback */
        line-height: 5vm; /* IE9 fallback */
        line-height: 5vmin;
      }
      @media #{$small-only} {
        font-size: 20px; /* general fallback */
        font-size: 6vm; /* IE9 fallback */
        font-size: 6vmin;
        line-height: 16px; /* general fallback */
        line-height: 5vm; /* IE9 fallback */
        line-height: 5vmin;
      }
    }
    .headline2 {
      margin-#{$ldirection}: 10%;
    }
    .subtitle-and-form-block {
      position: absolute;
    }
    .subtitle {
      font-family: $akzidenz;
      font-size: 15px;
      display: block;
      letter-spacing: 0.2em;
      color: $color-navy;
    }
    #email-signup__form {
      margin-#{$ldirection}: 0;
      margin-top: 20px;
      margin-bottom: 40px;
      input.form-text {
        width: 255px;
        @include swap_direction(border, 1px solid $color-light-gray);
        color: #05082b;
        &::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #05082b;
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #05082b;
          opacity: 1;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #05082b;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #05082b;
        }
      }
      input.form-submit {
        height: 33px;
        line-height: 33px;
        margin-top: -2px;
        margin-#{$ldirection}: 15px;
        @include swap_direction(padding, 0 20px);
      }
    }
    .thank_you_text {
      font-family: $akzidenz;
      font-size: 15px;
      display: block;
      margin-#{$ldirection}: 10%;
      margin-top: 30px;
      margin-bottom: 40px;
      color: $color-navy;
    }
  }
}

.device-mobile {
  .signup-tout-flxible-mobile-wrapper {
    width: 100%;
    @include swap_direction(margin, 10px 0 0 0);
    @include swap_direction(padding, 0);
    min-height: 530px;
    position: relative;
    .signup-tout-background-image img {
      width: 100%;
    }
    .signup-tout-content {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: 100%;
      padding-top: 0;
      text-align: center;
      .eyebrow {
        font-family: $akzidenz;
        font-size: 13px;
        display: block;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
        letter-spacing: 0.2em;
        color: $color-navy;
      }
      .headline1,
      .headline2 {
        font-family: $optimalight;
        font-size: 40px;
        line-height: 40px;
        display: block;
        color: #000;
        letter-spacing: -0.06em;
        color: $color-navy;
        @media #{$small-only} {
          font-size: 55px;
          line-height: 55px;
        }
      }
      .subtitle {
        font-family: $akzidenz;
        font-size: 13px;
        display: block;
        margin-#{$ldirection}: 15%;
        letter-spacing: 0.2em;
        color: $color-navy;
      }
      #email-signup__form {
        margin-#{$ldirection}: 10%;
        margin-top: 20px;
        margin-bottom: 30px;
        margin-#{$rdirection}: 10%;
        width: 80%;
        input.form-text {
          width: 100%;
          display: block;
          color: #05082b;
        }
        input.form-submit {
          width: 100%;
          line-height: 33px;
          margin-top: 15px;
          margin-#{$ldirection}: 0;
          display: block;
          height: 43px;
        }
      }
      .thank_you_text {
        font-family: $akzidenz;
        font-size: 13px;
        display: block;
        text-align: $ldirection;
        margin-#{$ldirection}: 10%;
        margin-top: 20px;
        margin-bottom: 30px;
        color: #000;
      }
    }
  }
}
