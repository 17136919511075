// NOTE: These are intended as *temporary* overrides until EL US removes the placeholder offers.
// Once the real FS offers are doing messaging, the base CSS will work as expected

// buttons for both
#loyalty__panel__offers,
#loyalty__panel__offers__sku {
  // buttons for FS offers stay as-is. Revert to original styles
  &.is-selected [data-offer-id='lyl_expedited_fss_visible'],
  &.is-selected [data-offer-id='lyl_fss_visible'] {
    .loyalty__panel__offers__button,
    .loyalty__panel__product__button {
      @extend .button--secondary;
      color: $color-navy;
      background: none;
    }
  }
}

// buttons for FS offers stay as-is. Revert to original styles
.is-selected [data-offer-id='lyl_expedited_fss_visible'],
.is-selected [data-offer-id='lyl_fss_visible'] {
  .loyalty__panel__offers__sku__remove,
  .loyalty__panel__offers__button.button--offers,
  .loyalty__panel__product__button.button--offers {
    visibility: visible;
  }
}
