#loyalty__panel__next-tier {
  @extend %loyalty-panel;
  @extend %loyalty-clear;
  &.is-guest,
  &.loyalty__has-max-tier,
  &.loyalty__has-pending-points {
    display: none;
  }
  .loyalty__panel__next-tier__title {
    font-weight: bold;
    @include swap_direction(padding, 10px);
  }
  @media #{$small-only} {
    //@extend %loyalty-panel-title;

    .loyalty__panel__next-tier__title {
      @include swap_direction(border, 1px solid #eee);
      border-width: 1px 0;
    }
  }
  @media #{$medium-up} {
    float: $ldirection;
    width: 30%;
    .loyalty__panel__next-tier__title {
      background-color: #eee;
    }
  }
}
