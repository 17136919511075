///
/// @file drupal_modules/esteelauder_endeca/_pc_page_search.scss
///
/// \brief styles for the PC full search page
///
/// used in template:
/// esteelauder_endeca/templates/endeca_search_v1/
/// and various theme based endeca tpl files
///

.search-page {
  max-width: 1024px;
  min-height: 300px;
  @include swap_direction(margin, 0 auto);
  color: $color-navy;
  position: relative;
  @include swap_direction(padding, 0 4px 2em 4px);
  .pre-order-container {
    .search-product__shade-select {
      width: 100%;
    }
  }
}

#results-container {
  @include pie-clearfix;
  display: none;
  @include swap_direction(margin, 2em auto 0 auto);
  width: 766px;
  @media #{$large-up} {
    width: 950px;
  }
  @media #{$xlarge-up} {
    width: 1024px;
  }
}

.search-product__misc-flag {
  display: none;
  font-size: 12px;
  font-family: 'AkzidenzGrotesk W1G';
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-bottom: 3px;
}

.search-page__headline {
  font-family: $optimalight;
  font-size: 40px;
}

///
/// form
///
.search-page__form {
  width: 746px;
  @media #{$large-up} {
    width: 800px;
  }
  border-bottom: 1px solid $color-light-gray;
  position: relative;
  @include swap_direction(margin, 0 auto);
}

input[type='text'].search-term {
  @include swap_direction(border, none);
  box-shadow: none;
  color: $color-navy;
  font-size: 80px;
  font-family: $optimalight;
  letter-spacing: -0.02em;
  height: 100px;
  line-height: 100px;
  @include swap_direction(padding, 0 75px 5px 0);
  width: 700px;
  background-color: transparent;
  &:focus {
    border-bottom: 1px solid $color-light-gray-blue;
  }
}

.search-submit {
  background-size: auto auto;
  background-position: 0 -7769px;
  width: 44px;
  height: 100px;
  display: block;
  line-height: 100px;
  cursor: pointer;
  position: absolute;
  #{$rdirection}: 0;
  top: 0;
  text-indent: -9999px;
  @include swap_direction(border, none);
  background-color: transparent;
}

///
/// summary area
///
.search-page__summary {
  margin-#{$ldirection}: 112px;
  font-size: 12px;
  text-transform: uppercase;
  p {
    font-size: 12px;
  }
}

.user-search-term {
  font-weight: bold;
}

///
/// selected filters aka breadcrumbs
///
.search-page__filters-breadcrumbs {
  display: none;
  background: $color-light-gray-blue;
  @include swap_direction(padding, 1em);
  position: relative;
  .results-header {
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
  }
  .results-clear {
    cursor: pointer;
    float: $rdirection;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: underline;
  }
}

.breadcrumb-results {
  li {
    position: relative;
    display: inline-block;
    @include swap_direction(margin, 10px 10px 0 0);
  }
  a {
    cursor: pointer;
    @include swap_direction(padding, 10px 33px 10px 10px);
    background: $color-white;
    @include swap_direction(border, 1px solid $color-light-gray);
    display: block;
    &:after {
      background-size: auto auto;
      background-position: 0 -1025px;
      content: ' ';
      height: 13px;
      width: 13px;
      position: absolute;
      top: 14px;
      #{$rdirection}: 10px;
    }
  }
}

///
/// sorting and pagination
/// @todo restyle when we have real sort in place
///
.results-header-container {
  position: relative;
  @include min-height(46px);
}

.product-sort-container {
  position: absolute;
  #{$rdirection}: 0;
  top: -25px;
  .arrow {
    @include arrow(
      $color: map-get($select-settings, arrow-color),
      $width: map-get($select-settings, arrow-width),
      $height: map-get($select-settings, arrow-height),
      $direction: down
    );
    line-height: 30px;
    height: 30px;
    display: block;
    position: absolute;
    #{$rdirection}: 0;
    top: -3px;
  }
}

.search-select__container {
  margin-bottom: 10px;
}

.results-pagination {
  position: absolute;
  #{$rdirection}: 0;
  bottom: 10px;
  width: auto;
  font-size: 12px;
  text-transform: uppercase;
  a {
    text-decoration: underline;
  }
  .page {
    @include swap_direction(padding, 0 6px 0 6px);
  }
  .view-all {
    padding-#{$rdirection}: 12px;
    margin-#{$rdirection}: 6px;
    border-#{$rdirection}: 1px solid $color-light-gray;
    font-weight: bold;
  }
  &:last-child {
    bottom: 0;
  }
}

///
/// filters and refinements aka dimensions
///
.search-page__filters {
  margin-#{$ldirection}: 10px;
  width: 200px;
  @media #{$xlarge-up} {
    width: 226px;
    margin-#{$ldirection}: 0;
  }
  display: block;
  float: $ldirection;
  .results-header {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.search-page__filters-dimensions {
  @include swap_direction(padding, 46px 20px 20px 0);
  border-bottom: $color-light-gray solid 1px;
  margin-bottom: 60px;
  dl {
    @include swap_direction(padding, 0 0 40px 0);
  }
  dt {
    @include swap_direction(padding, 0);
  }
  dd {
    @include swap_direction(padding, 1em 0 0 0);
    @include swap_direction(margin, 0);
    &.facet-showall {
      font-size: 0.9em;
      padding-#{$rdirection}: 1em;
    }
  }
}

// color family dimensions
.color-family__swatch {
  width: 16px;
  height: 16px;
  @include swap_direction(border-radius, 8px);
  display: block;
  float: $ldirection;
  @include swap_direction(margin, 2px 10px 0 0);
}
// coral to red
.refinement-coral_to_red {
  background: #ee2a25;
  border-#{$rdirection}: 8px solid #ae1123;
}
// gold to green
.refinement-gold_to_green {
  background: #efc8a7;
  border-#{$rdirection}: 8px solid #6e885c;
}
// mauve to blue
.refinement-mauve_to_blue {
  background: #805666;
  border-#{$rdirection}: 8px solid #374b69;
}
// mauve to plum
.refinement-mauve_to_plum {
  background: #93004c;
  border-#{$rdirection}: 8px solid #a23d5b;
}
// nude to brown
.refinement-nude_to_brown {
  background: #b0625e;
  border-#{$rdirection}: 8px solid #70342f;
}
// pink to berry
.refinement-pink_to_berry {
  background: #f72f5e;
  border-#{$rdirection}: 8px solid #b3223d;
}

///
/// products list returned
///
.search-page__products {
  float: $ldirection;
  width: 546px;
  @media #{$large-up} {
    width: 740px;
  }
  @media #{$xlarge-up} {
    margin-#{$ldirection}: 58px;
  }
}

.content-results,
.product-results {
  position: relative;
  width: 546px;
  @media #{$large-up} {
    width: 720px;
  }
  .result {
    min-height: 200px;
    border-top: 1px solid $color-light-gray;
    @include swap_direction(padding, 40px 0);
    margin-top: 20px;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}

// search product
.search-product {
  position: relative;
  @include pie-clearfix;
  margin-bottom: 20px;
}

.search-product__image {
  display: block;
  float: $ldirection;
  width: 144px;
  height: 198px;
  overflow: hidden;
  background: $color-light-gray;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.search-product__details {
  float: $ldirection;
  width: 328px;
  margin-#{$ldirection}: 38px;
}

.search-product__header {
  @include headline--quinary--plain;
  margin-bottom: 4px;
}

.search-product__sub-header {
  @include headline--senary--plain;
  margin-bottom: 10px;
}

.search-product__swatches {
  float: $ldirection;
  display: none;
  @media #{$medium-down} {
    display: none !important;
  }
}

.search-product__swatches,
.search-product__shade-select {
  &.shades_1 {
  }
}

.search-product__quantity-container {
  float: $ldirection;
}

.search-product__cta {
  @include pie-clearfix;
  width: 100%;
}

.search-product__cta {
}

.search-product__shade-select-container {
  display: none;
}

.search-product__size {
  margin-bottom: 10px;
  font-size: 12px;
}

.search-product__size-select {
  display: none;
  width: 225px;
  margin-bottom: 10px;
}

.search-product__shade-select {
  width: 288px;
  @include swap_direction(margin, 28px 0 10px 0);
}

.search-product__button {
  float: $ldirection;
  margin-#{$ldirection}: 10px;
}

.search-product__full-details,
.search-product__wishlist {
  display: block;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.15em;
  font-weight: bold;
}

.search-product__full-details {
  margin-top: 26px;
}

.search-product__wishlist {
  margin-top: 8px;
}

///
/// help block
///

.search-help {
  text-align: center;
  .search-page__bestsellers & {
    background: $color-light-gray-blue;
    @include swap_direction(padding, 1em);
    .headline--page {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0);
    }
  }
  .search-page__filters & {
    @include swap_direction(padding, 0 10px);
    @include swap_direction(margin, 0 0 20px -20px);
    .search-link__chat {
      display: block;
      margin-bottom: 2em;
    }
  }
}

.search-help__content {
  text-transform: uppercase;
}

.search-link__chat {
  text-decoration: underline;
  font-weight: bold;
}

.help-number {
  font-weight: bold;
}

///
/// best sellers
///

.bestsellers-header {
  border-bottom: 1px solid $color-light-gray;
  @include swap_direction(margin, 40px 0);
}

///
/// content results
///

.content-result__img {
  width: 226px;
  height: 226px;
  display: block;
  float: $ldirection;
  margin-#{$rdirection}: 1em;
  background: #eee;
}

.content-result__details {
  padding-top: 1em;
}

.bestseller-results {
  position: relative;
  width: 766px;
  @media #{$large-up} {
    width: 800px;
  }
  @include swap_direction(margin, 0 auto);
  .result {
    min-height: 200px;
    border-top: 1px solid $color-light-gray;
    @include swap_direction(padding, 40px 0);
    margin-top: 20px;
    &:nth-child(1) {
      margin-top: 0;
      border-top: 0;
    }
  }
}

.bestsellers-grid {
  @include pie-clearfix;
  width: 766px;
  @media #{$large-up} {
    width: 800px;
  }
  @include swap_direction(margin, 0 auto);
}

.search-product__skintype-select {
  margin-bottom: 10px;
  width: 100%;
}
