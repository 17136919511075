$medium-max-width: 768px;
$ipad-min-width: 767px;
$iphone-max-width: 766px;
$AkzidenzGroteskW1G: 'AkzidenzGrotesk W1G';
$AkzidenzGroteskBQBOL: 'AkzidenzGroteskBQBOL';
$OptimaDisplayLight: 'OptimaDisplayLight';
$ButtonStartBackgroundColor: #040a2b;
$ButtonInactiveBackgroundColor: #a4a4ab;

@-webkit-keyframes fadeFromRight {
  0% {
    -webkit-transform: translate3d(6%, 0, 0);
    transform: translate3d(6%, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes fadeFromRight {
  0% {
    -webkit-transform: translate3d(6%, 0, 0);
    transform: translate3d(6%, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@-webkit-keyframes fadeStarsInOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
}

@keyframes fadeStarsInOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
}

.anr_repromote_formatter_v1 {
  @media screen and (max-width: $ipad-min-width) {
    #r4_mod5 {
      margin-top: 0;
    }
  }
  #r4_mod5_h1 {
    padding-top: 0;
    font-size: 2.637362vw;
    text-transform: inherit;
    @media screen and (min-width: $max-width) {
      font-size: 36px;
    }
  }
}

.anr_repromote__v1 {
  .anr_repromote_ {
    &container {
      position: relative;
    }
    &mod1 {
      @media screen and (max-width: $ipad-min-width) {
        margin-top: 0;
      }
      margin-top: 4px;
      .boutique_vid_play_cta,
      .boutique_vid_play_img {
        cursor: pointer;
        position: absolute;
        z-index: 1;
        &:hover {
          opacity: 0.7;
        }
      }
      .boutique_vid_play_cta {
        @media screen and (max-width: $ipad-min-width) {
          top: 83.08vw;
          #{$ldirection}: 2.8%;
          font-size: 3.4375vw;
        }
        top: 84.7%;
        #{$ldirection}: 5.75%;
      }
      .boutique_vid_play_img {
        @media screen and (max-width: $ipad-min-width) {
          top: 60.3vw;
          width: 21.563vw;
          #{$ldirection}: 5.3vw;
        }
        top: 71.1%;
        width: 6.662%;
        #{$ldirection}: 5.85%;
      }
      &__copy {
        @media screen and (max-width: $ipad-min-width) {
          width: 100%;
          text-align: center;
          top: 51.4%;
          #{$ldirection}: 0;
        }
        position: absolute;
        top: 34.5%;
        #{$ldirection}: 47%;
        z-index: 1;
        .el_boutique_ {
          &body {
            @media screen and (max-width: $ipad-min-width) {
              font-size: 4.375vw;
              line-height: 115%;
            }
            font-size: 11px;
          }
          &h2_md {
            @media screen and (max-width: $ipad-min-width) {
              padding: 1.5% 0 0 0;
            }
            padding: 2.1% 0 0 0;
          }
          &h3 {
            line-height: 121%;
          }
        }
        &-3 {
          @media screen and (max-width: $ipad-min-width) {
            padding-top: 2.7%;
          }
          padding-top: 2.1%;
        }
        &-4 {
          @media screen and (max-width: $ipad-min-width) {
            padding-top: 5%;
          }
          padding-top: 2.4%;
        }
        &-5.el_boutique_body {
          @media screen and (max-width: $ipad-min-width) {
            padding-top: 20.8%;
            font-size: 3.90625vw;
          }
          padding-top: 3.9%;
        }
        #anr_repromote_hero__qs {
          height: 41px;
          line-height: 41px;
          padding: 0 31px;
          margin-top: 5.7%;
        }
        html[dir='rtl'] & {
          #{$ldirection}: 42%;
        }
      }
      .anr_repromote__star {
        position: absolute;
        &.fadeStarsInOut {
          -webkit-animation: fadeStarsInOut 0.8s ease-in alternate infinite;
          animation: fadeStarsInOut 0.8s ease-in alternate infinite;
        }
      }
      @media screen and (max-width: $medium-max-width) {
        .mpp.mpp_qs_detached {
          padding-bottom: 0;
          .mpp__header,
          .mpp__product-sort-container {
            display: none;
          }
          .mpp__product-grid {
            background-color: $color-white;
            margin-top: 0;
          }
          .quickshop-inline-top {
            #{$ldirection}: 47%;
          }
          .quickshop-inline {
            margin-bottom: 0;
          }
        }
        #anr_repromote__hero-block_qs {
          & + .product-brief a .product-brief__image,
          & + .product-brief a h3.product-brief__header,
          & + .product-brief .product-brief__misc-flag,
          & + .product-brief a h4.product-brief__sub-header,
          & + .product-brief div.product-brief__price,
          & + .product-brief div.product-brief__reviews {
            display: none;
          }
          & + .product-brief .shop_now_dark,
          & + .product-brief .shop_now_light {
            top: 3.6vw;
          }
          & + .product-brief {
            height: auto !important;
            float: none;
            width: 100%;
            padding-bottom: 0;
            list-style: none;
          }
        }
      }
    }
    &mod2 {
      &__copy {
        @media screen and (max-width: $ipad-min-width) {
          top: 2.3%;
          #{$ldirection}: 0;
          width: 100%;
          text-align: center;
        }
        position: absolute;
        top: 21%;
        #{$ldirection}: 5.9%;
        z-index: 1;
        video {
          @media screen and (max-width: $medium-max-width) {
            margin-top: 3.9vw;
            display: block;
            width: 106.6vw;
            margin-#{$ldirection}: -2%;
          }
        }
        .el_boutique_ {
          &body {
            @media screen and (max-width: $ipad-min-width) {
              font-size: 4.375vw;
              line-height: 115%;
            }
            font-family: $AkzidenzGroteskW1G;
          }
        }
        .anr_repromote_ {
          &mod2__ {
            &copy-1 {
              @media screen and (max-width: $ipad-min-width) {
                letter-spacing: 0.15em;
                font-size: 3.75vw;
                line-height: 123%;
              }
              font-family: $akzidenz;
              font-weight: bold;
              font-size: 12px;
              letter-spacing: 0.15em;
            }
            &copy-2 {
              padding-top: 2.5%;
              letter-spacing: 0.01em;
            }
            &copy-3 {
              @media screen and (max-width: $ipad-min-width) {
                padding-top: 1.3%;
              }
              padding-top: 3.2%;
            }
            &copy-4 {
              @media screen and (max-width: $ipad-min-width) {
                padding-top: 3.7%;
                line-height: 123%;
              }
              padding-top: 4.2%;
            }
          }
        }
        #anr_repromote__scrollVid_qs {
          height: 41px;
          line-height: 41px;
          padding: 0 31px;
          margin-top: 5.8%;
          @media screen and (min-width: $ipad-min-width) {
            margin-top: 3%;
          }
        }
      }
      @media screen and (max-width: $medium-max-width) {
        .mpp.mpp_qs_detached {
          padding-bottom: 0;
          .mpp__header,
          .mpp__product-sort-container {
            display: none;
          }
          .mpp__product-grid {
            background-color: $color-white;
            margin-top: 0;
          }
          .quickshop-inline-top {
            #{$ldirection}: 47%;
          }
          .quickshop-inline {
            margin-bottom: 0;
          }
        }
        .el_boutique_qs_mob {
          & + .product-brief a .product-brief__image,
          & + .product-brief a h3.product-brief__header,
          & + .product-brief .product-brief__misc-flag,
          & + .product-brief a h4.product-brief__sub-header,
          & + .product-brief div.product-brief__price,
          & + .product-brief div.product-brief__reviews {
            display: none;
          }
          & + .product-brief .shop_now_dark,
          & + .product-brief .shop_now_light {
            top: 5.4vw;
          }
          & + .product-brief .shop_now_dark {
            border: 1px solid $color-white;
          }
          & + .product-brief {
            height: auto !important;
            float: none;
            width: 100%;
            padding-bottom: 0;
            list-style: none;
          }
        }
      }
    }
    &mod3 {
      @media screen and (max-width: $ipad-min-width) {
        margin-top: 4.2%;
      }
      margin-top: 0.95%;
      .anr_repromote_carousel {
        &__content {
          position: relative;
        }
        &__pag,
        &__slides {
          position: absolute;
        }
        &__pag {
          position: absolute;
          bottom: 4%;
          width: 100%;
          margin: 0 auto;
          text-align: center;
          z-index: 2;
          @media screen and (min-width: 850) {
            bottom: 1%;
          }
        }
        &_pag_dot {
          @media screen and (max-width: $ipad-min-width) {
            width: 3.125vw;
            height: 3.125vw;
            margin: 0 1.2vw 0 1.2vw;
          }
          background: url(/media/boutiques/common/pag-dots-light-outline.svg) 0px center no-repeat transparent;
          display: inline-block;
          zoom: 1;
          width: 9px;
          height: 9px;
          cursor: pointer;
          margin: 0 3px 0 3px;
        }
        &_pag_dot.pagDotSelected {
          background: url(/media/boutiques/common/pag-dots-light-fill.svg) 0px center no-repeat transparent;
        }
        &__slides {
          @media screen and (max-width: $ipad-min-width) {
            text-align: center;
          }
          width: 100%;
          ul {
            @media screen and (max-width: $ipad-min-width) {
              margin-#{$ldirection}: 0;
              padding-top: 2.5%;
            }
            margin-#{$ldirection}: 1.45%;
            padding-top: 0.7%;
            li {
              @media screen and (max-width: $ipad-min-width) {
                padding: 2.5% 0 2.5% 0;
                line-height: 115%;
              }
              padding: 0.77% 0 0.77% 0;
              text-indent: -18px;
            }
          }
          .anr_repromote_carousel {
            &__slide {
              @media screen and (max-width: $ipad-min-width) {
                #{$ldirection}: 0;
              }
              position: absolute;
              width: 100%;
              #{$ldirection}: 51.5%;
              html[dir='rtl'] & {
                #{$rdirection}: 7.5%;
                #{$ldirection}: 0;
              }
              &:not(.anr_repromote_carousel__slide1) {
                opacity: 0;
              }
              h2 {
                @media screen and (max-width: $ipad-min-width) {
                  font-size: 9.375vw;
                }
                letter-spacing: 0.005em;
              }
              .el_boutique_body {
                @media screen and (max-width: $ipad-min-width) {
                  font-size: 4.375vw;
                }
              }
              &.fadeFromRight {
                -webkit-animation-name: fadeFromRight;
                animation-name: fadeFromRight;
                -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
                -webkit-animation-duration: 0.75s;
                animation-duration: 0.75s;
              }
              &.hideContent {
                opacity: 0;
                visibility: hidden;
              }
            }
            &__slide1 {
              @media screen and (max-width: $ipad-min-width) {
                margin-top: 59.3%;
              }
              margin-top: 3%;
              .anr_repromote_carousel__slide {
                &--copy2 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-top: 3.8%;
                  }
                  padding-top: 0.7%;
                  padding-#{$ldirection}: 0.1%;
                }
                &--copy7 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-top: 2.4%;
                  }
                  padding-top: 1%;
                  padding-#{$ldirection}: 0.2%;
                }
                &--copy2,
                &--copy7 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-#{$ldirection}: 0;
                    line-height: 115%;
                  }
                }
              }
            }
            &__slide2 {
              @media screen and (max-width: $ipad-min-width) {
                margin-top: 60%;
              }
              margin-top: 3.3%;
              h3 {
                @media screen and (max-width: $ipad-min-width) {
                  padding-top: 0%;
                  letter-spacing: -0.024em;
                  padding-#{$ldirection}: 0;
                  line-height: 115%;
                }
                padding-top: 0.95%;
                letter-spacing: 0.03em;
                padding-#{$ldirection}: 0.1%;
              }
              ul {
                padding-top: 0.85%;
              }
              .anr_repromote_carousel__slide {
                &--copy3 {
                  @media screen and (max-width: $medium-max-width) {
                    padding-top: 1.3%;
                    padding-bottom: 1.7%;
                  }
                  padding-top: 1.7%;
                  padding-#{$ldirection}: 0.2%;
                }
                &--copy4 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-top: 5.1%;
                  }
                  padding-top: 1.45%;
                  padding-#{$ldirection}: 0.2%;
                }
                &--copy3,
                &--copy4 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-#{$ldirection}: 0;
                    line-height: 115%;
                  }
                }
              }
            }
            &__slide3 {
              @media screen and (max-width: $ipad-min-width) {
                margin-top: 60.3%;
              }
              margin-top: 4%;
              h3 {
                @media screen and (max-width: $ipad-min-width) {
                  padding-top: 0;
                  padding-#{$ldirection}: 0;
                  letter-spacing: -0.02em;
                  line-height: 115%;
                }
                padding-top: 0.9%;
                padding-#{$ldirection}: 0.2%;
                letter-spacing: -0.02em;
              }
              .anr_repromote_carousel__slide {
                &--copy3 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-top: 1.3%;
                  }
                  padding-top: 1.7%;
                  padding-#{$ldirection}: 0.2%;
                }
                &--copy4 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-top: 5.2%;
                  }
                  padding-top: 1.5%;
                  padding-#{$ldirection}: 0.2%;
                }
                &--copy5 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-top: 5%;
                  }
                  padding-top: 1.4%;
                  padding-#{$ldirection}: 0.2%;
                }
                &--copy3,
                &--copy4,
                &--copy5 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-#{$ldirection}: 0;
                    line-height: 115%;
                  }
                }
              }
            }
            &__slide4 {
              @media screen and (max-width: $ipad-min-width) {
                margin-top: 67.5%;
              }
              margin-top: 6.2%;
              h3 {
                @media screen and (max-width: $ipad-min-width) {
                  padding-top: 0;
                  padding-#{$ldirection}: 0;
                  line-height: 115%;
                }
                padding-top: 0.9%;
                padding-#{$ldirection}: 0.1%;
              }
              .anr_repromote_carousel__slide {
                &--copy3 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-top: 3%;
                  }
                  padding-top: 1.7%;
                  padding-#{$ldirection}: 0.2%;
                }
                &--copy4 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-top: 5%;
                  }
                  padding-top: 1.45%;
                  padding-#{$ldirection}: 0.2%;
                }
                &--copy5 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-top: 5%;
                  }
                  padding-top: 1.55%;
                  padding-#{$ldirection}: 0.2%;
                }
                &--copy3,
                &--copy4,
                &--copy5 {
                  @media screen and (max-width: $medium-max-width) {
                    padding-#{$ldirection}: 0;
                    line-height: 115%;
                  }
                }
              }
            }
            &__slide5 {
              @media screen and (max-width: $ipad-min-width) {
                margin-top: 74.7%;
              }
              margin-top: 7.7%;
              .anr_repromote_carousel__slide {
                &--copy2 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-top: 2.5%;
                  }
                  padding-top: 0.7%;
                  padding-#{$ldirection}: 0.2%;
                }
                &--copy3,
                &--copy4 {
                  @media screen and (max-width: $ipad-min-width) {
                    padding-top: 5%;
                  }
                  padding-top: 1.5%;
                  padding-#{$ldirection}: 0.2%;
                }
                &--copy2,
                &--copy3,
                &--copy4 {
                  @media screen and (max-width: $medium-max-width) {
                    padding-#{$ldirection}: 0;
                    line-height: 115%;
                  }
                }
              }
            }
          }
        }
        &__stars {
          img {
            position: absolute;
            width: 100%;
          }
        }
      }
      .el_boutique_carouselnav {
        @media screen and (max-width: $ipad-min-width) {
          width: 7.032%;
          margin-top: 14.2%;
        }
        position: absolute;
        z-index: 2;
        width: 3.514%;
        top: 0;
        cursor: pointer;
        height: 100%;
        text-align: center;
        &_1 {
          #{$ldirection}: 0;
        }
        &_2 {
          #{$rdirection}: 0;
        }
        img {
          width: 100%;
          margin: auto;
          height: 100%;
        }
      }
    }
    &mod5 {
      @media screen and (max-width: $ipad-min-width) {
        margin-top: 0;
      }
      margin-top: 2%;
      .anr__quiz-section {
        position: relative;
        .anr__quiz {
          &-landing {
            @media screen and (max-width: 1365px) {
              height: 28.5714vw;
            }
            @media screen and (max-width: $ipad-min-width) {
              height: 137.03125vw;
            }
            height: 390px;
            overflow: hidden;
            .anr__quiz {
              &-landing-copy {
                @media screen and (max-width: $ipad-min-width) {
                  margin-top: 67.4%;
                  #{$ldirection}: 0;
                  width: 100%;
                }
                position: absolute;
                margin-top: 6.6%;
                #{$ldirection}: 5.8%;
                .anr__quiz {
                  &-header {
                    @media screen and (max-width: $ipad-min-width) {
                      text-align: center;
                      font-size: 9.375vw;
                    }
                    font-family: $OptimaDisplayLight;
                    width: 100%;
                    text-align: #{$ldirection};
                    letter-spacing: -0.02em;
                  }
                  &-subheader {
                    @media screen and (max-width: $ipad-min-width) {
                      text-align: center;
                      margin-top: 2%;
                      line-height: 115%;
                      font-size: 4.375vw;
                    }
                    font-family: $AkzidenzGroteskW1G;
                    width: 100%;
                    text-align: #{$ldirection};
                    margin-top: 2.4%;
                  }
                  &-btnStart {
                    @media screen and (max-width: $ipad-min-width) {
                      position: absolute;
                      bottom: 0;
                      text-align: center;
                      height: 14.063vw;
                      background: $ButtonStartBackgroundColor;
                      font-size: 3.75vw;
                      line-height: 14.2vw;
                      letter-spacing: 0.12em;
                      color: $color-white;
                      padding: 0 0;
                      width: 84%;
                      margin: 0 auto;
                      #{$ldirection}: 0;
                      #{$rdirection}: 0;
                      text-indent: 0;
                      border: 1px solid $color-white;
                      top: 119%;
                    }
                    html[dir='rtl'] & {
                      position: absolute;
                      #{$ldirection}: 0;
                    }
                    border: 1px solid $color-white;
                    margin-top: 6%;
                    height: 41px;
                    line-height: 41px;
                    padding: 0 21px;
                  }
                }
              }
            }
            .anr__quiz-landingBg {
              margin-top: 0;
            }
          }
          &-qs {
            @media screen and (max-width: $ipad-min-width) {
              margin-top: -2px;
            }
            display: none;
            position: relative;
            width: 100%;
            margin: 0 auto;
            .anr__quiz {
              &-results {
                display: none;
                position: absolute;
                text-align: center;
                width: 100%;
                margin: 0 auto;
                margin-top: 8.4%;
                @media screen and (min-width: $ipad-min-width) {
                  margin-top: 4.4%;
                }
                .anr__quiz {
                  &-resultsSubheaderPC,
                  &-resultsDescPC,
                  &-resultsTreatmentCTAPC {
                    @media screen and (max-width: $iphone-max-width) {
                      display: block;
                    }
                    @media screen and (min-width: $ipad-min-width) and (max-width: $medium-max-width) {
                      display: none;
                    }
                    display: block;
                  }
                  &-resultsSubheaderIpad,
                  &-resultsDescIpad,
                  &-resultsTreatmentCTAIpad,
                  &-resultsIpadInfo {
                    @media screen and (max-width: $iphone-max-width) {
                      display: block;
                    }
                    @media screen and (min-width: $ipad-min-width) and (max-width: $medium-max-width) {
                      display: block;
                    }
                    display: none;
                  }
                  &-resultsHeader {
                    @media screen and (max-width: $ipad-min-width) {
                      font-size: 9.375vw;
                      margin: 7.6% 0 0.1% 0;
                    }
                    margin-bottom: 1.75%;
                  }
                  &-resultsSubheaderPC,
                  &-resultsSubheaderIpad {
                    @media screen and (max-width: $ipad-min-width) {
                      line-height: 1.28;
                    }
                    letter-spacing: -0.012em;
                    line-height: 1.15;
                  }
                  &-resultsTreatment {
                    @media screen and (max-width: $ipad-min-width) {
                      margin-top: 4%;
                    }
                    margin-top: 2.1%;
                    margin-bottom: -0.1%;
                  }
                  &-resultsDescPC,
                  &-resultsDescIpad {
                    @media screen and (max-width: $ipad-min-width) {
                      margin-top: 1%;
                      line-height: 1.28;
                    }
                    margin-top: 1.5%;
                    line-height: 1.15;
                  }
                  &-resultsStore,
                  &-resultsDownload {
                    @media screen and (max-width: $ipad-min-width) {
                      height: 14.063vw;
                      font-size: 3.75vw;
                      line-height: 3.75;
                      letter-spacing: 0.12em;
                      padding: 0 0;
                      width: 84%;
                      margin: 0 auto;
                      text-indent: 0;
                      max-width: unset;
                    }
                    display: block;
                    border: 1px solid $color-white;
                    text-align: center;
                    margin: 0 auto;
                    height: 41px;
                    line-height: 3.45;
                  }
                  &-resultsIpadInfo {
                    margin-top: 2%;
                  }
                  &-resultsStore {
                    @media screen and (max-width: $iphone-max-width) {
                      display: block;
                    }
                    @media screen and (max-width: $ipad-min-width) {
                      max-width: unset;
                      margin-top: 11%;
                    }
                    display: block;
                    max-width: 160px;
                    margin-top: 3.9%;
                  }
                  &-resultsDownload {
                    @media screen and (max-width: $iphone-max-width) {
                      display: block;
                    }
                    @media screen and (max-width: $ipad-min-width) {
                      max-width: unset;
                      margin-top: 3%;
                    }
                    display: block;
                    max-width: 254px;
                    margin-top: 1.4%;
                    img {
                      @media screen and (max-width: $ipad-min-width) {
                        padding-#{$rdirection}: 4.2%;
                        margin-top: -2%;
                        width: 10vw;
                        max-width: unset;
                      }
                      vertical-align: middle;
                      padding-#{$rdirection}: 2.8%;
                      margin-top: -3%;
                      width: 2.19vw;
                      max-width: 30px;
                    }
                  }
                }
              }
              &-close {
                @media screen and (max-width: $ipad-min-width) {
                  width: 8.125%;
                  #{$rdirection}: 2.8%;
                  top: 0.9%;
                  z-index: 1;
                }
                position: absolute;
                width: 2.05%;
                #{$rdirection}: 2.2%;
                top: 4.2%;
                cursor: pointer;
              }
              &-questionHeader {
                @media screen and (max-width: $ipad-min-width) {
                  margin-top: 9.5%;
                  font-size: 9.375vw;
                }
                position: absolute;
                text-align: center;
                width: 100%;
                margin-top: 7.5%;
              }
              &-question {
                @media screen and (max-width: $ipad-min-width) {
                  padding: 4% 0 5.3% 0;
                }
                line-height: 104%;
                padding: 1.5% 0 1.7% 0;
                letter-spacing: -0.01em;
              }
              &_question_subq {
                @media screen and (max-width: $ipad-min-width) {
                  font-size: 4.375vw;
                  margin-top: -1.6%;
                  padding-bottom: 5.7%;
                }
                padding-bottom: 1.1%;
              }
              &-breadcrumb {
                @media screen and (max-width: $ipad-min-width) {
                  font-size: 4.375vw;
                }
              }
              &-q-1,
              &-q-2,
              &-q-3,
              &-q-4 {
                @media screen and (max-width: $ipad-min-width) {
                  margin-top: 24.1%;
                  z-index: 1;
                }
                margin-top: 12.35%;
                position: absolute;
                width: 100%;
                text-align: center;
                .anr__quiz {
                  &-wrapper {
                    @media screen and (max-width: 628px) {
                      width: 100%;
                    }
                    margin: 0 auto;
                    text-align: #{$ldirection};
                    .anr__quiz {
                      &-option {
                        @media screen and (max-width: 1365px) {
                          font-size: 1.75824vw;
                        }
                        @media screen and (max-width: $ipad-min-width) {
                          height: 14.0625vw;
                          line-height: 14.0625vw;
                          font-size: 5.9375vw;
                        }
                        font-family: $OptimaDisplayLight;
                        font-size: 24px;
                        width: 100%;
                        text-align: center;
                        letter-spacing: -0.01em;
                        text-transform: inherit;
                        font-weight: 400;
                        padding: 0;
                        -webkit-touch-callout: none;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        height: 41px;
                        line-height: 41px;
                        display: inline-block;
                        &.button--dark.active {
                          border: 1px solid $color-white;
                        }
                      }
                      &-btnNext {
                        display: block;
                        margin: 4.8% auto 0 auto;
                        padding: 0;
                        border: 1px solid $color-white;
                        height: 41px;
                        line-height: 41px;
                        width: 160px;
                        @media screen and (max-width: 1365px) {
                          height: 2.9304vw;
                          line-height: 2.9304vw;
                        }
                        @media screen and (max-width: 1126px) {
                          height: 33px;
                          line-height: 33px;
                        }
                        @media screen and (max-width: $ipad-min-width) {
                          height: 14.063vw;
                          font-size: 3.75vw;
                          line-height: 14.2vw;
                          letter-spacing: 0.12em;
                          padding: 0 0;
                          width: 84%;
                          margin: 0 auto;
                          text-indent: 0;
                        }
                        &.anr__quiz-btnInactive {
                          background-color: $ButtonInactiveBackgroundColor;
                          border: 1px solid $color-white;
                          pointer-events: none;
                        }
                      }
                    }
                  }
                }
              }
              &-q-2,
              &-q-3,
              &-q-4 {
                display: none;
              }
              &-q-1,
              &-q-2 {
                .anr__quiz {
                  &-wrapper {
                    @media screen and (max-width: $ipad-min-width) {
                      max-width: unset;
                    }
                    max-width: 45.974%;
                    .anr__quiz {
                      &-option {
                        @media screen and (max-width: $ipad-min-width) {
                          max-width: 40.3215vw;
                          margin: 2.3% 1.75%;
                          &:nth-child(2n + 1) {
                            margin-#{$ldirection}: 7.8vw;
                          }
                          &:nth-child(2n + 2) {
                            margin-#{$rdirection}: 0;
                          }
                        }
                        @media screen and (min-width: $ipad-min-width) {
                          max-width: 31.211%;
                          margin: 1.55% 1.2%;
                          &:nth-child(3n + 1) {
                            margin-#{$ldirection}: 0;
                          }
                          &:nth-child(3n + 3) {
                            margin-#{$rdirection}: 0;
                          }
                        }
                        @media screen and (max-width: 1340px) {
                          margin: 1.55% 0.9%;
                        }
                      }
                      &-btnNext {
                        margin: 7.8% auto 0 auto;
                      }
                    }
                  }
                }
              }
              &-q-3 {
                .anr__quiz {
                  &_question_subq {
                    @media screen and (max-width: $ipad-min-width) {
                      padding-bottom: 5.8%;
                    }
                    padding-bottom: 0.9%;
                  }
                  &-wrapper {
                    @media screen and (max-width: $ipad-min-width) {
                      max-width: unset;
                      text-align: center;
                    }
                    max-width: 61.714%;
                    .anr__quiz {
                      &-option {
                        @media screen and (max-width: $ipad-min-width) {
                          margin: 2.3% 0;
                          width: 84%;
                          &:nth-of-type(4) {
                            font-size: 4.6875vw;
                          }
                        }
                        @media screen and (min-width: $ipad-min-width) {
                          max-width: 47.925%;
                          margin: 1.55% 1.79%;
                          &:nth-child(2n + 1) {
                            margin-#{$ldirection}: 0;
                          }
                          &:nth-child(2n + 2) {
                            margin-#{$rdirection}: 0;
                          }
                        }
                        @media screen and (max-width: 1365px) {
                          margin: 1.55% 1.5%;
                        }
                      }
                      &-btnNext {
                        margin: 7.5% auto 0 auto;
                      }
                    }
                  }
                }
              }
              &-q-4 {
                .anr__quiz {
                  &-question {
                    @media screen and (max-width: $ipad-min-width) {
                      padding-bottom: 2.3%;
                    }
                  }
                  &-wrapper {
                    @media screen and (max-width: $ipad-min-width) {
                      max-width: unset;
                      text-align: center;
                    }
                    max-width: 70.279%;
                    .anr__quiz {
                      &-option {
                        @media screen and (max-width: $ipad-min-width) {
                          margin: 2.3% 0;
                          width: 84%;
                          &:nth-of-type(1) {
                            font-size: 4.6875vw;
                          }
                        }
                        @media screen and (min-width: $ipad-min-width) {
                          max-width: 48.959%;
                          margin: 1.55% 0.77%;
                          &:nth-child(2n + 1) {
                            margin-#{$ldirection}: 0;
                          }
                          &:nth-child(2n + 2) {
                            margin-#{$rdirection}: 0;
                          }
                        }
                        @media screen and (max-width: 1365px) {
                          margin: 1.55% 0.5%;
                        }
                      }
                      &-btnNext {
                        margin: 7.5% auto 0 auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .inactive {
        pointer-events: none;
      }
    }
  }
}
