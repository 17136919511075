.desire-captioned-video {
  z-index: 2;
  padding-bottom: 31px;
  .desire_mod2__video-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 47.62%;
    @media screen and(max-width: 767px) {
      height: 100%;
      padding-bottom: 2%;
    }
    .desire_mod2__poster {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: 100%;
      height: 100%;
      will-change: opacity;
      &.faded-out {
        opacity: 0;
        transition: opacity 500ms;
      }
    }
    .desire_mod2__video {
      width: 100%;
    }
    .desire_mod2__video-captions {
      position: absolute;
      bottom: 43%;
      #{$ldirection}: 11%;
      width: 50%;
      height: 1px;
      .video-caption {
        font-family: $optimalight;
        line-height: 1em;
        letter-spacing: -1px;
        color: $color-white;
        position: absolute;
        transition: opacity 650ms ease-in;
        opacity: 0;
        will-change: opacity;
        &.show {
          opacity: 1;
        }
        &__caption1 {
          font-size: 3.953vw;
          &.show {
            // this caption needs to appear a bit quicker as it's meant to be visible at 0
            transition: opacity 325ms ease-in;
          }
        }
        &__caption2 {
          font-size: 3.074vw;
          top: -0.878vw;
          line-height: 0.999em;
        }
        &__caption3 {
          font-size: 3.074vw;
          top: -0.585vw;
        }
        &__caption4 {
          font-size: 3.953vw;
          letter-spacing: -0.146vw;
        }
        &__caption5 {
          font-size: 2.781vw;
          top: 1.024vw;
        }
        &__caption6 {
          font-size: 3.001vw;
          top: -3.294vw;
        }
        @media #{$xxlarge-up} {
          &__caption1 {
            font-size: 54px;
          }
          &__caption2 {
            font-size: 42px;
            top: -12px;
          }
          &__caption3 {
            font-size: 42px;
            top: -8px;
          }
          &__caption4 {
            font-size: 54px;
            letter-spacing: -2px;
          }
          &__caption5 {
            font-size: 38px;
            top: 14px;
          }
          &__caption6 {
            font-size: 41px;
            top: -45px;
          }
        }
      }
    }
    .desire_mod2__copy {
      @media screen and(max-width: 767px) {
        text-align: center;
        padding-bottom: 4%;
      }
      #desire__video-block_1 {
        @media screen and(max-width: 767px) {
          padding-top: 7.5%;
          letter-spacing: 0.03em;
        }
      }
      #desire__video-block_2,
      #desire__video-block_4 {
        @media screen and(max-width: 767px) {
          padding-top: 2.7%;
          font-size: 5.5vw;
        }
      }
      #desire__video-block_3 {
        @media screen and(max-width: 767px) {
          padding-top: 7%;
          letter-spacing: 0.03em;
        }
      }
    }
  }
}
