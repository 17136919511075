/// moved from sections/account/_loyalty.scss
/// convert to bem?

.account-mobile-landing-page .account-page__loyalty.not_loyalty_member,
.account-body .account-page__loyalty.not_loyalty_member {
  margin-bottom: 0;
}

.account-mobile-landing-page .account-page__loyalty {
  margin-bottom: 0;
}

#loyalty__content {
  #loyalty__page__index {
    background-image: url('/media/images/account/loyalty/loyalty_e_list_header_mobile.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    @media #{$medium-up} {
      background-image: url('/media/images/account/loyalty/loyalty_e_list_header.jpg');
      background-position: center 0;
      background-size: auto;
      margin-top: -83px;
      padding-top: 250px;
    }
  }
  .account-page__header--loyalty {
    display: none;
  }
  .e-list_not_signed {
    @include swap_direction(padding, 0);
    color: $color-navy;
    [data-theme='dark'] & {
      @if $loyalty-banner-cr23update == false {
        color: $color-white;
      }
    }
    @media #{$medium-up} {
      margin-top: -90px;
    }
    .loyalty-account-page & {
      @include swap_direction(padding, 20px 30px);
    }
  }
  .e-list_not_signed-left__inner {
    @media #{$medium-up} {
      background: url(/media/images/account/loyalty/loyalty_tout_bg02.jpg) no-repeat right bottom,
        url('/media/images/account/loyalty/join_e_list_header.png') no-repeat left top;
      @include min-height(241px); // match e-list_not_signed-left height
      padding-bottom: 55px;
    }
  }
  .e-list_not_signed__link {
  }
  .e-list_not_signed_wrapper {
    background-color: transparent;
  }
  .e-list_not_signed-left {
    width: 100%;
    @media #{$medium-up} {
      background: url(/media/images/account/loyalty/loyalty_tout_bg01.jpg) no-repeat 0 40px;
      float: $ldirection;
      width: 68%;
      // @include min-height(286px); //fit background + room for loyalty_tout_bg02
      @include swap_direction(padding, 85px 0 0 106px);
      position: relative;
      #{$rdirection}: 30px;
    }
  }
  .e-list_not_signed-right {
    width: 100%;
    @media #{$medium-up} {
      float: $rdirection;
      width: 32%;
      @include swap_direction(padding, 65px 10px 25px 10px);
    }
  }
  .e-list_not_signed .title_super {
    //text-indent: 8px;
    //font-size: 8px;
    display: none;
    @media #{$medium-up} {
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      text-indent: 0;
      font-size: 12px;
      padding-bottom: 0;
      visibility: hidden;
    }
  }
  .e-list_not_signed .title_main {
    //font-size: 40px;
    //margin-top: -10px;
    //line-height: 44px;
    display: none;
    @media #{$medium-up} {
      display: block;
      font-family: $optimalight;
      font-size: 70px;
      margin-top: -25px;
      line-height: 74px;
      margin-#{$ldirection}: 5px;
      visibility: hidden;
    }
  }
  .e-list_not_signed .title_subheader {
    //@include swap_direction(margin, -5px 0 5px 1px);
    display: none;
    @media #{$medium-up} {
      display: block;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      @if $loyalty-banner-cr23update == false {
        @include swap_direction(margin, -5px 0 10px 24px);
      }
      visibility: hidden;
    }
  }
  .e-list_not_signed .sub_title {
    font-size: 34px;
    font-family: $optimalight;
    line-height: 31px;
    width: 96%;
    display: block;
    word-spacing: 1px;
    letter-spacing: -1.1px;
    @media #{$medium-up} {
      font-size: 28px;
      line-height: 30px;
      width: 90%;
      word-spacing: inherit;
      letter-spacing: inherit;
    }
  }
  .e-list_not_signed p {
    line-height: 1.1em;
    font-size: 15px;
  }
  .e-list_not_signed-right p {
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1.4em;
  }
  p.e-list_not_signed__text {
    display: none;
    @media #{$medium-up} {
      display: block;
      @include swap_direction(margin, 10px 0);
      line-height: 22px;
      font-size: 15px;
      width: 70%;
      color: $color-navy;
    }
    [data-theme='dark'] & {
      @if $loyalty-banner-cr23update == false {
        color: $color-white;
      }
    }
  }
  .e-list_not_signed__link {
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: underline;
    color: $color-navy;
    font-weight: bold;
    letter-spacing: 0.15em;
    [data-theme='dark'] & {
      @if $loyalty-banner-cr23update == false {
        color: $color-white;
      }
    }
  }
  .e-list_not_signed__link-container {
    margin-top: 11px;
    @media #{$medium-up} {
      margin-top: 20px;
    }
  }
  .e-list_not_signed-right__text {
    display: none;
    @media #{$medium-up} {
      display: block;
      letter-spacing: 0.15em;
    }
  }
  .e-list_not_signed-right__button-container {
    position: relative;
    .loading {
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $color-white;
    }
  }
  .e-list_not_signed-right .btn_join {
    @media #{$small-only} {
      background: $color-white;
      color: $color-navy;
      @include swap_direction(border, 1px solid $color-navy);
      width: 100%;
      @include swap_direction(margin, 30px 0 20px 0);
    }
    @include swap_direction(padding, 0 80px);
    @include swap_direction(margin, 10px 0);
  }
  .e-list_not_signed-right .footnote {
    font-size: 12px;
    text-transform: none;
    @include swap_direction(margin, 0);
    display: none;
    @media #{$medium-up} {
      @include swap_direction(margin, 12px 0);
      display: block;
    }
    &.mobile_terms {
      display: block;
      a {
        text-decoration: underline;
      }
      @media #{$medium-up} {
        display: none;
      }
    }
  }
  .e-list_not_signed-right .footnote a {
    text-transform: none;
  }
  /* enrolled */
  .e-list_signed {
    @include clearfix;
  }
  .e-list_signed-left {
    width: 100%;
    text-align: $ldirection;
    @media #{$medium-up} {
      float: $ldirection;
      width: 75%;
      max-width: 525px;
    }
  }
  .e-list_signed-right {
    // does not exist
    float: $rdirection;
    text-align: center;
    width: 50%;
  }
  .be_excited,
  .be_enthusiastic,
  .be_envied {
    position: relative;
    width: 33%;
    float: $ldirection;
    text-align: center;
    z-index: 3;
  }
  .chart {
    @include clearfix;
    position: relative;
  }
  .loyalty__panel__points__bg,
  .loyalty__panel__points__bg__slide {
    position: absolute;
    #{$ldirection}: 0;
    top: 0;
    height: 12px;
    width: 99%;
    // @media #{$medium-up} {
    // }
  }
  .loyalty__panel__points__bg {
    z-index: 0;
    background-color: #eee;
  }
  .loyalty__panel__points__bg__slide {
    @include background(linear-gradient(to right, #39526e, #050a2d));
    z-index: 1;
    background-repeat: no-repeat;
  }
  .chart .block {
    height: 25px;
    width: 100%;
    //background-color: #eee;
    background-color: transparent;
  }
  .chart .block.tier-1,
  .chart .block.tier-2,
  .chart .block.tier-3 {
    padding-bottom: 12px;
    //margin-top: 1px;
  }
  .chart .block.tier-1 {
    background: url(/media/images/account/loyalty/lvl_marker_left_mob.gif) no-repeat bottom left;
    @media #{$medium-up} {
      background: url(/media/images/account/loyalty/lvl_marker_left.gif) no-repeat bottom left;
    }
  }
  .chart .block.tier-2 {
    background: url(/media/images/account/loyalty/lvl_marker_center_mob.gif) no-repeat bottom center;
    @media #{$medium-up} {
      background: url(/media/images/account/loyalty/lvl_marker_center.gif) no-repeat bottom center;
    }
  }
  .chart .block.tier-3 {
    background: url(/media/images/account/loyalty/lvl_marker_right_mob.gif) no-repeat bottom right;
    @media #{$medium-up} {
      background: url(/media/images/account/loyalty/lvl_marker_right.gif) no-repeat bottom right;
    }
  }
  .chart p {
    @include swap_direction(margin, 0);
    color: $color-medium-gray;
    font-family: $optimaregular;
    font-size: 10px; //12.5px
    letter-spacing: 0;
    @include swap_direction(margin, 0);
    text-transform: capitalize;
    text-align: $ldirection;
    font-weight: bold;
    @media #{$medium-up} {
      font-size: 17px;
    }
    &.tier-1-benefits,
    &.tier-2-benefits,
    &.tier-3-benefits {
    }
    &.tier-2-benefits {
      text-align: center;
    }
    &.tier-3-benefits {
      text-align: $rdirection;
    }
  }
  .anniversary {
    float: none;
    clear: $ldirection;
    padding-top: 31px;
    @media #{$medium-up} {
      float: none;
      clear: both;
    }
    a {
      font-size: 12px;
      letter-spacing: 0.15em;
      text-decoration: underline;
      text-transform: uppercase;
    }
    &__header {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.15em;
      margin-bottom: 3px;
    }
  }
  .points_next {
    width: 100%;
    float: none;
    clear: $ldirection;
    padding-top: 31px;
    @media #{$medium-up} {
      display: none;
      float: $ldirection;
      width: 50%;
      clear: none;
    }
    a {
      font-size: 12px;
      letter-spacing: 0.15em;
      text-decoration: underline;
      text-transform: uppercase;
    }
    &__header {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.15em;
      margin-bottom: 3px;
    }
  }
  .points_to_next_earn_before {
    display: none;
    @media #{$medium-up} {
      display: block;
      clear: both;
      padding-top: 25px;
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
    }
    a {
      display: none;
      @media #{$medium-up} {
        display: inline;
        font-size: 12px;
        letter-spacing: 0.05em;
        text-decoration: underline;
        text-transform: none;
      }
    }
  }
  .loyalty__panel__points__stats {
    @include clearfix;
    @include swap_direction(margin, 8px 0 19px 0);
  }
  .loyalty__panel__points__stats__header {
    margin-bottom: 5px;
    @media #{$medium-up} {
      margin-bottom: 5px;
    }
  }
  .points__current {
    font-size: 65px;
    line-height: 65px;
    font-family: $optimalight;
    @media #{$medium-up} {
      font-size: 80px;
      line-height: 74px;
    }
  }
  .points__text {
    font-size: 12px;
    font-family: $akzidenz;
    text-transform: uppercase;
    @media #{$medium-up} {
      font-size: 16px;
    }
  }
  .points {
    float: $ldirection;
    padding-#{$rdirection}: 11px;
    border-#{$rdirection}: 1px solid $color-light-gray;
    @media #{$medium-up} {
      padding-#{$rdirection}: 22px;
    }
  }
  .status {
    float: $ldirection;
    font-size: 12px;
    text-transform: uppercase;
    @include swap_direction(padding, 22px 0 0 10px);
    color: $color-navy;
    letter-spacing: 0.15em;
    @media #{$medium-up} {
      font-size: 16px;
      @include swap_direction(padding, 20px 0 0 18px);
    }
    .tier-1,
    .tier-2,
    .tier-3 {
      font-weight: bold;
    }
  }
  .btn_see_lyt_rewards {
    @include swap_direction(margin, 30px 0);
  }
  .e-list_signed-right h5 {
    font-family: $akzidenz;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 15px;
    letter-spacing: 2px;
  }
  .slide {
    background-color: $color-gray-cs-side;
    width: 315px;
    @include swap_direction(padding, 30px 15px 40px);
    @include swap_direction(margin, 20px auto);
  }
  .slide .sub_title {
    font-weight: bold;
    text-transform: uppercase;
  }
  .slide p {
    font-size: 12px;
    line-height: 1.1em;
  }
  .status-tier-status-1 .tier-2,
  .status-tier-status-1 .tier-3,
  .status-tier-status-2 .tier-1,
  .status-tier-status-2 .tier-3,
  .status-tier-status-3 .tier-1,
  .status-tier-status-3 .tier-2 {
    display: none;
  }
  .chart-tier-status-1 .tier-1,
  .chart-tier-status-2 .tier-1,
  .chart-tier-status-2 .tier-2,
  .chart-tier-status-3 .tier-1,
  .chart-tier-status-3 .tier-2,
  .chart-tier-status-3 .tier-3 {
    //background-color: $color-medium-navy;
  }
  .chart-tier-status-1 .tier-1 {
    //background-image: url('/media/export/cms_2.0/loyalty/3px_bar_be_excited.jpg');
  }
  .chart-tier-status-2 .tier-1,
  .chart-tier-status-2 .tier-2 {
    //background-image: url('/media/export/cms_2.0/loyalty/3px_bar_be_enthusiastic.jpg');
  }
  .chart-tier-status-3 .tier-1,
  .chart-tier-status-3 .tier-2,
  .chart-tier-status-3 .tier-3 {
    //background-image: url('/media/export/cms_2.0/loyalty/3px_bar_be_envied.jpg');
  }
  .chart-tier-status-1 .tier-1 + p,
  .chart-tier-status-2 .tier-2 + p,
  .chart-tier-status-3 .tier-3 + p {
    color: $color-navy;
  }
  .chart-tier-status-1 p.tier-1-benefits,
  .chart-tier-status-2 p.tier-2-benefits,
  .chart-tier-status-3 p.tier-3-benefits {
    color: $color-navy;
  }
  .chart-tier-status-2 .tier-2 {
    background-position: 476px;
  }
  .chart-tier-status-3 .tier-2 {
    background-position: 300px;
  }
  .chart-tier-status-3 .tier-3 {
    background-position: 162px;
  }
  .info-tier-status-3 .points_next {
    display: none;
  }
  .info-tier-status-3 {
    @media #{$medium-up} {
      .new_points_disclaimer {
        clear: both;
        padding-top: 10px;
      }
      .points_to_next_earn_before {
        display: none;
      }
    }
  }
}

/// end move

#loyalty__panel__points {
  .not_loyalty_member & {
    padding-top: 41%;
    background: url(/media/images/account/loyalty/estee_elist_join_mob.jpg) no-repeat 0 0;
    background-size: contain;
    @media #{$medium-up} {
      padding-top: 0;
      background: none;
    }
  }
  @extend %loyalty-panel;
  text-align: $ldirection;
  @include swap_direction(padding, 0 $mobile-outer-gutter 30px $mobile-outer-gutter);
  @media #{$medium-up} {
    @include swap_direction(padding, 0 10px);
  }
  .loyalty-account-page & {
    width: 100%;
    @include swap_direction(padding, 10px $mobile-outer-gutter);
    @media #{$medium-up} {
      @include swap_direction(padding, 10px 10px 30px 10px);
    }
  }
  @media #{$medium-up} {
    // Making space for the Next Tier panel.
    float: $ldirection;
    width: 70%;
    &.loyalty__has-max-tier,
    &.loyalty__has-pending-points {
      float: none;
      width: auto;
    }
  }
  .loyalty__panel__points__title {
    @extend %loyalty-panel-title;
    @media #{$small-only} {
      display: none;
    }
  }
  .loyalty__panel__points__title--loyalty {
    display: block;
    margin-bottom: 15px;
    @media #{$small-only} {
      color: $color-navy;
      font-family: $optimaregular;
      font-size: 22px;
      letter-spacing: 0px;
    }
    @media #{$medium-up} {
      margin-bottom: 15px;
    }
  }
  .loyalty__panel__points__title--account {
    display: none;
  }
  .loyalty__panel__points__available,
  .loyalty__panel__points__pending {
    float: $ldirection;
    width: 50%;
    .loyalty__panel__points__available-inner,
    .loyalty__panel__points__pending-inner {
      background: #eee;
      @include swap_direction(padding, 20px);
      text-align: center;
    }
    .loyalty__panel__points__available-title,
    .loyalty__panel__points__pending-title {
      display: block;
      margin-bottom: 10px;
    }
    .loyalty__panel__points__available-title,
    .loyalty__panel__points__available-amount {
      font-weight: bold;
    }
    .loyalty__panel__points__pending-title {
      font-style: italic;
    }
    .loyalty__panel__points__pending-annotation {
      text-align: $rdirection;
    }
  }
  .loyalty__panel__points__available {
    @include swap_direction(padding, 0 5px 0 0);
  }
  .loyalty__panel__points__pending {
    @include swap_direction(padding, 0 0 0 5px);
  }
  .disclaimer {
    display: none;
    // @media #{$medium-up} {
    //   display: none;
    // }
    a {
      text-decoration: underline;
    }
    p {
      font-size: 12px;
      line-height: 16px;
      color: $color-navy;
    }
  }
  .new_points_disclaimer {
    // display: none;
    // @media #{$medium-up} {
    //   display: block;
    // }
    @media #{$small-only} {
      br {
        display: none;
      }
    }
    p {
      color: $color-navy;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
    }
  }
  .loyalty__panel__points__cta {
    clear: both;
    margin-top: 25px;
    a {
      width: 100%;
      height: 33px;
      line-height: 33px;
      @media #{$medium-up} {
        width: auto;
      }
    }
    .loyalty-account-page & {
      display: none;
    }
    .account-page__loyalty & {
      display: none;
      @media #{$medium-up} {
        display: block;
      }
    }
  }
}
/* Points History Page */
.loyalty-account-page #loyalty__page__history {
  .loyalty__panel__points-less-content {
    display: none;
    @media #{$medium-up} {
      display: block;
    }
  }
  .loyalty__panel__points-instruction-show-less,
  .loyalty__panel__points-instruction-show-more {
    text-align: $rdirection;
    text-decoration: underline;
    @media #{$medium-up} {
      display: none;
    }
  }
  .loyalty__panel__points__header {
    border-bottom: 1px solid $color-light-gray;
    font-family: 'OptimaDisplayLight';
    font-size: 45px;
    letter-spacing: 0.01em;
    line-height: 38px;
    margin-bottom: 40px;
    @include swap_direction(padding, 20px);
    text-align: center;
  }
  // original commit af875115a50ba04a85b5ed0a9a7bf7e05ccaa245
  // Mathe looking into reason for padding change
  // pushing temporary fix until update
  // .status {
  //   @include swap_direction(padding, 11px 0 26px 0);
  //   @media #{$small-only} {
  //     padding-bottom: 10px;
  //   }
  // }
  .loyalty__panel__transactions__list__column-points {
    display: none;
  }
  .loyalty__panel__transactions__title {
    @include swap_direction(border, medium none);
    padding-bottom: 8px;
    //margin-bottom: 8px;
    @include swap_direction(margin, 0); //@include swap_direction(margin,0 0 10px -25px);
    text-indent: 0; //text-indent: 25px;
    width: 100%; //width: 120%;
    font-family: $optimaregular;
    font-size: 22px;
    letter-spacing: 0;
    border-bottom: 1px solid #ccc;
    @media #{$medium-up} {
      font-family: $optimalight;
      font-size: 20px;
      letter-spacing: 0.01em;
    }
  }
  #loyalty__panel__transactions {
    table {
      border-bottom: 1px solid $color-light-gray;
      // border-top: 1px solid $color-light-gray;
      border-#{$ldirection}: none;
      border-#{$rdirection}: none;
    }
    th {
      // border-bottom: 1px solid $color-light-gray;
      // border-top: 1px solid $color-light-gray;
      border-#{$ldirection}: none;
      border-#{$rdirection}: none;
    }
    td {
      border-bottom: 1px solid $color-light-gray;
      border-top: 1px solid $color-light-gray;
      border-#{$ldirection}: none;
      border-#{$rdirection}: none;
    }
  }
  .loyalty__panel__transactions__list__column-date {
    text-align: $ldirection;
    width: 40%;
    @media #{$medium-up} {
      width: auto;
    }
  }
  .loyalty__panel__transactions__list__column-activity {
    text-align: $ldirection;
  }
  .loyalty__panel__transactions__list__column-total {
    text-align: center;
    width: 10%;
  }
  .loyalty__panel__transactions__list__column-earned {
    width: 40%;
    @media #{$medium-up} {
      width: 10%;
    }
    &__points-earned {
      text-align: center;
    }
  }
  .loyalty__panel__transactions__transaction.loyalty__panel__transactions__transaction--level {
    background-color: #e6e5ea;
  }
  .loyalty__panel__transactions__transaction {
    color: $color-navy;
  }
  .loyalty__panel__transactions__list__column-activity--level {
    background-color: #e6e5ea;
  }
  .points_next a {
    font-size: 14px;
    text-decoration: underline;
    text-transform: uppercase;
  }
  .loyalty__panel__transactions__table th {
    color: $color-light-gray;
    font-size: 12px;
  }
  .loyalty__panel__transactions__current-tier {
    display: none;
  }
  .loyalty__panel__transactions__available-amount {
    display: none;
  }
  .loyalty__panel__transactions__list__column-activity__transaction-link__order-id {
    border-bottom: 1px solid #000;
  }
  #loyalty__panel__points-instruction {
    background-color: #f2f3f4;
    @include swap_direction(margin, 22px 0 40px 30px);
    @include swap_direction(padding, 30px 24px 26px 35px);
    clear: both;
    color: $color-navy;
    @media #{$small-only} {
      margin-#{$ldirection}: 0;
      margin-#{$rdirection}: 0;
      @include swap_direction(padding, 10px 30px);
    }
  }
  .loyalty__panel__points__instruction__collect_points {
    font-family: 'AkzidenzGrotesk W1G';
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 20px;
  }
  .loyalty__panel__points__instruction__earn_points,
  .loyalty__panel__points__instruction__fav_products,
  .loyalty__panel__points__instruction__elist_level {
    @include swap_direction(margin, 0 0 15px);
    @include swap_direction(padding, 0);
  }
  .history-page__header--loyalty {
    border-bottom: 1px solid $color-light-gray;
    margin-bottom: 20px;
    margin-top: 0;
    padding-bottom: 16px;
    text-align: center;
    color: $color-navy;
    @media #{$small-only} {
      margin-bottom: 10px;
      @include swap_direction(border, none);
      padding-bottom: 0;
    }
  }
  .loyalty__panel__points__title {
    @media #{$small-only} {
      border-bottom: 1px solid $color-light-gray;
      font-family: $optimaregular;
      font-size: 22px;
      letter-spacing: 0;
    }
  }
  .e-list_signed-left {
    @media #{$small-only} {
      width: 100%;
      .status b {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
  .anniversary,
  .points_next {
    @media #{$small-only} {
      float: none;
      clear: $ldirection;
      width: 100%;
    }
  }
  .loyalty__panel__transactions__list__column-activity__points-earned {
    display: none;
  }
  .loyalty__page__index__breadcrumb {
    padding-bottom: 0;
  }
  .loyalty__panel__transactions__list__column-plus {
    background-size: auto auto;
    background-position: 101% -4445px;
    height: 15px;
    width: 15px;
    text-indent: -1200px;
    @include swap_direction(padding, 0);
    @media #{$medium-up} {
      display: none;
    }
  }
  .loyalty__panel__transactions__list__column-minus {
    background-size: auto auto;
    background-position: 101% -4325px;
    height: 15px;
    width: 15px;
    text-indent: -1200px;
    @include swap_direction(padding, 0);
    @media #{$medium-up} {
      display: none;
    }
  }
}

.loyalty_popover__input.is-alternate .loyalty_popover__checkbox-label::after {
  background-image: url('/media/images/background-images/background-image57.png');
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.pc-hidden {
  display: block;
  @media #{$medium-up} {
    display: none;
  }
}
