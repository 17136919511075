$lp-default: 50px; // + 30px for link + 20px for top
$lp-terms: 33px; // terms > 13px line-height + 20px margin
$lp-points: 36px; // points needed > 16px line-height + 20px margin
$lp-no-points: 33px; // no points needed 33px > buttons

#loyalty__panel__offers__sku {
  @extend %loyalty-panel;
  clear: both;
  .loyalty__panel__offers__sku__list-container {
    position: relative;
    padding-top: 36px;
    margin-top: 40px;
    &:first-child {
      margin-top: 0;
    }
  }
  .loyalty__panel__offers__sku__title-container {
    position: absolute;
    top: 15px;
    #{$ldirection}: 0;
    height: 38px;
    width: 100%;
    text-align: center;
  }
  .loyalty__panel__offers__sku__title {
    @extend %loyalty-title;
    background: $color-white;
    display: inline-block;
    @include swap_direction(padding, 0 30px 0 40px);
  }
  .loyalty__panel__offers__sku__sub-title {
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-align: center;
    margin-top: 10px;
    @include swap_direction(padding, 0 10%);
    @media #{$medium-up} {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.15em;
      margin-top: 20px;
      @include swap_direction(padding, 0 15%);
    }
  }
  .loyalty__panel__offers__sku__list-border {
    @media #{$medium-up} {
      padding-bottom: 30px;
      @include swap_direction(border, 1px solid $color-black);
    }
  }
  .loyalty__panel__offers__sku__list {
    @include clearfix;
    &.slick-slider {
      margin-bottom: 0;
    }
  }
  .loyalty__panel__offers__sku__loader {
    @extend %loyalty__panel__loader;
    background-position: center 1px;
  }
  .loyalty__panel__offers__sku__product {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    @media #{$medium-up} {
      @include min-width(220px);
      width: 33.3%;
      float: $ldirection;
      &:nth-child(3n + 1) {
        clear: $ldirection;
      }
      @media (max-width: 964px) {
        width: 49%;
        &:nth-child(3n + 1) {
          clear: none;
        }
        &:nth-child(2n + 1) {
          clear: $ldirection;
        }
      }
    }
    &.loyalty__panel__offers__sku__product--used {
      .loyalty__panel__product__button {
        display: none;
      }
      .loyalty__panel__offers__sku__redeemed {
        display: inline-block;
        cursor: default;
      }
    }
    &.loyalty__panel__offers__sku__disable__product--offer-used {
      opacity: 0.5;
      .loyalty__panel__product__button {
        display: none;
      }
    }
  }
  .loyalty__panel__offers__sku__product-wrapper {
    position: relative;
    width: 200px;
    @include swap_direction(padding, 30px 10px 105px 10px);
    @include swap_direction(margin, 0 auto);
    text-align: center;
    color: $color-navy;
    height: 100%;
    // @media #{$medium-up} {
    //   @include min-height(450px);
    // }
    &--terms,
    &--points {
      padding-bottom: $lp-default + $lp-points;
    }
    &--no-points {
      padding-bottom: $lp-default + $lp-no-points;
    }
    &--terms--points {
      padding-bottom: $lp-default + $lp-terms + $lp-points;
    }
    &--terms--no-points {
      padding-bottom: $lp-default + $lp-terms + $lp-no-points;
    }
  }
  .loyalty__panel__offers__sku__product-image-container {
    margin-bottom: 18px;
    @include min-height(198px);
  }
  .loyalty__panel__offers__sku__product__image {
    width: 144px;
    @include swap_direction(margin, 0 auto);
    @media #{$medium-up} {
      @include swap_direction(margin, 0);
    }
  }
  .loyalty__panel__offers__sku__product__header {
    font-family: $optimaregular;
    font-size: 22px;
  }
  .loyalty__panel__offers__sku__product__sub-header {
    font-family: $optimaregular;
    font-size: 16px;
    line-height: 20px;
  }
  .loyalty__panel__offers__sku__product__points {
    font-size: 12px;
    text-transform: uppercase;
    color: $color-red;
    margin-bottom: 20px;
  }
  .loyalty__panel__offers__sku__product__shade-container {
    @include clearfix;
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
  .loyalty__panel__offers__sku__product__shade {
    @include border-radius(18px/2);
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-#{$rdirection}: 4px;
  }
  .loyalty__panel__offers__sku__product__shadename {
    vertical-align: top;
  }
  .loyalty__panel__offers__sku__product__terms {
    font-family: $optimaregular;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 20px;
  }
  .loyalty__panel__offers__sku__product__offer-code {
    text-transform: uppercase;
    font-size: 12px;
    font-family: $optimaregular;
  }
  .loyalty__panel__offers__sku__cta__container {
    position: absolute;
    bottom: 0;
    #{$ldirection}: 0;
    width: 100%;
    padding-bottom: 30px;
  }
  .loyalty__panel__offers__sku__cta {
    position: relative;
    top: 20px;
  }
  .loyalty__panel__offers__sku__link-container {
    position: absolute;
    bottom: 0;
    #{$ldirection}: 0;
    width: 100%;
  }
  .loyalty__panel__offers__sku__link {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: underline;
  }
}
