.formatter-beauty-feed {
  padding-top: 23px;
  @include clearfix;
  background: $color-navy;
  color: $color-white;
  .js &.loading .formatter-beauty-feed__content {
    visibility: hidden;
    min-height: 500px;
  }
  &__header {
    // .formatter-beauty-feed__header
    margin-bottom: 49px;
    > .headline {
      font-family: $optimalight;
      font-size: 39px;
      letter-spacing: -0.02em;
      line-height: 1;
      margin-bottom: 10px;
    }
    > .subhead {
      color: $color-periwinkle-blue;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.7px;
      > a {
        color: $color-periwinkle-blue;
        text-decoration: underline;
      }
    }
  } // /&__footer

  &__content {
    // .formatter-beauty-feed__content
    max-width: 984px;
    @include swap_direction(margin, 0 auto);
    @media #{$small-only} {
      max-width: 328px;
    }
  } // /&__content

  &__isotope-item {
    // .formatter-beauty-feed__isotope-item
    width: 308px;
    @include swap_direction(margin, 11px 10px);
    float: $ldirection;
    overflow: visible;
    img {
      width: 100%; // dev
    }
  } // /&__isotope-item

  &__footer {
    // .formatter-beauty-feed__footer
    @include swap_direction(padding, 63px 0 100px);
    > .headline {
      font-family: $optimalight;
      font-size: 39px;
      letter-spacing: -0.02em;
      line-height: 1;
      margin-bottom: 10px;
    }
    > .subhead {
      color: $color-periwinkle-blue;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.7px;
      > a {
        color: $color-periwinkle-blue;
        text-decoration: underline;
      }
    }
  }
} // /.formatter-beauty-feed

.beauty-feed-tout {
  text-align: center;
  overflow: hidden;
  .eyebrow {
    padding-bottom: 16px;
  }
  .button--dark-tertiary {
    margin-top: 1.5em;
  }
  .social-image {
    max-width: 100%;
    height: auto;
  }
  .social-icon {
    @include swap_direction(margin, 0 auto);
  }
  .social-user {
    @include link--bold;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: none;
  }
  .social-action {
    @include link--bold;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: underline;
  }
}
// start elements of block

.beauty-feed-tout__header {
  // .beauty-feed-tout__header
  background: $color-light-navy-alt;
  @include swap_direction(padding, 30px);
} // /.beauty-feed-tout__header

// end elements of block
// start modifiers on block

.beauty-feed-tout--tool {
  text-align: center;
  height: 226px;
  &__headline {
    font-size: 40px;
    font-family: $optimalight;
    padding-top: 37px;
    &.color--dark {
      color: $color-navy;
    }
  }
  &__button {
    margin-top: 5px;
  }
} // /.beauty-feed-tout--tool

.beauty-feed-tout--video {
  position: relative;
  &__img {
    height: 173px;
  }
  .video-play {
    @include arrow($color: $color-white, $width: 29px, $height: 34px, $direction: right);
    position: absolute;
    top: 127px;
    #{$ldirection}: 29px;
    &.color--dark {
      @include arrow($color: $color-navy, $width: 29px, $height: 34px, $direction: right);
    }
  }
} // /.beauty-feed-tout--video

.beauty-feed-tout--facebook {
  &__header {
    padding-top: 26px;
    padding-bottom: 24px;
  }
  .social-icon {
    background-size: auto auto;
    background-position: 0 -3347px;
    height: 24px;
    width: 13px;
    margin-bottom: 8px;
  }
  .social-user {
    margin-bottom: 4px;
    display: block;
  }
  .social-timestamp {
    margin-bottom: 6px;
  } // /&__header
  .fb-post {
    display: none;
  }
} // /.beauty-feed-tout--facebook

.beauty-feed-tout--twitter {
  .beauty-feed-tout__wrapper {
    @include swap_direction(border, solid 1px $color-light-navy-alt);
  }
  .social-content {
    font-size: 40px;
    line-height: 1;
    font-family: $optimalight;
    letter-spacing: -2px;
    -webkit-font-smoothing: antialiased;
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 26px);
    a {
      color: $color-periwinkle-blue;
      text-decoration: none;
      font-size: 36px;
    }
  } // /.social-content
  &__header {
    @include swap_direction(padding, 20px 30px 18px);
  }
  .social-icon {
    background-size: auto auto;
    background-position: 0 -11880px;
    height: 24px;
    width: 26px;
    margin-bottom: 10px;
  }
  .social-user {
    text-decoration: underline;
    color: $color-periwinkle-blue;
  }
  .social-user-wrapper {
    @include swap_direction(margin, 0 0 22px);
    .separator {
      font-size: 12px;
      color: $color-periwinkle-blue;
      @include swap_direction(margin, 0 1px);
    }
  }
  .social-action-wrapper {
    @include swap_direction(margin, 0);
    text-align: $ldirection;
  }
  .social-action {
    color: $color-periwinkle-blue;
    &--reply {
      // .social-action--reply
    }
    &--retweet {
      // .social-action--retweet
      margin-#{$ldirection}: 20px;
    }
    &--favorite {
      // .social-action--favorite
      margin-#{$ldirection}: 21px;
    }
  } // /.social-action
} // /.beauty-feed-tout--twitter

.beauty-feed-tout--instagram {
  .social-icon {
    background-size: auto auto;
    background-position: 0 -3847px;
    height: 24px;
    width: 23px;
  }
  .social-iframe--instagram {
    display: none;
  }
  .js-social-action--instagram-like {
    display: inline;
  }
  .js-social-action--instagram-unlike {
    display: none;
  }
} // /.beauty-feed-tout--instagram

// shared beauty feed product styles
.beauty-feed-tout--products {
  background: $color-light-navy-alt;
  padding-bottom: 25px; // this is for slick dots
  .product_brief--beauty-feed__image-container,
  .sku-brief-editorial__image-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    width: 308px;
    height: 424px;
    margin-bottom: 51px;
  }
  .text-product-listing-names-main {
    margin-bottom: 5px;
  }
  .text-product-listing-names-sub {
    margin-bottom: 5px;
  }
  .product-brief__button-quickshop {
    padding-#{$ldirection}: 16px;
    padding-#{$rdirection}: 16px;
    margin-bottom: 4px;
    @media #{$small-only} {
      display: none;
    }
  }
  .product_brief--beauty-feed__image,
  .sku-brief-editorial__image {
    width: 308px;
    height: 424px;
  }
  .text-product-listing-names-link {
    display: block;
    @include swap_direction(margin, 0 45px);
    color: $color-white;
  }
  .button--dark-secondary {
    margin-top: 0.5em;
    color: $color-periwinkle-blue;
    border-color: $color-periwinkle-blue;
    background: $color-light-navy-alt;
  }
  .sku-brief-editorial .link--bold {
    color: $color-periwinkle-blue;
  }
  .sku-brief-editorial__button-wrapper {
    .button--dark-secondary {
      @include swap_direction(margin, 1.5em auto);
    }
  }
  .beauty-feed-tout--custom-products__list .button--dark-secondary,
  .beauty-feed-tout--bestseller-products__list .button--dark-secondary {
    margin-top: 1.5em;
  }
  .product_brief__desc1 {
    // This only displays for foundation finder results
    @include swap_direction(margin, 13px auto 0);
    letter-spacing: 0.15em;
    max-width: 200px;
  }
  .product-brief__view-details {
    color: $color-periwinkle-blue;
  }
  .slick-slider {
    margin-bottom: 13px;
    .slick-prev,
    .slick-next {
      width: 30px;
      height: 40px;
      top: 558px;
      &:before {
        content: '';
      }
    }
    .slick-prev {
      #{$ldirection}: 12px;
      background-size: auto auto;
      background-position: 0 -8930px;
      height: 40px;
      width: 30px;
    }
    .slick-next {
      #{$rdirection}: 12px;
      background-size: auto auto;
      background-position: 0 -8710px;
      height: 40px;
      width: 30px;
    }
    .slick-dots {
      bottom: -25px;
      li {
        @include swap_direction(margin, 0);
        button {
          &:before {
            // regular dot
            opacity: 1;
            color: $color-periwinkle-blue;
            font-size: 9px;
          }
        }
        &.slick-active {
          button:before {
            // active dot
            opacity: 1;
            color: $color-white;
            font-size: 9px;
          }
        }
      }
    }
  }
  .sku-brief-editorial__size,
  .sku-brief-editorial__price {
    display: inline;
  }
  .sku-brief-editorial__shadename {
    @include headline--senary;
  }
  .sku-brief-editorial__shade {
    @include swap_direction(margin, 5px 0 0);
  }
  .sku-brief-editorial__shadename {
    text-transform: none;
    margin-bottom: 0;
    padding-#{$ldirection}: 23px;
  }
  &.sku-brief-editorial--multi-sku .sku-brief-editorial__shadename {
    padding-#{$ldirection}: 0;
    text-align: center;
  }
  .sku-brief-editorial__size {
    text-transform: none;
    font-family: $optimaregular;
  }
  .sku-brief-editorial__swatch-container {
    @include swatches(15px, 50%, 33%, 66%, 3px);
    .swatches--single {
      overflow: visible;
    }
  }
} // /.beauty-feed-tout--products

// end modifiers on block

.bf-mustache-template,
.social-action--facebook--Comment {
  @include hidden;
}

// bestsellers SKU list and carousel
.beauty-feed-tout--products__headline,
.beauty-feed-tout--wish-products .beauty-feed-tout__header,
.beauty-feed-tout--expert-products .beauty-feed-tout__header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  top: 453px;
  width: 100%;
  z-index: 10;
  @include swap_direction(padding, 0);
}

// logged in users
.js-beauty-feed__title {
  text-transform: capitalize;
}

// center colorbox overlay for fb
[class*='section-beauty-feed'] #cboxLoadedContent {
  text-align: center;
  // but not quickshop
  .quickshop {
    text-align: $ldirection;
  }
}

// Profile questions overlay
body#beauty_feed {
  #cboxClose {
    top: 30px;
  }
  .beauty-feed-overlay__section {
    .section-head {
      @include swap_direction(border, none);
      @include swap_direction(margin, -2px 0 8px);
      font-size: 40px;
    }
    .beauty-feed-overlay__section-text {
      @include swap_direction(margin, 0 0 40px);
      line-height: 1;
    }
    form#beauty_questions .form-submit {
      float: $rdirection;
    }
    .beauty-questions__list-item {
      @include swap_direction(margin, 0px 25px 40px 0px);
      @include swap_direction(padding, 0);
      &:nth-child(even) {
        margin-#{$rdirection}: 0;
      }
      @media #{$small-up} {
        width: 100%;
      }
      @media #{$medium-up} {
        width: 48.25%;
      }
      .beauty-questions__label {
        line-height: 1;
        margin-bottom: 16px;
      }
    }
    .beauty-feed-overlay__section-link {
      text-decoration: underline;
      @media #{$small-up} {
        float: $ldirection;
        margin-top: 25px;
        line-height: 1;
      }
      @media #{$medium-up} {
        float: $rdirection;
        clear: both;
      }
    }
  }
}

// Beauty Feed Loyalty Tout
.beauty-feed-loyalty-tout {
  .non-member-loyalty-tout {
    width: 308px;
    display: block;
    .beauty-feed-tout__header {
      text-align: center;
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
      .beauty-feed-tout__headline {
        color: #fff;
        font-weight: bold;
        font-family: $akzidenz;
        font-size: 12px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
      }
      .loyalty-tout-copy {
        color: #fff;
        text-align: center;
        font-family: $akzidenz;
        font-size: 12px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
      }
    }
  }
  .member-loyalty-tout {
    width: 308px;
    height: 403px;
    display: none;
    position: relative;
    color: $color-navy;
    .member-loyalty-tout-bg-image {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: 308px;
      height: 403px;
    }
    .member-loyalty-wrapper {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: 308px;
      height: 403px;
      @include swap_direction(padding, 40px 20px);
    }
    .tout-loyalty-member-headline {
      margin-top: 40px;
      margin-bottom: 30px;
      color: $color-navy;
      text-align: center;
      font-family: $akzidenz;
      font-size: 12px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      width: 100%;
      display: inline-block;
    }
    .tout-loyalty-member-greeting {
      color: $color-navy;
      text-align: center;
      font-family: $akzidenz;
      font-size: 12px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      margin-bottom: 20px;
      display: inline-block;
      width: 100%;
    }
    .points-and-level-wrapper {
      display: inline-block;
      width: 100%;
      .points-wrapper {
        width: 150px;
        float: $ldirection;
        text-align: $rdirection;
        padding-#{$rdirection}: 15px;
        color: $color-navy;
        border-#{$rdirection}: $color-navy solid 1px;
        display: inline-block;
        .points-value {
          font-family: $optimalight;
          font-size: 60px;
          line-height: 66px;
          color: $color-navy;
        }
        .points-label {
          font-size: 16px;
          font-family: $akzidenz;
          color: $color-navy;
        }
      }
      .level-wrapper {
        width: 110px;
        float: $ldirection;
        text-align: $ldirection;
        padding-#{$ldirection}: 15px;
        color: $color-navy;
        .level {
          width: 100%;
          display: inline-block;
          margin-top: 10px;
          font-size: 13px;
        }
        .loyalty-status {
          width: 100%;
          display: inline-block;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 13px;
        }
      }
    }
    .see-my-rewards-button.button--dark-tertiary {
      color: $color-navy;
      @include swap_direction(border, 1px solid $color-navy);
      font-weight: bold;
    }
  }
  &.loyal {
    .non-member-loyalty-tout {
      display: none;
    }
    .member-loyalty-tout {
      display: block;
    }
  }
}
