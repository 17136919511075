// offer_grid_v1
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin scale($scale) {
  @include transform(scale($scale));
  moz-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

$color-red-darker: #c30505;
$color-red-darker-alpha: rgba(195, 5, 1, 0.3);

.offer-grid {
  position: relative;
  max-width: 1548px;
  margin: auto;
  display: flex;
  .node-elc-nodeblock & {
    @media #{$medium-up} {
      margin-top: $header-collapsed-height-pc;
    }
  }
  // 3 columns short
  @media screen and (max-width: 890px) {
    margin-bottom: 20px;
  }
  .navigation {
    float: #{$ldirection};
    min-width: 222px;
    padding-#{$ldirection}: 20px;
    .navigation_node_ref {
      position: fixed;
    }
    // 3 columns
    @media screen and (max-width: 890px) {
      display: none;
    }
  }
  &__container {
    overflow-x: auto;
    overflow-y: hidden;
    background-position: -37px 0;
    background-size: 192% 152%;
    background-repeat: no-repeat;
    display: inline-block;
    padding: 15px;
    width: 85%;
    padding-bottom: 50px;
    @media #{$medium-down} {
      @media #{$landscape} {
        background-position: -67px -10px;
      }
    }
    @media #{$medium-up} {
      background-position: #{$ldirection} top;
      background-size: 1160px 933px;
    }
    @media #{$large-up} {
      max-width: 1050px;
      margin-bottom: 20px;
      padding-bottom: 145px;
    }
    // grid layout response
    // 4 columns
    @media screen and (max-width: 1305px) {
      min-width: 100%;
      margin-bottom: 0;
      @media #{$medium-up} {
        padding-bottom: 50px;
      }
      @media #{$large-up} {
        min-width: 1050px;
        @media screen and (min-width: 1096px) {
          @include scale(0.9);
          padding-bottom: 145px;
        }
      }
    }
    // 3 columns
    @media screen and (max-width: 1095px) {
      max-width: 645px;
      min-width: 100%;
      @media #{$medium-down} {
        @media #{$landscape} {
          min-height: 700px;
        }
        @media #{$portrait} {
          min-height: 386px;
        }
      }
      @media #{$medium-up} {
        min-width: 645px;
      }
    }
    // 3 columns short
    @media screen and (max-width: 890px) {
      margin: auto;
      @media #{$iphone6plus-portrait} {
        max-width: 480px;
        min-height: 420px;
        background-position: -37px -20px;
      }
      @media #{$iphone6plus-landscape} {
        min-height: 750px;
      }
    }
  }
  .sub-title,
  .title {
    width: 100%;
    text-align: center;
    max-width: 1075px;
    color: $color-red-darker;
  }
  .item-title {
    font-size: 80px;
    font-family: $optimalight;
    @include vertical-align();
    text-align: center;
    font-family: $linotype-didot-roman;
    @media #{$medium-up} {
      font-size: 145px;
    }
    @media #{$medium-down} {
      @media #{$landscape} {
        font-size: 140px;
      }
    }
  }
  .item-title-text {
    font-size: 13px;
    font-family: $akzidenz;
    text-align: center;
    @include vertical-align();
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 3px;
    padding: 10%;
    line-height: 22px;
  }
  .sub-title {
    font-size: 13px;
    font-weight: 900;
    font-family: $akzidenz;
    letter-spacing: 1.5px;
    margin-top: 25px;
    text-transform: uppercase;
    display: none;
    @media #{$medium-up} {
      display: block;
    }
    @media #{$medium-down} {
      @media #{$landscape} {
        display: block;
      }
    }
  }
  .title {
    font-size: 37px;
    font-family: $optimalight;
    letter-spacing: 0.001em;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 10px;
    @media #{$medium-up} {
      font-size: 68px;
      margin-bottom: 10px;
      margin-top: 0;
    }
  }
  .offer-grid-item {
    width: 32%;
    height: 125px;
    border: 1px solid $color-white;
    overflow: hidden;
    color: $color-white;
    display: inline-block;
    margin: 2px;
    float: #{$ldirection};
    background-color: inherit;
    @media #{$medium-up} {
      width: 196px;
      height: 240px;
      margin: 4px;
    }
    @media #{$medium-down} {
      @media #{$landscape} {
        height: 237px;
        margin: 4px;
      }
    }
    &.inactive-tout > div {
      opacity: 0.5;
    }
    &:hover:not(.blank-tout):not(.overlay-active):not(.overlay-loading) {
      background-color: $color-red-darker-alpha;
      cursor: pointer;
    }
    &.blank-tout:hover {
      cursor: default;
    }
    &.overlay-loading:hover {
      background-color: $color-red-darker-alpha;
      cursor: progress;
    }
    &.overlay-active,
    .overlay-active:hover {
      background-color: $color-red-darker-alpha;
      cursor: default;
    }
    @media screen and (max-width: 1095px) {
      // only last one
      // 3 columns
      &.blank-tout:nth-of-type(n + 10) {
        display: none;
      }
    }
    @media screen and (max-width: 890px) {
      // except the first one
      // 3 columns short
      &.blank-tout:nth-of-type(n + 5) {
        display: none;
      }
    }
  }
  &__overlay {
    max-width: 751px;
    max-height: 316px;
    width: 90%;
    height: 316px;
    margin: auto;
    display: block;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-color: $color-white;
    @media #{$medium-up} {
      width: 751px;
      height: 416px;
      max-height: 416px;
    }
    .product-image {
      float: #{$ldirection};
      display: none;
      @include vertical-align();
      @include swap_direction(padding, 0 40px);
      img {
        height: 284px;
        width: 206px;
      }
      @media #{$medium-up} {
        display: block;
        @media #{$portrait} {
          display: none;
        }
      }
    }
    .sub-title {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: normal;
      font-family: $akzidenz;
      letter-spacing: 2px;
      color: $color-navy;
      @media #{$medium-up} {
        font-weight: 900;
      }
    }
    .title {
      font-family: $optimalight;
      font-size: 42px;
      letter-spacing: -2px;
      line-height: 42px;
      color: $color-navy;
      @include swap_direction(margin, 12px 0);
      @media #{$small-only} {
        font-size: 35px;
        line-height: 35px;
        @include swap_direction(margin, 5px 0);
      }
    }
    &__container {
      overflow-x: auto;
      overflow-y: hidden;
      @include vertical-align();
      color: $color-navy;
      padding: 20px;
      @media #{$medium-up} {
        padding: 50px;
      }
      @media #{$large-up} {
        padding: 0;
      }
      @media #{$small-only} {
        font-size: 13px;
        line-height: 20px;
        br {
          content: ' ';
        }
        br:after {
          content: ' ';
        }
      }
    }
    .button__container {
      a {
        font-size: 13px;
        color: $color-navy;
        font-weight: 900;
        font-family: $akzidenz;
        display: block;
        text-transform: uppercase;
        margin-top: 20px;
        text-decoration: underline;
        letter-spacing: 2px;
        @media #{$medium-up} {
          font-size: 12px;
          line-height: 33px;
          letter-spacing: 1px;
          width: 143px;
          height: 35px;
          border: solid 2px $color-navy;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          text-align: center;
          text-decoration: none;
        }
      }
    }
    body.offer-grid-loading & {
      cursor: progress;
    }
  }
  // boutique adjustment
  .boutique.mobile & {
    @media #{$medium-down} {
      margin-top: 30px;
      @media #{$landscape} {
        margin-top: 60px;
      }
    }
  }
}

.offer-grid + .disclaimer {
  margin: 0;
  width: 100%;
  margin-bottom: 25px;
  @include swap_direction(padding, 0 20px 0 222px);
  // 3 columns short
  @media screen and (max-width: 890px) {
    padding: 20px;
  }
}
// overlay/colorbox
#cboxOverlay.offer-grid-overlay__content,
#cboxOverlay.offer-grid-overlay__content #cboxContent {
  body.offer-grid-active & {
    background: none;
  }
}

#colorbox.offer-grid-overlay__content #cboxContent {
  body.offer-grid-active & {
    padding: 0;
    #cboxLoadedContent > div {
      @include vertical-align();
    }
    @media #{$medium-down} {
      background: none;
    }
  }
  .device-mobile & {
    #cboxLoadedContent > div {
      @include vertical-align();
    }
  }
}

#colorbox.offer-grid-overlay__content {
  .device-mobile & {
    width: 80%;
    max-width: 80%;
    height: 316px;
    max-height: 316px;
    border: 1px solid $color-light-gray;
    #cboxClose,
    #cboxClose:hover {
      top: 10px;
      right: 10px;
    }
  }
}

.offer_success_container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 45%;
  @media #{$small-only} {
    width: 80%;
  }
  @media #{$iphone6plus-landscape} {
    top: 30%;
  }
  margin: 0 10%;
}

.offer_success_title {
  font-style: normal;
  font-size: 30px;
  @media #{$small-only} {
    font-size: 40px;
  }
}

.offer_success_message_container {
  line-height: 25px;
  padding: 10px 0;
  @media #{$small-only} {
    text-transform: uppercase;
  }
}

.offer_shop_now {
  text-decoration: underline;
  text-transform: uppercase;
}
