.basic-tout:not(.content-block) {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;

  // Layout variation where image goes behind copy
  &--v2 {
    min-height: 300px;
    height: 100%;
    overflow: hidden;
    &.basic-tout--flexi-height {
      align-items: center;
      display: flex;
      .basic-tout__text {
        position: relative;
        top: 0;
        #{$ldirection}: 0;
        transform: none;
        padding: 40px 30px;
      }
    }
    &.basic-tout--show-copy-on-hover {
      &:before {
        content: '';
        opacity: 0;
        position: absolute;
        background: rgba($color-white, 0.7);
        width: 100%;
        height: 100%;
        top: 0;
        #{$ldirection}: 0;
      }
      .no-touch & {
        position: relative;
        &:before {
          @include transition(opacity 0.2s ease-in-out);
          opacity: 0;
        }
        .basic-tout__text {
          @include transition(opacity 0.2s ease-in-out);
          opacity: 0;
        }
        &:hover {
          &:before,
          .basic-tout__text {
            opacity: 1;
          }
        }
      }
    }
    .basic-tout__text {
      position: absolute;
      z-index: 1;
      width: 100%;
    }
  }
  &--v3 {
    .basic-tout__img {
      opacity: 0.5;
    }
    .basic-tout__text {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      transform: none;
      padding: 40px 30px;
    }
  }
  &__img {
    width: 100%;
  }
  &__text {
    padding: 5px 10px 0;
    text-align: center;
    @media #{$medium-up} {
      padding: 5px 30px 0;
    }
    &--dark {
      color: $color-black;
      .button--secondary {
        background: $color-white;
      }
    }
    &--light {
      color: $color-white;
      .button--secondary {
        background: $color-navy;
        color: $color-white;
      }
    }
  }
  &__eyebrow {
    text-transform: uppercase;
    font-size: 12px;
    padding-top: 10px;
  }
  &__title {
    line-height: 24px;
    margin: 7px 0 0 0;
    text-transform: uppercase;
    font-family: $optimalight;
    font-size: 22px;
    @media #{$medium-up} {
      font-size: 28px;
    }
    > p {
      margin: 0;
      line-height: inherit;
      font-size: 25px;
    }
    @media #{$medium-up} {
      margin-top: 15px;
    }
  }
  &__copy {
    margin-top: 6px;
    p {
      font-family: $optimaregular;
      line-height: inherit;
      margin: 0;
    }
    &--headline {
      font-size: 19px;
      line-height: 1.075em;
    }
  }
  &__cta {
    margin-top: 10px;
  }
}
