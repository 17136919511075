.optanon-show-settings-popup-wrapper {
  display: inline-block;
  .optanon-show-settings-button {
    .optanon-show-settings-left,
    .optanon-show-settings-middle,
    .optanon-show-settings-right {
      background: none !important;
      border: 0 !important;
      height: auto !important;
      width: auto !important;
    }
    .optanon-show-settings-middle {
      text-decoration: underline;
      float: none;
      padding-top: 0;
      .optanon-show-settings {
        color: $color-navy !important;
        font: 15px/24px $akzidenz;
        letter-spacing: 0.05em;
      }
    }
    .optanon-show-settings-right {
      float: none;
    }
  }
}

.optanon-alert-box-wrapper {
  &.hide-accept-button {
    @media #{$medium-up} {
      width: 295px;
      #{$rdirection}: 10px;
    }
    @include swap_direction(padding, 20px 25px 20px 20px);
    height: auto;
    display: inline-block;
    #{$rdirection}: 0;
    border: 10px solid $color-black;
    width: 100%;
    line-height: 1;
    div,
    p {
      display: inline;
    }
    .optanon-alert-box-bottom-top {
      .optanon-alert-box-corner-close {
        position: static;
        .banner-close-button {
          position: absolute;
        }
      }
    }
    .optanon-alert-box-button-container {
      padding-#{$rdirection}: 0;
      margin-top: 10px;
      position: static;
      margin-#{$ldirection}: 0;
      .optanon-alert-box-button-middle {
        padding: 0;
        .optanon-toggle-display,
        .optanon-allow-all {
          @media #{$medium-up} {
            padding-#{$rdirection}: 5px;
          }
          font-family: $akzidenz;
          color: $color-navy !important;
          font-weight: normal;
          border-bottom: none;
          text-decoration: underline;
          font-size: 12px;
          &:before {
            display: none;
          }
        }
      }
      .optanon-alert-box-button {
        float: none;
      }
    }
    .optanon-alert-box-bg {
      .optanon-alert-box-body {
        margin: 0;
        padding: 0;
        .banner-content {
          font-size: 12px;
          line-height: 1;
          color: $color-dark-gray !important;
          font-family: $akzidenz;
        }
      }
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFlat {
      #onetrust-group-container {
        width: 100%;
        #onetrust-policy {
          #onetrust-policy-text {
            margin-bottom: 0;
            margin-top: 10px;
          }
        }
      }
      #onetrust-button-group-parent {
        width: 95%;
        text-align: #{$rdirection};
        #onetrust-button-group {
          button {
            background: transparent;
            border: none;
            text-decoration: underline;
            width: auto;
          }
        }
      }
      #onetrust-close-btn-container {
        display: block;
        .onetrust-close-btn-handler {
          top: auto;
          #{$ldirection}: 82%;
          bottom: 18px;
          @media #{$medium-up} {
            #{$ldirection}: 96%;
          }
          &:hover {
            color: $color-white;
          }
        }
      }
      #onetrust-close-btn-container-mobile {
        display: none !important;
      }
    }
  }
}

.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      white-space: nowrap !important;
      &:after {
        opacity: inherit;
        transform: inherit;
        background-image: none;
        top: 1px;
      }
      &:before {
        display: none;
      }
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    font-size: 15px !important;
    color: $color-navy !important;
    text-decoration: underline;
    letter-spacing: 0.05em;
  }
}

#onetrust-pc-sdk {
  &.ot-sdk-container {
    .pc-header {
      .pc-logo-container {
        .pc-logo {
          width: auto;
          height: auto;
        }
      }
      .pc-title-container {
        width: 100%;
        @media #{$medium-up} {
          width: calc(100% - 190px);
        }
        .pc-close-button {
          &:focus {
            outline: 0;
          }
        }
        #pc-title {
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
    .description-container {
      .checkbox {
        label {
          &:after {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
    .ot-checkbox {
      label {
        &:after {
          background-image: none;
          border-top: none;
          border-right: none;
        }
      }
    }
  }
}

#optanon {
  .vendor-header-container {
    #optanon-popup-more-info-bar {
      width: 36%;
      @media #{$medium-up} {
        width: 30%;
      }
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFloatingRoundedCorner {
      @include swap_direction(padding, 25px 24px 25px 22px);
      border: 10px solid $color-black;
      width: 100%;
      bottom: 0 !important;
      font-size: 13px;
      #{$rdirection}: 0;
      @media #{$medium-up} {
        width: 295px;
        #{$rdirection}: 1rem;
      }
      p {
        display: inline !important;
        font-size: 13px;
      }
      #onetrust-group-container {
        margin-#{$ldirection}: 0;
        float: none;
        #onetrust-policy {
          margin-top: 0;
          .banner-header {
            margin: 0;
          }
          #onetrust-policy-text {
            padding: 0;
            line-height: normal;
            color: $color-dark-gray;
            float: none;
          }
          #onetrust-close-btn-container {
            margin-#{$rdirection}: -5%;
            margin-top: -5%;
          }
        }
      }
      #onetrust-button-group-parent {
        padding: 0;
        float: none;
        #onetrust-pc-btn-handler {
          padding: 0;
          margin: 0;
          width: auto;
          color: $color-navy;
          letter-spacing: normal;
          font-size: 13px;
          line-height: normal;
        }
      }
    }
    #onetrust-button-group {
      display: inline;
    }
  }
}
