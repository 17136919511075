$max-mobile-width: 767px;
$min-desktop-width: 768px;
$desktop-break: 920px;
$desktop-portrait: 1023px;
$desktop-landscape: 1024px;
$nav-container-height: 110;
$header-height: 55;
$custom-scrollbar-thumb: #ababab;
$custom-scrollbar-track: #e2e2e2;
$OptimaDisplayLight: 'OptimaDisplayLight', 'OptimaDisplayBook', 'sans-serif', 'Tahoma', 'arial';
$AkzidenzGrotesk: 'AkzidenzGrotesk W1G', 'sans-serif', 'Tahoma', 'arial';
$HeaderTextColor: #040a2b;

.get-the-look {
  padding-top: 30px;
  margin: auto;
  max-width: 1366px;
  width: 100%;
  position: relative;
  overflow: hidden;
  font-size: 100%;
  &__header {
    font-size: 63px;
    letter-spacing: -0.06em;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    color: $HeaderTextColor;
    margin-bottom: 1.3%;
    margin-top: 0%;
    font-family: $OptimaDisplayLight;
  }
  .ytPlayer {
    .ytPlayer__img {
      &.js-ytPlayer__img-close {
        background-image: url(/media/boutiques/common/video_close_x_white.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        vertical-align: inherit;
        z-index: 996;
        height: 6.2%;
        top: 0;
      }
    }
  }
  &-panel {
    .lookmod {
      &__slide {
        width: 100%;
        font-family: $OptimaDisplayLight;
        display: none;
        &--active {
          display: block;
        }
        &--inner {
          width: 100%;
          vertical-align: top;
          font-size: 0;
        }
        &-imgcontainer {
          width: 49%;
          display: inline-block;
          vertical-align: top;
          position: relative;
        }
        &-videocontent {
          position: absolute;
          left: 3.5%;
          bottom: 2.5%;
          width: 13.448%;
          height: 11.675%;
          cursor: pointer;
          @media screen and (max-width: $max-mobile-width) {
            width: 70px;
            height: 70px;
          }
          .js-ytPlayer__icon {
            background-image: url(/media/export/cms_2.0/styleguide_templates/common/video_circle_light.svg);
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
          }
        }
        &-img {
          width: 100%;
        }
        &-btm {
          width: 49%;
          display: inline-block;
          vertical-align: top;
          padding: 0.6% 0 0 2.8%;
          @media screen and (max-width: $max-mobile-width) {
            padding: 3.6% 0 0 4.1%;
          }
        }
        &-btminfo {
          margin: 0 0 0 0;
          height: 530px;
          width: 96.5%;
          overflow-y: scroll;
          overflow-x: hidden;
          font-size: 16px;
          line-height: 1.5;
          padding-top: 1%;
          @media screen and (min-width: 1140px) and (max-width: 1315px) {
            height: 440px;
          }
          @media screen and (min-width: $desktop-landscape) and (max-width: 1139px) {
            height: 400px;
          }
          @media screen and (min-width: $min-desktop-width) and (max-width: $desktop-portrait) {
            height: 310px;
          }
          @media screen and (max-width: $max-mobile-width) {
            height: 315px;
          }
          /* FF */
          scrollbar-width: thin;
          scrollbar-color: $custom-scrollbar-thumb $custom-scrollbar-track;
          .ie & {
            scrollbar-face-color: $custom-scrollbar-thumb;
            scrollbar-track-color: $custom-scrollbar-track;
            scrollbar-arrow-color: $custom-scrollbar-track;
          }
          /* Webkit */
          &::-webkit-scrollbar {
            -webkit-appearance: none;
          }
          &::-webkit-scrollbar:vertical {
            width: 3px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $custom-scrollbar-thumb;
          }
          &::-webkit-scrollbar-track {
            background-color: $custom-scrollbar-track;
          }
        }
        &-content {
          padding-right: 10px;
          h5 {
            font-size: 16px;
            letter-spacing: 0.04em;
            margin-top: 10px;
            font-family: $AkzidenzGrotesk;
          }
          a {
            letter-spacing: 0.055em;
            font-weight: 700;
            font-size: 16px;
          }
          p {
            margin-left: 3.5%;
            margin-top: 0;
            margin-bottom: 4.5%;
            letter-spacing: 0.05em;
            font-size: 16px;
            line-height: 1.45;
          }
          @media screen and (min-width: $min-desktop-width) and (max-width: $desktop-portrait) {
            h5 {
              font-size: 14px;
            }
            a {
              font-size: 14px;
            }
            p {
              font-size: 12px;
            }
          }
          @media screen and (max-width: $max-mobile-width) {
            padding-right: 8px;
            h5 {
              font-size: 14px;
              margin-top: 11px;
              line-height: 1;
              letter-spacing: 0.02em;
              padding-left: 1px;
            }
            a {
              letter-spacing: 0.04em;
              font-weight: 700;
              font-size: 12px;
            }
            p {
              font-size: 12px;
              margin-left: 6.2%;
              letter-spacing: 0.04em;
              line-height: 1.32;
              margin-bottom: 0.5%;
            }
          }
        }
      }
      &__header {
        &-btminfo {
          font-size: 40px;
          line-height: 1;
          letter-spacing: -0.02em;
          margin: 0;
          @media screen and (max-width: $max-mobile-width) {
            font-size: 31px;
            letter-spacing: -0.03em;
          }
        }
      }
      &__subheader {
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.041em;
        margin: 1.7% 0 1.7% 0%;
        font-family: $AkzidenzGrotesk;
        @media screen and (max-width: $max-mobile-width) {
          font-size: 12px;
          letter-spacing: 0.047em;
          padding-right: 18%;
          line-height: 1.2;
        }
      }
    }
    &--staying {
      .lookmod {
        &__slide {
          &-btminfo {
            padding-top: 2.2%;
          }
        }
        &__subheader {
          margin-left: 0.7%;
        }
      }
    }
  }
  &__navigationcontainer {
    position: absolute;
    width: 49%;
    bottom: 0;
    #{$rdirection}: 0;
    cursor: pointer;
    display: none;
    &--active {
      display: block;
    }
    &.slick-slider {
      margin-bottom: 0;
      .slick-prev {
        #{$ldirection}: 0;
        top: 46%;
        background-position-y: 72.95%;
        width: 34px;
        height: 48px;
        z-index: 99;
      }
      .slick-next {
        #{$rdirection}: 0;
        top: 46%;
        #{$ldirection}: auto;
        background-position-y: 71.13%;
        width: 34px;
        height: 48px;
        z-index: 99;
      }
      .slick-prev:before,
      .slick-next:before {
        display: none;
      }
    }
  }
  &__navigationthumb {
    cursor: pointer;
    width: 24.65%;
    float: #{$ldirection};
    opacity: 0.5;
    &--active {
      opacity: 1;
    }
    img {
      width: 100%;
    }
  }
  @media screen and (min-width: $min-desktop-width) and (max-width: $desktop-break) {
    width: 100%;
    height: auto;
    &__navigationcontainer {
      width: 50%;
      height: auto;
    }
    &__navigationthumb {
      width: 25%;
    }
  }
  @media screen and (max-width: $max-mobile-width) {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    &__header {
      height: $header-height * 1px;
      margin-bottom: 0;
    }
    &-panel {
      .lookmod {
        &__slide {
          &-imgcontainer {
            width: 100%;
          }
          &-btm {
            width: 100%;
          }
        }
      }
    }
    &__navigationcontainer {
      position: relative;
      width: 100%;
      height: $nav-container-height * 1px;
      top: 0;
    }
    &__navigationthumb {
      width: 25%;
    }
  }
}

[dir='rtl'] .get-the-look__navigationcontainer {
  left: 0;
  right: auto;
}

.get-the-look-panel--stayingin {
  .get-the-look-panel {
    top: 1px;
    .lookmod__slide {
      top: 3px;
      .lookmod__slide-btm {
        padding-left: 2.5%;
        .lookmod__subheader {
          margin-left: 0.7%;
          letter-spacing: 0.051em;
        }
        .lookmod__slide-btminfo {
          padding-top: 2.2%;
          .lookmod__slide-content {
            ol {
              li {
                text-indent: -1.2em;
                padding-left: 1.2em;
                p {
                  margin-left: 0.7%;
                  line-height: 1.5;
                  letter-spacing: 0.12em;
                }
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: $max-mobile-width) {
      .lookmod__slide {
        .lookmod__slide-btm {
          padding-left: 4.2%;
          .lookmod__header-btminfo {
            letter-spacing: -0.04em;
          }
          .lookmod__subheader {
            letter-spacing: 0.041em;
          }
          .lookmod__slide-btminfo {
            padding-left: 0.3%;
            .lookmod__slide-content {
              padding-right: 20px;
              ol {
                li {
                  margin-bottom: 13px;
                  p {
                    letter-spacing: 0.04em;
                    line-height: 0.4;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.get-the-look-carousel-container {
  .get-the-look-carousel {
    display: none;
    &.active {
      display: block;
    }
  }
}
