/// new styles for star ratings
.product-kit__product-stars,
.product_brief__stars,
.quickshop__stars {
  display: inline-block;
  position: relative;
  width: 72px;
  height: 10px;
  background: url('/media/images/background-images/background-image31.png');
  #{$aerin} & {
    background: url('/media/images/background-images/background-image32.png');
  }
  #{$renutriv} & {
    background: url('/media/images/background-images/background-image33.png');
  }
  .mpp-compare__reviews &,
  .formatter-beauty-feed & {
    background: url('/media/images/background-images/background-image34.png');
  }
}

.product-kit__star-rating,
.product_brief__stars-rating,
.quickshop__stars-rating {
  position: absolute;
  height: 10px;
  width: 100%;
  overflow: hidden;
  background: url('/media/images/background-images/background-image35.png');
  #{$aerin} & {
    background: url('/media/images/background-images/background-image36.png');
  }
  #{$renutriv} & {
    background: url('/media/images/background-images/background-image37.png');
  }
  .mpp-compare__reviews &,
  .formatter-beauty-feed & {
    background: url('/media/images/background-images/background-image38.png');
  }
}

.product_brief__reviews-count {
  font-size: 12px;
  letter-spacing: 0.02em;
  @media #{$medium-up} {
    font-size: 15px;
  }
}
