///
///  Colorbox User Style Overrides:
///
#colorbox:not(.sop--v2__success-popup):not(.overlay--auto-size) {
  .device-mobile & {
    &:not(.vto-ios-browser-error):not(.overlay--interstitial):not(.sop--v2__success-popup):not(.add_cart_response) {
      height: $colorbox-height;
      background: $color-white;
      position: fixed !important;
    }
    &:not(.offer-grid-overlay__content):not(.vto-ios-browser-error):not(.sop--v2__success-popup):not(.add_cart_response) {
      width: 100% !important;
      top: 0 !important;
      #{$ldirection}: 0 !important;
    }
    &.add_cart_response {
      &:not(.vto-ios-browser-error,
    .overlay--interstitial) {
        height: 140px !important;
        width: 330px !important;
        top: 220px !important;
        #{$ldirection}: 50% !important;
        transform: translateX(-50%);
        #cboxContent {
          display: flex;
        }
        #cboxLoadedContent {
          margin-top: 5px;
        }
        #cboxClose {
          #{$rdirection}: 10px;
          top: 10px;
          position: absolute;
          bottom: 0;
          width: 25px;
          height: 25px;
        }
      }
    }

    div {
      // height: auto !important;
      // width: auto !important;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      &#cboxWrapper,
      &#cboxContent {
        width: 100% !important;
      }
    }
  }
  &.add_cart_response {
    #cboxClose {
      background: url(/media/images/global/sprites-s38a9885fce.png) no-repeat;
      background-position: -5px -747px;
      top: 10px;
      #{$rdirection}: 15px;
      height: 18px;
      width: 18px;
    }
  }
}

#cboxOverlay {
  @include opacity(0.75);
  background: $color-navy;
  .device-mobile & {
    @include opacity(1);
  }
}

#cboxTopLeft,
#cboxTopRight,
#cboxBottomLeft,
#cboxBottomRight {
  display: none;
  width: 0;
  height: 0;
  background: none;
}

#cboxMiddleLeft,
#cboxMiddleRight,
#cboxTopCenter,
#cboxBottomCenter {
  display: none;
  width: 0;
  background: none;
}

#cboxContent {
  background: $color-white;
  @include swap_direction(padding, 25px);
  overflow: hidden;
}

#cboxLoadedContent {
  margin-bottom: 0;
  color: $color-navy;
  #{$aerin} & {
    color: $color-periwinkle-blue--aerin;
  }
}
// #cboxTitle{position:absolute; bottom:4px; #{$ldirection}:0; text-align:center; width:100%; color:#949494;}
// #cboxCurrent{position:absolute; bottom:4px; #{$ldirection}:58px; color:#949494;}
// #cboxSlideshow{position:absolute; bottom:4px; #{$rdirection}:30px; color:#0092ef;}
// #cboxPrevious{position:absolute; bottom:0; #{$ldirection}:0; background:url(images/controls.png) no-repeat -75px 0; width:25px; height:25px; text-indent:-9999px;}
// #cboxPrevious:hover{background-position:-75px -25px;}
// #cboxNext{position:absolute; bottom:0; #{$ldirection}:27px; background:url(images/controls.png) no-repeat -50px 0; width:25px; height:25px; text-indent:-9999px;}
// #cboxNext:hover{background-position:-50px -25px;}
// #cboxLoadingOverlay{background:url(images/loading_background.png) no-repeat center center;}
// #cboxLoadingGraphic{background:url(images/loading.gif) no-repeat center center;}
#cboxClose {
  @include squish-text;
  height: 28px;
  width: 28px;
  background: url(/media/images/global/sprites-s38a9885fce.png) no-repeat;
  background-size: auto auto;
  background-position: 0 -973px;
  position: absolute;
  top: 25px;
  #{$rdirection}: 25px;
  z-index: 100;
  @include swap_direction(border, none);
  -webkit-appearance: none;
}

#cboxClose:hover {
  background-size: auto auto;
  background-position: 0 -973px;
  height: 28px;
  width: 28px;
}

#cboxLoadingGraphic {
  @include ajaxSpinner($color-navy, 30px, 30px, 8px);
  background: none;
  position: absolute;
  #{$ldirection}: 50%;
  top: 50%;
  @include transform(translateX(-50%) translateY(-50%));
}

#cboxLoadingOverlay {
  background: none;
}

// hide body scroll bars when colorbox is on
.colorbox-on {
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}

// Welcome15 offer overlay
// The following selectors are necessary because _home_formatter_mobile is crap
.device-mobile {
  #colorbox {
    &.welcome15-overlay {
      top: 52px !important;
      div#cboxLoadedContent {
        width: auto !important;
      }
      // line up the close button with the cart icon on mobile
      #cboxClose,
      #cboxClose:hover {
        @include squish-text;
        background-size: auto auto;
        background-position: 0 -973px;
        height: 28px;
        width: 28px;
        @include form-help;
        position: absolute;
        z-index: 100;
        @include swap_direction(border, none);
        -webkit-appearance: none;

        // override some stuff from the mixins above
        top: 30px;
        #{$rdirection}: 12px;
        background-image: none;
        cursor: pointer;
        width: auto;
        height: auto;
        color: inherit;
        font-size: 12px;
        font-weight: bold;
        line-height: normal;
        text-transform: uppercase;
        text-decoration: underline;
        text-indent: 0;
        bottom: auto;
        @include swap_direction(padding, 0);
      }
    }
  }
}

.welcome15-overlay {
  #cboxContent {
    @include swap_direction(padding, 20px 20px 10px);
    @media #{$medium-up} {
      @include swap_direction(padding, 50px 80px);
    }
  }
}

#cboxClose--alt,
#cboxClose--alt:hover {
  // @extend #cboxClose;

  // Normally I'd just extend #cboxClose here (see above), but there's a global style in
  // _home_formatter_mobile preventing me from doing that. So instead I'll just copy all
  // of the lines from the original selector.
  @include squish-text;
  background-size: auto auto;
  background-position: 0 -973px;
  height: 28px;
  width: 28px;
  @include form-help;
  position: absolute;
  z-index: 100;
  @include swap_direction(border, none);
  -webkit-appearance: none;

  // override some stuff from the mixins above
  top: 30px;
  #{$rdirection}: 12px;
  background-image: none;
  cursor: pointer;
  width: auto;
  height: auto;
  color: inherit;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: underline;
  @media #{$medium-up} {
    top: 25px;
    #{$rdirection}: 50px;
    #{$ldirection}: auto;
  }
}

.colorbox {
  &__wishlist-confirm {
    #cboxContent {
      min-height: 165px;
      #cboxLoadedContent {
        height: 165px !important;
      }
    }
  }
}
