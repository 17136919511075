///
// Branding
///
.page-branding {
  float: $ldirection;
  margin-#{$ldirection}: 40px; // mobile-first
  height: 52px;

  // make sure padding top adjusts with image height
  //padding-top: ($header-item-height - sprites-sprite-height("elc-logo"))/2;
  padding-top: 8.5px;

  // after phone
  @media #{$medium-up} {
    margin-#{$ldirection}: $menu-toggle-width + $header-item-padding;
    #{$aerin} & {
      margin-#{$ldirection}: $menu-toggle-width + $header-item-padding;
    }
    // handle search positioning
    #{$search} & {
      margin-#{$ldirection}: $header-item-padding;
    }
  }

  // desktop
  @media #{$xlarge-up} {
    margin-#{$ldirection}: $header-item-padding;
    #{$aerin} & {
      margin-#{$ldirection}: $header-item-padding;
    }

    // for close button in alt state
    // #{$alt} & {
    //   margin-#{$ldirection}: $menu-toggle-width + $header-item-padding;
    // }
  }

  // minimum state for checkout
  #{$min-nav} &,
  .elc-gnav-minimal & {
    margin-#{$ldirection}: $mobile-outer-gutter;
  }
  &__logo,
  &__logo-svg-wrapper {
    background: url($svg-path + 'logo-estee.svg') no-repeat;
    display: block;
    width: 186px;
    height: 23px;
    position: absolute;
    #{$ldirection}: 50%;
    margin-top: 5px;
    margin-#{$ldirection}: -93px;
    @media #{$medium-up} {
      float: #{$rdirection};
      margin-#{$ldirection}: -2px;
      position: relative;
      width: 236px;
      height: 31px;
      #{$ldirection}: 0;
    }
    @media (min-width: $landscape-up) {
      position: absolute;
      margin-#{$ldirection}: 0;
    }
  }
  &__logo-svg-wrapper {
    background: none transparent;
    margin-top: 4px;
  }
  &__logo-svg {
    width: 186px;
    height: 23px;
    position: relative;
    top: 1px;
    @media #{$medium-up} {
      width: 236px;
      height: 31px;
      top: unset;
    }
  }
}

.page-header .page-branding__trustmark,
.is-sticky .page-branding .page-branding__trustmark,
.gnav-active .is-alternate .page-branding .page-branding__trustmark {
  position: relative;
}
///
// Logo
//
// styleguide pg.82
///

%mobile-logo--estee {
  background: url($svg-path + 'logo-estee.svg') no-repeat;
}

%mobile-logo--aerin {
  background: url($svg-path + 'logo-aerin.svg') no-repeat;
}

%mobile-logo--renutriv {
  background: url($svg-path + 'logo-renutriv.svg') no-repeat;
}
