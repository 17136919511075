.promo-unit {
  overflow: hidden;
  @include swap_direction(padding, 35px 8.411215%);
  &#{$alt} {
    background: $color-navy;
    color: $color-white;
  }
  @media #{$medium-up} {
    @include swap_direction(padding, 0);
  }
}

.promo-unit__img > img {
  @include swap_direction(margin, 15px auto 0);
  display: block;
  @media #{$medium-up} {
    @include swap_direction(margin, 0);
    display: inline;
  }
}

.promo-unit__img--on-top {
  @media #{$medium-up} {
    float: $ldirection;
    margin-#{$rdirection}: 17px;
  }
}

.promo-unit__headline {
  @include headline--quaternary--plain;
  text-align: center;
  line-height: 36px;
  margin-bottom: 8px;
  #{$alt} & {
    color: $color-white;
  }
  @media #{$medium-up} {
    text-align: $ldirection;
  }
}

.promo-unit__subheader {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 16px;
  #{$alt} & {
    color: $color-white;
  }
  @media #{$medium-up} {
    text-align: $ldirection;
  }
}

.promo-unit__link {
  @include link--bold;
  text-align: center;
  display: block;
  margin-top: 6px;
  #{$alt} & {
    color: $color-periwinkle-blue;
  }
  @media #{$medium-up} {
    text-align: $ldirection;
    display: inline;
  }
}
