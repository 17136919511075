#loyalty__panel__transactions {
  @extend %loyalty-panel;
  .loyalty__panel__transactions__title {
    @extend %loyalty-panel-title;
  }
  table {
    width: 100%;
    th,
    td {
      @include swap_direction(padding, 10px);
    }
    th {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  td {
    @include swap_direction(border, 1px solid $color-light-gray);
  }
  .loyalty__panel__transactions__list__column-plus,
  .loyalty__panel__transactions__list__column-minus {
    @media #{$medium-up} {
      display: none;
    }
  }
  loyalty__panel__transactions__list__column-activity--level {
  }
  .loyalty__panel__transactions__list__column-activity {
    display: none;
    @media #{$medium-up} {
      display: table-cell;
    }
  }
  @media #{$medium-up} {
    .mobile {
      display: none;
    }
    .loyalty__panel__transactions__list__column-points,
    .loyalty__panel__transactions__list__column-earned,
    .loyalty__panel__transactions__list__column-spent,
    .loyalty__panel__transactions__list__column-total {
      display: table-cell;
    }
  }
  .loyalty__panel__transaction__list__paginate__separate:before {
    content: '/';
  }
}
