$ab-iphone6plus-only: '#{$screen} and (min-width:#{lower-bound($iphone6plus-range)}) and (max-width:#{upper-bound($iphone6plus-range)})' !default;

$ab--color--gray--lighter: #c7c7c7;
$ab--color--gray--black: #1a1a1a;
$ab--color--white: #ffffff;

@mixin appt_booking {
  @media #{$small-only} {
    abbr,
    abbr[title] {
      @include swap_direction(border, none);
      cursor: default;
    }
  }

  // Collection formatter - main container
  .appt-book {
    position: relative;
    @include swap_direction(padding, 0);
    color: $color-navy;
    @include swap_direction(margin, 0 auto 70px);
    max-width: $max-width;
    // text-align: center;

    // Back To Link On Mobile
    @media #{$small-only} {
      a.back-to-link,
      a.back-to-link:hover {
        display: block;
        width: auto;
        background: url('/media/export/cms/appointment_booking/common/back_to_link_arrow.gif') no-repeat 0 3px;
        font-family: $akzidenz;
        font-size: 12px;
        color: $color-navy;
        text-decoration: none;
        @include swap_direction(border, none);
        @include swap_direction(margin, 5px 22px);
        padding-#{$ldirection}: 16px;
      }
    }
    &-sections-container {
      position: relative;
      display: block;
      .counters & {
        width: 50%;
        display: inline-block;
        padding-#{$ldirection}: 30px;
        @media #{$large-down} {
          padding-#{$ldirection}: 1.5%;
        }
      }
      @media #{$small-only} {
        display: block;
        width: 100%;
        @include swap_direction(padding, 0 10px);
        text-align: $ldirection;
        .services & {
          @include swap_direction(padding, 0);
        }
        .counters & {
          @include swap_direction(padding, 0);
          width: auto;
        }
      }
    }
    &-section {
      position: relative;
      width: 100%;
      &-content {
        position: relative;
        width: 100%;
        margin-bottom: 40px;
        @media #{$small-only} {
          margin-bottom: 20px;
          padding-top: 5px;
        }
      }
    }
    .button.disabled,
    input[type='submit'].disabled,
    input[type='button'].disabled {
      color: $color-navy;
      background-color: $color-medium-gray;
      cursor: default;
    }
    .basic-responsive {
      position: relative;
      display: block;
      font-size: 18px;
      @media #{$small-only} {
        font-size: 16px;
      }
      a {
        margin-top: 2px;
        @media #{$large-up} {
          margin-top: 0;
        }
      }
    }
    p {
      @include swap_direction(margin, 18px auto 11px);
      letter-spacing: 0.105em;
      @media #{$small-only} {
        margin-top: 12px;
        font-size: 16px;
        line-height: 1.2em;
        letter-spacing: 1px;
      }
    }
    &-link {
      text-transform: uppercase;
      font-family: $akzidenz;
      font-weight: bold;
      text-decoration: none;
      border-bottom: 1px solid $color-navy;
      font-size: 12px;
      @media #{$small-only} {
        font-size: 12px;
        padding-bottom: 0;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .touch & {
      select {
        background-position: 97% 46%;
        padding-#{$rdirection}: 35px;
      }
    }

    // Appointment Booking page header
    &-page-header {
      position: relative;
      color: $color-navy;
      text-decoration: none;
      @include swap_direction(margin, 0 auto);
      text-align: center;
      display: block;
      .appt-book.counters & {
        display: inline-block;
        width: 49%;
        @include swap_direction(margin, 0);
        @media #{$large-down} {
          /* @include swap_direction(margin, 0 0 56% 1.3%);
        width: 48%; */
        }
        @media #{$small-only} {
          @include swap_direction(margin, 0);
          margin-top: 16.5%;
          position: absolute;
          width: 100%;
        }
      }
      &-bg {
        display: block;
        width: 100%;
        height: auto;
        display: none;
        .counters & {
          display: block;
        }
        &.bg-img-mobile {
          display: none;
        }
      }

      // Don't show the pc background image on mobile
      @media #{$small-only} {
        &-bg.bg-img-pc {
          display: block;
          width: 100%;
          height: auto;
          @include swap_direction(margin, 0 auto);
          max-width: 579px;
          max-height: 510px;
          display: none;
          .counters & {
            display: block;
          }
        }
        &-bg.bg-img-mobile {
          display: block;
        }
      }
      &-content {
        z-index: 1;
        .counters & {
          width: 50%;
        }
        @media #{$small-only} {
          width: auto;
          .counters & {
            width: auto;
          }
        }
        .inner {
          height: auto;
          @media #{$small-only} {
            position: relative;
            top: auto;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            @include swap_direction(margin, 17px);
          }
        }
        h2,
        h3,
        h4,
        p {
          text-align: center;
          margin-bottom: 0;
          line-height: 1.2;
          font-size: 20px;
          @media #{$medium-down} {
            font-size: 18px;
          }
          @media #{$small-only} {
            line-height: 1.05em;
            font-family: $akzidenz;
            text-align: $ldirection;
          }
        }
        h2 {
          font-size: 70px;
          color: $color-navy;
          letter-spacing: normal;
          font-family: $optimalight;
          .counters & {
            font-size: 135px;
          }
          @media #{$medium-down} {
            font-size: 32px;
          }
          @media #{$small-only} {
            font-size: 46px;
            text-align: center;
          }
        }
        h3 {
          font-size: 27px;
        }
        h4 {
          font-size: 14px;
          line-height: 2;
          font-family: $akzidenz;
          letter-spacing: 0.18em;
          @media #{$medium-down} {
            font-size: 20px;
          }
          @media #{$small-only} {
            font-size: 14px;
            padding-top: 12px;
            line-height: 1.5;
            letter-spacing: 0;
            font-weight: bold;
            text-align: center;
            font-family: $akzidenz;
          }
          &.mobile {
            display: none;
            @media #{$small-only} {
              display: block;
            }
          }
        }
        p {
          @media #{$small-only} {
            font-family: $akzidenz;
            font-size: 16px;
            text-align: center;
          }
        }
        .multi {
          white-space: nowrap;
        }
        .multi--mobile-inline {
          display: inline-block;
          @media #{$large-up} {
            display: block;
          }
        }
        &__dots {
          list-style-type: none;
          text-align: center;
          padding-bottom: 6px;
          display: none;
          li {
            display: inline-block;
            font-size: 40px;
            color: $color-medium-gray;
            @include swap_direction(margin, 0 4px);
            &.active {
              color: $color-navy;
            }
          }
        }
        &__title {
        }
        &__subhead {
        }
      }
    }
    @media #{$small-only} {
      &.counters &-page-header,
      &.services &-page-header,
      &.appointments &-page-header,
      &.confirmation &-page-header {
        text-align: center;
        &-content {
          display: block;
          position: relative;
          @include swap_direction(margin, 20px 0px 20px 0px);
          width: auto;
          height: auto;
          top: auto;
          bottom: auto;
          .inner {
            position: relative;
            width: auto;
            height: auto;
            top: auto;
            #{$ldirection}: auto;
            @include swap_direction(margin, 0 auto);
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
          }
        }
        .section-header {
          &__inner {
            letter-spacing: 0.05em;
            font-size: 13px;
            font-weight: bold;
          }
        }
      }
    }
    &-content-header {
      @include swap_direction(margin, 20px auto);
      margin-top: 0;
      .service-select & {
        @media #{$small-only} {
          margin-bottom: 0;
          @include swap_direction(padding, 0 20px);
        }
      }
      p {
        @media #{$large-up} {
          font-size: 18px;
        }
      }
      .appt-book & .book-now-bar {
        padding-top: 17px;
        display: block;
        @media #{$small-only} {
          display: none;
        }
      }
    }
    .book-now-bar {
      @include clearfix;
      letter-spacing: 0.23em;
      font-family: $akzidenz;
      font-weight: bold;
      max-width: 250px;
      @include swap_direction(margin, 0 auto);
      .minutes {
        background-color: $color-light-gray-black;
        width: 41%;
        @include swap_direction(padding, 12px 0 13px);
        float: $ldirection;
        font-size: 12px;
        display: inline-block;
        text-align: center;
        color: $color-navy;
      }
      .button {
        width: 59%;
        float: $ldirection;
        color: $color-white;
        display: inline-block;
        letter-spacing: 0.23em;
        padding-bottom: 13px;
        font-size: 12px;
        cursor: pointer;
        background-color: $color-navy;
        &.disabled {
          background-color: $color-medium-gray;
          color: $color-navy;
          cursor: default;
        }
      }
      @media #{$small-only} {
        width: 100%;
        font-size: 12px;
        max-width: none;
        .minutes {
          width: 28%;
          height: 57px;
          line-height: 45px;
        }
        .button {
          font-size: 12px;
          width: 72%;
          height: 57px;
          padding-top: 9px;
          .sub-text {
            font-size: 11px;
            font-family: $akzidenz;
            font-style: italic;
            text-transform: none;
            letter-spacing: 0.03em;
          }
        }
      }
    }
    &-location-selector {
      font-size: 18px;
      @include swap_direction(padding, 23px 0 16px);
      position: relative;
      @media #{$large-down} {
        @include swap_direction(padding, 4% 0 4%);
      }
      @media #{$small-only} {
        @include swap_direction(padding, 0 0 20px 0);
        font-size: 16px;
        margin-top: 50%;
        margin-#{$ldirection}: 20px;
        margin-#{$rdirection}: 20px;
      }
      &-copy {
        display: block;
        color: $color-navy;
        font-size: 14px;
        font-weight: bold;
        @media #{$small-only} {
          font-size: 14px;
          text-align: center;
          font-weight: bold;
          font-family: $akzidenz;
        }
      }
      .form-container {
        position: relative;
        display: inline-block;
        overflow: hidden;
        margin-top: 5px;
        width: 100%;
        @media #{$medium-down} {
          display: block;
          text-align: center;
        }
        @media #{$small-only} {
          width: 100%;
          margin-top: 15px;
        }
      }
      .city-select,
      .location-select,
      .location-submit {
        display: block;
        float: $ldirection;
        @media #{$small-only} {
          float: none;
          @include swap_direction(margin, 0 auto);
        }
      }
      .city-select,
      .location-select {
        max-width: 339px;
        width: 60%;
        line-height: 40px;
        height: 40px;
        margin-#{$rdirection}: 2%;
        @media #{$large-down} {
          margin-bottom: 0;
        }
        @media #{$medium-down} {
          font-size: 13px;
          margin-bottom: 3%;
          max-width: 96%;
          width: 96%;
          margin-#{$rdirection}: 0;
        }
        @media #{$small-only} {
          float: none;
          @include swap_direction(margin, 0 auto 3%);
        }
      }
      .city-select {
        margin-bottom: 2%;
      }
      .location-submit,
      .virtual__appointment--button {
        height: auto;
        line-height: 1;
        @include swap_direction(padding, 13px 11px);
        margin-#{$ldirection}: 0;
        @include swap_direction(border, none);
        color: $color-white;
        background-color: $color-navy;
        max-width: 172px;
        /* min-width: 170px; */
        width: 30%;
        font-size: 14px;
        box-sizing: content-box;
        &.disabled {
          color: $color-navy;
        }
        @media #{$large-down} {
          font-size: 11px;
          @include swap_direction(padding, 14px 11px);
          /* margin-#{$ldirection}: 2%; */
        }
        @media #{$medium-down} {
          width: 90%;
          max-width: 90%;
          font-size: 13px;
          @include swap_direction(margin, 0 auto);
        }
      }
      .virtual__appointment--button {
        width: 50%;
        max-width: 317px;
        @media #{$medium-down} {
          width: 90%;
          max-width: 90%;
          font-size: 13px;
          @include swap_direction(margin, 0 auto);
        }
      }
    }
    .location-select-section {
      margin-bottom: -15px;
      &-content {
        h2,
        &__title {
          font-size: 135px;
          line-height: 130px;
          letter-spacing: normal;
          color: $color-navy;
          @media #{$xlarge-only} {
            font-size: 110px;
            line-height: 95px;
          }
          @media #{$large-down} {
            font-size: 65px;
            line-height: 60px;
          }
          @media #{$medium-down} {
            font-size: 55px;
            line-height: 45px;
          }
          @media #{$small-only} {
            font-size: 35px;
            line-height: normal;
            font-weight: normal;
            text-align: center;
            letter-spacing: -0.03em;
            br {
              display: none;
            }
          }
        }
        h4,
        &__subhead {
          font-size: 14px;
          line-height: 2;
          font-family: $akzidenz;
          letter-spacing: 0.18em;
          margin-top: 20px;
          @media #{$large-down} {
            letter-spacing: 0.08em;
            line-height: 1.5;
          }
          @media #{$medium-down} {
            margin-top: 2%;
          }
          @media #{$small-only} {
            font-size: 13px;
            padding-top: 26%;
            font-family: $akzidenz;
            width: 69%;
            color: #ffffff;
            padding-#{$ldirection}: 10%;
            margin-top: 5%;
          }
          @media #{$ab-iphone6plus-only} {
            margin-top: 5%;
            font-size: 15px;
            min-height: 250px;
            width: 60%;
            margin-#{$ldirection}: 5%;
          }
        }
        &__description {
          font-size: 16px;
          margin-top: 10px;
          @media #{$large-down} {
            font-size: 12px;
            letter-spacing: 0;
          }
          @media #{$small-only} {
            display: inline-block;
            font-size: 13px;
            margin: 31% 20% 3%;
          }
        }
        &-virtual__description {
          @media #{$small-only} {
            font-size: 16px;
            width: 100%;
            margin-top: 5%;
            text-align: center;
          }
        }
        &-separator {
          margin: 15px 0;
          @media #{$small-only} {
            text-align: center;
          }
        }
        &__msg {
          font-size: 12px;
          margin-top: 10px;
          color: $color-navy;
          .appt-book-link {
            font-size: 12px;
          }
          @media #{$large-down} {
            margin-top: 0;
          }
          @media #{$small-only} {
            font-size: 13px;
            @include swap_direction(margin, 10px 20% 0 20%);
            text-align: center;
            color: $color-navy;
            .appt-book-link {
              font-size: 13px;
              font-weight: normal;
            }
          }
        }
      }
    }
    .service-select {
      width: auto;
      @include swap_direction(margin, 0 auto 23px);
      overflow: hidden;
      @media #{$small-only} {
        margin-bottom: -16px;
      }
      .section-header {
        &__inner {
          @media #{$large-up} {
            font-size: 12px;
          }
        }
      }
      .services-container {
        position: relative;
        width: 100%;
        max-width: 984px;
        overflow: hidden;
        @include swap_direction(margin, 40px auto);
        padding-top: 0;
        @media #{$large-down} {
          @include swap_direction(margin, 40px 10px);
        }
        @media #{$small-only} {
          background: none;
          @include swap_direction(border, none);
          @include swap_direction(margin, 0);
          margin-bottom: 0;
          margin-top: 25px;
          @include swap_direction(padding, 0);
        }
      }
      @media #{$small-only} {
        .service:first-child {
          border-top: 1px solid $color-light-navy-alt;
        }
      }
      .service {
        position: relative;
        display: block;
        width: 50%;
        margin-bottom: 60px;
        overflow: hidden;
        float: $ldirection;
        min-height: 246px;
        @media #{$medium-down} {
          min-height: 192px;
        }
        @media #{$small-only} {
          float: none;
          width: 100%;
          min-height: 0;
          @include swap_direction(padding, 1.9% 1.7%);
          @include swap_direction(margin, 10px 0px 10px 0px);
          border-top: 1px solid #282d51;
          &.expanded .service-head .icon {
            background: url('/media/export/cms/appointment_booking/icons/icon-minus.png') no-repeat;
            background-size: contain;
          }
          .service-head {
            position: relative;
            width: 100%;
            height: 38px;
            overflow: hidden;
            cursor: pointer;
            .service-title {
              display: block;
              float: $ldirection;
              width: 85%;
              font-size: 12px;
              font-family: $akzidenz;
              font-weight: bold;
              text-transform: uppercase;
              @include swap_direction(padding, 0);
              margin-top: 6px;
              @media #{$small-only} {
                text-align: center;
                width: 100%;
              }
            }
            .icon {
              background: url('/media/export/cms/appointment_booking/icons/icon-plus.png') no-repeat;
              background-size: contain;
              height: 25px;
              margin-#{$rdirection}: 20px;
              margin-top: 5px;
              position: absolute;
              #{$rdirection}: 0;
              width: 25px;
            }
          }
        }
        .service-image {
          display: block;
          float: $ldirection;
          width: 40.7%;
          @media #{$small-only} {
            width: 45.35%;
          }
        }
        .service-details {
          display: block;
          float: $ldirection;
          width: 51%;
          text-align: $ldirection;
          overflow: hidden;
          position: absolute;
          #{$ldirection}: 45%;
          bottom: 0;
          @media #{$small-only} {
            width: 50.1%;
            margin-#{$ldirection}: 4.5%;
            position: relative;
            #{$ldirection}: 0%;
            margin-top: 10%;
          }
          h3 {
            @include swap_direction(margin, 0);
            line-height: 1.1;
            &.service-title {
              font-weight: bold;
              margin-top: -5px;
              font-size: 33px;
              line-height: 1.5em;
              @media #{$medium-down} {
                font-size: 29px;
                line-height: 38px;
              }
              @media #{$small-only} {
                font-size: 12px;
                margin-top: -2px;
                letter-spacing: 0.19em;
                line-height: 1.2em;
              }
            }
          }
          .lesson_description {
            font-family: $roboto-condensed;
            font-size: 16px;
            @media #{$medium-down} {
              font-size: 12px;
            }
            p {
              margin-top: 0;
              margin-bottom: 0;
              line-height: 1.72em;
              font-size: 16px;
              letter-spacing: normal;
              @media #{$small-only} {
                font-size: 12px;
                line-height: 1.3em;
                letter-spacing: 0.06em;
              }
            }
            @media #{$small-only} {
              font-size: 12px;
            }
            .selection-time {
              display: inline-block;
            }
          }
        }
        .selection-bar {
          display: block;
          bottom: 0;
          clear: both;
          padding-top: 19px;
          overflow: hidden;
          letter-spacing: 0.23em;
          @media #{$medium-down} {
            padding-top: 10px;
          }
          @media #{$small-only} {
            position: relative;
            #{$ldirection}: auto;
            bottom: auto;
            width: 50%;
            float: $rdirection;
            clear: inherit;
            padding-top: 19px;
          }
          > div {
            display: block;
            float: $ldirection;
            line-height: 1;
            text-transform: uppercase;
            text-align: center;
            @include swap_direction(padding, 10px 0);
            @include swap_direction(margin, 0);
            font-family: $akzidenz;
            font-weight: bold;
            @media #{$small-only} {
              font-size: 10px;
              letter-spacing: 0.2em;
              @include swap_direction(padding, 6px 0);
            }
          }
          .selection-time {
            display: none;
            width: 41%;
            @include swap_direction(border, 1px solid $color-light-navy-alt);
            background-color: $color-light-navy-alt;
            text-transform: uppercase;
            @media #{$small-only} {
              width: 50%;
            }
          }
          .selection-selector {
            @include swap_direction(border, 1px solid $color-navy);
            background-color: $color-white;
            width: 133px;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 12px;
            .icon {
              display: none;
              position: relative;
              top: -2px;
              margin-#{$rdirection}: 2px;
              width: 17px;
              height: 12px;
              background: url('/media/export/cms/appointment_booking/services/service_selected_check.gif') no-repeat;
              @media #{$small-only} {
                display: none;
              }
            }
            @media #{$small-only} {
              width: 100%;
              @include swap_direction(padding, 12px 0px);
            }
          }
          .selection-selector.selected {
            background-color: $color-navy;
            color: $color-white;
            filter: alpha(opacity=50);
            opacity: 0.5;
            .icon {
              @media #{$small-only} {
                display: none;
              }
            }
          }
        }
      }
    }
    .appointment-select {
      width: auto;
      @include swap_direction(margin, 0 auto);
      overflow: hidden;
      color: $color-navy;
      .appointments-container {
        .booking-step {
          margin-bottom: 60px;
          text-align: $ldirection;
          &.step2 {
            @include swap_direction(padding, 0 3.6%);
            margin-bottom: 60px;
          }
          &.step3 {
            @include swap_direction(padding, 0 3.6%);
            @media #{$small-only} {
              margin-bottom: 30px;
            }
          }
          .change-services {
            font-size: 24px;
            @include swap_direction(margin, 0 3.6%);
            @media #{$small-only} {
              margin-bottom: 25px;
            }
          }
        }
        .step-header {
          border-bottom: 1px solid $color-medium-gray;
          color: $color-navy;
          font-family: $optimalight;
          font-size: 36px;
          letter-spacing: -0.035em;
          @include swap_direction(margin, 0);
          @include swap_direction(padding, 12px 0 10px);
          text-align: $ldirection;
          @media #{$small-only} {
            letter-spacing: 0.05em;
            border-bottom: 0px;
            @include swap_direction(padding, 0);
            font-weight: bold;
            font-size: 13px;
          }
        }
        .step1 {
          .step-main-header {
            @include swap_direction(margin, 0 3.6%);
          }
          .step-header {
            @include swap_direction(margin, 0 3.6%);
            @media #{$small-only} {
              @include swap_direction(margin, 0 0 0 10px);
            }
          }
          .services {
            h4 {
              display: none;
            }
          }
        }
        .step2 {
          .step-header {
            @media #{$small-only} {
              display: none;
            }
          }
        }
        .services {
          width: 100%;
          overflow: hidden;
          @include swap_direction(padding, 0 1.8%);
          @media #{$small-only} {
            @include swap_direction(padding, 0 10px);
          }
          h4 {
            font-family: $akzidenz;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 16px;
            padding-bottom: 18px;
            letter-spacing: 0.2em;
            @media #{$small-only} {
              letter-spacing: 1px;
            }
          }
        }
        .service {
          display: inline-block;
          width: 50%;
          @include swap_direction(padding, 2.5% 1.8% 0);
          overflow: hidden;
          font-size: 33px;
          font-family: $optimalight;
          @media #{$small-only} {
            width: auto;
            float: none;
            @include swap_direction(padding, 1.8% 0);
          }
          h3 {
            @media #{$small-only} {
              font-size: 14px;
            }
          }
          .service-head {
            font-size: 36px;
            font-weight: normal;
            display: inline-block;
            letter-spacing: -0.035em;
            line-height: 1.1;
            @include swap_direction(margin, 0);
            @media #{$xlarge-only} {
              font-size: 20px;
            }
            @media #{$large-down} {
              font-size: 16px;
            }
            @media #{$small-only} {
              font-size: 26px;
              text-transform: none;
            }
          }
          .selection-service {
            display: inline-block;
            width: auto;
          }
          .selection-time {
            display: inline-block;
            font-size: 16px;
            font-weight: bold;
            text-align: $rdirection;
            letter-spacing: 0.02em;
            @media #{$large-down} {
              font-size: 12px;
            }
            @media #{$small-only} {
              font-size: 12px;
            }
          }
          .selection-bar {
            display: block;
            clear: both;
            overflow: hidden;
            @media #{$small-only} {
              display: none;
            }
            > div {
              display: block;
              float: $ldirection;
              line-height: 1;
              text-transform: uppercase;
              text-align: center;
              @include swap_direction(padding, 11px 0);
              @include swap_direction(margin, 0);
              font-family: $akzidenz;
              font-weight: bold;
              @media #{$large-up} {
                letter-spacing: 0.25em;
              }
            }
            .selection-time {
              width: 41%;
              @include swap_direction(border, 1px solid $color-light-navy-alt);
              background-color: $color-light-navy-alt;
              text-transform: uppercase;
            }
            .selection-selector {
              @include swap_direction(border, 1px solid $color-navy);
              background-color: $color-white;
              width: 59%;
              text-transform: uppercase;
              .icon {
                display: none;
                position: relative;
                top: -2px;
                margin-#{$rdirection}: 2px;
                width: 17px;
                height: 12px;
                background: url('/media/export/cms/appointment_booking/services/service_selected_check.gif') no-repeat;
              }
            }
            .selection-selector.selected {
              background-color: $color-navy;
              color: $color-white;
              .icon {
                display: inline-block;
              }
            }
          }
        }
        .service:nth-child(2) {
          float: $ldirection;
        }
        #change-services-link,
        #read-all-artist-link,
        #appt-book-sign-in-link,
        .start-over {
          text-transform: uppercase;
          font-family: $akzidenz;
          font-weight: bold;
          text-decoration: none;
          border-bottom: 2px solid $color-medium-gray;
          font-size: 12px;
          @media #{$small-only} {
            font-size: 13px;
            padding-bottom: 0;
          }
        }
        #appt-book-sign-in-link {
          font-size: 22px;
          border-bottom: 2px solid $color-navy;
          font-family: $optimalight;
          display: inline-block;
          text-transform: none;
          font-weight: normal;
          @media #{$small-only} {
            float: $ldirection;
            font-size: 13px;
          }
        }
        #change-services-link {
          // @include swap_direction(margin, 0 3.6%);
        }
        #read-all-artist-link {
          margin-#{$ldirection}: 51.8%;
        }
        .selects-container {
          overflow: hidden;
          width: 100%;
          margin-bottom: 3px;
          @include swap_direction(padding, 0 1.8%);
          .artist-select-container {
            .artist-info {
              margin-top: 5px;
              text-decoration: underline;
              cursor: pointer;
            }
          }
          .select-container {
            float: $ldirection;
            text-align: $ldirection;
            @include swap_direction(padding, 2.5% 1.8% 0);
            width: 50%;
            @media #{$small-only} {
              width: auto;
              float: none;
              display: block;
              select {
                width: 100%;
              }
            }
            h4 {
              font-family: $akzidenz;
              font-weight: bold;
              text-transform: uppercase;
              font-size: 12px;
              padding-bottom: 5px;
              letter-spacing: 0.1em;
              @media #{$small-only} {
                letter-spacing: 0.05em;
                font-size: 13px;
              }
            }
            .location-select,
            .artist-select,
            .virtual-location {
              max-width: 363px;
              width: 65%;
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              @media #{$small-only} {
                width: 100%;
                font-size: 12px;
                font-weight: bold;
              }
              .selectBox-label {
                font-weight: bold;
              }
            }
          }
        }
        .appointment-select {
          .calendar-head {
            position: relative;
            text-align: center;
            height: 73px;
            @include swap_direction(margin, 0);
            @media #{$small-only} {
              text-align: $ldirection;
              height: auto;
              @include swap_direction(margin, 20px 0);
              font-family: $akzidenz;
              font-weight: bold;
              font-size: 14px;
              span {
                font-family: $akzidenz;
              }
            }
            .cal-head-content {
              position: relative;
              display: inline-block;
              overflow: hidden;
              margin-top: 22px;
              > * {
                float: $ldirection;
              }
              .cal-copy {
                position: relative;
                font-family: $akzidenz;
                font-weight: bold;
                font-size: 12px;
                text-transform: uppercase;
                @include swap_direction(padding, 5px 33px);
                letter-spacing: 0.1em;
                @media #{$small-only} {
                  letter-spacing: 1px;
                }
              }
              .cal-controls {
                width: 25px;
                height: 25px;
                cursor: pointer;
                overflow: hidden; // for img replacement
                text-indent: 100%; // for img replacement
                white-space: nowrap; // for img replacement
              }
              .cal-controls.next {
                background: transparent url('/media/export/cms/appointment_booking/icons/cal_arrow_right.png') no-repeat;
              }
              .cal-controls.previous {
                background: transparent url('/media/export/cms/appointment_booking/icons/cal_arrow_left.png') no-repeat;
              }
            }
            .date-picker {
              position: absolute;
              #{$rdirection}: 0;
              top: 0;
              background: transparent url('/media/export/cms/appointment_booking/icons/cal_date_picker_icon.png')
                no-repeat;
              width: 72px;
              height: 73px;
              cursor: pointer;
              overflow: hidden; // for img replacement
              text-indent: 100%; // for img replacement
              white-space: nowrap; // for img replacement
            }
          }
          @media #{$small-only} {
            .date-picker {
              width: 100%;
              margin-bottom: 13px;
              .pikaday-container {
                @include swap_direction(padding, 0);
                .pika-single {
                  z-index: 0;
                  font-family: $akzidenz;
                  font-weight: bold;
                  @include swap_direction(border, none);
                  .pika-lendar {
                    float: none;
                    @include swap_direction(margin, 0);
                    width: 100%;
                    .pika-title {
                      font-size: 16px;
                      margin-top: 10px;
                      .pika-label {
                        z-index: 0;
                        &:last-child {
                          display: none;
                        }
                        .pika-select {
                          z-index: 0;
                          display: none;
                        }
                      }
                      .pika-prev,
                      .pika-next {
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                        overflow: hidden; // for img replacement
                        text-indent: 100%; // for img replacement
                        white-space: nowrap; // for img replacement
                      }
                      .pika-prev {
                        background: transparent url('/media/export/cms/appointment_booking/icons/cal_arrow_left.png')
                          no-repeat;
                      }
                      .pika-next {
                        background: transparent url('/media/export/cms/appointment_booking/icons/cal_arrow_right.png')
                          no-repeat;
                      }
                    }
                    table.pika-table {
                      border-collapse: collapse;
                      border-spacing: 0;
                      td,
                      th {
                        @include swap_direction(border, none);
                        background-color: transparent;
                        height: 33px;
                        @include swap_direction(padding, 0);
                      }
                      th {
                        abbr,
                        abbr[title] {
                          @include swap_direction(border, none);
                          cursor: default;
                        }
                      }
                      td {
                        .pika-button {
                          background-color: $color-white;
                          color: $color-navy;
                          @include swap_direction(border-radius, 0);
                          box-shadow: none;
                          text-align: center;
                          font-size: 14px;
                          font-family: $akzidenz;
                          font-weight: bold;
                          height: 33px;
                        }
                        &.is-selected .pika-button {
                          background-color: $color-navy;
                          color: $color-white;
                          &:hover {
                            background-color: $color-navy;
                            color: $color-white;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          table {
            text-align: center;
            @include swap_direction(border, 1px solid $color-medium-gray);
            @include swap_direction(margin, 0);
            width: 100%;
            @media #{$small-only} {
              @include swap_direction(border, none);
              border-collapse: separate;
              border-spacing: 6px;
            }
            th {
              background-color: $color-navy;
              color: $color-white;
              @include swap_direction(border, none);
              text-align: center;
              font-size: 12px;
              font-weight: bold;
              @include swap_direction(padding, 0.55em 0);
              width: 14.285%;
              &.hilite {
                color: #9eafff;
              }
              @media #{$small-only} {
                color: #000000;
              }
            }
            td {
              @include swap_direction(border, 1px solid $color-medium-gray);
              border-top: none;
              text-align: center;
              color: $color-medium-gray;
              font-weight: bold;
              cursor: default;
              font-size: 12px;
              @include swap_direction(padding, 0.9em 0);
              width: 14.285%;
              @media #{$small-only} {
                border-top: 1px solid $color-medium-gray;
              }
            }
            td.enabled {
              font-family: $akzidenz;
              font-weight: bold;
              background-color: $color-white;
              color: $color-navy;
              cursor: pointer;
              &:hover {
                color: $color-white;
                background-color: $color-navy;
              }
              @media #{$small-only} {
                background-color: $color-navy;
                color: $color-white;
              }
            }
            td.selected {
              font-family: $akzidenz;
              font-weight: bold;
              background-color: #abc7ff;
              color: $color-white;
              font-family: $akzidenz;
              font-weight: bold;
              &:hover {
                color: $color-white;
                background-color: $color-navy;
                @media #{$small-only} {
                  background-color: #abc7ff;
                }
              }
            }
          }
        }
        .error-messages {
          display: none;
          .error-message {
            display: none;
            @media #{$small-only} {
              color: #ff0000;
            }
          }
        }
        .sign-in-container {
          display: block;
          overflow: hidden;
          width: 100%;
          height: auto;
          @include swap_direction(margin, 37px 0 0 0);
          letter-spacing: 0.1em;
          @media #{$small-only} {
            letter-spacing: 1px;
            @include swap_direction(margin, 0px);
          }
          h4,
          .sign-in-link {
            font-size: 22px;
            font-family: $optimalight;
            display: inline-block;
            @media #{$small-only} {
              font-size: 13px;
            }
          }
          .sign-in-form-container {
            display: none;
            position: relative;
            overflow: hidden;
            margin-top: 30px;
            input {
              display: block;
              width: 48.2%;
              margin-bottom: 20px;
              height: 40px;
              font-size: 12px;
              font-family: $akzidenz;
              &:focus {
                border-color: $color-black;
              }
              &.login__email {
                float: $ldirection;
                @media #{$small-only} {
                  width: 100%;
                  float: none;
                }
              }
              &.login__password {
                float: $ldirection;
                width: 35%;
                margin-#{$ldirection}: 3.6%;
                @media #{$small-only} {
                  width: 100%;
                  float: none;
                  @include swap_direction(margin, none);
                  margin-#{$ldirection}: 0%;
                }
              }
              &.login__submit {
                display: inline-block;
                background-color: $color-navy;
                color: $color-white;
                width: auto;
                float: $rdirection;
                font-size: 12px;
                @include swap_direction(padding, 0 30px);
                font-family: $akzidenz;
                font-weight: bold;
                @media #{$small-only} {
                  float: $rdirection;
                }
              }
            }
          }
        }
        .book-appt-container {
          display: block;
          overflow: hidden;
          width: 100%;
          height: auto;
          @include swap_direction(margin, 10px 0 23px);
          letter-spacing: 0.1em;
          @media #{$small-only} {
            letter-spacing: 1px;
          }
          h4 {
            margin-bottom: 25px;
            font-size: 22px;
            letter-spacing: 0.1em;
            @media #{$small-only} {
              letter-spacing: 1px;
              font-size: 13px;
            }
          }
          input,
          fieldset {
            display: inline-block;
            width: 48.2%;
            margin-bottom: 15px;
            font-size: 12px;
            font-family: $akzidenz;
            height: 40px;
            &:focus {
              border-color: $color-black;
            }
            @media #{$small-only} {
              width: 100%;
              float: none;
            }
            &.appt-book-first-name,
            &.appt-book-email {
              float: $ldirection;
              @media #{$small-only} {
                width: 100%;
                float: none;
              }
            }
            &.appt-book-last-name,
            &.appt-book-mobile {
              float: $rdirection;
              @media #{$small-only} {
                width: 100%;
                float: none;
              }
            }
          }
          .registration__privacy-policy-list,
          .regristration_loyalty-list,
          .registration__commercial-list,
          .registration__email-list,
          .registration__sms-list {
            input {
              width: auto;
            }
          }
          .note-head {
            clear: both;
            padding-top: 15px;
            padding-bottom: 3px;
            @media #{$small-only} {
              font-size: 20px;
              padding-top: 0;
              padding-bottom: 5px;
              margin-bottom: 0;
            }
          }
          .appt-book-note {
            width: 100%;
            display: block;
            height: 149px;
            margin-bottom: 16px;
            font-family: $akzidenz;
            font-size: 18px;
            letter-spacing: 0.1em;
            @media #{$small-only} {
              letter-spacing: 1px;
            }
          }
          .registration__privacy-policy-list,
          .regristration_loyalty-list,
          .registration__commercial-list,
          .registration__email-list,
          .registration__sms-list {
            font-family: $akzidenz;
            text-transform: none;
            width: 50%;
            float: $ldirection;
            span {
              width: 60%;
            }
            @media #{$small-only} {
              width: auto;
              float: none;
              display: block;
              margin-bottom: 18px;
              span {
                width: auto;
                letter-spacing: 0;
              }
            }
          }
          .registration__commercial-list {
            padding-top: 20px;
          }
          .registration__sms-list,
          .registration__commercial-list {
            float: $rdirection;
            @media #{$small-only} {
              width: auto;
              float: none;
              padding-top: 0;
            }
          }
        }
        .book-appt-container.no-last-name {
          input.appt-book-first-name {
            width: 100%;
          }
          input.appt-book-last-name {
            display: none;
          }
        }
        .confirm-container {
          display: block;
          overflow: hidden;
          height: auto;
          @include swap_direction(margin, 18px 0);
          float: $rdirection;
          max-width: 600px;
          @media #{$small-only} {
            width: auto;
            float: none;
            text-align: center;
          }
          .total-time {
            font-size: 16px;
            font-family: $akzidenz;
            float: $ldirection;
            @include swap_direction(padding, 18px 0);
            max-width: 600px;
            @media #{$small-only} {
              width: auto;
              float: none;
              font-weight: bold;
            }
          }
          .appt-book-book-submit {
            float: $rdirection;
            font-size: 16px;
            @include swap_direction(padding, 0 105px);
            height: 55px;
            color: $color-white;
            background-color: $color-navy;
            cursor: pointer;
            &.disabled {
              background-color: $color-medium-gray;
              color: $color-navy;
              cursor: default;
            }
            @media #{$small-only} {
              float: none;
              @include swap_direction(padding, 0px 25px);
              font-weight: bold;
            }
          }
          .start-over {
            text-align: $rdirection;
            float: $rdirection;
            display: block;
            margin-top: 35px;
            text-transform: uppercase;
            color: $color-navy;
            border-color: $color-navy;
            @media #{$small-only} {
              display: inline-block;
              width: auto;
              float: none;
              text-align: $ldirection;
            }
          }
        }
      }
    }
    .page-header {
      &__title {
        font-family: $akzidenz;
        font-weight: bold;
        font-size: 39px;
        font-weight: bold;
        letter-spacing: 7px;
        padding-#{$ldirection}: 90px;
        text-align: $ldirection;
        text-transform: uppercase;
      }
      &__subtitle {
        padding-#{$ldirection}: 90px;
        text-align: $ldirection;
        p {
          font-family: $akzidenz;
          font-size: 24px;
          line-height: 1.3;
        }
      }
    }
    &.confirmation {
      @media #{$small-only} {
        .appt-book-page-header {
          &-content {
            @include swap_direction(margin, 0);
            h4 {
              font-family: $optimalight;
              font-size: 46px;
              font-weight: normal;
            }
          }
        }
        .appt-book-section {
          &-content {
            padding-top: 0;
          }
        }
      }
    }
    .confirmation {
      @include swap_direction(margin, 0 auto 40px);
      max-width: 1110px;
      @include swap_direction(padding, 0 4%);
      position: relative;
      width: auto;
      .appt-book-content-header {
        .section-header {
          margin-bottom: 45px;
          @media #{$small-only} {
            margin-bottom: 0;
          }
        }
        h4 {
          text-align: $ldirection;
          @media #{$small-only} {
            text-align: center;
            font-family: $akzidenz;
            font-size: 12px;
            font-weight: normal;
          }
        }
        p {
          font-size: 16px;
          letter-spacing: 0;
          @include swap_direction(margin, 15px auto 30px);
          text-align: $ldirection;
          @media #{$small-only} {
            width: 100%;
            text-align: $ldirection;
            font-size: 14px;
            margin-top: 25px;
          }
        }
      }
      .confirmation-container {
        position: relative;
        width: 100%;
        @media #{$small-only} {
          // border-top: none;
        }
        > div {
          @include swap_direction(margin, 2px 0 14px);
          @media #{$small-only} {
            @include swap_direction(margin, 20px 0);
          }
          h4 {
            display: inline-block;
            font-family: $akzidenz;
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            vertical-align: top;
            width: 125px;
            @media #{$small-only} {
              display: block;
            }
          }
          span {
            display: inline-block;
            font-size: 16px;
            letter-spacing: 0;
          }
        }
        .your-lessons {
          @media #{$small-only} {
          }
        }
        .date-time,
        .artist {
          @media #{$small-only} {
          }
        }
        .location {
          clear: $rdirection;
          .location-details {
            display: inline-block;
          }
          .appt-date-cal,
          .location-direction {
            display: block;
            margin-#{$ldirection}: 130px;
            margin-top: 15px;
            @media #{$small-only} {
              @include swap_direction(margin, 0);
            }
          }
          span {
            display: table-row;
            @media #{$small-only} {
              display: block;
            }
          }
          a {
            border-bottom: 2px solid $color-medium-gray;
            color: $color-navy;
            font-family: $akzidenz;
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 0.15em;
            text-decoration: none;
            text-transform: uppercase;
            @media #{$small-only} {
              font-size: 12px;
              padding-bottom: 0;
            }
            &.appt-book-link {
              font-weight: 100;
              text-transform: none;
              word-break: break-all;
            }
          }
        }
        .appt-date-cal {
          .add-cal-link {
            border-bottom: 1px solid $color-medium-gray;
            display: inline-block;
            cursor: pointer;
            font-family: $akzidenz;
            font-weight: bold;
            font-size: 12px;
            text-decoration: none;
            text-transform: uppercase;
          }
          .cals-container {
            width: 115px;
            display: none;
            position: absolute;
            border: 1px solid $color-black;
            .appt-book-link {
              border: none;
              display: block;
              text-align: center;
              padding: 2px 0;
            }
            &.expanded {
              display: block;
              position: inherit;
              margin-top: 10px;
            }
          }
          .appt-book-link {
            font-size: 11px;
            line-height: 18.4px;
            letter-spacing: 0;
            border-color: 1px solid $color-black;
          }
        }
      }
      &-bottom-banner {
        margin-bottom: 30px;
        padding-top: 10px;
        text-align: center;
        @media #{$small-only} {
          margin-bottom: 20px;
          @include swap_direction(padding, 0);
        }
      }
    }
    .my-appointments {
      width: 100%;
      max-width: 560px;
      position: relative;
      text-align: $ldirection;
      @media #{$small-only} {
        max-width: 100%;
      }
      .appt-book-content-header {
        @include swap_direction(margin, 36px 0 28px);
        p {
          width: 56%;
          @include swap_direction(margin, 18px auto);
          @media #{$small-only} {
            width: 100%;
            text-align: $ldirection;
          }
        }
      }
      .appointments-container {
        position: relative;
        width: auto;
        overflow: hidden;
        @include swap_direction(margin, 0 0 0 0);
        @media #{$small-only} {
          @include swap_direction(margin, 0);
        }
        &.current > h4 {
          border-bottom: 1px solid $color-medium-gray;
          height: 50px;
          padding-#{$ldirection}: 10px;
          @media #{$small-only} {
            @include swap_direction(margin, 0);
            padding-#{$ldirection}: 0;
            padding-bottom: 5px;
            height: auto;
            font-size: 20px;
          }
        }
        .mobile-header {
          display: none;
          @media #{$small-only} {
            font-family: $akzidenz;
            font-weight: bold;
            text-transform: uppercase;
            display: block;
            border-top: 1px solid $color-light-navy;
            border-bottom: 1px solid $color-light-navy;
            width: 100%;
            @include swap_direction(padding, 15px 0);
            @include swap_direction(margin, 5px 0 23px);
          }
        }
        &.past {
          height: auto;
          overflow: hidden;
          border-bottom: 1px solid $color-medium-gray;
          &.expanded {
            @include swap_direction(border, none);
            .selectBox-arrow {
              transform: rotate(180deg);
            }
          }
          .past-appt-head {
            width: 100%;
            position: relative;
            margin-bottom: 25px;
            border-bottom: 1px solid $color-light-navy;
            @media #{$small-only} {
              margin-bottom: 15px;
            }
            a.view-link {
              font-family: $optimalight;
              font-weight: normal;
              font-size: 30px;
              text-transform: none;
              color: $color-navy;
              display: block;
              height: 50px;
              border-bottom: 1px solid $color-medium-gray;
              letter-spacing: -0.005em;
              position: relative;
              padding-#{$ldirection}: 10px;
              @media #{$small-only} {
                letter-spacing: 1px;
                height: 54px;
                @include swap_direction(padding, 13px 0 0);
                font-size: 20px;
              }
            }
            .section-header {
              @include swap_direction(margin, 28px 0 29px);
              text-align: center;
              font-size: 16px;
              letter-spacing: 0.1em;
              @media #{$small-only} {
                letter-spacing: 0.2em;
                font-size: 12px;
                @include swap_direction(margin, 0);
                @include swap_direction(padding, 0);
                &:before {
                  background: transparent;
                }
              }
              .section-header .inner,
              .section-header__inner {
                background-color: $color-light-navy-alt;
                @include swap_direction(padding, 0 14px 0 18px);
                @media #{$small-only} {
                  letter-spacing: 0.2em;
                  font-size: 12px;
                  @include swap_direction(margin, 20px 0);
                  @include swap_direction(padding, 0 5px);
                }
              }
            }
          }
          .appointment-details {
            @include swap_direction(margin, 0);
            &:last-child {
              .date-time,
              .artist {
                @media #{$small-only} {
                  border-bottom: none;
                }
              }
            }
            .date-time,
            .artist {
              @media #{$small-only} {
                border-bottom: none;
              }
            }
          }
          .appointment-container {
            margin-bottom: 60px;
            &:last-child {
              margin-bottom: 0;
            }
            @media #{$small-only} {
              margin-bottom: 30px;
            }
          }
        }
      }
      .appointment-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-bottom: 0;
        margin-#{$ldirection}: 10px;
        @media #{$small-only} {
          margin-#{$ldirection}: 0;
        }
        .appointment-details {
          position: relative;
          width: 100%;
          @include swap_direction(margin, 40px 0);
          overflow: hidden;
          font-size: 18px;
          line-height: 22px;
          @media #{$small-only} {
            font-size: 14px;
            line-height: 19px;
            @include swap_direction(margin, 10px 0);
          }
          > div {
            @media #{$small-only} {
              float: none;
              text-align: $ldirection;
              width: 100%;
              @include swap_direction(border, none);
              min-height: 0;
              @include swap_direction(padding, 0);
              @include swap_direction(margin, 0 0 15px);
            }
            h4 {
              font-family: $akzidenz;
              font-weight: bold;
              font-size: 12px;
              width: 125px;
              text-transform: uppercase;
              display: inline-block;
            }
            span {
              display: inline-block;
              font-size: 16px;
              @media #{$small-only} {
                text-align: $ldirection;
                display: block;
              }
            }
          }
          .your-lessons,
          .location {
            @media #{$small-only} {
              border-top: none;
            }
          }
          .your-lessons,
          .date-time {
            @media #{$small-only} {
              border-#{$rdirection}: none;
            }
          }
          .date-time,
          .artist {
            @media #{$small-only} {
              padding-top: 0;
            }
          }
          .location {
            clear: $rdirection;
            .location-details {
              display: inline;
            }
            .appt-date-cal,
            .location-direction {
              display: block;
              margin-#{$ldirection}: 130px;
              margin-top: 10px;
            }
            span {
              display: table-row;
              @media #{$small-only} {
                display: block;
              }
            }
            a {
              border-bottom: 1px solid $color-navy;
              font-family: $akzidenz;
              font-weight: bold;
              font-size: 12px;
              color: $color-navy;
              text-decoration: none;
              text-transform: uppercase;
              @media #{$small-only} {
                font-size: 12px;
                padding-bottom: 0;
              }
              &.appt-book-link {
                font-weight: 100;
                text-transform: none;
                word-break: break-all;
              }
            }
          }
        }
      }
      .appt-booking-links {
        @include swap_direction(margin, 18px 0 30px 0);
        @media #{$small-only} {
          @include swap_direction(margin, 33px 0px 0px);
        }
        h4 {
          font-family: $akzidenz;
          font-size: 18px;
          @include swap_direction(margin, 0 0 28px);
          letter-spacing: 0.1em;
        }
        a.button {
          color: $color-navy;
          border-color: $color-navy;
          font-size: 13px;
          letter-spacing: 0;
          text-transform: uppercase;
          font-family: $akzidenz;
          font-weight: bold;
          text-decoration: none;
          border-bottom: 1px solid $color-navy;
          text-align: $ldirection;
          margin-#{$ldirection}: 10px;
          @media #{$large-up} {
            @include swap_direction(padding, 0.8em 0.45em 0.95em 0);
          }
          @media #{$small-only} {
            @include swap_direction(padding, 0px);
            margin-#{$ldirection}: 0px;
            height: 30px;
          }
        }
      }
      .appointments-buttons {
        margin-bottom: 36px;
        @media #{$small-only} {
          margin-bottom: 30px;
          a {
            display: table;
            margin-top: 10px;
          }
        }
        .appt-book-link {
          font-size: 13px;
          letter-spacing: 0;
        }
        .cancel-appt-btn,
        .change-appt-btn {
          margin-#{$rdirection}: 17px;
          @media #{$small-only} {
            margin-#{$rdirection}: 0;
          }
        }
        a.cancel-appt-btn {
          color: $color-navy;
          border-color: $color-navy;
        }
      }
    }
    #appt-book-my-appointments-no-appts {
      max-width: 536px;
      font-size: 16px;
      text-align: $ldirection;
      margin-#{$ldirection}: 10px;
      @media #{$small-only} {
        max-width: 100%;
      }
      .button-container {
        margin-top: 25px;
        @media #{$small-only} {
          max-width: 100%;
          margin-top: 40px;
        }
      }
    }
    .appointment-cancellation {
      width: 100%;
      position: relative;
      & .cancel-content,
      & .error-content {
        display: none;
      }
      & .cancel-content {
        font-size: 16px;
        @media #{$small-only} {
          display: block;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
        }
        .appt-book-link {
          font-size: 14px;
          @media #{$small-only} {
            text-align: center;
          }
        }
      }
      &-banner {
        text-align: center;
        margin-bottom: 140px;
        @media #{$small-only} {
          margin-bottom: 40px;
        }
      }
      h3 {
        font-size: 33px;
        margin-bottom: 15px;
        @media #{$small-only} {
          font-size: 16px;
          text-align: center;
          margin-bottom: 0px;
        }
      }
      .button-container {
        display: block;
        margin-top: 40px;
        text-align: center;
      }
    }
    .services-container-header {
      margin-bottom: 40px;
      text-align: center;
      .section-header__inner {
        font-size: 60px;
      }
      & > p {
        font-family: $bb-roman;
        font-size: 32px;
        letter-spacing: normal;
      }
    }
    .services-renutrive,
    .services-skincare {
      background-color: #010101;
      color: #fffefe;
      margin-bottom: 50px;
      overflow: hidden;
      padding-top: 60px;
      @media #{$small-only} {
        padding-top: 10px;
        margin-bottom: 0;
        &.expanded .services-renutrive-header .icon,
        &.expanded .services-skincare-header .icon {
          background: url('/media/export/cms/appointment_booking/icons/icon-minus-gold.png') no-repeat;
          background-size: contain;
        }
      }
      &-header {
        margin-bottom: 40px;
        text-align: center;
        @media #{$small-only} {
          margin-bottom: 15px;
          @include swap_direction(padding, 0 1.7%);
          // accordion function
          padding-top: 2px;
          position: relative;
          width: 100%;
          height: 50px;
          overflow: hidden;
          cursor: pointer;
          border-bottom: 1px solid $color-light-navy-alt;
          img {
            height: 29px;
          }
          .icon {
            background: url('/media/export/cms/appointment_booking/icons/icon-plus-gold.png') no-repeat;
            background-size: contain;
            float: $rdirection;
            display: block;
            height: 25px;
            width: 25px;
            margin-#{$rdirection}: 20px;
            margin-top: 5px;
          }
        }
        > p {
          font-family: $bb-roman;
          font-size: 32px;
          letter-spacing: normal;
        }
      }
      .service {
        width: 33.3%;
        @media #{$small-only} {
          width: 100%;
          border-top: none;
          border-bottom: 1px solid #191919;
          margin-bottom: 20px;
          @include swap_direction(padding, 0 20px 15px 20px);
          &:first-child {
            border-top: none;
          }
        }
        &-content-container {
          @media #{$small-only} {
            @include swap_direction(padding, 0 50px 0 15px);
            .service-image {
              display: none;
            }
          }
        }
        .service-image {
          display: none;
          @media #{$small-only} {
            .service-image {
              display: none;
            }
          }
        }
        .service-head {
          @media #{$small-only} {
            height: 44px;
            padding-#{$ldirection}: 15px;
            .icon {
              display: none;
            }
          }
          .service-title {
            @media #{$small-only} {
              font-family: $bb-roman;
              font-size: 16px;
              letter-spacing: 0.05em;
              line-height: normal;
              margin-bottom: 20px;
              margin-top: 0;
              text-transform: none;
              text-align: $ldirection;
              width: 100%;
            }
          }
        }
        .selection-bar {
          #{$ldirection}: auto;
          padding-top: 25px;
          position: relative;
          text-align: center;
          width: 100%;
          @media #{$small-only} {
            text-align: $ldirection;
            @include swap_direction(margin, 0);
          }
          .selection-selector {
            background-color: #191919;
            border-color: $color-gold--renutriv;
            float: none;
            @include swap_direction(margin, 0 auto);
            text-align: center;
            @media #{$small-only} {
              @include swap_direction(margin, 0);
              width: 60%;
              @include swap_direction(padding, 12px 0);
            }
            &.selected {
              background-color: #191919;
              color: #ebdca1;
              filter: alpha(opacity=50);
              opacity: 0.5;
            }
          }
        }
        .service-details {
          bottom: auto;
          #{$ldirection}: auto;
          position: relative;
          text-align: center;
          width: 100%;
          @media #{$small-only} {
            font-family: $roboto-condensed;
            text-align: $ldirection;
            margin-#{$ldirection}: 0;
            margin-top: 0;
          }
          .lesson_description {
            color: #fffefe;
            @media #{$small-only} {
              font-size: 14px;
            }
            .selection-time {
              display: block;
              text-align: center;
              @media #{$small-only} {
                text-align: $ldirection;
              }
            }
          }
          h3.service-title {
            font-family: $bb-roman;
            font-size: 34px;
            font-weight: normal;
            letter-spacing: normal;
            line-height: normal;
            margin-bottom: 20px;
            margin-top: 5px;
          }
        }
      }
    }
    .services-renutrive {
      text-align: center;
      .service {
        display: inline-block !important;
        float: none;
      }
    }
    .services-skincare {
      background-color: #fffefe;
      color: #010101;
      .section-header__inner {
        font-size: 60px;
      }
      .service-details {
        .lesson_description {
          color: #010101 !important;
        }
        .selection-bar {
          .selection-selector {
            @include swap_direction(border, 1px solid $color-navy);
            background-color: $color-white;
          }
          .selection-selector.selected {
            background-color: $color-navy;
            color: $color-white;
          }
        }
      }
    }
  }
  .appointments-page {
    .mobile {
      display: none;
      @media #{$small-only} {
        display: initial;
      }
    }
    .appt-book-page-header-content h2 {
      border-bottom: 1px solid $color-medium-gray;
      @include swap_direction(margin, 0 0 50px);
      @media #{$small-only} {
        border-bottom: none;
        @include swap_direction(margin, 0px);
      }
    }
  }
  .appt-book-overlay {
    display: none;
    position: fixed;
    top: 0;
    #{$ldirection}: 0;
    bottom: 0;
    #{$rdirection}: 0;
    background-color: #ffffff; //fallback
    background-color: rgba(4, 10, 43, 0.85);
    z-index: 100;
    @media #{$small-only} {
      z-index: 1000;
    }
    .overlay-content-container {
      position: absolute;
      display: block;
      @include swap_direction(padding, 70px 170px);
      text-align: center;
      background-color: $color-white;
      @include swap_direction(border, 1px solid $color-light-gray);
      z-index: 1;
      width: 627px;
      height: 258px;
      top: 50%;
      #{$ldirection}: 50%;
      margin-top: -95px;
      margin-#{$ldirection}: -314px; // -214px;
      @media #{$small-only} {
        width: 300px;
        height: 232px;
        @include swap_direction(padding, 32px 25px);
        margin-#{$ldirection}: -150px;
      }
      .overlay-close {
        position: absolute;
        display: block;
        #{$rdirection}: 0;
        top: 0;
        background: transparent url('/media/export/cms/appointment_booking/icons/overlay_close_btn.png') no-repeat;
        width: 67px;
        height: 62px;
        cursor: pointer;
        z-index: 3;
        overflow: hidden; // for img replacement
        text-indent: 100%; // for img replacement
        white-space: nowrap; // for img replacement
      }
      .overlay-content {
        display: block;
        position: relative;
        text-align: center;
        h4 {
          font-size: 40px;
          color: $color-navy;
          margin-top: 4px;
          @media #{$small-only} {
            font-size: 32px;
            letter-spacing: 0.11em;
            line-height: 1.1em;
            margin-bottom: 8px;
            margin-top: 13px;
          }
        }
        p {
          line-height: 1.75;
          @media #{$small-only} {
            @include swap_direction(margin, 0 auto);
            line-height: 2.3em;
            font-size: 12px;
            width: 90%;
          }
        }
      }
    }
    &.appt-book-services-overlay .overlay-content-container,
    &.appt-book-book-conflict-overlay .overlay-content-container {
      width: 627px; // 428px;
      height: 258px; // 185px;
      top: 50%;
      #{$ldirection}: 50%;
      margin-top: -95px;
      margin-#{$ldirection}: -214px;
      margin-#{$ldirection}: -314px; // -214px
      @media #{$small-only} {
        width: 300px;
        height: 232px;
        margin-#{$ldirection}: -150px;
      }
    }
    &.appt-book-datepicker-overlay {
      z-index: 1000;
      .overlay-content-container {
        width: 950px;
        height: auto;
        top: 100px;
        #{$ldirection}: 50%;
        @include swap_direction(padding, 60px 20px);
        margin-#{$ldirection}: -475px;
        @media #{$large-down} {
          @include swap_direction(padding, 70px 170px);
        }
        @media #{$medium-down} {
          top: 60px;
          padding-top: 65px;
          padding-bottom: 38px;
        }
        .pika-single {
          color: $color-navy;
          @include swap_direction(border, none);
          .pika-lendar {
            @media #{$large-up} {
              border-#{$rdirection}: 1px solid $color-medium-gray;
              width: 50%;
              padding-#{$rdirection}: 3%;
              padding-#{$ldirection}: 0.5%;
              @include swap_direction(margin, 0);
              &:last-child {
                @include swap_direction(border, none);
                padding-#{$rdirection}: 0.5%;
                padding-#{$ldirection}: 3%;
              }
            }
          }
        }
        .pika-title {
          @include swap_direction(padding, 0 20px);
          button {
            &:hover {
              background-color: transparent;
            }
          }
        }
        .pika-label {
          font-family: $akzidenz;
          font-weight: bold;
          font-size: 24px;
          letter-spacing: 0.25em;
          @media #{$small-only} {
            font-size: 14px;
            letter-spacing: 1px;
          }
        }
        .pika-table {
          @media #{$large-up} {
            margin-top: 27px;
          }
          th {
            color: $color-navy;
            font-family: $akzidenz;
            font-weight: bold;
            border-bottom: none;
            @media #{$large-up} {
              font-size: 18px;
              @include swap_direction(padding, 0.7em 0 1em);
            }
          }
          abbr[title],
          abbr {
            @include swap_direction(border, none);
            text-decoration: none;
            cursor: default;
          }
          td {
            color: $color-navy;
            font-family: $akzidenz;
            border-bottom: none;
            @media #{$large-up} {
              font-size: 24px;
              @include swap_direction(padding, 0.47em 0);
            }
          }
        }
        .pika-button {
          background: #ffffff;
          font-family: $akzidenz;
          color: $color-navy;
          @include swap_direction(border-radius, 0);
          @media #{$large-up} {
            font-size: 24px;
            @include swap_direction(padding, 25px 16px);
            line-height: 5px;
          }
          &:hover {
            background-color: $color-navy;
            color: $color-white;
            @include swap_direction(border-radius, 0);
          }
        }
        .is-selected {
          .pika-button {
            background-color: #abc7ff;
            box-shadow: initial;
            color: $color-white;
            &:hover {
              background-color: $color-navy;
            }
          }
        }
        .pika-prev,
        .is-rtl .pika-next {
          background: transparent url('/media/export/cms/appointment_booking/icons/cal_arrow_left.png') no-repeat;
          opacity: 1;
          &.is-disabled {
            opacity: 0.2;
          }
        }
        .pika-next,
        .is-rtl .pika-prev {
          background: transparent url('/media/export/cms/appointment_booking/icons/cal_arrow_right.png') no-repeat;
          opacity: 1;
          &.is-disabled {
            opacity: 0.2;
          }
        }
      }
    }
    &.my-appointments-overlay .overlay-content-container {
      width: 428px;
      min-height: 184px;
      height: 350px;
      top: 50%;
      #{$ldirection}: 50%;
      @include swap_direction(padding, 50px 0px 0px);
      margin-top: -95px;
      margin-#{$ldirection}: -214px;
      @media #{$small-only} {
        width: 300px;
        height: auto;
        min-height: 0;
        margin-#{$ldirection}: -150px;
        margin-top: -155px;
        p {
          letter-spacing: 1px;
        }
      }
      #confirm-cancel {
        margin-top: 50px;
        @media #{$small-only} {
          margin-top: 0;
        }
        p {
          text-transform: uppercase;
          font-family: $akzidenz;
          font-weight: bold;
          letter-spacing: 0.2em;
          margin-top: 1px;
          margin-bottom: 20px;
          @media #{$small-only} {
            br {
              display: none;
            }
          }
        }
        .button {
          width: 152px;
          height: 38px;
          background-color: $color-navy;
          color: $color-white;
          cursor: pointer;
          @media #{$small-only} {
            display: block;
            @include swap_direction(margin, 10px auto);
          }
          &.yes-btn {
            margin-#{$rdirection}: 36px;
            @media #{$small-only} {
              margin-#{$rdirection}: auto;
              display: inline;
            }
          }
          &.no-btn {
            @media #{$small-only} {
              display: inline;
            }
          }
        }
      }
      .canceled {
        h4 {
          @include swap_direction(margin, 1px auto 12px);
          line-height: 32px;
          font-size: 32px;
          width: 80%;
          @media #{$small-only} {
            @include swap_direction(margin, 15px auto 12px);
            line-height: 24px;
            width: auto;
            font-size: 14px;
          }
        }
        p {
          margin-top: 17px;
          margin-bottom: 23px;
          span {
            display: block;
            text-align: center;
            margin-bottom: 5px;
          }
        }
        .book-new-btn {
          letter-spacing: 0.25em;
          margin-bottom: 7px;
          color: $color-white;
          background-color: $color-navy;
          @media #{$small-only} {
            @include swap_direction(padding, 0.86em 1.1em 0.86em 1.4em);
            letter-spacing: 1px;
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }
  .appt-book {
    &-page-header-content,
    &-content-header {
      font-size: 12px;
      font-family: $akzidenz;
      text-align: center;
      color: $color-navy;
      .section-header {
        margin-top: 3px;
        margin-bottom: 3px;
        font-size: 12px;
        @media #{$small-only} {
          width: 100%;
          font-size: 20px;
          text-align: center;
        }
        &__inner {
          font-size: 12px;
          font-weight: bold;
          font-family: $akzidenz;
        }
        &--description {
          font-size: 12px;
          @media #{$small-only} {
            font-weight: normal;
          }
        }
      }
    }
    &-datepicker-overlay {
      .overlay-content-container {
        .overlay-close {
          #{$ldirection}: 93%;
          @media #{$large-down} {
            height: 49px;
            #{$ldirection}: 100%;
            position: relative;
            width: 50px;
          }
          @media #{$large-up} {
            height: 62px;
            #{$ldirection}: 90%;
            position: absolute;
            width: 67px;
          }
        }
      }
    }
  }
  .no-rgba .appt-book-overlay {
    background: transparent url(/media/export/cms/appointment_booking/common/overlay_tint.png) repeat;
  }
}

#appointments {
  .book-appt-container {
    .registration__email-list-text {
      &.error::before {
        border: 1px solid red !important;
      }
    }
  }
}

#cboxOverlay {
  background: $ab--color--gray--lighter;
}

#colorbox {
  .device-mobile.artistColorboxOverlay & {
    &:not(.vto-ios-browser-error) {
      height: 410px !important;
    }
    &:not(.offer-grid-overlay__content):not(.vto-ios-browser-error) {
      width: 352px !important;
      top: 32px !important;
      #{$ldirection}: 5px !important;
      @media #{$ab-iphone6plus-only} {
        width: 357px !important;
        #{$ldirection}: 29px !important;
      }
    }
  }
}

.appt-artist-overlay {
  #cboxContent {
    padding: 0;
  }
  #cboxClose {
    top: 10px;
    #{$rdirection}: 15px;
    filter: brightness(0);
  }
}

.app-artist-info {
  @media #{$small-only} {
    width: 357px;
  }
  .slick-slide {
    width: 295px;
  }
  .slick-list {
    margin: 25px 30px 0 35px;
    @media #{$small-only} {
      margin: 10px 30px 0 -10px;
    }
  }
  .slick-track {
    @media #{$small-only} {
      #{$ldirection}: 7px;
      margin-top: 15px;
    }
  }
  .slick-prev {
    #{$ldirection}: 10px;
    background-image: none;
    &:before {
      content: '\2039';
      color: $ab--color--gray--black;
      font-size: 50px;
    }
  }
  .slick-next {
    #{$rdirection}: 7px;
    background-image: none;
    &:before {
      content: '\203A';
      color: $ab--color--gray--black;
      font-size: 50px;
      position: static;
    }
  }
  .slick-dots {
    @media #{$small-only} {
      bottom: -35px;
    }
    li {
      @media #{$small-only} {
        display: inline-block;
      }
      button {
        &:before {
          @media #{$small-only} {
            font-size: 30px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.artist-bio {
  margin-#{$rdirection}: 5px;
  margin-#{$ldirection}: 15px;
  @media #{$small-only} {
    margin: 0;
    margin-#{$rdirection}: 20px;
  }
  border-radius: 4px;
  border: 1px solid $ab--color--gray--lighter;
  overflow: hidden;
  .artist-image {
    padding: 0 0 1rem;
    img {
      width: 100%;
      height: auto;
    }
  }
  .artist-details {
    padding: 0px 0px 10px 10px;
    text-align: left;
    background-color: $ab--color--white;
    .artist_pre_header {
      text-transform: uppercase;
      font-size: 10px;
    }
    .artist-title {
      word-wrap: break-word;
      .artist-header {
        font-size: 12px;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
    .artist-description {
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 16px;
    }
  }
}

.virtual-service-message {
  color: rgb(176, 0, 32);
  margin-top: 10px;
  font-size: 15px;
  width: 60%;
}
