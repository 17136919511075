#loyalty__panel__receipt {
  @media #{$small-only} {
    @include swap_direction(padding, 10px 20px);
  }
  a {
    text-decoration: underline;
  }
  div,
  p {
    @media #{$small-only} {
      font-size: 14px;
      line-height: 1.2;
      color: $color-navy;
    }
  }
  .loyalty__panel__receipt__frame {
    height: 300px;
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0);
    @include swap_direction(border, none);
  }
  .loyalty__panel__receipt__submit__title {
    margin-top: 40px;
    color: $color-navy;
    @media #{$small-only} {
      text-align: center;
    }
    @media #{$medium-up} {
      br {
        display: none;
      }
    }
  }
  .loyalty__panel__receipt__scanning {
    margin-top: 30px;
  }
  .loyalty__panel__receipt__header {
    color: $color-navy;
    margin-bottom: 10px;
    margin-top: 0;
    padding-bottom: 16px;
    text-align: center;
    @media #{$medium-up} {
      border-bottom: 1px solid #cccccc;
    }
    span {
      @media #{$small-only} {
        display: block;
      }
    }
  }
  .loyalty__panel__receipt__introduction {
    p {
      strong {
        font-size: 14px;
      }
      span {
        display: block;
        margin-top: -2px;
      }
    }
    br {
      @media #{$small-only} {
        display: none;
        letter-spacing: 0.02em;
      }
    }
  }
  .loyalty__panel__receipt__instruction {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.15em;
    line-height: 18px;
    text-align: center;
    margin-top: 30px;
    @media #{$medium-up} {
      margin-top: 0;
      line-height: 24px;
      text-align: $ldirection;
    }
  }
  .loyalty__panel__receipt__terms__condition {
    color: $color-navy;
    margin-top: 56px;
    @media #{$small-only} {
      margin-top: 25px;
      @include swap_direction(padding, 0 4px);
      letter-spacing: 0.02em;
    }
  }
}

#loyalty__panel__receipt .loyalty__panel__receipt__submit {
  @media #{$small-only} {
    margin-top: 12px;
  }
  &__options {
    float: $ldirection;
    width: 40%;
    @media #{$small-only} {
      width: 100%;
    }
  }
  &__option2 {
    margin-#{$ldirection}: 10px;
    .loyalty__panel__receipt__submit__method {
      background-image: url('/media/images/account/loyalty/loyalty_panel_receipt_submit_method2.png');
      background-repeat: no-repeat;
    }
    @media #{$small-only} {
      width: 100%;
      @include swap_direction(margin, 0 0 30px 0);
    }
  }
  &__option3 {
    margin-#{$ldirection}: 52px;
    .loyalty__panel__receipt__submit__method {
      background-image: url('/media/images/account/loyalty/loyalty_panel_receipt_submit_method3.png');
      background-repeat: no-repeat;
    }
    @media #{$small-only} {
      @include swap_direction(margin, 0);
    }
  }
  &__method {
    float: $ldirection;
    height: 110px;
    text-indent: -9999px;
    width: 85px;
    @media #{$small-only} {
      background-size: 33px auto;
      height: 75px;
      width: 38px;
    }
    &__title {
      font-family: $optimalight;
      color: $color-navy;
      font-size: 34px;
      margin-bottom: 3px;
      letter-spacing: -0.02em;
      @media #{$small-only} {
        font-size: 25px;
      }
    }
  }
  &__method__container {
    @media #{$small-only} {
      float: $ldirection;
      width: 86%;
      position: relative;
      bottom: 4px;
    }
  }
  &__method__content {
    overflow: hidden;
  }
}

// receipt standalone page /elistemail
.receipt__email__verification {
  .receipt__email__verification__frame {
    width: 100%;
    height: 700px;
    @media #{$medium-up} {
      margin-top: -$header-collapsed-height;
      height: 900px;
    }
  }
}

#loyalty__page__activity .loyalty__page__index__breadcrumb {
  padding-bottom: 0;
}
