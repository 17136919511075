.page-navigation__hotswap {
  .depth-1 {
    @media #{$medium-up} {
      &.menu-container--has-nav-tout {
        padding-left: 0;
        width: 774px;
        ul.menu {
          float: right;
          width: 31%;
        }
      }
    }
  }
}
