.ee-social-share {
  @include swap_direction(margin, 20px auto);
  > ul {
    @include swap_direction(margin, 0);
    text-align: center;
    > li {
      display: inline-block;
      margin-#{$ldirection}: 27px;
      &:first-child {
        margin-#{$ldirection}: 0;
      }
    }
  }
  @media #{$medium-up} {
    @include swap_direction(margin, 0);
    position: fixed;
    #{$rdirection}: 0;
    top: 200px;
    background: $color-navy;
    @include swap_direction(padding, 13px);
    > ul {
      > li {
        display: block;
        @include swap_direction(margin, 12px 0 0 0);
        line-height: 0;
        &:first-child {
          @include swap_direction(margin, 0);
        }
      }
    }
    a.ee-social-share__link {
      // html element added to increase specificity
      @include swap_direction(margin, 0);
      line-height: 0;
    }
  }
}

.ee-social-share__link {
  @include squish-text;
  display: inline-block;
  &.facebook {
    background-size: auto auto;
    background-position: 0 -9122px;
    height: 36px;
    width: 20px;
    @media #{$medium-up} {
      background-size: auto auto;
      background: url('/media/images/global/sprites-s38a9885fce.png') 0 -9077px no-repeat;
      width: 11px;
      height: 20px;
    }
  }
  &.twitter {
    background-size: auto auto;
    background-position: 0 -9509px;
    height: 34px;
    width: 42px;
    @media #{$medium-up} {
      background-size: auto auto;
      background: url('/media/images/global/sprites-s38a9885fce.png') 0 -9465px no-repeat;
      width: 21px;
      height: 19px;
    }
  }
  &.pinterest {
    background-size: auto auto;
    background-position: 0 -9316px;
    height: 36px;
    width: 37px;
    @media #{$medium-up} {
      background-size: auto auto;
      background: url('/media/images/global/sprites-s38a9885fce.png') 0 -9271px no-repeat;
      width: 19px;
      height: 20px;
    }
  }
}

.ee-social-share__tooltip {
  display: none;
  position: absolute;
  #{$rdirection}: 50px;
  top: 15px;
  text-transform: uppercase;
}

.ee-social-share__tooltip-content {
  background: $color-white;
  font-size: 12px;
  line-height: 15px;
  @include swap_direction(padding, 5px 10px 5px 10px);
  @include swap_direction(border, $color-navy solid 1px);
  border-#{$rdirection}-width: 0;
  height: 26px;
  position: relative;
  margin-#{$rdirection}: 15px;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    #{$ldirection}: 100%;
    width: 0;
    height: 0;
    border-style: solid;
  }
}

.ee-social-share__tooltip-content:before {
  top: -1px;
  border-color: transparent transparent transparent $color-navy;
  border-width: 13px;
}

.ee-social-share__tooltip-content:after {
  top: 0;
  border-color: transparent transparent transparent $color-white;
  border-width: 12px;
}

.ee-quote-share.social-share {
  ul {
    li {
      margin-#{$ldirection}: 14px;
      margin-#{$rdirection}: 14px;
    }
  }
}
