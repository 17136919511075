$gwp-min-width: 630px;

.gwp {
  &__container {
    @media #{$xlarge-up} {
      max-width: $max-width;
      margin: 0 auto;
    }
    &--banner,
    &--products {
      position: relative;
    }
    &--bleed {
      @media #{$xlarge-up} {
        margin-top: -$header-collapsed-height;
      }
    }
    &--products {
      background-size: 100%;
      color: $color-navy;
      @media #{$medium-up} {
        background-image: none !important;
      }
    }
    &--product-items {
      background-size: 100%;
      @media #{$small-only} {
        background: none !important;
      }
    }
  }
  &__banner {
    &--image {
      width: 100%;
    }
    &--small {
      @media #{$medium-up} {
        display: none;
      }
    }
    &--large {
      display: none;
      @media #{$medium-up} {
        min-height: 310px;
        display: block;
      }
    }
    &-content {
      max-width: $product-wide;
      width: 100%;
      margin: 0 auto;
      position: absolute;
      top: 0;
      height: 100%;
      #{$rdirection}: 0;
      #{$ldirection}: 0;
      padding-#{$ldirection}: 25px;
      padding-top: 28px;
      @media #{$medium-up} {
        padding-#{$ldirection}: 20px;
        min-width: $gwp-min-width;
      }
      @media #{$xlarge-up} {
        padding-top: 12%;
        padding-#{$ldirection}: 65px;
      }
      color: $color-white;
      &--eyebrow,
      &--heading {
        text-transform: uppercase;
        margin: 0;
        line-height: 1;
      }
      &--eyebrow {
        font-weight: bold;
        margin-bottom: 10px;
        @include text-promo;
        font-size: 15px;
        @media #{$large-up} {
          margin-bottom: 15px;
          font-size: 12px;
        }
      }
      .headline--large {
        font-size: 52px;
        letter-spacing: -1px;
        margin-bottom: 7px;
        @media #{$medium-up} {
          font-size: 47px;
          margin-bottom: 0;
        }
        @media #{$xlarge-up} {
          font-size: 76px;
          text-indent: -5px;
        }
      }
      .headline--medium {
        font-size: 34px;
        letter-spacing: -1px;
        margin-bottom: 9px;
        @media #{$medium-up} {
          font-size: 30px;
          margin-bottom: 6px;
          letter-spacing: -0.75px;
        }
        @media #{$xlarge-up} {
          font-size: 49px;
          text-indent: -4px;
        }
      }
      &--description,
      &--promo {
        font-size: 25px;
        @media #{$medium-up} {
          font-size: 29px;
        }
        font-family: OptimaDisplayLight;
        line-height: 1.2;
        letter-spacing: -0.5px;
        margin: 0;
      }
      &--promo {
        margin-top: 20px;
        position: absolute;
        bottom: 20%;
        width: 35%;
        @media #{$medium-up} {
          position: static;
          width: auto;
          margin-top: 40px;
          text-indent: -3px;
        }
        @media #{$large-up} {
          margin-top: 35px;
        }
      }
      &--images {
        position: absolute;
        bottom: -2.2%;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        margin: auto;
        width: 100%;
        overflow: visible;
        z-index: 1;
        max-width: $product-wide + 40px;
        img {
          width: 87%;
          @media #{$medium-up} {
            width: 100%;
          }
        }
      }
      &--image-wrapper {
        width: 100%;
        position: absolute;
        bottom: 0;
        text-align: center;
      }
    }
  }
  &__product-details {
    .gwp__product-cta--oos {
      float: #{$rdirection};
      width: auto;
      margin-top: 0;
      display: block;
      font-size: 15px;
      @media #{$medium-up} {
        display: none;
      }
    }
    &--link {
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: bold;
      display: inline-block;
      margin-top: 8px;
      @media #{$medium-up} {
        font-size: 12px;
        margin-top: 0;
      }
    }
    &--overlay {
      @include swap_direction(padding, 35px 32px 30px 47px);
      z-index: 999;
      bottom: 50px;
      width: 100%;
      border: 1px solid $color-black;
      background: $color-white;
      position: absolute;
      color: $color-navy;
      #{$rdirection}: 0;
      #{$ldirection}: 0;
      @media #{$medium-up} {
        width: 100%;
        #{$rdirection}: auto;
        #{$ldirection}: auto;
      }
    }
    &--close {
      position: absolute;
      bottom: 0;
      text-indent: -9999px;
      background: url('/media/images/global/sprites-s38a9885fce.png') no-repeat 0 -972px;
      text-shadow: none;
      color: transparent;
      background-size: auto auto;
      height: 28px;
      width: 28px;
      top: 10px;
      z-index: 100;
      border: none;
      -webkit-appearance: none;
      #{$rdirection}: 10px;
    }
  }
  &__product-options {
    &--image-wrapper img {
      width: 87%;
    }
  }
  &__product--options {
    width: 100%;
    position: static;
    top: 0;
    float: #{$ldirection};
    &-content {
      width: 84.375vw;
      @media #{$medium-up} {
        width: auto;
      }
      margin: auto;
    }
    label {
      text-transform: none;
    }
    @media #{$medium-up} {
      @include swap_direction(padding, 0 2px 0 40px);
      width: auto;
    }
    @media #{$xlarge-up} {
      @include swap_direction(padding, 0 3% 0 4.8%);
    }
    .label.gwp__product-option--label {
      font-size: 29px;
      font-family: OptimaDisplayLight;
      line-height: 1;
      letter-spacing: 0;
    }
    &-copy {
      @media #{$xlarge-up} {
        max-width: 297px;
      }
    }
  }
  &__product-option {
    &--subline {
      margin: 10px 0 0;
      @media #{$medium-up} {
        width: auto;
      }
    }
    &--sub-title {
      text-transform: none;
      font-size: 15px;
    }
  }
  &__product-options--image-wrapper {
    padding-top: 35px;
    padding-bottom: 9px;
    text-align: center;
  }
  &__products--3,
  &__products--2,
  &__products--1 {
    .gwp__product--options {
      width: 100%;
      .gwp__product-options--image-wrapper {
        @media #{$medium-up} {
          display: none;
        }
      }
      @media #{$medium-up} {
        width: 33.3%;
        &:nth-child(n + 2) {
          #{$ldirection}: 33.3%;
        }
        &:nth-child(n + 3) {
          #{$ldirection}: 66.6%;
        }
      }
    }
    .gwp__banner-content--image-wrapper {
      width: 100%;
      &:nth-child(n + 2),
      &:nth-child(n + 3) {
        display: none;
      }
      @media #{$medium-up} {
        width: 33.3%;
        &:nth-child(n + 2) {
          #{$ldirection}: 33.3%;
          display: block;
        }
        &:nth-child(n + 3) {
          #{$ldirection}: 66.6%;
          display: block;
        }
      }
    }
  }
  &__products-list {
    padding-top: 20px;
    width: 100%;
    max-width: $product-wide;
    margin: auto;
    @media #{$medium-up} {
      padding-top: 42px;
      overflow: visible;
    }
    .gwp__banner-content--image-wrapper {
      @media #{$medium-up} {
        display: none;
      }
    }
  }
  &__product {
    &--disable {
      .gwp__product-cta--add.button--inverted {
        cursor: default;
        border-color: $color-darker-gray;
        color: $color-darker-gray;
      }
    }
    &-details {
      position: relative;
      padding-top: 7px;
      padding-bottom: 24px;
      @media #{$medium-up} {
        padding-bottom: 16px;
      }
    }
    &-cta {
      padding-#{$ldirection}: 0;
      text-align: center;
      @media #{$medium-up} {
        text-align: #{$ldirection};
      }
    }
    &-details--text {
      text-transform: none;
      letter-spacing: 0.1em;
      .non-breaking-word {
        display: inline-block;
      }
      .gwp__details-list--header {
        line-height: 18px;
      }
      .gwp--title {
        text-transform: uppercase;
      }
      dt {
        font-family: 'AkzidenzGrotesk W1G';
        font-weight: 700;
        display: list-item;
        list-style-type: disc;
        text-transform: none;
        line-height: 18px;
      }
      dd {
        text-transform: none;
      }
    }
    &-cta--add,
    &-cta--remove {
      font-weight: bold;
      width: 84.375vw;
      height: 58px;
      font-size: 16px;
      line-height: 59px;
      @media #{$medium-up} {
        width: auto;
        height: 40px;
        font-size: 12px;
        line-height: 40px;
      }
      &.button--inverted {
        background: $color-white;
        color: $color-navy;
        @media #{$medium-up} {
          background: none;
        }
      }
    }
    &--sold-out {
      .gwp__product-cta--add {
        cursor: default;
      }
      &.gwp__product--options {
        .label.gwp__product-option--label {
          cursor: default;
        }
      }
    }
    &-cta--oos {
      display: none;
      border: 0;
      width: 84.375vw;
      margin: 15px auto 0;
      @media #{$medium-up} {
        width: auto;
        margin-#{$ldirection}: 0;
        display: block;
      }
      text-align: #{$ldirection};
      font-weight: normal;
      padding: 0;
      color: $color-red;
      line-height: normal;
      height: auto;
      cursor: default;
      text-transform: uppercase;
      font-size: 12px;
      background: none;
      letter-spacing: 0.15em;
    }
    &-cta--loading {
      @include swap_direction(margin, 0 0 0 80px);
      display: none;
      position: absolute;
      font-size: 12px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: 700;
      height: 45px;
      line-height: 45px;
      padding-#{$ldirection}: 38px;
      background: url(/media/images/common/ajax-loader.gif) no-repeat 0 0 transparent;
      #{$ldirection}: 4.5%;
    }
    &-cta--loader {
      position: relative;
      padding-bottom: 45px;
      width: 84.375vw;
      margin: auto;
      @media #{$medium-up} {
        width: auto;
      }
      .gwp__product-cta--loading {
        display: block;
        #{$ldirection}: 0;
        margin: auto;
        @media #{$medium-up} {
          #{$ldirection}: 4.5%;
        }
      }
      .gwp__product-cta--add,
      .gwp__product-cta--remove,
      .gwp__product-cta--oos {
        display: none;
      }
    }
  }
  &__notice-container {
    width: 100%;
    text-align: center;
    padding: 26px 0 23px;
    clear: both;
  }
  &__overlay-container--success-overlay {
    #cboxClose {
      #{$rdirection}: 20px !important;
      top: 20px;
    }
  }
  &__overlay-content--success {
    text-align: center;
    @media #{$medium-up} {
      position: absolute;
      top: 50%;
      #{$ldirection}: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    width: 100%;
    &-header {
      font-size: 38px;
      font-family: OptimaDisplayLight;
      line-height: 1;
      letter-spacing: 0;
      font-weight: bold;
    }
    &-copy {
      text-transform: uppercase;
      font-size: 13px;
      margin: 10px 100px;
      line-height: 20px;
    }
    &-button {
      font-size: 12px;
      font-family: 'AkzidenzGrotesk W1G';
      letter-spacing: 0.15em;
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 700;
      display: block;
    }
  }
  &__overlay-content--continue-button {
    margin-bottom: 14px;
  }
}
