// Common styles
$color-dw-cool: #f2d8c8;
$color-dw-neutral: #f3e2cf;
$color-dw-warm: #f4d6b2;
$color-dw-grey: #ddd;

@mixin dw-foundation-sampling-layout {
  margin: auto;
  width: 100%;
  max-width: $max-width;
  position: relative;
  font-size: 100%;
  cursor: default;
}

@mixin dw-foundation-sampling-loader {
  background-image: url('/media/export/cms/mpp/mpp_loader.gif');
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-#{$ldirection}: 5px;
}

.dw-fs-loader {
  @include dw-foundation-sampling-loader;
}

@import 'dw_foundation_sampling-header';
@import 'dw_foundation_sampling-tout';
@import 'dw_foundation_sampling-rating';
@import 'dw_foundation_sampling-shades';
@import 'dw_foundation_sampling-pop-up';
@import 'dw_foundation_sampling-footer';
@import 'dw_foundation_sampling-filter';
@import 'dw_foundation_sampling-view-cart';
@import 'dw_foundation_sampling-order-history';
