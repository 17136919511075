$medium-max-width: 767px;

.bvtout_v1 {
  @media screen and (max-width: $medium-max-width) {
    margin-top: 8.1%;
  }
  margin-top: 2.1%;
  .el_boutique_h2 {
    @media screen and (max-width: $medium-max-width) {
      font-size: 7.5vw;
    }
    width: 100%;
    text-align: center;
    text-transform: inherit;
  }
  .bvtout {
    &__carousel {
      @media screen and (max-width: $medium-max-width) {
        margin-top: 5.4%;
        .basic_infinite_carouselnav {
          width: 5.6vw;
          height: 84.3%;
          .basic_infinite_carouselnav_btn {
            width: 75%;
          }
        }
        .basic_infinite_carouselnav_1 {
          margin-#{$ldirection}: 0;
        }
        .basic_infinite_carouselnav_2 {
          margin-#{$rdirection}: 0;
        }
      }
      margin-top: 1%;
      .basic_infinite_carousel_pagination_dot {
        border: 1px solid $color-navy;
      }
      .basic_infinite_carouselOuter:not(.basic_infinite_carousel_header) {
        @media only screen and (min-width: $max-width) {
          padding-bottom: 42px;
        }
        padding-bottom: 3.07692vw;
      }
      .bvtout {
        &__slide {
          @media screen and (max-width: $medium-max-width) {
            margin-bottom: 3.2vw;
          }
          position: relative;
          .bvtout {
            &__slide-video {
              @media screen and (max-width: $medium-max-width) {
                width: 88.90625vw;
              }
              position: relative;
              width: 54.393%;
              margin: 0 auto;
              .el_boutique_link,
              .ytPlayer__icon {
                position: absolute;
                cursor: pointer;
                z-index: 1;
                &:hover {
                  opacity: 0.7;
                }
              }
              .ytPlayer__icon {
                @media screen and (max-width: 980px) {
                  width: 66px;
                  bottom: 13.4%;
                  @media screen and (max-width: $medium-max-width) {
                    width: 21.5625vw;
                    #{$ldirection}: 50%;
                    transform: translateX(-50%);
                    bottom: 14.5vw;
                  }
                }
                width: 12.517%;
                bottom: 9.4%;
                #{$ldirection}: 4%;
              }
              .el_boutique_link {
                bottom: 4.5%;
                #{$ldirection}: 4.1%;
              }
            }
          }
          .text--short {
            @media screen and (max-width: $medium-max-width) {
              padding-top: 2.1%;
              line-height: 115%;
              font-size: 3.4375vw;
              width: 88.90625vw;
              margin: 0 auto;
            }
            padding-top: 1.05%;
            line-height: 2em;
          }
        }
      }
    }
  }
}
