///
/// @file drupal_modules/esteelauder_common/_franchise_catlinks.scss
/// Styles for additional links on mpp franchise pages
///
/// \brief Styling for template at:
///   esteelauder/modules/custom/esteelauder_common/templates/franchise_catlinks_v1
///   styles expect markup, e.g., ul.links-column links-col-1, ul.links-column links-col-2
///
.mpp__estee_edit_subnav,
.spp__estee_edit_subnav {
  // filter styles
  .item-count-container {
    margin: 0;
  }
  #mpp__filter-footer {
    right: auto;
    float: none;
    .item-count-container {
      float: none;
      margin-bottom: 10px;
      margin-top: 0;
      font-family: $devinne-italic;
      color: $color-light-gray--estee-edit;
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }
  #mpp__filter {
    padding: 0;
  }
  #mpp__filter .mpp__filter-section {
    //about the brand link
    li a.about__brand {
      color: $color-bright-blue--estee_edit;
      font-family: $devinne-roman;
      font-size: 37px;
      letter-spacing: -1px;
      padding-right: 22px;
      padding-left: 22px;
      line-height: 53px;
      display: inline-block;
    }
    text-align: center;
    margin: auto;
    width: 100%;
    padding-top: 50px;
    a,
    .clear-all {
      color: $color-bright-blue--estee_edit;
      font-family: $devinne-roman;
      font-size: 37px;
      letter-spacing: -1px;
      padding-right: 22px;
      padding-left: 22px;
      line-height: 53px;
    }
    .clear-all {
      display: inline-block;
    }
    //last line and filter  menu
    ul:last-of-type {
      padding-top: 28px;
      li {
        padding: 0 15px 0 15px;
      }
      a {
        padding: 10px 0 15px 0;
        font-size: 27px;
        font-family: $devinne-roman;
        text-transform: inherit;
        letter-spacing: 0px;
        line-height: 53px;
      }
      .clear-all {
        cursor: pointer;
        padding: 10px 15px 15px 15px;
        font-size: 27px;
        font-family: $devinne-italic;
        letter-spacing: 0px;
      }
      a:hover {
        color: $color-black;
      }
      .clear-all:hover {
        color: $color-black;
      }
    }
  }
  #mpp__filter li.mpp__filter-item {
    width: inherit;
    height: inherit;
  }
  #mpp__filter .mpp__filter-section ul {
    float: none;
  }
  #mpp__filter-footer .clear-all {
    float: none;
  }
  // filter styles ends

  // Sticky state. See main-pc.js for sticky behavior
  &#{$sticky} {
    @include animation-name(fadeHeaderInDown);
    @include animationDuration(2s);
    position: fixed;
    top: $header-collapsed-height;
    //fine tuning adjusting for header collapsed
    padding: 3px 0 0 0;
    left: 0;
  }
  .filter__trigger,
  #mpp__filter-tab {
    display: inline-block;
    color: #a4a4ab;
    font-weight: normal;
    white-space: nowrap;
    letter-spacing: 0;
    font-family: 'FFMarkWeb';
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
    padding: 0 15px 0 15px;
    position: inherit;
  }
  .filter__trigger.active {
    background: url(/media/export/cms/mpp/tee-mpp-subnav-icon-ex.png) no-repeat 135px 14px transparent;
  }
  #mpp__filter-tab .minus {
    background: url(/media/export/cms/mpp/tee-mpp-subnav-icon-ex.png) no-repeat 0px 7px transparent;
  }
  #mpp__filter-tab .plus {
    background: url(/media/export/cms/mpp/tee-mpp-subnav-icon-plus.png) no-repeat 0px 7px transparent;
  }
  .field-menu {
    display: inline-block;
  }
  .subnav__background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-bright-blue--estee_edit;
    opacity: 0.85;
    z-index: -1;
  }
  a {
    color: $color-white;
    font-weight: normal;
    -webkit-transition: color 0.9s ease;
    -moz-transition: color 0.9s ease;
    -o-transition: color 0.9s ease;
    transition: color 0.9s ease;
  }
  width: 100%;
  //fine tuning adjusting for header collapsed
  padding: 3px 0 0 0;
  position: absolute;
  top: $header-collapsed-height;
  z-index: 101;
  @media #{$medium-only} {
    z-index: 2;
  }
  @media #{$medium-up} {
    margin: 0 auto;
  }
  .subnav_links {
    a:hover {
      color: $color-white;
    }
    &.has-hover a {
      color: #8d8d8e; // grey out other menu links
      -webkit-transition: color 0.9s ease;
      -moz-transition: color 0.9s ease;
      -o-transition: color 0.9s ease;
      transition: color 0.9s ease;
    }
    a.has-hover {
      color: $color-white;
      -webkit-transition: color 0.9s ease;
      -moz-transition: color 0.9s ease;
      -o-transition: color 0.9s ease;
      transition: color 0.9s ease;
    }
  }
  .subnav_links {
    letter-spacing: 0;
    line-height: 34px;
    padding-top: 2px;
    font-family: 'FFMarkWeb';
    text-transform: uppercase;
    font-size: 11px;
    opacity: 1;
    .actived {
      font-family: 'FFMarkWebMedium';
    }
    // hidding break-to-next-line li for long nav when in main subnav links
    li.expanded {
      display: none;
    }
    li {
      display: inline;
      padding: 0 15px 0 15px;
      white-space: nowrap;
    }
    li:last-child {
      opacity: 0.85;
    }
    &li:last-child a {
      line-height: 37px;
      padding-bottom: 15px;
    }
  }
}

.mpp__estee_edit_subnav_expanded {
  &#{$sticky} {
    position: static;
  }
  .mpp__filter__landing__nav a {
    outline: 0;
    &.makeup {
      color: $color-bright-blue--estee_edit;
    }
    &.makeup.activate {
      color: $color-black;
    }
    &.skincare {
      color: $color-bright-blue--estee_edit;
    }
    &.skincare.activate {
      color: $color-black;
    }
  }
  .mpp__filter__landing__nav a:hover,
  .mpp__filter__landing__nav a:active,
  .mpp__filter__landing__nav a:active {
    outline: 0;
    color: $color-black;
  }
  .mpp__filter__landing__nav {
    li {
      padding: 0 15px 0 15px;
    }
    a {
      padding: 10px 0 15px 0;
      font-size: 27px;
      font-family: $devinne-roman;
      text-transform: inherit;
      letter-spacing: 0px;
      line-height: 53px;
    }
    .viewall {
      cursor: pointer;
      font-size: 27px;
      font-family: $devinne-italic;
      letter-spacing: 0px;
    }
    a:hover {
      color: $color-black;
    }
    .clear-all:hover {
      color: $color-black;
    }
  }
}

.mpp__estee_edit_subnav-filter--content,
.mpp__estee_edit_subnav_expanded {
  &:not(.mpp__estee_edit_subnav_expanded) {
    display: none;
    .field-menu {
      display: block;
    }
  }
  // hidding break-to-next-line padding li for long nav when in main subnav links
  li.expanded {
    padding: 0;
  }
  span {
    font-family: $devinne-roman;
    font-size: 25px;
  }
  text-align: center;
  font-family: $devinne-roman;
  background-color: $color-white;
  a {
    color: $color-bright-blue--estee_edit;
    line-height: 0.8em;
  }
  width: 100%;
  display: block;
  &:not(.mpp__estee_edit_subnav_expanded) {
    float: left;
  }
  ul {
  }
  //last line and filter  menu
  .field-menu:last-of-type {
    padding: 10px 0 48px 0;
    font-size: 27px;
    font-family: $devinne-roman;
  }
  .field-menu:last-of-type {
    color: $color-bright-blue--estee_edit;
  }
  // main links and filter menu
  .field-menu:first-of-type {
    font-size: 37px;
    padding: 40px 0 0 0;
    font-family: $devinne-roman;
    color: $color-bright-blue--estee_edit;
    line-height: 55px;
    letter-spacing: -1px;
    text-transform: uppercase;

    // for hover underline thinner use below
    a {
      display: inline-block;
      position: relative;
      vertical-align: top;
    }
    a:first-of-type {
      margin-bottom: 18px;
    }
    a:hover {
      color: $color-black;
    }
  }
  li {
    display: inline;
    padding: 0 15px 0 15px;
    @media #{$medium-up} {
      padding: 0 15px 0 15px;
    }
  }
}
// Fine tuning for SPP TEE nav
.spp__estee_edit_subnav {
  margin: 3px 0 0 0;
  &#{$sticky} {
    @include animation-name(fadeHeaderInDown);
    @include animationDuration(1.5s);
    margin-top: 3px;
    left: 0;
  }
  .subnav_links {
    padding-top: 0;
  }
}
