.device-pc {
  .discover-more {
    padding-bottom: 100px;
  }
  .discover-more__header {
    @include headline--secondary;
    text-align: center;
    @include swap_direction(padding, 0 0 65px);
    #{$renutriv} & {
      font-family: $bb-roman;
      text-transform: uppercase;
      font-size: 35px;
      letter-spacing: 0.03em;
      font-weight: lighter;
    }
  }
  .discover-more__products {
    @include pie-clearfix;
    width: 820px;
    @include swap_direction(margin, 0 auto);
    @media #{$medium-only} {
      width: 100%;
    }
  }
  .discover_more__product {
    position: relative;
    float: $ldirection;
    width: 246px;
    text-align: center;
    &:nth-child(2) {
      @include swap_direction(margin, 0 41px);
    }
    @media #{$medium-only} {
      margin-#{$ldirection}: 5px;
      &:nth-child(2) {
        @include swap_direction(margin, 0 5px 0 10px);
      }
    }
    padding-bottom: 43px;
  }
  .discover_more__product-url {
    display: block;
  }
  .discover_more__product-image-container {
    position: relative;
    display: block;
    width: 246px;
    height: 350px;
    background: $color-light-gray;
    overflow: hidden;
  }
  .discover_more__product-image {
    position: absolute;
    display: block;
    top: 0;
    #{$ldirection}: -5px;
    width: 256px;
    height: 352px;
  }
  .discover_more__product-header {
    @include headline--quinary;
    margin-top: 40px;
    #{$renutriv} & {
      font-family: $bb-roman;
      text-transform: uppercase;
    }
  }
  .discover_more__product-sub-header {
    @include headline--senary;
    margin-top: 3px;
    #{$renutriv} & {
      @include renutriv-copy;
    }
  }
  .discover_more__product-price {
    font-weight: bold;
    margin-top: 10px;
  }
  .discover_more__product-reviews {
    margin-top: 40px;
  }
  .discover_more__product-quickshop {
    margin-top: 16px;
    @media #{$device-ipad-tablet-portrait-landscape} {
      display: none;
    }
  }
  .discover_more__reviews-image {
    margin-bottom: 4px;
  }
  .discover_more__product-shopnow {
    position: absolute;
    bottom: 0;
    display: block;
    width: 130px;
    text-align: center;
    margin-#{$ldirection}: 58px;
    #{$renutriv} & {
      color: $color-gold--renutriv;
      text-transform: uppercase;
      font-family: $roboto-condensed;
    }
  }
  .discover-more--estee-edit {
    font-family: $ffmarkweb;
    text-transform: uppercase;
    .discover_more__product-header-container {
      position: relative;
      min-height: 86px;
    }
    .discover_more__product-reviews {
      font-size: 11px;
      font-family: $akzidenz;
      margin-top: 0;
      span {
        padding-#{$ldirection}: 6px;
      }
    }
    .discover-more__header {
      font-family: $devinne-italic;
      font-size: 60px;
      line-height: 70px;
      padding-bottom: 4px;
      text-transform: none;
    }
    .discover_more__misc-flag {
      font-size: 11px;
      height: 11px;
      font-family: $ffmarkweb;
      margin-top: 10px;
      letter-spacing: 0;
    }
    .discover_more__product-header {
      font-family: $ffmarkweb-medium;
      font-size: 14px;
      height: 14px;
      font-weight: normal;
      letter-spacing: 0;
      margin-top: 4px;
    }
    .discover_more__product-sub-header {
      font-family: $ffmarkweb;
      font-size: 14px;
      height: 14px;
      font-weight: normal;
      letter-spacing: 0;
      margin-top: 0;
    }
    .discover_more__product-price {
      font-family: $ffmarkweb;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0;
      margin-top: 14px;
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 100%;
    }
    .discover_more__buttons {
      @include clearfix;
      position: absolute;
      bottom: 0;
      text-align: $ldirection;
      width: 164px;
      margin-#{$ldirection}: 41px;
      &.discover_more__buttons--center {
        margin-#{$ldirection}: 68px;
      }
    }
    .discover_more__product-quickshop {
      float: $ldirection;
      width: 120px;
      font-family: $ffmarkweb-medium;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0;
      padding: 0 5px;
      text-align: center;
    }
    .discover_more__video {
      background-size: auto auto;
      background-position: 0 -11824px;
      display: block;
      float: $ldirection;
      margin: 16px 0 0 10px;
      width: 34px;
      height: 33px;
      border: 1px solid $color-light-gray;
    }
  }
  .discover_more__product_brief__desc1 {
    padding-#{$ldirection}: 0;
    padding-#{$rdirection}: 0;
    font-size: 11px;
    margin-bottom: 7px;
    margin-top: 52px;
  }
}

.spp-discover-more-section-mobile {
  width: 100%;
  text-align: center;
  clear: both;
  display: inline-block;
  color: $color-navy;
  margin-bottom: 40px;
  h4.spp-product__section-header {
    text-align: center;
    font-family: $akzidenz;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: $color-navy;
    text-transform: uppercase;
    @include swap_direction(margin, 10px 0 20px);
  }
  .discover-more__mobile-slider {
    margin-#{$ldirection}: 25px;
    margin-#{$rdirection}: 25px;
    display: inline-block;
  }
  .discover_more__product {
    width: 45%;
    margin-#{$ldirection}: 5%;
    margin-#{$rdirection}: 0;
    float: $ldirection;
    padding-bottom: 60px;
    position: relative;
    &:first-child {
      margin-#{$rdirection}: 5%;
      margin-#{$ldirection}: 0;
    }
    .discover_more__product-header {
      font-size: 22px;
      letter-spacing: 0;
      color: $color-navy;
      font-family: $optimalight;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .discover_more__product-sub-header {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0;
      color: $color-navy;
      font-family: $optimalight;
    }
    .discover_more__product-price {
      font-family: $akzidenz;
      font-size: 12px;
      font-weight: bold;
      #{$estee_edit} & {
        margin-top: 7px;
        line-height: normal;
        font-family: $ffmarkweb-medium;
        letter-spacing: 0;
        font-size: 15px;
      }
    }
    .discover_more__product-image-container {
      margin-bottom: 15px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .discover_more__product-shopnow {
      width: 100%;
      @include swap_direction(padding, 0);
      height: 46px;
      line-height: 46px;
      @include swap_direction(margin, 10px 0);
      position: absolute;
      bottom: 0;
      #{$ldirection}: 0;
    }
  }
  .product-brief__headers__container {
    #{$estee_edit} & {
      min-height: 88px;
      line-height: normal;
    }
  }
  .product_brief__misc-flag {
    #{$estee_edit} & {
      font-family: $ffmarkweb;
      margin-bottom: 3px;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 0;
    }
  }
  .discover_more__product-header {
    #{$estee_edit} & {
      font-family: $ffmarkweb-medium;
      text-transform: uppercase;
      font-size: 15px;
      color: $color-black;
      letter-spacing: 0;
      font-weight: normal;
      line-height: normal;
      margin: 0;
      min-height: 30px;
    }
  }
  .discover_more__product-sub-header {
    #{$estee_edit} & {
      font-family: $ffmarkweb-medium;
      text-transform: uppercase;
      font-size: 11px;
      color: $color-black;
      letter-spacing: 0;
      font-weight: normal;
      line-height: 11px;
    }
  }
  .discover_more__price_review__container {
    #{$estee_edit} & {
      min-height: 35px;
      margin-top: 7px;
    }
  }
  .discover_more__product-reviews {
    #{$estee_edit} & {
      line-height: normal;
    }
  }
}
// Estee Edit video
.colorbox__estee-edit {
  .device-mobile & {
    &#colorbox {
      background: rgba(0, 18, 201, 0.75); // estee bright blue with opacity
    }
    background: none;
    #cboxContent {
      @include opacity(1);
      background: none;
    }
    .cboxIframe {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      margin: 0 auto;
      @media #{$small-only} {
        @media #{$portrait} {
          width: 100%;
          height: 50%;
        }
      }
      @media #{$iphone6-landscape} {
        width: 100%;
        height: 100%;
      }
    }
  }
}
