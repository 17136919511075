.product_brief__misc-flag-spacer {
  display: none !important;
} /* required for product names to be aligned after filtering */

#mpp__filter-tab {
  color: #333;
  cursor: pointer;
  text-transform: uppercase;
  position: absolute;
  #{$ldirection}: 0;
  top: 15px;
  font-size: 12px;
  font-family: 'AkzidenzGrotesk W1G';
  letter-spacing: 0.15em;
  .minus {
    position: relative;
    top: 2px;
    #{$ldirection}: 5px;
    display: none;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-image: url('/media/images/background-images/background-image54.png');
  }
  .plus {
    position: relative;
    top: 2px;
    #{$ldirection}: 5px;
    display: inline-block;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-image: url('/media/images/background-images/background-image55.png');
  }
}

#mpp__filter {
  text-align: center;
  background-color: #fff;
  color: #333;
  font-family: 'AkzidenzGrotesk W1G';
  letter-spacing: 1.25px;
  width: 100%;
  position: relative;
  display: none;
  @include swap_direction(padding, 10px 0 50px 0);
  top: 0;
  .mpp__filter-column {
    h2 {
      text-transform: uppercase;
      font-family: 'AkzidenzGrotesk W1G';
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 1.25px;
      @include swap_direction(margin, 5px 0 10px);
    }
  }
  .mpp__filter-section {
    @include swap_direction(margin, auto auto auto 5px);
    text-align: $ldirection;
    float: $ldirection;
    &.three-columns {
      width: 27%;
      margin-#{$rdirection}: 5%;
    }
    &:last-child {
      margin-#{$rdirection}: auto;
    }
    &.four-columns {
      width: 22%;
    }
    &.double-three-columns {
      width: 54%;
    }
    &.double-four-columns {
      width: 37%;
    }
    ul {
      float: $ldirection;
      &.three-columns {
        width: 47%;
        margin-#{$rdirection}: 5%;
      }
      &:last-child {
        margin-#{$rdirection}: auto;
      }
      &.four-columns {
        width: 51%;
      }
    }
  }
  li.mpp__filter-item {
    color: #a4a4ab;
    cursor: pointer;
    /* padding: 10px 20px; */
    display: inline-block;
    width: 200px;
    height: 30px;
    &.isie {
      width: 285px !important;
    }
    a {
      color: #a4a4ab;
      font-size: 12px;
      text-transform: uppercase;
      outline: 0; /* removing dotted outline */
      &.active {
        color: #040a2b;
      }
    }
    &.zero-results {
      cursor: default;
      a {
        cursor: default;
        color: #dcdcdc;
      }
      .filter-item-checkbox {
        /* visibility: hidden; */
        opacity: 0.3;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=30)';
      }
    }
    .filter-item-checkbox {
      text-align: $ldirection;
      height: 10px;
      width: 10px;
      @include swap_direction(border, 1px solid $color-medium-gray);
      display: inline-block;
      position: relative;
      top: 1px;
      margin-#{$rdirection}: 5px;
      &.active {
        border-color: #040a2b;
        background-image: url('/media/images/background-images/background-image56.png');
        background-position: -2px -2px;
      }
    }
  }
  .clear {
    float: none;
    clear: both;
  }
}

#mpp__filter-footer {
  position: relative;
  #{$rdirection}: 75px;
  .item-count-container {
    float: $rdirection;
    margin-top: 10px;
    .js-product {
      display: none;
    }
  }
  .clear-all {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: #9b9daa;
    font-size: 12px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    @include swap_direction(padding, 12px 25px);
    @include swap_direction(border, 1px solid #9da2b3);
    float: $rdirection;
    cursor: pointer;
    display: inline-block;
    margin-#{$ldirection}: 20px;
    &.filter-selected {
      @include swap_direction(border, 1px solid $color-navy);
      color: #040a2b;
    }
    &.hover {
      @include swap_direction(border, 1px solid $color-navy);
      background: #040a2b;
      color: #fff;
    }
  }
}
