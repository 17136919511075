.special-offer-row-promo-wrapper {
  @include swap_direction(margin, 20px auto);
  max-width: 1366px;
  width: 100%;
  &.special-offer-theme-holiday {
    .special-offer-eyebrow {
      font-family: $linotype-didot-italic;
    }
    .special-offer-headline {
      font-family: $linotype-didot-roman;
    }
    .special-offer-copy-block {
      font-family: $akzidenz;
    }
  }
  &.special-offer-theme-regular {
    .special-offer-eyebrow {
      font-family: $akzidenz;
    }
    .special-offer-headline {
      font-family: $optimalight;
    }
    .special-offer-copy-block {
      font-family: $akzidenz;
    }
  }
  .special-offer-row-promo {
    display: inline-block;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    width: 100%;
    text-align: center;
    .special-offer-promo-text-wrapper {
      display: inline-block;
      padding-bottom: 40px;
      border-bottom: 1px solid $color-navy;
      cursor: pointer;
      .special-offer-eyebrow {
        font-size: 12px;
        color: $color-navy;
        font-family: $akzidenz;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .special-offer-headline {
        font-size: 70px;
        line-height: 60px;
        color: $color-navy;
      }
      .special-offer-copy-block {
        font-size: 15px;
        margin-top: 15px;
        color: $color-navy;
      }
      .special-offer-button,
      .special-reward-button,
      .special-offer-button-popup {
        font-size: 12px;
        font-family: $akzidenz;
        text-decoration: underline;
        font-weight: bold;
        color: $color-navy;
        display: inline-block;
        margin-top: 30px;
      }
    }
  }
}

.special-offer-row-wrapper {
  &.special-offer-theme-holiday {
    .special-offer-eyebrow {
      font-family: $linotype-didot-italic;
    }
    .special-offer-headline {
      font-family: $linotype-didot-roman;
    }
    .special-offer-copy-block {
      font-family: $akzidenz;
    }
  }
  &.special-offer-theme-regular {
    .special-offer-eyebrow {
      font-family: $akzidenz;
    }
    .special-offer-headline {
      font-family: $optimalight;
    }
    .special-offer-copy-block {
      font-family: $akzidenz;
    }
  }
  @include swap_direction(margin, 20px auto);
  max-width: 1366px;
  width: 100%;
  .special-offer-row {
    display: inline-block;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    width: 100%;
    position: relative;
    .special-offer-image {
      width: 40%;
      position: relative;
      cursor: pointer;
      &.offer-image-left {
        float: $ldirection;
      }
      &.offer-image-right {
        float: $rdirection;
      }
    }
  }
  .special-offer-text-wrapper {
    position: absolute;
    width: 100%;
    top: 17%;
    @media #{$medium-only} {
      top: 0;
    }
    .special-offer-eyebrow {
      font-size: 28px;
      color: $color-navy;
    }
    .special-offer-headline {
      font-size: 70px;
      line-height: 60px;
      color: $color-navy;
    }
    .special-offer-copy-block-wrapper {
      margin-top: 10px;
      .special-offer-copy-block {
        font-size: 15px;
        margin-bottom: 20px;
        color: $color-navy;
      }
      .special-offer-button {
        font-size: 12px;
        font-family: $akzidenz;
        text-decoration: underline;
        font-weight: bold;
        color: $color-navy;
      }
    }
  }
}

.special-offer-row-mobile {
  max-width: 320px;
  @include swap_direction(margin, 0 auto);
  margin-top: 50px;
  margin-bottom: 40px;
  &.special-offer-theme-holiday {
    .special-offer-eyebrow {
      font-family: $akzidenz;
    }
    .special-offer-headline {
      font-family: $linotype-didot-roman;
    }
    .special-offer-copy {
      font-family: $akzidenz;
    }
  }
  &.special-offer-theme-regular {
    .special-offer-eyebrow {
      font-family: $akzidenz;
    }
    .special-offer-headline {
      font-family: $optimalight;
      letter-spacing: -3px;
    }
    .special-offer-copy {
      font-family: $akzidenz;
    }
  }
  .top-border {
    display: none;
  }
  &.top-border-no {
    .top-border {
      display: none;
    }
  }
  &.top-border-yes {
    margin-top: -50px;
    hr.top-border {
      display: block;
      margin-#{$ldirection}: 15%;
      margin-#{$rdirection}: 15%;
      margin-bottom: 30px;
      height: 0;
      @include swap_direction(border, 0);
      border-top: 1px solid #040a2b;
    }
  }
  .special-offer-header {
    position: relative;
    z-index: 3;
    margin-bottom: -20px;
    text-align: center;
    .special-offer-eyebrow {
      font-size: 12px;
      font-style: normal;
      font-weight: bold;
      color: $color-navy;
      letter-spacing: 0.15em;
      padding-bottom: 5px;
    }
    .special-offer-headline {
      font-size: 47px;
      margin-top: 7px;
      color: $color-navy;
    }
  }
  .special-offer-image-wrapper {
    text-align: center;
    &.special-offer-image-size-large {
      width: 100%;
      img {
        width: 100%;
      }
    }
    &.special-offer-image-size-small {
      width: 100%;
      img {
        width: 226px;
      }
    }
  }
  .special-offer-content {
    text-align: center;
    margin-top: 40px;
    a.special-offer-button-mobile-popup {
      font-size: 12px;
      font-weight: bold;
      text-decoration: underline;
      color: #040a2b;
      letter-spacing: 0.15em;
      margin-bottom: 85px;
      display: block;
    }
    p.special-offer-copy {
      color: $color-navy;
      font-size: 12px;
      line-height: 18px;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .special-offer-button-wrapper {
      width: 100%;
      text-align: center;
      @include swap_direction(padding, 0 25px);
      a.special-offer-button {
        @include button--dark;
        width: 100%;
        font-size: 12px;
      }
    }
  }
}

.device-mobile.section-promotions {
  #colorbox {
    div#cboxLoadedContent {
      width: 100% !important;
    }
  }
}

h3.formatter-123__title-2 {
  @media #{$medium-only} {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}
