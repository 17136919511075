%loyalty-title {
  font-family: $optimalight;
  letter-spacing: 0.01em;
  font-size: 20px;
  line-height: 24px;
  @media #{$medium-up} {
    font-size: 30px;
    line-height: 38px;
  }
}

%loyalty-panel-title {
  @extend %loyalty-title;
  padding-bottom: 10px;
  @include swap_direction(margin, 0 0 10px (-$mobile-outer-gutter));
  text-indent: $mobile-outer-gutter;
  width: 120%;
  border-bottom: 1px solid $color-light-gray;
  @media #{$medium-up} {
    @include swap_direction(margin, 0 0 10px 0);
    text-indent: 0;
    width: 100%;
  }
}

%loyalty-panel-title-remove {
  text-align: initial;
  text-transform: none;
  font-weight: normal;
  @include swap_direction(padding, 0);
  margin-bottom: 0;
  @media #{$medium-up} {
    @include swap_direction(border, 0);
  }
}

// NAVIGATION EXAMPLE
#loyalty__navigation {
  .profile-pic-main {
    display: none;
  }
  /*
  @media #{$medium-up} {
    .account-utilities {
      float: left;
      width: 25%;
      padding-#{$rdirection}: 20px;
    }
  }
  */
}
/*
@media #{$medium-up} {
  #loyalty__content {
    padding-#{$ldirection}: 25%;
  }
}
*/
// END NAVIGATION EXAMPLE

#loyalty__panel__test {
  @extend %loyalty-panel;
}

.loyalty-account-page {
  @media #{$small-only} {
    @include swap_direction(padding, 0 0 35px 0);
    .sidebar-page__content {
      padding-bottom: 0;
    }
  }
  .account-page__header--loyalty {
    line-height: 61px;
    @include swap_direction(padding, 30px 0 26px 0);
    text-align: center;
    .loyalty-account__header-image {
      display: inline-block;
    }
  }
}

.loyalty__panel__loader,
%loyalty__panel__loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(/media/images/account/loyalty/ajax-loader.gif) no-repeat center center $color-white;
  z-index: 10;
}

.loyalty__content-container {
  @include swap_direction(margin, 0);
  @media #{$medium-up} {
    @include swap_direction(margin, 0);
  }
}

.loyalty__page__index__breadcrumb {
  display: block;
  @include swap_direction(padding, 30px 0 90px 0);
  @media #{$medium-up} {
    display: none;
  }
}

#loyalty__page__index {
  padding-bottom: 20px;
  @media #{$medium-up} {
    padding-bottom: 0;
  }
  .loyalty-landing-menu {
    display: block;
    border-bottom: 1px solid $color-light-gray;
    margin-top: 22px;
    @media #{$medium-up} {
      display: none;
    }
    .loyalty-landing-menu__link {
      @include text--short--plain;
      @include print-size-tracking(22px);
      font-family: $optimaregular;
      text-transform: none;
      display: block;
      @include swap_direction(padding, 15px 25px);
      border-top: 1px solid $color-light-gray;
      background-size: auto auto;
      background-position: 100% -499px;
    }
  }

  // slick carousel for offers
  .slick-prev {
    //@include estee-sprites("smoosh_next_white_at2x");
    width: 16px;
    #{$ldirection}: 20px;
    top: 30%;
  }
  .slick-next {
    background-size: auto auto;
    background-position: -14px -8600px;
    width: 16px;
    #{$rdirection}: 20px;
    top: 30%;
  }
}
