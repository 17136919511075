.ytPlayer {
  display: none;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 997;
  text-align: center;
  overflow: hidden;
  &.ytPlayer__inline {
    position: absolute;
    z-index: 99;
  }
  @media only screen and (max-width: 1040px) {
    height: 100.1%;
  }
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 1;
  }
  &__foreground {
    margin: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0px;
    left: 0;
    min-width: 1024px;
    @media only screen and (max-width: 1040px) {
      min-width: 0;
    }
    .ytPlayer__inline & {
      min-width: 0;
    }
  }
  &__img {
    position: absolute;
    width: 6%;
    max-width: 36px;
    top: 0.5%;
    right: 0.5%;
    z-index: 996;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    -webkit-transition: all 400ms ease;
    opacity: 1;
    @media screen and (max-width: 768px) {
      top: 2%;
    }
    img {
      vertical-align: inherit;
      z-index: 996;
    }
    &:hover {
      opacity: 0.5;
    }
    body.ie & {
      top: -3.5%;
    }
    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
  &__container {
    margin: auto;
    width: 100%;
    height: 85%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__controls {
    position: absolute;
    bottom: 12.5%;
    width: 100%;
    height: 0;
    .device-mobile & {
      display: none;
    }
  }
  &__controls-overlay {
    bottom: 8%;
  }
  &__controlsWrapper {
    position: absolute;
    top: 91%;
    left: 6%;
    right: 6%;
    margin: auto;
    width: 86.4%;
    height: 20px;
    padding-top: 20px;
    max-width: 1124px;
  }
  &__btn {
    background: transparent no-repeat left top;
    display: inline-block;
    cursor: pointer;
    border: 0px;
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    border-radius: 0;
  }
  &__btn-play {
    margin-top: 6px;
    width: 25px;
    height: 25px;
    background-image: url(/media/boutiques/common/video_play_white.png);
    background-position: 50% 50%;
  }
  &__btn-play.active {
    background-image: url(/media/boutiques/common/video_pause_white.png);
  }
  &__btn-full {
    background-image: url(/media/boutiques/common/video_full-screen_white.png);
    margin-top: 20px;
    width: 25px;
    height: 25px;
    margin-left: 20px;
    @media only screen and (max-width: 980px) {
      margin-left: 0;
      @media screen and (max-width: 768px) {
        margin-left: 1.5vw;
      }
    }
  }
  &__scrubber {
    margin-top: 12px;
    height: 1px;
    border-top: 1px solid #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    width: 90%;
    display: inline-block;
    @media screen and (max-width: 768px) {
      width: 80%;
    }
    &-inner {
      width: 0px;
      height: 1px;
      background-color: #fff;
    }
  }
}
