.chat-reviews-page {
  .BVDI_QT {
    margin-top: -13px;
    padding-top: 0;
  }
  .spp-product__reviews {
    margin-top: -45px;
  }
}

.beauty-advisor-review {
  color: #040a2b;
  font-family: 'OptimaDisplayLight';
  margin-botom: 40px;
  text-align: center;
  .beauty-advisor-header {
    font-family: 'OptimaDisplayLight';
    font-size: 128px;
    margin-top: 10px;
  }
  .beauty-advisor-stars-container {
    .beauty-advisor-stars-text {
      font-family: 'AkzidenzGrotesk W1G';
      font-size: 13px;
      font-weight: bold;
      line-height: 20px;
      margin-top: -16px;
      text-transform: uppercase;
    }
  }
  .beauty-advisor-body {
    font-size: 30px;
    margin: 10px auto 0 auto;
    max-width: 960px;
    br.mobile-only {
      display: none;
    }
  }
  .beauty-advisor-footer {
    border-bottom: 1px solid #ccc;
    margin: 25px auto 40px auto;
    padding-bottom: 30px;
    width: 310px;
  }
  .beauty-advisor-small-header {
    font-family: 'AkzidenzGrotesk W1G';
    font-size: 12px;
    font-weight: bold;
    margin-top: 30px;
    text-transform: uppercase;
  }
  .beauty-advisor-text {
    font-family: 'AkzidenzGrotesk W1G';
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
  }
}

@media (max-width: 767px) {
  .chat-reviews-page {
    .spp-product__reviews {
      margin-left: 25px;
      margin-right: 25px;
      #BVRRDisplayContentID.BVRRDisplayContent {
        overflow-x: hidden;
      }
      .BVRRSortAndSearch {
        display: none !important;
      }
      .BVSearchResults {
        width: auto;
      }
    }
  }
  .beauty-advisor-review {
    padding: 0 10px;
    .beauty-advisor-header {
      font-size: 45pt;
      letter-spacing: -5px;
      margin-top: 10px;
    }
    .beauty-advisor-stars-container {
      margin: auto;
      padding: 0 10px;
      width: 90%;
      .beauty-advisor-stars-text {
        font-size: 11px;
        line-height: 20px;
      }
      img {
        max-width: 100%;
      }
    }
    .beauty-advisor-body {
      font-size: 7vw;
      letter-spacing: -1px;
      line-height: 4vh;
      max-width: 365px;
      margin-top: 13px;
      br.mobile-only {
        display: inline;
      }
    }
    .beauty-advisor-small-header {
      font-size: 18px;
      margin-top: 0;
    }
    .beauty-advisor-text {
      font-size: 4vw;
      line-height: 24px;
      margin-bottom: 5px;
    }
    .beauty-advisor-footer {
      border: 0;
      margin-top: 25px;
      width: auto;
      .beauty-advisor-footer-top {
        width: 340px;
        margin-left: auto;
        margin-right: auto;
      }
      a.button {
        display: block;
        margin-bottom: 25px;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
}

.beauty-advisor-chat-live {
  color: #040a2b;
  font-family: 'OptimaDisplayLight';
  margin-botom: 40px;
  text-align: center;
  .beauty-advisor-chat-live-header {
    font-family: 'OptimaDisplayLight';
    font-size: 128px;
    margin-top: 45px;
  }
  .beauty-advisor-chat-live-stars-container {
    margin-top: 15px;
    .beauty-advisor-chat-live-stars-text {
      font-family: 'AkzidenzGrotesk W1G';
      font-size: 13px;
      font-weight: bold;
      line-height: 20px;
      margin-top: -16px;
      text-transform: uppercase;
    }
  }
  .beauty-advisor-chat-live-description {
    font-family: 'AkzidenzGrotesk W1G';
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
  }
  a.button {
    margin: 30px 0;
  }
}

@media (max-width: 767px) {
  .beauty-advisor-chat-live {
    padding: 0 25px;
    .beauty-advisor-chat-live-header {
      font-size: 84px;
    }
    .beauty-advisor-chat-live-stars-container {
      margin: auto;
      width: 70%;
      img {
        max-width: 100%;
      }
    }
  }
}
