@import '../../../estee_base/scss/theme-bootstrap';

.elc-service-order-tracking {
  .elc-grid-column {
    a.elc-order-tracking-see-delivery-results {
      @include button--light-navy-border;
      display: inline-block;
      padding: 10px;
    }
  }
}
