///
// Header items
//
// Shared styling for header items
///
%utility-item-button-base,
.utility-item-button-base {
  vertical-align: top;
  height: $header-item-height;
  line-height: $header-item-height;
}
%utility-item-button,
.utility-item-button {
  @extend %utility-item-button-base;
  padding: 0 8px;
  @media #{$medium-up} {
    padding: 0 16px;
  }
  @media #{$xlarge-up} {
    border-#{$ldirection}: 1px solid $color-light-gray;
    padding: 0 20px;
  }
}
%utility-item-text,
.utility-item-text {
  @include text--short--plain;
  color: $color-dark-navy;

  #{$aerin} & {
    color: $color-light-gray--aerin;
  }
  #{$renutriv} &,
  #{$dark-nav} & {
    color: $color-white;
  }

  #{$alt} &,
  #{$dark-nav} {
    color: $color-white;
    @media #{$small-only} {
      color: $color-dark-navy;
    }
    #{$aerin} &,
    #{$estee-edit} & {
      color: $color-white;
      @media #{$small-only} {
        color: $color-dark-navy;
      }
    }
  }
}

@mixin utility-nav-default-search-icon {
  @include svg-icon-inline-mask('search.svg');
  background-color: $color-black;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

///
// Utilties
///
.page-utilities {
  float: $rdirection;
  &__search-button {
    @media #{$medium-up} {
      position: relative;
      #{$rdirection}: 64px;
    }
  }
  &__cart {
    @media #{$medium-up} {
      position: relative;
      #{$ldirection}: 24px;
      &-button {
        padding-#{$ldirection}: 16px;
      }
    }
    @media #{$xlarge-up} {
      #{$ldirection}: 50px;
    }
  }
}

.page-utilities-elc-nodeblock {
  float: $ldirection;
}

.page-utilities__item {
  float: $ldirection;
  vertical-align: bottom;
}

// help
.page-utilities__help {
  @media #{$small-only} {
    display: none; // because comps?
  }
  @media #{$xlarge-up} {
    display: none;
  }
  // hide on cart index.tmpl
  #{$min-nav} &,
  .elc-gnav-minimal & {
    display: none;
  }
}

.page-utilities__help-button {
  @extend %utility-item-button;
  border-#{$ldirection}: none;
}
%page-utilities__help-text,
.page-utilities__help-text {
  @extend %utility-item-text;
  display: block;
  #{$aerin} & {
    color: $color-light-gray--aerin;
  }
}

// account
.page-utilities__account {
  @media #{$large-up} {
    position: relative;
  }
  @media #{$small-only} {
    .user-loyalty-state,
    .user-login-state {
      display: none;
    }
  }
  // hide on cart index.tmpl
  #{$min-nav} &,
  .elc-gnav-minimal & {
    display: none;
  }
  &.page-utilities__loyalty {
    @media #{$small-only} {
      .user-login-state {
        display: block;
      }
    }
  }
}

.page-utilities__account-button {
  @extend %utility-item-button;
  border-#{$ldirection}: none;
  @media #{$small-only} {
    padding: 0;
  }
}

.page-utilities__account-icon {
  display: none;
}

.page-utilities__account-text {
  @extend %utility-item-text;
  display: none;
  @media #{$small-only} {
    letter-spacing: 0;
    font-size: 11px;
    @include swap_direction(padding-top, 2px);
  }
  .elc-user-state-logged-in & {
    display: block;
  }
  .user-greeting {
    .elc-user-state-logged-in & {
      @media #{$small-only} {
        display: none;
      }
    }
  }
  .user-greeting-short {
    display: none;
    .elc-user-state-logged-in & {
      @media #{$small-only} {
        display: inline-block;
        text-transform: uppercase;
      }
    }
    #{$renutriv} & {
      @media #{$small-only} {
        color: $color-white;
      }
    }
  }
}

.page-utilities__signin-text {
  @extend %utility-item-text;
  display: block;
  @media #{$small-only} {
    letter-spacing: 0;
    font-size: 11px;
    @include swap_direction(padding-top, 2px);
  }
  .elc-user-state-logged-in & {
    display: none;
  }
}

.user-login-state {
  @media #{$medium-up} {
    display: block;
    position: absolute;
    font-size: 12px;
    font-weight: normal;
    text-align: $rdirection;
    #{$rdirection}: 20px;
    bottom: 0;
    min-width: 375px;
    .user-logged-out,
    .user-logged-in {
      display: none;
      a {
        margin-#{$ldirection}: 7px;
        color: $color-darker-gray;
        font-weight: normal;
      }
    }
  }
  @media #{$medium-only} {
    #{$rdirection}: 100px;
  }
  @media #{$large-only} {
    #{$rdirection}: 10px;
  }
  .user-greeting {
    @media #{$small-only} {
      display: none;
    }
  }
  .user-greeting-short {
    @media #{$small-only} {
      display: inline-block;
      text-transform: uppercase;
    }
    #{$renutriv} & {
      color: $color-white;
    }
  }
}

// loyalty
.loyalty_mrkt_ldng {
  .js-sign-in-popup {
    display: none;
  }
}

.field-mobile-template {
  .loyalty_market {
    .loyalty_market__btn__signin {
      display: none;
    }
  }
}

// cart
.page-utilities__cart {
  position: relative;
  // show cart mobile-only during checkout index.tmpl
  #{$min-nav} &,
  .elc-gnav-minimal & {
    @media #{$large-up} {
      display: none;
    }
  }
}

.page-utilities__cart-button {
  @extend %utility-item-button;
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  z-index: 10;
  cursor: pointer;
  @media #{$small-only} {
    position: relative;
    border-#{$ldirection}: none;
  }
  #{$aerin} & {
    border-color: $color-header-border-aerin;
  }
  #{$alt} & {
    // when menu is open
    #{$aerin} & {
      border-color: rgba($color-periwinkle-blue, 0.5);
    }
  }
}

%default-cart-icon {
  background: url($svg-path + 'cart.svg') no-repeat;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  @media #{$small-only} {
    background-image: url($base-theme-image-path + 'sprites/mob_gnav_bag_navy.png');
  }
}

.no-backgroundsize .page-utilities__cart-icon,
.no-backgroundsize .brand-aerin .is-alternate .page-utilities__cart-icon,
.brand-aerin .is-alternate .no-backgroundsize .page-utilities__cart-icon,
.no-backgroundsize .brand-renutriv .is-alternate .page-utilities__cart-icon,
.brand-renutriv .is-alternate .no-backgroundsize .page-utilities__cart-icon,
.no-backgroundsize .brand-aerin .is-dark-nav .page-utilities__cart-icon,
.brand-aerin .is-dark-nav .no-backgroundsize .page-utilities__cart-icon,
.no-backgroundsize .brand-renutriv .is-dark-nav .page-utilities__cart-icon,
.brand-renutriv .is-dark-nav .no-backgroundsize .page-utilities__cart-icon {
  background: url($svg-path + 'cart.svg') no-repeat;
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 736px) and (max-width: 736px) {
  .page-utilities__cart-icon,
  .brand-aerin .is-alternate .page-utilities__cart-icon,
  .brand-renutriv .is-alternate .page-utilities__cart-icon,
  .brand-aerin .is-dark-nav .page-utilities__cart-icon,
  .brand-renutriv .is-dark-nav .page-utilities__cart-icon {
    background-size: 21px 22px;
    width: 21px;
    height: 22px;
  }
}

@media only screen and (max-width: 736px) {
  .no-backgroundsize .page-utilities__cart-icon,
  .no-backgroundsize .brand-aerin .is-alternate .page-utilities__cart-icon,
  .brand-aerin .is-alternate .no-backgroundsize .page-utilities__cart-icon,
  .no-backgroundsize .brand-renutriv .is-alternate .page-utilities__cart-icon,
  .brand-renutriv .is-alternate .no-backgroundsize .page-utilities__cart-icon,
  .no-backgroundsize .brand-aerin .is-dark-nav .page-utilities__cart-icon,
  .brand-aerin .is-dark-nav .no-backgroundsize .page-utilities__cart-icon,
  .no-backgroundsize .brand-renutriv .is-dark-nav .page-utilities__cart-icon,
  .brand-renutriv .is-dark-nav .no-backgroundsize .page-utilities__cart-icon {
    background-image: url($base-theme-image-path + 'sprites/mob_gnav_bag_navy.png');
    width: 126px;
    height: 132px;
  }
}

.page-utilities__cart-icon {
  @extend %default-cart-icon;
  background-position: 0 0;
  display: block;
}

.no-backgroundsize .brand-aerin .page-utilities__cart-icon {
  background-image: url($base-theme-image-path + 'sprites/cart_gray.png');
  width: 24px;
  height: 22px;
}

.no-backgroundsize .brand-renutriv .page-utilities__cart-icon {
  background-image: url($base-theme-image-path + 'sprites/cart_white.png');
  width: 24px;
  height: 22px;
}

@media only screen and (max-width: 736px) and (max-width: 736px) {
  .brand-aerin .page-utilities__cart-icon {
    background-size: 21px 22px;
    width: 21px;
    height: 22px;
  }
  .brand-renutriv .page-utilities__cart-icon {
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
  }
}

@media only screen and (max-width: 736px) {
  .no-backgroundsize .brand-aerin .page-utilities__cart-icon {
    background-image: url($base-theme-image-path + 'sprites/mob_gnav_bag_aerin.png');
    width: 126px;
    height: 132px;
  }
  .no-backgroundsize .brand-renutriv .page-utilities__cart-icon {
    background-image: url($base-theme-image-path + 'sprites/mob_gnav_bag_white.png');
    width: 144px;
    height: 144px;
  }
}

@media only screen and (min-width: 737px) {
  .no-backgroundsize .is-alternate .page-utilities__cart-icon,
  .no-backgroundsize .brand-renutriv .is-alternate .page-utilities__cart-icon,
  .no-backgroundsize .brand-aerin .is-alternate .page-utilities__cart-icon,
  .no-backgroundsize .is-dark-nav .page-utilities__cart-icon,
  .no-backgroundsize .brand-renutriv .is-dark-nav .page-utilities__cart-icon,
  .no-backgroundsize .brand-aerin .is-dark-nav .page-utilities__cart-icon {
    background-image: url($base-theme-image-path + 'sprites/cart_white.png');
    width: 24px;
    height: 22px;
  }
}

@media only screen and (min-width: 737px) and (max-width: 736px) and (max-width: 736px) {
  .is-alternate .page-utilities__cart-icon,
  .brand-renutriv .is-alternate .page-utilities__cart-icon,
  .brand-aerin .is-alternate .page-utilities__cart-icon,
  .is-dark-nav .page-utilities__cart-icon,
  .brand-renutriv .is-dark-nav .page-utilities__cart-icon,
  .brand-aerin .is-dark-nav .page-utilities__cart-icon {
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
  }
}

@media only screen and (min-width: 737px) and (max-width: 736px) {
  .no-backgroundsize .is-alternate .page-utilities__cart-icon,
  .no-backgroundsize .brand-renutriv .is-alternate .page-utilities__cart-icon,
  .no-backgroundsize .brand-aerin .is-alternate .page-utilities__cart-icon,
  .no-backgroundsize .is-dark-nav .page-utilities__cart-icon,
  .no-backgroundsize .brand-renutriv .is-dark-nav .page-utilities__cart-icon,
  .no-backgroundsize .brand-aerin .is-dark-nav .page-utilities__cart-icon {
    background-image: url($base-theme-image-path + 'sprites/mob_gnav_bag_white.png');
    width: 144px;
    height: 144px;
  }
}

.page-utilities__cart-text {
  @extend %utility-item-text;
  display: none;
}

.page-utilities__cart-count {
  @extend %utility-item-button-base;
  @include swap_direction(padding, 0 0 0 40px);
  width: 0;
  text-align: $rdirection;
  font-size: 11px;
  cursor: pointer;
  @media #{$xlarge-up} {
    border-#{$ldirection}: 1px solid $color-light-gray;
    @include swap_direction(padding, 0 6px 0 46px);
  }
  @media #{$small-only} {
    text-align: center;
    @include swap_direction(padding, 2px 2px 0 2px);
    @include min-width(5px);
    position: absolute;
    width: 100%;
    top: 0;
    #{$ldirection}: 0;
  }
}

// search
.page-utilities__search {
  @media #{$small-only} {
    display: none;
  }
  // hide on checkout index.tmpl
  #{$min-nav} &,
  .elc-gnav-minimal & {
    display: none;
  }
}

.page-utilities__search-button {
  @extend %utility-item-button;
  border-#{$ldirection}: 1px solid $color-light-gray;
  @media #{$small-only} {
    border: none;
    &.active {
      background-color: $color-navy;
      #{$alt-color-white} & {
        background-color: $color-medium-gray-new;
      }
    }
    .elc-gnav-minimal & {
      display: none;
    }
  }
}

.page-utilities__search-icon {
  @include utility-nav-default-search-icon;
  display: block;
}
