.product-thumb {
  @include clearfix;
  max-width: 240px;
  .product-thumb__img-wrapper {
    display: block;
    float: $ldirection;
    margin-#{$rdirection}: 20px;
  }
  .product-thumb__title-price {
    min-height: 70px;
  }
  .product-thumb__title {
    @include index-navigation;
    @include swap_direction(padding, 0 0 3px);
    @include swap_direction(margin, -5px 0 0);
    // Have to override the overly-specific, greedy footer styles:
    a {
      color: $color-white !important;
    }
  }
  .product-thumb__price {
    @include text--bold;
    @include swap_direction(margin, 0 0 5px);
    display: inline;
  }
  .product-thumb__size {
    font-size: 12px;
    display: inline;
  }
  @include swatches(18px, 9px, 6px, 12px, 3px);
  .swatch__container {
    float: $ldirection;
    @include swap_direction(margin, 3px 5px 0 0);
  }
  .product-thumb__shadename {
    font-size: 12px;
  }
}
