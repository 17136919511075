// Estee Edit Brand Styles that don't fit elsewhere. Consider using the method below before placing styles in here:
//
// SCSS:
// .button--dark {
//   background: $color-navy;
//   color: $color-white;
//   #{$renutriv} & { // Renutriv Brand Overrides
//     background: black;
//     border-color: $color-gold--renutriv;
//   }
//   #{$aerin} & { // Aerin Brand Overrides
//     background: $color-periwinkle-blue--aerin;
//   }
// }
//
// This will generate this CSS:
// .button--dark {
//   background: navy;
//   color: white;
// }
// .brand-renutriv .button--dark {
//   background: black;
//   border-color: gold;
// }
// .brand-aerin .button--dark {
//   background: periwinkle;
// }
//

//#{$estee_edit} .header-items {
//background-color: #0130d3;
//}
//#{$estee_edit} .page-header {
//  @media #{$medium-up} {
//    background-color: #0130d3;
//  }
//}
// WORLD OF AERIN LANDING PAGE
$mq-woa-landing-tile-columns: '#{$screen} and (min-width: 802px)';

.woa-landing {
  max-width: 1024px;
  margin: 0 auto;
}

.woa-landing__tile {
  background: $color-muted-gray-aerin;
  margin: 20px auto;
  max-width: 380px;
  @media #{$mq-woa-landing-tile-columns} {
    @include clearfix;
    clear: both;
    max-width: 800px;
    max-height: 224px;
  }
}

.woa-landing-tile__img {
  max-width: 100%;
  @media #{$mq-woa-landing-tile-columns} {
    max-width: 50%;
    float: left;
  }
}

.woa-landing__text {
  padding: 30px 28px;
  @media #{$mq-woa-landing-tile-columns} {
    width: 50%;
    float: right;
  }
  h4 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .aerin-block-link {
    display: inline-block;
    background: $color-muted-teal-aerin;
    padding: 10px 2em;
    color: white;
    text-decoration: none;
    margin-top: 1em;
  }
}

// BLOG
.formatter-woa {
  .header-meta {
    @extend %content-width-limit;
  }
  .woa-blog__header-headline {
    font-size: 30px;
  }
  .woa-blog__header-subhead {
    font-size: 18px;
  }
}

.woa-blog__content {
  max-width: $estee-edit-content-max-width;
  margin: 0 auto;
  .basic-textarea-v1 {
    @extend %content-width-limit;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  blockquote {
    @extend %content-width-limit;
  }
  .ee-disco-more__label {
    font-weight: normal;
    font-family: 'FuturaStd-Light';
  }
}

// ESTEE EDIT CUSTOM MPP TEMPLATE
.custom-mpp-estee-edit-tem-wrapper {
  &.primary {
    .parallaxfx__container {
      margin: 0 auto 100px auto;
      max-width: 1920px;
      position: absolute;
      text-align: center;
      background-repeat: no-repeat;
      background-position: 50% 82px;
      background-attachment: fixed;
      -webkit-transform-style: preserve-3d;
      z-index: -1;
      width: 100%;
      top: 0;
    }
    .parallaxfx__slider {
      width: 100%;
      position: absolute;
      top: 0;
      background-repeat: no-repeat;
      background-position-x: center;
      margin: 0 auto;
    }
    .parallax__ee-window {
      position: relative;
    }
    .parallax__ee {
      //smoothing out parallax for tablet
      &.static-parallax-touch-device {
        //position: fixed;
        //position: absolute;
        //z-index: -1;
        -moz-transition: all 0ms ease-in-out;
        -webkit-transition: all 0ms ease-in-out;
        -o-transition: all 0ms ease-in-out;
        transition: all 0ms ease-in-out;
        -moz-transform: translate3d(0px, 0px, 0px);
        -webkit-transform: translate3d(0px, 0px, 0px);
        -o-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        transform: translate3d(0px, 0, 0px);
      }
      &.static-parallax {
        @media #{$xlarge-up} {
          background-attachment: fixed;
        }
      }
      &.expanded-subnav {
        @media #{$xlarge-up} {
          background-attachment: scroll;
          background-position: 50% 0px;
        }
      }
      &.static-parallax:nth-child(1) {
        background-position: 50% 82px;
      }
      margin: 0 auto 100px auto;
      //max-width: 1920px;
      position: absolute;
      text-align: center;
      background-repeat: no-repeat;
      background-position: 50% 0;
      -webkit-transform-style: preserve-3d;
      z-index: -1;
      width: 100%;
      top: 0;
      &.full-width {
        background-size: 100%;
      }
    }
    .parallax__ee-container {
      margin: 0 auto 100px auto;
      //max-width: 1920px;
      position: absolute;
      text-align: center;
      overflow: hidden;
      -webkit-transform-style: preserve-3d;
      //z-index: -1;
      //adjustments for background
      z-index: auto;
      width: 100%;
      top: 0;
    }
  }

  // template typography
  .mpp__header {
    em {
      font-family: $devinne-italic;
      font-style: normal;
    }
    &.h2 {
      border: none;
      font-family: $devinne-roman;
      font-size: 63px;
      text-transform: uppercase;
      @media #{$medium-only} {
        font-size: 47px;
      }
    }
    &.h5 {
      border: none;
      font-family: $devinne-roman;
      font-size: 45px;
      line-height: 0.9em;
      margin: 3px 0 -18px 0;
      text-transform: uppercase;
      @media #{$medium-only} {
        font-size: 55px;
        margin: 8px 0 -18px 0;
      }
    }
    &.h3 {
      border: none;
      font-family: $devinne-roman;
      font-size: 84px;
      line-height: 0.75em;
      text-transform: uppercase;
      @media #{$medium-only} {
        font-size: 68px;
      }
    }
    &.h4 {
      border: none;
      font-family: $devinne-roman;
      font-size: 28px;
      margin: 12px 0;
      text-transform: uppercase;
    }
    &.h6 {
      border: none;
      font-family: $devinne-roman;
      font-size: 20px;
      text-transform: uppercase;
    }
  }
  .mpp__header_secondary {
    &.h4 {
      border: none;
      font-family: $devinne-roman;
      font-size: 20px;
      margin: -19px 0 0 0;
      text-align: center;
    }
  }
}

#cboxOverlay {
  &.colorbox__estee-edit {
    @include opacity(0.8);
    background: $color-white;
  }
  &.colorbox__youtube__estee-edit {
    background: none;
  }
}

.colorbox__estee-edit {
  #cboxContent {
    @include opacity(0.8);
    background: $color-blue--estee_edit;
  }
  .cboxIframe {
    //@include opacity(0.8);
    background: $color-blue--estee_edit;
    text-align: center;
    padding: 40px 0;
    @media #{$medium-up} {
      padding: 40px;
    }
  }
}

.device-mobile {
  #colorbox.colorbox__estee-edit {
    #cboxContent {
      @media #{$small-only} {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

// youtube mobile video overlay
.device-mobile {
  #colorbox.colorbox__youtube__estee-edit {
    background: rgba(0, 18, 201, 0.75); // estee bright blue with opacity
  }
}

.colorbox__youtube__estee-edit {
  .device-mobile & {
    background: none;
    //background: rgba(0, 18, 201, 0.75);
    #cboxContent {
      background: none;
      //#cboxClose {
      //  @media #{$small-only} {
      //    @media #{$portrait} {
      //      top: 30%;
      //    }
      //    @media #{$landscape} {
      //      top: 20%;
      //    }
      //  }
      //}
    }
    .cboxIframe {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      @media #{$small-only} {
        @media #{$portrait} {
          width: 100%;
          height: 33%;
        }
        @media #{$landscape} {
          width: 80%;
          height: 80%;
        }
      }
    }
  }
}

.device-mobile {
  .colorbox__youtube {
    #cboxContent {
      padding: 0px;
      #cboxClose,
      #cboxClose:hover {
        top: 0px;
        #{$rdirection}: 0px;
      }
      @media #{$small-only} {
        @media #{$portrait} {
          background: $color-black;
          #cboxLoadedContent {
            height: 210px !important;
            position: absolute;
            top: 28%;
            .cboxIframe {
              background: $color-black;
            }
          }
        }
        @media #{$landscape} {
          #cboxLoadedContent {
            height: 100% !important;
          }
        }
      }
    }
  }
  a.video-launcher-mobile {
    &:focus {
      outline: none;
    }
  }
}
