///
/// @file components/_button.scss
///
/// \brief Buttons used throughout the site, button is by default dark
///  with light variants
///
.button-reset {
  @include swap_direction(padding, 0);
  @include swap_direction(border, 0);
  background: none;
}

.button {
  @include button;
  #{$aerin} .page-main & {
    background: $color-periwinkle-blue--aerin-v2;
    color: white;
    font-family: 'Futura Now Medium';
  }
  #{$estee_edit} {
    background: $color-bright-blue--estee_edit;
    color: white;
    font-family: $ffmarkweb;
  }
  #{$estee_edit} .page-main .product-full & {
    background: $color-blue--estee_edit;
    color: white;
    font-family: $ffmarkweb-medium;
  }
  #{$renutriv} & {
    background: black;
    @include swap_direction(border, solid 1px $color-gray--renutriv);
    color: white;
  }
  #{$compare} & {
    @include swap_direction(border, solid 1px $color-darker-gray);
    color: white;
  }
}
// dark
.button--dark {
  @include button--dark;
  #{$renutriv} & {
    background: black;
    border-color: $color-gold--renutriv;
    color: $color-white;
  }
  #{$aerin} & {
    background: $color-periwinkle-blue--aerin-v2;
    color: $color-white;
  }
  #{$estee_edit} & {
    background: $color-bright-blue--estee_edit;
    color: $color-white;
    letter-spacing: 0;
    font-family: $ffmarkweb;
    font-weight: bold;
  }
  &.pre-order-button {
    text-transform: uppercase !important; //overriding Botique CSS
    &.pre-order-button-disabled {
      pointer-events: none;
      &.button--dark {
        color: $color-white;
        border: 1px solid $color-white;
        background: $color-light-gray;
        .section-preorder-vb & {
          opacity: 0.25;
          color: $color-white;
        }
      }
    }
  }
}
// light
.button--light {
  @include button--light;
  #{$aerin} & {
    background: $color-periwinkle-blue--aerin-v2;
    color: white;
  }
  #{$estee_edit} & {
    background: $color-bright-blue--estee_edit;
    color: $color-white;
    font-family: $ffmarkweb;
    letter-spacing: 0;
    padding: 0 33px;
  }
  #{$renutriv} & {
    background: black;
    color: white;
  }
}

// light with navy border
.button--light-navy-border {
  @include button--light-navy-border;
  #{$aerin} & {
    background: $color-periwinkle-blue--aerin-v2;
    color: white;
  }
  #{$estee_edit} & {
    background: $color-bright-blue--estee_edit;
    color: white;
  }
  #{$renutriv} & {
    background: black;
    color: white;
  }
}

// black with white text
.button--black {
  background: $color-black;
  color: $color-white;
  font-size: 12px;
  font-family: $akzidenz;
  letter-spacing: .15em;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}

// inverted
.button--inverted {
  @include button--inverted;
  #{$renutriv} & {
    background: white;
    color: black;
    border-color: $color-gold--renutriv;
  }
}

.button--inverted-dark {
  @include button--inverted-dark;
  #{$renutriv} & {
    background: white;
    color: black;
    border-color: $color-gray--renutriv;
  }
}

.button--light-border {
  @include button--light-border;
}

///
/// Appy to instances
///
.cta {
  @include button--dark;
}

a.cta {
  text-decoration: none;
}

// "plus icon" buttons
.button-explore {
  @include hide-text;
  background-size: auto auto;
  background-position: 0 -3269px;
  height: 53px;
  width: 53px;
  background-color: $color-white;
  @include swap_direction(border, 1px solid $color-navy);
  cursor: pointer;
  &.open {
    background-position: 0 -2957px;
  }
}

.button--secondary {
  @include button-base;
  height: 33px;
  line-height: 33px;
  @include swap_direction(border, 1px solid $color-navy);
  #{$renutriv} & {
    border-color: $color-gold--renutriv;
  }
  #{$estee_edit} & {
    border-color: $color-bright-blue--estee_edit;
  }
  #{$aerin} & {
    @include swap_direction(border, none);
  }
}

.button--dark-secondary {
  @include button-base;
  height: 33px;
  line-height: 31px;
  @include swap_direction(border, 1px solid $color-white);
}

.button--dark-tertiary {
  @include button-base;
  height: 33px;
  line-height: 31px;
  @include swap_direction(border, 1px solid $color-periwinkle-blue);
  color: $color-periwinkle-blue;
  font-size: 12px;
  letter-spacing: 2.2px;
  padding-#{$ldirection}: 16px;
  padding-#{$rdirection}: 16px;
}

.button-color-picker {
  @include inline-block;
  background-size: auto auto;
  background-position: 0 -1606px;
  height: 33px;
  width: 34px;
  @include swap_direction(border, 1px solid $color-light-gray);
  #{$renutriv} & {
    background-position: 0 -1550px;
    height: 33px;
    width: 34px;
  }
}

// Form submit buttons:
.form-submit {
  @include form-submit-button;
  @include inline-block;
  @include form-submit;
}

.form-submit--light {
  @include form-submit;
  @include swap_direction(border, solid 1px $color-navy);
  background: none;
  color: $color-navy;
}

/// A lot of perlgem forms have buttons at the top and bottom
/// of the page that are right-aligned on desktop and 100% wide
/// on mobile.
.main-button-wrapper {
  @include clearfix;
  .button,
  input[type='submit'] {
    width: 100%;
    @media #{$medium-up} {
      width: auto;
      float: $rdirection;
    }
  }
}

.hero-block-wrapper {
  .hero-block__boutique-link--label {
    width: auto;
    padding-#{$rdirection}: 20px;
    padding-#{$ldirection}: 60px;
    text-align: center;
  }
}
