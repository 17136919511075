// site banner V2
.site-banner {
  &-formatter {
    background-color: $color-navy;
    visibility: hidden;
    &__slides {
      margin: 0;
      padding: 0;
      list-style: none;
      top: 50%;
      transform: translateY(-50%);
      position: relative;
      &:after {
        content: '\0020';
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
      }
      .site-banner__slide {
        display: none;
        box-align: center;
        -moz-box-align: center;
        -webkit-box-align: center;
        justify-content: center;
        margin: 0 auto;
        min-height: 60px;
        height: auto;
        padding: 0 5px;
        &:first-of-type {
          .not-initialized & {
            display: block;
          }
        }
        &.item-active {
          display: block;
        }
        @media #{$medium-up} {
          min-height: 24px;
          padding: 5px;
        }
      }
      .slick-prev,
      .slick-next {
        z-index: 1;
        height: 18px;
        width: 18px;
        background-color: $color-white;
        @include svg-icon-inline-mask('arrow-left.svg');
      }
      .slick-prev {
        #{$ldirection}: 10px;
        @media #{$medium-up} {
          #{$ldirection}: 32px;
        }
      }
      .slick-next {
        transform: rotate(180deg);
        #{$rdirection}: 12px;
        @media #{$medium-up} {
          #{$rdirection}: 48px;
        }
      }
    }
    &__slider {
      color: $color-white;
      text-align: center;
      position: relative;
      margin: 0 auto;
      min-height: 60px;
      @media #{$medium-up} {
        min-height: 34px;
      }
      p {
        margin: 0;
        padding: 0;
        .site-banner-formatter & {
          font: 15px/24px $akzidenz;
          letter-spacing: 0;
          overflow-wrap: break-word;
          word-break: normal;
          max-width: 330px;
          @media #{$medium-up} {
            overflow-wrap: unset;
            word-break: unset;
            max-width: none;
          }
        }
      }
      a {
        color: $color-periwinkle-blue;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &__close {
      &-icon {
        background-color: $color-white;
        @include svg-icon-inline-mask('close.svg');
        width: 12px;
        height: 12px;
        position: absolute;
        #{$rdirection}: 4px;
        top: 6px;
        cursor: pointer;
        body.ie & {
          #{$rdirection}: 36px;
        }
      }
    }
  }
  &__slider-content {
    margin: 0;
    padding: 0;
    line-height: 1.35;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
