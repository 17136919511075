// Inputs
%form-text {
  // styleguide(batch 2) pg. 13
  @include text--short;
  @include input-placeholder {
    color: $color-navy;
    background-color: $color-white;
    text-transform: uppercase;
  }
  -webkit-appearance: none;
  text-transform: none;
  @include swap_direction(border-radius, 0);
  @include swap_direction(border, solid 1px $color-navy);
  color: $color-navy;
  @include swap_direction(padding, 12px 15px 11px 15px); // border(2) + padding(23) + line-height(20) = height 45px
  line-height: 20px;

  // Tablet-Desktop has a separate state for fields that are empty and un-focused:
  @media #{$medium-up} {
    @include box-shadow(inset 0 0 5px $color-light-gray);
    @include input-placeholder {
      color: $color-light-gray;
      background-color: $color-white;
    }
    @include swap_direction(padding, 6px 10px 5px 10px); // border(2) + padding(11) + line-height(20) = height 33px
    border-color: $color-light-gray; // styleguide(batch 2) pg. 13
    color: $color-light-gray;
    height: 33px;
    &.filled-in {
      @include box-shadow(none);
      border-color: $color-black;
      color: $color-black;
    }
    .page-footer & {
      height: 40px;
    }
  }

  #{$alt} &.error,
  &.error {
    border-color: $color-red;
  }

  #{$alt} & {
    //20131002_EL_FOOTER.psd
    @include input-placeholder {
      color: $color-white;
      background-color: transparent;
    }
    @include box-shadow(none);
    @include swap_direction(border, 1px solid $color-medium-gray);
    color: $color-white;
    background: $color-navy;
    @include swap_direction(padding, 10px 12px 10px 10px); // border(2) + padding(20) + line-height(18) = height 40px
    &.filled-in {
      color: $color-white;
      border-color: $color-white;
    }
  }
} // /%form-text

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='password'],
textarea,
.form-text,
select {
  @extend %form-text;
}

textarea {
  width: 100%;
  height: auto;
}

select {
  text-transform: uppercase;
}

input[type='radio'] {
  @include swap_direction(margin, 0 5px 0 1px);
}

$checkbox-label-offset: 9px;
$checkbox-width: 15px;

// Checkboxes & Radio Buttons
// =======
// Expected HTML:
// Either this:
// <input type="checkbox" id="thecheckbox">
// <label for="thecheckbox">Label Text</label>
//
// Or this:
// <label>
//   <input type="checkbox">
//   <span class="label">Label Text</span>
// </label>

input[type='checkbox'],
input[type='radio'] {
  // Base for label styling
  position: absolute;
  #{$ldirection}: -9999px;
  ~ label,
  ~ .label {
    position: relative;
    padding-#{$ldirection}: 29px;
    cursor: pointer;
    display: block;
    // checkbox aspect
    @media #{$medium-up} {
      padding-#{$ldirection}: $checkbox-width + $checkbox-label-offset;
      padding-top: 3px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 3px;
      #{$ldirection}: 0;
      width: $checkbox-width;
      height: $checkbox-width;
      @include swap_direction(border, 1px solid $color-light-gray);
      #{$alt} & {
        @include swap_direction(border, 1px solid $color-medium-gray);
        background: $color-navy;
      }
    }
    // checked mark aspect
    &::after {
      background-image: url('/media/images/background-images/background-image11.png');
      width: $checkbox-width;
      height: $checkbox-width;
      content: '';
      position: absolute;
      top: 3px;
      #{$ldirection}: 0;
      color: $color-black;
      @include swap_direction(border, solid 1px $color-navy);
      #{$alt} & {
        background-image: url('/media/images/background-images/background-image12.png');
        @include swap_direction(border, solid 1px $color-medium-gray);
      }
    }
    a {
      text-decoration: underline;
      #{$alt} & {
        color: $color-white;
      }
    }
  }
  /* checked mark aspect changes */
  &:not(:checked) {
    ~ .label::after,
    ~ label::after {
      opacity: 0;
      transform: scale(0);
    }
  }
  &:checked,
  &:-ms-check {
    ~ .label::after,
    ~ label::after {
      opacity: 1;
      transform: scale(1);
    }
  }

  // disabled checkbox
  &:disabled,
  &.disabled {
    &:not(:checked),
    &:checked {
      ~ .label,
      ~ label {
        color: $color-light-gray;
        cursor: not-allowed;
        &::before {
          border-style: dotted;
        }
      }
    }
  }

  #{$ie8} & {
    position: static;
    float: $ldirection;
    margin-#{$rdirection}: 5px;
    ~ .label,
    ~ label {
      padding-#{$ldirection}: 0;
    }
  }
}

input[type='radio'] {
  ~ label,
  ~ .label {
    @media #{$medium-up} {
      padding-top: 0;
    }
    display: inline-block;
    padding-#{$ldirection}: 24px;
    line-height: 20px;
    &::before,
    &::after {
      @include swap_direction(border-radius, 8px);
      box-shadow: 0px 0px 0px 1px $color-light-gray;
      @include swap_direction(border, 2px solid $color-white);
    }
    &::after {
      background: $color-navy;
    }
  }
}

// Custom file uploads fields. Some of this relies on javascript in
// main-shared.js. To use it you have to follow this markup:
//  <div class="upload-file">
//    <div class="upload-file__button">
//      <div class="upload-file__imposter">Choose file</div>
//      <input type="file" />
//    </div>
//    <div class="upload-file__value">No File Chosen</div>
//  </div>
.upload-file {
  text-align: center;
  .upload-file__button {
    @include button;
    @include button--inverted-dark;
    font-weight: bold;
    letter-spacing: 0.12em;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    display: block;
    @media #{$medium-up} {
      display: inline-block;
    }
    #{$ie8} & {
      display: inline-block;
    }
  }
  .upload-file__value {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    line-height: 42px;
    @include swap_direction(margin, 0 0 0 20px);
    display: block;
    @media #{$medium-up} {
      display: inline-block;
    }
    #{$ie8} & {
      display: inline-block;
    }
  }
  input[type='file'] {
    position: absolute;
    top: 0;
    #{$rdirection}: 0;
    bottom: 0;
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

label {
  display: inline;
  @include text--short;
  // Use this class to get a label to display as regular text (for checkboxes &
  // radios). Also available as a wrapper class because perlgem makes it
  // difficult to add classes to particular labels.
  &.text--checkbox-label,
  .text--checkbox-label & {
    text-transform: none;
    letter-spacing: 0.05em;
  }
  .text--checkbox-sub-label {
    text-transform: none;
    display: block;
    font-size: 10px;
    letter-spacing: 0.01em;
  }
}

.form-submit--selected {
  @include form-submit;
}

.form-submit--unselected {
  @include form-submit;
  background: $color-white;
  @include swap_direction(border, solid 1px $color-light-gray);
  line-height: 28px;
}

.form-submit--active {
  @include form-submit;
}

.form-submit--inactive {
  @include form-submit;
  background: $color-light-gray;
}

.form-item {
  margin-bottom: 20px;
  .label {
    margin-bottom: 7px;
  }
  @media #{$medium-up} {
    margin-bottom: 30px;
  }
}

// editor login form
#user-login {
  padding-bottom: 2em;
}
