/* Tile Image - 390 x 224 landscape; 308 x 454 portrait */

.ee-mb-tile {
  margin-bottom: 40px;
}

.ee-mb-tile__content--landscape {
  max-width: 390px;
}

.ee-mb-tile__content--portrait {
  max-width: 308px;
}

.ee-mb-tile__text {
  width: 100%;
  text-align: center;
  margin: {
    top: 14px;
    #{$ldirection}: auto;
    #{$rdirection}: auto;
  }
  p:first-of-type {
    margin-top: 0;
  }
}

// NOTE: "!important" is used here to combat inline styles,
// which are needed for specific composed layouts
$brand-story-one-col: '#{$screen} and (max-width:960px)' !default;

@media #{$brand-story-one-col} {
  .ee-mb-tiles {
    > div:first-child {
      height: auto !important;
    }
  }
  .ee-mb-tile {
    position: static !important;
    width: auto !important;
    max-width: 100% !important;
  }
  .ee-mb-tile__content {
    img {
      position: relative;
      max-width: 100%;
      #{$ldirection}: 50%;
      -webkit-transform: translateX(-50%);
    }
  }
}
