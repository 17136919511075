// $small-up 0, 736px
// $medium-up 737px, 768px - portrait
// $large-up 769px, 1024px - landscape
// $xlarge-up 1025px, 1279px
// $xxlarge-up 1280px and up

$color-ping--1: #ff508a;
$color-ping--2: #ff598a;

// shared sytes for offer kit
.page-wrapper--offer__kit {
  // right after mobile
  // #{$min-nav} & {
  //   padding-top: 0;
  // }
}

.offer__kit__formatter {
  max-width: 1366px;
  min-width: 320px;
  margin: 0 auto 40px auto;
  @media #{$medium-up} {
    margin: 0 auto 20px auto;
    position: relative;
  }
}

//marquee
.offer__kit__marquee {
  position: relative;
}

.offer__kit__marquee__image {
  display: none;
  width: 100%;
  @media #{$medium-up} {
    display: block;
  }
}

.offer__kit__marquee__image__small {
  display: block;
  width: 100%;
  @media #{$medium-up} {
    display: none;
  }
}

.offer__kit__marquee__content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-top: 50px;
  padding: 6px 0 0 30px;
  @media #{$small-only} {
    @include swap_direction(padding, 6px 0 0 0);
  }
  // right after mobile
  @media #{$xlarge-up} {
    margin-top: $header-collapsed-height;
    padding: 50px 0 0 60px;
  }
}

.offer__kit__marquee__callout {
  font-weight: bold;
}

.offer__kit__marquee__header {
  @media #{$medium-up} {
    font-size: 75px;
  }
  @media #{$large-up} {
    font-size: 100px;
  }
}

.offer__kit__marquee__description {
  margin-top: 10px;
}

//steps
.offer__kit__container {
  padding: 0;
  @media #{$medium-up} {
    padding: 20px 0 0 30px;
    max-width: 100%;
    margin: 0 auto;
  }
  @media #{$medium-down} {
    padding: 20px 0 0 0px;
  }
  @media #{$large-up} {
    @include swap_direction(padding, 0 0 0 60px);
  }
  .offer__kit__step {
    //@include clearfix;
    @include pie-clearfix; //need overflow for image
    &.offer__kit__step--alt {
      .offer__kit__step__content,
      .offer__kit__image__container {
        @media #{$medium-up} {
          float: right;
        }
      }
    }
  }
  .offer__kit__step__content,
  .offer__kit__image__container {
    @media #{$medium-up} {
      float: left;
    }
  }
  .offer__kit__image__container {
    display: none;
    @media #{$medium-up} {
      display: block;
      margin-left: 0;
      margin-top: 2%;
    }
    @media #{$large-up} {
      margin-left: 0;
    }
    @media #{$xxlarge-up} {
      display: block;
      margin-left: 0;
      margin-top: 2%;
    }
  }
  .offer__kit__image {
    width: 100%;
    @media #{$xlarge-up} {
      width: auto;
    }
  }
  .offer__kit__image__small {
    width: 100%;
  }
  .offer__kit__image--01 {
    display: block;
  }
  .offer__kit__image__small--01 {
    display: block;
    @media #{$medium-up} {
      display: none;
    }
  }
  .offer__kit__image--02,
  .offer__kit__image__small--02 {
    display: none;
    @media #{$medium-up} {
      display: none;
    }
  }
  // option 2
  .offer__kit__option__image__alt {
    .offer__kit__image--01 {
      display: none;
    }
    .offer__kit__image__small--01 {
      display: none;
      @media #{$medium-up} {
        display: none;
      }
    }
    .offer__kit__image--02 {
      display: block;
    }
    .offer__kit__image__small--02 {
      display: block;
      @media #{$medium-up} {
        display: none;
      }
    }
  }
  // option 3
  .offer__kit {
    &__image--03 {
      display: none;
    }
    &__image__small--03 {
      display: none;
    }
    &__option__image__alt_3 {
      .offer__kit__image--01 {
        display: none;
      }
      .offer__kit__image--03 {
        display: block;
      }
      .offer__kit__image__small--01 {
        display: none;
      }
      .offer__kit__image__small--03 {
        display: block;
        @media #{$medium-up} {
          display: none;
        }
      }
    }
  }
}

.offer__kit__header__container {
  @include pie-clearfix; //need overflow
  .offer__kit__number,
  .offer__kit__header {
    float: none;
    padding: 0 0 10px 0;
    @media #{$medium-up} {
      text-align: left;
      border: none;
      padding-bottom: 15px;
    }
    @media #{$medium-only} {
      padding-bottom: 8px;
    }
  }
}

.offer__kit__cta {
  position: relative;
}

.offer__kit__button__add,
.offer__kit__button__oos {
  @media #{$small-only} {
    width: 100%;
    background: $color-navy;
    height: 45px;
    line-height: 45px;
    color: $color-white;
    @media #{$small-up} {
      background: $color-white;
      color: $color-black;
    }
  }
  @media #{$medium-up} {
    width: auto;
  }
}

.offer__kit__loading {
  display: none;
  position: absolute;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  height: 45px;
  line-height: 45px;
  padding-#{$ldirection}: 38px;
  background: url(/media/images/common/ajax-loader.gif) no-repeat 0 0 transparent;
  margin: 0 25%;
  @media #{$medium-up} {
    height: 33px;
    line-height: 33px;
    margin: 0;
  }
  .offer__kit__is__loading & {
    display: block;
  }
}

.offer__kit__oos {
  display: none;
  font-size: 12px;
  text-transform: uppercase;
  color: $color-red;
  .offer__kit__is__sold__out & {
    display: block;
  }
}

.offer__kit__button__oos {
  display: none;
}

.offer__kit__products__data {
  display: none;
}

// success overlay
//.offer__kit__success__overlay#cboxOverlay {
//  display: none;
//}

.offer__kit__success__overlay #cboxContent {
  background: $color-white;
  border: 1px solid $color-white;
}

.elc-user-state-logged-in,
.elc-user-state-anonymous {
  #colorbox.offer__kit__success__overlay {
    #cboxContent {
      #cboxClose {
        #{$rdirection}: 10px;
        top: 10px;
      }
    }
  }
}

.offer__kit__success__overlay #cboxLoadedContent {
  color: $color-white;
}

.offer__kit__success__overlay__container {
  display: none;
}

.offer__kit__success__overlay__content {
  text-align: center;
  padding-bottom: 50px;
}

.offer__kit__success__overlay__header {
  color: $color-navy;
  font-family: $optimalight;
  font-size: 60px;
  line-height: 60px;
  margin-bottom: 0;
  width: 100%;
  margin-top: 50px;
}

.offer__kit__success__overlay__copy {
  color: $color-navy;
  width: 80%;
  margin: 15px auto;
}

.offer__kit__success__overlay__button__container {
  margin-top: 30px;
  a {
    text-decoration: underline;
    border: 0;
    background: none;
  }
}

.section-fall-gwp-17 .offer__kit__success__overlay__copy {
  width: 75%;
  text-transform: uppercase;
  font-size: 11px;
}

.offer__kit__formatter--gwp_fa17 {
  margin-bottom: 0;
  .offer__kit {
    @media #{$large-up} {
      min-height: 730px;
    }
    &__step--3 {
      margin-bottom: 90px;
    }
    &__container {
      @media #{$large-up} {
        @include swap_direction(padding, 0 90px);
      }
      @media #{$large-only} {
        @include swap_direction(padding, 0 70px);
      }
      @media #{$medium-down} {
        @include swap_direction(margin, 0 auto);
        text-align: center;
      }
      @media #{$medium-only} {
        @include swap_direction(padding, 0 20px);
      }
      &__step {
        clear: both;
        @media #{$large-only} {
          @include swap_direction(padding, 0 20px);
        }
      }
      .offer__kit__option {
        &__container {
          &__product_image_small {
            @media #{$medium-down} {
              width: 100%;
            }
            @media #{$medium-only} {
              display: none;
            }
            @media #{$large-up} {
              display: none;
            }
          }
          &__product_image {
            @media #{$medium-down} {
              display: none;
            }
            @media #{$medium-only} {
              display: block;
              width: 95%;
            }
            @media #{$large-up} {
              display: block;
              width: 100%;
            }
            @media #{$large-only} {
              width: 100%;
            }
          }
        }
      }
    }
    &__marquee__content {
      color: $color-white;
      @media #{$medium-down} {
        display: none;
      }
      @media #{$medium-up} {
        display: block;
        margin-top: 35px;
        color: $color-white;
        .headline--large {
          font-size: 48px;
        }
        .headline--medium {
          font-size: 35px;
        }
      }
      @media #{$large-up} {
        margin-top: 65px;
        padding-#{$ldirection}: 122px;
        .headline--large {
          font-size: 92px;
        }
        .headline--medium {
          font-size: 62px;
        }
      }
      @media #{$medium-only} {
        left: 31px;
      }
      @media #{$large-only} {
        padding-#{$ldirection}: 100px;
        margin-top: 35px;
      }
    }
    &__footer {
      position: relative;
      clear: both;
      @media #{$medium-only} {
        margin-bottom: 25px;
      }
      &__banner_header {
        &_mob,
        &_pc {
          display: none;
        }
        &_mob {
          @media #{$medium-down} {
            display: block;
            width: 100%;
            text-align: center;
            background: $color-ping--1;
            padding-top: 15px;
            color: $color-white;
            .okf_splitter {
              display: block;
            }
            h4 {
              font-size: 27px;
              line-height: 27px;
            }
          }
          @media #{$medium-up} {
            display: none;
          }
        }
        &_pc {
          @media #{$medium-up} {
            display: block;
          }
        }
      }
      &__banner_text {
        color: $color-white;
        @media #{$medium-down} {
          @include swap_direction(padding, 15px);
          width: 100%;
          text-align: center;
          position: initial;
          background: $color-ping--2;
        }
        @media #{$medium-only} {
          @include swap_direction(padding, 10px 0 0 25px);
          width: 40%;
          text-align: left;
          position: absolute;
          background: none;
          top: 0;
          font-size: 13px;
          line-height: 16px;
        }
        @media #{$large-up} {
          @include swap_direction(padding, 0 0 0 10px);
          width: 37%;
          position: absolute;
          top: 35px;
          left: 43px;
          line-height: 27px;
          background: none;
          text-align: left;
        }
        @media #{$large-only} {
          top: 20px;
          left: 43px;
          font-size: 13px;
          line-height: 15px;
        }
        h4 {
          @media #{$medium-down} {
            font-size: 27px;
            line-height: 29px;
          }
          @media #{$large-up} {
            font-size: 42px;
            line-height: 50px;
          }
          span {
            display: block;
          }
          @media #{$large-only} {
            font-size: 24px;
            line-height: 25px;
          }
        }
      }
      &__banner_small_text {
        &__offer {
          margin-top: 8px;
          @media #{$small-only} {
            margim-top: 0;
          }
        }
        @media #{$small-only} {
          margin-bottom: 30px;
        }
      }
      &__banner_image {
        display: none;
        width: 100%;
        @media #{$medium-up} {
          display: block;
          margin-bottom: 25px;
        }
        img {
          @media #{$medium-up} {
            width: 100%;
          }
        }
      }
      &__banner_image_small {
        display: block;
        width: 100%;
        @media #{$medium-up} {
          display: none;
        }
        @media #{$medium-down} {
          background: $color-ping--2;
          img {
            width: 100%;
            vertical-align: initial;
          }
        }
      }
    }
    &__details {
      position: relative;
      @media #{$medium-down} {
        text-align: left;
      }
      &__link {
        font-weight: bold;
        padding-#{$ldirection}: 44px;
        text-decoration: underline;
        @media #{$medium-down} {
          font-size: 15px;
        }
      }
    }
    &__details__overlay {
      @include swap_direction(padding, 22px);
      width: 90%;
      margin-#{$ldirection}: 44px;
      border: 1px solid $color-black;
      display: none;
      background: $color-white;
      position: absolute;
      z-index: 100;
      @media #{$large-up} {
        @include swap_direction(padding, 30px 25px 30px 33px);
        z-index: 999;
        bottom: 20px;
        width: 75%;
        min-height: 330px;
      }
    }
    &__details__list {
      dt {
        font-weight: bold;
        display: list-item;
        list-style-type: disc;
      }
    }
    &__content {
      @include swap_direction(padding, 15px 30px);
      width: 100%;
      text-align: center;
      @media #{$large-up} {
        @include swap_direction(padding, 0);
        width: 80%;
        text-align: left;
        font-size: 13px;
      }
      @media #{$xxlarge-up} {
        font-size: 15px;
      }
    }
    &__header__container {
      .mpp__header {
        @media #{$large-up} {
          font-size: 28px;
        }
        @media #{$xxlarge-up} {
          font-size: 40px;
        }
        @media #{$medium-down} {
          @include swap_direction(margin, 0 auto);
          width: 90%;
        }
      }
      .offer__kit_includes {
        &__header {
          @media #{$medium-down} {
            position: initial;
            font-size: 25px;
          }
          @media #{$medium-only} {
            position: absolute;
            font-size: 30px;
            top: 219px;
            left: 60px;
          }
          @media #{$large-up} {
            position: absolute;
            top: 360px;
            left: 122px;
            font-size: 42px;
          }
          @media #{$large-only} {
            left: 100px;
            top: 280px;
          }
        }
        &__content {
          @media #{$medium-down} {
            position: initial;
            text-align: center;
          }
          @media #{$medium-only} {
            position: absolute;
            top: 260px;
            text-align: left;
            left: 60px;
            width: 275px;
          }
          @media #{$large-up} {
            position: absolute;
            top: 413px;
            left: 122px;
            text-align: left;
            width: 295px;
          }
          @media #{$large-only} {
            left: 100px;
            top: 340px;
          }
        }
        &__choose_header {
          @media #{$medium-down} {
            position: initial;
            font-size: 35px;
            margin-top: 20px;
          }
          @media #{$medium-only} {
            position: absolute;
            top: 300px;
            left: 60px;
            font-size: 30px;
          }
          @media #{$large-up} {
            position: absolute;
            top: 515px;
            left: 122px;
            font-size: 42px;
          }
          @media #{$large-only} {
            left: 100px;
            top: 417px;
          }
        }
      }
    }
    &__oos {
      padding-#{$ldirection}: 44px;
      @media #{$medium-down} {
        text-align: left;
        margin-top: 5px;
      }
    }
    &__details__header {
      padding-bottom: 20px;
    }
    &__loading {
      width: 30%;
    }
    &__option {
      @media #{$small-only} {
        margin-top: 30px;
      }
      @media #{$medium-up} {
        @include swap_direction(padding, 0 0 25px 0);
        float: left;
        padding-bottom: 0;
        width: 31%;
      }
      @media #{$medium-only} {
        width: 33%;
      }
      @media #{$large-up} {
        @include swap_direction(padding, 0 0 25px 0);
        float: left;
        padding-bottom: 0;
        width: 33.33%;
      }
      @media #{$large-only} {
        @include swap_direction(padding, 0 10px 25px 10px);
      }
      &--03 {
        @media #{$medium-only} {
          margin-top: 35px;
        }
        @media #{$large-only} {
          margin-top: 40px;
        }
      }
      &--02 {
        @media #{$medium-only} {
          margin-top: 10px;
        }
        @media #{$large-only} {
          margin-top: 10px;
        }
      }
      p {
        @include swap_direction(margin, 15px 45px);
        text-transform: none;
        width: 80%;
        @media #{$medium-down} {
          text-align: left;
        }
        @media #{$small-only} {
          width: 225px;
        }
      }
      &__details {
        @media #{$large-up} {
          text-align: left;
        }
      }
      .label {
        padding-#{$ldirection}: 42px;
        @media #{$medium-down} {
          padding-top: 0;
        }
        @media #{$medium-down} {
          margin-top: 10px;
        }
        &::before {
          width: 28px;
          height: 28px;
          border: 1px solid $color-darker-gray;
        }
        &::after {
          width: 28px;
          height: 28px;
          background-image: url(/sites/esteelauder/themes/estee_base/img/sprites/checkbox_gwp_transparent.png);
        }
      }
    }
    &__option__label {
      font-size: 30px;
      text-align: left;
      border: none;
      text-transform: none;
      @media #{$large-up} {
        font-size: 22px;
      }
      @media #{$xxlarge-up} {
        font-size: 30px;
      }
    }
    &__cta {
      @include swap_direction(padding, 0 30px);
      @media #{$large-up} {
        @include swap_direction(padding, 0 0 25px 0);
      }
      .loading_text {
        background: $color-white;
        height: 45px;
        display: none;
        @media #{$large-up} {
          height: auto;
        }
      }
    }
    &__extra__step {
      @media #{$large-up} {
        @include swap_direction(padding, 0);
      }
    }
    &__button__add {
      display: none;
      @media #{$large-up} {
        position: absolute;
        bottom: 0;
        width: 85%;
      }
      @media #{$medium-down} {
        width: 100%;
      }
      @media #{$medium-only} {
        width: auto;
        position: absolute;
        bottom: -8px;
      }
      @media #{$large-up} {
        width: auto;
        bottom: -8px;
      }
    }
    &__cta.active {
      .offer__kit__button__add {
        display: block;
      }
      &.offer__kit__is__loading {
        .loading_text {
          display: block;
        }
        .offer__kit__button__add {
          display: none;
        }
      }
    }
    &__button__add:hover {
      @media #{$large-up} {
        background: $color-navy;
        color: $color-white;
      }
    }
    &__step__content {
      margin-top: 20px;
      @media #{$medium-only} {
        float: none;
        margin-top: 0;
      }
      @media #{$large-up} {
        margin-top: 0;
        width: 35%;
      }
      @media #{$large-only} {
        width: 100%;
      }
      @media #{$xlarge-up} {
        margin-top: 0;
        width: 37%;
      }
      @media #{$xxlarge-up} {
        @include swap_direction(padding, 0 30px);
        width: 100%;
      }
      .offer__kit__button__add {
        display: none;
      }
      .offer__kit__error {
        @include swap_direction(margin, 0 0 20px 0);
        color: $color-red;
        text-align: center;
        @media #{$large-up} {
          @include swap_direction(margin, 20px 0 0 0);
          text-align: left;
        }
      }
    }
    &__notice__container {
      position: relative;
      .offer__kit__notice {
        text-align: center;
        position: absolute;
        top: 52px;
        width: 100%;
        @media #{$small-only} {
          top: 30px;
        }
        @media #{$large-up} {
          text-align: left;
        }
        @media #{$medium-only} {
          text-align: left;
          width: 300px;
          left: 30px;
          top: 25px;
        }
      }
    }
    &__option__container {
      @include swap_direction(padding, 0 30px);
      @media #{$large-up} {
        @include swap_direction(padding, 0);
      }
      @media #{$medium-down} {
        width: 100%;
      }
    }
    &__image__container {
      width: 25%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.offer__kit__formatter--gwp_su17 {
  @media #{$large-up} {
    margin-bottom: 0;
  }
  @media #{$large-up} {
    min-height: 730px;
  }
  @media #{$large-up} {
    min-height: 820px;
  }
  .offer__kit {
    &__details__link {
      font-weight: bold;
      padding-#{$ldirection}: 120px;
      text-decoration: underline;
      @media #{$medium-only} {
        padding-#{$ldirection}: 60px;
      }
    }
    &__details__overlay {
      @include swap_direction(padding, 22px);
      width: 90%;
      margin-#{$ldirection}: 44px;
      border: 1px solid $color-black;
      display: none;
      background: $color-white;
      position: absolute;
      z-index: 100;
      @media #{$large-up} {
        @include swap_direction(padding, 30px 25px 30px 42px);
        z-index: 999;
        bottom: 20px;
        width: 95%;
      }
    }
    &__details__list {
      dt {
        font-weight: bold;
        display: list-item;
        list-style-type: disc;
      }
    }
    &__content {
      @include swap_direction(padding, 15px 30px);
      width: 100%;
      text-align: center;
      @media #{$large-up} {
        @include swap_direction(padding, 0);
        width: 80%;
        text-align: left;
        font-size: 13px;
      }
      @media #{$xxlarge-up} {
        font-size: 15px;
      }
    }
    &__header__container {
      .mpp__header {
        @media #{$large-up} {
          font-size: 28px;
        }
        @media #{$xxlarge-up} {
          font-size: 40px;
        }
      }
    }
    &__details {
      position: relative;
    }
    &__oos {
      padding-left: 44px;
    }
    &__details__header {
      padding-bottom: 20px;
    }
    &__loading {
      width: 30%;
    }
    &__option {
      @media #{$large-up} {
        float: none;
        width: 100%;
        padding: 0 0 25px 0;
      }
      .label {
        padding-left: 42px;
        margin-left: 75px;
        @media #{$medium-down} {
          padding-top: 0;
        }
        &::before {
          width: 28px;
          height: 28px;
        }
        &::after {
          width: 28px;
          height: 28px;
          background-image: url(/sites/esteelauder/themes/estee_base/img/sprites/checkbox_gwp_transparent.png);
        }
      }
    }
    &__option__label {
      font-size: 30px;
      text-align: left;
      border: none;
      text-transform: none;
      @media #{$large-up} {
        font-size: 22px;
      }
      @media #{$xxlarge-up} {
        font-size: 30px;
      }
    }
    &__cta {
      padding: 0 30px;
      @media #{$large-up} {
        padding: 0 0 25px 0;
      }
      .loading_text {
        background: $color-white;
        height: 45px;
        @media #{$large-up} {
          height: auto;
        }
      }
    }
    &__extra__step {
      @media #{$large-up} {
        padding: 0;
      }
    }
    &__button__add {
      position: absolute;
      bottom: 0;
      width: 85%;
      @media #{$large-up} {
        width: auto;
        bottom: -8px;
      }
    }
    &__step__content {
      margin-top: 20px;
      @media #{$large-up} {
        margin-top: 0;
        width: 35%;
      }
      @media #{$large-up} {
        margin-top: 0;
        width: 35%;
      }
      @media #{$xlarge-up} {
        margin-top: 0;
        width: 37%;
      }
      @media #{$xxlarge-up} {
        margin-top: 0;
        width: 43%;
      }
      .offer__kit__button__add {
        display: none;
      }
      .offer__kit__error {
        color: $color-red;
        margin: 0 0 20px 0;
        text-align: center;
        @media #{$large-up} {
          margin: 20px 0 0 0;
          text-align: left;
        }
      }
    }
    &__notice__container {
      position: relative;
      .offer__kit__notice {
        text-align: center;
        position: absolute;
        top: 52px;
        width: 100%;
        @media #{$large-up} {
          text-align: left;
        }
      }
    }
    &__image__container {
      width: 25%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.section-summer-gwp-17 {
  .special-offer {
    &-row-mobile {
      .special-offer-header {
        margin-bottom: 17px;
      }
    }
  }
  .special-offer-row-wrapper {
    @media #{$medium-up} {
      padding: 0 30px;
    }
    @media #{$xlarge-up} {
      padding: 0;
    }
  }
}

// $small-up 0, 736px
// $medium-up 737px, 768px - portrait
// $large-up 769px, 1024px - landscape
// $xlarge-up 1025px, 1279px
// $xxlarge-up 1280px and up

//***/
//*** current gwp - gwp_sp17
//***/

.offer__kit__formatter--gwp_sp17 {
  //marquee
  .offer__kit__marquee__content {
    @include swap_direction(padding, 22px 0 0 14px);
    font-size: 12px;
    line-height: 24px;
    width: 55%;
    letter-spacing: 0.05em;
    @media #{$medium-up} {
      margin: 0;
      width: 25%;
    }
    @media #{$large-up} {
      @include swap_direction(margin, 0 0 0 3.3%);
      letter-spacing: 0.15em;
      padding: 28px 0 0 0;
    }
    @media #{$xlarge-up} {
      @include swap_direction(margin, $header-collapsed-height 0 0 3.3%);
      letter-spacing: 0.15em;
      padding: 28px 0 0 0;
    }
    @media #{$xxlarge-up} {
      @include swap_direction(margin, $header-collapsed-height 0 0 6.6%);
      letter-spacing: 0.15em;
      padding: 28px 0 0 0;
    }
  }
  .offer__kit__marquee__callout {
    text-transform: uppercase;
    font-weight: bold;
  }
  .offer__kit__marquee__header {
    font-family: $optimalight;
    font-size: 84px;
    line-height: 82px;
    // font-size: 98px;
    // line-height: 90px;
    letter-spacing: -0.05em;
    @media #{$medium-up} {
      font-size: 56px;
      line-height: 58px;
    }
    @media #{$xlarge-up} {
      font-size: 84px;
      line-height: 82px;
    }
    @media #{$xxlarge-up} {
      font-size: 128px;
      line-height: 110px;
    }
  }
  .offer__kit__marquee__description {
    text-transform: uppercase;
    line-height: 20px;
    @media #{$medium-up} {
      line-height: 24px;
      margin-top: 13px;
    }
  }
  //steps
  .offer__kit__container {
    @media #{$medium-up} {
      padding-#{$ldirection}: 30px;
      position: relative;
      z-index: 2;
    }
  }
  .offer__kit__step {
    &.offer__kit__step--alt {
      .offer__kit__step__content {
        @media #{$medium-up} {
          width: 45%;
        }
      }
      .offer__kit__image__container {
        @media #{$medium-up} {
          width: 55%;
        }
      }
    }
  }
  .offer__kit__step__content {
    @media #{$medium-up} {
      width: 55%;
    }
  }
  .offer__kit__image__container {
    @media #{$medium-up} {
      width: 45%;
    }
  }
  .offer__kit__header__container {
    margin-#{$ldirection}: 50px;
    @media #{$medium-up} {
      margin-#{$ldirection}: 0;
    }
  }
  .offer__kit__number {
    font-family: $optimaUltralight;
    font-size: 90px;
    line-height: 96px;
  }
  .offer__kit__header {
    font-family: $optimalight;
    font-size: 36px;
    line-height: 38px;
    letter-spacing: -0.01em;
    width: 80%;
    @media #{$medium-up} {
      width: 85%;
      font-size: 40px;
      line-height: 40px;
    }
  }
  .offer__kit__option__container {
    //@include clearfix;
    @include pie-clearfix; //need overflow for image
    clear: both;
    position: relative;
    margin-#{$ldirection}: 10px;
    @media #{$medium-up} {
      margin: 0;
    }
  }
  .offer__kit__option {
    float: left;
    width: 50%;
    input[type='checkbox'] {
      &:disabled,
      &.disabled {
        &:not(:checked),
        &:checked {
          ~ .label,
          ~ label {
            &::before {
              border-style: none;
            }
          }
        }
      }
      &.loading {
        &:not(:checked),
        &:checked {
          ~ .label,
          ~ label {
            color: $color-black;
            cursor: not-allowed;
            &::before {
              border-style: none;
            }
          }
        }
      }
    }
    input[type='checkbox'] ~ .label {
      @include swap_direction(padding, 0 25px);
      @media #{$medium-up} {
        @include swap_direction(padding, 0 42px);
      }
    }
    input[type='checkbox'] ~ label:before,
    input[type='checkbox'] ~ .label:before {
      border-color: $color-black;
      width: 18px;
      height: 18px;
      //margin-left: -25px;
      @media #{$medium-up} {
        width: 30px;
        height: 30px;
      }
    }
    input[type='checkbox'] ~ label:after,
    input[type='checkbox'] ~ .label:after {
      width: 18px;
      height: 18px;
      background-size: contain;
      //margin-left: -25px;
      @media #{$medium-up} {
        background-image: url(/media/promotions/gwp/spring_2015/pc/checked.png);
        width: 30px;
        height: 30px;
      }
    }
  }
  .offer__kit__option__label {
    font-family: $optimalight;
    font-size: 21px;
    line-height: 23px;
    letter-spacing: -0.01em;
    text-transform: none;
    @media #{$large-up} {
      font-size: 30px;
      line-height: 32px;
    }
  }
  .offer__kit__details {
    @include swap_direction(padding, 8px 0 0 25px);
    @media #{$medium-up} {
      @include swap_direction(padding, 8px 0 0 42px);
    }
    position: relative;
  }
  .offer__kit__details__link {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    text-decoration: underline;
  }
  .offer__kit__details__overlay {
    display: none;
    position: absolute;
    bottom: 22px;
    left: 0;
    z-index: 10;
  }
  .offer__kit__details__text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    width: 320px;
    padding: 30px 20px;
    background: $color-white;
    border: 1px solid $color-black;
  }
  .offer__kit__details__header {
    padding-#{$ldirection}: 9px;
    margin-bottom: 18px;
  }
  .offer__kit__details__list {
    dt,
    dd {
      padding-#{$ldirection}: 9px;
    }
    dt {
      font-weight: bold;
      background: url(/media/export/cms_2.0/offers/shared/overlay_disc.gif) no-repeat 0 0;
    }
  }
  .offer__kit__details__close {
    background-size: auto auto;
    background-position: 0 -883px;
    height: 13px;
    width: 13px;
    position: absolute;
    top: 10px;
    right: 10px;
    @media #{$medium-up} {
      display: none;
    }
  }
  .offer__kit__oos {
    @include swap_direction(padding, 8px 0 0 25px);
    @media #{$medium-up} {
      @include swap_direction(padding, 8px 0 0 42px);
    }
  }
  // STEP 1
  .offer__kit__step--1 {
    .offer__kit__step__content {
      @media #{$medium-up} {
        @include swap_direction(padding, 30px 0 0 40px);
        width: 54%;
      }
    }
    .offer__kit__image__container {
      @media #{$medium-up} {
        width: 46%;
      }
    }
    .offer__kit__image {
      @media #{$medium-up} {
        margin-top: -10px;
      }
      @media #{$large-up} {
        margin-top: -20px;
      }
    }
    .offer__kit__header {
      @include swap_direction(padding, 10px 0 0 5px);
      @media #{$medium-up} {
        @include swap_direction(padding, 30px 0 0 10px);
      }
    }
    .offer__kit__option--01 {
      @include swap_direction(padding, 5px 0 0 10px);
      @media #{$large-up} {
        @include swap_direction(padding, 5px 0 0 40px);
      }
      .offer__kit__details__overlay {
        left: -20px;
        @media #{$iphone6-up} {
          left: 8px;
        }
        @media #{$medium-up} {
          left: 0;
        }
      }
    }
    .offer__kit__option--02 {
      @include swap_direction(padding, 5px 0 0 12px);
      .offer__kit__details__overlay {
        left: auto;
        right: 0;
        @media #{$iphone6-up} {
          right: 26px;
        }
        @media #{$medium-up} {
          right: 0;
        }
        @media #{$xlarge-up} {
          right: auto;
          left: 0;
        }
      }
    }
  }
  // STEP 2
  .offer__kit__step--2 {
    margin-top: 20px;
    @media #{$medium-up} {
      margin-top: 0;
    }
    &.offer__kit__step--alt {
      .offer__kit__step__content {
        @media #{$medium-up} {
          width: 55%;
        }
      }
      .offer__kit__image__container {
        @media #{$medium-up} {
          width: 45%;
        }
      }
    }
    .offer__kit__step__content {
      @media #{$medium-up} {
        @include swap_direction(padding, 10px 0 0 68px);
      }
    }
    .offer__kit__image {
      @media #{$large-up} {
        margin-top: -22px;
      }
      @media #{$xlarge-up} {
        margin-top: -82px;
      }
    }
    .offer__kit__header {
      @include swap_direction(padding, 10px 0 0 5px);
      @media #{$medium-up} {
        @include swap_direction(padding, 26px 0 0 10px);
      }
    }
    .offer__kit__option__container {
      @media #{$medium-up} {
        margin-top: -5px;
      }
    }
    .offer__kit__option--01 {
      @include swap_direction(padding, 5px 0 0 10px);
      width: 50%;
      input[type='checkbox'] ~ .label {
        @media #{$medium-up} {
          @include swap_direction(padding, 0 0 0 42px);
        }
      }
      @media #{$large-up} {
        @include swap_direction(padding, 0 0 0 60px);
      }
      .offer__kit__details__overlay {
        left: -20px;
        @media #{$iphone6-up} {
          left: 8px;
        }
        @media #{$medium-up} {
          left: 0;
        }
      }
    }
    .offer__kit__option--02 {
      width: 50%;
      @media #{$medium-up} {
        @include swap_direction(padding, 0 0 0 20px);
      }
      @media #{$large-up} {
        @include swap_direction(padding, 0 0 0 40px);
      }
      .offer__kit__details__overlay {
        left: auto;
        right: 0;
        @media #{$iphone6-up} {
          right: 26px;
        }
        @media #{$medium-up} {
          left: auto;
          right: 0;
        }
      }
    }
  }
  // STEP 3
  .offer__kit__step--3 {
    margin-top: 20px;
    @media #{$medium-up} {
      margin-top: 0;
    }
    .offer__kit__step__content {
      @media #{$medium-up} {
        @include swap_direction(padding, 0 0 0 3px);
        width: 44%;
      }
    }
    .offer__kit__image__container {
      @media #{$medium-up} {
        width: 56%;
      }
    }
    .offer__kit__header__container {
      margin-#{$ldirection}: 20px;
      @media #{$medium-up} {
        margin-#{$ldirection}: 0;
      }
    }
    .offer__kit__image {
      @media #{$medium-up} {
        margin-top: -8px;
      }
      @media #{$xlarge-up} {
        margin-top: -66px;
      }
    }
    .offer__kit__header {
      @include swap_direction(padding, 10px 0 0 5px);
      @media #{$medium-up} {
        @include swap_direction(padding, 8px 0 0 10px);
        width: 80%;
      }
    }
    .offer__kit__error {
      @include swap_direction(margin, 0 20px 0 20px);
      height: 24px;
      line-height: 24px;
      color: $color-red;
      @media #{$medium-up} {
        @include swap_direction(margin, 3px 0 0 55px);
      }
    }
    .offer__kit__cta {
      @include swap_direction(margin, 0 20px 0 20px);
      display: block;
      @media #{$medium-up} {
        @include swap_direction(margin, 3px 0 0 55px);
      }
    }
    .offer__kit__number {
      width: 45px;
    }
    .offer__kit__notice__container {
      @include swap_direction(margin, 12px 20px 0 20px);
      text-align: center;
      @media #{$medium-up} {
        @include swap_direction(margin, 12px 0 0 55px);
        text-align: left;
      }
    }
    .offer__kit__notice {
      margin: 6px 0;
    }
  }
  // moving cta into extra step
  // hide default cta
  .offer__kit__cta {
    display: none;
  }
}

//spring2018 start

.section-spring-2018-gwp-choice-page {
  .offer__kit {
    &__success__overlay {
      #cboxContent {
        background: $color-white;
      }
      &__content {
        color: $color-black;
      }
      &__copy {
        text-transform: uppercase;
        line-height: 25px;
        margin: 10px auto;
      }
      &__button__container {
        margin-top: 0px;
        a {
          border: none;
          text-decoration: underline;
        }
      }
    }
  }
}

.offer__kit__formatter--#{$gwp_offer_code} {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
  &.gwp-video-playing {
    .offer__kit__marquee__content {
      position: static;
    }
  }
  .offer__kit__marquee {
    position: static;
  }
  &.step-no-error {
    .offer__kit__cta {
      &.active {
        .offer__kit__button__add {
          @media #{$small-only } {
            background: $color-navy;
            color: $color-white;
          }
        }
      }
    }
  }
  .gwp_bag_section {
    position: relative;
    .offer__kit {
      &__step {
        .offer__kit__footer {
          display: block;
        }
      }
      &__header__container {
        .offer__kit_includes__choose_header {
          top: 38px;
        }
      }
      &__option__container {
        .offer__kit__option--02 {
          .offer__kit__oos {
            @media #{$medium-up} {
              padding-#{$ldirection}: 49%;
              margin-top: 5px;
              @media #{$xlarge-up} {
                padding-#{$ldirection}: 48%;
              }
            }
            @media #{$small-only} {
              padding-#{$ldirection}: 0;
            }
          }
        }
        .offer__kit__option--01 {
          .offer__kit__oos {
            @media #{$medium-up} {
              padding-#{$ldirection}: 48%;
              margin-top: 5px;
              @media #{$xlarge-up} {
                padding-#{$ldirection}: 46%;
              }
            }
            @media #{$small-only} {
              padding-#{$ldirection}: 0;
            }
          }
        }
      }
      &__option {
        @media #{$small-only} {
          width: 100%;
        }
        @media #{$large-up} {
          img {
            width: 100%;
          }
        }
        width: 50%;
        label {
          display: table;
          margin: 0 auto;
          @media #{$small-only} {
            margin: 0;
          }
          p {
            margin: 0;
          }
        }
        .offer__kit__details {
          display: none;
        }
      }
      &__step__content {
        &__header__container {
          &__choose_header {
            @media #{$large-up} {
              text-align: center;
            }
          }
        }
      }
    }
    .offer__kit__loading {
      width: auto;
      position: relative;
    }
  }
  .offer__kit__footer__flyer {
    @media #{$small-only} {
      position: relative;
      .offer__kit__footer__flyer_text {
        bottom: auto;
        top: 138px;
      }
    }
    &_text {
      position: absolute;
      bottom: 70px;
      text-align: center;
      left: 0;
      right: 0;
      @media #{$large-up} {
        bottom: 45px;
      }
      @media #{$medium-down} {
        bottom: 24px;
      }
      a {
        font-weight: bold;
        font-size: 12px;
        font-family: $akzidenz;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        text-decoration: underline;
      }
    }
    &_image_small {
      display: block;
      width: 100%;
      @media #{$medium-up} {
        display: none;
      }
      img {
        width: 100%;
      }
    }
    &_image {
      @include swap_direction(margin, 50px 0 40px);
      display: none;
      width: 100%;
      @media #{$medium-up} {
        display: block;
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
  }
  .offer__kit {
    @media #{$large-up} {
      min-height: 730px;
    }
    &__step--3 {
      margin-bottom: 0;
      position: absolute;
      width: 100%;
      .offer__kit__step__content {
        background: none;
        top: 355px;
        #{$ldirection}: 33px;
        width: 50%;
        @media #{$small-only} {
          @include swap_direction(padding, 2px 0 0 0);
          width: 100%;
          top: 0;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
        }
        @media #{$medium-only} {
          top: 211px;
          #{$ldirection}: 20px;
        }
        @media #{$large-only} {
          top: 276px;
          #{$ldirection}: 20px;
          width: 38%;
        }
      }
      div.offer__kit__header__container {
        position: inherit;
      }
    }
    &__container {
      .offer__kit__footer {
        @include swap_direction(padding, 0 20px);
        display: block;
        position: absolute;
        top: 71%;
        @media #{$medium-up} {
          top: 68%;
        }
        @media #{$large-only} {
          top: 71%;
        }
      }
      @media #{$large-up} {
        @include swap_direction(padding, 0);
      }
      @media #{$large-only} {
        @include swap_direction(padding, 0);
      }
      @media #{$medium-down} {
        @include swap_direction(margin, 0 auto);
        text-align: center;
        padding-top: 0;
      }
      @media #{$medium-only} {
        @include swap_direction(padding, 0);
      }
      &__step {
        clear: both;
        @media #{$large-only} {
          @include swap_direction(padding, 0 20px);
        }
      }
      .offer__kit__option {
        &__container {
          &__product_image_small {
            @media #{$medium-down} {
              width: 100%;
            }
            @media #{$medium-only} {
              display: none;
            }
            @media #{$large-up} {
              display: none;
            }
          }
          &__product_image_wrapper {
            margin-bottom: 45px;
            @media #{$small-only} {
              @include swap_direction(margin, 0);
            }
            & + label {
              @include swap_direction(margin, 0 0 0 5%);
              display: block;
              @media #{$small-only} {
                @include swap_direction(margin, 0 0 0 1%);
              }
            }
          }
          &__product_image {
            @media #{$medium-down} {
              display: none;
            }
            @media #{$medium-only} {
              display: block;
              width: 100%;
            }
            @media #{$large-up} {
              @include swap_direction(margin, 0);
              display: block;
            }
            @media #{$large-only} {
              width: 100%;
            }
          }
        }
        .offer__kit {
          &__oos {
            @media #{$medium-up} {
              padding-#{$ldirection}: 56px;
            }
            @media #{$large-only} {
              padding-#{$ldirection}: 57px;
            }
            @media #{$xlarge-up} {
              padding-#{$ldirection}: 71px;
            }
          }
        }
      }
    }
    &__marquee__content {
      width: 100%;
      left: 0;
      top: 10px;
      color: $color-black;
      text-align: center;
      margin-top: 8%;
      max-width: 100%;
      margin: 0 auto;
      @media #{$medium-up} {
        display: block;
        width: 48%;
        top: 33px;
        text-align: #{$ldirection};
      }
      @media #{$iphone6-landscape} {
        margin-top: 15%;
      }
      @media #{$medium-only} {
        top: 14px;
      }
      .text-promo {
        @media #{$medium-down} {
          @include swap_direction(margin, 3px 0 4px 0);
          font-size: 15px;
        }
        @media #{$medium-only} {
          font-size: 10px;
        }
      }
      @media #{$large-up} {
        width: 49%;
        top: 65px;
        .headline--medium {
          text-transform: uppercase;
          text-align: left;
          padding-top: 0.5%;
        }
        .offer__kit__marquee__callout {
          .text-promo {
            padding-#{$ldirection}: 0;
          }
        }
        .offer__kit__marquee__description {
          margin-top: 0;
          line-height: 35px;
          .text-promo {
            padding-#{$ldirection}: 0;
          }
        }
      }
      @media #{$large-only} {
        top: 33px;
      }
      @media #{$xlarge-up} {
        width: 41%;
        top: 10px;
        #{$ldirection}: 4px;
      }
      .offer__kit__marquee__description {
        font-family: $optimalight;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 2vw;
        &::first-letter {
          text-transform: capitalize;
        }
        @media #{$xxlarge-up} {
          font-size: 14px;
          line-height: 26px;
        }
        @media #{$large-up} {
          font-size: 14px;
          line-height: 25px;
        }
        @media #{$large-only} {
          line-height: 23px;
        }
        @media #{$medium-down} {
          font-size: 22px;
        }
      }
      .offer__kit__marquee__callout {
        @media #{$xxlarge-up} {
          padding-#{$ldirection}: 6px;
        }
      }
      .headline--large {
        padding-top: 1%;
        font-size: 5vw;
        text-transform: uppercase;
        letter-spacing: 1px;
        @media #{$xxlarge-up} {
          font-size: 70px;
        }
        @media #{$medium-down} {
          font-size: 44px;
          padding-top: 16px;
        }
      }
      .headline--medium {
        font-size: 2.5vw;
        letter-spacing: 1px;
        text-align: center;
        @media #{$medium-up} {
          text-align: #{$ldirection};
        }
        @media #{$large-only} {
          padding-bottom: 2%;
        }
        @media #{$xxlarge-up} {
          font-size: 43px;
        }
        @media #{$medium-down} {
          font-size: 24px;
          text-transform: uppercase;
        }
        @media #{$small-only} {
          font-size: 28px;
        }
      }
      &__video {
        .video_content_cta_outer {
          @media #{$medium-only} {
            top: 133px;
          }
          @media #{$xxlarge-up} {
            top: 253px;
          }
        }
      }
      &__info {
        position: absolute;
        z-index: 999;
        padding: 60px 0 0 50px;
        margin-top: 3%;
        width: 15%;
        @media #{$small-only} {
          top: 367px;
          #{$ldirection}: -20px;
        }
        @media #{$iphone6plus-portrait} {
          top: 411px;
        }
        @media #{$landscape} {
          top: 720px;
          #{$ldirection}: 0;
        }
        @media #{$iphone6plus-landscape} {
          top: 802px;
        }
        @media #{$medium-up} {
          top: 110px;
          #{$ldirection}: 264px;
        }
        @media #{$large-up} {
          top: 184px;
          #{$ldirection}: 380px;
        }
        @media #{$xlarge-up} {
          top: 220px;
          #{$ldirection}: 500px;
        }
        &__artist {
          .offer__kit__details__overlay {
            display: none;
            min-width: 354px;
            min-height: 180px;
            padding-bottom: 0;
            @media #{$medium-only} {
              min-width: 272px;
              top: -68px;
            }
            @media #{$small-only} {
              min-width: 252px;
              top: -170px;
              #{$ldirection}: 65px;
            }
          }
        }
        .offer__kit__marquee__content__info__marker {
          background: url(/media/images/common/plus-sign-pc.png) no-repeat;
          width: 30px;
          height: 30px;
          cursor: pointer;
          background-size: contain;
          background-position: initial;
          @media #{$xlarge-up} {
            width: 39px;
            height: 39px;
          }
        }
        &.showInfo {
          .offer__kit__marquee__content__info {
            &__marker {
              background: url(/media/images/common/x-sign-pc.png) no-repeat;
            }
            &__artist {
              .offer__kit__details__overlay {
                display: block;
              }
            }
          }
        }
      }
    }
    &__footer {
      position: relative;
      clear: both;
      @media #{$medium-only} {
        margin-bottom: 25px;
      }
      &__banner_header {
        &_mob,
        &_pc {
          text-transform: uppercase;
          display: none;
        }
        &_mob {
          @media #{$medium-down} {
            @include swap_direction(padding, 20px 55px 25px);
            display: block;
            width: 100%;
            text-align: center;
            .okf_splitter {
              display: block;
            }
            h4 {
              font-size: 35px;
              line-height: 35px;
            }
          }
          @media #{$medium-up} {
            display: none;
            margin-bottom: 15px;
          }
        }
        &_pc {
          @media #{$medium-up} {
            display: block;
          }
        }
      }
      &__banner_text {
        color: $color-black;
        @media #{$medium-down} {
          @include swap_direction(padding, 15px);
          width: 100%;
          text-align: center;
          position: initial;
        }
        @media #{$medium-only} {
          @include swap_direction(padding, 0 0 0 14px);
          text-align: left;
          background: none;
          top: 205px;
          left: 95px;
          font-size: 13px;
          line-height: 16px;
        }
        @media #{$large-up} {
          @include swap_direction(padding, 0 0 0 50px);
          top: 10px;
          #{$ldirection}: 78px;
          line-height: 20px;
          background: none;
          text-align: left;
        }
        @media #{$large-only} {
          @include swap_direction(padding, 0 0 0 15px);
          top: 310px;
          right: 70px;
          font-size: 13px;
          line-height: 15px;
        }
        h4 {
          margin-bottom: 15px;
          @media #{$medium-down} {
            font-size: 27px;
            line-height: 29px;
          }
          @media #{$medium-only} {
            margin-bottom: 3px;
          }
          @media #{$medium-up} {
            width: 60%;
            font-size: 44px;
            line-height: 40px;
          }
          @media #{$xxlarge-up} {
            width: 70%;
            font-size: 64px;
            line-height: 60px;
          }
          span {
            display: block;
          }
          @media #{$large-only} {
            font-size: 24px;
            line-height: 25px;
          }
        }
      }
      &__banner_small_text {
        line-height: 24px;
        padding-left: 5px;
        @media #{$medium-only} {
          line-height: 18px;
        }
        @media #{$large-only} {
          line-height: 21px;
          width: 56%;
        }
        @media #{$medium-only} {
          width: 58%;
        }
        &__offer {
          @include swap_direction(margin, 8px 0 0 5px);
          font-size: 15px;
          letter-spacing: 1.8px;
          @media #{$small-only} {
            margin: 0;
            letter-spacing: 0.1em;
          }
        }
        @media #{$small-only} {
          margin-bottom: 30px;
        }
      }
      &__banner_image {
        display: none;
        width: 100%;
        @media #{$medium-up} {
          display: block;
          margin-bottom: 25px;
        }
        img {
          @media #{$medium-up} {
            width: 100%;
          }
        }
      }
      &__banner_image_small {
        display: block;
        width: 100%;
        @media #{$medium-up} {
          display: none;
        }
        @media #{$medium-down} {
          img {
            width: 100%;
            vertical-align: initial;
          }
        }
      }
    }
    &__details {
      position: relative;
      @media #{$medium-down} {
        text-align: left;
      }
      &__link {
        font-weight: bold;
        padding-#{$ldirection}: 120px;
        text-decoration: underline;
        @media #{$medium-down} {
          font-size: 15px;
        }
        @media #{$medium-only} {
          padding-#{$ldirection}: 60px;
          font-size: 13px;
        }
        @media #{$small-only} {
          padding-#{$ldirection}: 80px;
        }
      }
    }
    &__details__overlay {
      @include swap_direction(padding, 30px 25px 30px 33px);
      width: 90%;
      margin-#{$ldirection}: 44px;
      border: 1px solid $color-black;
      display: none;
      background: $color-white;
      position: absolute;
      z-index: 100;
      @media #{$small-only} {
        @include swap_direction(padding, 22px 22px 22px 36px);
        margin-#{$ldirection}: 20px;
      }
      @media #{$medium-only} {
        @include swap_direction(padding, 22px 22px 22px 32px);
      }
      @media #{$large-up} {
        z-index: 999;
        bottom: 20px;
        width: 95%;
        min-height: 330px;
      }
    }
    &__details__list {
      dt {
        font-weight: bold;
        display: list-item;
        list-style-type: disc;
      }
    }
    &__content {
      @include swap_direction(padding, 15px 30px);
      width: 100%;
      text-align: center;
      @media #{$large-up} {
        @include swap_direction(padding, 0);
        width: 80%;
        text-align: left;
        font-size: 13px;
      }
      @media #{$xxlarge-up} {
        font-size: 15px;
      }
    }
    &__header__container {
      .mpp__header {
        @media #{$large-up} {
          font-size: 28px;
        }
        @media #{$xxlarge-up} {
          font-size: 40px;
        }
        @media #{$medium-down} {
          font-size: 30px;
        }
        @media #{$medium-only} {
          font-size: 18px;
        }
      }
      .offer__kit_includes {
        @media #{$small-only} {
          &__number,
          &_includes__header,
          &_includes__content {
            display: none;
          }
        }
        &__header {
          @media #{$medium-up} {
            width: 44%;
          }
          @media #{$xlarge-up} {
            width: 30%;
            font-size: 30px;
          }
          @media #{$medium-only} {
            width: 35%;
            line-height: 20px;
          }
        }
        &__choose_header {
          border-bottom: none;
          font-size: 3vw;
          text-align: center;
          width: 40%;
          line-height: 40px;
          @media #{$medium-up} {
            top: -28px;
            left: 150px;
          }
          @media #{$medium-only} {
            line-height: 21px;
            padding-bottom: 6px;
          }
          @media #{$medium-up} {
            text-align: #{$ldirection};
          }
          @media #{$small-only} {
            line-height: 34px;
            font-size: 35px;
            width: 80%;
            margin: 0 auto;
          }
          @media #{$xxlarge-up} {
            @include swap_direction(margin, 2px 0 0);
            font-size: 40px;
          }
        }
      }
    }
    &__oos {
      @include swap_direction(padding, 3px 0 0 118px);
      text-align: left;
      @media #{$medium-down} {
        margin-top: 5px;
      }
      @media #{$medium-only} {
        @include swap_direction(padding, 1px 0 0 60px);
      }
      @media #{$small-only} {
        @include swap_direction(margin, 5px 0 0 58px);
        padding-#{$ldirection}: 23px;
      }
    }
    &__details__header {
      padding-bottom: 20px;
    }
    &__option {
      @media #{$medium-up} {
        @include swap_direction(padding, 0 0 25px 0);
        float: left;
        padding-bottom: 0;
        width: 31%;
      }
      @media #{$medium-only} {
        width: 33%;
      }
      @media #{$large-up} {
        @include swap_direction(padding, 0 0 25px 0);
        float: left;
        padding-bottom: 0;
        width: 33.33%;
      }
      p {
        @include swap_direction(margin, 15px 45px 15px 120px);
        text-transform: none;
        width: 53%;
        @media #{$medium-only} {
          @include swap_direction(margin, 15px 0px 15px 60px);
        }
        @media #{$xxlarge-up} {
          width: 43%;
        }
        @media #{$medium-down} {
          text-align: left;
        }
        @media #{$small-only} {
          @include swap_direction(margin, 0 0 8px 77px);
          width: 61%;
        }
      }
      &:first-child {
        p {
          @media #{$small-only} {
            width: 63%;
          }
        }
      }
      &__details {
        @media #{$large-up} {
          text-align: left;
        }
      }
      .label {
        padding-#{$ldirection}: 40px;
        margin-#{$ldirection}: 75px;
        @media #{$small-only} {
          @include swap_direction(margin, 0 0 8px 35px);
        }
        @media #{$medium-down} {
          padding-top: 0;
        }
        @media #{$medium-only} {
          @include swap_direction(margin, 0 0 0 15px);
          font-size: 26px;
        }
        &::before {
          width: 28px;
          height: 28px;
          border: 1px solid $color-darker-gray;
          top: 5px;
        }
        &::after {
          width: 28px;
          height: 28px;
          background-image: url(/sites/esteelauder/themes/estee_base/img/sprites/checkbox_gwp_transparent.png);
          top: 5px;
        }
      }
      input[type='checkbox'] {
        &.disabled:not(:checked) {
          ~ .label {
            color: $color-navy;
          }
        }
      }
    }
    &__option__label {
      font-size: 30px;
      text-align: left;
      border: none;
      text-transform: none;
      @media #{$large-up} {
        font-size: 22px;
      }
      @media #{$xxlarge-up} {
        font-size: 32px;
      }
    }
    &__cta {
      width: 100%;
      text-align: left;
      .offer__kit__button__add {
        display: none;
        width: auto;
      }
      .loading_text {
        background: $color-white;
        height: 45px;
        display: none;
        width: fit-content;
        margin: 0 auto;
        @media #{$large-up} {
          @include swap_direction(margin, 0 37%);
          height: 30px;
          width: 100%;
          background: none;
        }
        .offer__kit__loading {
          left: 0;
          @media #{$small-only} {
            left: 0;
            right: 0;
          }
          @media #{$medium-up} {
            margin-#{$ldirection}: 10px;
          }
        }
      }
    }
    &__extra__step {
      @media #{$large-up} {
        @include swap_direction(padding, 0);
      }
    }
    &__button__add {
      display: none;
      @media #{$large-up} {
        position: relative;
        width: 100%;
      }
      @media #{$medium-down} {
        width: 100%;
      }
    }
    &__cta.active {
      .offer__kit__button__add {
        display: inline-block;
        width: auto;
        background: transparent;
        color: $color-black;
        @media #{$xxlarge-up} {
          height: 40px;
          padding: 3px 20px;
        }
        @media #{$small-only} {
          display: block;
          width: 80%;
          margin: 0 auto;
        }
      }
      &.offer__kit__is__loading {
        .loading_text {
          display: block;
        }
        .offer__kit__button__add {
          display: none;
        }
      }
    }
    &__button__add:hover {
      @media #{$large-up} {
        background: $color-navy;
        color: $color-white;
      }
    }
    &__step__content {
      color: $color-navy;
      background-image: url(/media/export/cms_2.0/offers/gwp_su18/bg_product_kit.png);
      background-position: top;
      background-repeat: no-repeat;
      background-color: transparent;
      @media #{$medium-down} {
        padding-top: 20px;
        background-color: $color-gwp-background;
        background-image: none;
      }
      @media #{$medium-down} {
        padding-top: 5px;
      }
      .offer__kit__header__container {
        padding: 0 20px;
        .mpp__header__content {
          @media #{$small-only } {
            width: 78%;
            margin: 0 auto;
          }
        }
      }
      @media #{$medium-only} {
        float: none;
        margin-top: 0;
        background-size: 100% 33%;
        background-image: url(/media/export/cms_2.0/offers/gwp_su18/bg_product_kit.png);
        background-color: transparent;
      }
      @media #{$large-up} {
        margin-top: 0;
        width: 35%;
        background-size: 100% 46%;
      }
      @media #{$large-only} {
        width: 89%;
        margin-bottom: 20px;
        background-size: 100% 39%;
      }
      @media #{$xlarge-up} {
        margin-top: 0;
        width: 100%;
      }
      @media #{$xxlarge-up} {
        width: 92%;
      }
      .offer__kit__button__add {
        display: none;
      }
      .offer__kit__error {
        @include swap_direction(margin, 10px 0 20px 0);
        color: $color-red;
        text-align: center;
        @media #{$medium-up} {
          @include swap_direction(margin, 15px 0 5px 0);
          text-align: #{$ldirection};
        }
      }
      .offer__kit {
        &__option__container {
          .offer__kit {
            &__option--01 {
              @media #{$medium-only} {
                @include swap_direction(margin, 0 55px 0 0);
              }
            }
            &__option--02 {
              @media #{$medium-up} {
                @include swap_direction(margin, 0 0 0 47px);
              }
            }
          }
        }
      }
    }
    &__notice__container {
      position: relative;
      .offer__kit__notice {
        @include swap_direction(margin, 26px 0 40px 0);
        line-height: 24px;
        width: auto;
        @media #{$small-only} {
          @include swap_direction(margin, 20px 0);
        }
        @media #{$medium-only} {
          @include swap_direction(margin, 7px 0);
          float: #{$ldirection};
        }
        @media #{$large-only} {
          @include swap_direction(margin, 14px 0);
        }
      }
    }
    &__option__container {
      @media #{$medium-down} {
        width: 100%;
      }
      @media #{$small-only} {
        padding-top: 15px;
      }
    }
    &__image__container {
      width: 25%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
//spring2018 end

.offer__container_option_row {
  .offer__kit {
    &__option {
      &--02 {
        @include swap_direction(margin, 39px 0 0 47px);
        @media #{$small-only} {
          @include swap_direction(margin, 3px 0 0 0);
        }
        @media #{$large-only} {
          margin-top: 20px;
        }
        @media #{$medium-only} {
          @include swap_direction(margin, 13px 0 0 47px);
        }
      }
      @media #{$medium-only} {
        width: 40%;
      }
      @media #{$xxlarge-up} {
        width: 46.5%;
      }
      p {
        @media #{$medium-up} {
          @include swap_direction(margin, 15px 45px 15px 41px);
        }
        @media #{$medium-only} {
          @include swap_direction(margin, 0 45px 6px 41px);
          line-height: 20px;
        }
      }
      .label {
        @media #{$medium-up} {
          margin-#{$ldirection}: 0;
        }
      }
      &__container {
        @media #{$medium-up} {
          @include swap_direction(padding, 0 2px);
          .offer__kit__option__container {
            &__product_image_wrapper {
              @include swap_direction(margin, 0 0 5px 0);
              float: none;
              position: absolute;
              @media #{$medium-only} {
                @include swap_direction(margin, 0);
              }
              img {
                @include swap_direction(margin, 0);
                width: 75%;
                @media #{$large-only} {
                  @include swap_direction(margin, 0);
                }
                @media #{$medium-only} {
                  width: 65%;
                }
              }
              @media #{$medium-up} {
                top: 0;
                position: inherit;
              }
              @media #{$xlarge-up} {
                top: 0;
                position: inherit;
              }
            }
          }
        }
      }
      .offer__kit__oos {
        @media #{$medium-up} {
          padding-#{$ldirection}: 50px;
        }
        @media #{$large-only} {
          padding-#{$ldirection}: 56px;
        }
        @media #{$xlarge-up} {
          padding-#{$ldirection}: 68px;
        }
      }
    }
    &__step__content {
      position: absolute;
      background-size: 0;
      background-color: transparent;
      z-index: 99;
      @media #{$small-only} {
        top: 24%;
      }
      @media #{$medium-up} {
        top: 20%;
        #{$ldirection}: 15px;
      }
      @media #{$xlarge-up} {
        top: 19%;
        left: 42px;
      }
    }
    &__details {
      @media #{$medium-up} {
        .offer__kit {
          &__details__link {
            padding-#{$ldirection}: 19%;
            @media #{$medium-only} {
              padding-#{$ldirection}: 19%;
            }
          }
          &__details__overlay {
            width: 55%;
          }
        }
      }
      .offer__kit {
        &__details__overlay {
          @media #{$medium-only} {
            width: 100%;
          }
          @media #{$large-only} {
            width: 100%;
          }
        }
      }
      @media #{$xlarge-up} {
        .offer__kit {
          &__details__link {
            padding-#{$ldirection}: 12%;
          }
        }
      }
    }
    &__marquee__content {
      .offer__kit {
        &__marquee__callout {
          @media #{$medium-down} {
            @include swap_direction(margin, 3px 0 7px 0);
          }
        }
        &__marquee__description {
          font-family: $akzidenz;
          letter-spacing: 0.1em;
          color: $color-navy;
          @media #{$medium-only} {
            letter-spacing: 0.001em;
            font-size: 9px;
          }
          @media #{$small-only} {
            line-height: 24px;
          }
          @media #{$medium-down} {
            margin: 8px 0 4px 3px;
            font-size: 15px;
          }
          @media #{$large-only} {
            font-size: 15px;
          }
        }
        &__marquee__content__video {
          .video_content_cta_outer {
            @media #{$small-only} {
              top: 414px;
            }
            @media #{$iphone6plus-landscape} {
              top: 877px;
            }
            @media #{$iphone6-landscape} {
              top: 695px;
            }
            @media #{$iphone6plus-portrait} {
              top: 467px;
            }
          }
        }
      }
      .headline--large {
        @media #{$small-only} {
          text-transform: uppercase;
          font-size: 65px;
        }
        @media #{$medium-down} {
          @include swap_direction(padding, 6px 0 0);
          letter-spacing: -0.05em;
        }
        @media #{$medium-only} {
          padding: 0;
        }
      }
    }
  }
}
