@mixin menu-item-link-hover {
  @include print-size-tracking(15px, 50);
  line-height: 17px;
  color: $glux-color-submenu-text;
  a {
    color: $glux-color-submenu-text;
    &:hover {
      text-decoration: underline;
    }
  }
  &:hover > span,
  &:hover > a {
    color: $glux-color-submenu-text;
  }
}

@mixin menu-item-link-hover-secondary {
  color: $color-white;
  a {
    color: $color-white;
  }
  &:hover > span,
  &:hover > a {
    color: $color-white;
  }
}

@mixin menu-item-category-hover {
  @include print-size-tracking(12px, 15);
  color: $glux-color-submenu-text;
  a {
    color: $glux-color-submenu-text;
  }
  &:hover > span,
  &:hover > a {
    color: $glux-color-submenu-text;
  }
}

@mixin menu-item-category-hover-secondary {
  color: $color-white;
  a {
    color: $color-white;
  }
  &:hover > span,
  &:hover > a {
    color: $color-white;
  }
}

@mixin menu-item-tout-image-hover {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

@mixin menu-item-link() {
  font-size: 3.75vw;
  line-height: 5.625vw;
  letter-spacing: 0;
  font-family: $akzidenz;
  @media #{$device-ipad_mini} {
    font-size: 18px;
    line-height: 1.3;
  }
  @media #{$xlarge-up} {
    @include menu-item-link-hover;
    font-family: $akzidenz;
  }
}

@mixin menu-item-category() {
  font-family: $akzidenz;
  font-size: 3.75vw;
  line-height: 5.625vw;
  letter-spacing: 0;
  font-weight: bold;
  @media (min-width: $large-min) {
    font-weight: normal;
  }
  @media #{$device-ipad_mini} {
    font-size: 18px;
    line-height: 1.3;
  }
  @media #{$xlarge-up} {
    @include menu-item-category-hover;
    line-height: 17px;
    font-weight: bold;
    letter-spacing: 0.15em;
  }
}

@mixin menu-item-tout-image() {
  p {
    @include print-size-tracking(15px, 50);
    line-height: 18px;
    font-family: $akzidenz;
    font-weight: normal;
    margin: 0;
  }
  @media #{$xlarge-up} {
    @include menu-item-tout-image-hover;
  }
}

@mixin menu-items-formatter(
  $max_width,
  $min_padding,
  $text-align: justify,
  $max-width-image-optional: none,
  $override-max-image-width: false,
  $max-width-column-optional: none,
  $override-max-column-width: false
) {
  text-align: $text-align;
  &:after {
    content: ' ';
    width: 100%;
    display: inline-block;
  }
  & > li {
    float: $ldirection;
    vertical-align: top;
    text-align: $ldirection;
  }
  & > li.pc-hidden {
    display: none;
  }
  & > li.menu__item--column {
    li.menu-item-image {
      max-width: none;
      margin-top: 0;
      padding: 0;
      @if $override-max-column-width == true {
        padding: 0;
        margin-bottom: 0;
        max-width: $max-width-column-optional;
      }
      img {
        width: 100%;
        height: auto;
        max-width: 234px;
        @if $override-max-image-width == true {
          max-width: $max-width-image-optional;
        }
      }
      .menu-item-image__content {
        padding-left: 10px;
      }
    }
  }
  & > li {
    max-width: calc(#{$max_width} - #{$min_padding});
    margin-#{$ldirection}: 0;
    width: 100%;
  }
  & > li:first-child {
    margin-#{$ldirection}: 0px;
  }
}

@mixin menu-items-formatter-tout-image(
  $max_width,
  $min-padding-tout,
  $max-width-tout-optional,
  $override-max-width: false
) {
  & > li.menu__item {
    &.menu-item-image {
      max-width: calc(#{$max_width} - #{$min-padding-tout});
      width: 100%;
      margin-#{$rdirection}: 15px;
      img {
        width: 100%;
        height: auto;
      }
      @media #{$xxlarge-up} {
        @if $override-max-width == true {
          max-width: $max-width-tout-optional;
        }
        &:first-child {
          margin-#{$ldirection}: -26px;
        }
      }
    }
  }
}

@mixin menu-item-title-hover {
  content: ' ';
  position: absolute;
  height: 1px;
  width: 100%;
  background: $glux-color-menu-title;
  #{$ldirection}: 0;
  bottom: 0px;
  z-index: 1;
  body.section-re-nutriv &,
  #{$renutriv} & {
    background: $color-white;
  }
  #{$alt} & {
    body.section-re-nutriv &,
    #{$renutriv} & {
      background: $glux-color-menu-title;
    }
  }
}

@mixin header-alternate-background {
  .page-header#{$alt} {
    background: $color-white;
    .page-navigation {
      &__menu-toggle {
        background-color: $color-white;
        border-color: $color-light-gray;
      }
      &__menu-icon {
        background: url($svg-path + 'close.svg') no-repeat;
        background-size: auto auto;
        height: 20px;
        width: 20px;
      }
      &__menu_text {
        color: $glux-color-menu-navigation;
      }
    }
  }
  &.section-re-nutriv,
  &.brand-renutriv {
    .page-header#{$alt} {
      background: transparent;
      .page-navigation {
        background: $color-white;
        &__menu-toggle {
          background-color: $color-white;
          border-color: $color-light-gray;
        }
        &__menu-icon {
          background-position: 3px 2px;
        }
        &__menu_text {
          color: $glux-color-menu-navigation;
        }
      }
    }
  }
  &.foundation-finder-page {
    .page-header {
      &#{$alt} {
        background: $color-white;
        &#{$sticky} {
          background: $color-white;
        }
        .page-navigation {
          &__menu-toggle {
            background-color: $color-white;
            border-color: $color-light-gray;
          }
          &__menu_text {
            color: $glux-color-menu-navigation;
          }
        }
      }
    }
  }
}

@mixin header-alternate-background-mobile {
  .page-header#{$alt} {
    background: transparent;
    z-index: 999999;
  }
  &.section-re-nutriv,
  &.brand-renutriv {
    .page-header#{$alt} {
      background: transparent;
    }
  }
  &.foundation-finder-page {
    .page-header#{$alt} {
      background: transparent;
    }
  }
}

@mixin header-alternate-background-search-open {
  &#{$search} {
    &#{$alt-color-white} {
      background: $color-white;
      &#{$sticky} {
        background: $color-white;
        .foundation-finder-page & {
          background: $color-white;
        }
      }
      .page-branding {
        margin-#{$ldirection}: 0;
      }
      s .page-navigation {
        background-color: transparent;
      }
      .section-re-nutriv &,
      .brand-renutriv & {
        background-color: $color-white;
        .page-navigation {
          background-color: $color-white;
        }
        .page-utilities {
          .page-utilities__search-icon {
            background-image: url($base-theme-image-path + 'sprites/search_blue.png');
          }
        }
      }
      .foundation-finder-page & {
        background: $color-white;
        .page-navigation {
          background-color: $color-white;
        }
      }
    }
  }
}

@mixin menu-ref-title-color {
  color: $glux-color-menu-title;
  a {
    @media #{$xlarge-up} {
      color: $glux-color-menu-title;
    }
  }
  .section-re-nutriv &,
  .brand-renutriv & {
    color: $color-white;
    a {
      color: $color-white;
    }
  }
  #{$dark-nav} & {
    color: $color-white;
    a {
      color: $color-white;
    }
  }
  #{$alt} & {
    color: $glux-color-menu-title;
    a {
      color: $glux-color-menu-title;
    }
    .section-re-nutriv &,
    .brand-renutriv & {
      color: $glux-color-menu-title;
      a {
        color: $glux-color-menu-title;
      }
    }
  }
}

@mixin gluxre_update__navigation {
  @media #{$large-down} {
    .page-navigation {
      &.active-menu-toogle {
        #{$ldirection}: -120%;
        position: absolute;
        display: block;
        overflow: auto;
        width: 85%;
        margin-top: 0;
        height: 100%;
      }
    }
    .page-navigation__menu {
      margin-bottom: 190px;
      -webkit-overflow-scrolling: touch;
    }
    body.mobile-gnav-active {
      @include header-alternate-background;
      @include header-alternate-background-mobile;
      overflow: hidden;
      .page-navigation {
        #{$ldirection}: 0;
        top: auto;
        transition: $ldirection $default-transition-duration;
        position: fixed;
        height: 100%;
        background-color: $color-white;
        z-index: 88;
      }
    }
  }
  @media #{$small-only} {
    .page-navigation {
      &.active-menu-toogle {
        margin-top: 0;
      }
    }
  }
  @media #{$xlarge-up} {
    body.gnav-active {
      @include header-alternate-background;
      .page-navigation__menu-toggle {
        display: none;
      }
      .page-navigation {
        &.active-menu-toogle {
          background-color: transparent;
        }
      }
    }
    .page-header {
      @include header-alternate-background-search-open;
    }
  }
  .page-navigation__menu {
    text-align: $ldirection;
    position: relative;
    .menu-ref {
      font-size: 11px;
      color: $glux-color-menu-text;
      display: inline-block;
      @media #{$xlarge-up} {
        padding: 0 1em;
        &:first-child {
          padding-#{$ldirection}: 19px;
        }
      }
      // HEADER LINK
      &__title {
        @include menu-ref-title-color;
        font-size: 3.75vw;
        line-height: 5.625vw;
        letter-spacing: 0.15em;
        position: relative;
        font-family: $akzidenz;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;
        @media #{$device-ipad_mini} {
          font-size: 18px;
          line-height: 1.3;
        }
        @media #{$xlarge-up} {
          font-size: 12px;
          letter-spacing: 0.15em;
          padding-bottom: 1px;
          line-height: normal;
        }
      }
      &:hover .menu-ref__title {
        @media #{$xlarge-up} {
          &:before {
            @include menu-item-title-hover;
          }
        }
      }
      &--close {
        display: none;
        position: fixed;
        z-index: 99;
        top: 0;
        #{$rdirection}: 0;
        font-size: 20px;
        padding-#{$ldirection}: 6px;
        color: $color-white;
        width: 15%;
        background-color: $color-black;
        text-align: center;
        @media #{$medium-up} {
          height: $glux-ipad-header-height;
        }
        @media #{$xlarge-up} {
          height: $glux-medium-up-header-height;
        }
      }
      &:hover .menu--lvl-2 {
        @media #{$xlarge-up} {
          display: none;
        }
      }
      .menu__item {
        @include menu-item-link;
        @media #{$xlarge-up} {
          padding: 0px;
          margin-bottom: 9px;
        }
        &--link {
          @include menu-item-link;
        }
        &--category {
          @include menu-item-category;
        }
      }
      .contextual-links-region {
        position: static;
      }
      /* spread out menu background for wide screen
      */
      .menu--lvl-2 {
        @media #{$xlarge-up} {
          &:before {
            content: ' ';
            position: absolute;
            height: 100%;
            width: 100vw;
            background: $glux-color-menu-background;
            #{$ldirection}: 50%;
            z-index: -1;
            transform: translate(-50%, 0);
            top: 0px;
          }
        }
      }
      .menu--lvl-5 {
        overflow: auto;
      }
      .menu--lvl-2,
      .menu--lvl-4 {
        display: none;
        width: 100%;
        @media #{$xlarge-up} {
          width: 100%;
          background: $glux-color-menu-background;
        }
        z-index: 100;
        text-align: $ldirection;
        position: absolute;
        top: -25px;
        #{$ldirection}: 111%;
        height: 100%;
        & > .menu__item:first-child {
          @media #{$large-down} {
            padding-top: 2.2em;
          }
          @media #{$device-tablet-down} {
            padding-top: 1.5em;
          }
        }
        @media #{$xlarge-up} {
          @include swap_direction(padding, 40px 50px 20px 20px);
          #{$ldirection}: 0;
          top: auto;
          line-height: 2em;
          margin-top: 0;
        }
        @media #{$xlarge-up} {
          &.menu--column-width__ {
            &20 {
              @include menu-items-formatter(20%, 10px);
              @include menu-items-formatter-tout-image(20%, 70px, 0);
            }
            &25 {
              @include menu-items-formatter(25%, 10px);
              @include menu-items-formatter-tout-image(25%, 30px, 284px, true);
            }
            &33 {
              @include menu-items-formatter(33%, 100px, $ldirection);
              @include menu-items-formatter-tout-image(33%, 30px, 0);
            }
          }
        }
        @media #{$xlarger-only} {
          &.menu--column-width__20 {
            @include menu-items-formatter-tout-image(20%, 70px, 0);
          }
        }
        .menu--lvl-3,
        .menu--lvl-5 {
          .menu__item--category {
            @media #{$xlarge-up} {
              &.menu__item--category-expandable {
                &:not(:first-child) {
                  // margin to make sections distcint
                  margin-top: 19px;
                }
              }
            }
          }
          .menu-item-image {
            float: none;
            &.mobile-hidden {
              @media #{$large-down} {
                display: none;
              }
            }
            @media #{$xlarge-up} {
              margin-top: 40px;
            }
          }
        }
        .menu-item-image {
          @include menu-item-tout-image;
          &__title {
            @include print-size-tracking(12px, 15);
            font-weight: bold;
            line-height: 20px;
            padding-top: 4px;
            @media #{$xlarge-up} {
              letter-spacing: 0.15em;
            }
          }
          &__description {
            @include print-size-tracking(15px, 50);
            font-weight: normal;
            line-height: 18px;
          }
          @media #{$xlarge-up} {
            float: $rdirection;
          }
          @media #{$large-down} {
            margin-top: 30px;
            padding: 0 18px 10px;
            display: none;
          }
          @media #{$device-ipad_only} {
            padding-#{$ldirection}: 25px;
            padding-#{$rdirection}: 25px;
          }
          &.pc-hidden {
            @media #{$large-down} {
              display: block;
            }
          }
        }
        a {
          border: none;
        }
        & > li {
          @media #{$xlarge-up} {
            padding: 0px;
            margin-top: -1px;
            float: $ldirection;
            clear: none;
          }
          &.menu__item--category-expandable {
            @media #{$large-down} {
              @include swap_direction(padding, 0 0 0 10px);
            }
          }
        }
      }
      .menu--lvl-2 {
        height: auto;
      }
      .menu--lvl-4 {
        top: 25px;
      }
    } //end menu-ref
    .menu-ref,
    .menu__item--lvl-3,
    .menu__item--lvl-5 {
      @media #{$large-down} {
        padding: 10px 18px;
        box-sizing: content-box;
        display: block;
        z-index: 1000;
        color: $glux-color-menu-text;
        background: $color-white;
        height: auto;
        line-height: normal;
        vertical-align: middle;
        border-bottom: $glux-color-menu-border-gray 1px solid;
      }
      @media #{$device-ipad_only} {
        padding-#{$ldirection}: 25px;
      }
      &.menu__item--back {
        background-color: $color-lighter-gray;
        @media #{$large-down} {
          font-size: 3.75vw;
          line-height: 5.625vw;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          font-family: $akzidenz;
          font-weight: bold;
          height: auto;
          vertical-align: middle;
          position: relative;
          text-indent: 30px;
          &::before {
            content: '';
            position: absolute;
            width: 22px;
            height: 22px;
            #{$ldirection}: 12px;
            transform: rotate(180deg);
            background-color: $color-navy;
            @include svg-icon-inline-mask('arrow-right.svg');
          }
          &-lvl--4 {
            text-transform: none;
            font-size: 3.75vw;
            line-height: 5.625vw;
            letter-spacing: 0.15em;
          }
        }
        @media #{$device-ipad_mini} {
          font-size: 18px;
          line-height: 1.3;
          &-lvl--4 {
            font-size: 18px;
            line-height: 1.3;
          }
        }
      }
    }
    .menu-ref__title,
    .menu__item--category-expandable {
      margin: 0;
      position: relative;
      @media #{$xlarge-up} {
        padding-top: 0px;
      }
    }
    .menu-ref--has-children .menu-ref__title,
    .menu__item--category-expandable {
      color: $glux-color-menu-text;
      &:after {
        position: absolute;
        font-size: 11px;
        top: 50%;
        transform: translate(0, -50%);
      }
      @media #{$xlarge-up} {
        color: $glux-color-menu-text;
      }
    }
    .menu-ref--has-children .menu-ref__title {
      @include menu-ref-title-color;
    }
    .menu-ref--has-children .menu-ref__title,
    .menu__item--category-expandable {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 22px;
        height: 22px;
        background-color: $color-navy;
        #{$rdirection}: 0;
        @include svg-icon-inline-mask('arrow-right.svg');
        @media #{$xlarge-up} {
          display: none;
        }
      }
    }
    .menu__item--lvl-3::after {
      margin-#{$rdirection}: 15px;
      @media #{$medium-up} {
        margin-#{$rdirection}: 0;
      }
    }

    // targets submenus
    .menu--lvl-2 {
      .menu-ref.menu-ref--has-children > .menu-ref__title,
      .menu__item--category-expandable {
        @media #{$xlarge-up} {
          @include print-size-tracking(12px, 30);
        }
      }
      .menu__item--category-expandable {
        @media #{$xlarge-up} {
          letter-spacing: 0.15em;
        }
      }
    }
    .menu-ref__title a {
      border: none;
      text-decoration: none;
      @media #{$xlarge-up} {
        font-size: 12px;
      }
    }
  }
  body.section-re-nutriv,
  #{$renutriv} {
    .page-navigation__menu {
      .menu-ref {
        background: transparent;
        .menu--lvl-2,
        .menu--lvl-4 {
          background: $color-white;
        }
        .menu--lvl-2 {
          &:before {
            background: $color-white;
          }
        }
        .menu__item {
          &--category-expandable {
            color: $glux-color-submenu-text;
          }
        }
      }
      .menu-ref,
      .menu__item--lvl-3,
      .menu__item--lvl-5 {
        .menu__item--back {
          @media #{$large-down} {
            &:before {
              background-position: 2px -25px;
            }
          }
        }
      }
      .menu-ref--has-children .menu-ref__title,
      .menu__item--category-expandable {
        @media #{$large-down} {
          &:after {
            background-position: 2px -52px;
          }
        }
      }
    }
  }
}

// GNAV_AUDIT Fixes
.page-navigation__menu .menu__item--lvl-3.menu__item--back-lvl--4 {
  font-weight: bold;
  @media (min-width: $large-min) {
    font-weight: normal;
  }
}
