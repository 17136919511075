$color-shade-picker-gold: mix(#caa04e, #f8e4ab);
// SWATCH MIXIN - SINGLE TO MULTI SWATCH
@mixin swatches($swatch-total, $swatch-half, $swatch-third, $swatch-two-third, $swatch-offset) {
  .swatch__container {
    @include border-radius($swatch-half); /* top left, top right, bottom right, bottom left */
    #{$ie8} & {
      @include border-radius($swatch-half);
    }
    position: relative;
    @include swap_direction(margin, $swatch-offset 0 0 $swatch-offset);
    // #{$renutriv} & {
    //   @include swap_direction(margin, 6px 0 0 7px);
    // }
    width: $swatch-total;
    height: $swatch-total;
    overflow: hidden;
    z-index: 6;
    > div {
      //@include border-radius($swatch-half); /* top left, top right, bottom right, bottom left */
      position: absolute;
    }
    .swatch--1 {
      z-index: 1;
    }
    .swatch--2 {
      z-index: 2;
    }
    .swatch--3 {
      z-index: 3;
    }
    .swatch--4 {
      z-index: 4;
    }
    .swatch--5 {
      z-index: 5;
    }
  }
  .swatches--single {
    .swatch--2,
    .swatch--3,
    .swatch--4,
    .swatch--5 {
      display: none;
    }
  }
  .swatch--1 {
    @include border-radius($swatch-half);
    #{$ie8} & {
      @include border-radius($swatch-half);
    }
    top: 0;
    #{$ldirection}: 0;
    height: $swatch-total;
    width: $swatch-total;
  }
  .swatches--duo {
    .swatch--2 {
      top: 0;
      #{$rdirection}: 0;
      height: $swatch-total;
      width: $swatch-half;
    }
    .swatch--3,
    .swatch--4,
    .swatch--5 {
      display: none;
    }
  }
  .swatches--trio {
    .swatch--2 {
      top: 0;
      #{$rdirection}: 0;
      height: $swatch-total;
      width: $swatch-two-third;
    }
    .swatch--3 {
      #{$rdirection}: 0;
      height: $swatch-total;
      width: $swatch-third;
    }
    .swatch--4,
    .swatch--5 {
      display: none;
    }
  }
  .swatches--quad {
    .swatch--2 {
      top: 0;
      #{$ldirection}: 0;
      height: $swatch-half;
      width: $swatch-total;
    }
    .swatch--3 {
      top: 0;
      #{$rdirection}: 0;
      height: $swatch-half;
      width: $swatch-half;
    }
    .swatch--4 {
      bottom: 0;
      #{$rdirection}: 0;
      height: $swatch-half;
      width: $swatch-half;
    }
    .swatch--5 {
      display: none;
    }
  }
  .swatches--quint {
    .swatch--2 {
      top: 0;
      #{$ldirection}: 0;
      height: $swatch-third;
      width: $swatch-total;
    }
    .swatch--3 {
      top: 0;
      #{$rdirection}: 0;
      height: $swatch-total;
      width: $swatch-third;
    }
    .swatch--4 {
      bottom: 0;
      #{$rdirection}: 0;
      height: $swatch-third;
      width: $swatch-total;
    }
    .swatch--5 {
      bottom: 0;
      #{$ldirection}: 0;
      height: $swatch-two-third;
      width: $swatch-third;
    }
  }
}

// SHARED
ul.shade-list {
  @include pie-clearfix;
  list-style-type: none;
  @include swap_direction(margin, 8px auto);
  @include swap_direction(padding, 0);
  li {
    position: relative;
    float: $ldirection;
    display: block;
    a.swatch {
      position: absolute;
      display: block;
      &:hover &:active,
      &:focus {
        outline: 0;
      }
    }
  }
  @media #{$medium-up} {
    @include swap_direction(margin, 0);
  }
}

// SPP + QUICKSHOP
#{$renutriv} ul.shade-list,
.page-product ul.shade-list,
.product-full ul.shade-list,
.spp-product ul.shade-list,
.quickshop ul.shade-list,
.device-mobile .quickshop-inline ul.shade-list {
  @include swatches(
    $spp-swatch-total,
    $spp-swatch-half,
    $spp-swatch-third,
    $spp-swatch-two-third,
    $swatch-offset-mobile
  );
  width: 268px;
  @include swap_direction(margin, 16px auto 0 auto);
  @media #{$medium-up} {
    @include swatches(
      $spp-swatch-total,
      $spp-swatch-half,
      $spp-swatch-third,
      $spp-swatch-two-third,
      $spp-swatch-offset
    );
    width: 343px; //35px * 9 + 28px
  }
  li {
    height: $spp-swatch-container-mobile;
    width: $spp-swatch-container-mobile;
    @include swap_direction(margin, 7px 9px 7px 7px);
    &:nth-child(5n + 5) {
      @include swap_direction(margin, 7px);
      clear: $rdirection;
    }
    @media #{$medium-up} {
      height: $spp-swatch-container;
      width: $spp-swatch-container;
      @include swap_direction(margin, 6px 7px 6px 0);
      &:nth-child(5n + 5) {
        @include swap_direction(margin, 6px 7px 6px 0); //reset
        clear: none;
      }
    }
    a.swatch {
      &.swatch--selected {
        top: 0;
        #{$ldirection}: 0; //1px offset?
        z-index: 10;
        height: $spp-swatch-container-mobile;
        width: $spp-swatch-container-mobile;
        &.selected {
          @include swap_direction(border, 1px solid $color-dark-navy);
          @include border-radius($spp-swatch-container-mobile/2);
          #{$ie8} & {
            @include border-radius(19px);
            height: 28px;
            width: 28px;
            @include swap_direction(border, 1px solid #e1c27c);
            color: #e1c27c;
          }
        }
        @media #{$medium-up} {
          height: $spp-swatch-container;
          width: $spp-swatch-container;
          &:hover,
          &.selected {
            #{$ie8} & {
              @include border-radius(19px);
              height: 28px;
              width: 28px;
              @include swap_direction(border, 1px solid #e1c27c);
              color: #e1c27c;
            }
            .borderradius & {
              @include swap_direction(border, 1px solid $color-shade-picker-gold);
            }
            #{$estee_edit} & {
              .borderradius & {
                border: 1px solid $color-blue--estee_edit;
              }
            }
            .no-borderradius & {
              background-size: auto auto;
              background: url('/media/images/global/sprites-s38a9885fce.png') 0 -8374px no-repeat;
            }
          }
          &:hover {
            @include swap_direction(border, 1px solid $color-dark-navy);
            @include border-radius($spp-swatch-container-mobile/2);
          }
        }
      }
    }
  }
}

//left align
.colorbox__quickshop .quickshop ul.shade-list {
  @include swap_direction(margin, 16px 0 0 0);
}

// MPP
// mpp shade picker
.cart-confirm__shade,
.product_brief ul.shade-list,
.mpp-compare__swatch-list ul.shade-list,
.mpp-compare__cart-print {
  @include swatches(
    $mpp-swatch-total,
    $mpp-swatch-half,
    $mpp-swatch-third,
    $mpp-swatch-two-third,
    $mpp-swatch-offset
  );
  height: auto;
  overflow: hidden; //
  width: 245px; // has a container with overflow hidden of 226px;
  li,
  div.mpp-compare__swatch-container {
    width: $mpp-swatch-container;
    height: $mpp-swatch-container;
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 4px 16px 4px 0);
    #{$renutriv} & {
      margin-#{$rdirection}: 16px;
    }
    a.swatch {
      &.swatch--selected {
        top: 0;
        #{$ldirection}: 0; //1px offset?
        height: $mpp-swatch-container;
        width: $mpp-swatch-container;
        z-index: 10;
        #{$ie8} & {
          @include border-radius(19px);
          height: 28px;
          width: 28px;
          @include swap_direction(border, 1px solid #e1c27c);
          color: #e1c27c;
        }
        #{$renutriv} & {
          // @include estee-sprites('mpp_shade--black',false);
          //@include swap_direction(border, solid 1px $color-black);
        }
        &:hover,
        &.selected {
          .borderradius & {
            @include swap_direction(border, 1px solid $color-shade-picker-gold);
            @include border-radius($mpp-swatch-container / 2);
            #{$ie8} & {
              @include border-radius(19px);
              height: 28px;
              width: 28px;
              @include swap_direction(border, 1px solid #e1c27c);
              color: #e1c27c;
            }
          }
          .no-borderradius & {
            background-size: auto auto;
            background-position: 0 -6746px;
          }

          #{$renutriv} & {
            // @include estee-sprites('mpp_shade--black',false);
            // @include border-radius($mpp-swatch-container / 2);
            // @include box-sizing('content-box');
            // @include swap_direction(border, solid 1px $color-gold--renutriv);
          }
        }
      }
    }
  }
}

.mpp-compare__swatch-list-container {
  width: 226px;
  @include swap_direction(margin, 0 auto);
}

.mpp-compare__swatch-list ul.shade-list {
  @include swap_direction(margin, 5px auto);
  height: auto;
  overflow: visible;
}

.search-product ul.shade-list {
  @include swatches(
    $search-swatch-total,
    $search-swatch-half,
    $search-swatch-third,
    $search-swatch-two-third,
    $search-swatch-offset
  );
  width: 160px;
  margin-#{$ldirection}: 5px;
  @media #{$xlarge-up} {
    width: 210px;
    margin-#{$ldirection}: 0;
  }
  li {
    height: $search-swatch-container;
    width: $search-swatch-container;
    @include swap_direction(margin, 6px 10px 6px 0);
    @media #{$xlarge-up} {
      @include swap_direction(margin, 6px 22px 6px 0);
    }
    &:nth-child(5n + 5) {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 6px 0);
      clear: $rdirection;
    }
    a.swatch {
      &.swatch--selected {
        top: 0;
        #{$ldirection}: 0; //1px offset?
        height: $search-swatch-container;
        width: $search-swatch-container;
        z-index: 10;
        &:hover,
        &.selected {
          .borderradius & {
            @include swap_direction(border, 1px solid $color-shade-picker-gold);
            @include border-radius($search-swatch-container / 2);
          }
          .no-borderradius & {
            background-size: auto auto;
            background-position: 0 -8427px;
          }
        }
      }
    }
  }
}

//shade select
// #{$renutriv} .shade-select,
// .page-product .shade-select,
// .product-full .shade-select,
// .quickshop-inline .shade-select,
// .quickshop .shade-select,
// .selectBox-dropdown-menu

.shade-select,
.shade-select-selectBox-dropdown-menu {
  @include swatches(
    $spp-swatch-total,
    $spp-swatch-half,
    $spp-swatch-third,
    $spp-swatch-two-third,
    $spp-swatch-offset
  );
  .swatch--1 {
    #{$ie8} & {
      @include border-radius(19px);
    }
  }
  .swatch__container {
    float: $ldirection;
    @include swap_direction(margin, 10px 10px 0 8px);
    .swatch--1 {
      #{$ie8} & {
        @include border-radius(19px);
      }
    }
    @media #{$medium-up} {
      @include swap_direction(margin, 7px 10px 0 8px);
    }
  }
  .selectBox-label,
  li a {
    padding-#{$ldirection}: 0;
  }
}

// editorial single sku shade
.sku-brief-editorial__shade {
  @include swatches(
    $spp-swatch-total,
    $spp-swatch-half,
    $spp-swatch-third,
    $spp-swatch-two-third,
    $spp-swatch-offset
  );
}

// SEARCH
// .search-product .shade-select,
// .search-product__swatches {
//   @include swatches($spp-swatch-total, $spp-swatch-half, $spp-swatch-third, $spp-swatch-two-third, $spp-swatch-offset);
// }

// #product-results,
// #bestseller-results {
//   .search-product .search-product__shade-select {
//     .swatch__container {
//       float: $ldirection;
//       @include swap_direction(margin, 7px 10px 0 8px);
//     }
//   }
// }

// inside shade-picker swatch
.shade-select__display,
.shade-select-label__display {
  @include border-radius(10px, 10px);
  display: block;
  float: $ldirection;
  // height: 38px;
  // width: 38px;
  height: 21px;
  width: 21px;
  @include swap_direction(margin, 11px 10px 11px 10px);
  @media #{$medium-up} {
    @include swap_direction(margin, 9px 10px 8px 10px);
  }
}

.page-product {
  .shade-select {
    .swatch__container {
      .swatch--1 {
        #{$ie8} & {
          z-index: 90;
          @include border-radius(11px);
          behavior: url(/sites/esteelauder/themes/estee_base/js/lib/pie/PIE.htc);
        }
      }
    }
  }
}
