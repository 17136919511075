@mixin opc_update__checkout-account {
  ///
  /// Registration before checkout
  /// not logged into elc, sign in block
  .sign-in-panel {
    @include clearfix;
    // This is terrible and should not be read by anyone. A special case of:
    // 1) User has item in cart
    // 2) User signs in
    // 3) User closes browser completely
    // 4) User returns to checkout, /checkout/index.tmpl
    // 5) We must hide the "main" sign in panel, but show the paypal signin panel
    .elc-user-state-logged-in &.main:not(.finished) {
      display: none;
    }
  }

  ///
  /// Layout of signin panel
  ///
  body.active-panel-signin {
    .sign-in-panel.active,
    .sign-in-paypal-panel.active {
      // Sign in with full account, close browser, come back to index
      display: block;
      min-height: 250px;
    }
    .guarantee-panel,
    .page-utilities,
    .new-or-returning-radios {
      display: none;
    }
    .checkout__sidebar,
    .order-summary-panel,
    .panel--offer-code {
      display: none;
      @media #{$large-up} {
        display: block;
      }
    }
    .checkout__sidebar {
      display: block;
    }
    .promo-bca-panel {
      display: none;
    }
    .checkout {
      position: static;
      &__content {
        .checkout-index-header {
          display: block;
          margin: $checkout-spacing--extra-large 0 0;
          @media #{$large-up} {
            margin: $checkout-spacing--medium 0 $checkout-spacing--extra-large;
          }
        }
        .panel.edit {
          padding: 0;
          border: 0;
          .messages {
            padding: 0 24px;
            margin: 0 0 $checkout-spacing--large;
            @media #{$large-up} {
              width: 100%;
              margin: 0 auto $checkout-spacing--extra-large;
              padding: 0;
            }
          }
        }
      }
    }
    .opc__footer {
      @media #{$medium-up} {
        position: absolute;
        bottom: 0;
        margin: 0 auto;
        width: 100%;
        padding-bottom: 5em;
      }
    }
  } // .active-panel-signin

  .page-wrapper-checkout .checkout {
    &__content {
      .sign-in-panel {
        margin: 0 30px;
        @media #{$large-up} {
          margin: 0;
        }
        // Submit buttons
        .btn {
          &.new-account__submit,
          &.return-user__submit {
            @include button--dark;
            background: $color-navy;
            border-color: $color-navy;
            color: $color-white;
            width: 100%;
            height: 4em;
            @media #{$medium-up} {
              position: static;
            }
            @media #{$large-up} {
              height: calc(3.5em - -2px);
            }
          }
        }
        .content {
          float: none;
          @media #{$large-up} {
            margin: 0 auto;
            width: 320px;
          }
        }
        &.substate-new-account {
          .checkout__return-user {
            display: none;
          }
        }
        &.substate-return-user {
          .checkout__new-account,
          #return-account-h {
            display: none;
          }
        }
        .form-item {
          margin-bottom: 16px;
        }
        .form-item.password {
          margin-bottom: 12px;
        }
        .return-user-link,
        .continue-guest-link {
          margin: 32px auto 0 auto;
          width: 70%;
          text-align: center;
          text-transform: uppercase;
          font-size: 13px;
          font-weight: bold;
          text-decoration: underline;
          letter-spacing: 0.05em;
          @media #{$large-up} {
            width: 100%;
            margin-top: 40px;
          }
        }
      } // .sign-in-panel
    }
    &__new-account,
    &__return-user {
      border: 0;
      margin: 0 auto;
      padding: 0 0 40px;
      @media #{$medium-up} {
        width: 100%;
      }
      @media #{$large-up} {
        width: 320px;
        min-height: 300px;
      }
      .adpl {
        input[type='text'],
        input[type='email'],
        input[type='tel'],
        input[type='password'] {
          display: block;
          border: $checkout-line--input;
          border-color: $color-navy;
          border-radius: 0;
          max-width: inherit;
        }
      }
    }
  } // .checkout

  .new-account__new-customers-checkout-signin-text {
    @include hidden;
  }

  // Registration on checkout
  .checkout-registration {
    margin-top: 30px;
  }
  .checkout-registration__email-address,
  .checkout-registration__password {
    vertical-align: top;
  }
  .sign-in-confirmation-panel__content .password,
  .checkout-registration__password {
    @media #{$large-up} {
      width: 40%;
      padding-#{$rdirection}: 10px;
      float: none;
    }
  }
  .checkout-registration__show-password {
    float: $rdirection;
    @media #{$large-up} {
      float: none;
    }
  }
  .how-we-will-use-container {
    @media #{$large-up} {
      width: 50%;
      float: $rdirection;
      padding-#{$ldirection}: 10px;
    }
  }
  .new-or-returning-radios {
    margin-bottom: $cart-spacing;
    .new-customer,
    .returning-customer {
      @include inline-block;
    }
  }
  #forgot_password {
    @include text--short--plain;
    text-decoration: underline;
    font-weight: bold;
  }
  #forgot-pw-note {
    margin: 0 0 16px 0;
  }
} // @mixin opc_update__checkout-account
.cancel_order_overlay {
  margin: 20px;
  @media #{$large-up} {
    margin: 0;
  }
  .headline {
    &--quinary {
      font-weight: bold;
    }
  }
  .form-submit {
    &.continue-btn,
    &.cancel-btn,
    &.cancel-thankyou-reorder,
    &.cancel-thankyou-close {
      width: 100%;
    }
    &.continue-btn,
    &.cancel-thankyou-reorder {
      margin-bottom: 15px;
    }
  }
}
