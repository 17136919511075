.multi_use_homepage_module,
.section-virtual-beauty-services {
  .multi_use_homepage_v1 {
    #extole_zone_homepage_tout {
      a {
        background: $color-navy;
        color: $color-white;
        display: inline-block;
        line-height: 3.5;
        padding: 0 20px;
      }
    }
  }
  .content-formatter__rendered-item:first-child {
    .content-block-tout {
      position: relative;
    }
    #extole_zone_homepage_tile {
      bottom: 0;
      cursor: pointer;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.special-offer-row-mobile {
  .special-offer-content {
    #extole_zone_offers_page {
      a {
        background: $color-navy;
        color: $color-white;
        display: inline-block;
        line-height: 3.5;
        padding: 0 20px;
      }
    }
  }
}

#extole_zone_product_page {
  a {
    color: $color-white;
  }
}

#confirmation-page {
  .refer-a-friend {
    cursor: pointer;
    padding: 40px 20px;
    @media #{$medium-up} {
      padding: 40px 0;
    }
    &__content {
      position: relative;
      .refer-a-friend {
        &__banner {
          width: 100%;
        }
        &__offer-content {
          font-family: $optimaregular;
          font-size: 30px;
        }
        &__offer-content,
        &__reward-content {
          letter-spacing: 0.02em;
          padding-top: 10px;
          text-align: center;
        }
      }
      #extole_zone_confirmation {
        height: 100%;
        position: absolute;
        width: 100%;
      }
      .pc-hidden {
        display: block;
        @media #{$medium-up} {
          display: none;
        }
      }
      .mobile-hidden {
        display: none;
        @media #{$medium-up} {
          display: block;
        }
      }
    }
  }
}

.page-navigation__menu {
  .menu-ref {
    #extole_zone_homepage_banner {
      bottom: 0;
      cursor: pointer;
      position: absolute;
      top: 35px;
      width: 21%;
    }
  }
}
