// quickshop
#colorbox.colorbox__quickshop,
#product_brief__quickshop__container {
  #cboxContent {
    @include swap_direction(padding, 0);
    min-height: 725px;
    width: 965px;
    max-width: 965px;
    #{$cbox-qs} & {
      width: 1024px;
      max-width: 1024px;
      min-height: 616px;
    }
  }
  #cboxClose {
    z-index: 20;
    #{$rdirection}: 20px !important;
    top: 20px !important;
  }
  #cboxLoadedContent {
    overflow: hidden !important;
  }
  .quickshop {
    @include pie-clearfix;
    @include min-height(725px);
    @include text-product-anchor;
    width: 914px;
    position: relative;
    padding-bottom: 50px;
    #{$renutriv} & {
      @include renutriv-copy;
      background: $color-black;
    }
    #{$estee_edit} & {
      //both
      @include estee_edit-copy;
      background: $color-white;
      padding-bottom: 0;
      @media #{$medium-up} {
        // (737px, 768px)
        @include min-height(383px);
        //width: 350px; // height 415
        background: url(/media/export/cms/products/bg/product_bg_tee_768px.jpg) repeat-x 0 -32px $color-white;
        background-size: auto 481px;
      }
      @media #{$large-up} {
        // (769px, 1024px)
        @include min-height(521px);
        //width: 400px; // height 553
        background: url(/media/export/cms/products/bg/product_bg_tee_768px.jpg) repeat-x 0 -32px $color-white;
        background-size: auto 553px;
      }
      @media #{$xlarge-up} {
        @include min-height(736px); //768 - 32
        background: url(/media/export/cms/products/bg/product_bg_tee_768px.jpg) repeat-x 0 -32px $color-white;
      }
    }
    #{$inline-qs} & {
      //inline only
      width: 100%;
      @media #{$medium-up} {
        width: 100%;
      }
      @media #{$large-up} {
        width: 100%;
      }
    }
    @media #{$medium-only} {
      width: 964px;
    }
    @media #{$large-only} {
      width: 914px;
    }
  }
  .quickshop--estee-edit {
    &.non-bg {
      background: $color-white;
    }
  }
  .quickshop__view-full {
    @include inline-block;
    @include link--bold;
    // position: absolute;
    // bottom: 76px;
    // #{$rdirection}: 19px;
    #{$renutriv} & {
      @include renutriv-copy;
    }
    @media #{$medium-only} {
      #{$rdirection}: 100px;
    }
    @media #{$large-only} {
      // #{$rdirection}: 100px;
      float: right;
    }
  }
  .quickshop__view-full--estee-edit {
    #{$estee_edit} & {
      font-family: $ffmarkweb;
      font-size: 12px;
      font-weight: normal;
      text-transform: uppercase;
      text-decoration: underline;
      margin-right: 0;
      letter-spacing: 0.15em;
    }
  }
  .non-shaded {
    #{$estee_edit} & {
      @media #{$large-up} {
        padding: 40px 0;
      }
    }
  }
  .quickshop h3 {
    @include pie-clearfix;
    margin-bottom: 0px;
    #{$renutriv} & {
      font-family: $bb-roman;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      font-weight: lighter;
      font-size: 40px;
    }
    #{$estee_edit} & {
      font-family: $ffmarkweb-medium;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
  .quickshop__sub-header {
    #{$renutriv} & {
      @include renutriv-copy;
      font-size: 20px;
    }
    #{$estee_edit} & {
      font-family: $ffmarkweb;
      line-height: 28px;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      margin-bottom: 4px;
    }
    margin-top: 4px;
  }
  .quickshop__image-container {
    position: absolute;
    z-index: 0;
    width: 485px;
    overflow-y: hidden;
    .quickshop__image {
      img {
        width: 100%;
      }
    }
    .quickshop__image__thumb {
      width: 45px;
      height: 45px;
      border: 1px solid $color-white;
      margin-left: 6px;
      overflow: hidden;
      background-repeat: no-repeat;
      //background-position: 50% 50%;
      background-size: 100%;
    }
    .slick-active {
      .quickshop__image__thumb {
        border: 1px solid $color-blue--estee_edit;
      }
    }
    .slick-dots {
      bottom: 90px;
      #{$estee_edit} & {
        bottom: 50px;
        z-index: 2;
      }
      @media #{$large-only} {
        bottom: -150px;
        #{$estee_edit} & {
          bottom: 50px;
        }
      }
    }
    @media #{$medium-only} {
      width: 300px;
    }
    @media #{$large-only} {
      height: 520px;
    }
    #{$inline-qs} & {
      width: 50%;
      background: transparent;
      @media #{$medium-up} {
        // (737px, 768px)
        width: 350px; // height 415
      }
      @media #{$large-up} {
        // (769px, 1024px)
        height: auto;
        width: 400px; // height 553
      }
      @media #{$xlarge-up} {
        // (769px, 1024px)
        width: 558px; // height 768
      }
    }
    #{$aerin} & {
      background: $color-white;
    }
    #{$renutriv} & {
      background: $color-black;
    }
  }
  .quickshop__image {
    @include swap_direction(margin, -32px auto 0 auto);
    display: block;
  }
  .quickshop__description {
    @include swap_direction(padding, 64px 29px 10px 0);
    position: relative;
    float: $rdirection;
    z-index: 10;
    width: 375px;
    #{$renutriv} & {
      color: $color-white;
    }
    #{$estee_edit} & {
      text-align: center;
      width: 384px;
      margin-right: 20px;
      padding-top: 44px;
      @media #{$large-up} {
        width: 446px; //1024 - 558
        //margin-right: 30px;
        margin-right: 10px;
        padding-top: 44px;
      }
      @media #{$device-tablet} {
        @media #{$portrait} {
          width: 420px;
        }
      }
    }
    @media #{$medium-only} {
      width: 340px;
      margin-#{$rdirection}: 50px;
    }
    @media #{$large-only} {
      @include swap_direction(padding, 40px 0 10px 0);
      width: 380px;
      margin-#{$rdirection}: 40px;
    }
  }
  .quickshop__misc-flag {
    font-size: 12px;
    font-family: 'AkzidenzGrotesk W1G';
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-weight: bold;
    height: 16px;
    #{$estee_edit} & {
      margin-bottom: 4px;
      font-family: $ffmarkweb;
    }
  }
  .quickshop__header {
    width: 360px;
    max-width: 360px;
    line-height: 48px;
    padding-top: 6px;
    #{$estee_edit} & {
      width: 100%;
      max-width: 100%;
      line-height: 28px;
      padding: 0 5px;
    }
    &.quickshop__header-small {
      font-size: 40px;
      line-height: 39px;
    }
  }
  .quickshop__description-section {
    @include swap_direction(padding, 0 2px);
    width: 100%;
  }
  .quickshop__shade-select-container {
    padding-top: 5px;
  }
  .shade-list {
    width: 384px;
    #{$estee_edit} & {
      width: 100%;
      margin: 16px auto 0 auto;
    }
  }
  .quickshop__review {
    @include text--short;
    margin-top: 20px;
  }
  .quickshop__reviews {
    font-size: 12px;
    line-height: 16px;
    height: 16px;
    margin-top: 20px;
    #{$estee_edit} & {
      text-align: center;
    }
  }
  .quickshop__review-write {
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: bold;
    margin-#{$ldirection}: 10px;
    letter-spacing: 2px;
    #{$estee_edit} & {
      font-family: $ffmarkweb-medium;
    }
  }
  .quickshop__review-read {
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: bold;
    margin-#{$ldirection}: 10px;
    letter-spacing: 2px;
  }
  .quickshop__reviews-image {
    margin-bottom: 2px;
  }
  .quickshop__price-size {
    @include pie-clearfix;
    #{$estee_edit} & {
      padding: 20px 0 10px 0;
    }
    @include swap_direction(padding, 26px 0 9px 0);
  }
  .quickshop__price-size.shaded {
    //padding-top: 0px;
    padding: 20px 0 9px;
    #{$estee_edit} & {
      padding-top: 20px;
    }
  }
  .quickshop__price-size .product__inventory-status {
    float: $ldirection;
    margin-#{$ldirection}: 20px;
  }
  .quickshop__price-text {
    float: $ldirection;
    font-size: 12px;
    #{$estee_edit} & {
      float: none;
      text-align: center;
    }
  }
  .quickshop__size-text {
    font-weight: normal;
  }
  .quickshop__price-size-select-container {
    margin-top: 11px;
  }
  .quickshop__price-size-select {
    width: 268px;
  }
  .quickshop__attribute {
    margin-top: 21px;
    height: 10px;
    margin-bottom: 34px;
    @media #{$medium-up} {
      margin-bottom: 20px;
      height: 64px;
      overflow-x: hidden;
    }
    #{$estee_edit} & {
      height: auto;
      margin-top: 25px;
      @media #{$medium-only} {
        padding: 0 30px;
      }
      @media #{$large-up} {
        padding: 0 55px;
      }
    }
  }
  .quickshop__attribute > p {
    line-height: 13px;
    #{$estee_edit} & {
      font-family: $devinne-roman;
      font-size: 24px;
      line-height: 26px;
      text-transform: none;
      margin: 0;
      letter-spacing: 0;
      word-break: keep-all;
    }
  }
  .quickshop__attribute h5 {
    @include text--bold;
    margin-bottom: 0px;
    #{$renutriv} & {
      @include renutriv-copy;
    }
  }
  .quickshop__attribute p {
    @include text--short;
    margin-top: 5px;
    #{$renutriv} & {
      @include renutriv-copy;
    }
  }
  .quickshop__sku-menu {
    display: block;
    margin-top: 10px;
  }
  .quickshop__button-container {
    @include pie-clearfix;
    margin-top: 11px;
    #{$estee_edit} & {
      width: 328px;
      margin: 11px auto 0 auto;
    }
  }
  .quickshop__add-button {
    @include swap_direction(border, 0);
    float: $ldirection;
    display: block;
    margin-#{$ldirection}: 10px;
    #{$renutriv} & {
      @include swap_direction(border, $color-gold--renutriv solid 1px);
      color: $color-gold--renutriv;
      text-transform: uppercase;
      font-family: $roboto-condensed;
    }
    #{$estee_edit} & {
      font-family: $ffmarkweb-medium;
      font-size: 14px;
      letter-spacing: 0.015em;
      width: 48%;
      margin-left: 4%;
    }
  }

  //custom selects
  .quickshop select {
    @include opacity(0);
  }
  .shade-select__display {
    @include border-radius(10px, 10px);
    @include swap_direction(margin, 9px 10px 8px 10px);
    display: block;
    float: $ldirection;
    // height: 38px;
    // width: 38px;
    height: 21px;
    width: 21px;
  }
  .quickshop__shade-select {
    width: 328px;
  }
  .quickshop__quantity {
    float: $ldirection;
    width: 125px;
    .selectBox-label {
      text-indent: 14px;
    }
    #{$estee_edit} & {
      width: 48%;
    }
  }
  .quickshop__personal {
    padding-top: 15px;
  }
  .quickshop__detail-link,
  .quickshop__share,
  .quickshop__wishlist {
    @include inline-block;
    @include link--bold;
    margin-#{$rdirection}: 30px;
    #{$renutriv} & {
      @include renutriv-copy;
    }
    #{$estee_edit} & {
      font-family: $ffmarkweb-medium;
      margin-right: inherit;
    }
  }
  .quickshop__detail-link {
    margin-bottom: 10px;
  }

  // Quickshop Tabs
  .quickshop__tabs {
    width: 100%;
    margin-top: 22px;
    #{$estee_edit} & {
      margin-top: 16px;
    }
  }
  .quickshop__tab {
    font-size: 15px;
  }
  .quickshop__tabs-control {
    @include pie-clearfix;
    width: 100%;
    line-height: 16px;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
    .quickshop__tab-control {
      letter-spacing: 0.15em;
      float: $ldirection;
      padding-#{$rdirection}: 10px;
      &.quickshop__tab-control--1 {
        width: 100%;
      }
      &.quickshop__tab-control--2 {
        &:first-child {
          width: 33%;
        }
        width: 66%;
      }
      &.quickshop__tab-control--3 {
        width: 33%;
      }
      #{$renutriv} & {
        @include renutriv-copy;
      }
      @media #{$medium-only} {
        letter-spacing: 0.01em;
        padding-#{$rdirection}: 0px;
      }
      @media #{$large-only} {
        padding-#{$rdirection}: 0px;
      }
    }
  }
  .quickshop__tabs-control::after {
    border-bottom: 1px solid $color-light-gray;
    width: 380px;
    padding-bottom: 8px;
  }
  .quickshop__tab-container {
    width: 380px;
    height: 135px; // formerly 163px, but this was cutting off shade lists in some quickshops
    cursor: auto;
    overflow-x: hidden; // HOW TO USE Tab Content is getting hidden, if the content text is more.
    padding-right: 10px;
    #{$estee_edit} & {
      height: auto;
      margin: 0 auto;
    }
  }
  .quickshop__tab--description {
    display: none;
    margin-top: 15px;
    width: 380px;
  }
  .quickshop__tab--how-to-use {
    display: none;
    margin-top: 15px;
    width: 380px;
    padding-right: 10px;
  }
  .quickshop__headers {
    height: 100%;
    #{$estee_edit} & {
      text-align: center;
      @media #{$device-tablet} {
        @media #{$portrait} {
          padding: 0px 40px;
        }
      }
    }
  }
} //end

body.html .quickshop__price-size-select-selectBox-dropdown-menu {
  &.selectBox-options li a {
    @include swap_direction(padding, 0 0 0 10px);
    font-size: 14px;
  }
}

body.html .quickshop__quantity-selectBox-dropdown-menu {
  &.selectBox-options li a {
    @include swap_direction(padding, 0 0 0 10px);
    text-indent: 14px;
  }
}

.ellipsis {
  overflow: hidden;
}

.ellipsis_characters {
  font-weight: bold;
  cursor: pointer;
  font-size: 23px;
  line-height: 1px;
}

.quickshop--estee-edit {
  .quickshop__personal {
    // position: absolute;
    // bottom: 66px;
    // width: 100%;
    .social-share-icons {
      display: none;
      .social-share__link {
        @include inline-block;
        margin-right: 12px;
        &.email {
          background-size: auto auto;
          background-position: 0 -9036px;
          height: 16px;
          width: 22px;
        }
        &.facebook {
          background-size: auto auto;
          background-position: 0 -9227px;
          height: 19px;
          width: 11px;
        }
        &.twitter {
          background-size: auto auto;
          background-position: 0 -9612px;
          height: 19px;
          width: 22px;
        }
        &.pinterest {
          background-size: auto auto;
          background-position: 0 -9421px;
          height: 19px;
          width: 19px;
        }
      }
    }
  }
}

// iPad Portrait
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #colorbox.colorbox__quickshop {
    #cboxClose {
      #{$rdirection}: 20px;
    }
    .quickshop {
      width: 896px;
      .quickshop__image-container {
        background: $color-white;
      }
      .quickshop__description {
        padding-#{$rdirection}: 0;
        margin-#{$rdirection}: 10px;
        .quickshop__headers {
          display: inline-block;
          width: 340px;
          margin-bottom: 10px;
        }
        .quickshop__tabs {
          .quickshop__tab--description,
          .quickshop__tab--how-to-use {
            width: 340px;
          }
        }
      }
      .quickshop__description-section {
        a.quickshop__view-full {
          bottom: 0;
        }
      }
    }
  }
}

@media screen and (width: 768px) {
  #colorbox.colorbox__quickshop {
    #cboxClose {
      #{$rdirection}: 20px !important;
    }
    .quickshop {
      width: 680px;
      .quickshop__description {
        width: 365px;
      }
      .quickshop__description-section {
        a.quickshop__view-full {
          bottom: 0;
        }
      }
    }
  }
}

// iPad Landscape
@media screen and (width: 1024px) {
  #colorbox.colorbox__quickshop {
    #cboxClose {
      #{$rdirection}: 20px !important;
    }
    .quickshop {
      width: 922px;
      .quickshop__description-section {
        a.quickshop__view-full {
          bottom: 0;
        }
      }
    }
  }
}

#colorbox.colorbox__quickshop {
  position: absolute !important;
  height: 825px !important;
  #{$estee_edit} & {
    height: 616px;
  }
  #cboxWrapper {
    height: 825px !important;
    #{$estee_edit} & {
      height: 616px;
    }
  }
  #cboxContent {
    height: 825px !important;
    min-height: 825px;
    #{$estee_edit} & {
      height: 616px;
    }
    #cboxLoadedContent {
      height: 825px !important;
      #{$estee_edit} & {
        height: 616px;
      }
      .quickshop {
        min-height: 825px !important;
      }
    }
  }
}

// Notify Me
.qs-basic-reorder {
  .basic-reorder__sold-out {
    @include swap_direction(margin, 0 0 5px);
  }
  .pre-order_status_display {
    margin: 0;
  }
}
