///
/// @file drupal_modules/esteelauder_common/__hero_tout.scss
/// Styles for hero touts, without products (found within carousel blocks on MPPs)
///
/// Style 1: image lower left, height of frame minus header text, heading top, content right edge
///

// Hero and tout shared styles
.hero-tout-wrapper {
  margin-top: -$header-collapsed-height;
  margin-bottom: 30px;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: 1366px;
}

.height-container {
  height: 100%;
}

// products page needs a negative margin on the carousel
.slick-prodpage {
  @media #{$medium-up} {
    @include swap_direction(margin, -$header-collapsed-height auto 30px auto);
    @include swap_direction(padding, 0 0 0 0);
    max-width: 1366px;
  }
  .hero-tout-wrapper,
  .hero-block {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.hero-tout {
  width: 100%;
  min-height: 200px;
  position: relative;
  max-width: 1300px;
  margin-top: 220px;
  @media (max-width: 1024px) {
    margin-top: 160px;
  }
  .promo-eyebrow-text {
    display: block;
    position: absolute;
    top: -23%;
    #{$ldirection}: 0;
    width: 100%;
    z-index: 10;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-size: 12px;
    z-index: 11;
  }
  .tout-headline {
    // top: -13%;
    top: -78px;
    #{$ldirection}: 0;
    padding-top: 0;
    position: absolute;
    width: 100%;
    z-index: 10;
    text-align: center;
  }
  .hero-tout-container {
    position: relative;
    //max-width: 844px;
    //width: 65%;
    max-width: 1104px;
    width: 83%;
  }
  .product-background-image,
  .product-promo-image {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  .tout__product {
    position: absolute;
    bottom: 33%;
    #{$rdirection}: -23%;
    @include swap_direction(padding, 0 40px 0 0);
  }
  .special-offers-page & {
    // I believe the above styles are broken, but I don't know where else it may be used, so I'm limiting my changes to just this page; perhaps they should just become the styles at one point later.
    .hero-tout-container {
      width: 100%;
    }
    $img-width-small: 64%;
    $img-width-large: 84.6%;
    .product-background-image,
    .product-promo-image {
      width: $img-width-small;
      max-width: 844px;
      @media #{$xxlarge-up} {
        width: $img-width-large;
        max-width: 1104px;
      }
    }
    .tout__product {
      bottom: auto;
      top: 9%;
      #{$ldirection}: $img-width-small + 1;
      @media #{$xxlarge-up} {
        #{$ldirection}: $img-width-large + 1.5;
      }
    }
  }
  .tout__product-copy {
    .promo-header-text {
      //width: 120px;
      width: 190px;
      position: relative;
      z-index: 1;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      font-size: 12px;
      line-height: 1.6;
      .subhead {
        margin-bottom: 3px;
      }
      .subcopy {
        font-weight: bold;
      }
    }
    .cta-button-wrapper {
      margin-top: 20px;
      position: relative;
      z-index: 1;
    }
  }
}

.add-to-bag-tout-wrapper {
  @include swap_direction(margin, 0 auto 20px);
  max-width: 1366px;
  position: relative;
  font-family: $akzidenz;
  font-size: 12px;
  color: #040a2b;
  .add-to-bag-tout-contents {
    .product-info {
      position: absolute;
      top: 185px;
      #{$ldirection}: 71%;
      .eyebrow,
      .product-title,
      .shade-copy {
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.15em;
      }
      .eyebrow {
        margin-bottom: 190px;
      }
      .product-description,
      .product-price {
        font-family: $akzidenz;
        font-size: 12px;
        font-weight: normal;
      }
      .product-title,
      .product-description,
      .product-price {
        line-height: 20px;
      }
      .shade-copy {
        padding-top: 10px;
        padding-bottom: 10px;
        .shade-color {
          width: 22px;
          height: 22px;
          margin-#{$rdirection}: 15px;
          @include swap_direction(border-radius, 11px);
          margin-bottom: -5px;
          display: inline-block;
        }
      }
      .text-link {
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.15em;
        text-decoration: underline;
        margin-top: 25px;
      }
      .add_to_bag_button_module {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }
  &.tout-size-large {
    .add-to-bag-tout-contents {
      .product-info {
        top: 275px;
        #{$ldirection}: 72.5%;
      }
    }
  }
}
/* Adjustments for iPad portrait */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .add-to-bag-tout-wrapper {
    width: 100%;
    .add-to-bag-tout-contents {
      .background-image {
        width: 100%;
      }
      .product-info {
        .eyebrow {
          margin-bottom: 100px;
        }
        .eyebrow,
        .product-title,
        .shade-copy {
          font-weight: bold;
          font-size: 10px;
          letter-spacing: 0.15em;
        }
        .product-description,
        .product-price {
          font-family: $akzidenz;
          font-size: 10px;
          font-weight: normal;
          line-height: 16px;
        }
        .text-link {
          font-size: 10px;
          margin-top: 15px;
        }
        .shade-copy {
          padding-top: 5px;
          padding-bottom: 5px;
        }
        .add_to_bag_button_module {
          margin-bottom: 10px;
          a.selectBox.product-full__quantity {
            font-size: 10px;
            height: 36px;
            line-height: 36px;
            width: 85px;
          }
          .product-full__add-button.button--dark {
            font-size: 10px;
            height: 36px;
            line-height: 36px;
            @include swap_direction(padding, 0 10px);
          }
        }
      }
    }
    &.tout-size-large {
      .add-to-bag-tout-contents {
        .product-info {
          top: 155px;
          #{$ldirection}: 72%;
        }
      }
    }
    &.tout-size-medium {
      .add-to-bag-tout-contents {
        .product-info {
          top: 100px;
          #{$ldirection}: 70.5%;
          .add_to_bag_button_module {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
/* iPad landscape */
@media screen and (width: 1024px) {
  .add-to-bag-tout-wrapper {
    width: 100%;
    .add-to-bag-tout-contents {
      .background-image {
        width: 100%;
      }
      .product-info {
      }
    }
    &.tout-size-large {
      .add-to-bag-tout-contents {
        .product-info {
          top: 195px;
          #{$ldirection}: 72.5%;
          .eyebrow {
            margin-bottom: 150px;
          }
        }
      }
    }
    &.tout-size-medium {
      .add-to-bag-tout-contents {
        .product-info {
          top: 135px;
          #{$ldirection}: 72.5%;
          .eyebrow {
            margin-bottom: 140px;
          }
        }
      }
    }
  }
}

// Variants
