$medium-max-width: 767px;
$ipad-width: 768px;

.amba-living {
  &__hero-copy {
    h2 {
      @media screen and (max-width: $medium-max-width) {
        margin-top: -3.6%;
        font-size: 9.375vw;
        line-height: 82%;
      }
      text-align: center;
      margin-top: -0.8%;
    }
    p {
      @media screen and (max-width: $medium-max-width) {
        margin-top: 4%;
        font-size: 4.375vw;
        line-height: 128%;
      }
      text-align: center;
      margin-top: 0.3%;
      b {
        @media screen and (max-width: $medium-max-width) {
          font-size: 3.75vw;
        }
        font-size: 12px;
        letter-spacing: 0.13em;
      }
    }
  }
  &-v1 {
    @media screen and (max-width: $medium-max-width) {
      margin-top: 0;
      position: relative;
    }
    margin-top: 1%;
    .amba-living {
      @media screen and (max-width: $ipad-width) {
        margin-bottom: 0;
        padding-top: 0;
        opacity: 1;
        margin-top: 0;
      }
      cursor: pointer;
      margin-top: 8.39vw;
      margin-bottom: -0.3663vw;
      padding-top: 2.19vw;
      text-align: center;
      opacity: 0;
      @media screen and (min-width: $max-width) {
        margin-top: 150px;
        padding-top: 30px;
        margin-bottom: -5px;
      }
      &:hover .amba-living__main-img,
      &:hover .amba-living__copy {
        opacity: 0.5 !important;
      }
      &__main-img {
        @media screen and (max-width: $medium-max-width) {
          max-width: 45.313vw;
        }
        width: 100%;
        max-width: 410px;
      }
      &__copy {
        text-transform: uppercase;
        text-align: center;
        font-size: 0.878vw;
        font-weight: bold;
        line-height: 1.317vw;
        margin-top: 1.83vw;
        letter-spacing: 0.14em;
        @media screen and (min-width: $max-width) {
          font-size: 12px;
          line-height: 18px;
          margin-top: 25px;
        }
        @media screen and (max-width: $medium-max-width) {
          font-size: 3.75vw;
          line-height: 5vw;
          margin: 2.8vw 0 7vw;
          letter-spacing: 0.1em;
        }
      }
      &__grid {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 33.33% 33.33% 33.33%;
        grid-template-columns: 33.33% 33.33% 33.33%;
        max-width: $max-width;
        margin: 0 auto 4.026vw;
        direction: ltr;
        @media screen and (max-width: $medium-max-width) {
          -ms-grid-columns: 50% 50%;
          grid-template-columns: 50% 50%;
        }
        @media screen and (min-width: $max-width) {
          margin: 0 auto 55px;
        }
        div:nth-child(3n + 1) {
          -ms-grid-column: 1;
          grid-column: auto;
        }
        div:nth-child(3n + 2) {
          -ms-grid-column: 2;
          grid-column: auto;
        }
        div:nth-child(3n + 3) {
          -ms-grid-column: 3;
          grid-column: auto;
        }
        div:nth-child(-n + 12) {
          -ms-grid-row: 4;
          grid-row: auto;
        }
        div:nth-child(-n + 9) {
          -ms-grid-row: 3;
          grid-row: auto;
        }
        div:nth-child(-n + 6) {
          -ms-grid-row: 2;
          grid-row: auto;
        }
        div:nth-child(-n + 3) {
          -ms-grid-row: 1;
          grid-row: auto;
        }
      }
    }
    .amba-living__carousel {
      @media screen and (max-width: $medium-max-width) {
        position: fixed;
        top: 15%;
        height: 77.2%;
      }
      @media screen and (min-width: $max-width) {
        position: fixed;
        z-index: 103;
        top: 115px;
        #{$ldirection}: 50%;
        transform: translate(-50%);
        width: 100%;
        max-width: 1366px;
        margin: 0 auto;
        display: none;
      }
      position: fixed;
      width: 100%;
      margin: auto;
      z-index: 103;
      display: none;
      vertical-align: middle;
      top: 8.65vw;
      max-width: 1366px;
      #{$ldirection}: 50%;
      -webkit-transform: translate(-50%);
      transform: translate(-50%);
      &-outer {
        @media screen and (max-width: $medium-max-width) {
          height: 100%;
          overflow-y: visible;
        }
        width: 100%;
        height: 53.001vw;
        overflow: hidden;
        margin: auto;
        cursor: default;
        @media screen and (min-width: $max-width) {
          height: 724px;
        }
        .amba-living__carousel-container {
          position: relative;
          width: 100%;
          height: 100%;
          .amba-living__carousel-wrapper {
            margin-#{$ldirection}: -100%;
            white-space: nowrap;
            font-size: 0;
            .amba-living__carousel-inner {
              display: inline-block;
              height: 100%;
              ::-webkit-scrollbar {
                width: 0px;
                background: transparent;
              }
              .amba-living__slide {
                display: inline-block;
                zoom: 1;
                text-align: center;
                white-space: normal;
                vertical-align: top;
                position: relative;
                width: 100%;
                height: 100%;
                @media screen and (min-width: $medium-max-width) {
                  overflow-y: scroll;
                  overflow-x: hidden;
                  height: 75vh;
                  -ms-overflow-style: none;
                }
                &-inner {
                  max-width: 92.679vw;
                  width: 100%;
                  overflow: hidden;
                  vertical-align: top;
                  margin: 0 auto;
                  @media screen and (min-width: $max-width) {
                    max-width: 1266px;
                  }
                  .amba-living__slide-img {
                    @media screen and (max-width: $medium-max-width) {
                      max-width: 100%;
                    }
                    position: absolute;
                    z-index: 1;
                    #{$ldirection}: 0;
                    #{$rdirection}: 0;
                    max-width: 92.679vw;
                    width: 100%;
                    overflow: hidden;
                    vertical-align: top;
                    margin: 0 auto;
                    @media screen and (min-width: $max-width) {
                      max-width: 1266px;
                    }
                  }
                  .amba-living__slide-btm {
                    @media screen and (max-width: $medium-max-width) {
                      padding-top: 18.9%;
                      margin-#{$ldirection}: 4.3%;
                    }
                    position: relative;
                    z-index: 3;
                    padding-top: 10.4%;
                    .amba-living__ingredients {
                      &-section {
                        @media screen and (max-width: $medium-max-width) {
                          font-size: 3.652968vw;
                          letter-spacing: 0.13em;
                        }
                        font-size: 0.878vw;
                        letter-spacing: 0.14em;
                        @media screen and (min-width: $max-width) {
                          font-size: 12px;
                        }
                      }
                    }
                    .amba-living__ingredients,
                    .amba-living__procedure {
                      @media screen and (max-width: $medium-max-width) {
                        float: none;
                        width: 100%;
                        margin-#{$ldirection}: 0;
                        margin-top: 0;
                      }
                      float: #{$ldirection};
                      text-align: #{$ldirection};
                      width: 22%;
                      margin-#{$ldirection}: 11%;
                      margin-top: -0.5%;
                      p {
                        @media screen and (max-width: $medium-max-width) {
                          font-size: 4.375vw;
                          line-height: 132%;
                          color: $color-navy;
                          margin-top: 0;
                          margin-bottom: 0;
                        }
                        font-size: 1.098vw;
                        line-height: 1.903vw;
                        color: $color-navy;
                        margin-top: 0;
                        margin-bottom: 3.6%;
                        @media screen and (min-width: $max-width) {
                          font-size: 15px;
                          line-height: 26px;
                        }
                      }
                      h4 {
                        @media screen and (max-width: $medium-max-width) {
                          font-size: 6.5625vw;
                          line-height: 108%;
                        }
                        font-size: 2.196vw;
                        letter-spacing: -0.02em;
                        text-transform: uppercase;
                        @media screen and (min-width: $max-width) {
                          font-size: 30px;
                        }
                      }
                    }
                    .amba-living__procedure {
                      @media screen and (max-width: $medium-max-width) {
                        width: 100%;
                        margin-#{$ldirection}: 0;
                        margin-top: 6.2%;
                      }
                      width: 56%;
                      margin-#{$ldirection}: 0.85%;
                    }
                  }
                }
              }
            }
          }
          .amba-living__carouselnav {
            @media screen and (max-width: $medium-max-width) {
              display: none;
            }
            position: absolute;
            z-index: 2;
            width: 8.35%;
            top: 43%;
            cursor: pointer;
            height: 14.158%;
            text-align: center;
            img {
              width: 1.464vw;
              margin: auto;
              height: 100%;
              @media screen and (min-width: $max-width) {
                width: 20px;
              }
            }
            &--1 {
              #{$ldirection}: -2.4%;
            }
            &--2 {
              #{$ldirection}: 94.1%;
            }
          }
        }
      }
      &--hidden {
        @media screen and (max-width: $medium-max-width) {
          height: 13.850837vw;
        }
        width: 95.7%;
        overflow: hidden;
        margin: auto;
        cursor: default;
        @media screen and (min-width: $max-width) {
          height: 58px;
        }
        img {
          @media screen and (max-width: $medium-max-width) {
            width: 7.6103vw;
            padding-bottom: 6.7%;
            margin-#{$rdirection}: 2.6%;
          }
          width: 2.052vw;
          padding-bottom: 2.35%;
          float: #{$rdirection};
          cursor: pointer;
          @media screen and (min-width: $max-width) {
            width: 28px;
          }
        }
      }
    }
    .amba-living__modal-bg {
      @media screen and (max-width: $medium-max-width) {
        z-index: 99;
      }
      display: none;
      position: fixed;
      width: 100%;
      background-color: $color-white;
      opacity: 0.8;
      height: 100%;
      z-index: 102;
      top: 0;
      #{$ldirection}: 0;
    }
  }
}

html[dir='rtl'] {
  .amba-living__v1 {
    .amba-living__copy {
      direction: rtl;
    }
    .amba-living__carousel-outer .amba-living__carousel-container {
      direction: ltr;
      .amba-living__carousel-wrapper .amba-living__carousel-inner .amba-living__slide {
        direction: rtl;
      }
    }
  }
}
