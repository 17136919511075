body.lp {
  @include swap_direction(padding, 0);
  @include swap_direction(margin, 0);
}

#user-input-container {
  width: 475px;
  position: absolute;
  top: 72px;
  @include swap_direction(border, 1px solid lightgray);
}

#custom-container {
  margin-top: 12px;
  width: 850;
  overflow: hidden;
  font-family: arial;
  font-size: 0.9em;
}

#chatLines {
  margin-bottom: 12px;
}
// #chatLines div { padding: 5px 12px 5px 0px; }

#chatLines .icon {
  width: 30px;
  height: 33px;
  @include swap_direction(border, 1px solid lightgray);
  float: $ldirection;
  @include swap_direction(margin, 2px 10px 0 12px);
}

.rich-line-content {
  width: 100%;
  clear: $ldirection;
}

.logs {
  display: none;
}

#logs div {
  font-size: 0.7em;
}

.chat-window-container {
  position: relative;
  float: $ldirection;
  width: 475px;
  height: 475px;
  // @include swap_direction(border, 1px solid lightgray);
  // border-top: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
}

#custom-container .text-input-container {
  position: absolute;
  top: 573px;
  border-#{$rdirection}: 1px solid lightgray;
  height: 164px;
  width: 477px;
  padding-top: 14px;
}

#custom-container .input-field-container {
  width: 373px;
  float: $ldirection;
  height: 30px;
  @include swap_direction(padding, 4px 10px);
}

input.chat-input-field {
  width: 355px;
  float: $rdirection;
  height: 30px;
  font-size: 0.9em;
}

button#closeChat {
  position: relative;
  #{$rdirection}: 3px;
  top: 3px;
  height: 24px;
  float: $rdirection;
  visibility: hidden;
}

button#emailTranscript {
  position: relative;
  top: 3px;
  height: 24px;
  float: $rdirection;
}

#custom-container .btn {
  cursor: pointer;
}

#custom-container .btn-send {
  float: $rdirection;
  width: 80px;
  height: 35px;
  margin-#{$rdirection}: 20px;
}

#custom-container .btn-upload {
  @include swap_direction(margin, 12px);
  height: 35px;
  width: 150px;
}

#custom-container .upload-silhoette {
  width: 202px;
  height: 202px;
  @include swap_direction(margin, 12px);
}

#vba-logo {
  height: 40px;
  width: 260px;
  margin-#{$ldirection}: 10px;
}

// layout

#custom-container .right-col-top {
  position: absolute;
  top: 72px;
  #{$ldirection}: 477px;
  @include swap_direction(border, 1px solid lightgray);
  border-#{$ldirection}: 0px;
  float: $rdirection;
  height: 325px;
  width: 360px;
}

#custom-container .right-col-bottom {
  position: absolute;
  #{$ldirection}: 477px;
  top: 392px;
  float: $rdirection;
  clear: both;
  width: 360px;
  height: 362px;
  @include swap_direction(border, 1px solid lightgray);
  border-#{$ldirection}: 0px;
  border-top: 0px;
  border-bottom: 0px;
}

#chatContainer {
  position: relative;
  margin-top: 3px;
}

// photo upload

#custom-container .section-heading-photo {
  @include swap_direction(margin, 12px);
  letter-spacing: 0.1em;
  font-weight: bold;
}

#custom-container .section-heading-consultant {
  @include swap_direction(margin, 12px);
  letter-spacing: 0.1em;
  font-weight: bold;
  margin-bottom: 15px;
}

#custom-container .icon-consultant,
#custom-container .icon-chat-visitor {
  width: 30px;
  height: 33px;
}

#custom-container .chat-face-icon {
  float: $ldirection;
  @include swap_direction(margin, 0 12px);
}

#custom-container .consultant-name {
  float: $ldirection;
  font-size: 11px;
  width: 75%;
  font-weight: bold;
}

#custom-container .consultant-location {
  float: $ldirection;
  font-size: 11px;
  width: 75%;
  @include swap_direction(padding, 6px 0px);
}

#custom-container .consultant-msg {
  @include swap_direction(padding, 10px 15px);
  width: 100%;
  clear: both;
}

#photo-upload-query div {
  font-family: $optimalight;
}

#photo-upload-query .next-row {
  clear: both;
}

#photo-upload-query .choose-photo-btn {
}

#photo-upload-query .file-chosen-state {
}

#photo-upload-query .msg-instructions {
  @include swap_direction(margin, 0 auto);
  font-size: 0.9em;
  @include swap_direction(padding, 12px 0 14px 0);
  width: 333px;
  text-align: center;
}

#photo-upload-query .btn-instructions {
  @include swap_direction(margin, 0 auto);
  font-size: 0.9em;
  @include swap_direction(padding, 12px 0 14px 0);
  width: 360px;
  text-align: center;
}

#photo-upload-query .msg-header {
  text-align: center;
  margin-top: 24px;
  font-size: 1.2em;
}

#photo-upload-query .photo-upload-row {
  width: 400px;
}

#photo-upload-query #choose-file-btn {
  float: $ldirection;
  height: 35px;
  @include swap_direction(padding, 8px);
  text-align: center;
  width: 175px;
  @include swap_direction(margin, 0px);
  cursor: pointer;
}

#photo-upload-query #file-save-btn {
  padding-top: 7px;
  width: 75px;
  @include swap_direction(margin, 30px auto);
}

#photo-upload-query #file-chosen-state {
  float: $rdirection;
  @include swap_direction(border, 1px solid);
  height: 35px;
  @include swap_direction(padding, 6px);
  text-align: center;
  width: 175px;
}

.vba-x-close {
  height: 20px;
  position: absolute;
  #{$rdirection}: 0;
  top: 0;
  width: 20px;
  cursor: pointer;
}

body.lp #cboxOverlay {
  background: none repeat scroll 0 0 #040a2b;
  opacity: 0.92;
  height: 100%;
  position: fixed;
  width: 100%;
}

body.lp #cboxOverlay {
  #{$ldirection}: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 9999;
  display: none;
}

// prechat overlay
.prechat-form-container {
  width: 480px;
  overflow: hidden;
  margin-top: -12px;
}

#prechat_container {
  width: 490px;
}

#prechat_container #textarea-your-question {
  height: 100%;
}

.prechat-form-container #beauty-advice-btn,
.prechat-form-container #beauty-advice-btn-active,
.prechat-form-container #beauty-advice-btn-off {
  float: $ldirection;
  cursor: pointer;
}

.prechat-form-container #order-question-btn,
.prechat-form-container #order-question-btn-active,
.prechat-form-container #order-question-btn-off {
  float: $rdirection;
  cursor: pointer;
}

.prechat-form-container #beauty-advice-btn-active,
.prechat-form-container #order-question-btn-active {
  display: none;
}

.prechat-form-container #lpbuttonproduct {
  float: $ldirection;
}

.prechat-form-container #lpbuttonordertech {
  float: $rdirection;
}

.prechat-form-container .instruction-msg {
  margin-bottom: 16px;
  font-size: 0.9em;
}

.prechat-form-container .form-row-msg p {
  font-size: 0.8em;
}

.prechat-form-container h4 {
  margin-top: 25px;
  text-align: center;
}

.prechat-form-container .form-row {
  clear: both;
}

.prechat-form-container .textarea-form-row {
  @include swap_direction(padding, 12px 0 6px 0);
}

.prechat-form-container .prechat-input {
  width: 200px;
}

.prechat-form-container .prepopulated-input {
  margin-top: 6px;
}

.prechat-form-container .form-col-one {
  float: $ldirection;
}

.prechat-form-container .form-col-two {
  float: $ldirection;
  margin-#{$ldirection}: 10px;
}

.prechat-form-container .form-col {
  width: 45%;
}

.prechat-form-container .form-row-btn {
  @include swap_direction(margin, 10px auto);
  text-align: center;
}

.prechat-form-container .form-spacer {
  float: $ldirection;
  @include swap_direction(margin, 20px);
}

.prechat-form-container .form-row-msg {
  clear: both;
}

.prechat-form-container .form-row-btn {
  @include swap_direction(margin, 0 auto);
  width: 150px;
  background-color: #0d091a;
  display: inline-block;
  text-align: center;
}

.prechat-form-container .form-row-btns {
  width: 480px;
  @include swap_direction(margin, 10px auto);
}

.prechat-form-container .form-row-btn span {
  color: white;
}

.prechat-messaging-area .required {
  font-size: 12px;
  @include swap_direction(margin, 0px);
}

.prechat-messaging-area p {
  display: block;
  font-size: 13px;
}

.prechat-form-container .highlighted {
  @include swap_direction(border, 1px solid $color-black);
  color: black;
}

#prechat_container .prechat-form-container #input-first-name,
#prechat_container .prechat-form-container #input-email-address,
#prechat_container #textarea-your-question {
  color: black;
}

// landing and post chat target pages

.beauty-consultations {
  text-align: center;
  @include swap_direction(padding, 25px);
  font-family: $optimalight;
}

.beauty-consultations-headline {
  font-size: 6em;
}

.beauty-consultations-large-msg {
  font-size: 2.6em;
  width: 666px;
  @include swap_direction(margin, 0 auto);
}

.vba-tgt-pg-divider {
  border-bottom: 1px solid lightgray;
  width: 500px;
  @include swap_direction(margin, 0 auto);
}

.beauty-consultations-lbl {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
}

.beauty-consultations-placement-msg {
  font-size: 1.4em;
}

.beauty-consultations #start-chat-btn,
.beauty-consultations #order-question-btn,
.beauty-consultations #save-to-profile-btn,
.beauty-consultations #write-review-btn {
  @include swap_direction(margin, 0 auto);
  width: 150px;
  background-color: #0d091a;
  color: white;
  @include swap_direction(padding, 0px);
}

.beauty-consultations-instructions a {
  text-decoration: underline;
}

.vba-btn {
  background-color: #0d091a;
  color: white;
  height: 38px;
  @include swap_direction(margin, 0 auto);
}

.vba-txt-btn {
  text-align: center;
  color: white;
  height: 32px;
  padding-top: 10px;
  cursor: pointer;
}

.file-save-btn-row {
  float: none;
  clear: both;
  width: 333px;
  position: relative;
  @include swap_direction(margin, 0 auto);
}
