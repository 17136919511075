/*
 * Desktop Store Locator
 */
$store-locator-nav-height: 45px;
$store-locator-sidebar-width: 330px;
$trans-mode-width: 71px;

// Important: Note the use of .device-pc
.section-store-locator.device-pc {
  .store-locator {
    @include swap_direction(margin, 0 30px 70px);
    .store-locator__header {
      text-align: center;
    }
    .store-locator__title {
      @include headline--tertiary;
      #{$ie8} & {
        font-size: 50px;
      }
      @include headline--tertiary--plain;
    }
    .error_messages li {
      margin-bottom: 13px;
    }
    .local-search__search-container .form-submit {
      width: 100%;
      height: 41px;
      line-height: 41px;
    }
    .store-locator__nav {
      border-bottom: solid 1px $color-light-gray;
      height: $store-locator-nav-height;
      line-height: $store-locator-nav-height;
      .store-locator__nav-item {
        @include text--short--plain;
        cursor: pointer;
        display: inline-block;
        margin-#{$ldirection}: 35px;
        position: relative;
        &.first {
          margin-#{$ldirection}: 0;
        }
        &.active {
          @include text--bold;
          &:before {
            content: ' ';
            background-size: auto auto;
            background-position: 0 -11262px;
            height: 8px;
            width: 13px;
            position: absolute;
            top: $store-locator-nav-height - 2px;
            #{$ldirection}: 50%;
            margin-#{$ldirection}: -13px;
          }
        }
      }
    }
    .store-locator__sub-page {
      @include legacy-pie-clearfix;
      .intl-search__menu,
      .local-search-form__inner {
        .selectBox-label {
          display: inline-block;
          white-space: nowrap;
        }
      }
    }

    // Local search:
    .local-search__action-links {
      text-align: $rdirection;
      @include swap_direction(padding, 25px 0 20px);
    }
    .local-search__action-link {
      display: inline-block;
      margin-#{$ldirection}: 20px;
    }
    .local-search__search-container {
      float: $ldirection;
      width: $store-locator-sidebar-width;
      padding-#{$rdirection}: 20px;
      .local-search__search-container-inner {
        @include swap_direction(border, solid 1px $color-light-gray);
      }
    }
    .local-search__map-container {
      margin-#{$ldirection}: $store-locator-sidebar-width;
    }
    .local-search__banner {
      margin-bottom: 12px;
      margin-top: 15px;
      img {
        max-width: 794px;
        width: 100%;
      }
    }
    .local-search__options {
      @include clearfix;
    }
    .local-search__option {
      @include background(linear-gradient($color-white 54%, #eaeaea));
      @include text--short--plain;
      float: $ldirection;
      width: 50%;
      height: 40px;
      line-height: 40px;
      @include swap_direction(padding, 0 20px);
      border-bottom: solid 1px $color-light-gray;
      cursor: pointer;
      &.local-search__option-directions {
        border-#{$ldirection}: solid 1px $color-light-gray;
      }
      &.active {
        @include text--bold;
        border-bottom: none;
        background: $color-white;
      }
    }
    // Local search form:
    .local-search-form {
      @include swap_direction(padding, 17px 0 0);
      position: relative;
      .local-search-form__inner {
        @include swap_direction(padding, 0 9px);
      }
      .local-search-form__zip {
        @include input-placeholder {
          text-transform: uppercase;
        }
        #{$ie8} & {
          text-transform: uppercase;
        }
      }
      .local-search-form__city-state-wrapper,
      .local-search-form__zip-wrapper {
        @include clearfix;
        margin-bottom: 10px;
      }
      .local-search-form__zip,
      .local-search-form__or,
      .local-search-form__state,
      .local-search-form__city {
        width: 142px;
        display: block;
        float: $ldirection;
      }
      .local-search-form__state,
      .local-search-form__city {
        width: 142px !important;
      }
      .local-search-form__or,
      .local-search-form__city {
        float: $rdirection;
      }
      .local-search-form__or {
        line-height: 33px;
      }
      // Filters:
      .store-name-filters {
        background-color: $color-white;
        @include swap_direction(border, solid 1px $color-light-gray);
        display: none;
        @include swap_direction(margin, 0 9px);
        &.expanded {
          @include box-sizing('content-box');
          position: relative;
          width: 100%;
          @include swap_direction(margin, 0 0 0 -1px);
          border-color: $color-black;
          .store-name-filters__plus-minus {
            background-size: auto auto;
            background-position: 0 -4775px;
            height: 9px;
            width: 8px;
          }
        }
        .store-name-filters__inner {
          @include swap_direction(padding, 0 16px);
        }
        .store-name-filters__header {
          @include swap_direction(padding, 15px 0);
          text-align: center;
          cursor: pointer;
        }
        .store-name-filters__plus-minus {
          background-size: auto auto;
          background-position: 0 -7224px;
          height: 9px;
          width: 9px;
          display: inline-block;
        }
        .store-name-filters__dropdown {
          @include swap_direction(padding, 0 0 28px);
          border-top: solid 1px #fafafa;
          display: none;
        }
        &.expanded .store-name-filters__dropdown {
          display: block;
        }
        .store-name-filters__list {
          @include swap_direction(margin, 0 0 0 14px);
        }
        .store-name-filters__item {
          @include swap_direction(margin, 10px 0);
          #{$ie8} & {
            input[type='checkbox'] {
              position: absolute;
            }
            input[type='checkbox'] ~ label {
              padding-#{$ldirection}: 29px;
            }
            input[type='checkbox'] ~ label.checkedlabel:after {
              @include swap_direction(border, solid 1px $color-navy);
              background: url('/media/images/background-images/background-image12.png');
            }
            input[type='checkbox'] ~ label.notcheckedlabel:after {
              @include swap_direction(border, solid 1px $color-light-gray);
              background: none;
            }
            label:after {
              width: $checkbox-width;
              height: $checkbox-width;
              content: '';
              position: absolute;
              #{$ldirection}: 0;
              color: $color-black;
              top: 3px;
              @include swap_direction(border, solid 1px $color-light-gray);
              background: url('/media/images/background-images/background-image12.png');
            }
            label:before {
              content: '';
              position: absolute;
              #{$ldirection}: 0;
              width: $checkbox-width;
              height: $checkbox-width;
              @include swap_direction(border, 1px solid $color-light-gray);
              top: 3px;
              #{$alt} & {
                @include swap_direction(border, 1px solid $color-medium-gray);
                background: $color-navy;
              }
            }
          }
        }
        .store-name-filters__show_all {
          margin-top: 10px;
        }
        .store-name-filters__clear {
          display: block;
          text-align: center;
          margin-top: 20px;
        }
      }
    }
    &.has-filters {
      .store-name-filters {
        display: block;
      }
      &.expanded-filters .store-results {
        margin-top: 29px;
      }
    }

    // Results:
    .store-results {
      margin-top: 29px;
    }
    .store-results-header {
      @include headline--quinary--plain;
      @include swap_direction(margin, 26px 0 3px 12px);
    }
    .doors-results__inner {
      border-top: solid 1px $color-light-gray;
    }
    .door-row {
      @include clearfix;
      border-bottom: solid 1px $color-light-gray;
      @include swap_direction(padding, 18px 10px 15px);
      position: relative;
      cursor: pointer;
      &.active {
        background-color: #f6f6f6;
      }
      .door-row__closest {
        @include text--bold;
        @include swap_direction(margin, 0 0 12px);
      }
      .door-row__directions-header {
        display: none;
      }
      .door-row__marker {
        float: $ldirection;
        width: 40px;
      }
      .door-row__info {
        padding-#{$ldirection}: 40px;
        line-height: 1.5em;
        &.appt-booking {
          padding-bottom: 40px;
        }
      }
      .door-row__name {
        @include headline--quinary--plain;
        @include swap_direction(margin, 0 0 5px);
      }
      .door-row__distance {
        float: $rdirection;
        font-size: 14px;
        margin-top: 5px;
      }
      .door-row__hours-link {
        text-decoration: underline;
        display: inline-block;
        margin-top: 5px;
      }
      .door-row__hours {
        display: none;
      }
      &:last-child,
      &:nth-child(4n) {
        border-bottom: none;
      }
      .store-locator_book-appointment {
        text-transform: uppercase;
        background-color: #040a2b;
        color: #fff;
        height: 50px;
        width: 100%;
        position: absolute;
        bottom: 0;
        #{$ldirection}: 0;
        text-transform: uppdercase;
        @include swap_direction(padding, 8px);
        font-size: 12px;
        font-weight: bold;
        line-height: 38px;
        .icon {
          background: url('/media/export/cms/appointment_booking/icons/cal-ab-icon-store-locator.jpg') no-repeat 0 0;
          height: 35px;
          width: 35px;
          display: inline-block;
          float: $ldirection;
          margin-#{$rdirection}: 10px;
        }
      }
    }
    &.show-all .door-row:nth-child(4n) {
      border-bottom: solid 1px $color-light-gray;
    }

    // Pager
    .doors-results__pager {
      border-top: solid 1px $color-light-gray;
      height: 52px;
      line-height: 52px;
      @include swap_direction(padding, 0 0 0 20px);
      .doors-results__pager-view-all {
        @include link--bold;
        display: inline-block;
        float: $ldirection;
        &.active {
          text-decoration: none;
          cursor: default;
        }
      }
      .doors-results__pager-pages {
        float: $ldirection;
        @include swap_direction(margin, 0 0 0 20px);
        @include swap_direction(padding, 0 0 0 20px);
        border-#{$ldirection}: solid 1px $color-light-gray;
      }
      .doors-results__pager-page-link {
        float: $ldirection;
        text-decoration: underline;
        cursor: pointer;
        @include swap_direction(margin, 0 15px 0 0);
        &.active {
          text-decoration: none;
          cursor: default;
        }
      }
    }

    // Directions form:
    .directions-form {
      .error_messages {
        color: red;
        @include swap_direction(padding, 0 10px);
      }
    }
    .directions-form__inner {
      width: ($trans-mode-width * 4) + 2;
      @include swap_direction(margin, 0 auto);
      padding-bottom: 20px;
    }
    .directions-form__transportation-modes {
      @include clearfix;
      @include swap_direction(margin, 15px 0 20px);
      @include swap_direction(border, solid 1px $color-light-gray);
    }
    .directions-form__transportation-mode-link {
      @include hide-text;
      display: block;
      float: $ldirection;
      width: $trans-mode-width;
      height: 33px;
      background-color: #f0f0f2;
      background-repeat: no-repeat;
      border-#{$ldirection}: solid 1px $color-light-gray;
      &:first-child {
        border-#{$ldirection}: none;
      }
      &.directions-form__transportation-mode-link--driving {
        background-size: auto auto;
        background-position: 0 -11101px;
        &.active {
          background-size: auto auto;
          background-position: 0 -11159px;
        }
      }
      &.directions-form__transportation-mode-link--transit {
        background-size: auto auto;
        background-position: 0 -11353px;
        &.active {
          background-size: auto auto;
          background-position: 0 -11411px;
        }
      }
      &.directions-form__transportation-mode-link--walking {
        background-size: auto auto;
        background-position: 0 -11469px;
        &.active {
          background-size: auto auto;
          background-position: 0 -11527px;
        }
      }
      &.directions-form__transportation-mode-link--bicycling {
        background-size: auto auto;
        background-position: 0 -10927px;
        &.active {
          background-size: auto auto;
          background-position: 0 -10985px;
        }
      }
    }
    .directions-form__input-wrapper {
      @include swap_direction(padding, 0 0 0 34px);
      @include swap_direction(margin, 0 0 11px);
      &.directions-form__input-wrapper--a {
        background-size: auto auto;
        background-position: 0 -11295px;
      }
      &.directions-form__input-wrapper--b {
        background-size: auto auto;
        background-position: 0 -11043px;
      }
      .directions-form__input {
        width: 100%;
        #{$ie8} & {
          text-transform: uppercase;
        }
      }
    }
    .directions-form__submit {
      width: 100%;
    }

    // Directions results:
    .directions-form__results {
      @include text--long-copy--plain;
      font-size: 14px;
      .adp-legal {
        font-size: 10px;
        text-align: $rdirection;
        @include swap_direction(margin, 0 10px 10px 0);
        line-height: normal;
      }
      .adp-placemark {
        padding-top: 12px;
        padding-bottom: 10px;
        td:first-child {
          padding-#{$ldirection}: 30px;
          padding-#{$rdirection}: 12px;
        }
        .adp-text {
          padding-#{$rdirection}: 15px;
        }
      }
      .adp-directions {
        .adp-substep {
          width: 160px;
          max-width: 160px;
          padding-top: 12px;
          padding-bottom: 10px;
        }
        .adp-substep {
          &:first-child {
            padding-#{$ldirection}: 15px;
          }
          &:nth-child(2) {
            font-weight: bold;
          }
        }
        .adp-distance {
          padding-#{$rdirection}: 9px;
        }
      }
      .adp-agencies {
        @include swap_direction(padding, 0 15px);
      }
    }
    .directions-form__closest-store {
      border-top: solid 1px $color-light-gray;
      .door-row,
      .door-row__info,
      .door-row__marker {
        cursor: default;
      }
      .door-row__closest {
        display: none;
      }
      .door-row__directions-header {
        @include clearfix;
        display: block;
        .door-row__closest {
          display: block;
          float: $ldirection;
        }
        .door-row__change {
          float: $rdirection;
          line-height: 1em;
          text-decoration: underline;
        }
      }
    }

    // Maps:
    .local-search__map,
    .local-search__dir-map {
      height: 600px;
    }
    .location-info {
      @include swap_direction(border, none);
      width: 100%;
      font-size: 15px;
      font-family: $akzidenz;
      text-transform: none;
      &.appt-booking {
        @include swap_direction(padding, 20px 20px 0);
        position: inherit;
      }
    }

    // International search:
    .intl-search {
      text-align: center;
      @include swap_direction(margin, 30px 0 0);
      .intl-search__copy {
        @include text--long-copy--plain;
        font-size: 14px;
        letter-spacing: 0.05em;
        @include swap_direction(margin, 0 0 20px);
      }
      .intl-search__label {
        @include headline--quaternary--plain;
        font-size: 40px;
        letter-spacing: -0.01em;
        @include swap_direction(margin, 0 0 20px);
      }
      .intl-search__menu {
        .menu {
          display: none;
        }
        .intl-search__menu-select {
          width: 246px !important;
          text-transform: uppercase;
        }
      }
    }

    // Display logic:
    .store-locator__loading-message,
    &.store-locator--loading .store-locator__sub-page.local-search,
    .local-search__search-container--directions .local-search-form,
    .directions-form,
    .store-locator__sub-page,
    &.store-locator--directions-map .local-search__map,
    .local-search__dir-map,
    .store-locator__sub-page.local-search.directions .local-search__map,
    .store-locator__sub-page.local-search .local-search__dir-map {
      display: none;
    }
    &.store-locator--loading .store-locator__loading-message,
    .local-search__search-container--directions .directions-form,
    &.store-locator--local-search .local-search,
    &.store-locator--intl-search .intl-search,
    &.store-locator--trip-planner .trip-planner,
    &.store-locator--directions-map .local-search__dir-map,
    .store-locator__sub-page.local-search .local-search__map,
    .store-locator__sub-page.local-search.directions .local-search__dir-map {
      display: block;
    }
  } // .store-locator

  .intl-search__menu-select-selectBox-dropdown-menu {
    text-transform: uppercase;
  }
  .store-locator-location-info {
    // InfoBubble clones this and appends to body to get the dimensions of
    // the outer bubble, so any layout-related stuff needs to be set outside
    // of .store-locator
    width: 256px;
    @include swap_direction(padding, 20px);
    line-height: 1.6em;
    @include swap_direction(border, solid 1px $color-light-gray);
    .location-info__door-name {
      @include headline--quinary--plain;
      margin-bottom: 10px;
    }
    .location-info__links {
      margin-top: 15px;
      padding-bottom: 14px;
    }
    .location-info__link-item {
      display: inline-block;
      margin-#{$rdirection}: 18px;
      &:last-child {
        margin-#{$rdirection}: 0;
      }
    }
    .location-info__link-item {
      text-decoration: underline;
    }
    .location-info__hours {
      display: none;
    }
    &.show-hours .location-info__hours {
      display: block;
    }
    .store-locator_book-appointment {
      border-top: 1px solid $color-light-gray;
      display: block;
      font-weight: bold;
      font-size: 13px;
      line-height: 1.4em;
      @include swap_direction(padding, 10px 0 0);
      text-transform: uppercase;
      width: 100%;
      .icon {
        background: url('/media/export/cms/appointment_booking/icons/cal-ab-icon-store-locator-info.jpg') no-repeat 0 0;
        height: 34px;
        width: 40px;
        display: inline-block;
        float: $ldirection;
      }
    }
  }

  // Services
  $store-locator-promo-width: 224px;
  $store-locator-promo-gutter: 62px;
  .find-a-store-page {
    .promo-unit__headline {
      text-align: center;
      @include swap_direction(margin, 0 30px 40px);
    }
    .promo-unit--threecol {
      @include clearfix;
      width: ($store-locator-promo-width * 3) + ($store-locator-promo-gutter * 2);
      @include swap_direction(margin, 0 auto 80px);
      .promo-unit {
        float: $ldirection;
        width: $store-locator-promo-width;
        margin-#{$rdirection}: $store-locator-promo-gutter;
        &:last-child {
          margin-#{$rdirection}: 0;
        }
      }
      .promo-unit__headline {
        @include headline--quinary--plain;
        @include swap_direction(margin, 38px 0 15px);
      }
    }
  }
} //.device-pc
