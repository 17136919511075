// advent_calendar_capability

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin scale($scale) {
  @include transform(scale($scale));
  moz-transform-origin: top center;
  -o-transform-origin: top center;
  -ms-transform-origin: top center;
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

@mixin scaleLeftAlign($scale) {
  @include transform(scale($scale));
  moz-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

$offer-image-height: 284px;
$offer-image-width: 206px;

$card-image-height: 238px;
$card-image-width: 196px;

$card-mobile-image-height: 125px;
$card-mobile-image-width: 110px;

$color-red-darker: #c30505;
$color-red-darker-alpha: rgba(195, 5, 1, 0.3);
$color-navy-alpha: rgba(4, 10, 43, 0.75);

$boutique-nav-height-landscape: 92px;
$boutique-nav-height-portrait: 44px;

// 5 columns scaled
$low-bp-five-column-grid: 1096px;
$high-bp-five-column-grid: 1310px;

.advent-calendar {
  position: relative;
  overflow: hidden;
  @media #{$medium-up} {
    &.left-nav-layout {
      margin-#{$ldirection}: 200px;
      margin-#{$rdirection}: auto;
    }
  }
  &__wrapper {
    max-width: 1100px;
    margin: 0 auto;
    min-height: 790px;
    padding-bottom: 15px;
    position: relative;
    overflow: hidden;
    @media #{$medium-up} {
      background-position: inherit;
      background-size: unset;
      .left-nav-layout & {
        margin-#{$ldirection}: 0;
        margin-#{$rdirection}: auto;
      }
    }
    @media screen and (min-width: $high-bp-five-column-grid) {
      .left-nav-layout & {
        max-width: 1180px;
        padding-#{$rdirection}: 80px;
      }
    }
    // iPad scaled // landscape
    @media screen and (max-width: 1305px) {
      min-width: 100%;
      @media #{$large-up} {
        min-width: 1050px;
      }
    }
    // 3 columns
    @media screen and (max-width: 1095px) {
      max-width: 645px;
      min-width: 100%;
      @media #{$medium-down} {
        @media #{$landscape} {
          min-height: 700px;
          .left-nav-layout & {
            margin-top: $boutique-nav-height-landscape;
          }
        }
        @media #{$portrait} {
          min-height: 386px;
          .left-nav-layout & {
            margin-top: $boutique-nav-height-portrait;
          }
        }
      }
      @media #{$medium-up} {
        min-width: 645px;
      }
    }
    // 3 columns short
    @media screen and (max-width: 890px) {
      margin: auto;
      @media #{$iphone6plus-portrait} {
        max-width: 480px;
        min-height: 420px;
        .left-nav-layout & {
          margin-top: $boutique-nav-height-portrait;
        }
      }
      @media #{$iphone6plus-landscape} {
        min-height: 750px;
        .left-nav-layout & {
          margin-top: $boutique-nav-height-landscape;
        }
      }
    }
    .wrapper__pre-title {
      font-size: 13px;
      font-weight: 900;
      font-family: $akzidenz;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      text-align: center;
      color: $color-white;
      padding-top: 25px;
      @media screen and (max-width: 1095px) {
        @media #{$medium-up} {
          padding-top: 45px;
        }
      }
    }
    .wrapper__title {
      font-size: 30px;
      font-family: $bb-italic;
      letter-spacing: 0;
      text-transform: uppercase;
      margin: 5px 0 10px;
      text-align: center;
      color: $color-white;
      @media #{$landscape} {
        font-size: 58px;
        margin-bottom: 15px;
      }
      @media #{$medium-up} {
        font-size: 68px;
        margin-bottom: 35px;
      }
      @media screen and (max-width: 1095px) {
        @media #{$medium-up} {
          font-size: 52px;
          margin: 5px 0 30px;
        }
      }
    }
    .wrapper__cards {
      break {
        flex-basis: 100%;
        width: 0;
        height: 0;
        overflow: hidden;
      }
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: center;
      justify-content: center;
      counter-reset: card;
    }
    .wrapper__background {
      position: absolute;
      z-index: -1;
      width: 100%;
      @media #{$medium-up} {
        width: inherit;
      }
      img {
        width: 100%;
        height: auto;
        @media #{$medium-up} {
          width: inherit;
          height: inherit;
        }
      }
    }
  }
  &__loader {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: $color-white;
    z-index: 99;
    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 29px);
      #{$ldirection}: calc(50% - 29px);
      display: block;
      width: 50px;
      height: 50px;
      border: 4px solid $color-light-gray;
      border-top: 4px solid $color-navy;
      border-radius: 50%;
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
      z-index: 100;
    }
  }
  &__error-container {
    display: none;
    position: fixed;
    top: 0px;
    #{$ldirection}: 0px;
    width: 100%;
    height: 100%;
    background-color: $color-navy-alpha;
    z-index: 1000;
    .error-container {
      color: $color-navy;
      position: absolute;
      top: 50%;
      #{$ldirection}: 50%;
      display: table-cell;
      vertical-align: middle;
      max-width: 700px;
      width: 90%;
      min-height: 300px;
      padding: 10% 3em;
      font-size: 2em;
      background: $color-white;
      text-align: center;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-family: $akzidenz;
    }
  }
  &__popup {
    position: initial;
    top: 0px;
    #{$ldirection}: 0px;
    width: 100%;
    height: 100%;
    display: none;
    z-index: -1;
    @media #{$medium-up} {
      position: absolute;
      z-index: 1000;
    }
    .popup {
      max-width: 751px;
      width: 90%;
      display: none;
      position: fixed;
      top: 50%;
      #{$ldirection}: 50%;
      background: $color-white;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      transition: all 0.1s linear;
      z-index: 9999;
      @media #{$medium-up} {
        position: absolute;
        z-index: inherit;
      }
      @media #{$medium-up} {
        position: fixed;
        width: 751px;
        height: 416px;
        max-height: 416px;
      }
      &--welcome {
        @media #{$medium-up} {
          width: 500px;
          height: 330px;
        }
      }
    }
    .popup__messages {
      display: none;
      text-align: center;
      border-bottom: 0 none;
      background-color: $color-navy;
      color: $color-white;
      padding: 20px;
    }
    .popup__content {
      color: $color-navy;
      padding: 40px;
      position: relative;
      text-align: center;
      min-height: 230px;
      height: 100%;
      text-align: center;
      @media #{$medium-up} {
        text-align: $ldirection;
      }
      h3 {
        font-family: $bb-italic;
        letter-spacing: 0;
        text-transform: uppercase;
      }
      p {
        font-size: 15px;
        letter-spacing: 0.75px;
        font-family: $akzidenz;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }
  &__disclaimer {
    text-align: center;
    color: $color-medium-gray;
    padding: 25px;
    .left-nav-layout & {
      text-align: left;
      @media screen and (min-width: $high-bp-five-column-grid) {
        max-width: 1180px;
      }
      @media screen and (max-width: $low-bp-five-column-grid) {
        max-width: 645px;
      }
    }
  }
  .content__welcome-message {
    @include vertical-align();
    opacity: 0;
    animation: fadein 1s linear forwards;
    animation-delay: 1s;
    color: $color-navy;
    text-align: center;
    text-transform: uppercase;
    display: inline;
    @media #{$medium-up} {
      text-align: $ldirection;
      display: block;
    }
    h1 {
      font-size: 13px;
      font-weight: 900;
      font-family: $akzidenz;
      letter-spacing: 1.5px;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 32px;
      font-family: $bb-italic;
      letter-spacing: 0.001em;
      line-height: 32px;
      @media #{$medium-up} {
        font-size: 36px;
        line-height: 36px;
      }
    }
    p {
      text-transform: none;
    }
    .welcome-message__button {
      font-size: 13px;
      font-weight: 900;
      margin-top: 20px;
      text-decoration: underline;
      display: block;
    }
  }
  .content__offer-details {
    text-align: $ldirection;
    // pre header
    span:first-of-type {
      font-size: 13px;
      font-weight: 900;
      font-family: $akzidenz;
      letter-spacing: 1.5px;
      margin-top: 25px;
      text-transform: uppercase;
    }
    @media #{$medium-up} {
      @include vertical-align();
    }
  }
  .content__offer-image {
    @include vertical-align();
    @include swap_direction(margin, 0 40px 10px 0);
    float: $ldirection;
    position: absolute;
    @media #{$medium-up} {
      position: relative;
      @media #{$portrait} {
        position: absolute;
      }
    }
    img,
    picture {
      height: $offer-image-height;
      width: $offer-image-width;
      display: none;
      @media #{$medium-up} {
        display: block;
        @media #{$portrait} {
          display: none;
        }
      }
    }
  }
  .card {
    -webkit-flex: 1 auto;
    flex: 1 auto;
    position: relative;
    width: 100%;
    max-width: $card-mobile-image-width;
    height: $card-mobile-image-height;
    color: $color-white;
    margin: 5px;
    border: 1px solid $color-white;
    overflow: hidden;
    @media #{$landscape} {
      max-width: $card-mobile-image-width + 100;
      height: $card-mobile-image-height + 100;
    }
    @media #{$medium-up} {
      max-width: $card-image-width;
      height: $card-image-height;
      margin: 5px;
    }
    @media #{$medium-down} {
      @media #{$landscape} {
        height: 237px;
        margin: 5px;
      }
    }
    a {
      @include vertical-align();
      position: absolute;
      color: $color-white;
      height: 100%;
    }
    &:not(.card--tout):before,
    &:not(.card--tout):after {
      position: absolute;
      #{$ldirection}: 0;
      display: block;
      width: 100%;
      color: $color-white;
      font-variant: small-caps;
      font-weight: normal;
      text-rendering: auto;
      text-align: center;
      font-size: 80px;
      font-family: $linotype-didot-roman;
    }
    &:not(.card--tout):after {
      opacity: 0.65;
      content: counter(card);
      counter-increment: card;
      top: calc(13%);
      @media #{$medium-up} {
        font-size: 145px;
      }
      @media #{$medium-down} {
        @media #{$landscape} {
          font-size: 140px;
        }
      }
    }
    &.active:not(.card--tout):after {
      opacity: 1;
    }
    &.expired:after,
    &.expired:before {
      color: $color-medium-gray;
    }
    &--tile.active:hover {
      animation: none;
      cursor: pointer;
      animation: fadein 0.5s linear forwards;
      -webkit-animation: fadein 0.5s linear forwards;
      background-color: $color-red-darker-alpha;
      .content__offer-image-background {
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
      }
      img {
        width: 100%;
        display: none;
      }
    }
    &__content {
      @include vertical-align();
      display: table;
      width: 100%;
      table-layout: fixed;
      .content__offer-image-background {
        opacity: 0;
        position: relative;
        z-index: 2;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
      }
      p {
        font-size: 13px;
        font-family: $akzidenz;
        text-align: center;
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: 3px;
        padding: 10%;
        line-height: 22px;
      }
    }
    &--tout {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    &--tout:last-of-type {
      // 3 columns
      @media screen and (max-width: 1095px) {
        @media #{$medium-up} {
          display: none;
        }
      }
    }
    &.tout-1-2 {
      max-width: calc((196px * 2) + (2 * 5px));
    }
    &.tout-1-3 {
      max-width: calc((196px * 3) + (4 * 5px));
    }
    .tout__background {
      position: absolute;
      img {
        width: 100%;
      }
    }
  }
  .btn-primary {
    margin-top: 20px;
    font-size: 13px;
    color: $color-navy;
    font-weight: 900;
    font-family: $akzidenz;
    text-transform: uppercase;
    text-decoration: underline;
    letter-spacing: 2px;
    display: inline-block;
    @media #{$medium-up} {
      font-size: 12px;
      line-height: 33px;
      letter-spacing: 1px;
      width: 143px;
      height: 35px;
      border: solid 2px $color-navy;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      text-align: center;
      text-decoration: none;
    }
  }
  .btn-popup-close {
    position: absolute;
    top: 40px;
    #{$rdirection}: 55px;
    display: inline-block;
    width: 40px;
    height: 40px;
    padding: 2px;
    transition: ease 0.25s all;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    border-radius: 100%;
    text-align: center;
    &:hover {
      -webkit-transform: translate(50%, -50%) rotate(180deg);
      transform: translate(50%, -50%) rotate(180deg);
      text-decoration: none;
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:before {
      transform: rotate(45deg);
    }
    &:before,
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      #{$ldirection}: 20px;
      height: 40px;
      width: 1px;
      background-color: $color-light-gray;
    }
  }
  h1 {
    @media #{$medium-up} {
      font-size: 42px;
    }
  }
  .in-progress + .advent-calendar__wrapper {
    min-height: auto;
  }
  [data-show-image] {
    .card--tile:hover {
      img {
        display: block;
      }
    }
  }
  [data-show-image-future-offer] {
    .card--tile.future:hover {
      box-shadow: none;
      .content__offer-image-background {
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
      }
      img {
        width: 100%;
        display: block;
      }
    }
  }
  &__popup-container {
    @media #{$medium-up} {
      position: fixed;
      z-index: 9999;
    }
  }
}
// for boutique navigation
.advent-calendar-navigation #holiday_nav .mobile,
.advent-calendar-navigation #holiday_nav .desktop,
body.html .advent-calendar-navigation #holiday_nav .ipad {
  @media #{$medium-up} {
    display: none;
  }
  @media #{$device-ipad_only} {
    @media #{$landscape} {
      display: none;
    }
  }
  @media #{$device-tablet} {
    @media #{$landscape} {
      display: none;
    }
  }
}
// ipad
body.html .left-nav-layout,
body.html .left-nav-layout .advent-calendar__wrapper {
  @media #{$device-ipad_only} {
    @media #{$portrait} {
      margin-#{$ldirection}: auto;
    }
  }
  @media #{$device-tablet} {
    @media #{$portrait} {
      margin-#{$ldirection}: auto;
    }
  }
}

body.html .left-nav-layout .advent-calendar__disclaimer {
  @media #{$device-ipad_only} {
    @media #{$portrait} {
      text-align: center;
      max-width: 100%;
    }
  }
  @media #{$device-tablet} {
    @media #{$portrait} {
      text-align: center;
      max-width: 100%;
    }
  }
}

.advent-calendar-navigation #holiday_nav.js-desktop .mobile.ipad {
  @media #{$device-ipad_only} {
    @media #{$portrait} {
      display: block;
    }
  }
  @media #{$device-tablet} {
    @media #{$portrait} {
      display: block;
    }
  }
}

.advent-calendar-navigation #holiday_nav {
  @media #{$medium-up} {
    min-height: 100vw;
  }
  @media #{$device-ipad_only} {
    @media #{$portrait} {
      min-height: 100%;
    }
  }
  @media #{$device-ipad_only} {
    @media #{$landscape} {
      min-height: 133vw;
    }
  }
  @media #{$device-tablet} {
    @media #{$portrait} {
      min-height: 100%;
    }
  }
}

.advent-calendar-navigation {
  position: relative;
}
// scale 5 columns / excludes popup
.advent-calendar__wrapper > *:not(.advent-calendar__popup-container) {
  @media screen and (min-width: $low-bp-five-column-grid) and (max-width: $high-bp-five-column-grid) {
    @include scale(0.9);
    .left-nav-layout & {
      @include scaleLeftAlign(0.9);
    }
  }
}
