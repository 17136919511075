#loyalty__panel__how-to-earn {
  @extend %loyalty-panel;
  &.is-member {
    display: none;
    #loyalty__page__about & {
      display: block;
    }
  }
  @media #{$medium-up} {
    float: $ldirection;
    width: 50%;
  }
  .loyalty__panel__how-to-earn__title {
    @extend %loyalty-panel-title;
  }
}
