.review-submit {
  @include pie-clearfix;
  @include swap_direction(margin, 0 auto 50px auto);
  width: 746px;
}

.review-submit__product-image {
  float: $ldirection;
  margin-#{$rdirection}: 30px;
  width: 58px;
  height: auto;
}

.review-submit__header {
  @include text--short;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 11px;
}

.review-submit__product-head {
  font-family: $optimalight;
  font-size: 30px;
  line-height: 31px;
  @include swap_direction(margin, 0);
}

.review-submit__bv,
.review-submit__bv-mobile {
  a.BVLinkReturnToContent,
  a.BVLinkWriteReview {
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      padding: 14px 19px 12px;
      height: auto;
    }
  }
}

.review-submit__bv-mobile {
  .BVFieldSubmit,
  .BVFieldPreview {
    button.BVButton {
      height: auto;
      line-height: 0.9;
      &:active,
      &:visited,
      &:focus,
      &:hover {
        height: auto;
        line-height: 0.9;
      }
    }
  }
}
