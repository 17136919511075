.drawer-container {
  position: relative;
  z-index: 100;
}

.drawer-formatter__trigger {
  position: relative;
  z-index: 1;
  cursor: pointer;
  color: $color-periwinkle-blue;
  &.active {
    color: #fff;
  }
}

.drawer-formatter__close {
  background-size: auto auto;
  background-position: 0 -1513px;
  height: 12px;
  width: 21px;
  position: absolute;
  top: 10px;
  #{$rdirection}: 10px;
  cursor: pointer;
  z-index: 3;
}

.drawer-formatter__content {
  display: none;
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  width: 100%;
  min-height: $sticky-footer-drawer-height;
  height: auto;
  background: url($base-theme-image-path + 'rgbapng/040a2beb-10.png');
  background: rgba(4, 10, 43, 0.92);
  .button {
    height: 33px;
    line-height: 33px;
  }
}
