.rating-review {
  @media #{$xxlarge-up} {
    @include dw-foundation-sampling-layout;
  }
  @media #{$medium-up} {
    padding-bottom: 40px;
  }
  width: 100%;
  margin: 25px 0;
  &__content__bg {
    @media #{$large-up} {
      position: relative;
      display: inline-block;
      float: $ldirection;
      padding-#{$ldirection}: 30px;
      width: 47%;
      max-width: 500px;
    }
    &-image {
      width: 100%;
    }
  }
  &__content {
    width: 100%;
    text-align: center;
    margin: 25px 0;
    @media #{$large-up} {
      @include swap_direction(margin, 27px 0 25px 20px);
      width: 50%;
      display: inline-block;
      padding: 30px 0;
      text-align: $ldirection;
    }
    @media #{$xxlarge-up} {
      width: 56%;
    }
    &__title {
      text-align: center;
      padding-#{$ldirection}: 0;
      @media #{$medium-up} {
        text-align: $ldirection;
        padding-#{$ldirection}: 90px;
      }
      .heading {
        color: $color-navy;
        font-family: $optimalight;
        font-size: 50px;
        line-height: 0.8;
        @media #{$medium-up} {
          font-size: 35px;
          line-height: 1.2;
        }
      }
      .sub-heading {
        font-family: $akzidenz;
        margin-bottom: 18px;
        font-size: 18px;
        @media #{$medium-up} {
          margin-bottom: 25px;
          font-size: 15px;
        }
      }
    }
    &__star {
      margin-bottom: 20px;
      text-align: center;
      padding-#{$ldirection}: 0;
      @media #{$medium-up} {
        margin-bottom: 15px;
        text-align: $ldirection;
        padding-#{$ldirection}: 90px;
      }
      &-image {
        width: 40%;
        @media #{$medium-up} {
          width: auto;
        }
      }
    }
    &__write-button {
      font-family: $akzidenz;
      background: $color-navy;
      font-weight: bold;
      margin: 0;
      border: none;
      padding: 18px 86px;
      @media #{$large-up} {
        @include swap_direction(margin, 35px 0 0 90px);
        padding: 10px 20px;
      }
      .write-button__link {
        color: $color-white;
        letter-spacing: 2px;
        font-size: 14px;
        display: block;
        @media #{$medium-up} {
          font-size: 10px;
        }
      }
    }
    &__list {
      padding: 20px 60px 0;
      text-align: $ldirection;
      letter-spacing: 0;
      @media #{$medium-up} {
        padding: 0 90px;
        margin: 0;
      }
      .slick-arrow {
        height: 70px;
        width: 30px;
        z-index: 91;
        background-size: 50% 40%;
        background-position: center center;
        background-color: $color-light-gray-blue;
        background-image: url(/media/images/global/arrow-prev.png);
        background-repeat: no-repeat;
        @media #{$medium-up} {
          height: 45px;
          background-size: auto;
          background-position: center;
          background-color: transparent;
        }
      }
      .slick-prev {
        #{$ldirection}: 0;
        @media #{$medium-up} {
          background-image: url(/media/export/cms/dw_dramming/left-arrow.png);
          top: 25%;
        }
      }
      .slick-next {
        #{$rdirection}: 0;
        transform: rotate(180deg);
        @media #{$medium-up} {
          transform: rotate(0deg);
          background-image: url(/media/export/cms/dw_dramming/right-arrow.png);
          top: 25%;
        }
      }
      &-item {
        font-size: 28px;
        font-family: $optimalight;
        @media #{$xxlarge-up} {
          width: 575px;
        }
        &__author {
          font-size: 12px;
          letter-spacing: 1.5px;
          margin: 10px 0;
          font-family: $akzidenz;
        }
      }
      .slick-arrow:before {
        content: '';
      }
      .slick-dots {
        position: relative;
        bottom: -15px;
        @media #{$medium-up} {
          text-align: center;
        }
        li {
          margin: 0;
          @media #{$medium-up} {
            margin: 0 5px;
          }
          button {
            border: 1px solid $color-navy;
            background: $color-white;
            padding: 0;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            &:before {
              font-size: 14px;
              opacity: 0;
              color: $color-navy;
            }
          }
        }
        .slick-active {
          button {
            background: $color-navy;
            &:before {
              opacity: 0;
            }
          }
        }
      }
    }
    .slick-list {
      overflow: auto;
    }
  }
}
