.nodeblock-signup-form {
  background: hsl(0, 0%, 95.7%);
  @include swap_direction(padding, 18px 23px);
  input[type='text'] {
    width: 100%;
    margin-bottom: 12px;
  }
  input[type='submit'] {
    width: 100%;
  }
}
