//colors
$extended_mpp_sample_black_pearl: #040a2b;

@media #{$medium-up} {
  .samples-page {
    .samples-panel__title {
      text-align: left;
      color: $extended_mpp_sample_black_pearl;
      margin-bottom: 0;
      .head-line {
        font-family: $akzidenz;
        font-size: 15px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        font-weight: bold;
      }
      .offer-title-1 {
        letter-spacing: -0.01em;
      }
    }
    .samples-panel__content {
      .samples-buttons.top {
        clear: both;
      }
      .remaining {
        text-align: left;
        color: $extended_mpp_sample_black_pearl;
        font-size: 12px;
        p.available {
          font-size: 12px;
          margin-bottom: 0;
          display: block;
          text-transform: uppercase;
          &.pc-static-msg {
            display: none;
            margin-top: 12px;
          }
          &.mobile-static-msg {
            display: none;
          }
          &.static-msg {
            font-family: $akzidenz;
            letter-spacing: 0.15em;
          }
          &.dyn-msg-1,
          &.dyn-msg-2 {
            font-family: $optimaregular;
            font-size: 22px;
            line-height: 26px;
            text-transform: inherit;
          }
          &.dyn-msg-1 {
            font-family: $optimalight;
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 11px;
          }
        }
      }
      .product_brief__size {
        margin-top: 10px;
        text-transform: uppercase;
        font-size: 12px;
        padding-left: 32px;
      }
      .product_brief__buttons {
        margin-top: 16px;
      }
      .disclaimer-msg {
        font-family: $akzidenz;
        font-size: 15px;
        margin-top: 71px;
        text-align: left;
        width: 75%;
      }
      .go-shopping {
        text-decoration: underline;
      }
    }
    .sample-product {
      .checkout-sample-select-button {
        display: block;
      }
      .checkout-sample-select-button.selected {
        @include background($color-navy);
        @include link--bold;
        text-decoration: none; // override from .link--bold
        color: $color-white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    a.product_spp {
      -moz-user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -ms-user-select: none;
      user-select: none;
      pointer-events: none;
    }
  }
}
