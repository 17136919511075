// sms signup
.sms-signup__header {
  margin-bottom: 20px;
}

.sms-signup__message {
  clear: both;
  font-size: 11px;
  > div {
    @media #{$medium-up} {
      padding-top: 18px;
    }
  }
}

.sms-signup__terms, .email_signup__terms {
  clear: both;
  padding-top: 18px;
  label {
    @include text--tiny-copy;
  }
}

.sms-signup__success-header {
  @include header--modal;
  text-align: center;
  margin-bottom: 20px;
}

.sms-signup__success-text {
  text-align: center;
}
