$mq-spp-add-plusses: '(min-width: 540px)';
$mq-spp-2-cols: '(min-width: 540px) and (max-width: 749px)';
$mq-spp-3-cols: '(min-width: 750px) and (max-width: 829px)';
$mq-spp-4-cols: '(min-width: 830px)';

.spp-product__how-to-use {
  margin-bottom: 100px;
  #spp-howtouse-headline {
    margin-bottom: 40px;
  }
  .spp__steps {
    @include clearfix;
    //width: 90%;
    @media #{$mq-spp-add-plusses} {
      max-width: 500px;
    }
    @media #{$mq-spp-2-cols} {
      max-width: 568px;
    }
    @media #{$mq-spp-3-cols} {
      max-width: 750px;
    }
    @media #{$mq-spp-4-cols} {
      max-width: 800px;
    }
    margin: {
      #{$ldirection}: auto;
      #{$rdirection}: auto;
    }
  }
  .spp-howtouse {
    width: 226px;
    margin: {
      #{$ldirection}: auto;
      #{$rdirection}: auto;
    }
    @media #{$mq-spp-add-plusses} {
      float: $ldirection;
    }
    @media #{$mq-spp-2-cols} {
      &:nth-of-type(2n + 3) {
        clear: $ldirection;
      }
    }
    @media #{$mq-spp-3-cols} {
      width: 210px;
      &:nth-of-type(3n + 4) {
        clear: $ldirection;
      }
    }
    @media #{$mq-spp-4-cols} {
      &:nth-of-type(3n + 4) {
        clear: $ldirection;
      }
    }
    &:not(:last-of-type) {
      @media #{$mq-spp-add-plusses} {
        margin-#{$rdirection}: 57px;
        .spp-howtouse__product-image {
          position: relative;
          &:after {
            background-size: auto auto;
            background: url('/media/images/global/sprites-s38a9885fce.png') 0 -3788px no-repeat;
            width: 34px;
            height: 34px;
            content: ' ';
            display: block;
            position: absolute;
            top: 50%;
            #{$rdirection}: -47px;
            font-size: 4em;
            @include transform(translateY(-50%));
          }
        }
      }
    }
    .text-promo {
      margin-bottom: 20px;
    }
    .spp-howtouse__product-image img {
      max-width: 100%;
      height: auto;
    }
  }
  .headline--secondary {
    margin-bottom: 10px;
  }
  .text-product-listing-names-main,
  .text-product-listing-names-sub {
    margin-bottom: 10px;
  }
  .spp_howtouse__product {
    .sku-brief__button {
      margin-top: 10px;
    }
  }
}
