// email signup
.email_signup__header {
  margin-bottom: 20px;
}

.email-signup__message {
  clear: both;
  font-size: 11px;
  @media #{$medium-up} {
    padding-top: 18px;
  }
}

.email-signup__success-header {
  @include header--modal;
  text-align: center;
  margin-bottom: 20px;
}

.email-signup__success-text {
  text-align: center;
}

.email_popover {
  background-color: $color-white;
  z-index: 2;
  @media #{$medium-up} {
    position: absolute;
    top: 42px;
    #{$ldirection}: 111px;
    width: 430px !important;
    height: 297px !important;
  }
  .email_popover_title {
    font-size: 24px;
    text-align: $ldirection;
    margin-#{$ldirection}: 10px;
  }
  .email_popover_title__bar--full-width {
    width: 100%;
    height: 1px;
  }
  .email_popover_title2 {
    @include swap_direction(margin, 25px 0 0 10px);
  }
  .email_input {
    width: 100%;
    margin-top: 28px;
    margin-bottom: 28px;
    display: inline-block;
    @media #{$medium-up} {
      margin-#{$ldirection}: 10px;
      margin-#{$rdirection}: 20px;
    }
    input {
      width: 100%;
      &.form-text {
        color: $color-black;
        text-transform: lowercase;
        display: block;
        margin-bottom: 12px;
        text-align: center;
        @media #{$medium-up} {
          display: inline;
          float: $ldirection;
          width: 230px;
          margin-#{$rdirection}: 20px;
          margin-bottom: 0;
          text-align: start;
        }
      }
      &.form-submit {
        height: auto;
        @include swap_direction(padding, 0);
        line-height: 16px;
        text-align: center;
        @media #{$medium-up} {
          width: auto;
          text-align: start;
        }
      }
      &[type='submit'] {
        line-height: 40px;
        @media #{$medium-up} {
          line-height: normal;
          height: 33px;
          @include swap_direction(padding, 0 12px);
        }
      }
    }
  }
  .email_thanks {
    @include swap_direction(margin, 40px 0 35px 10px);
    line-height: 15px;
  }
  .email_popover__content {
    overflow: hidden;
    font-size: 18px;
    padding-top: 30px;
  }

  // I know there's a better way to do this
  .email_popover__disclaimer,
  .email_popover__disclaimer p,
  .email_popover__disclaimer a {
    @include form-help;
    letter-spacing: 0.1em;
  }
  .email_popover__disclaimer {
    color: $color-medium-gray;
    a {
      text-decoration: underline;
    }
  }
  .popover_signup_error {
    width: 100%;
    top: 158px;
    #{$ldirection}: 13px;
    position: absolute;
    font-size: 12px;
    color: $color-red;
    @include swap_direction(border, 0px);
    display: none;
    z-index: 15;
    li {
      list-style: disc inside;
    }
  }
  .popup_social_icons {
    @include popup_social_icons;
  }
  .email_popover__social_icons {
    @include popup_social_icons;
    top: auto;
    bottom: auto;
    @media #{$medium-up} {
      bottom: 0;
    }
  }
}

// welcome15 offer popover
.welcome15-overlay {
  .email_popover {
    position: relative;
    padding-bottom: 40px;
    @media #{$medium-up} {
      top: auto;
      #{$ldirection}: auto;
    }
    .email_popover_title {
      margin-#{$ldirection}: 0;
    }
    .email_input {
      margin-top: 0;
      margin-bottom: 0;
    }
    .email_popover--disclaimer {
      position: absolute;
      display: none;
      overflow: hidden;
      width: 100%; // auto;
      margin-#{$rdirection}: 20px; // The right padding of the bounding box
      margin-top: 30px;
      height: 100%;
      z-index: 999;
      background-color: #fff;
      @media #{$medium-up} {
        @include swap_direction(margin, 0);
      }
      &.scrollable {
        overflow-y: scroll;
      }
    }
    .popup_social_icons {
      img {
        @media #{$small-only} {
          margin-#{$rdirection}: 10px;
        }
      }
    }
    &.show_second_language {
      @media #{$medium-up} {
        height: 700px !important;
        width: 530px !important;
      }
      hr {
        margin-top: 30px;
        margin-bottom: 30px;
        max-width: 420px;
        width: 100%;
        float: $ldirection;
      }
      .email_popover__content {
        .welcome-title {
          margin-bottom: 15px;
          letter-spacing: 0.15em;
        }
        h4 {
          font-size: 56px;
          line-height: 50px;
        }
      }
      .popup_social_icons {
        position: static;
      }
      .email_popover__disclaimer {
        position: static;
        margin-top: 15px;
        p {
          line-height: 10px;
          @include swap_direction(margin, 0);
        }
      }
    }
  }
}
/* ALT LAYOUT */
.welcome15-overlay.alt_layout1 {
  #cboxClose {
    background-image: url('/media/images/pro_active_popup/transparent_close_x.png') !important;
    background-size: 34px 34px;
    background-position: right center;
    background-size: contain;
    #{$rdirection}: 43px !important;
    top: 25px !important;
  }
  #cboxClose--alt {
    color: #ffffff;
  }
  #cboxLoadedContent {
    overflow: visible !important;
  }
  input.form-text::-webkit-input-placeholder {
    background-color: transparent !important;
    color: #ffffff !important;
  }
  .email_popover {
    color: #ffffff;
    background-color: transparent;
    .email_popover__shop_now_link {
      margin-bottom: -2px;
      margin-top: 17px;
      color: #9eafff;
      font-size: 12px;
      text-decoration: underline;
      font-weight: bold;
    }
    .email_popover__shop_now_link a {
      color: #9eafff;
      font-weight: bold;
      letter-spacing: 0.15em;
    }
    .text-form-text,
    input[type='text'],
    input[type='tel'],
    input[type='email'],
    input[type='password'],
    textarea,
    .form-text,
    select {
      border-color: #ffffff;
    }
    .form-submit {
      background-color: transparent;
      @include swap_direction(border, 1px solid $color-periwinkle-blue);
      color: #9eafff;
    }
    .email_popover__disclaimer a {
      color: #9eafff;
    }
    h4,
    .promo-unit__headline {
      font-size: 66px;
      line-height: 62px;
    }
    .email_popover__content p {
      letter-spacing: 1.5px;
    }
    .email_popover__content {
      padding-top: 0px;
      overflow: visible;
      width: 450px;
    }
    .email_popover__content.thanks p:nth-child(3) {
      padding-top: 14px;
    }
    .email_input {
      margin-#{$ldirection}: 0px;
      padding-#{$ldirection}: 1px;
      padding-#{$rdirection}: 17px;
      margin-top: 8px;
      input.form-text {
        width: 290px;
      }
    }
    .text-form-text::-moz-placeholder,
    input[type='text']::-moz-placeholder,
    input[type='tel']::-moz-placeholder,
    input[type='email']::-moz-placeholder,
    input[type='password']::-moz-placeholder,
    textarea::-moz-placeholder,
    .form-text::-moz-placeholder,
    select::-moz-placeholder {
      background-color: transparent;
      color: #ffffff;
    }
    input {
      background-color: transparent;
      box-shadow: none;
    }
    .email_input input.form-text {
      color: #ffffff;
      margin-#{$rdirection}: 10px;
    }
    .email_popover__social_icons {
      top: 305px;
    }
    .email_popover__disclaimer {
      color: #ffffff;
    }
    .email_input input[type='submit'] {
      padding-#{$ldirection}: 17px;
      padding-#{$rdirection}: 17px;
    }
    .email_popover--disclaimer {
      background-color: transparent;
    }
    .welcome15_popover__link {
      p {
        line-height: 16px;
        font-size: 12px;
        @media #{$medium-up} {
          line-height: 21px;
        }
      }
      a {
        color: $color-periwinkle-blue;
        text-decoration: underline;
        @media #{$medium-up} {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }
}
/* PC specific */
.device-pc .welcome15-overlay.alt_layout1 {
  &#colorbox {
    width: 585px !important;
  }
  #cboxWrapper {
    width: 585px !important;
  }
  #cboxContent {
    background-color: transparent;
    width: 465px !important;
  }
  .email_popover {
    &.show_second_language {
      @media #{$medium-up} {
        height: 700px !important;
        width: 530px !important;
      }
      hr {
        margin-top: 22px;
        margin-bottom: 26px;
        max-width: 420px;
        width: 100%;
        float: $ldirection;
      }
      .email_popover__content {
        .welcome-title {
          margin-bottom: 15px;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: 0.15em;
        }
        h4 {
          font-size: 56px;
          line-height: 50px;
        }
      }
      .popup_social_icons {
        position: static;
      }
      .email_popover__disclaimer {
        position: static;
        margin-top: 15px;
        p {
          line-height: 10px;
          @include swap_direction(margin, 0);
        }
      }
    }
  }
}
/* MOBILE ALT LAYOUT */
.device-mobile #colorbox.welcome15-overlay.alt_layout1 {
  top: 0px !important;
  overflow: visible;
  #cboxWrapper {
    background: $color-navy;
    overflow: visible;
  }
  #cboxLoadedContent {
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    overflow: visible;
  }
  #cboxContent {
    max-width: 320px;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    padding-#{$ldirection}: 0px;
    padding-#{$rdirection}: 0px;
    float: none !important;
    overflow: visible;
    padding-top: 0px;
    background-color: transparent;
  }
  #cboxClose {
    background-image: url('/media/images/pro_active_popup/transparent_close_x.png') !important;
    background-size: 34px 34px;
    background-repeat: no-repeat;
    height: 67px;
    width: 51px;
    color: #ffffff;
    text-indent: -7px;
    padding-top: 35px;
    overflow: visible;
    background-position: 5px 0;
    z-index: 2012;
    #{$ldirection}: 250px;
    margin-top: -20px;
  }
  #cboxClose--alt {
    background-image: url('/media/images/pro_active_popup/transparent_close_x.png') !important;
    background-size: 34px 34px;
    background-repeat: no-repeat;
    height: 67px;
    width: 51px;
    color: #ffffff;
    text-indent: 0px;
    padding-top: 43px;
    overflow: visible;
    background-position: 5px 0;
    z-index: 2013;
    #{$ldirection}: 250px;
    margin-top: -25px;
  }
  input.form-text::-webkit-input-placeholder {
    color: #040a2b !important;
  }
  .email_popover {
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    width: 293px;
    height: 600px;
    z-index: 2010;
    padding-bottom: 0px;
    &.show_second_language {
    }
    .email_popover__content {
      width: 320px;
      padding-top: 88px;
      line-height: 35px;
    }
    .email_popover__disclaimer p {
      line-height: 16px;
    }
    .email_popover__disclaimer {
      line-height: 16px;
      #{$ldirection}: 2px;
    }
    .email_popover__social_icons {
      top: 423px;
      #{$ldirection}: -7px;
      width: 320px;
    }
    .email_popover--disclaimer {
      height: 349px;
      @include swap_direction(margin, auto);
      top: -76px;
      #{$ldirection}: 0;
      bottom: 0;
      #{$rdirection}: 0;
    }
    .email_popover--disclaimer p {
      margin-top: 0px;
    }
    .text-form-text::-moz-placeholder,
    input[type='text']::-moz-placeholder,
    input[type='tel']::-moz-placeholder,
    input[type='email']::-moz-placeholder,
    input[type='password']::-moz-placeholder,
    textarea::-moz-placeholder,
    .form-text::-moz-placeholder,
    select::-moz-placeholder {
      background-color: #ffffff;
      color: #040a2b;
    }
    input {
      background-color: #ffffff;
    }
    .email_input input.form-text {
      color: #040a2b;
      text-align: $ldirection;
    }
    .email_input input {
      width: 290px;
    }
    .email_input {
      width: 318px;
      margin-top: 0px;
    }
    .popup_social_icons img,
    .welcome15-overlay .email_popover .email_popover__social_icons img {
      margin-#{$rdirection}: 36px;
    }
    .youtube > img {
      margin-#{$rdirection}: 0 !important;
    }
    .email_input input[type='submit'] {
      background-color: #040a2b;
    }
    .already_signed_up .form-submit {
      width: 298px;
      margin-top: 24px;
    }
    p {
      letter-spacing: 1.9px;
    }
  }
}

.device-mobile {
  #cboxOverlay.welcome15-overlay.alt_layout1 {
    height: 120%;
  }
  #colorbox.welcome15-overlay.with-second-language {
    position: absolute !important;
    height: 2500px !important;
    .email_popover__content {
      &.second_language_content {
        padding-top: 68px;
      }
      & > h3,
      & > h4 {
        font-family: $optimalight;
        font-size: 40px;
        line-height: 35px;
      }
      & > .welcome-title {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}
/* /ALT LAYOUT */

/* Tooltip */
.footer-signup-email {
  .tooltip {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    .tooltiptext-over {
      visibility: hidden;
      width: 100%;
      background-color: $color-dark-gray;
      color: $color-white;
      text-align: justify;
      border-radius: 6px;
      padding: 10px;
      position: absolute;
      z-index: 99;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s;
      font-weight: normal;
      bottom: 125%;
    }
    .tooltiptext-over::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 10%;
      border-width: 5px;
      border-style: solid;
      border-color: $color-dark-gray transparent transparent transparent;
    }
    &:hover .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
  }
}
