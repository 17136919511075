@mixin utility-nav-item-button-base {
  vertical-align: top;
  height: $header-item-height;
  line-height: $header-item-height;
}

@mixin utility-nav-item-button {
  @include utility-nav-item-button-base;
  padding: 0 8px;
  cursor: pointer;
  @media #{$medium-up} {
    padding-#{$ldirection}: 16px;
  }
  @media #{$xlarge-up} {
    border-#{$ldirection}: 1px solid $color-light-gray;
    padding: 0 20px;
  }
}

@mixin utility-nav-item-text {
  @include text--short--plain;
  letter-spacing: 0;
  font-size: 11px;
  color: $color-dark-navy;
  @media #{$medium-up} {
    @include text--short--plain;
  }
  .section-aerin &,
  #{$aerin} & {
    color: $color-dark-navy;
  }
  .section-re-nutriv &,
  #{$renutriv} & {
    color: $color-white;
  }
  #{$dark-nav} & {
    color: $color-dark-navy;
    @media #{$medium-up} {
      color: $color-white;
    }
  }
  #{$alt} & {
    color: $color-dark-navy;
    @media #{$small-only} {
      color: $color-dark-navy;
    }
    .section-aerin &,
    #{$aerin} &,
    #{$estee-edit} & {
      color: $color-dark-navy;
      @media #{$small-only} {
        color: $color-dark-navy;
      }
    }
    .section-re-nutriv &,
    #{$renutriv} & {
      color: $color-dark-navy;
    }
  }
}

@mixin utility-nav-default-cart-icon {
  background: url($svg-path + 'cart.svg') no-repeat;
  width: 22px;
  height: 22px;
  display: block;
  @media #{$medium-up} {
    width: 24px;
    height: 24px;
  }
}

@mixin utility-nav-default-search-icon {
  @include svg-icon-inline-mask('search.svg');
  background-color: $color-black;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

@mixin gluxre_update__utility_nav {
  .page-wrapper .page-header {
    padding: 0;
    @media #{$device-ipad_mini} {
      min-height: auto;
    }
    .page-branding {
      @media #{$xlarge-up} {
        padding-top: 15px;
      }
    }
    &#{$alt-color-white}#{$alt}#{$search} {
      @media #{$device-ipad_mini} {
        background: $color-white;
      }
    }
    &#{$alt} {
      .header-inner {
        .gnav-active &,
        .mobile-gnav-active & {
          @media #{$device-ipad_mini} {
            background: $color-white;
          }
        }
      }
    }
  }
  .page-wrapper-checkout .page-header {
    .page-branding {
      @media #{$xlarge-up} {
        margin-#{$ldirection}: 0;
      }
    }
  }
  .header-items {
    position: relative;
    @media #{$device-ipad_mini} {
      padding: 0 0 9px 0;
      margin: 0 0 9px 0;
      top: 9px;
      #{$alt-color-white}#{$alt} & {
        background: $color-white;
      }
    }
    @media #{$xlarge-up} {
      padding: 9px 0 0;
    }
  }
  .page-utilities-loyalty-elc-nodeblock {
    float: $ldirection;
  }
  .utility-nav {
    &__item {
      float: $ldirection;
      vertical-align: bottom;
      .user-loyalty-state {
        .user-logged-in,
        .user-logged-out {
          &__link {
            #{$alt} &,
            #{$dark-nav} {
              color: $color-dark-navy;
              .section-re-nutriv &,
              #{$renutriv} & {
                color: $color-dark-navy;
              }
              .section-aerin &,
              #{$aerin} & {
                color: $color-dark-navy;
              }
            }
          }
        }
      }
    }
    &__help {
      display: none;
      @media #{$device-ipad_mini} {
        display: block;
      }
      // hide on cart index.tmpl
      #{$min-nav} &,
      .elc-gnav-minimal & {
        display: none;
      }
      &-button {
        @include utility-nav-item-button;
        border-#{$ldirection}: none;
      }
      &-text {
        @include utility-nav-item-text;
        display: block;
        #{$aerin} & {
          color: $color-light-gray--aerin;
        }
      }
    }
    &__wishlist {
      position: relative;
      &-button {
        vertical-align: top;
        height: $header-item-height;
        padding: 0 12px;
        @media #{$xlarge-up} {
          padding: 0 20px;
        }
        top: 0;
        #{$ldirection}: 0;
        z-index: 10;
        border-#{$ldirection}: 1px solid $color-light-gray;
        cursor: pointer;
        @media #{$small-only} {
          position: relative;
          border-#{$ldirection}: none;
          display: none;
          .show-wishlist & {
            display: block;
          }
        }
        &-svg {
          position: relative;
          top: 2px;
          width: 23px;
          height: 21px;
        }
        &-count {
          display: inline;
          font-size: 11px;
          position: relative;
          top: -1px;
        }
      }
    }
    &__account {
      @media #{$medium-up} {
        position: relative;
        margin-top: -10px;
      }
      .user-loyalty-state,
      .user-login-state {
        display: none;
        @media #{$medium-up} {
          display: block;
        }
      }
      // hide on cart index.tmpl
      #{$min-nav} &,
      .elc-gnav-minimal & {
        display: none;
      }
      &-button {
        @include utility-nav-item-button;
        border-#{$ldirection}: none;
        padding: 0;
        @media #{$medium-up} {
          border-#{$ldirection}: none;
          padding: 0 20px;
        }
      }
      &-icon {
        display: none;
      }
      &-text {
        @include utility-nav-item-text;
        padding-top: 2px;
        @media #{$medium-up} {
          display: none;
        }
        .elc-user-state-logged-in & {
          display: block;
        }
      }
    }
    &__signin {
      &-text {
        @include utility-nav-item-text;
        display: block;
        padding-top: 2px;
        @media #{$medium-up} {
          padding: 0;
        }
        .elc-user-state-logged-in & {
          display: none;
        }
      }
    }
    &__cart {
      position: relative;
      @media #{$medium-up} {
        position: relative;
        #{$ldirection}: 45px;
      }
      @media #{$xlarge-up} {
        #{$ldirection}: 48px;
      }
      // show cart mobile-only during checkout index.tmpl
      #{$min-nav} & {
        @media #{$large-up} {
          display: none;
        }
      }
      .elc-gnav-minimal & {
        display: none;
      }
      &-button {
        @include utility-nav-item-button;
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        z-index: 10;
        border-#{$ldirection}: 1px solid $color-light-gray;
        cursor: pointer;
        border-#{$rdirection}: 1px solid $color-light-gray;
        @media #{$medium-up} {
          border-#{$rdirection}: none;
        }
        @media #{$small-only} {
          position: relative;
          border-#{$ldirection}: none;
        }
        .section-aerin &,
        #{$aerin} & {
          border-color: $color-header-border-aerin;
        }
      }
      &-text {
        @include utility-nav-item-text;
        display: none;
      }
      &-count {
        @include utility-nav-item-button-base;
        @include swap_direction(padding, 0 0 0 40px);
        text-align: $rdirection;
        font-size: 11px;
        width: 0;
        cursor: pointer;
        @media #{$xlarge-up} {
          @include swap_direction(padding, 0 6px 0 46px);
          border-#{$ldirection}: 1px solid $color-light-gray;
        }
        @media #{$small-only} {
          @include min-width(5px);
          padding: 2px 2px 0 2px;
          text-align: center;
          position: absolute;
          width: 100%;
          top: 0;
          #{$ldirection}: 0;
        }
      }
    }
  }
  .has-mobile-checkout-nav {
    .utility-nav {
      &__cart {
        @media #{$medium-up} {
          position: relative;
          #{$ldirection}: 100px;
        }
        @media #{$large-up} {
          position: relative;
          #{$ldirection}: 45px;
        }
      }
    }
  }
  .utility-nav__cart-svg {
    fill: $color-navy;
    position: relative;
    top: 7px;
    width: 22px;
    height: 38px;
    @media #{$medium-up} {
      width: 24px;
    }
  }
  .utility-nav__hide-loyalty {
    .user-login-state {
      @media #{$medium-up} {
        #{$rdirection}: 20px;
      }
    }
    .utility-nav__account {
      &-text {
        @media #{$medium-up} {
          padding-top: 0;
        }
      }
    }
    .user-login-state {
      @media #{$medium-up} {
        bottom: -18px;
      }
    }
  }
  .utility-nav__show-loyalty {
    .utility-nav__account-button {
      @media #{$medium-up} {
        @include swap_direction(padding, 0 20px 0 10px);
      }
    }
    .utility-nav__account-text {
      .elc-user-state-logged-in & {
        font-size: 12px;
        font-weight: normal;
        text-transform: none;
        @media #{$small-only} {
          letter-spacing: 0;
          font-size: 11px;
          padding-top: 2px;
          .js-is_signed_in {
            display: none;
          }
        }
      }
    }
    &.utility-nav__loyalty {
      .user-login-state {
        @media #{$medium-up} {
          position: static;
          min-width: 0;
        }
      }
      .user-greeting {
        margin-#{$ldirection}: 9px;
        color: $color-navy;
        font-weight: normal;
        text-transform: none;
        text-decoration: none;
        #{$alt} & {
          color: $color-navy;
          .section-re-nutriv &,
          #{$renutriv} & {
            color: $color-navy;
          }
        }
      }
      .sign-out-link {
        margin-#{$ldirection}: 7px;
        color: $color-darker-gray;
        font-weight: normal;
        text-transform: none;
        text-decoration: none;
        #{$alt} & {
          @media #{$medium-up} {
            color: $color-darker-gray;
          }
        }
      }
    }
    &.utility-nav__account {
      &.utility-nav__loyalty {
        .user-login-state {
          display: block;
        }
        .user-loyalty-state {
          @media #{$device-ipad_only} {
            #{$rdirection}: 20px;
          }
        }
      }
      .user-loyalty-state {
        display: none;
        @media #{$medium-up} {
          display: block;
        }
      }
    }
    .user-loyalty-state {
      @media #{$medium-up} {
        display: block;
        position: absolute;
        font-size: 12px;
        font-weight: normal;
        text-align: $rdirection;
        #{$rdirection}: 20px;
        min-width: 375px;
        @media #{$large-down} {
          @media #{$portrait} {
            top: 38px;
          }
        }
        #{$alt} & {
          color: $color-white;
        }
        .user-logged-out,
        .user-logged-in {
          display: block;
          &__link {
            margin-#{$ldirection}: 9px;
            color: $color-navy;
            font-weight: normal;
            text-decoration: none;
            .js-loyalty-points-value {
              font-weight: bold;
            }
            #{$alt} & {
              color: $color-navy;
            }
          }
        }
      }
      @media #{$large-up} {
        bottom: 0;
      }
      @media #{$large-only} {
        #{$rdirection}: 10px;
      }
    }
  }
  .gnav-active,
  .mobile-gnav-active {
    #{$alt},
    #{$dark-nav} {
      .page-utilities {
        &__cart-icon {
          @include utility-nav-default-cart-icon;
        }
        &__account-text,
        &__signin-text,
        &__help-text {
          color: $color-dark-navy;
        }
      }
    }
    &.section-re-nutriv,
    &#{$renutriv} {
      #{$alt},
      #{$dark-nav} {
        .page-utilities {
          .utility-nav__signin-text {
            color: $color-dark-navy;
          }
          .page-utilities__cart-icon {
            @include utility-nav-default-cart-icon;
          }
          .page-utilities__account-text,
          .page-utilities__signin-text,
          .page-utilities__help-text {
            color: $color-dark-navy;
          }
        }
        .header-items {
          background: $color-white;
        }
      }
    }
  }
  .page-utilities__search-icon {
    #{$alt-color-white} & {
      body.device-mobile.section-re-nutriv & {
        @media #{$medium-down} {
          display: block;
          background-image: url($base-theme-image-path + 'sprites/search_white.png');
        }
      }
      body.device-mobile.section-re-nutriv.mobile-gnav-active & {
        @media #{$medium-down} {
          display: block;
          @include utility-nav-default-search-icon;
        }
      }
    }
  }
  .page-utilities {
    &__cart-icon {
      #{$search}#{$alt}#{$alt-color-white} & {
        @include utility-nav-default-cart-icon;
      }
    }
    &__account-text,
    &__signin-text,
    &__help-text {
      #{$search}#{$alt}#{$alt-color-white} & {
        color: $color-dark-navy;
        .section-re-nutriv &,
        #{$renutriv} & {
          color: $color-dark-navy;
        }
      }
    }
  }
  .section-re-nutriv,
  .brand-renutriv {
    #renutriv #renutriv_nav {
      top: 0;
      @media #{$large-up} {
        top: 85px;
        padding: 40px 0 0 20px;
      }
    }
  }
}
