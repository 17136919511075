///
/// @file drupal_modules/esteelauder_common/_product_hero_tout.scss
/// Styles for product hero touts (found within carousel blocks on MPPs)
///

// Hero and tout shared styles
.product-hero-tout-wrapper {
  background: $color-white;
  margin-top: -$header-collapsed-height;
  margin-bottom: 30px;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: 1366px;
}

// products page needs a negative margin on the carousel
.slick-prodpage {
  .product-hero-tout-wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.product-hero-tout {
  width: 100%;
  min-height: 200px;
  position: relative;
  max-width: 1300px;
  .promo-eyebrow-text {
    display: block;
    position: absolute;
    top: 27%;
    #{$ldirection}: 0;
    width: 100%;
    z-index: 10;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-size: 12px;
    z-index: 11;
  }
  .tout-headline {
    top: 0;
    #{$ldirection}: 0;
    padding-top: 16%;
    position: absolute;
    width: 100%;
    z-index: 10;
    text-align: center;

    // field values for alignment adjustments of main headline
    &.tout-headline--shift-left {
      text-align: $ldirection;
      span {
        padding-#{$ldirection}: 20%;
      }
    }
    &.tout-headline--far-left {
      text-align: $ldirection;
      span {
        padding-#{$ldirection}: 5%;
      }
    }
    &.tout-headline--shift-right {
      text-align: $rdirection;
      span {
        padding-#{$rdirection}: 20%;
      }
    }
    &.tout-headline--far-right {
      text-align: $rdirection;
      span {
        padding-#{$rdirection}: 5%;
      }
    }
  }
  .product-hero-tout-container {
    position: relative;
    max-width: 844px;
    width: 65%;
  }
  .product-background-image,
  .product-promo-image {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  .tout__product {
    position: absolute;
    bottom: -30px;
    #{$rdirection}: 0;
    @include swap_direction(padding, 0 0 0 63.5%);
  }
  .product-promo-link {
    display: block;
    #{$rdirection}: -21%;
    position: relative;
    max-width: 308px;
    z-index: 1;
  }
  .tout__product-copy {
    position: absolute;
    top: 25%;
    padding-#{$ldirection}: 129%;
    .promo-header-text {
      width: 190px;
      position: relative;
      z-index: 1;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      font-size: 12px;
      line-height: 1.6;
      .subhead {
        margin-bottom: 3px;
      }
      .subcopy {
        font-weight: bold;
      }
    }
    .cta-button-wrapper {
      margin-top: 20px;
      position: relative;
      z-index: 1;
      .button--plus-sign {
        @include button-plus;
        @include text--short--plain;
        text-indent: 0;
        font-weight: bold;
        position: relative;
        width: 150px;
        padding-#{$ldirection}: 60px;
        text-align: $ldirection;
        white-space: nowrap;
        color: $color-navy;
        &:before {
          content: '';
          position: absolute;
          #{$ldirection}: 40px;
          height: 40px;
          border-#{$rdirection}: 1px solid $color-navy;
        }
      }
    }
  }
}

// Variants

// Template 1
.product-hero-tout--style1 {
  margin-bottom: 30px;
}

// Template 2
.product-hero-tout--style2 {
  @include swap_direction(margin, 0 auto);
  .product-hero-tout-container {
    @include swap_direction(margin, 0 auto);
  }
  .promo-eyebrow-text {
    top: 19%;
  }
  .tout-headline {
    padding-top: 11.75%;
  }
  .tout__product {
    bottom: 10px;
  }
  .product-promo-link {
    #{$rdirection}: 56%;
  }
  .tout__product-copy {
    padding-#{$ldirection}: 52%;
  }
}

// Template 3
.product-hero-tout--style3 {
  margin-bottom: 30px;
  float: $rdirection;
  .product-hero-tout-container {
    float: $rdirection;
  }
  .promo-eyebrow-text {
    padding-#{$rdirection}: 145px;
  }
  .tout__product {
    @include swap_direction(padding, 0 63.5% 0 0);
  }
  .product-promo-link {
    #{$rdirection}: auto;
    #{$ldirection}: -45%;
  }
  .tout__product-copy {
    padding-#{$ldirection}: 0;
    top: 15%;
    #{$ldirection}: -36%;
    width: 220px;
    z-index: 1;
    .promo-header-text {
    }
  }
}

// Template 4
.product-hero-tout--style4 {
  margin-top: 220px;
  margin-bottom: 15px;
  @media (max-width: 1024px) {
    margin-top: 160px;
  }
  .product-hero-tout-container {
    max-width: 1104px;
    width: 83%;
  }
  .promo-eyebrow-text {
    top: -23%;
  }
  .tout-headline {
    top: -13%;
    padding-top: 0;
  }
  .tout__product {
    bottom: -15px;
    @include swap_direction(padding, 0 0 0 71.5%);
  }
  .product-promo-link {
    #{$rdirection}: 16%;
  }
  .tout__product-copy {
    padding-#{$ldirection}: 66%;
    top: 8%;
  }
}

// FIXME

/* Target Firefox only */
@-moz-document url-prefix() {
  .product-hero-tout {
    .tout__product-copy {
      padding-#{$ldirection}: 47.5%;
    }
  }
  .product-hero-tout--style2 {
    .tout__product-copy {
      padding-#{$ldirection}: 19.5%;
    }
  }
  .product-hero-tout--style3 {
    .tout__product-copy {
      padding-#{$ldirection}: 0;
    }
  }
  .product-hero-tout--style4 {
    .tout__product-copy {
      padding-#{$ldirection}: 18.5%;
    }
  }
}

// IE hack
body.ie {
  .product-hero-tout {
    .tout__product-copy {
      padding-#{$ldirection}: 47.5%;
    }
  }
  .product-hero-tout--style2 {
    .tout__product-copy {
      padding-#{$ldirection}: 19.5%;
    }
  }
  .product-hero-tout--style3 {
    .tout__product-copy {
      padding-#{$ldirection}: 0;
    }
  }
  .product-hero-tout--style4 {
    .tout__product-copy {
      padding-#{$ldirection}: 18.5%;
    }
  }
}
