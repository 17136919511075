/// @file _pc_mpp_compare.scss
/// print specific styles are in
/// themes/estee_base/scss/print/_print_mpp_compare.scss

#cboxOverlay.mpp-compare__container {
  background: $color-white;
}

#colorbox.mpp-compare__container {
  background: $color-navy;
  #cboxContent {
    background: $color-navy;
    @include swap_direction(padding, 0);
  }
}

.mpp-compare {
  color: $color-white;
  @include swap_direction(padding, 30px);
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    @include swap_direction(border, 0);
    outline: 0;
    font-size: 100%;
    border-collapse: collapse;
    vertical-align: baseline;
    background: transparent;
  }
}

@media #{$small-up} {
  .mpp-compare__print {
    display: none;
  }
}

@media #{$large-up} {
  .mpp-compare__print {
    display: block;
    text-align: $rdirection;
    padding-#{$rdirection}: 30px;
    text-decoration: underline;
    text-transform: uppercase;
    color: $color-periwinkle-blue;
  }
}

.mpp-compare__table {
  width: 900px;
  td {
    @include swap_direction(border, 1px solid $color-medium-navy);
    text-align: center;
    vertical-align: top;
    @include swap_direction(padding, 15px);
  }
  th {
    @include swap_direction(padding, 0 15px 15px 15px);
    font-weight: normal;
  }
  // border
  tr:first-child td {
    border-top: 0;
    @include swap_direction(padding, 0 15px 15px 15px);
  }
  tr:last-child td {
    border-bottom: 0;
  }
  tr.mpp-compare__cart td {
    border-bottom: 0;
  }
  tr td:first-child {
    border-#{$rdirection}: 0;
    border-#{$ldirection}: 0;
  }
  tr td:nth-child(2) {
    border-#{$ldirection}: 0;
  }
  tr td:last-child {
    border-#{$rdirection}: 0;
  }
  // widths
  th:nth-of-type(1) {
    width: 100px;
    @include swap_direction(padding, 0 10px);
  }
  th:nth-of-type(2) {
    width: 266px;
  }
  th:nth-of-type(3) {
    width: 266px;
  }
  th:nth-of-type(4) {
    width: 266px;
  }
}

.mpp-compare__title {
  font-family: $optimaregular;
  color: $color-white;
  font-size: 30px;
  text-align: center;
}

.mpp-compare__label {
  color: $color-periwinkle-blue;
  font-family: $optimaregular;
  font-size: 16px;
  text-transform: capitalize;
}

.mpp-compare__image {
}

.mpp-compare__header {
  color: $color-white;
  font-family: $optimaregular;
  font-size: 22px;
  margin-top: 18px;
}

.mpp-compare__sub-header {
  color: $color-white;
  font-family: $optimaregular;
  font-size: 16px;
  @include swap_direction(margin, 4px 0 10px 0);
}

.mpp-compare__price {
  td {
    font-size: 12px;
  }
  .mpp-compare__label {
    font-size: 16px;
  }
}

td.mpp-compare__bag {
  position: relative;
  padding-bottom: 5px; // for add to bag
}

.mpp-compare {
  .selectBox-dropdown {
    width: 100%;
  }
  .mpp-compare__swatch-select-container,
  .mpp-compare__price-size-select,
  .mpp-compare__price-size,
  .mpp-compare__skintype-select-container {
    margin-bottom: 10px;
  }
  .mpp-compare__shadename,
  .product__inventory-status li {
    @include swap_direction(margin, 10px 0);
  }
  .compare_add_to_bag {
    bottom: 0;
    #{$ldirection}: 55px;
    text-align: center;
    .button--secondary {
      @include swap_direction(border, 1px solid $color-darker-gray);
    }
  }
}

// VBA section
.mpp-compare-vba {
  color: $color-white;
  @include swap_direction(padding, 80px 30px 100px 100px);
}

.mpp-compare-vba__link {
  text-decoration: underline;
  color: $color-periwinkle-blue;
  text-transform: uppercase;
  display: block;
  margin-top: 1em;
}

.mpp-compare__swatch-select-selectBox-dropdown-menu {
  .filtered-swatch {
    @include swap_direction(margin, 3px 10px 0 10px);
  }
}

// print
.mpp-compare__cart-print {
  display: none;
}

// MPP compare nav
.product-compare-nav {
  position: fixed;
  top: 290px;
  #{$ldirection}: 0;
  z-index: 10;
}

.mpp-product-compare-nav {
  li {
    @include swap_direction(padding, 15px);
    text-align: center;
    background-color: $color-navy;
    &.mpp-compare-button {
      display: none;
      a {
        font-family: $optimaregular;
        font-size: 14px;
        color: $color-white;
        display: inline-block;
        text-align: center;
      }
      .compare-counter-wrapper {
        display: block;
        clear: both;
        margin-#{$ldirection}: 20px;
        padding-bottom: 15px;
        .compare-counter {
          color: $color-white;
          display: block;
          font-size: 12px;
          text-align: center;
          font-family: $akzidenz;
          float: $ldirection;
        }
        .compare-counter-arrow {
          width: 0;
          height: 0;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          border-#{$ldirection}: 7px solid $color-white;
          display: block;
          float: $ldirection;
          margin-#{$ldirection}: 5px;
          margin-top: 4px;
        }
      }
    }
  }
}

.mpp-product-compare-tooltip {
  position: fixed;
  top: 210px;
  #{$ldirection}: 120px;
  z-index: 10;
  display: none;
  background-color: $color-white;
  @include swap_direction(border, 1px solid $color-light-gray);
  @include swap_direction(padding, 15px);
  min-height: 175px;
  &:after,
  &:before {
    #{$rdirection}: 100%;
    top: 70%;
    @include swap_direction(border, solid transparent);
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-#{$rdirection}-color: $color-white;
    border-width: 10px;
    margin-top: -10px;
  }
  &:before {
    border-color: rgba(204, 204, 204, 0);
    border-#{$rdirection}-color: $color-light-gray;
    border-width: 11px;
    margin-top: -11px;
  }
  .close-button {
    float: $rdirection;
    cursor: pointer;
    &:after {
      background-image: image-url('../themes/estee_base/img/sprites/checked.png');
      width: 13px;
      height: 13px;
      display: block;
      content: '';
      color: #000;
    }
  }
  .compare-info {
    font-family: $optimaregular;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .mpp-compare-now-button-wrapper {
    clear: both;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .mpp-compare-now-button {
    background-color: $color-navy;
    @include swap_direction(padding, 10px 30px);
    color: $color-white;
    font-family: $akzidenz;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.15em;
  }
}

.compare-checkbox-wrapper {
  margin-bottom: 7px;
  .compare-checkbox-label {
    color: #bebfc3;
    font-size: 12px;
    &:before,
    &:after {
      #{$ldirection}: 65px;
    }
  }
}
