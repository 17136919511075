///
/// @file drupal_modules/esteelauder_common/__hero_tout_fullwidth.scss
/// Styles for hero touts, without products (found on MPPs or other page headers)
///
/// full width bg image
/// text placement variations
/// 'normal-left', 'inside-left', 'normal-right', 'inside-right', 'center'
/// normal-left: 20% from left (default)
/// inside-left: 30% from left
/// normal-right: 20% from right
/// inside-right: 30% from right
/// center: centered equally

.hero-tout-fullwidth-wrapper {
  @include swap_direction(margin, -$header-collapsed-height auto 40px auto);
  @media #{$large-up} {
    margin-bottom: 80px;
  }
  @media #{$xxlarge-up} {
    max-width: 1366px;
  }
}

.hero-tout-fullwidth {
  @include swap_direction(margin, 0);
  max-width: 100%;
  position: relative;
  .text-promo {
    #{$renutriv} & {
      font-size: 16px;
      font-family: $roboto-condensed;
      text-transform: none;
    }
  }
  .hero-tout__promo {
    position: absolute;
    bottom: auto;
    #{$rdirection}: auto;
    top: 0;
    #{$ldirection}: 0;
    @include swap_direction(padding, 15% 0 0 20%);
    text-align: $ldirection;
    @media #{$xlarge-up} {
      padding-top: 20%;
    }
    &.hero-tout__promo--inside-left {
      padding-#{$ldirection}: 30%;
    }
    &.hero-tout__promo--normal-right {
      padding-#{$rdirection}: 20%;
      padding-#{$ldirection}: 0;
      #{$rdirection}: 0;
      #{$ldirection}: auto;
    }
    &.hero-tout__promo--inside-right {
      padding-#{$rdirection}: 30%;
      padding-#{$ldirection}: 0;
      #{$rdirection}: 0;
      #{$ldirection}: auto;
    }
    &.hero-tout__promo--center {
      padding-#{$rdirection}: 0;
      padding-#{$ldirection}: 0;
      text-align: center;
      @include swap_direction(margin, 0 auto);
      #{$rdirection}: 0;
      #{$ldirection}: 0;
    }
  }
  .headline--large {
    margin-bottom: 28px;
  }
  .hero-tout__image--fullwidth {
    width: 100%;
  }
  .cta-button-wrapper {
    margin-top: 28px;
    .cta {
      #{$renutriv} & {
        @include renutriv-copy;
        font-size: 14px;
      }
    }
  }
}

// custom for 2014 fall gwp
.mpp-header-2014-fall-gwp-elc-nodeblock {
  .hero-tout-fullwidth {
    .hero-tout__promo {
      @include swap_direction(padding, 16% 0 0 13%);
      @media #{$xlarge-up} {
        @include swap_direction(padding, 16% 0 0 13%);
        .hero-tout__copy {
          margin-#{$ldirection}: 25%;
        }
        .promo-eyebrow-text {
          top: 42%;
        }
      }
    }
  }
}
