.foundation__sampling {
  &__pop-up {
    text-align: center;
    &__data {
      @media #{$medium-up} {
        display: table;
        border-spacing: 15px;
        border-collapse: separate;
      }
    }
    &__header {
      @media #{$medium-up} {
        margin-bottom: 5px;
        font-size: 40px;
      }
      font-size: 28px;
      font-family: $optimalight;
      margin-bottom: 20px;
    }
    &__content {
      @media #{$medium-up} {
        @include swap_direction(margin, 0 1% 0 0);
        display: table-cell;
        width: 25%;
        vertical-align: middle;
        min-height: 98px;
        padding: 22px;
        &:last-child {
          margin-#{$rdirection}: 0;
        }
      }
      display: block;
      padding: 13px;
      background-color: $color-dw-cool;
      margin-bottom: 20px;
      &--neutral {
        background-color: $color-dw-neutral;
      }
      &--warm {
        background-color: $color-dw-warm;
      }
      h3 {
        font-size: 14px;
        font-family: $akzidenz;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 5px;
        @media #{$medium-up} {
          font-size: 12px;
          margin-bottom: 3px;
          letter-spacing: 0.1em;
        }
      }
      p {
        font-size: 18px;
        font-family: $akzidenz;
        margin: 0;
        @media #{$medium-up} {
          font-size: 15px;
        }
      }
      &__wrapper {
        @media #{$medium-up} {
          display: inline-block;
          vertical-align: middle;
        }
        &--warm {
          @media #{$medium-up} {
            margin: 0 20px;
          }
        }
      }
    }
  }
  &-overlay {
    border: 1px solid $color-dw-grey;
    #cboxClose {
      background-position: 0 -973px;
    }
  }
}

.device-mobile {
  #colorbox {
    &.foundation__sampling-overlay {
      top: 180px !important;
      height: 521px !important;
    }
  }
}
