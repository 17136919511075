///
/// @file drupal_modules/esteelauder_common/_chat_parallax.scss
/// Styles for parallax effect chat page break component
///
/// \brief Styling for template at:
///   esteelauder/modules/custom/esteelauder_common/templates/chat_parallax_v1
///

.parallax__container {
  @include swap_direction(margin, 0 auto 100px auto);
  max-width: 1920px;
  min-height: 515px;
  height: 515px;
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
  background-position: 50% 50%; // js overrides
  background-attachment: fixed;
  @media #{$medium-down} {
    margin-bottom: 78px;
    max-width: 768px;
    min-height: 350px;
    height: 350px;
  }
  @media #{$large-down} {
    background-attachment: scroll;
    background-size: cover;
  }
  @media #{$large-only} {
    background-attachment: scroll;
    background-size: cover;
  }
  .chat-parallax__content {
    @include swap_direction(margin, 0 auto);
    position: relative;
    text-align: center;
    max-width: 1024px;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    top: 50%;
    transform: translate(0%, -50%);
    @media #{$medium-down} {
      padding-top: 15px;
      max-width: 768px;
      transform: translate(0%, -50%);
    }
    &.color--light {
      color: $color-white;
      .chat-parallax__small-text a {
        color: $color-white;
      }
    }
    &.color--dark {
      color: $color-navy;
      .chat-parallax__small-text a {
        color: $color-navy;
      }
      &#{$renutriv} {
        color: $color-black;
      }
    }
    .eyebrow {
      font-weight: bold;
      @media #{$medium-down} {
        line-height: 12px;
        font-size: 12px;
      }
    }
    .chat_button--transparent_blue {
      @media #{$medium-down} {
        margin: 0px 25px;
        border: 1px solid $color-navy;
      }
      a {
        color: $color-navy;
        background: none;
        @media #{$medium-up} {
          border: 1px solid $color-navy;
        }
      }
    }
    .chat_button--dark {
      @media #{$medium-down} {
        margin: 0px 25px;
        background: $color-navy;
      }
      a {
        color: $color-white;
        background: $color-navy;
      }
    }
    .chat_button--transparent_white {
      @media #{$medium-down} {
        margin: 0px 25px;
        border: 1px solid $color-white;
      }
      a {
        color: $color-white;
        background: none;
        @media #{$medium-up} {
          border: 1px solid $color-white;
        }
      }
    }
    .chat-parallax__headline {
      font-size: 70px;
      letter-spacing: -0.02em;
      &.headline-font {
        @include headline--secondary;
      }
      @include swap_direction(margin, 15px auto 30px auto);
      #{$renutriv} & {
        font-size: 40px;
      }
      @media #{$medium-down} {
        @include swap_direction(margin, 10px auto 15px auto);
        font-size: 55px;
        line-height: 0.9em;
      }
    }
    .chat-parallax__subtitle {
      padding-bottom: 30px;
      margin: 0 auto;
      width: 36%;
      @media #{$medium-down} {
        width: 75%;
        padding-bottom: 10px;
        margin: 0 40px;
      }
    }
    .chat-parallax__small-text {
      padding-top: 30px;
      p {
        margin: 0;
        padding: 0;
        font-size: 12px;
        letter-spacing: 0.15em;
        line-height: 18px;
      }
      a {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
