@media screen and (max-width: 767px) {
  .el_boutique_wrapper {
    div.hideModules {
      opacity: 0;
      padding-top: 5%;
    }
    .shop_now_light.product-brief__button-quickshop--close {
      background-position: 100% 60%;
    }
    .qs__append-section {
      position: relative;
      width: 100%;
      text-align: center;
      margin: 18.1% auto 0 auto;
      &:empty {
        display: none;
      }
    }
  }
  .belle_formatter {
    .mpp__product-sort-container {
      display: none;
    }
    .mpp_qs_detached {
      &.mpp {
        display: none;
      }
      .quickshop-inline-top {
        left: 48.4%;
      }
      .quickshop-inline {
        margin-bottom: 0;
      }
    }
  }
  #belle_mod1 {
    position: relative;
    .boutique_vid_play_cta {
      position: absolute;
      font-size: 3.4375vw;
      bottom: 3.1%;
      left: 2.8%;
    }
    .boutique_vid_play_img {
      position: absolute;
      width: 21.563vw;
      bottom: 8%;
      left: 5.4%;
    }
    .boutique__video_close_x_inPlace {
      top: 9.5%;
    }
  }
  #belle_mod2 {
    position: relative;
    .belle_mod2__copy1 {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 3.65%;
      h2.belle__parallax-block {
        font-family: 'Futura Now Medium';
        font-size: 5.9375vw;
        letter-spacing: 0.25em;
      }
      .belle_mod2__logo {
        width: 81.25%;
        margin-top: 6%;
      }
      .el_boutique_body.belle__parallax-block {
        font-size: 3.4375vw;
        margin-top: 5.1%;
        letter-spacing: 0.14em;
      }
    }
    .belle_mod2__img-left {
      height: 144.0625vw;
      overflow: hidden;
      img {
        margin-top: 0;
      }
    }
    .belle_mod2__copy2 {
      width: 100%;
      text-align: center;
      .belle_mod2__desc {
        font-size: 3.4375vw;
        margin-top: 3.6%;
        letter-spacing: 0.14em;
        line-height: 148%;
      }
      .belle_mod2__price {
        font-size: 3.4375vw;
        margin-top: 3.1%;
        letter-spacing: 0.14em;
        line-height: 148%;
      }
    }
    .qs__append-section {
      margin: 18.1% auto 0 auto;
      &:empty {
        display: none;
      }
    }
  }
  #belle_mod3 {
    .belle_mod3__header {
      font-size: 9.375vw;
      padding-top: 11.5%;
    }
    .belle_mod3__subheader {
      font-size: 3.4375vw;
      padding: 0.65% 0 4.8% 0;
    }
    .belle_mod3__tile {
      width: calc(100% / 2);
      .belle_mod3__tile-copy {
        font-size: 8.59375vw;
      }
      .boutique_vid_play_img {
        width: 43.44%;
        transform: translate(65%, -50%);
        margin: 0 auto;
        text-align: center;
        left: 0;
        bottom: 0;
        top: 50%;
      }
      .boutique__video_videoContainer {
        height: 90%;
        margin-top: 10%;
      }
      .boutique__video_close_x {
        width: 7.032%;
        right: 3%;
      }
    }
  }
  #belle_mod4 {
    .belle_mod4__header {
      font-size: 9.375vw;
      padding: 11.1% 0 5% 0;
      letter-spacing: -0.02em;
      line-height: 99%;
    }
    .belle_mod4__slide {
      width: 84.375%;
      .boutique_vid_play_img {
        margin: 0 auto;
        text-align: center;
        left: 37.4%;
        bottom: 0;
        top: 22%;
        width: 25.556%;
      }
      .boutique_vid_play_cta {
        font-size: 3.4375vw;
        text-align: center;
        bottom: 22.1%;
        left: 34.1%;
      }
    }
    .boutique__video_close_x {
      width: 7.032%;
      right: 3%;
    }
    .basic_infinite_carouselnav.basic_infinite_carouselnav_1 {
      left: 0;
      margin-left: 0;
    }
    .basic_infinite_carouselnav.basic_infinite_carouselnav_2 {
      right: 0;
      margin-right: 0;
    }
    .basic_infinite_carouselnav {
      width: 7.8%;
      top: -4%;
    }
    .basic_infinite_carouselnav_btn {
      width: 50%;
    }
    .basic_infinite_carousel_pagination {
      margin-top: 3.15%;
    }
    .basic_infinite_carousel_pagination_dot {
      width: 10px;
      height: 10px;
      margin: 0 8px 0 10px;
    }
    .basic_infinite_carouselOuter:not(.basic_infinite_carousel_header) {
      padding-bottom: 0;
    }
    .basic_infinite_carouselOuter:not(.basic_infinite_carousel_header) {
      .mob_pag_1 .basic_infinite_carousel_pagination {
        padding-bottom: 0;
      }
    }
  }
  #belle_mod5 {
    margin-top: 10.9%;
    .belle__quiz-header {
      position: absolute;
      font-family: 'OptimaDisplayLight';
      width: 100%;
      text-align: center;
      padding-top: 3.75%;
      font-size: 9.375vw;
      padding: 6.4% 0 0 0;
      letter-spacing: -0.02em;
      line-height: 99%;
    }
    .belle__quiz-subheader {
      position: absolute;
      width: 100%;
      text-align: center;
      margin-top: 28.9%;
      font-size: 4.375vw;
    }
    .belle__quiz-section {
      position: relative;
      .belle__quiz-qs {
        position: absolute;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        .belle__quiz-btnNext {
          display: block;
          width: 100%;
          max-width: 84.375%;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          height: 14.0635vw;
          line-height: 14.0635vw;
          text-transform: uppercase;
          font-size: 3.75vw;
          margin: 0 auto;
          &.belle__quiz-btnInactive {
            background-color: #a4a4ab;
            border: 1px solid #fff;
            pointer-events: none;
          }
        }
        .belle__quiz-questionHeader {
          font-size: 4.375vw;
        }
        .belle__quiz-question {
          font-family: 'OptimaDisplayLight';
          width: 100%;
          text-align: center;
          line-height: 100%;
          padding: 2.9% 0 4.7% 0;
          letter-spacing: -0.01em;
          font-size: 9.375vw;
        }
        .belle__quiz-q:not(.belle__quiz-q-1) {
          .belle__quiz-option {
            display: block;
            font-family: 'OptimaDisplayLight';
            font-size: 5.9375vw;
            font-weight: normal;
            letter-spacing: -0.01em;
            text-transform: inherit;
            text-align: center;
            height: 14.0635vw;
            line-height: 14.0635vw;
            width: 100%;
            margin: 0 auto 4.55% auto;
            max-width: 84.375%;
            padding: 0;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
        }
        .belle__quiz-q-1 {
          .belle__quiz-option {
            display: block;
            width: 100%;
            margin: 0 auto 4.55% auto;
            max-width: 84.375%;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            height: 19.375vw;
            padding: 2.4% 0;
            text-transform: inherit;
            .belle__quiz-optionsLg {
              font-family: 'OptimaDisplayLight';
              font-size: 5.9375vw;
              text-align: center;
              letter-spacing: -0.01em;
              text-transform: inherit;
              font-weight: normal;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              line-height: 145%;
              display: block;
            }
            .belle__quiz-optionsSm {
              font-family: 'AkzidenzGrotesk W1G';
              font-size: 4.375vw;
              text-transform: inherit;
              font-weight: normal;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              line-height: 100%;
              display: block;
              letter-spacing: 0em;
            }
          }
        }
        .belle__quiz-q-1,
        .belle__quiz-q-2,
        .belle__quiz-q-3,
        .belle__quiz-q-4 {
          margin-top: 39.7%;
          position: absolute;
          width: 100%;
        }
        .belle__quiz-q-2,
        .belle__quiz-q-3,
        .belle__quiz-q-4 {
          display: none;
        }
        .belle__quiz-q-2 {
          .belle__quiz-question {
            padding: 2.9% 0 12% 0;
          }
          .belle__quiz-btnNext {
            margin: 13.2% auto 0 auto;
          }
        }
        .belle__quiz-q-3 {
          .belle__quiz-question {
            padding: 2.9% 0 12% 0;
          }
          .belle__quiz-btnNext {
            margin: 13.2% auto 0 auto;
          }
        }
        .belle__quiz-q-4 {
          .belle__quiz-question {
            padding: 12.3% 0 16.9% 0;
          }
          .belle__quiz-btnNext {
            padding: 0 1.4%;
            margin: 18% auto 0 auto;
          }
        }
      }
    }
    .belle__quiz-ans {
      height: 174.5vw;
      position: absolute;
      width: 100%;
      margin: 0 auto;
      .belle__quiz-ansImg {
        width: 84.375%;
        position: absolute;
        top: 3.6%;
        margin: 0 7.8%;
      }
      .belle__ans-header {
        position: absolute;
        font-family: 'OptimaDisplayLight';
        text-align: center;
        z-index: 1;
        font-size: 9.375vw;
        padding: 0;
        letter-spacing: -0.02em;
        line-height: 100%;
        top: 53%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      }
      .belle__quiz-ansSection-overlay {
        display: none;
        position: absolute;
        z-index: 5;
        background-color: #fff;
        margin: 0 7.8% 0 7.8%;
        text-align: center;
        width: 84.375%;
        height: 92.8%;
        top: 3.6%;
        a {
          font-weight: bold;
          text-decoration: underline;
          text-transform: uppercase;
          font-size: 3.4375vw;
          letter-spacing: 0.05em;
          cursor: pointer;
        }
        .belle__quiz-overlayClose {
          position: absolute;
          width: 9.26%;
          right: 3.5%;
          top: 1.8%;
          opacity: 0.3;
          cursor: pointer;
        }
      }
      .belle__quiz-ansSection {
        line-height: 115%;
        font-size: 4.375vw;
      }
      .belle__quiz-ans-1 {
        display: none;
        .belle__quiz-ansSection1_1 {
          margin-bottom: 4%;
          font-size: 4.375vw;
        }
        .belle__quiz-ansSection-overlay {
          .belle__quiz-ansSection1_2 {
            margin-bottom: 6.4%;
            margin-top: 24.9%;
          }
          .belle__quiz-ansSection1_3 {
            margin-bottom: 5.7%;
          }
          .belle__quiz-ansSection1_4 {
            margin-bottom: 6%;
          }
          .belle__quiz-ansSection1_5 {
            margin-bottom: 5.3%;
          }
          .belle__quiz-ansSection1_6 {
            margin-bottom: 5.9%;
          }
          .belle__quiz-ansSection1_7 {
            margin-bottom: 6%;
          }
        }
      }
      .belle__quiz-ans-2 {
        display: none;
        .belle__quiz-ansCopy {
          top: 61.95%;
        }
        .belle__quiz-ansSection2_1 {
          margin-bottom: 4.2%;
        }
        .belle__quiz-ansSection2_2 {
          margin-top: 35.6%;
        }
        .belle__quiz-ansSection2_3 {
          margin-top: 6.4%;
        }
        .belle__quiz-ansSection2_4 {
          margin-top: 5.7%;
        }
        .belle__quiz-ansSection2_5 {
          margin-top: 5.7%;
        }
        .belle__quiz-ansSection2_6 {
          margin-top: 5.6%;
        }
        .belle__quiz-ansSection2_7 {
          margin-top: 6%;
        }
      }
      .belle__quiz-ans-3 {
        display: none;
        .belle__quiz-ansSection3_1 {
          margin-bottom: 4%;
        }
        .belle__quiz-ansSection3_2 {
          margin-top: 45.5%;
        }
        .belle__quiz-ansSection3_3 {
          margin-top: 4.2%;
        }
        .belle__quiz-ansSection3_4 {
          margin-top: 5.8%;
        }
        .belle__quiz-ansSection3_5 {
          margin-top: 5.7%;
        }
        .belle__quiz-ansSection3_6 {
          margin-top: 5.6%;
        }
        .belle__quiz-ansSection3_7 {
          margin-top: 6.1%;
        }
      }
      .belle__quiz-restartBtn {
        margin: 0 auto;
        text-align: center;
        position: absolute;
        width: 100%;
        bottom: 4.4%;
        .belle__quiz-restart {
          padding: 0 2.5%;
          display: block;
          width: 100%;
          max-width: 84.375%;
          height: 14.0635vw;
          line-height: 14.0635vw;
          text-transform: uppercase;
          font-size: 3.75vw;
          margin: 0 auto;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
      .belle__quiz-ansCopy {
        position: absolute;
        left: 0;
        top: 62.5%;
        width: 100%;
        text-align: center;
        .belle__quiz-ansSection {
          line-height: 115%;
        }
        .belle__quiz-openOverlay {
          font-weight: bold;
          text-decoration: underline;
          text-transform: uppercase;
          font-size: 3.4375vw;
          letter-spacing: 0.04em;
          span {
            cursor: pointer;
          }
        }
      }
    }
    .inactive {
      pointer-events: none;
    }
    /* RTL Supported */
  }
  #belle_mod6 {
    .belle_mod6__esStories-carousel {
      margin-top: 12.1%;
    }
    .basic_infinite_carouselnav {
      width: 7.8%;
      top: -14%;
      .basic_infinite_carouselnav_btn {
        width: 50%;
      }
    }
    .basic_infinite_carouselnav.basic_infinite_carouselnav_1 {
      left: 0;
      margin-left: 0;
    }
    .basic_infinite_carouselnav.basic_infinite_carouselnav_2 {
      right: 0;
      margin-right: 0;
    }
    .basic_infinite_carousel_pagination {
      margin-top: 6%;
      padding-bottom: 0;
      .basic_infinite_carousel_pagination_dot {
        width: 10px;
        height: 10px;
        margin: 0 8px 0 10px;
      }
    }
    .belle_mod6__slide {
      width: 84.375%;
      .belle_mod6__slideLogo {
        margin-bottom: -11.7%;
        width: 83%;
        margin-left: -3%;
      }
      .belle_mod6__slide-copy {
        letter-spacing: 0.14em;
        font-size: 3.75vw;
        margin-top: 4.2%;
        line-height: 150%;
      }
      .belle_mod6__slide-link {
        height: 14.0625vw;
        background-color: #0c0f20;
        line-height: 14.0625vw;
        font-size: 3.75vw;
        letter-spacing: 0.12em;
        margin-top: 5.9%;
        width: 100%;
        cursor: pointer;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
}
