.promotions {
  .promo-unit {
  }
  .promo-unit__headline {
    @include headline--tertiary--plain;
    border-bottom: 0;
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0 0 -1px 0);
    line-height: 46px;
    @media #{$medium-up} {
      line-height: 49px;
      @include swap_direction(margin, 0 0 4px 0);
    }
  }
  .promo-unit__subheader {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.7px;
    margin-bottom: 12px;
    @media #{$medium-up} {
      margin-bottom: 18px;
    }
  }
  .promo-unit__copy {
    margin-bottom: 14px;
  }
  .promo-unit__link {
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: underline;
    font-size: 12px;
    letter-spacing: 1.6px;
  }
  .nodeblock-signup-form {
    @include swap_direction(padding, 35px 8.411215%);
    ul {
      @include text--tiny--plain;
      margin-bottom: 21px;
      @media #{$medium-up} {
        margin-bottom: 16px;
        font-size: 13px;
        letter-spacing: 0.1em;
      }
    }
    li {
      margin-bottom: 8px;
      @media #{$medium-up} {
        margin-bottom: 11px;
      }
    }
    input[type='text'],
    .email-signup,
    .sms-signup__terms {
      margin-bottom: 10px;
      @media #{$medium-up} {
        margin-bottom: 12px;
      }
    }
    .email-signup__message {
      @media #{$medium-up} {
        padding-top: 0;
      }
    }
    .sms-signup {
      margin-bottom: 5px;
      input[type='text'] {
        margin-bottom: 17px;
        @media #{$medium-up} {
          margin-bottom: 12px;
        }
      }
      input[type='submit'] {
        @media #{$medium-up} {
          margin-bottom: 8px;
        }
      }
    }
    .sms-signup__terms {
      padding-top: 0;
      @media #{$medium-up} {
        margin-bottom: 0;
      }
      label {
        line-height: 22px;
        padding-#{$rdirection}: 15px;
        @media #{$medium-up} {
          font-size: 13px;
          letter-spacing: 0.1em;
        }
      }
    }
  }
  @media #{$medium-up} {
    .formatter-123__row-3 {
      .promo-unit {
        @include swap_direction(padding, 18px 23px);
      }
      .promo-unit__headline {
        font-size: 40px;
        line-height: 43px;
        margin-bottom: 9px;
      }
      .promo-unit__subheader {
        margin-bottom: 12px;
      }
    }
  }
}

$checkbox-width-gwp: 29px;

.gwp-cms-content-container {
  input[type="checkbox"]
  // override for label styling
  ~ label, ~ .label {
    padding-#{$ldirection}: 29px;
    // checkbox aspect
    @media #{$medium-up} {
      padding-#{$ldirection}: $checkbox-width-gwp + $checkbox-label-offset; //15px - 9px
      padding-top: 3px;
    }
    &::before {
      top: 3px;
      width: $checkbox-width-gwp;
      height: $checkbox-width-gwp;
      @include swap_direction(border, 1px solid $color-light-gray);
      #{$alt} & {
        @include swap_direction(border, 1px solid $color-medium-gray);
        background: $color-navy;
      }
    }
    // checked mark aspect
    &::after {
      background-image: url('/media/images/background-images/background-image16.png');
      width: $checkbox-width-gwp;
      height: $checkbox-width-gwp;
      top: 3px;
      color: $color-black;
      @include swap_direction(border, solid 1px $color-navy);
      #{$alt} & {
        background-image: url('/media/images/background-images/background-image16.png');
        @include swap_direction(border, solid 1px $color-medium-gray);
      }
    }
  }
}

#{$ie8} .gwp-cms-content-container {
  input[type='checkbox'] {
    position: relative !important;
    #{$ldirection}: 0 !important;
  }
}
