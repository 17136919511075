/* Ipsy Gen Sweepstakes UI */
.ipsy-gen-sweepstakes {
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
  @media #{$medium-up} {
    padding: 0 0 100px;
    position: relative;
  }
  @media #{$large-up} {
    padding: 0;
  }
  & &__background-image {
    display: none;
    @media #{$medium-up} {
      display: block;
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
  &__content {
    font-family: $ffmarkweb;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    @media #{$medium-up} {
      position: absolute;
      top: 170px;
      left: 160px;
    }
    @media #{$large-up} {
      top: 209px;
      left: 243px;
      font-size: 14px;
    }
    a {
      color: $color-black;
      font-family: $ffmarkweb-medium;
      text-decoration: underline;
      outline: none;
    }
    .ipsy-gen-sweepstakes {
      &__header {
        width: 280px;
        margin: 0 auto;
        @media #{$medium-up} {
          width: 300px;
          margin: 0;
        }
        @media #{$large-up} {
          width: auto;
        }
        img {
          width: 100%;
          @media #{$large-up} {
            width: auto;
          }
        }
      }
      &__subtitle {
        display: block;
        margin: 25px auto 0;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.0239em;
        @media #{$medium-up} {
          display: inline-block;
          margin: 25px 0 0 55px;
          text-align: left;
          line-height: 24px;
        }
        @media #{$large-up} {
          margin-left: 98px;
        }
      }
      &__subscribe-title {
        margin-top: 18px;
        text-align: center;
        letter-spacing: 0.095em;
        @media #{$medium-up} {
          margin-left: 57px;
          text-align: left;
        }
        @media #{$large-up} {
          margin-left: 100px;
        }
      }
      &__signup-wrapper {
        margin-top: 20px;
        @media #{$medium-up} {
          margin: 33px 0 0 59px;
        }
        @media #{$large-up} {
          margin-left: 102px;
        }
      }
      &__signup-error {
        width: 85%;
        font-size: 14px;
        font-weight: normal;
        text-transform: none;
        margin-bottom: 5px;
      }
      &__email {
        width: 100%;
        height: 40px;
        margin: 0 9px 10px 0;
        box-shadow: none;
        border-color: $color-black;
        @media #{$medium-up} {
          width: 275px;
          margin-bottom: 0;
        }
        @media #{$large-up} {
          width: 323px;
        }
        @include input-placeholder {
          color: $color-black;
        }
      }
      &__button {
        width: 100%;
        background-color: $color-blue--estee_edit;
        @media #{$medium-up} {
          width: auto;
        }
      }
      &__subscribe-label {
        font-size: 11px;
        font-family: $ffmarkweb;
        margin-top: 20px;
        padding: 0 0 0 25px;
        line-height: 24px;
        letter-spacing: 0.045em;
        @media #{$medium-up} {
          width: 440px;
          margin: 35px 0 0 60px;
        }
        @media #{$large-up} {
          width: 500px;
          font-size: 13px;
          margin-left: 102px;
        }
        a {
          letter-spacing: 0.05em;
        }
      }
      &__signup-link {
        margin-top: 23px;
        text-align: center;
        letter-spacing: 0.11em;
        @media #{$medium-up} {
          margin-left: 85px;
          text-align: left;
        }
        @media #{$large-up} {
          margin-left: 127px;
        }
      }
    }
  }
}
/* Ipsy Gen Popup style */
.ipsy-gen-email-popup {
  #cboxContent {
    background-color: transparent;
    padding-top: 40%;
  }
  .ipsy-gen-sweepstakes__thanks-text {
    color: $color-white;
    font-family: $ffmarkweb;
    font-size: 20px;
    text-align: center;
    span {
      font-size: 24px;
      text-transform: uppercase;
    }
    @media #{$small-only} {
      color: $color-black;
    }
  }
}
/* Stage Coach Sweepstakes style */
.stage_coach_container {
  .stage_coach_section {
    width: 50%;
    @include swap_direction(margin, 10px auto 20px);
    font-weight: bold;
    text-transform: uppercase;
    @media #{$small-only} {
      width: 90%;
    }
    @media #{$medium-only} {
      width: 70%;
    }
    @media #{$large-only} {
      width: 70%;
    }
    #email-signup__form {
      margin-top: 10px;
      @include swap_direction(margin, 0 0 10px 0);
      input.error {
        border: 1px solid $color-red;
      }
    }
    .form_section_container {
      .form_left_section {
        width: 51%;
        float: $ldirection;
        @include swap_direction(margin, 0 0 10px 0);
        @media #{$small-only} {
          width: 100%;
          @include swap_direction(margin, 0 0 20px 0);
        }
      }
      .form_right_section {
        width: 45%;
        float: $rdirection;
        @include swap_direction(margin, 0 0 10px 0);
        @media #{$small-only} {
          width: 100%;
          @include swap_direction(margin, 0 0 20px 0);
        }
        .selectBox-label {
          font-weight: normal;
        }
        a.selectBox {
          width: 100%;
        }
      }
      .form_address_field {
        width: 100%;
        clear: both;
        label {
          display: block;
          font-weight: normal;
        }
      }
      .form_address_field {
        input {
          width: 100%;
          color: $color-black;
          border: 1px solid $color-light-gray;
          box-shadow: none;
          @include swap_direction(margin, 0 0 10px 0);
          @media #{$small-only} {
            width: 100%;
            @include swap_direction(margin, 0 0 20px 0);
          }
        }
      }
      .form_left_section,
      .form_right_section {
        input,
        select {
          width: 100%;
          color: $color-black;
          border: 1px solid $color-light-gray;
          box-shadow: none;
          @media #{$small-only} {
            width: 100%;
          }
        }
        label,
        select {
          font-weight: normal;
          display: block;
        }
      }
      .form-submit {
        @include swap_direction(margin, 20px 0 0 0);
        @media #{$small-only} {
          width: 100%;
        }
      }
    }
    .form_section_subscribe {
      @include swap_direction(margin, 20px 0);
      width: 85%;
      @media #{$small-only} {
        width: 100%;
      }
      label {
        font-size: 14px;
      }
    }
    .ipsy-gen-sweepstakes__signup-link {
      text-decoration: underline;
      font-size: 14px;
    }
    .font_bold {
      font-weight: bold;
    }
    .form_section_subscribe {
      label {
        font-weight: normal;
        text-transform: none;
        letter-spacing: 1px;
      }
    }
    div.error {
      font-size: 14px;
      text-transform: none;
      font-weight: normal;
      @include swap_direction(margin, 0 0 10px 0);
    }
  }
}

.stage_coach_btextarea {
  .age_factor {
    font-size: 14px;
    display: block;
    @include swap_direction(margin, 0 auto 60px auto);
    width: 50%;
  }
}

@media #{$large-down} {
  .stage_coach_btextarea {
    .age_factor {
      width: 70%;
    }
  }
}

@media #{$small-only} {
  .stage_coach_btextarea {
    .age_factor {
      @include swap_direction(margin-top, -5px);
      width: 90%;
    }
  }
}
