$mobile-max: 768px;

%desire-shades-carousel-button {
  position: absolute;
  background: rgba(211, 211, 211, 0.35);
  bottom: 41%;
  border: none;
  width: 6.83vw;
  height: 14.928vw;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  transition: opacity 500ms;
  &.disabled {
    cursor: default;
  }
  &.visible {
    opacity: 1;
  }
  img {
    width: 70%;
    opacity: 0.4;
  }
  @media #{$medium-up} {
    bottom: 50%;
    background: none;
    width: 1.83vw;
    height: 2.928vw;
    opacity: 1;
    img {
      width: 100%;
      opacity: 1;
    }
  }
  @media #{$xxlarge-up} {
    width: 25px;
    height: 40px;
  }
}

.desire-shades-carousel {
  @media #{$medium-up} {
    padding-bottom: 3.2%;
  }
  .carousel__header {
    h2 {
      position: absolute;
      top: 23.8vw;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      text-transform: uppercase;
      text-align: center;
      font-size: 9vw;
      padding: 0 8vw 0 7vw;
      letter-spacing: 0.005em;
      z-index: 2;
      @media #{$medium-up} {
        top: 23.8%;
        font-size: 3.95vw;
        padding: 0;
      }
      @media #{$xxlarge-up} {
        font-size: 54px;
      }
    }
    .el_boutique_img_wrapper img {
      margin-#{$ldirection}: 100%;
    }
  }
  .carousel__body {
    position: relative;
    overflow: hidden;
    margin-top: -17.6vw;
    @media #{$medium-up} {
      margin-top: -28.9%;
    }
    @media #{$xxlarge-up} {
      margin-top: -371px;
    }
    &.hideModules {
      padding-top: 7%;
      opacity: 0;
    }
  }
  .carousel__track {
    position: relative;
  }
  .carousel__previous {
    @extend %desire-shades-carousel-button;
    #{$ldirection}: 0;
    @media screen and (max-width: $mobile-max) {
      display: none;
    }
    @media #{$medium-up} {
      #{$ldirection}: 1%;
    }
  }
  .carousel__next {
    @extend %desire-shades-carousel-button;
    #{$rdirection}: 0;
    @media screen and (max-width: $mobile-max) {
      display: none;
    }
    @media #{$medium-up} {
      #{$rdirection}: 1%;
    }
  }
  .desire-shades-carousel__slide {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    max-width: $max-width;
    min-width: 688px;
    float: left;
  }
  .desire-shades-carousel__shade {
    position: relative;
    width: 33.333%;
    opacity: 0;
    will-change: opacity;
    &.visible {
      opacity: 1;
      transition: opacity 500ms ease-out;
    }
    &:hover {
      .shade__image img {
        transform: scale(1.1, 1.1);
      }
    }
    a.quick_shop.button--secondary {
      height: 100%;
      width: 100%;
      padding: 0;
      margin: 0;
      border: 0;
      .shade__image {
        width: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: transform 650ms ease-out;
        }
      }
      .shade__title {
        font-family: $akzidenz;
        color: $color-white;
        font-weight: bold;
        font-size: 3.6vw;
        letter-spacing: 2px;
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: $mobile-max) {
          font-weight: 700;
        }
        @media #{$medium-up} {
          font-size: 1.2vw;
        }
        @media #{$xxlarge-up} {
          font-size: 17px;
          letter-spacing: 3px;
        }
      }
    }
  }
  @media screen and (max-width: $mobile-max) {
    .mpp.mpp_qs_detached {
      .mpp__header,
      .mpp__product-sort-container {
        display: none;
      }
      .mpp__product-grid {
        background-color: $color-white;
      }
      .quickshop-inline-top {
        left: 47%;
      }
      .quickshop-inline {
        margin-bottom: 0;
      }
    }
    .desire-mod4__qs {
      & + .product-brief a .product-brief__image,
      & + .product-brief a h3.product-brief__header,
      & + .product-brief .product-brief__misc-flag,
      & + .product-brief a h4.product-brief__sub-header,
      & + .product-brief div.product-brief__price,
      & + .product-brief div.product-brief__reviews,
      & + .product-brief .product-brief__button-quickshop {
        display: none;
      }
      & + .product-brief .product-brief__button-quickshop,
      & + .product-brief .product-brief__button-quickshop--close {
        position: relative;
        margin-top: 5vw;
        margin-left: -3.28125vw;
      }
      & + .product-brief .shop_now_dark {
        border: 1px solid $color-white;
      }
      & + .product-brief {
        height: auto !important;
        float: none;
        width: 100%;
        padding-bottom: 0;
      }
    }
  }
}
