///
/// Checkout pages styling
///

//colors
$checkout-border-header: $color-light-gray;

// Local variables
$checkout-spacing: 20px; // common shared margin/paddings
$cart-spacing: 10px;
$checkout-pushdown: 35px; // used to nudge content down from top
$viewcart-pushdown: 35px; // used to nudge content down from top
$cart-container-width: 1024px; // comps
$checkout-sidebar-width: percentage(356px / $cart-container-width);
$cart-sidebar-width: percentage(256px / $cart-container-width); // this will override
$cart-sidebar-width: $checkout-sidebar-width; //same as onepage
$cart-line: 1px solid $color-light-gray; // line used everywhere

/// Cart item values
// product
$product-width-mobile: 25%;
$product-width-desktop: 25%;
// description
$desc-width-mobile: 60%;
$desc-width-desktop: 35%;
// price
$price-width: 15%;
// replenishment
// $replenishment-width-desktop: 20%;
// qty
$qty-width: 15%;
// total
$total-width-desktop: 10%;

%loyalty-clear {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

%loyalty-panel {
  @extend %loyalty-clear;
  display: block;
  width: 100%;
  @include swap_direction(padding, 10px $mobile-outer-gutter);
  @media #{$medium-up} {
    @include swap_direction(padding, 10px 10px 30px 10px);
  }
}

// Common styles
@import 'loyalty-shared';
@import 'loyalty-about';
@import 'loyalty-benefits';
@import 'loyalty-how-to-earn';
@import 'loyalty-join';
@import 'loyalty-leave';
@import 'loyalty-next-tier';
@import 'loyalty-notification';
@import 'loyalty-offers';
@import 'loyalty-offers-sku';
@import 'loyalty-points';
@import 'loyalty-transactions';
@import 'loyalty-tout';
@import 'loyalty-receipt';
@import 'loyalty-faq';

@import 'redesign/loyalty_account';
@import 'redesign/loyalty_global';
@import 'redesign/loyalty_sigin';
