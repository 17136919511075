///
/// @file components/input_types/_home_up_next.scss
///
/// \brief Styling for up next component that shows on front page
///

$upnext-size: 174px;

.up-next {
  position: fixed;
  z-index: 50;
  #{$rdirection}: 0;
  bottom: $sticky-footer-height; // _variables.scss
  width: $upnext-size; // styleguide pg 79
  height: $upnext-size; // styleguide pg 79

  // Hide .up-next for portrait
  @media #{$portrait} {
    display: none;
  }
}

.up-next__label {
  @include print-size-tracking(16px);
  font-family: $optimalight;
  display: block;
  position: absolute;
  top: 12px;
  #{$ldirection}: 15px;
  z-index: 10;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: $color-white;
  &.light {
    color: $color-white;
  }
  &.dark {
    color: $color-navy;
  }
}

.up-next__previous-label {
  display: block;
  position: absolute;
  bottom: 105%;
  #{$rdirection}: 10px;
  padding-top: 30px;
  cursor: pointer;
}

.up-next__previous-arrow {
  background-size: auto auto;
  background-position: 0 -118px;
  height: 13px;
  width: 23px;
  display: block;
  position: absolute;
  #{$ldirection}: 50%;
  top: 0;
  margin-#{$ldirection}: -(sprites-sprite-width('arrow-up') / 2);
}

.up-next-wrapper {
  @include transparent;
  @include single-transition(opacity, 0.5s, ease-in-out);
  visibility: hidden;
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  z-index: 1;
  &.is-opaque {
    @include opaque;
    visibility: visible;
  }
}

.up-next__item-name {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 16px;
  display: block;
  @include swap_direction(padding, 41px 15px 15px);
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
}

.up-next__item-arrow {
  background-size: auto auto;
  background-position: 0 -194px;
  height: 13px;
  width: 25px;
  position: absolute;
  #{$ldirection}: 18px;
  bottom: 18px;
  &.dark {
    background-size: auto auto;
    background-position: 0 -156px;
    height: 13px;
    width: 26px;
  }
}

.up-next__item {
  color: $color-white;
  &.dark {
    color: $color-navy;
  }
}

.up-next-show {
  display: block;
}
