.last-purchased__header {
  @include text--bold;
  @include swap_direction(margin, 0 0 14px);
}

.product-thumb__title a {
  color: #fff;
}

.last-purchased__days-ago {
  @include swap_direction(margin, 9px 0 0);
}
