///
/// @file drupal_modules/esteelauder_common/_franchise_bestfor.scss
/// Styles for mpp best for block on franchise pages
///
/// \brief Styling for template at:
///   esteelauder/modules/custom/esteelauder_common/templates/franchise_bestfor_v1
///

.mpp__bestfor {
  @include swap_direction(padding, 3em 1em);
  @include swap_direction(margin, 0 auto);
  @media #{$medium-up} {
    @include swap_direction(padding, 0 3em);
    max-width: 1024px;
    @include swap_direction(margin, 0 auto 100px auto);
  }
}

.mpp__bestfor--subcopy {
  padding-top: 1em;
  @media #{$medium-up} {
    width: 50%;
    @include swap_direction(margin, 0 auto);
  }
}
