.enhanced-cart-page {
  -webkit-font-smoothing: antialiased;
  margin-top: 12px;
  @media #{$medium-up} {
    margin-top: 36px;
  }
  .pg_wrapper.checkout {
    padding-bottom: 0;
  }
  .our-guarantee-panel {
    border: 0;
    margin: 23px 0 6px;
    padding: 0;
    @media #{$medium-up} {
      margin: 17px 0 0;
    }
    @media #{$device-ipad_only} {
      margin-bottom: 30px;
    }
    @media #{$medium-only} {
      margin-bottom: 50px;
    }
    &__title {
      @include swap_direction(padding, 0 25px 0 16px);
      border: 0;
      color: $glux-color-submenu-text;
      font-family: $optimalight;
      font-size: 18px;
      font-weight: 600;
      height: auto;
      letter-spacing: 0;
      line-height: 1.35;
      margin: 0 0 5px;
      width: auto;
      @media #{$medium-up} {
        font-size: 26px;
        letter-spacing: 1px;
        line-height: 1.5;
        padding-#{$ldirection}: 0;
      }
    }
    &__text {
      @include swap_direction(padding, 0 13px 0 16px);
      color: $color-black-darker-transparent;
      font-family: $akzidenz;
      font-size: 14px;
      height: 88px;
      letter-spacing: 0;
      line-height: 1.4;
      width: auto;
      @media #{$medium-up} {
        height: auto;
        max-width: 562px;
        padding: 0;
      }
      @media #{$medium-only} {
        max-width: 100%;
      }
    }
    &__content {
      width: auto;
    }
  }
  .checkout {
    .checkout__content {
      @media #{$medium-up} {
        margin-#{$rdirection}: 51px;
        max-width: 666px;
        padding: 0;
      }
      @media #{$device-ipad_only} {
        margin: 0;
        max-width: 100%;
        padding: 0 30px;
      }
      .viewcart-panel {
        border: 0 !important;
        padding-bottom: 0;
        &__header {
          margin: 0 0 21px;
          width: auto;
          &--title {
            border: 0;
            color: $color-black;
            font-family: $optimalight;
            font-size: 22px;
            font-weight: 600;
            height: 32px;
            letter-spacing: 0;
            line-height: 0.8;
            margin: 0;
            padding: 0;
            text-transform: capitalize;
            @media #{$medium-up} {
              font-size: 32px;
              height: auto;
              letter-spacing: 0.5px;
              line-height: 0.67;
            }
            .item-total-count {
              color: $color-black;
              font-family: $optimalight;
              font-size: 22px;
              font-weight: 600;
              height: 32px;
              letter-spacing: 0;
              line-height: 1.3;
              margin-#{$ldirection}: 0;
              text-align: center;
              @media #{$medium-up} {
                font-size: 32px;
              }
              &__number {
                font-family: $optimalight;
                &::after {
                  content: '\0029';
                }
                &::before {
                  content: '\0028';
                }
              }
            }
          }
          .empty-cart {
            text-align: $ldirection;
          }
          &--sub-title {
            color: $color-black;
            font-family: $akzidenz;
            font-size: 14px;
            font-weight: normal;
            height: 16px;
            letter-spacing: 0;
            line-height: 1.1;
            margin: 12px 0 0;
            @media #{$medium-up} {
              font-size: 20px;
              margin-top: 15px;
            }
          }
        }
        .account-login-button {
          background-color: $color-gray-cs-side;
          height: 51px;
          padding: 14px 0;
          text-align: center;
          &__content {
            @include swap_direction(padding, 14px 28px 15px 13px);
            background-image: none;
            font-family: $akzidenz;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 1.5;
            text-decoration: underline;
            .right_arrow {
              font-size: 14px;
            }
          }
        }
        #messages {
          margin: 0 0 14px;
          padding: 0 16px;
          text-align: $ldirection;
          @media #{$medium-up} {
            padding: 0;
          }
        }
        .signin-to-see-cart {
          margin-top: 24px;
          padding: 0 16px;
          text-align: $ldirection;
          @media #{$medium-up} {
            padding: 0;
          }
        }
        .wp-messages {
          margin-bottom: 21px;
        }
      }
      .viewcart-product__content {
        .cart-items {
          @include swap_direction(padding, 0 17px 0 16px);
          background-color: $color-white;
          border-bottom: $checkout-line-lighter-gray;
          border-top: $checkout-line-lighter-gray;
          margin-bottom: 16px;
          overflow: auto;
          @media #{$medium-up} {
            border: 1px solid $color-light-gray;
          }
          &__engraving-sku-item {
            flex-wrap: wrap;
            .cart-items__item--sub {
              width: 100%;
            }
            .engraving-wrapper {
              position: relative;
              width: 100%;
              .engraving-view {
                position: static;
                &__message-disclaimer {
                  font-size: 12px;
                }
                &__message-edit,
                &__message-delete {
                  font-size: 13px;
                  font-weight: bold;
                  letter-spacing: 1px;
                  text-decoration: underline;
                  text-transform: capitalize;
                }
              }
            }
          }
          .cart-item {
            align-items: flex-start;
            background-color: $color-white;
            border-bottom: 1px solid $color-light-gray;
            display: flex;
            margin: 0;
            padding: 16px 0;
            width: 100%;
            @media #{$medium-up} {
              padding: 16px 0 17px;
            }
            &__pc-group {
              flex: 1;
              @media #{$medium-up} {
                display: flex;
                flex-direction: column;
              }
              .donation_amount {
                .qty-container {
                  margin: 20px 0;
                  @media #{$medium-up} {
                    margin: 12px 0;
                  }
                }
              }
            }
            &:last-child {
              border-bottom: 0;
            }
            &__thumb {
              margin-#{$rdirection}: 16px;
              width: 80px;
              height: auto;
              &-image {
                width: 100%;
                height: auto;
              }
            }
            &__desc {
              float: none;
              width: 100%;
            }
            &__product-name {
              @include swap_direction(margin, 0 15px 10px 0);
              font-family: $optimaregular;
              font-size: 14px;
              font-weight: normal;
              letter-spacing: 0;
              line-height: 1.3;
              margin-bottom: 8px;
              max-width: 195px;
              @media #{$medium-up} {
                font-size: 16px;
                font-weight: 600;
                line-height: 1;
                margin-bottom: 3px;
                max-width: 307px;
              }
              &--text {
                color: $color-blue-dark;
              }
            }
            &__product-subname {
              font-family: $optimaregular;
              font-size: 14px;
              font-weight: normal;
              letter-spacing: 0;
              line-height: 1.15;
              margin: 0 0 11px;
              @media #{$medium-up} {
                font-weight: 600;
                margin-bottom: 12px;
              }
              &--text {
                color: $color-blue-dark;
              }
            }
            &__size-shade-qty-group {
              align-items: center;
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              margin-bottom: 10px;
              @media #{$medium-up} {
                margin: 0;
              }
            }
            &__size,
            &__skin_type {
              color: $color-black-dark-transparent;
              font-size: 14px;
              height: 16px;
              letter-spacing: 0;
              line-height: 1.1;
              margin-bottom: 12px;
              @media #{$medium-up} {
                @include swap_direction(margin, 0 15px 0 0);
                @include swap_direction(padding, 0 15px 0 0);
                border-#{$rdirection}: 1px solid $color-light-gray;
                display: inline-block;
              }
            }
            &__size {
              font-family: $akzidenz;
              @media #{$medium-up} {
                font-family: $akzidenz;
                font-size: 15px;
              }
            }
            &__shade-name {
              color: $color-black-dark-transparent;
              font-family: $akzidenz;
              @media #{$medium-up} {
                font-size: 15px;
              }
            }
            &__skin_type {
              font-family: $optimaregular;
              margin-#{$ldirection}: 10px;
            }
            &__remove-form {
              #{$rdirection}: 0;
              top: 17px;
              z-index: 2;
            }
            &__color {
              @include swap_direction(margin, 0 10px 12px 0);
              @include swap_direction(padding, 0 10px 0 0);
              border-#{$rdirection}: 1px solid $color-light-gray;
              color: $color-black;
              font-family: $optimaregular;
              font-size: 14px;
              font-weight: normal;
              height: auto;
              letter-spacing: 0;
              line-height: 1.4;
              text-transform: capitalize;
              @media #{$medium-up} {
                @include swap_direction(margin, 0 15px 0 0);
                display: inline-block;
                font-size: 12px;
                padding-#{$rdirection}: 15px;
              }
            }
            &__swatch {
              height: 16px;
              margin-#{$rdirection}: 6px;
              width: 16px;
            }
            &__price-content {
              margin-top: 7px;
            }
            &__price {
              color: $color-black;
              font-family: $akzidenz;
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 1.8px;
              line-height: 1.7;
              margin: 0;
              @media #{$medium-up} {
                clear: $ldirection;
                display: block;
                width: auto;
              }
              .strike_price {
                color: $color-black-transparent;
                white-space: nowrap;
              }
            }
            &__price--ca-price {
              display: flex;
              flex-direction: column;
              margin-bottom: 10px;
              width: auto;
              @media #{$medium-up} {
                flex-direction: row;
                margin-bottom: 0;
              }
            }
            &__total {
              font-family: $akzidenz;
              font-size: 14px;
              line-height: 1.7;
              margin-#{$ldirection}: 10px;
              text-align: $rdirection;
              @media #{$medium-up} {
                clear: $ldirection;
                margin-#{$ldirection}: 0;
                width: auto;
              }
            }
            .qty-container {
              margin: 0 auto;
              width: 100%;
              @media #{$medium-up} {
                margin: 0;
                width: auto;
              }
              &.loading {
                background-position-x: 25px;
                background-size: 24px;
                @media #{$medium-up} {
                  background-position-x: center;
                }
              }
              .button-container {
                display: flex;
                margin: 0;
                .cart-qty-minus,
                .cart-qty-plus {
                  @include swap_direction(padding, 0 13px 0 0);
                  background-color: $color-white;
                  border: 1px solid $color-light-gray;
                  border-radius: 0;
                  color: $color-black;
                  float: $ldirection;
                  font-weight: normal;
                  height: 25px;
                  line-height: 0.3;
                  text-align: center;
                  width: 25px;
                }
                .icon--minus,
                .icon--plus {
                  height: 25px;
                  position: relative;
                  width: 25px;
                  &::before {
                    #{$ldirection}: 48%;
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 48%;
                    transform: translate(-50%, -50%);
                    width: 8px;
                  }
                }
                .icon--minus {
                  &::before {
                    background: url('../../../../../media/images/cart_enhancement/qty-minus-icon.png')
                      no-repeat 0% 0% / cover;
                    height: 1px;
                  }
                }
                .icon--plus {
                  &::before {
                    background: url('../../../../../media/images/cart_enhancement/qty-plus-icon.png')
                      no-repeat 0% 0% / contain;
                    height: 8px;
                  }
                }
                .qty {
                  @include swap_direction(padding, 5px 6px 4px 7px);
                  border-#{$ldirection}: 0;
                  border-#{$rdirection}: 0;
                  border-bottom: 1px solid $color-light-gray;
                  border-top: 1px solid $color-light-gray;
                  box-shadow: none;
                  color: $color-black-darker-transparent;
                  font-family: $akzidenz;
                  font-size: 14px;
                  font-weight: normal;
                  height: 25px;
                  line-height: 1.5;
                  text-align: center;
                  width: 25px;
                }
                .donation-qty {
                  padding: 6px 6px 4px;
                  width: 98px;
                  @media #{$medium-up} {
                    padding-top: 5px;
                  }
                  &--us {
                    padding-top: 5px;
                    width: 40%;
                  }
                }
                .disabled {
                  pointer-events: none;
                  .icon {
                    opacity: 0.5;
                  }
                }
              }
            }
            .remove_link {
              background: url('../../../../../media/images/cart_enhancement/close_icon.png')
                no-repeat 0% 0% / contain;
              height: 16px;
              opacity: 0.75;
              width: 16px;
              &:focus,
              &:active {
                border: 0;
                outline: 0;
              }
            }
            .replenishment-content {
              align-items: center;
              display: flex;
              width: 100%;
            }
            &__qty-replenishment-group {
              display: flex;
              justify-content: flex-start;
              margin-top: 10px;
              width: 100%;
              .cart-item__qty {
                display: none;
              }
              .cart-item__replenishment {
                float: none;
                width: 100%;
                @media #{$medium-up} {
                  margin: 0;
                  max-width: 250px;
                  padding: 0;
                }
                .selectbox {
                  @include swap_direction(padding, 10px 45px 10px 16px);
                  background-position: $rdirection center;
                  border: 1px solid $color-light-gray;
                  height: 44px;
                  line-height: 2;
                  margin-bottom: 0;
                  text-overflow: ellipsis;
                  @media #{$medium-up} {
                    color: $color-blue-dark;
                    margin-top: 0;
                  }
                  .selectBox-label {
                    padding: 0;
                  }
                  .selectBox-arrow {
                    #{$rdirection}: 16px;
                  }
                }
              }
              #replenishment-description {
                @include swap_direction(margin, 0 0 0 14px);
                display: block;
                .overlay-link {
                  align-items: center;
                  background-color: $color-black;
                  border-radius: 50%;
                  color: $color-white;
                  display: flex;
                  height: 20px;
                  justify-content: center;
                  text-decoration: none;
                  width: 20px;
                  .info-icon {
                    height: 11px;
                    width: 2px;
                  }
                }
              }
            }
            &__error--out-of-stock {
              width: 100%;
            }
            &.kit-item {
              border-bottom: 1px solid $color-light-gray;
              &.custom-kit {
                width: 100%;
                display: inline-block;
                .viewcart-remove__kit--remove {
                  background-size: 16px 16px;
                  background-position: bottom;
                }
                .remove_kit {
                  opacity: 0.75;
                  &:focus,
                  &:active {
                    border: 0;
                    outline: 0;
                  }
                }
              }
            }
            &.bundle-kit {
              .cart-item {
                &__size-shade-qty-group {
                  display: none;
                }
                &__price-content {
                  margin: 0;
                }
              }
              .kit-container {
                .dw__view-cart {
                  border: 0;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                  &__shaded {
                    border: 0;
                    padding: 0;
                  }
                  .color.column {
                    padding-bottom: 10px;
                  }
                }
              }
            }
            &__promo-message {
              font-size: 14px;
              margin-top: 7px;
            }
          }
          .strike_price,
          .discount_price {
            margin-#{$rdirection}: 2px;
            @media #{$medium-up} {
              margin-#{$rdirection}: 17px;
            }
          }
          .donation {
            padding: 16px 0;
            @media #{$medium-up} {
              padding-bottom: 19px;
            }
            .cart-item {
              &__product-name {
                margin-bottom: 9px;
                @media #{$medium-up} {
                  margin-bottom: 6px;
                }
              }
              &__product-subname {
                margin: 0;
              }
              &__size {
                border: 0;
              }
              &__price-content {
                margin-top: 12px;
              }
              &__qty-replenishment-group {
                margin-top: 0;
              }
            }
          }
          .sample {
            .cart-item__desc {
              .cart-item {
                &__size {
                  border: 0;
                  margin-#{$rdirection}: 0;
                }
                &__qty {
                  text-align: $ldirection;
                  width: 100%;
                  &.qty {
                    @media #{$medium-up} {
                      border-#{$ldirection}: 1px solid $color-light-gray;
                      padding-#{$ldirection}: 15px;
                    }
                  }
                }
              }
            }
          }
        }
        /* stylelint-disable selector-max-compound-selectors, selector-max-class */
        .kit-items {
          & > .cart-item__desc > .product_subname > a,
          .sample .cart-item__desc .product_subname {
            display: none;
          }
        }
        /* stylelint-enable selector-max-compound-selectors, selector-max-class */
        .empty-cart-items {
          border: 0;
          margin: 0;
          padding: 0;
        }
      }
      .giftwrap-panel {
        &__content {
          border: 0;
          padding: 0;
          #checkout_giftwrap {
            border-bottom: 1px solid $color-light-gray;
            border-top: 1px solid $color-light-gray;
            margin-bottom: 16px;
            @media #{$medium-up} {
              border: 1px solid $color-light-gray;
              margin-bottom: 0;
            }
          }
        }
        &__form {
          width: 100%;
          @media #{$medium-up} {
            width: calc(100% - 100px);
          }
        }
        &__is-gift {
          clear: none;
          float: none;
        }
        &__form-container {
          display: block;
          margin: 0;
          @media #{$medium-up} {
            display: flex;
          }
        }
        &__label {
          color: $color-black;
          cursor: pointer;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 1.14;
          margin-bottom: 21px;
          padding-#{$ldirection}: 46px;
          position: relative;
          @media #{$medium-up} {
            margin-bottom: 7px;
            padding-#{$ldirection}: 48px;
          }
          &::after,
          &::before {
            @include transition(all 0.3s ease);
            content: '';
            position: absolute;
          }
          &::before {
            #{$ldirection}: 0;
            background-color: $color-dark-blue;
            border-radius: 10px;
            height: 14px;
            padding: 1px;
            top: 5px;
            width: 34px;
          }
          &::after {
            @include transform(none);
            #{$ldirection}: 1px;
            background-color: $color-white;
            background-image: none;
            border: 0;
            border-radius: 50%;
            box-shadow: 0 1px 3px 0 $color-black-opacic;
            height: 20px;
            opacity: 1;
            top: 1px;
            width: 20px;
          }
          .text-no {
            display: block;
            line-height: 1;
            padding-top: 5px;
            @media #{$medium-up} {
              padding-top: 2px;
            }
          }
          .text-yes {
            display: none;
          }
        }
        .gift-options-content__container {
          padding: 0 16px;
        }
        .giftwrap-message-container {
          margin-top: 12px;
          .gift-options {
            margin-bottom: 22px;
            width: 100%;
            @media #{$medium-up} {
              margin-bottom: 21px;
            }
          }
          .gift-message__text {
            color: $color-black;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 1.43;
            margin-bottom: 12px;
            @media #{$medium-up} {
              line-height: 1.72;
            }
          }
          .card-message {
            margin-bottom: 0;
            .field {
              border: 1px solid $color-light-gray;
              height: 94px;
              padding: 15px;
              &:focus + label::before {
                @include swap_direction(padding, 0 10px 0 6px);
                margin: 0 10px;
                transform: translateY(-1.2em) scale(0.8, 0.8);
              }
              &.error {
                border-color: $color-red;
              }
            }
            label {
              margin-bottom: 3px;
            }
            .cart-label-mode + label::before {
              @include swap_direction(padding, 0 10px 0 6px);
              margin: 0 10px;
              transform: translateY(-1.2em) scale(0.8, 0.8);
            }
          }
        }
        &__checkbox {
          &:checked ~ .giftwrap-label {
            &::before {
              background-color: $color-gw-toggle-blue;
              border: 0;
              content: '';
            }
            &::after {
              #{$ldirection}: 16px;
            }
            .text-no {
              display: none;
            }
            .text-yes {
              display: block;
              line-height: 1;
              padding-top: 5px;
              @media #{$medium-up} {
                padding-top: 2px;
              }
            }
          }
        }
        &__image {
          @include swap_direction(margin, 0 15px 15px 0);
          float: $ldirection;
          height: 80px;
          max-width: 90px;
          width: 100%;
          @media #{$medium-up} {
            float: none;
          }
          .giftwrap__image {
            height: 100%;
            margin: 0;
            width: 100%;
          }
        }
        &__max-count {
          font-family: $akzidenz;
          font-style: italic;
        }
        &__save-gift-options {
          .form-item {
            margin: 14px 0 0;
          }
          .button {
            background: $color-white;
            border: 1px solid $color-blue-dark;
            color: $color-blue-dark;
            height: 40px;
            line-height: 2;
            min-width: 150px;
          }
        }
        &__title {
          font-family: $optimalight;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0;
          margin-bottom: 0;
          padding: 14px 16px;
          @media #{$medium-up} {
            font-size: 26px;
            letter-spacing: 1px;
          }
          .title-icon {
            #{$rdirection}: 16px;
            background: url('../../../../../media/images/cart_enhancement/plus-icon.png') no-repeat;
            background-size: contain;
            height: 24px;
            top: 11px;
            width: 24px;
            @media #{$medium-up} {
              top: 18px;
            }
          }
          &.open {
            .title-icon {
              background: url('../../../../../media/images/cart_enhancement/minus-icon.png')
                no-repeat;
              background-size: contain;
              top: 22px;
              @media #{$medium-up} {
                top: 30px;
              }
            }
          }
        }
        &__question,
        .giftwrap-success-message {
          font-size: 14px;
          line-height: 1.43;
          margin-bottom: 10px;
          @media #{$medium-up} {
            line-height: 1.72;
            margin-bottom: 12px;
          }
        }
        .giftwrap-success-message {
          display: flex;
          margin-top: 12px;
          &__text {
            margin-#{$ldirection}: 5px;
          }
          &__seperator {
            background-color: $color-light-gray;
            height: 20px;
            margin: 2px 8px 0;
            width: 1px;
          }
          &__edit {
            cursor: pointer;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 2;
            text-decoration: underline;
            text-transform: capitalize;
          }
        }
        .giftwrap-success-container {
          margin-top: 12px;
          .gift-message__text {
            font-size: 14px;
            letter-spacing: 0;
            margin: 0;
          }
        }
      }
      #promo-bca-panel.promo-bca-panel {
        border-bottom: 1px solid $color-light-gray;
        border-top: 1px solid $color-light-gray;
        margin: 0 0 15px;
        padding: 0;
        @media #{$medium-up} {
          border: 1px solid $color-light-gray;
          margin: 16px 0 0;
        }
        .promo-bca-panel__title {
          border: 0;
          cursor: pointer;
          font-family: $optimalight;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0;
          margin: 0;
          padding: 14px 16px;
          @media #{$medium-up} {
            font-size: 26px;
            letter-spacing: 1px;
            padding-bottom: 14px;
          }
          .title-icon {
            #{$rdirection}: 16px;
            background: url('../../../../../media/images/cart_enhancement/plus-icon.png') no-repeat;
            background-size: contain;
            height: 24px;
            position: absolute;
            top: 11px;
            width: 24px;
            @media #{$medium-up} {
              top: 18px;
            }
          }
          &.open {
            padding-bottom: 14px;
            .title-icon {
              background: url('../../../../../media/images/cart_enhancement/minus-icon.png')
                no-repeat;
              background-size: contain;
              top: 22px;
              @media #{$medium-up} {
                top: 30px;
              }
            }
          }
        }
        .checkout__panel-content {
          padding: 0 16px 21px;
          .product_image {
            height: 70px;
            padding: 5px 9px;
            width: 124px;
          }
        }
        .promo-multi-donation {
          &__header {
            font-size: 14px;
            font-weight: bold;
            line-height: 1.6;
            margin: 0 0 11px;
            padding: 0;
          }
          &__container {
            margin: 11px 0 0;
            .promo_bca_description {
              .overlay-link {
                color: $color-black;
                font-weight: normal;
              }
            }
          }
          &-img__container {
            align-items: center;
            display: flex;
            .product_image {
              height: 70px;
              padding: 5px 9px;
              width: 124px;
            }
            .promo-multi-donation__promo_bca_logo {
              margin-#{$rdirection}: 4px;
            }
          }
          &__donation_amount {
            margin-#{$rdirection}: 5px;
            &:checked ~ label::before {
              border: 1px solid $color-navy;
            }
          }
          &__donation_amount_label {
            color: $color-black;
            font-size: 14px;
            letter-spacing: 1.8px;
            padding-#{$ldirection}: 30px;
            @media #{$medium-up} {
              padding-#{$ldirection}: 32px;
            }
            &::before {
              #{$ldirection}: 1px;
              border: 1px solid $color-light-gray;
              border-radius: 50%;
              cursor: pointer;
              height: 16px;
              padding: 2px;
              position: absolute;
              top: 2px;
              width: 16px;
            }
            &::after {
              #{$ldirection}: 5px;
              background-color: $color-navy;
              border-color: $color-navy;
              height: 8px;
              position: absolute;
              top: 7px;
              width: 8px;
            }
          }
        }
        .promo_bca_title {
          font-family: $optimaregular;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0;
          line-height: 1.5;
          @media #{$medium-up} {
            font-weight: 600;
          }
        }
        .promo_bca_description {
          font-family: $akzidenz;
          font-size: 14px;
          line-height: 1.45;
          margin-top: 5px;
          @media #{$medium-up} {
            margin-top: 6px;
          }
        }
        .promo_bca_logo {
          @include swap_direction(margin, 0 16px 0 0);
          border: 1px solid $color-light-gray;
          border-radius: 6px;
          float: none;
          height: 70px;
          padding: 5px 9px;
          width: 124px;
        }
        .donation_amounts {
          margin: 10px 0 11px;
          padding-#{$ldirection}: 2px;
          @media #{$medium-up} {
            margin: 14px 0 19px;
          }
          .donation_amount {
            @include swap_direction(margin, 0 7px 0 0);
            @media #{$medium-up} {
              margin-#{$rdirection}: 20px;
            }
            .form-item.radio {
              margin: 0;
            }
            &:last-child {
              margin-#{$rdirection}: 0;
            }
          }
        }
        .donation_amount ~ label::before,
        .donation_amount ~ label::after {
          height: 16px;
          top: 2px;
          width: 16px;
        }
        .donation_amount_label {
          font-size: 14px;
          margin: 0;
        }
        .promo_bca_button {
          background: $color-white;
          border: 1px solid $color-blue-dark;
          color: $color-black;
          width: 150px;
        }
      }
      .promo-section {
        margin: 0 0 32px;
        .checkout_banner {
          img {
            width: 100% !important;
          }
        }
      }
      .promo-panel-ca {
        margin: 0;
      }
      .promo-donations-panel {
        border-bottom: 1px solid $color-light-gray;
        border-top: 1px solid $color-light-gray;
        margin: 17px 0;
        padding: 14px 16px;
        @media #{$medium-up} {
          border: 1px solid $color-light-gray;
          margin-top: 16px;
        }
        &__title {
          border-bottom: 0;
          cursor: pointer;
          font-family: $optimaregular;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          padding: 0;
          .title-icon {
            #{$rdirection}: 16px;
            background: url('../../../../../media/images/cart_enhancement/minus-icon.png') no-repeat;
            background-size: contain;
            display: block;
            height: 24px;
            position: absolute;
            top: 24px;
            width: 24px;
          }
          &.open {
            margin-bottom: 16px;
            .title-icon {
              background: url('../../../../../media/images/cart_enhancement/plus-icon.png')
                no-repeat;
              background-size: contain;
              height: 24px;
              top: 12px;
              width: 24px;
            }
          }
        }
        .checkout__panel-content {
          .promo_bca_description {
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 1.4;
            margin-bottom: 8px;
          }
          .donation_amounts {
            margin-bottom: 20px;
            .donation_amount {
              margin-#{$rdirection}: 10px;
              &_label {
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0;
                margin-bottom: 0;
              }
            }
          }
          .promo_bca_button {
            margin-bottom: 8px;
          }
          .promo-multi-donation {
            &__header {
              font-size: 18px;
              font-weight: bold;
              line-height: 1.35;
              margin-bottom: 10px;
              @media #{$medium-up} {
                font-size: 20px;
                line-height: 1.2;
              }
            }
            &__container {
              @media #{$medium-up} {
                float: none;
                margin: 0;
                width: auto;
              }
              .donation_list {
                border-radius: 2px;
                font-size: 14px;
                height: 46px;
                letter-spacing: 0;
                line-height: 1.4;
                margin: 0 0 12px;
                padding-#{$rdirection}: 44px;
                text-transform: capitalize;
                width: 100%;
                @media #{$medium-up} {
                  max-width: 430px;
                }
                .selectBox-label {
                  border-#{$rdirection}: 1px solid $color-medium-gray-blue;
                  color: $color-black;
                  display: flex;
                  font-size: 14px;
                  height: 46px;
                  letter-spacing: 0;
                  line-height: 1.4;
                  margin: 0;
                  padding: 13px 16px;
                  text-transform: capitalize;
                  width: 100%;
                }
                .selectBox-arrow {
                  #{$rdirection}: 18px;
                  border-top-color: $color-dark-gray;
                  border-#{$ldirection}-width: 5px;
                  border-#{$rdirection}-width: 5px;
                  border-top-width: 5px;
                }
              }
              .promo_bca_cart {
                .promo_bca_button {
                  background-color: $color-navy;
                  border-radius: 2px;
                  color: $color-white;
                  font-size: 14px;
                  font-weight: 500;
                  height: 36px;
                  letter-spacing: 1.25px;
                  line-height: 1.4;
                  width: 143px;
                }
              }
            }
          }
          .promo_bca_title {
            font-family: $akzidenz;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            margin-bottom: 5px;
            line-height: 1.75;
            @media #{$medium-up} {
              margin-bottom: 4px;
            }
          }
          .promo_bca_logo {
            display: none;
          }
        }
      }
    }
    #offer-code-panel.panel--offer-code {
      .offer-code__form {
        padding: 20px 16px;
        @media #{$device-ipad_only} {
          padding: 16px 0 0;
        }
        .offer-code__code {
          padding-#{$rdirection}: 11px;
        }
        .offer-code__success-text {
          border: 0;
          color: $color-success;
          display: block;
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 1.5;
          padding: 0;
          text-transform: none;
          width: 100%;
          .offer-code__value {
            font-weight: bold;
          }
          .edit-offer {
            float: $rdirection;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 2;
            text-decoration: underline;
            text-transform: capitalize;
          }
          .offer-code__applied-text {
            display: inline-block;
            font-size: 14px;
            padding-#{$ldirection}: 30px;
          }
          .applied-offer {
            display: block;
            margin-bottom: 5px;
            position: relative;
            &:last-child {
              margin-bottom: 10px;
            }
            .edit-offer {
              color: $color-black;
              text-transform: uppercase;
            }
          }
          .message-icon {
            #{$ldirection}: 0;
            background: url('/media/images/cart_enhancement/success-icon.png') no-repeat center;
            background-size: 20px;
            height: 20px;
            position: absolute;
            top: 2px;
            width: 20px;
          }
        }
        .offer-code-message-container {
          font-size: 14px;
          .offer-code__messages {
            margin-bottom: 17px;
          }
          .success,
          .error,
          .deferred {
            padding-#{$ldirection}: 30px;
            position: relative;
            .message-icon {
              #{$ldirection}: 0;
              height: 20px;
              position: absolute;
              top: 0;
              width: 20px;
            }
          }
          .success {
            color: $color-success;
            .message-icon {
              background: url('/media/images/cart_enhancement/success-icon.png') no-repeat center;
              background-size: 20px;
            }
            a {
              color: $color-success;
            }
          }
          .error {
            color: $color-error;
            .message-icon {
              background: url('/media/images/cart_enhancement/error-icon.png') no-repeat center;
              background-size: 20px;
            }
            a {
              color: $color-error;
            }
          }
          .deferred {
            color: $color-alert;
            .message-icon {
              background: url('/media/images/cart_enhancement/attention-icon.png') no-repeat center;
              background-size: 20px;
            }
            a {
              color: $color-alert;
            }
          }
        }
        .offer-code__byline {
          .success {
            color: $color-success;
          }
        }
        .offer-code-field {
          padding-#{$ldirection}: 15px;
          &:focus + label::before {
            @include swap_direction(padding, 0 10px 0 6px);
            margin: 0 10px;
            transform: translateY(-1.8em) scale(0.8, 0.8);
          }
        }
        .cart-label-mode + label::before {
          @include swap_direction(padding, 0 10px 0 6px);
          margin: 0 10px;
          transform: translateY(-1.8em) scale(0.8, 0.8);
        }
      }
    }
    .loyalty-offer-code-panel {
      @media #{$device-ipad_only} {
        border: 1px solid $color-light-gray;
        margin-top: 16px;
      }
      @media #{$medium-only} {
        border: 0;
        margin: 16px 0 19px;
      }
      .panel--offer-code__header {
        padding: 15px 16px 16px;
      }
    }
    .empty-loyalty-panel {
      border: 0;
      margin: 0;
    }
    .offer-code-panel__content {
      border-bottom: 1px solid $color-light-gray;
      @media #{$device-ipad_only} {
        border: 0;
      }
      .offer-code {
        &__byline {
          @include swap_direction(padding, 21px 0 0 26px);
          color: $color-navy;
          display: none;
          font-family: $optimalight;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 1;
          .title-icon {
            #{$rdirection}: 8px;
          }
          &.open {
            .title-icon {
              #{$rdirection}: 20px;
            }
          }
        }
      }
      .checkout__offer-code {
        &__wallet-count {
          display: none;
        }
        &__wallet {
          padding: 0 16px 46px;
          .slick-prev,
          .slick-next {
            bottom: 17px;
            height: 14px;
            top: auto;
            width: 20px;
            &::before {
              content: '';
            }
          }
          .slick-prev {
            #{$ldirection}: 16px;
            background: url('../../../../../media/images/cart_enhancement/arrow-left.png') no-repeat
              center;
            background-size: contain;
          }
          .slick-next {
            #{$rdirection}: 16px;
            background: url('../../../../../media/images/cart_enhancement/arrow-right.png')
              no-repeat center;
            background-size: contain;
          }
          .slick-dots {
            #{$ldirection}: 0;
            #{$rdirection}: 0;
            bottom: 17px;
            display: block;
            margin: auto;
            top: auto;
            width: 80%;
            li {
              height: 12px;
              margin: 0 8px;
              width: 12px;
              button {
                height: 12px;
                padding: 0;
                width: 12px;
                &::before {
                  #{$ldirection}: 0;
                  font-size: 12px;
                  height: 12px;
                  line-height: 1;
                  margin: auto;
                  width: 12px;
                }
              }
            }
          }
          .slick-disabled {
            opacity: 0.32;
          }
        }
        &__wallet-item {
          background-color: $color-white;
          border: 1px solid $color-light-gray;
          border-radius: 0;
          padding: 16px 16px 0;
          &__image-container {
            float: $ldirection;
            height: 47px;
            width: 47px;
          }
          &__image {
            width: 100%;
          }
          &__header,
          &__description {
            font-family: $akzidenz;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0.4px;
            line-height: 1.3;
            margin-#{$ldirection}: 60px;
            text-align: $ldirection;
          }
          &__header {
            color: $color-black-darker-transparent;
            font-weight: 600;
          }
          &__description {
            color: $color-black-dark-transparent;
            .loyalty__panel__offers__offer-code-wrap {
              text-align: $ldirection;
            }
          }
          &__button-container {
            margin-top: 0;
            .checkout__offer-code__wallet-apply {
              height: 40px;
              line-height: 2;
              margin-top: 13px;
              padding: 9px 0 7px;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .recommended-section {
    border-top: 1px solid $color-light-gray;
    margin-top: 55px;
    .recommended-products-panel {
      margin: 0 auto;
      max-width: 900px;
      padding: 53px 0 94px;
      &__title {
        color: $color-navy;
        font-family: $optimalight;
        font-size: 32px;
        font-weight: 600;
        height: 36px;
        letter-spacing: 1px;
        line-height: 1.3;
        margin-bottom: 25px;
        text-align: center;
        text-transform: capitalize;
      }
      &__content {
        .recommended-products__list {
          display: flex;
          justify-content: center;
          .slick-track {
            display: flex;
            margin: 0 auto;
          }
          .sku-brief-editorial {
            max-width: 200px;
            min-height: auto !important;
            padding: 0;
            position: static;
            width: 100%;
            &__image-wrapper {
              margin-bottom: 10px;
            }
            &__image {
              margin: auto;
            }
            &__reviews {
              display: flex;
              margin-bottom: 15px;
            }
            &__shade {
              display: none;
            }
            &__product--name,
            &__product--sub-name {
              color: $color-blue-dark;
              font-family: $optimaregular;
              letter-spacing: 0;
              text-align: center;
            }
            &__product--name {
              font-size: 22px;
              margin-bottom: 10px;
            }
            &__product--sub-name {
              font-size: 16px;
            }
            &__checkout--controls {
              position: static;
              text-align: center;
              .btn {
                #{$ldirection}: 0;
                #{$rdirection}: 0;
                bottom: 17px;
                height: 48px;
                margin: auto;
                max-width: 168px;
                position: absolute;
                width: 100%;
              }
              .product-price {
                letter-spacing: 1.8px;
                line-height: 1.7;
                margin: 3px 0 10px;
              }
            }
          }
        }
      }
      .recommended-products__section {
        height: auto;
        margin-#{$rdirection}: 16px;
        max-width: 200px;
        padding: 17px 16px 64px;
        position: relative;
        &:last-child {
          margin-#{$rdirection}: 0;
        }
      }
    }
  }
  .scroll-top-section {
    #{$rdirection}: 9%;
    background-color: $color-navy;
    border-radius: 50%;
    bottom: 50px;
    box-shadow: 0 3px 5px $color-black-opacic;
    color: $color-white;
    cursor: pointer;
    height: 56px;
    position: fixed;
    width: 56px;
    z-index: 3;
    @media #{$device-ipad_only} {
      bottom: 12%;
    }
    @media #{$large-only} {
      bottom: 50px;
    }
    &__icon {
      align-items: center;
      background: url('/media/images/cart_enhancement/up-arrow.png') no-repeat center;
      height: 100%;
      margin-top: 1px;
      position: absolute;
      width: 100%;
    }
  }
}

#colorbox {
  .customer-service {
    padding: 20px;
    @media #{$medium-up} {
      padding: 0;
    }
    .content_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 15px;
      .replenish__options {
        border: $checkout-line-lighter-gray;
        box-sizing: border-box;
        margin: 5px;
        padding: 5px 0;
        text-align: center;
        width: 45%;
        @media #{$medium-up} {
          padding: 10px;
          margin: 10px;
        }
        .content_section__option {
          margin-bottom: 10px;
        }
      }
    }
  }
}

#viewcart {
  .enhanced-cart-page.page-wrapper {
    .account-login-button {
      border: 0;
      height: auto;
      margin: 0 0 13px;
      .checkout-login__content {
        padding: 0 24px;
      }
    }
    .viewcart-empty {
      .checkout__content {
        .top-viewcart-buttons {
          border: 0;
          display: block;
          margin: 4px 16px 24px;
          padding-bottom: 0;
          @media #{$medium-up} {
            margin-bottom: 8px;
          }
          @media #{$device-ipad_only} {
            margin: 0;
          }
          .viewcart-buttons-panel {
            .continue-buttons {
              display: flex;
              flex-direction: column;
              @media #{$medium-up} {
                flex-direction: row;
              }
              .shop-best-seller,
              .go-to-shopping {
                background-color: $color-navy;
                color: $color-white;
                flex: 1;
                height: 48px;
                line-height: 3;
                padding: 5px;
              }
              .shop-best-seller {
                margin: 0;
                width: 100%;
                @media #{$medium-up} {
                  margin-#{$rdirection}: 16px;
                }
              }
              .go-to-shopping {
                margin: 0;
              }
            }
          }
        }
      }
    }
    .checkout__content {
      .top-viewcart-buttons {
        display: none;
      }
      .viewcart-shortcuts-panel {
        margin-top: 13px;
        padding: 0 16px 14px;
        .shortcuts-content {
          &__list--item {
            text-align: $ldirection;
            width: auto;
          }
          &__list--link {
            text-align: $ldirection;
          }
        }
      }
    }
  }
}
