.giftcard-nav__menu {
  @include swap_direction(padding, 33px 0 15px);
  .sidebar-menu__item {
    @include swap_direction(margin, 0 0 10px);
  }
  .sidebar-menu__link {
    text-decoration: none;
  }
}

.giftcard-page {
  width: 85%;
  .sidebar-page__content {
    padding-top: 20px;
    @media #{$medium-up} {
      padding-top: 190px;
      width: 70%;
    }
  }
  .headline--section {
    padding-bottom: 16px;
    margin-top: 5px;
  }
  .giftcard-terms h3 {
    font-weight: bold;
    @media #{$small-only} {
      margin-top: 10px;
    }
  }
  .headline--page {
    font-size: 40px;
    letter-spacing: -0.02em;
  }
  .sidebar-chat {
    display: none;
    border-top: solid 1px $color-light-gray;
    @media #{$medium-up} {
      display: block;
    }
    .text--short-copy {
      @include swap_direction(margin, 0 0 22px);
    }
  }
  .giftcard-label,
  .giftcard-copy,
  .giftcard-copy p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;
    font-family: 'AkzidenzGrotesk W1G';
    @include swap_direction(margin, 0 0 10px 0);
    text-align: $ldirection;
  }
  .giftcard-copy {
    .ecard_title {
      font-weight: bold;
      text-align: $ldirection;
      text-transform: uppercase;
    }
  }
  .giftcard-label {
    font-size: 12px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }
  .form-item--checkbox .giftcard-label {
    font-weight: normal;
    text-transform: none;
  }
  .field,
  .selectbox {
    display: block !important;
    width: 100%;
  }
  .error_messages li:last-child {
    margin-bottom: 20px;
  }

  // About:
  .about-giftcards__question {
    margin-top: 34px;
    font-weight: bold;
    @media #{$small-only} {
      margin-top: 10px;
    }
  }
  .about-giftcards__answer,
  .about-giftcards__more-info {
    a {
      text-decoration: underline;
    }
  }
  .about-giftcards__more-info {
    @include swap_direction(margin, 21px 0 0);
  }
  @media #{$medium-up} {
    .giftcard-cols {
      @include clearfix;
    }
    .giftcard-col {
      width: 50%;
      float: $ldirection;
      padding-#{$rdirection}: 40px;
    }
  }
  .giftcard-col__image {
    display: block;
    width: 100%;
    padding-bottom: 30px;
  }
  // Check balance:
  .giftcard-balance__image {
    max-width: 300px;
  }
  // eGiftcard:
  .giftcard-egift-form {
    width: 100%;
    .giftcard-col--1 a {
      text-decoration: underline;
    }
    .giftcard-col--2 .form-item {
      margin-bottom: 20px;
      width: 100%;
    }
    .giftcard-egift-form__required-copy {
      margin-bottom: 10px;
    }
    .egiftcard-message {
      display: block;
      height: 50px;
      color: $color-navy;
      width: 100%;
      resize: none;
    }
    .giftcard-egift-form__custom-message-header {
      @include swap_direction(margin, 0 0 15px);
      border-bottom: solid 1px $color-light-gray;
      @include swap_direction(padding, 0 0 15px);
      width: 100%;
    }
    .text--form-help {
      @include swap_direction(margin, 5px 0 0);
    }
    .add-button {
      text-align: $rdirection;
    }
  }
  // eGiftcard sample overlay:
  .view-giftcard {
    .view-giftcard__info,
    .view-giftcard__redeem {
      @include swap_direction(margin, 0 0 10px);
    }
    .view-giftcard__redeem {
      list-style-position: inside;
    }
  }
  #colorbox & .view-giftcard {
    &,
    div {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
  .giftcard-small_show {
    display: none;
    @media #{$small-only} {
      margin-top: 20px;
      display: inline-block;
    }
  }
  .giftcard-small_hide {
    @media #{$small-only} {
      display: none;
    }
  }
}

#egiftcard,
#giftcard-about {
  #cboxWrapper #cboxClose {
    #{$rdirection}: 43px;
  }
}

.giftcard-accordion {
  width: 100%;
  border-top: 1px solid $color-light-gray;
  margin-top: 15px;
  .accordion_egift_card_content {
    @include swap_direction(padding, 15px);
    border-bottom: 1px solid $color-light-gray;
    h3.title {
      font-family: $optimalight;
      font-size: 28px;
      span.three-step-icon-last {
        display: inline-block;
        float: $rdirection;
        background-size: auto auto;
        background-position: 0 -10582px;
        height: 45px;
        width: 21px;
        margin-top: -7px;
      }
      &.selected {
        span.three-step-icon-last {
          background-size: auto auto;
          background-position: 0 -10212px;
          height: 45px;
          width: 21px;
        }
      }
    }
    .check-balance-btn {
      width: 100%;
    }
  }
}

//  egiftcard_basic_v1
.egiftcard_basic {
  margin-top: 35px;
  .egift_image {
    float: $ldirection;
    @include swap_direction(padding, 60px 60px 20px 0);
    @media #{$large-up} {
      width: 55%;
      @include swap_direction(padding, 0 20px 20px 80px);
    }
    @media #{$medium-only} {
      width: 65%;
      @include swap_direction(padding, 0 20px 20px 40px);
    }
    img {
      @media #{$medium-up} {
        width: 100%;
      }
    }
  }
  .egift_content {
    width: 50%;
    display: inline-block;
    @include swap_direction(padding, 70px 70px 0 0);
    @media #{$large-up} {
      width: 45%;
      float: $ldirection;
      @include swap_direction(padding, 0 50px 0 30px);
    }
    @media #{$medium-only} {
      width: 35%;
      @include swap_direction(padding, 0);
    }
    .egift_header {
      color: $color-navy;
      font-size: 60px;
      font-family: $optimalight;
      font-weight: normal;
      @media #{$medium-up} {
        font-size: 30px;
      }
      @media #{$xlarge-up} {
        font-size: 60px;
      }
    }
    .egift_subheader {
      color: $color-navy;
      font-weight: bold;
      font-size: 12px;
      margin-top: 15px;
      font-family: $akzidenz;
      letter-spacing: 0.15em;
    }
    .egift_text-promo {
      color: $color-navy;
      font-size: 12px;
      font-family: $akzidenz;
      letter-spacing: 0.15em;
      margin-top: 10px;
      text-transform: uppercase;
    }
    .shop_now {
      background: $color-navy;
      margin-top: 30px;
      color: $color-white;
      font-family: $akzidenz;
      @media #{$medium-up} {
        font-size: 12px;
        font-weight: bold;
      }
    }
    .disclaimer-desc {
      color: $color-light-gray;
      margin-top: 30px;
    }
  }
}

//  gift_balance_v1
.gift_balance {
  @media #{$small-only} {
    margin-top: 10px;
  }
  @media #{$medium-up} {
    margin-top: 20px;
    .giftcard-copy {
      margin-top: 20px;
      margin-bottom: 25px;
      padding-#{$ldirection}: 10px;
    }
    .form-item {
      margin-bottom: 8px;
    }
    .form-submit {
      margin-top: 15px;
      @media #{$medium-up} {
        margin-#{$ldirection}: 10px;
      }
    }
  }
  .form-item {
    input {
      @media #{$medium-up} {
        width: 30%;
        border-color: $color-black;
        box-shadow: none;
      }
      @media #{$medium-only} {
        width: 50%;
      }
    }
  }
}

//  tab_item_formatter_v1
.tab_item_formatter {
  .giftcard-page__content {
    margin-top: 60px;
    @media #{$medium-up} {
      @include swap_direction(padding, 0 100px 0 70px);
    }
    @media #{$large-up} {
      @include swap_direction(padding, 0 150px 0 70px);
      margin-top: 20px;
    }
    @media #{$medium-only} {
      margin-top: 25px;
      @include swap_direction(padding, 0 0 0 40px);
    }
    .giftcard-copy {
      margin-top: 20px;
      @media #{$large-up} {
        padding-#{$ldirection}: 10px;
      }
    }
    .about-giftcards__more-info {
      @media #{$large-up} {
        padding-#{$ldirection}: 0;
      }
    }
    .about-giftcards__answer {
      @media #{$large-up} {
        padding-#{$ldirection}: 0;
      }
    }
  }
  .about-giftcards {
    width: 77%;
    @media #{$medium-up} {
      width: 100%;
      padding-#{$ldirection}: 10px;
    }
    @media #{$xlarge-up} {
      width: 77%;
    }
    @media #{$medium-only} {
      padding-#{$ldirection}: 0;
    }
    .about-giftcards__question {
      margin-top: 20px;
      font-weight: bold;
    }
    .giftcard-label {
      font-size: 12px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      font-family: $akzidenz;
    }
    .about-giftcards__answer.giftcard-copy {
      font-size: 15px;
      line-height: 22px;
      font-family: $akzidenz;
      @include swap_direction(margin, 0 0 20px 0);
    }
    .about-giftcards__more-info {
      a {
        text-decoration: underline;
      }
    }
  }
  // Terms and conditions
  .sidebar-page__content {
    .giftcard-terms__title {
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.15em;
      font-family: $akzidenz;
      text-transform: uppercase;
    }
    .giftcard-terms__description {
      margin-bottom: 30px;
      @media #{$medium-up} {
        line-height: 20px;
      }
    }
  }
}

.sidebar-page {
  &__sidebar {
    @media #{$medium-up} {
      width: 25%;
    }
    @media #{$large-up} {
      padding-top: 205px;
      min-height: 740px;
    }
    @media #{$xlarge-up} {
      width: 20%;
    }
    @media #{$medium-only} {
      padding-top: 50px;
      min-height: 1000px;
      width: 30%;
    }
    .sidebar-menu__item {
      @media #{$medium-up} {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      a {
        @media #{$medium-up} {
          text-decoration: none;
        }
      }
      a:active,
      a:focus {
        outline: 0;
      }
    }
  }
  &__content {
    @media #{$medium-up} {
      width: 75%;
      margin-top: 25px;
      padding-right: 60px;
    }
    @media #{$xlarge-up} {
      width: 80%;
    }
    @media #{$medium-only} {
      width: 70%;
    }
  }
  .about-giftcards {
    @media #{$medium-only} {
      width: 100%;
    }
    &__more-info {
      @media #{$large-up} {
        padding-#{$ldirection}: 0;
      }
    }
  }
  .egift-card-customer-support {
    @media #{$small-only} {
      margin-top: 10px;
    }
    @media #{$medium-up} {
      margin-top: 20px;
      @include swap_direction(padding, 25px 0 0 80px);
    }
    @media #{$xlarge-up} {
      @include swap_direction(padding, 0 150px 0 70px);
    }
    @media #{$medium-only} {
      padding-#{$ldirection}: 40px;
    }
    a {
      text-decoration: underline;
    }
    .customer-support-content {
      @media #{$medium-up} {
        margin-top: 20px;
        padding-#{$ldirection}: 10px;
      }
      ul {
        li {
          @media #{$small-only} {
            list-style-position: outside;
            margin-left: 1.5em;
          }
        }
      }
    }
  }
  .gift_balance {
    @media #{$medium-up} {
      @include swap_direction(padding, 25px 0 0 80px);
    }
    @media #{$medium-only} {
      @include swap_direction(padding, 25px 0 0 40px);
    }
    @media #{$xlarge-up} {
      @include swap_direction(padding, 0 150px 0 70px);
    }
    h3 {
      @media #{$medium-only} {
        font-size: 35px;
      }
    }
    .giftcard-balance__copy {
      @media #{$medium-up} {
        margin-bottom: 20px;
        padding-#{$ldirection}: 10px;
      }
    }
    .form-item {
      @media #{$medium-up} {
        margin-bottom: 10px;
        padding-#{$ldirection}: 10px;
      }
    }
    .add-progress {
      @media #{$medium-up} {
        padding-#{$ldirection}: 10px;
      }
    }
    .error_messages li {
      text-align: $ldirection;
      margin-bottom: 15px;
      @media #{$medium-up} {
        padding-#{$ldirection}: 10px;
      }
    }
  }
}
