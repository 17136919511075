.dw__foundation__sampling__footer__widget {
  .olapic-widget {
    &__header {
      margin-bottom: 10px;
    }
    &__subhead {
      @media #{$medium-up} {
        margin-bottom: 10px;
      }
    }
    &__heading {
      @media #{$medium-up} {
        font-size: 50px;
      }
      font-size: 45px;
      letter-spacing: 0;
    }
  }
}
