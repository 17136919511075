@mixin cart-text {
  font-size: 12px;
  color: $color-navy;
}

@mixin cart-header {
  font-size: 14px;
  line-height: 18px;
  color: $color-navy;
}

.cart-confirm {
  @include cart-text;
  display: none;
  position: absolute;
  top: $header-item-height;
  #{$rdirection}: 0px;
  #{$ie8} & {
    #{$rdirection}: 19px;
  }
  width: 255px;
  padding-top: 13px;
  z-index: 9000;
  &__engrave-container {
    clear: both;
    width: 100%;
    margin-top: 10px;
    border-top: solid 1px $color-light-gray;
    padding-top: 10px;
    &--desc {
      overflow: auto;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-size: 11px;
    }
    &--label,
    &--price {
      float: #{$ldirection};
      width: 50%;
      text-align: right;
    }
    &--label {
      text-align: left;
      .engraving {
        display: inline-block;
      }
      .monogramming {
        display: none;
      }
      #{$aerin} & {
        .engraving {
          display: none;
        }
        .monogramming {
          display: inline-block;
        }
      }
    }
    &--copy {
      padding: 10px;
      text-align: center;
      font-size: 20px;
      font-family: $optimaregular;
      line-height: 1;
      background: $color-gray-cs-side;
      font-weight: normal;
      @include fontname--Monog-Engr;
      &.Interlock_M_Script_aBc,
      &.Interlock_M_Script_abc,
      &.Optima {
        -webkit-text-stroke: 0.5px $color-black;
      }
    }
  }
}

.cart-confirm__carrot {
  background-size: auto auto;
  background-position: 0 -656px;
  position: absolute;
  width: 22px;
  height: 14px;
  top: 0;
  #{$rdirection}: 10px;
  #{$ie8} & {
    #{$rdirection}: 34px;
  }
}

.cart-confirm__content {
  width: 253px;
  @include swap_direction(padding, 20px 20px 20px 20px);
  @include swap_direction(border, 1px solid $color-medium-gray);
  background: $color-white;
}

.cart-confirm__kit-name {
  text-align: center;
  font-size: 22px;
  margin-bottom: 13px;
}

.cart-confirm__image-container {
  display: block;
  width: 96px;
}

.cart-confirm__image {
  float: $ldirection;
  width: 50px;
  height: 80px;
  background: $color-light-gray;
}

.cart-confirm__description {
  float: $ldirection;
  width: 150px;
  padding-#{$ldirection}: 10px;
}

.cart-confirm__header,
.cart-confirm__sub-header {
  @include cart-header;
}

.cart-confirm__shade-swatch {
  width: 18px;
  height: 18px;
  float: $ldirection;
  @include swap_direction(border-radius, 15px);
  margin-#{$rdirection}: 5px;
}

.cart-confirm__description .cart-confirm__shade {
  height: auto;
  width: auto;
  @include swap_direction(margin, 5px 0);
}

.cart-confirm__description .cart-confirm__shade .swatch__container {
  @include swap_direction(margin, 0px);
}

.cart-confirm__shade-button {
  clear: both;
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

.wishlist-confirm__container {
  text-align: center;
}

.wishlist-confirm__message {
  font-family: $optimalight;
  line-height: 110%;
  @include swap_direction(margin, 20px 20px 0 20px);
  @media #{$small-only} {
    @include swap_direction(margin, 10px 20px 0 20px);
  }
}

.wishlist-confirm__button-view {
  @include swap_direction(margin, 10px 0);
}

.wishlist-confirm__button-close {
  @include link--bold;
  display: block;
}

.colorbox__wishlist-confirm {
  #cboxClose {
    top: 10px;
    #{$rdirection}: 10px;
  }
  #cboxContent {
    @include swap_direction(padding, 25px 0);
  }
}

.offer_confirm {
  &__header {
    div {
      font-size: 20px;
      font-family: $optimaregular;
      text-transform: none;
    }
    font-family: $akzidenz;
    font-size: 15px;
    letter-spacing: 0.05em;
    text-transform: lowercase;
    .success_msg {
      font-family: $optimaregular;
      font-size: 20px;
      &_content {
        font-size: 15px;
        font-family: $akzidenz;
        letter-spacing: 0.05em;
        margin-top: 10px;
        strong {
          font-size: 12px;
          letter-spacing: 0.15em;
          text-transform: uppercase;
        }
      }
    }
  }
  &__applied {
    font-family: $akzidenz;
    font-size: 15px;
    letter-spacing: 0.05em;
    margin-top: 14px;
    text-transform: capitalize;
    strong {
      font-size: 12px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
    }
  }
  &__button {
    margin-top: 20px;
    width: 100%;
  }
}

.cart-confirm__kit-item-name {
  font-weight: bold;
}

.cart-confirm__kit-price {
  float: $rdirection;
  width: 150px;
  padding-#{$ldirection}: 10px;
}

.cart-confirm__kit-sub-items {
  float: $rdirection;
}

.cart-confirm__custom-kit-name {
  text-align: $ldirection;
  font-size: 15px;
  margin-bottom: 13px;
  font-family: $optimaregular;
  letter-spacing: 0;
  padding-#{$ldirection}: 70px;
}

.cart-confirm__custom-kit-img {
  height: 55px;
}
