.extended-mpp {
  .mpp__product {
    .product__inventory-status {
      margin-top: 5px;
    }
    .product__inventory-status {
      li {
        font-size: 10px;
      }
    }
    .product_brief__buttons {
      &.product_brief__buttons--shaded {
        width: 100%;
        @include swap_direction(margin, 20 auto 0);
        @media #{$product-normal-only} {
          margin-#{$ldirection}: 0;
        }
        //@include swap_direction(margin, 20px auto 0 auto);
      }
      &.product_brief__buttons--non-shaded {
        width: 100%;
        @include swap_direction(margin, 20 auto 0);
        @media #{$product-normal-only} {
          margin-#{$ldirection}: 0;
        }
        //@include swap_direction(margin, 20px auto 0 auto);
      }
    }
    .product_brief__button--add-to-bag {
      background-color: inherit;
      color: $color-navy;
      display: inline-block;
      float: none;
      @media #{$product-normal-only} {
        margin-#{$ldirection}: 0;
        margin-top: 5px;
      }
    }
    .product_brief__sub-panel-buttons-container {
      width: 208px;
    }
    .product_brief__button-panel.button-color-picker {
      background: none;
      height: 33px;
      line-height: 33px;
      width: 132px;
      @include swap_direction(border, 1px solid $extended_mpp_black_pearl);
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      float: none;
      display: inline-block;
      //margin-#{$ldirection}: 0;
      margin-#{$ldirection}: 0;
    }
    .product_brief__buttons--non-shaded .product_brief__button--benefits {
      background: none;
      height: 33px;
      line-height: 33px;
      width: 132px;
      @include swap_direction(border, 1px solid $extended_mpp_black_pearl);
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      float: none;
      display: inline-block;
      // margin-#{$ldirection}: 0;
      margin-#{$ldirection}: 0;
    }
    .product_brief__sub-panel--shaded .product_brief__shadename {
      display: none;
    }
    .product_brief__image-container.is-open {
      .product_brief__image {
        height: 412px;
      }
      ~ .product_brief__description .product_brief__headers {
        padding-top: 40px;
      }
    }
    .product_brief__image-container .product_brief__image {
      height: auto;
      width: 100%;
    }
    .product_brief__headers .product_brief__shadename {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
      padding-#{$ldirection}: 32px;
      &.sized {
        padding-#{$ldirection}: 0;
      }
    }
    .rollover_plus {
      font-family: 'optimadisplaylight';
      position: absolute;
      #{$rdirection}: 12px;
      top: 362px;
      cursor: pointer;
      @media #{$product-normal-only} {
        #{$rdirection}: 6px;
        top: 266px;
      }
    }
    .rollover_minus {
      font-family: 'optimadisplaylight';
      position: absolute;
      #{$rdirection}: 12px;
      top: 362px;
      display: none;
      @media #{$product-normal-only} {
        #{$rdirection}: 6px;
        top: 266px;
      }
    }
    .product_brief ul.shade-list {
      height: auto;
    }
    .product_brief__image.loader {
      width: auto;
      height: auto;
    }
    .product_brief__buttons-container {
      bottom: 10px;
    }
    .product_brief__container {
      display: inline-block;
      line-height: 22px;
      max-width: 80%;
      min-height: 22px;
      text-align: $ldirection;
      margin-top: 5px;
      .sku-brief-editorial__swatch-container {
        position: absolute;
      }
      .swatch__container {
        @include swap_direction(border-radius, 11px);
        height: 22px;
        @include swap_direction(margin, 3px 0 0 3px);
        overflow: hidden;
        position: relative;
        width: 22px;
        z-index: 6;
        .swatch--1 {
          @include swap_direction(border-radius, 11px);
          height: 22px;
          #{$ldirection}: 0;
          top: 0;
          width: 22px;
          position: absolute;
          z-index: 1;
        }
      }
    }
  }
}

.extended-mpp-qs {
  .quickshop__price-text .quickshop__size-text {
    float: $ldirection;
  }
  .selectBox.quickshop__skintype-select {
    margin-top: 11px;
    width: 328px;
  }
}
