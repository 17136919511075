///
/// @file components/header/_page_navigation.scss
///
/// \brief Nav menu in header
///
/// Styleguide pg.21
///
/// We can't really BEM this due to Drupal menu markup, no biggie
/// "Why does everything include a .page-navigation &{}"
/// This allows us to think about each component first, without having
///

$nav-adjust: 38px;
$nav-container-margin: sprites-sprite-width('elc-logo-small') + $header-item-padding;

@mixin nav-fall-below() {
  margin-top: $nav-adjust;
  margin-#{$ldirection}: 0;
  margin-#{$rdirection}: 0;
  width: 100%;
  #{$ie8} & {
    width: 85%;
    margin-#{$ldirection}: 210px;
  }
}

@mixin nav-pull-above() {
  margin-top: -$nav-adjust; // move navigation up
}

///
/// .page-navigation parent styles
///
.page-navigation {
  width: auto;
  display: none; // starts hidden up to > ipad landscape
  // center inline lists
  text-align: center;
  @include nav-fall-below;
  // above iphone portrait
  @media #{$xlarge-up} {
    @include nav-pull-above;
    cursor: default;
  }
  @media #{$medium-down} {
    margin-top: 0;
  }
  // // Show when in alternate state (open menu)
  #{$alt} & {
    display: block;
    background-color: $color-navy;
    //   // all our text will be light on dark in this state, smooth it
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  // above ipad / android tablets portrait
  @media #{$device-tablet-up} {
  }
  // after ipad landscape
  @media #{$xlarge-up} {
    display: block; // show by default
  }
  #{$min-nav} &,
  .elc-gnav-minimal & {
    display: none;
  }
  // Wider than mobile and tall
  @media #{$medium-up-tall} {
    // On the front page (or other landing pages), when not sticky
    #{$landing} .page-header:not(#{$sticky}) & {
      // When the header is not sticky
      @include nav-fall-below;
    }
  } // @media

  ///
  /// RESETS
  ///

  // Prevents IE8 from putting an outline around menu items after a click. Not sure why `.level-3` is needed on top of `a`, but it is. More info: https://jira.phase2technology.com/browse/ELB-676
  .menu-item-container,
  .level-3,
  a {
    outline-style: none;
    @media #{$large-down} {
      display: block;
    }
  }

  // drupal hover navs get in the way here
  .contextual-links-region {
    position: static;
  }
} // .page-navigation

///
/// Wrapper around each menu silo
///
.menu-reference {
  .page-navigation & {
    vertical-align: top;

    // up to after iphone
    @media #{$device-tablet-up} {
      @include inline-block;
      @include swap_direction(margin, 0 8px);

      // Front gets a little more spacing, let's ipad portrait fit
      // #{$landing} .page-navigation .menu-reference
      #{$landing} & {
        @include swap_direction(margin, 0 12px);
      }
    }

    // slightly wider space, after ipad portrait
    @media #{$device-tablet-up} {
      #{$landing} & {
        @include swap_direction(margin, 0 12px);
      }
    }
    &:first-child {
      margin-#{$ldirection}: 0;
    }
    &:last-child {
      margin-#{$rdirection}: 0;
    }
  }
  &.menu-reference--signin {
    height: 5em;
  }
}
///
/// Menu Level 0: Top level, visible inline menu
/// "Makeup, skincare, fragrance, renutriv, airin, etc"
///
.level-1 {
  .page-navigation & {
    @include text--short--plain;
    background-size: auto auto;
    background-position: 100% -6835px;
    color: $color-navy;
    font-weight: bold;

    #{$aerin} & {
      color: $color-light-gray--aerin;
    }

    #{$renutriv} & {
      color: $color-white;
    }

    // cheat a little, mobile only
    @media #{$device-tablet-down} {
      // avoiding extends here
      @include print-size-tracking(22px);
      font-family: $optimaregular;
      text-transform: none;
      color: $color-periwinkle-blue;
      display: block;
      @include swap_direction(padding, 15px 25px);
      border-top: 1px solid rgba($color-periwinkle-blue, 0.5);
    }

    // remove the menu hamburger after mobile
    @media #{$device-tablet-up} {
      background-image: none;
      #{$alt} & {
        color: $color-periwinkle-blue;
        #{$aerin} & {
          font-weight: bold;
        }
      }
    }
  }
  .page-navigation #{$active} & {
    background-size: auto auto;
    background-position: 0 -6785px;
    color: $color-white;
    @media #{$small-only} {
      text-align: center;
    }
    @media #{$device-tablet-up} {
      background-image: none;
    }
  }
}
// dark nav version
#{$dark-nav} .page-navigation .level-1 {
  @media #{$medium-up} {
    color: $color-periwinkle-blue;
  }
}

///
/// Menu Level 1: Sub menu wrapper
/// Makeup + .depth-1
///
.depth-1 {
  .page-navigation & {
    display: none; // starting state hidden
    max-width: $max-width;
    @include swap_direction(margin, 0 auto);

    // each submenu title
    h3 {
      @include headline--quinary--plain; // base/_typography.scss
      // actually only need for mobile, undo at medium-up below
      background-size: auto auto;
      background-position: 100% -6835px;

      // mobile menu sub titles
      @media #{$device-tablet-down} {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 15px 25px);
        color: $color-periwinkle-blue;
        border-top: 1px solid rgba($color-periwinkle-blue, 0.5);
        background-color: #111940;
      }

      // remove arrow of medium-up
      @media #{$medium-up} {
        color: $color-white;
        background-image: none;
      }
    }
  } // .page-navigation .depth-1
  .page-navigation #{$active} & {
    @media #{$device-tablet-down} {
      max-width: $max-width; // vars
      display: block;
    }
  }
} // .depth-1

///
/// Full links with no subs
///
.level-2 {
  .page-navigation & {
    @include text--short--plain;
    @include inline-block;
    color: $color-periwinkle-blue;
    line-height: 18px;
    @include swap_direction(margin, 10px 0);
    @media #{$device-tablet-down} {
      display: block;
      @include swap_direction(margin, 0);
      color: $color-periwinkle-blue;
      h3 {
        text-transform: none;
      }
    }
  }
}

///
/// Menu Level 2:
/// Makeup > Face
///

// Subnav columns should dynamicaly width. See main-shared.js for classes
.menu-item-container {
  .page-navigation & {
    // after mobile
    @media #{$device-tablet-up} {
      @include inline-block;
      vertical-align: top;
      @include swap_direction(padding, 0 5px); // squish for smaller
    }
    @media #{$device-tablet-up} {
      @include swap_direction(padding, 0 20px); // more space for larger
    }
    // specific for makeup menu iPad Landscape
    @media #{$large-only} {
      @media #{$landscape} {
        .menu-item-container--childcount-6 {
          @include swap_direction(padding, 0 0px);
        }
      }
    }
    @media #{$large-only} {
      @media #{$landscape} {
        .menu-item-container--childcount-3 {
          @include swap_direction(padding, 0);
        }
      }
    }
    // specific for makeup menu galaxy tab Landscape
    @media #{$device-tablet} {
      @media #{$landscape} {
        .menu-item-container--childcount-6 {
          @include swap_direction(padding, 0 0px);
        }
      }
    }
  }
}

// basic nav column counts/widths
@for $nav-col-count from 1 through 12 {
  .menu-item-container--childcount-#{$nav-col-count} {
    max-width: 100% / $nav-col-count;
  }
}
// really specific fix for the fragrance menu, 6 items
.menu-item-container--childcount-6 {
  width: (1 / 6) * 100%;
  // really specific fix for the makeup menu iPad landscape / galaxy tab, to account for 5 sub-columns and iPad / galaxy tab landscape only
  @media #{$large-only} {
    @media #{$landscape} {
      max-width: 12%;
    }
  }
  @media #{$device-tablet} {
    @media #{$landscape} {
      max-width: 12%;
    }
  }
}

.menu-item-container--childcount-5.first {
  @media #{$large-up} {
    max-width: 13%;
  }
}

// really specific fix for the makeup menu galaxy tab landscape / iPad landscape, extending last column only and adjusting column
.menu-item-container--childcount-6:nth-of-type(6) {
  @media #{$large-only} {
    @media #{$landscape} {
      max-width: 360px;
      margin-#{$ldirection}: 25px;
    }
  }
  @media #{$device-tablet} {
    @media #{$landscape} {
      max-width: 360px;
      margin-#{$ldirection}: 25px;
    }
  }
}

.menu-item-container--childcount-6:nth-of-type(6) .menu-container.depth-2 .menu.menu-column {
  @media #{$large-only} {
    @media #{$landscape} {
      margin-#{$ldirection}: -10px;
    }
  }
  @media #{$device-tablet} {
    @media #{$landscape} {
      margin-#{$ldirection}: -10px;
    }
  }
}

.menu-item-container--childcount-3 .menu-container.depth-2 .menu.menu-column {
  @media #{$large-only} {
    @media #{$landscape} {
      max-width: 150px;
    }
  }
}

.menu-item-container--childcount-6:nth-of-type(6) .menu-container.depth-2 .menu {
  @media #{$large-only} {
    @media #{$landscape} {
      padding-#{$rdirection}: 5px;
    }
  }
  @media #{$device-tablet} {
    @media #{$landscape} {
      padding-#{$rdirection}: 5px;
    }
  }
}

.menu-item-container--childcount-6:nth-of-type(3),
.menu-item-container--childcount-6:nth-of-type(4) {
  @media #{$large-only} {
    @media #{$landscape} {
      max-width: 100px;
    }
  }
  @media #{$device-tablet} {
    @media #{$landscape} {
      max-width: 100px;
    }
  }
}

.menu-item-container--childcount-6:nth-of-type(1),
.menu-item-container--childcount-6:nth-of-type(2),
.menu-item-container--childcount-6:nth-of-type(3),
.menu-item-container--childcount-6:nth-of-type(5) {
  @media #{$large-only} {
    @media #{$landscape} {
      margin-#{$ldirection}: 10px;
    }
  }
  @media #{$device-tablet} {
    @media #{$landscape} {
      margin-#{$ldirection}: 10px;
    }
  }
} // really specific fix for the makeup menu iPad / galaxy tab
// Makeup > Face > .depth-2
.depth-2 {
  .page-navigation & {
    @media #{$device-tablet-down} {
      display: none;
    }
  }
  .page-navigation #{$active} > & {
    display: block;
  }
}
// Makeup > Face > menu > Foundation
.level-3 {
  .page-navigation & {
    @include text--short--plain;
    @include inline-block;
    color: $color-periwinkle-blue;
    line-height: 18px;
    @include swap_direction(margin, 10px 0);
    @media #{$small-only} {
      display: block;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 15px 25px);
      color: $color-periwinkle-blue;
    }
    // adjustments for iPad mini
    @media #{$device-ipad_mini} {
      @include swap_direction(margin, 5px 0);
    }
    // adjustments for galaxy tab landscape
    @media #{$device-tablet} {
      @media #{$landscape} {
        @include swap_direction(margin, 8px 0);
      }
    }
    // adjustments for iPad tab landscape
    @media #{$large-only} {
      @media #{$landscape} {
        @include swap_direction(margin, 4px 0);
      }
    }
  }
}

///
///  Hot swap region for dynamic menu
///
.page-navigation__hotswap {
  // Hide for mobile
  display: none;
  text-align: $ldirection;
  @include swap_direction(margin, 0 $mobile-outer-gutter);
  // Then show above mobile
  @media #{$device-tablet-up} {
    #{$alt} & {
      display: block;
      padding-top: 45px;
      @include transition(opacity 300ms ease);
      opacity: 0;
    }
  }
  // adjustments for iPad mini
  @media #{$device-ipad_mini} {
    #{$alt} & {
      padding-top: 25px;
    }
  }
  li.menu-item-container--childcount-3 {
    @media #{$xlarge-up} {
      max-width: 50%;
    }
    // really specific iPad landscape, to account for 5 sub-columns
    @media #{$large-only} {
      @media #{$landscape} {
        max-width: 40%;
        padding-#{$rdirection}: 10px;
      }
    }
    // really specific galaxy tab landscape, to account for 5 sub-columns
    @media #{$device-tablet} {
      @media #{$landscape} {
        max-width: 40%;
        padding-#{$rdirection}: 10px;
      }
    }
  }

  // entire wrapper above subnav
  .depth-1 {
    text-align: center; // center inline block columns below
    display: block;
    @media #{$medium-up} {
      padding-bottom: 50px;
      &.menu-container--has-nav-tout {
        padding-#{$ldirection}: 577px;
        padding-top: 76px;
        position: relative;
        text-align: $ldirection;
        width: 713px;
        @include swap_direction(margin, 0 auto);
        .nav-tout {
          position: absolute;
          top: 60px;
          #{$ldirection}: 0;
        }
        .level-2 h3 {
          color: $color-periwinkle-blue;
          font-size: 12px;
          font-family: $akzidenz;
          letter-spacing: 0.15em;
        }
      }
    }
    //Specific iPad android landscape navigation adjustments
    &:not(.menu-container--has-nav-tout) {
      @media #{$large-only} {
        @media #{$landscape} {
          padding-bottom: 160px;
        }
      }
      @media #{$device-tablet} {
        @media #{$landscape} {
          padding-bottom: 170px;
        }
      }
    } //
  }
  // nav columns
  //  @comp: 20140104_EL_NAV.psd
  .depth-2 {
    margin-top: 30px;
    text-align: $ldirection;
    // adjustments for iPad mini
    @media #{$device-ipad_mini} {
      margin-top: 10px;
    }
    // specific iPad tab landscape
    @media #{$large-only} {
      @media #{$landscape} {
        margin-top: 8px;
      }
    }
    // specific galaxy tab landscape
    @media #{$device-tablet} {
      @media #{$landscape} {
        margin-top: 10px;
      }
    }
    // Side-by-side menus
    .menu {
      @include inline-block;
      vertical-align: top;
      max-width: 200px;
      @media #{$medium-up} {
        padding-#{$rdirection}: 20px;
      }
    }
  }
  h3 {
    text-align: $ldirection;
  }
} // page-navigation__hotswap

.page-navigation.is_animated {
  // Hover state for navigation elements with no menu-trigger
  .level-1.has-hover,
  .level-3.has-hover,
  .level-2 h3.has-hover {
    color: $color-white;
  }
  .page-navigation__hotswap {
    @media #{$medium-up} {
      #{$alt} & {
        opacity: 1;
      }
    }
  }
}

///
/// Mobile menu toggle
///
.page-navigation__menu-toggle {
  text-align: center;
  width: 38px;
  height: $header-item-height;
  position: absolute;
  #{$ldirection}: 0;
  top: 0;
  z-index: 30;
  @media #{$small-only} {
    height: 54px;
  }
  #{$aerin} & {
    border-color: $color-header-border-aerin;
  }

  // wider after phones
  @media #{$medium-up} {
    width: $menu-toggle-width;
    border-#{$rdirection}: 1px solid $color-light-gray;
    #{$dark-nav} & {
      color: $color-white;
      background-color: $color-navy;
    }
  }

  // always show the X in alt state
  #{$alt} & {
    display: block;
    color: $color-white;
    border-color: $color-periwinkle-blue;
    background-color: $color-navy;
    #{$aerin} & {
      border-color: rgba($color-periwinkle-blue, 0.5);
    }
  }

  #{$search} & {
    @media #{$medium-up} {
      display: none;
    }
  }

  // after ipad landscape, when alternate
  @media #{$xlarge-up} {
    display: none;
    #{$alt}:not(#{$search}) & {
      display: block;
      #{$ldirection}: auto;
      #{$rdirection}: 0;
      top: 80px;
    }
    #{$alt}#{$alt-color-white}:not(#{$search}) & {
      display: block;
    }
  }

  #{$min-nav} &,
  .elc-gnav-minimal & {
    display: none;
  }
}

.mobile-checkout-back {
  @media #{$medium-up} {
    display: none;
  }
}

.page-navigation__menu-icon,
.brand-aerin .page-navigation__menu-icon {
  display: inline-block;
  background: url($svg-path + 'menu.svg') no-repeat;
  background-size: auto auto;
  width: 20px;
  height: 20px;
}

.page-navigation__menu_text {
  @include text--short--plain;
  display: none;
  width: 100%;
  color: $color-dark-navy;

  #{$aerin} & {
    color: $color-light-gray--aerin;
  }

  #{$renutriv} & {
    color: $color-white;
  }

  #{$alt} & {
    color: $color-white;
  }
  &#{$alt} {
    display: none;
  }

  // hide text for mobile
  @media #{$medium-up} {
    display: block;
    #{$dark-nav} & {
      color: $color-white;
    }
  }
  @media #{$xlarge-up} {
    display: none;
  }
}

// Hide carousel area on short screens
.page-navigation__supplemental {
  // specific request to hide this when on a short screen
  @media #{$screen} and (max-height: #{lower-bound($nav-tall-range)}) {
    display: none;
  }

  // lock navigation touts to bottom of screen iPad Landscape only
  @media #{$large-only} {
    @media #{$landscape} {
      width: 100%;
      position: fixed;
      bottom: $sticky-footer-height;
    }
  }
  // lock navigation touts to bottom of screen galaxy tab Landscape only
  @media #{$device-tablet} {
    @media #{$landscape} {
      width: 100%;
      position: fixed;
      bottom: $sticky-footer-height;
    }
  }
  //Specific iPad portrait only
  @media #{$medium-only} {
    @media #{$portrait} {
      display: none;
    }
  }
  //Specific android tablet portrait only
  @media #{$device-tablet} {
    @media #{$portrait} {
      display: none;
    }
  }
}

.nav-tout {
  display: none;
  @media #{$device-tablet-up} {
    display: block;
    img {
      max-width: 100%;
    }
  }
}

.menu-container--has-nav-tout {
}

.page-header.is-sticky.mobile_sticky,
.page-sticky-footer.is-sticky.mobile_sticky {
  position: absolute;
}

.page-wrapper-checkout {
  .page-header.is-sticky {
    z-index: 9999;
    @media #{$medium-up} {
      z-index: 10;
    }
  }
  .sd-search-gnav-input-field
    .elc-search-active-screen-wrapper
    .elc-search-gnav-box
    .elc-search-results-button {
    bottom: 0;
  }
  .sd-search-gnav-input-field .elc-search-active-screen-wrapper .elc-search-results-wrapper {
    margin-bottom: 80px;
  }
}

.global-header-navigation-elc-nodeblock {
  @media #{$small-only} {
    #{$ios4} & {
      position: absolute;
      z-index: 100;
      top: 105px;
      bottom: 48px;
      #{$ldirection}: 0;
      width: 100%;
      height: 280px;
      overflow: hidden;
    }
  }
}

.global-header-navigation-elc-nodeblock .content {
  @media #{$small-only} {
    #{$ios4} & {
      position: absolute;
      z-index: 100;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      width: 100%;
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -o-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-text-size-adjust: none;
      -moz-text-size-adjust: none;
      -ms-text-size-adjust: none;
      -o-text-size-adjust: none;
      text-size-adjust: none;
    }
  }
}

.global-header-navigation-elc-nodeblock .content a {
  @media #{$small-only} {
    #{$ios4} & {
      z-index: 999;
    }
  }
}

// FE-959 ReNutriv menu image tout

.page-navigation__hotswap .depth-1.menu-container--has-nav-tout.nav-tout-3-cols {
  padding-#{$ldirection}: 0;
  padding-top: 0;
  ul.menu {
    float: none;
    width: 900px;
    li.menu-item-container--childcount-3 {
      max-width: 45%;
    }
  }
  .nav-tout--3-cols.nav-tout {
    margin-top: 6px;
    position: static;
    img {
      max-width: 483px;
      width: 100%;
    }
  }
}
