// Facebook divider
@mixin facebook_divider {
  margin: 0 auto 20px;
  font-family: $optimalight;
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 0 33px;
  &:before {
    content: '';
    background: $color-black;
    width: 100%;
    height: 2px;
    display: inline-block;
    position: absolute;
    top: 43%;
    #{$ldirection}: 0;
  }
  .inner,
  &__inner {
    @include swap_direction(padding, 0 7px 0 9px);
    background: $color-white;
    display: inline-block;
    position: relative;
  }
  .social-login__word,
  .social-login-combined__word {
    background: $color-white;
    display: inline-block;
    position: relative;
    padding: 5px;
  }
}

//Login-page
.social-login {
  clear: both;
  &__container {
    text-align: center;
    padding: 20px 0;
  }
  &__divider {
    @include facebook_divider;
    width: 84%;
  }
  &__terms {
    margin-top: 15px;
    padding: 0 20px;
    p {
      margin: 0;
    }
    a {
      text-decoration: underline;
    }
  }
  &__title,
  &__info {
    display: none;
  }
  &__title {
    font-family: $optimalight;
    font-size: 40px;
    text-transform: capitalize;
  }
  &__info {
    margin-bottom: 15px;
  }
  .tooltip {
    display: none;
  }
}
/* GNAV styling */
#cboxLoadedContent {
  .social-login.gnav {
    margin-#{$rdirection}: 100px;
  }
  .social-login {
    &__container {
      padding: 20px 0;
      text-align: center;
      @media #{$medium-up} {
        text-align: $ldirection;
      }
    }
    &__divider {
      width: 86%;
    }
    &__terms {
      padding-#{$ldirection}: 0;
    }
  }
}
/* Disconnect button styling */
#vkontakte_disconnect,
#facebook_disconnect {
  display: inline;
  padding-#{$ldirection}: 22px;
  input.form-submit {
    height: 20px;
    margin-bottom: 4px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;
  }
}

#vkontakte_disconnect {
  padding-#{$ldirection}: 19px;
}
/* Order confirmation page */
.checkout-confirmation-page {
  .checkout__content {
    .social-login {
      padding: 18px 24px;
      @media #{$medium-up} {
        padding: 0;
      }
      &__container {
        text-align: $ldirection;
        padding-bottom: 0;
        margin-bottom: 20px;
      }
      &__terms,
      &__divider {
        display: none;
      }
      &__title {
        display: block;
        font-size: 24px;
        font-family: $optimaregular;
        text-transform: capitalize;
        letter-spacing: 0;
      }
      &__info {
        margin-bottom: 10px;
        display: block;
      }
    }
  }
}

.confirmation-panel,
#confirm {
  .social-login {
    padding: 18px 24px;
    @media #{$medium-up} {
      padding: 18px 24px;
    }
    &__container {
      text-align: $ldirection;
      padding-bottom: 0;
      margin-bottom: 20px;
    }
    &__terms,
    &__divider {
      display: none;
    }
    &__title {
      display: block;
      font-size: 18px;
      font-family: $optimaregular;
      text-transform: capitalize;
      letter-spacing: 0;
      @media #{$medium-up} {
        font-size: 24px;
      }
    }
  }
}
/* Social info section on Account preferences page */
.social-info {
  border-top: 2px solid $color-light-gray;
  padding: 18px 25px 18px;
  @media #{$medium-up} {
    margin-#{$ldirection}: 30%;
    padding: 0 20px;
    border-top: none;
  }
  @media #{$large-up} {
    margin-#{$ldirection}: 25%;
  }
  @media #{$xlarge-up} {
    margin-#{$ldirection}: 20%;
  }
  &__connect {
    margin-bottom: 30px;
  }
  &__header {
    font-family: $optimaregular;
    font-size: 20px;
    letter-spacing: 0;
    margin-bottom: 8px;
    @media #{$medium-up} {
      font-family: $optimalight;
      padding-bottom: 16px;
      border-bottom: solid 1px $color-light-gray;
      margin-bottom: 20px;
      font-size: 30px;
      letter-spacing: 0px;
    }
  }
  &__content p {
    margin-bottom: 30px;
    letter-spacing: 0.75px;
    font-size: 12px;
    font-family: $akzidenz;
  }
}
/* Connected Status Styling */
.account-profile__connected-accounts {
  text-transform: uppercase;
  font-size: 12px;
}
