/*
* CUSTOM STYLES FOR THE FFP 3 MINUTE BEAUTY LANDING PAGE
*/
h1.ffp_header {
  position: absolute;
  z-index: 1;
  #{$ldirection}: 291px;
}

.tmb_content {
  position: absolute;
  a {
    padding-#{$ldirection}: 45%;
  }
  h4 {
    padding-top: 9px;
    text-align: center;
  }
  p {
    text-align: center;
  }
}

/*
*  BEGIN Content blocks for Tips pages
*/
div#igim.tmb_content {
  max-width: 670px;
  #{$ldirection}: 15px;
  top: 155px;
}

div#ff.tmb_content {
  max-width: 400px;
  top: 898px;
  #{$ldirection}: 119px;
}

div#mw.tmb_content {
  max-width: 500px;
  top: 898px;
  #{$ldirection}: 744px;
}

div#hac.tmb_content {
  max-width: 670px;
  top: 1578px;
  #{$ldirection}: 744px;
}

div#lsu.tmb_content {
  max-width: 500px;
  top: 1750px;
  #{$ldirection}: 185px;
}

div#xos.tmb_content {
  max-width: 440px;
  top: 2280px;
  #{$ldirection}: 890px;
}
/* Don't let images shrink to thumbnail size on resize */
@media (max-width: 900px) {
  .tmb_content > img {
    min-width: 300px;
  }
  div#right_top_signature.tmb_content img,
  div#left_bottom_signature.tmb_content img {
    min-width: 187px;
  }
  a.plus_sign img {
    min-width: 52px;
  }
  h1.ffp_header {
    font-size: 80px;
  }
}

.tmb_content > img {
  max-width: 100%;
  height: auto;
}
/*
* END Content blocks for tips pages
*/

/*
* BEGIN signature content block
*/
div#right_top_signature.tmb_content {
  width: 300px;
  #{$ldirection}: 715px;
  top: 235px;
}

div#left_bottom_signature.tmb_content {
  width: 300px;
  #{$ldirection}: 160px;
  top: 1550px;
}
/*
* END signature content block
*/
