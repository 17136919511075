$ff-gutter: 7%;
$ff-cols: 3;
$ff-gutter-total: $ff-cols * $ff-gutter;

.ff-results {
  @include swap_direction(margin, 0 auto);
  max-width: 860px;
  text-align: center;
  @media #{$medium-up} {
    @include swap_direction(padding, 0 30px);
  }
  .ff-results {
    &__regimen-steps,
    &__matches-sub-headline {
      display: none;
    }
  }
  @media #{$small-only} {
    .product_brief__desktop-only {
      display: none;
    }
    .ff-results__recommendations,
    .ff-results__headline--matches,
    .ff-results__matches-copy,
    .ff-results__matches-sub-headline,
    .ff-results__regimen-step {
      @include swap_direction(padding, 0 25px);
    }
    .ff-results__regimen-steps {
      padding-bottom: 35px;
    }
    .ff-results__recommendations,
    .ff-results__matches-copy {
      border-bottom: solid 1px $color-light-gray;
      .foundation-finder--quiz--v3 ~ & {
        border-bottom: 0;
      }
    }
    .ff-results__matches {
      padding-top: 35px;
    }
    .ff-results__matches-copy {
      padding-bottom: 35px;
    }
    .ff-results__matches-sub-headline {
      font-family: $optimaregular;
      font-size: 20px;
      letter-spacing: 0;
      margin-bottom: 8px;
      font-weight: normal;
      text-transform: none;
      padding-top: 15px;
    }
    .ff-results__regimen-step-headline {
      @include text--bold;
    }
    .button {
      width: 100%;
      height: 45px;
      line-height: 45px;
      margin-top: 30px;
    }
    .ff-results__matches-copy,
    .ff-results__matches-copy p {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.15em;
    }
    .ff-results__matches .ff-results__matches-copy p {
      @include swap_direction(margin, 0 auto);
      width: 245px;
    }
  }
  &.loading {
    visibility: hidden;
  }
  .ff-results__links {
    margin-bottom: 20px;
  }
  .ff-results__link-item {
    display: inline-block;
  }
  .elc-user-state-logged-in & .ff-results__link--save {
    display: none;
  }
  .ff-results__link--print {
    display: none;
  }
  .ff-results__count {
    font-size: 40px;
    letter-spacing: -0.02em;
    font-family: $optimalight;
  }
  &--v3 {
    .ff-results__links,
    .ff-results__count,
    .ff-results__matches,
    .ff-results__recommended-products {
      .foundation-finder--quiz--v3 ~ & {
        display: none;
      }
      .foundation-finder--quiz--v3.results-loaded ~ & {
        display: block;
      }
    }
    .foundation-finder--quiz--v3 ~ & {
      margin-bottom: 50px;
    }
    .foundation-finder--quiz--v3.results-loaded ~ & {
      margin-bottom: 0;
    }
    .ff-results__count {
      .foundation-finder--quiz--v3 ~ & {
        border: none;
        margin: 0;
      }
    }
    .slick-slider {
      touch-action: unset;
    }
  }
  .ff-results__matches-sub-headline {
    @include swap_direction(margin, 0 0 19px);
  }
  .ff-results__regimen-step-headline {
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0 0 10px);
    border-bottom: none;
  }
  .ff-results__matches-copy p {
    // Rich text
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
  }
  .ff-results__matches-copy a {
    @include link--bold;
    @media #{$small-only} {
      display: block;
      margin-top: 15px;
    }
  }
  .ff-results__regimen-step-instructions {
    @include text--short--plain;
    line-height: 2em;
    margin-bottom: 25px;
    a {
      @include link--bold;
    }
  }
  .ff-results__regimen-step-gutter {
    font-size: 50px;
    font-family: $optimalight;
    text-align: center;
    -webkit-font-smoothing: antialiased;
  }
  @media #{$medium-up} {
    .product_brief__mobile-only {
      display: none;
    }
    .ff-results__links {
      float: $rdirection;
    }
    &--v3 {
      .foundation-finder--quiz--v3 ~ & {
        .ff-results__links {
          float: none;
          .ff-results__link-item {
            margin: 0;
          }
        }
        .ff-results__matches-copy p {
          text-transform: none;
          font-size: 14px;
          strong {
            font-size: 12px;
          }
        }
      }
    }
    .ff-results__matches-copy p {
      // Rich text
      @include text--short--plain;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
    }
    .ff-results__link-item {
      @include swap_direction(margin, 0 0 0 25px);
    }
    .ff-results__recommended-products,
    .ff-results__regimen-steps {
      @include clearfix;
      clear: $rdirection;
    }
    .ff-results__recommended-product {
      @include swap_direction(margin, 0 0 50px);
      float: $ldirection;
      width: (100% / $ff-cols);
      padding-#{$ldirection}: $ff-gutter-total / ($ff-cols * 2);
      padding-#{$rdirection}: $ff-gutter-total / ($ff-cols * 2);

      &:first-child,
      &:nth-child(#{$ff-cols}n + 1) {
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: $ff-gutter-total / $ff-cols;
      }
      &:nth-child(#{$ff-cols}n) {
        padding-#{$ldirection}: $ff-gutter-total / $ff-cols;
        padding-#{$rdirection}: 0;
      }
    }
    .ff-results__regimen-step {
      @include swap_direction(margin, 0 0 50px);
      float: $ldirection;
      width: ((100% - $ff-gutter-total) / $ff-cols);
    }
    .ff-results__regimen-step-gutter {
      float: $ldirection;
      width: $ff-gutter-total / ($ff-cols - 1);
      height: 570px;
      padding-top: 270px;
    }
    .ff-results__link--print {
      display: inline;
    }
    .ff-results__matches-copy {
      @include swap_direction(margin, 0 auto 60px);
      @include text--short--plain;
      width: 480px;
      .foundation-finder--quiz--v3 ~ & {
        width: 100%;
      }
    }
    .ff-results__headline {
      font-size: 40px;
      letter-spacing: 0;
    }
    .ff-results__headline--matches {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0 0 16px);
      border-bottom: none;
    }
  }
  .product_brief__image {
    // Having the transition on all screws up equal heights in safari
    @include transition(none);
  }
  &__overlay {
    .ff-results__success-message,
    .ff-results__failure-message,
    .ff-noresult-message {
      @include swap_direction(padding, 0 45px 0 0);
    }
  }
  .slick-list {
    padding-#{$rdirection}: 12.5%;
    @media #{$medium-up} {
      padding-#{$rdirection}: 0;
    }
    .slick-slide {
      margin-#{$rdirection}: 20px;
      &:last-of-type {
        margin-#{$rdirection}: 0;
      }
      @media #{$medium-up} {
        margin: 0;
      }
    }
  }
  .slick-arrow {
    text-indent: -9999px;
    width: 50px;
    height: 90px;
    background-color: $color-medium-gray-opacic;
    top: calc(50% - 70px);
    z-index: 1;
    &.slick-disabled {
      opacity: 0;
    }
    &::before {
      content: '';
    }
  }
  .slick-next {
    #{$rdirection}: 0;
    background-image: url('/media/images/global/sprites-s38a9885fce.png');
    background-repeat: no-repeat;
    background-position: 10px -8575px;
  }
  .slick-prev {
    #{$ldirection}: 0;
    background-image: url('/media/images/global/sprites-s38a9885fce.png');
    background-repeat: no-repeat;
    background-position: 5px -8795px;
  }
}

// Note: This can appear in the beauty profile, not just the results page
.ff-results__product {
  text-align: center;
  margin-bottom: 35px;
  &.ff-results__regimen-product {
    margin-bottom: 0;
  }
  @media #{$medium-up} {
    margin-bottom: 0;
  }
  .product_brief__image-container,
  .product_brief__image,
  .sku-brief-editorial__image {
    width: 100%;
    // firefox ~v27 causes $(el).width to return '100' when an inline element is
    // set to width: 100%
    display: block;
    height: auto;
    position: static;
  }
  .sku-brief-editorial__reviews {
    width: auto;
    margin-bottom: 2px;
    .reviews-image {
      vertical-align: middle;
    }
    .foundation-finder--quiz--v3 ~ .ff-results--v3 & {
      display: none;
      &--v3 {
        display: block;
      }
    }
  }
  .sku-brief-editorial__size {
    font-weight: normal;
  }
  .sku-brief-editorial__size,
  .sku-brief-editorial__price {
    display: inline;
  }
  .sku-brief-editorial__shadename {
    @include headline--senary;
  }
  .sku-brief-editorial {
    height: 100%;
    position: relative;
    padding-bottom: 44px;
    .foundation-finder--quiz--v3 ~ .ff-results--v3 & {
      text-align: #{$ldirection};
      &:hover {
        .sku-brief-editorial__button-wrapper {
          display: block;
        }
      }
    }
    .sku-brief-editorial__button-wrapper {
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 0;
      .foundation-finder--quiz--v3 ~ .ff-results--v3 & {
        bottom: -10px;
        @media #{$medium-up} {
          bottom: unset;
          top: 25%;
          display: none;
        }
      }
      .button--dark-secondary {
        border: none;
      }
    }
    .text-product-listing-names-main,
    .text-product-listing-names-sub {
      margin-bottom: 2px;
    }
    .sku-brief-editorial__reviews-count {
      top: 2px;
    }
    &.sku-brief-editorial--multi-sku .sku-brief-editorial__shade {
      @include swap_direction(margin, 0 5px 2px);
      cursor: pointer;
      width: 15px;
      position: relative;
      &.active:before {
        @include swap_direction(border, 1px solid #e1c27c);
        border-radius: 50%;
        content: '';
        width: 25px;
        height: 25px;
        #{$ldirection}: -5px;
        top: -2px;
        position: absolute;
        .foundation-finder--quiz--v3 ~ .ff-results--v3 & {
          width: 26px;
          height: 26px;
          border-color: $color-navy;
          #{$ldirection}: -3px;
          top: -3px;
        }
      }
    }
    .sku-brief-editorial__shade {
      margin-bottom: 2px;
      .foundation-finder--quiz--v3 ~ .ff-results--v3 & {
        max-width: 100%;
      }
    }
    .sku-brief-editorial__shadename {
      text-transform: none;
      margin-bottom: 0;
      padding-#{$ldirection}: 23px;
      .foundation-finder--quiz--v3 ~ .ff-results--v3 & {
        clear: both;
        padding-#{$ldirection}: 28px;
      }
    }
    &.sku-brief-editorial--multi-sku .sku-brief-editorial__shadename {
      padding-#{$ldirection}: 0;
      text-align: center;
      .foundation-finder--quiz--v3 ~ .ff-results--v3 & {
        padding-#{$ldirection}: 0;
        font-size: 13px;
        text-align: #{$ldirection};
        @media #{$medium-up} {
          font-size: 14px;
        }
      }
    }
    &.sku-brief-editorial .sku-brief-editorial__shadename {
      .foundation-finder--quiz--v3 ~ .ff-results--v3 & {
        font-family: $akzidenz;
        font-size: 13px;
        letter-spacing: 1.9px;
        text-transform: uppercase;
      }
    }
    .sku-brief-editorial__size {
      text-transform: none;
      font-family: $optimaregular;
    }
    .sku-brief-editorial__swatch-container {
      .swatch__container {
        @include border-radius(50%);
        @include swap_direction(margin, 3px 0 0);
        width: 15px;
        height: 15px;
        .foundation-finder--quiz--v3 ~ .ff-results--v3 & {
          width: 20px;
          height: 20px;
          margin: 0;
        }
      }
    }
    .product_brief__desc1 {
      display: none;
      .foundation-finder--quiz--v3 ~ .ff-results--v3 & {
        display: block;
        letter-spacing: 0;
        height: auto;
        text-transform: none;
        padding: 7px 0;
        font-size: 14px;
      }
    }
  }
}

body.foundation-finder-page .chat-parallax {
  position: static;
  background-size: cover;
  -webkit-transform-style: flat;
  .chat-parallax__content {
    max-width: none; // IE doesn't apply margin auto correctly with max-width
  }
}

.ff-results__regimen-step-product {
  @media #{$medium-up} {
    padding-bottom: 86px;
  }
  position: relative;
  // Mobile doesn't inherit the styles we need from _pc_product_brief.scss so
  // including them here. They're pretty much copied straight out of that file,
  // since they use @extend a lot I'm keeping them out of a media query.
  .product_brief__sub-panel,
  .product_brief__sub-panel--shaded,
  .product_brief__sub-panel--non-shaded {
    display: none;
  }
  .product_brief__header {
    @include headline--quinary;
    @include swap_direction(padding, 20px 3px 0 3px);
  }
  .product_brief__button--quick-shop {
    margin-bottom: 15px;
    // No quickshop on mobile:
    @media #{$small-only} {
      display: none;
    }
  }
  .product_brief__sub-header {
    @include headline--senary;
    margin-top: 2px;
  }
  .product_brief__price {
    @include text--bold;
    margin-top: 8px;
    margin-bottom: 25px;
  }
}
