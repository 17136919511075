.dw__foundation__sampling__shades-selected {
  @media #{$xxlarge-up} {
    @include dw-foundation-sampling-layout;
    margin: 1% 6%;
  }
  @media #{$medium-up} {
    padding: 20px 9% 8px;
  }
  @media #{$device-ipad_mini} {
    padding: 0;
    margin: 22px;
  }
  margin: 25px 5.5%;
  &__title {
    @media #{$medium-only} {
      padding-#{$rdirection}: 3px;
    }
    color: $color-navy;
    font-size: 25px;
    padding-#{$rdirection}: 22px;
    &__container {
      @media #{$medium-up} {
        position: relative;
        bottom: 43px;
      }
      display: inline-block;
    }
  }
  &__shades {
    &__container {
      @media #{$medium-up} {
        margin: 0;
        display: inline-block;
        overflow: visible;
        width: 400px;
      }
      @media #{$medium-only} {
        width: 280px;
      }
      display: block;
      overflow: hidden;
    }
  }
  &__shade {
    &__value {
      @media #{$medium-up} {
        font-size: 11px;
        letter-spacing: 1.5px;
        height: 95px;
      }
      border: 1px solid $color-light-gray;
      width: 47%;
      height: 93px;
      text-align: center;
      display: table;
      color: $color-medium-gray-blue;
      letter-spacing: 2px;
      background-color: $color-medium-gray-new;
      font-size: 15px;
      &__1 {
        float: #{$ldirection};
      }
      &__2 {
        float: #{$rdirection};
      }
      &__description {
        @media #{$medium-up} {
          line-height: 1.4;
          background-size: 1600px;
        }
        background-size: 1500px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        background-repeat: no-repeat;
        background-position: center;
        .title {
          @media #{$medium-up} {
            font-size: 11px;
          }
          font-weight: bold;
          font-size: 15px;
          color: $color-navy;
        }
        .shade-value {
          @media #{$medium-up} {
            letter-spacing: 0;
            font-weight: normal;
            font-family: $optimaregular;
            padding: 2px 0;
          }
          line-height: 1.5;
          font-family: $akzidenz;
          font-size: 17px;
          color: $color-navy;
          font-weight: bold;
          p {
            @media #{$medium-up} {
              font-family: $optimaregular;
              font-size: 21px;
              font-weight: normal;
              letter-spacing: 0;
            }
            display: inline;
            margin: 0;
            font-weight: bold;
            font-size: 15px;
            letter-spacing: 1px;
          }
        }
        .remove {
          @media #{$medium-up} {
            line-height: 1.6;
            font-size: 11px;
          }
          text-transform: uppercase;
          font-size: 15px;
          text-decoration: underline;
          font-weight: bold;
          cursor: pointer;
          color: $color-navy;
        }
      }
    }
  }
  &__btn {
    &-container {
      @media #{$medium-up} {
        display: inline;
      }
      .add-to-bag {
        @media #{$medium-up} {
          display: inline-block;
          font-size: 11px;
          padding: 10px 18px;
          position: relative;
          bottom: 48px;
          margin-#{$ldirection}: 20px;
        }
        @media #{$medium-only} {
          margin-#{$ldirection}: 10px;
        }
        cursor: pointer;
        letter-spacing: 2px;
        font-size: 15px;
        color: $color-white;
        text-align: center;
        padding: 16px;
        background-color: $color-black;
        display: block;
        margin-top: 18px;
        &.button--disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }
      }
    }
  }
  .dw-fs-btn-loader {
    @include swap_direction(margin, 8% 0 0 45%);
    @media #{$medium-up} {
      @include swap_direction(margin, 0 0 0 8%);
      position: relative;
      bottom: 38px;
    }
  }
}
