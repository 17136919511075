/// Content pages
/// privacy policy
/// terms and conditions

/// privacy policy styles
.elb-privacy-policy,
.elb-terms-page {
  @include swap_direction(padding, 1em 2em);
  @include swap_direction(margin, 0 auto);
  color: $color-navy;
  max-width: 1024px;
  a {
    text-decoration: underline;
  }
}

.privacy-policy__toc {
  @include swap_direction(padding, 0 0 1em 2em);
}

.privacy-policy__toc-link {
  display: block;
  &.sub--1 {
    margin-#{$ldirection}: 1em;
  }
  &.sub--2 {
    margin-#{$ldirection}: 2em;
  }
  &.sub--3 {
    margin-#{$ldirection}: 3em;
  }
}

.pp-top {
  display: block;
  float: $rdirection;
}

.privacy-policy__columns {
  @include swap_direction(margin, 0 auto);
  @include column-count(3);
  width: 100%;
  margin-bottom: 20px;
  text-align: $ldirection;
  li {
    list-style-type: none;
    padding-#{$rdirection}: 2em;
  }
}

.pp__title {
  text-align: center;
  font-size: 70px;
}

.pp__headline {
  font-size: 50px;
}

.pp__headline--sub {
  font-size: 30px;
}

.pp__headline--sub-section {
  font-size: 20px;
  font-family: $akzidenz;
}

.pp__headline--sub-section-sub {
  font-size: 18px;
  font-family: $akzidenz;
}

.pp-list {
  padding-#{$ldirection}: 1em;
  li {
    list-style-position: outside;
  }
}
///
/// terms and conditions styles
///
.terms__title {
  text-align: center;
  font-size: 40px;
}

.terms-heading {
  font-family: $akzidenz;
  font-size: 15px;
  font-weight: bold;
}

.device-mobile .privacy-policy__columns {
  width: 100%;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
}

.loyalty-terms-grid {
  ol {
    counter-reset: item;
    padding-#{$ldirection}: 10px;
    @media #{$small-only} {
      padding-#{$ldirection}: 0;
    }
    ol ol li div.loyalty-content {
      margin-#{$ldirection}: 44px;
    }
    li ol li:before {
      font-weight: normal;
    }
  }
  li {
    display: block;
    @include swap_direction(margin, 15px);
    @media #{$small-only} {
      @include swap_direction(margin, 15px 0);
    }
    &:before {
      content: counters(item, '.') '.';
      counter-increment: item;
      font-weight: bold;
    }
    ol {
      @media #{$small-only} {
        padding-#{$ldirection}: 10px;
      }
    }
    div.loyalty-content {
      margin-top: -20px;
      margin-#{$ldirection}: 33px;
    }
  }
  strong {
    margin-#{$ldirection}: 5px;
    strong {
      margin-#{$ldirection}: 0px;
    }
  }
}

ol.loyalty-grid-tier {
  counter-reset: none;
  padding: 5px 0 5px 40px;
  li {
    &:before {
      content: none;
      counter-increment: none;
    }
    display: list-item;
    list-style-type: disc;
    div {
      width: 230px;
      float: $ldirection;
    }
  }
}

table.loyalty-grid-tier,
table.loyalty-retailers {
  width: 100%;
  margin-top: 10px;
  td {
    padding: 10px 0;
  }
}

ul.loyalty-points-benefits,
ul.loyalty-tier-benefits {
  counter-reset: none;
  li {
    @include swap_direction(margin, 10px 0 10px 95px);
    list-style-type: disc;
    display: list-item;
    list-style-position: outside;
    &:before {
      content: none;
      counter-increment: none;
    }
    ul.loyalty-tier-benefits-level {
      counter-reset: none;
      @include swap_direction(padding, 0 0 0 15px);
      li {
        &:before {
          content: none;
          counter-increment: none;
        }
        list-style-type: circle;
        display: list-item;
        padding-#{$ldirection}: 0;
        @include swap_direction(margin, 10px 0 10px 25px);
      }
    }
  }
}

.page-wrapper .terms__title {
  @media #{$small-only} {
    line-height: 1;
  }
}

.loyalty__panel__terms .loyalty__page__index__breadcrumb {
  padding-bottom: 0;
}

//One Trust Cookie Settings
.elb-terms-page {
  .optanon-show-settings-popup-wrapper {
    height: 20px;
    .optanon-show-settings {
      text-decoration: none !important;
    }
  }
}

#optanon {
  .optanon-status-editable {
    input[type='checkbox'] {
      & ~ label {
        &:after {
          top: 2px;
          transition: none;
        }
        &:before {
          border-radius: 50%;
          background-color: $color-white;
          height: 19px;
          width: 19px;
          #{$ldirection}: 2px;
          top: 1px;
          bottom: 2px;
        }
      }
    }
    .optanon-status-on {
      input[type='checkbox'] {
        & ~ label {
          &:after {
            background-image: none;
            border: none;
            transition: none;
          }
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
