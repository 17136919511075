.social-login {
  position: relative;
  .email-optin {
    text-transform: none;
    text-align: #{$ldirection};
    margin-top: 15px;
    letter-spacing: 0;
  }
  &__container {
    @include swap_direction(padding, 35px 30px 0);
    @media #{$medium-up} {
      @include swap_direction(margin, 0 30px);
    }
  }
  &__divider {
    width: 100%;
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    .inner {
      text-transform: lowercase;
      font-size: 18px;
      bottom: 2px;
    }
  }
  &__email-opt-in {
    label {
      text-transform: none;
      letter-spacing: 0;
    }
  }
  &__terms {
    @include swap_direction(padding, 10px 0);
    margin-top: 10px;
    text-align: #{$ldirection};
    p {
      font: 12px/20px $akzidenz;
      font-style: italic;
      letter-spacing: 0;
    }
  }
}

.social-info {
  margin-top: 40px;
  &__connect {
    display: inline-flex;
    margin-bottom: 20px;
  }
}

#registration {
  .sidebar-page {
    &__content {
      @media #{$medium-up} {
        padding-bottom: 0;
      }
    }
  }
  .social-container,
  .social-info {
    @media #{$medium-up} {
      margin-top: 0;
    }
  }
}

#confirm {
  .confirmation-panel {
    .social-login {
      padding: 18px 24px;
      @media #{$medium-up} {
        padding: 0;
      }
    }
  }
}
/* Gnav */
#cboxLoadedContent {
  .social-login {
    &.gnav {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0);
      .social-login__divider {
        width: 100%;
      }
      .fb-overlay-container {
        text-align: center;
      }
    }
    &__container {
      @include swap_direction(padding, 35px 30px 0);
    }
  }
  .sign-in-component__form-content--sign-in {
    margin-top: 0;
  }
}
