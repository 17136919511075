// @todo deprecate when SD products are live and landing pages use v2 formatter
// Renutriv Brand Styles that don't fit elsewhere. Consider using the method below before placing styles in here:
//
// SCSS:
// .button--dark {
//   background: $color-navy;
//   color: $color-white;
//   #{$renutriv} & { // Renutriv Brand Overrides
//     background: black;
//     border-color: $color-gold--renutriv;
//   }
//   #{$aerin} & { // Aerin Brand Overrides
//     background: $color-periwinkle-blue--aerin;
//   }
// }
//
// This will generate this CSS:
// .button--dark {
//   background: navy;
//   color: white;
// }
// .brand-renutriv .button--dark {
//   background: black;
//   border-color: gold;
// }
// .brand-aerin .button--dark {
//   background: periwinkle;
// }
//

#{$renutriv} .page-main {
  font-family: $roboto-condensed;
  p {
    font-family: $roboto-condensed;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $bb-roman;
    letter-spacing: normal;
  }
  // fix for inverted button
  .node-elc-nodeblock {
    .hero-block--renutriv,
    .hero-tout-mobile {
      .cta-button-wrapper,
      .tout-cta-button-wrapper--mobile {
        .button--inverted {
          @include swap_direction(border, solid 1px $color-white);
          background: transparent;
        }
      }
    }
  }
}

.brand-renutriv {
  #colorbox.colorbox__quickshop {
    .quickshop__description {
      .quickshop__header {
        color: #fff;
      }
      .quickshop__sub-header {
        color: #fff;
      }
      .quickshop__reviews {
        .quickshop__review-write {
          color: #fff;
        }
      }
      .quickshop__personal {
        a.quickshop__wishlist {
          color: #fff;
        }
      }
      a.quickshop__view-full {
        color: #fff;
      }
    }
  }
}
