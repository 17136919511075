$estee-edit-content-max-width: 1024px;
$back-bar-width: 82px;
$back-bar-margin: 10px;

%content-width-limit {
  max-width: 718px;
  margin: {
    #{$ldirection}: auto;
    #{$rdirection}: auto;
  }
  @media #{$medium-down} {
    margin: {
      #{$ldirection}: 0.5rem;
      #{$rdirection}: 0.5rem;
    }
  }
}

.formatter-estee-edit {
  .header-meta__headline {
    @media #{$large-up} {
      font-size: 90px;
    }
  }
  .header-meta__subhead {
    @media #{$large-up} {
      font-size: 30px;
      letter-spacing: -0.02em;
      line-height: 1.5em;
    }
  }
}

.estee-edit__header {
  margin-bottom: 2em;
}

.formatter-estee-edit__content {
  max-width: $estee-edit-content-max-width;
  @include swap_direction(margin, 0 auto);
  .header-meta {
    @extend %content-width-limit;
  }
  .basic-textarea-v1 {
    @extend %content-width-limit;
    img {
      max-width: 100%;
      height: auto;
    }
    p {
      font-size: 18px;
      line-height: 26px;
      font-family: $akzidenz;
      letter-spacing: 0.05em;
      @include swap_direction(margin, 0 0 1.5em 0);
    }
    ol {
      @include swap_direction(margin, 0 0 0 1em);
    }
  }
  blockquote {
    @extend %content-width-limit;
  }
}

[class*='--has-back-bar'] {
  @media #{$medium-up} {
    position: relative;
    padding-#{$ldirection}: $back-bar-width + $back-bar-margin;
  }
}

.back-bar__link {
  width: $back-bar-width;
  background: $color-navy;
  position: absolute;
  #{$ldirection}: 0;
  top: 0;
  bottom: -1em;
  display: none;
  text-align: center;
  @media #{$medium-up} {
    display: block;
  }
  .text {
    position: fixed;
    top: 50%;
    color: $color-white;
    text-transform: uppercase;
    text-decoration: underline;
    width: inherit;
    @include swap_direction(padding, 0 3px);
  }
  .formatter-woa & {
    background: #9598a9;
  }
  #{$aerin} & {
    font-weight: bold;
    line-height: 1.5em;
  }
}

.ee-hero {
  position: relative;
  margin-bottom: 37px;
  max-width: 942px;
  margin-#{$ldirection}: -10px;
  @media (min-width: 1020px) {
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
  }
  img {
    max-width: 100%;
  }
  .video-play {
    position: absolute;
    bottom: 30px;
    #{$ldirection}: 30px;
  }
}

.header-tags {
  margin-bottom: 0px;
  @extend %content-width-limit;
}

.estee-edit__header-tags-label {
  @include text--bold;
  #{$aerin} & {
    font-weight: normal;
  }
}

.header-meta {
  .header-meta__headline {
    margin-top: -13px;
    margin-top: 0.3em;
    margin-bottom: 0.5em;
  }
  .header-meta__subhead {
    margin-bottom: 25px;
    @media #{$medium-down} {
      font-size: 16px;
    }
  }
}

// article image styles
// ee_image_caption_v1
//ee-article-image
//ee-article-image--align-left
//ee-article-image--align-right
//ee-article-image--align-center

.ee-article-image--align-left {
  display: block;
  @include swap_direction(margin, 0 auto);
  @media #{$large-up} {
    float: $ldirection;
    @include swap_direction(margin, 0 2em 0 153px);
  }
}

.ee-article-image--align-right {
  display: block;
  @include swap_direction(margin, 0 auto);
  @media #{$large-up} {
    float: $rdirection;
    @include swap_direction(margin, 0 153px 0 2em);
  }
}

.ee-article-image--align-center {
  clear: both;
  text-align: center;
  @include swap_direction(margin, 0 auto);
  .ee-article-image__image {
    max-width: 100%;
    @media #{$large-up} {
      @include swap_direction(margin, 0 -153px 0 -153px);
    }
  }
}

.ee-article-image__caption {
  font-size: 15px;
  line-height: 24px;
  font-family: $akzidenz;
  text-align: center;
}
// WYSIWYG element styles
// p.ee-question
// p.ee-answer
// h3.ee--subhead headline--quaternary
.ee--subhead {
  @include headline--quaternary;
  margin-bottom: 0.25em;
}

.ee-question {
  color: #969696;
}

.ee-answer {
}

// drop cap first letter
.ee-first-letter {
  font: 60px/26px $optimalight;
  @include swap_direction(padding, 14px 6px 0 0);
  float: $ldirection;
}
