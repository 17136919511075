.loyalty-tout {
  float: $ldirection;
  text-align: center;
  @include swap_direction(padding, 0 60px 0 30px);
  width: 48%;
  &__container {
    width: 1122px; // 1022 without border
    @include swap_direction(padding, 36px 38px 24px 38px);
    @include swap_direction(border, 50px solid #1e203e); // just to frame for now
  }
  &__columns {
    @include clearfix;
    width: 946px;
  }
  &__image {
    margin-top: 16px;
  }
  &__text {
    text-align: $ldirection;
  }
  &__header {
    font-family: $optimaregular;
    font-size: 25px;
    letter-spacing: 0.1em;
    margin-top: 10px;
  }
  &__text {
    font-size: 17px;
    letter-spacing: 0.05em;
    text-align: $ldirection;
    padding-top: 15px;
    p {
      @include swap_direction(margin, 15px 0);
    }
    a {
      text-decoration: underline;
    }
  }
  &__link {
    font-weight: bold;
    text-decoration: underline;
  }
  &__cta {
    float: $ldirection;
    width: 50%;
    @include swap_direction(padding, 0 35px);
    border-#{$ldirection}: 1px solid $color-black;
    text-align: center;
    &-image {
      width: 100%;
    }
    &-text {
      font-weight: bold;
      @include swap_direction(margin, 12px 0);
    }
  }
  &__yes {
    display: block;
    @include swap_direction(padding, 0 40px);
    width: 227px;
    @include swap_direction(margin, 0 auto 4px auto);
  }
  &__no {
    font-size: 12px;
    font-family: 'AkzidenzGrotesk W1G';
    font-weight: bold;
  }
  &__terms {
    font-size: 12px;
    text-align: center;
    margin-top: 34px;
    a {
      text-decoration: underline;
    }
  }
}
