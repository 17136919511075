.tabs-component,
.tabs.primary {
  @include inline-block-list;
  padding-bottom: 5px;
  border-bottom: 1px solid $color-light-gray;
  li {
    @include swap_direction(margin, 0 15px 0 0);
  }
  a {
    @include text--short--plain;
  }
}
