$medium-colorbox-up: '#{$screen} and (min-width:850px)';

#cboxOverlay.colorbox__mb-slides {
  background: $color-navy;
}

.colorbox__mb-slides {
  #cboxContent {
    background-color: $color-navy;
  }
  #cboxLoadingGraphic {
    @include ajaxSpinner($color-white, 30px, 30px, 8px);
  }
  #cboxClose {
    #{$rdirection}: 50px;
  }
}

.formatter-mb-slideshow {
  max-width: 800px;
  background: $color-navy;
  color: $color-white;
  @include swap_direction(margin, 0 auto);
}

.mb-slideshow__slide__image {
  margin-bottom: 40px;
}

.mb-slideshow__slide__headline {
  font-size: 21px;
  @media #{$medium-colorbox-up} {
    font-size: 42px;
  }
}

.mb-slideshow__slide__image {
  width: 100%;
}

.mb-slideshow__slide-content--portrait {
  .mb-slideshow__slide__image {
    @media #{$medium-colorbox-up} {
      float: $ldirection;
      max-width: 390px;
      margin-#{$rdirection}: 1em;
    }
  }
  .mb-slideshow__slide__copy {
    text-align: center;
    @media #{$medium-colorbox-up} {
      text-align: $ldirection;
      float: $rdirection;
      max-width: 380px;
    }
  }
}

.mb-slideshow__slide-content--landscape {
  .mb-slideshow__slide__copy {
    text-align: center;
    max-width: 590px;
    margin: {
      #{$ldirection}: auto;
      #{$rdirection}: auto;
    }
  }
}

.mb-slide-share {
  position: relative;
  width: 100px;
  @include swap_direction(margin, 10px auto 40px);
  li {
    display: inline;
    @include swap_direction(padding, 0 5px);
  }
}

.mb-slide-share__link {
  @include squish-text;
  display: inline-block;
  &.facebook {
    background-size: auto auto;
    background-position: 0 -9077px;
    height: 20px;
    width: 11px;
  }
  &.twitter {
    background-size: auto auto;
    background-position: 0 -9465px;
    height: 19px;
    width: 21px;
  }
  &.pinterest {
    background-size: auto auto;
    background-position: 0 -9271px;
    height: 20px;
    width: 19px;
  }
}

.flexslider:hover {
  @media #{$medium-colorbox-up} {
    .flex-prev {
      #{$ldirection}: -50px;
    }
    .flex-next {
      #{$rdirection}: -50px;
    }
  }
}
