///
/// @file components/_carousel_responsive_v1.scss
///
/// General responsive carousels
/// MOBILE_STYLEGUIDE.pdf
///

.flex-viewport {
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.flex-control-nav {
  bottom: 8px;
}

.flex-control-paging li a.flex-active {
  background: $color-white;
}

.flex-control-paging li a {
  background: $color-medium-gray;
}

.flex-control-paging li a:hover {
  background: $color-white;
}
// direction nav (left/right arrows)
.flex-direction-nav a {
  margin-top: -39px;
}

.flex-direction-nav .flex-prev {
  @include hide-text;
  background-size: auto auto;
  background-position: -20px -3547px;
  height: 78px;
  width: 44px;
}

.flex-direction-nav .flex-next {
  @include hide-text;
  background-size: auto auto;
  background-position: -407px -3445px;
  height: 77px;
  width: 44px;
}

// Carousel wrapper
.responsive-carousel {
  background-color: $color-navy;
  padding-top: 50px;

  // Slide, specific to this generic style
  .flexslider {
    padding-bottom: 40px;
  }
  .slide__caption {
    font-family: $optimaregular;
    font-size: 40px;
    line-height: 1.2;
    color: $color-white;
    letter-spacing: -1px;
    text-align: center;
  }
  .slide__image {
    @include swap_direction(padding, 0 50px);
  }
  .slide__link {
    text-align: center;
    font-family: 'AkzidenzGrotesk W1G';
    font-weight: bold;
    color: $color-periwinkle-blue;
    font-size: 12px;
    text-decoration: underline;
    text-transform: uppercase;
    display: block;
    @include swap_direction(padding, 23px 0 25px);
  }
}

.responsive-carousel__title {
  font-family: 'AkzidenzGrotesk W1G';
  font-weight: bold;
  font-size: 12px;
  color: $color-white;
  text-align: center;
  text-transform: uppercase;
}
