.dw__foundation__sampling__tout {
  @media #{$medium-up} {
    padding: 0 0 50px;
  }
  @media #{$xxlarge-up} {
    @include dw-foundation-sampling-layout;
  }
  padding: 0 0 35px;
  &__shades {
    @media #{$medium-up} {
      @include swap_direction(padding, 85px 80px 0 100px);
      float: #{$ldirection};
      width: 58%;
    }
    @media #{$medium-only} {
      padding: 20px 30px 0;
      width: auto;
    }
    @media #{$large-only} {
      width: 53%;
      padding-top: 64px;
    }
  }
  &__body {
    .slick-prev,
    .slick-next {
      #{$ldirection}: 0;
      height: 100px;
      width: 42px;
      display: block;
      background: url(/media/images/global/arrow-prev.png) center center/auto auto no-repeat $color-border-aerin;
      z-index: 91;
      top: 28%;
      &:before {
        content: '';
      }
    }
    .slick-next {
      #{$ldirection}: auto;
      #{$rdirection}: 0;
      transform: rotate(180deg);
    }
    .slick-dots {
      bottom: -10px;
      button {
        border: 1px solid $color-navy;
        background: $color-white;
        padding: 0;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        &:before {
          font-size: 14px;
          opacity: 0;
          color: $color-navy;
        }
      }
      .slick-active {
        button {
          background: $color-navy;
          &:before {
            opacity: 0;
          }
        }
      }
    }
    .slick-track {
      overflow: auto;
    }
  }
  &__carousel-item {
    padding: .6% 10% 5%;
    text-align: center;
    font-size: 37px;
    font-family: $optimalight;
    letter-spacing: 0;
    color: $color-navy;
  }
  &__list-item {
    font-family: $optimalight;
    font-size: 20px;
    padding: 3px 0;
    letter-spacing: 0;
    color: $color-navy;
  }
  &__title {
    @media #{$medium-up} {
      padding: 0 0 8px;
      text-align: #{$ldirection};
      font-size: 30px;
    }
    @media #{$medium-only} {
      font-size: 24px;
    }
    color: $color-navy;
    padding: 0 62px;
    text-align: center;
    font-size: 55px;
  }
  &__format-item {
    @media #{$medium-up} {
      padding-bottom: 12px;
    }
  }
  &__bg__image {
    @media #{$medium-up} {
      width: auto;
    }
    @media #{$medium-only} {
      width: 43%;
    }
    @media #{$large-only} {
      width: 42%;
    }
    width: 100%;
  }
}
