.account-body .account-page__loyalty {
  padding-top: 10px;
  margin-bottom: 40px;
  @include pie-clearfix;
}

.account-page.page-main {
  .account-page__header {
    margin-bottom: 43px;
  }
}

#loyalty__page__tout {
  #loyalty__panel__points {
    width: 100%;
    float: none;
  }
  .info-tier-status-1,
  .info-tier-status-2,
  .info-tier-status-3 {
    display: none;
    @media #{$medium-up} {
      display: block;
    }
  }
  #loyalty__panel__points {
    .loyalty__panel__points__title--loyalty {
      display: none;
    }
    .loyalty__panel__points__title--account {
      display: block;
      @include swap_direction(border, none);
      @include swap_direction(padding, 0);
      margin-bottom: 6px;
    }
  }
}

#loyalty__content #loyalty__page__tout .points {
  margin-top: 0;
}
