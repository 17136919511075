@if $loyalty-redesign {
  .sign-in-page,
  .signin-overlay-wrapper {
    .sign-in-component {
      .signin-overlay {
        &__toggle-form {
          font-size: 15px;
        }
      }
      .forgot-password {
        .error_messages_display {
          color: $color-red;
        }
      }
      color: $color-navy;
      @media #{$medium-up} {
        padding: 30px;
        width: 585px;
      }
      &__form {
        margin: 0;
        .field[type='text'],
        .field[type='email'],
        .field[type='password'],
        .field[type='tel'],
        .form-text,
        .form-submit {
          @include box-shadow(none);
          @include input-placeholder {
            line-height: normal;
          }
          width: 100%;
          height: 40px;
          @media #{$small-only} {
            @include input-placeholder {
              color: $color-light-gray;
              background-color: $color-white;
            }
            height: 48px;
            border-color: $color-light-gray;
            &.error {
              border-color: $color-red;
            }
          }
        }
        &--sign-in,
        &--registration {
          padding: 0 30px;
          @media #{$medium-up} {
            padding: 0;
          }
        }
        &-content {
          @media #{$medium-up} {
            margin: 0 30px;
            padding: 0 30px;
          }
          .sign-in-component__header {
            margin: 8px 0;
            width: auto;
            font-family: $optimalight;
            font-weight: normal;
            font-size: 40px;
            letter-spacing: -0.05em;
          }
          .sign-in-component {
            .form-submit {
              margin-bottom: 0;
            }
            &__password-wrapper {
              @include swap_direction(margin, 0 0 10px);
              position: relative;
              .form-text {
                margin: 0;
              }
              .visible-pass-wrapper {
                .form-text {
                  @media #{$medium-up} {
                    border-color: $color-black;
                    color: $color-black;
                  }
                }
              }
            }
            &__show-password-wrapper {
              @media #{$medium-up} {
                @include swap_direction(margin, 6px 0 0 10px);
                float: #{$ldirection};
              }
              position: absolute;
              #{$rdirection}: 12px;
              top: 2px;
              @media #{$small-only} {
                top: 15px;
              }
              label {
                &:before,
                &:after {
                  visibility: hidden;
                }
              }
            }
            &__registration-options {
              width: auto;
              .sign-in-component {
                &__email-promos {
                  display: none;
                }
              }
            }
            &__header {
              font-family: $font-bauerbodoni-roman;
              text-transform: uppercase;
              text-align: center;
              letter-spacing: 0.02em;
              @media #{$medium-up} {
                text-align: #{$ldirection};
              }
            }
          }
          .text--form-help {
            margin-bottom: 10px;
            .sign-in-component {
              &__fpw-link {
                font-size: 15px;
              }
            }
            label {
              letter-spacing: 0.05em;
            }
          }
          .text--checkbox-label {
            label {
              &.error a {
                color: $color-red;
              }
            }
          }
          h6 {
            font-family: $akzidenz;
            margin-bottom: 10px;
            letter-spacing: 0.05em;
          }
        }
        &-loyalty {
          display: none;
        }
      }
      &.signin-join-loyalty {
        .sign-in-component {
          &__confirm--join-loyalty {
            display: block;
          }
        }
        &.sign-in {
          .sign-in-component__form--sign-in {
            display: none;
          }
        }
      }
      &__confirm--registration,
      &__confirm--join-loyalty {
        display: none;
      }
    }
    .error_messages {
      text-align: #{$ldirection};
    }
    .sign-in-component__form-content {
      padding: 0;
      .signin-loyalty-cta {
        .signin-loyalty-cta {
          &__button {
            margin: 20px 0;
          }
          &__header {
            margin: 8px 0;
            width: auto;
            font-family: $font-bauerbodoni-roman;
            font-weight: 400;
            font-size: 38px;
            letter-spacing: -0.05em;
            text-transform: uppercase;
          }
          &__terms {
            width: auto;
          }
          &__sub-header {
            padding-bottom: 30px;
          }
          &__link {
            font-weight: 700;
          }
        }
      }
    }
  }
  .signin-overlay {
    &-loyalty {
      .sign-in-component--loyalty {
        .sign-in-component__form--sign-in {
          .sign-in-component__form-content--sign-in {
            padding: 0;
          }
          .sign-in-component__header {
            font-family: $bb-roman;
            font-size: 40px;
            letter-spacing: -0.01em;
          }
          .form-text {
            font-size: 10px;
          }
          .form-submit {
            font-size: 12px;
          }
          .text--form-help {
            p {
              &.forget-password {
                font-size: 13px;
              }
            }
          }
        }
        .social-login__terms {
          p {
            font-style: inherit;
            width: 70%;
            letter-spacing: 0.05em;
          }
        }
        .sign-in-component__form--registration,
        .sign-in-component__form--enrollment {
          .sign-in-component__form-content {
            .sign-in-component__header {
              font-family: $bb-roman;
              font-size: 40px;
              letter-spacing: -0.01em;
            }
            h6 {
              font-size: 12px;
            }
            .form-text {
              font-size: 10px;
            }
            .form-submit {
              font-size: 12px;
            }
          }
        }
        .sign-in-component__form--registration {
          .sign-in-component__form-content {
            padding: 0;
            h6 {
              font-size: 15px;
            }
            .sign-in-component__promo-loyalty {
              .text--checkbox-label {
                width: 95%;
              }
            }
          }
        }
        .sign-in-component__form--enrollment {
          .sign-in-component__form-content {
            padding: 0;
            .sign-in-component {
              &__header {
                font-family: $bb-roman;
                font-size: 40px;
                letter-spacing: -0.01em;
              }
              &__body-text {
                font-size: 15px;
                .modifier {
                  font-weight: bold;
                }
              }
            }
            .form-submit {
              width: 110px;
              height: 40px;
              line-height: 40px;
              background: $color-navy;
              color: $color-white;
              text-decoration: none;
              padding: 0;
            }
            .later-link {
              text-transform: uppercase;
              font-weight: bold;
              font-size: 12px;
              letter-spacing: 0.15em;
            }
            .social-login__terms {
              font-size: 12px;
            }
          }
        }
      }
    }
    &-wrapper {
      border: 1px solid $color-medium-gray;
      .sign-in-component {
        @media #{$medium-up} {
          width: 546px;
        }
        &__form {
          margin: 0;
          padding: 0;
          .signin-overlay__toggle-form {
            display: none;
          }
          &-content {
            @media #{$small-only} {
              @include swap_direction(padding, 30px 30px 0);
            }
          }
        }
        &--loyalty {
          @media #{$medium-up} {
            padding: 30px 10px;
            &.sign-in-component--lyl-enrollment {
              width: 100%;
              padding: 28px 10px;
              min-height: 320px;
            }
          }
          .sign-in-component__form-content {
            @media #{$medium-up} {
              margin: 0 28px;
            }
          }
        }
      }
      &#colorbox {
        position: absolute !important;
      }
      #cboxContent {
        padding: 0;
        #cboxClose,
        #cboxClose:hover {
          #{$rdirection}: 20px;
          top: 20px;
        }
      }
      #cboxLoadedContent {
        @media #{$small-only} {
          width: 100% !important;
          height: 100% !important;
          overflow: scroll !important;
        }
        .social-login__container {
          padding: 35px 0;
        }
        .social-login {
          &.gnav {
            .social-login__container {
              padding: 35px 0 0;
              .social-login__divider {
                width: 89%;
                margin: 0 29px;
                .inner {
                  text-transform: uppercase;
                  font-size: 12px;
                  letter-spacing: 0.2em;
                  font-family: $akzidenz;
                }
              }
              .email-optin {
                @media #{$medium-up} {
                  width: 84%;
                }
              }
            }
          }
        }
      }
      .sign-in-component--loyalty {
        &.registration {
          &.sign-in {
            .signin-overlay__toggle {
              .signin-overlay__toggle {
                &-form {
                  &--have-account {
                    display: none;
                  }
                  &--dont-have-account {
                    display: block;
                    text-align: #{$ldirection};
                    font-size: 15px;
                    margin-top: 8px;
                  }
                }
              }
            }
          }
          .signin-overlay__toggle {
            .signin-overlay__toggle {
              &-form {
                &--have-account {
                  display: block;
                  text-align: #{$ldirection};
                  font-size: 15px;
                  margin-top: 8px;
                }
                &--dont-have-account {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    &__toggle-form {
      margin: 20px 0;
      padding: 0 30px;
      text-align: center;
      &--have-account {
        display: block;
        &-signin {
          font-size: 12px;
          color: $color-navy;
          background: transparent;
        }
        .sign-in & {
          display: none;
        }
      }
      &--dont-have-account {
        display: none;
        &-register,
        &-signin {
          font-size: 12px;
          color: $color-navy;
          background: transparent;
        }
        .mobile_hidden {
          @media #{$medium-up} {
            display: inline;
          }
        }
      }
    }
  }
  /* Account sign in page */
  .sign-in-page.sign-in-page--loyalty {
    max-width: 1366px;
    margin: 0 auto;
    .social-login {
      &__divider {
        position: relative;
        .inner {
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 0.15em;
          font-weight: 100;
          font-family: $akzidenz;
        }
      }
      &__terms {
        @media #{$medium-up} {
          margin-top: 0;
        }
        p {
          @media #{$medium-up} {
            width: 73%;
          }
          font-style: normal;
          letter-spacing: 0.05em;
          font-size: 12px;
          width: 100%;
        }
      }
      .email-optin {
        @media #{$medium-up} {
          font-size: 12px;
        }
        line-height: 1.7;
        letter-spacing: 0.05em;
      }
    }
    .sign-in-page--loyalty {
      &-container-banner {
        display: none;
        @media #{$medium-up} {
          width: 54%;
          float: #{$ldirection};
          display: block;
          background-image: url('/media/loyalty/tablet-potrait.jpg');
          background-repeat: no-repeat;
          background-size: cover;
          height: 780px;
        }
        @media #{$large-up} {
          background-image: url('/media/loyalty/tablet-landscape.jpg');
          height: 825px;
        }
        @media #{$xlarge-up} {
          width: 50%;
          max-width: 668px;
          @if $loyalty-banner-cr24update {
            background-image: url('/media/loyalty/cr24/account-login.jpg');
          } @else {
            background-image: url('/media/loyalty/account-login.jpg');
          }
        }
        @if $signin-banner-cr23update {
          @media #{$medium-up} {
            background-image: url('/media/loyalty/account-login-tablet-banner.jpg');
            background-size: contain;
          }
          @media #{$large-up} {
            height: 790px;
            background-size: cover;
          }
          @media #{$xlarge-up} {
            width: 54%;
            background-image: url('/media/loyalty/account-login-banner.jpg');
          }
          @media #{$xxlarge-up} {
            height: 825px;
          }
          @media #{$device-ipad_only} and (orientation: portrait) {
            background-size: contain;
            margin-top: 5%;
          }
        }
      }
      &--header {
        display: none;
        @media #{$medium-up} {
          font-size: 3.6vw;
          font-family: $font-bauerbodoni-roman;
          letter-spacing: 0.5px;
          text-align: center;
          color: $color-white;
          padding-top: 44vw;
          display: block;
        }
        @media #{$large-up} {
          padding-top: 41vw;
        }
        @media #{$xlarge-up} {
          padding-top: 28vw;
        }
        @media #{$xxlarge-up} {
          font-size: 50px;
          padding-top: 380px;
        }
      }
      &--sub--content {
        display: none;
        @media #{$medium-up} {
          margin: 0;
          text-align: center;
          color: $color-white;
          font-weight: 500;
          font-size: 1.3vw;
          display: block;
          text-transform: uppercase;
          padding-top: 7px;
        }
        @media #{$xxlarge-up} {
          font-size: 15px;
          letter-spacing: 0.15em;
        }
      }
    }
    .sign-in-component--loyalty {
      @media #{$medium-up} {
        width: 46%;
        padding: 15px;
        max-width: 574px;
        overflow: hidden;
      }
      @media #{$large-up} {
        padding: 20px;
      }
      .sign-in-component__form-content {
        @media #{$medium-up} {
          padding: 0;
          margin: 0;
        }
      }
      &.registration {
        @media #{$medium-up} {
          padding-top: 2%;
        }
        @media #{$large-up} {
          padding-top: 3%;
        }
        &.sign-in {
          @media #{$medium-up} {
            padding-top: 13%;
          }
          @media #{$large-up} {
            padding-top: 10%;
          }
          .signin-overlay__toggle-form {
            &--have-acount {
              display: none;
            }
            &--dont-have-acount {
              display: block;
            }
          }
        }
        .signin-overlay__toggle-form {
          margin: 15px 0 25px;
          @media #{$medium-up} {
            padding: 0;
            text-align: #{$ldirection};
            font-size: 15px;
            margin-bottom: 0;
          }
          a {
            display: inline;
            color: $color-black;
            background: none;
            line-height: 1;
            font-weight: normal;
            text-transform: initial;
            font-family: $akzidenz;
            text-decoration: underline;
            letter-spacing: 0.05em;
          }
          &--have-acount {
            display: block;
            @media #{$medium-up} {
              padding: 0;
              text-align: #{$ldirection};
              font-size: 12px;
            }
          }
          &--dont-have-acount {
            display: none;
          }
        }
      }
      .sign-in-component {
        &__form {
          margin: 0;
          .field[type='text'],
          .field[type='email'],
          .field[type='password'],
          .field[type='tel'],
          .form-text,
          .form-submit {
            @include box-shadow(none);
            @media #{$medium-up} {
              width: 90%;
              height: 4vw;
              line-height: 0;
            }
            @media #{$large-up} {
              width: 100%;
              height: 40px;
            }
            @media #{$xlarge-up} {
              width: 85%;
              height: 40px;
            }
          }
        }
        &__password-wrapper {
          @media #{$medium-up} {
            margin-bottom: 10px;
          }
          position: relative;
          margin-bottom: 25px;
        }
        &__show-password-wrapper {
          position: absolute;
          top: 12px;
          #{$rdirection}: 12px;
          @media #{$medium-up} {
            @include swap_direction(margin, 6px 0 0 10px);
            top: 0;
            #{$rdirection}: 18%;
          }
          .text--checkbox-label {
            &:after,
            &:before {
              display: none;
            }
          }
        }
        &__registration-options {
          @media #{$medium-up} {
            width: 100%;
          }
          .text--form-help {
            @media #{$medium-up} {
              width: 85%;
            }
            .label,
            .label-content {
              @media #{$medium-up} {
                line-height: normal;
              }
              line-height: 1.7;
            }
            .label-content {
              @media #{$medium-up} {
                width: 90%;
              }
              width: 96%;
              display: inline-block;
            }
          }
          .sign-in-component {
            &__email-promos {
              display: none;
            }
          }
        }
        &__header {
          width: auto;
          font-family: $font-bauerbodoni-roman;
          text-transform: uppercase;
          text-align: center;
          font-size: 36px;
          font-weight: 100;
          letter-spacing: -0.01em;
          line-height: 1;
          margin: 0 0 10px;
          @media #{$medium-up} {
            text-align: #{$ldirection};
            font-size: 25px;
            margin-bottom: 10px;
          }
          @media #{$large-up} {
            font-size: 38px;
            width: 100%;
          }
          @media #{$xlarge-up} {
            font-size: 40px;
            width: 65%;
          }
        }
        &__subheader {
          margin-bottom: 10px;
          font-size: 15px;
          letter-spacing: 0.05em;
          font-family: $akzidenz;
          padding: 0;
          text-align: center;
          @media #{$medium-up} {
            width: 85%;
            font-size: 15px;
            letter-spacing: 0.05em;
          }
          @media #{$xlarge-up} {
            font-size: 15px;
            letter-spacing: 0.05em;
            width: 75%;
            text-align: #{$ldirection};
          }
          .rewards__access {
            @media #{$medium-up} {
              display: inline;
            }
            display: block;
          }
        }
        &__fpw-link {
          @media #{$medium-up} {
            text-align: #{$ldirection};
          }
          text-align: center;
          display: block;
        }
      }
    }
    .social-login {
      .social-login {
        &__container {
          padding: 0 30px;
          @media #{$medium-up} {
            padding: 0;
            margin: 0;
            width: 100%;
            text-align: center;
          }
          @media #{$xlarge-up} {
            width: 85%;
          }
        }
        &__email-opt-in {
          padding-top: 15px;
          @media #{$medium-up} {
            text-align: #{$ldirection};
            width: 100%;
          }
          @media #{$xlarge-up} {
            width: 90%;
          }
        }
        &__divider {
          @media #{$medium-up} {
            width: 100%;
          }
          &:before {
            top: 30%;
          }
        }
        &__terms {
          @media #{$medium-up} {
            text-align: #{$ldirection};
          }
          text-align: center;
        }
      }
    }
  }
  .sign-in-page {
    &.sign-in-page--loyalty {
      .sign-in-component__confirm {
        &.sign-in-component__confirm {
          &--join-loyalty {
            padding: 0 20px;
            display: block;
            text-align: center;
            @media #{$medium-up} {
              margin-top: 25%;
              text-align: #{$ldirection};
            }
            .signin-loyalty-cta {
              &__button {
                color: $color-white;
                @media #{$medium-up} {
                  width: 150px;
                }
              }
            }
            .error_messages {
              padding: 0;
              margin: 0;
            }
          }
        }
      }
      .sign-in-component {
        .form-submit {
          margin: 8px 0 20px;
        }
      }
    }
  }
  /* My feed Section */
  .drawer-formatter {
    &__content {
      .my-feed-tray--loyalty {
        &:before {
          width: 100%;
        }
        .my-feed-tray {
          &__inner {
            .my-feed {
              &-tray {
                &__dual-col {
                  padding: 30px 0;
                  width: 100%;
                }
                &__col {
                  &.my-feed-summary {
                    display: none;
                  }
                  &.special-offer {
                    .special-offer {
                      &__button {
                        font-size: 11px;
                      }
                    }
                  }
                }
              }
              &-loyalty__joined {
                padding-top: 0;
                a {
                  &.my-feed-loyalty__account {
                    position: static;
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }
        .my-feed-loyalty-container {
          max-width: 600px;
          .loyalty-offer {
            max-width: 600px;
            &__img {
              @include swap_direction(padding, 1px 20px 0 0);
              float: #{$ldirection};
            }
            &__elist-header {
              @include headline--quinary--plain;
              line-height: 0.97;
            }
            &__right-container {
              overflow: hidden;
            }
            &__header {
              font-size: 16px;
              margin: 10px 0;
            }
            &__link {
              letter-spacing: 1px;
              &-container {
                position: static;
              }
            }
          }
          .my-feed-loyalty-raf {
            margin-bottom: 5px;
          }
        }
        .my-feed-loyalty {
          &__right-container {
            overflow: hidden;
          }
          &__status-display {
            @include swap_direction(padding, 0px 10px 5px 0);
            float: none;
            width: 100%;
            border-#{$rdirection}: none;
            letter-spacing: 0.02em;
            font-size: 16px;
          }
          &__zero-points {
            padding: 0;
            @media #{$medium-up} {
              letter-spacing: 0.05em;
              width: 100%;
            }
            @media #{$large-up} {
              letter-spacing: 0.15em;
              width: 66%;
            }
          }
          &__status-container {
            margin: 5px 0;
          }
          &__status-next {
            width: 95%;
            padding-#{$ldirection}: 0;
            .next-level {
              display: none;
            }
          }
          &__loyalty {
            &-status {
              font-size: 11px;
            }
          }
        }
      }
    }
    &__close {
      #{$rdirection}: 15px;
    }
  }
  .registration-confirmation {
    .social-login,
    .signin-overlay__toggle {
      display: none;
    }
  }
  .signin-join-loyalty {
    .signin-overlay__toggle {
      display: none;
    }
  }
  .page-footer {
    clear: both;
  }
  .page-utilities {
    .page-utilities {
      &__signin-text {
        display: inline-block;
        cursor: pointer;
      }
    }
  }
  .loyalty_popover {
    .loyalty_popover {
      &__checkbox-label {
        font-size: 12px;
        letter-spacing: 0.05em;
      }
    }
  }
}
