///
/// @file drupal_modules/esteelauder_common/_video_youtube.scss
/// Styles for promo item linking to a youtube video in colorbox overlay
///
/// \brief Styling for template at:
///   esteelauder/modules/custom/esteelauder_common/templates/video_youtube_v1
///

.video_youtube {
  position: relative;
  @include swap_direction(margin, 0 auto 100px auto);
  width: 800px;
  .eyebrow {
    margin-bottom: 2em;
  }
  .pc-carousel-formatter & {
    margin-bottom: 0;
  }
  @media #{$medium-only} {
    width: 100%;
  }
  .video_youtube__header .headline--primary {
    @media #{$medium-only} {
      font-size: 135px;
    }
  }
}

.video_youtube.fonts-theme-re-nutriv {
  .headline--primary,
  .headline--primary span {
    font-size: 35px !important;
    font-family: $bb-roman;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-weight: lighter;
    margin-bottom: 30px;
  }
  .video_youtube__content {
    .text-promo {
      font-size: 16px;
      line-height: 1.5em;
      font-family: $roboto-condensed;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
  }
}

.video_youtube__content {
  min-height: 376px;
  margin-top: -40px;
  color: #fff;
  &.color--dark {
    color: #000;
  }
  .video-play {
    position: absolute;
    bottom: 30px;
    #{$ldirection}: 30px;
  }
  .text-promo {
    position: absolute;
    bottom: 100px;
    #{$ldirection}: 30px;
  }
}

.video-play {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 21px 0 21px 37px;
  border-color: transparent transparent transparent $color-white;
  &.color--dark {
    border-color: transparent transparent transparent $color-navy;
  }
}

.colorbox__youtube {
  #cboxContent {
    background: $color-navy;
  }
  .cboxIframe {
    text-align: center;
    @include swap_direction(padding, 40px);
    background: $color-navy;
  }
}

.video-tout-autoplay {
  max-width: 1366px;
  @include swap_direction(margin, 0 auto);
  display: block;
  .video-tout-content-wrapper {
    display: inline-block;
    position: relative;
    .video-tout-background-image {
      img {
        width: 100%;
      }
    }
    .video-tout-content {
      font-family: $roboto-condensed;
      position: absolute;
      top: 20%;
      #{$ldirection}: 0;
      padding-#{$ldirection}: 56%;
      text-align: center;
      &.color--light {
        color: #fff;
      }
      &.color--dark {
        color: #000;
      }
      .headline--primary,
      .headline--second,
      .text-promo-header,
      .text-promo-copy,
      .video-play {
        width: 100%;
        text-align: center;
        font-family: $roboto-condensed;
      }
      .headline--primary {
        font-family: $optimagreek;
      }
      .headline--second {
        font-size: 80px;
        font-family: $roboto-condensed;
        margin-bottom: 10px;
      }
      .text-promo-header,
      .text-promo-copy {
        font-size: 30px;
        letter-spacing: 0;
        line-height: 35px;
      }
      .text-promo-header {
        font-weight: bold;
        font-size: 31px;
      }
      .video-play {
        margin-top: 30px;
        @include swap_direction(border, 0);
        color: #fff;
        font-family: $akzidenz;
        text-decoration: underline;
      }
    }
  }
}
