///
/// Customer services styling
/// This uses the formatter_sidebar_page sidebar at /customer-services/*
///

/// Customer service / formatter overrides
/// $sidebar-link-padding comes from _formatter_sidebar_page_v1.scss
///
.customer-service {
  color: $color-navy;
  max-width: 1024px; // request, override default of 1366 for .sidebar-page pages

  // Menus
  .level-1 {
    background-size: auto auto;
    background-position: 100% -4444px;
  }
  .sidebar-link--is-active .level-1 {
    background-size: auto auto;
    background-position: 100% -4364px;
  }
  .active-trail .level-1 {
    background-size: auto auto;
    background-position: 100% -4324px;
  }
  .depth-1 {
    display: none;
  }
  .active-trail .depth-1,
  .sidebar-link--is-active .depth-1 {
    display: block;
  }
  // target customer service links on non-landing page
  &:not(.customer-service--landing) .sidebar-page__sidebar li:first-child .level-1 {
    background-image: none;
    border-bottom: 1px solid $color-light-gray;
    margin-bottom: 15px;
  }

  // Customer service / content formatting
  .sidebar-page__content {
    a {
      text-decoration: underline;
      &.country_select {
        width: 275px;
      }
    }
    // Tyopgraphy
    h2 {
      font-size: 30px;
      line-height: 1.8;
      border-bottom: 1px solid $color-medium-gray;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 24px;
      line-height: 1.8;
      margin-bottom: 20px;
    }
    table {
      width: 100%;
      // line-height: 2;
      margin-bottom: 70px;
    }
    table.loyalty-grid {
      margin-bottom: 30px;
    }
    th {
      strong {
        display: block;
        margin-#{$rdirection}: 10px;
        border-bottom: 1px solid $color-medium-gray;
        line-height: 2;
        padding-bottom: 10px;
      }
    }
    strong {
      text-align: $ldirection;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    td {
      @include swap_direction(padding, 10px 0); // won't work in IE
    }
    ul {
      @include text--long-copy--plain;
      padding-#{$ldirection}: 20px;
    }
    li {
      margin-bottom: 10px;
      list-style-type: disc;
      @media #{$medium-up} {
        min-height: 125px;
      }
    }
    ol {
      @include text--long-copy--plain;
      padding-#{$ldirection}: 20px;
      li {
        list-style-type: decimal;
      }
    }
  } // .sidebar-page__content
} // .customer-service

///
/// Customer Service / Callouts
///
.customer-service-callout {
  background-color: $color-gray-cs-callout;
  border-top: 1px solid $color-light-gray;
  border-bottom: 1px solid $color-light-gray;
  @include swap_direction(padding, 25px);
  margin-bottom: 40px;
  text-align: center;
}

.customer-service-callout__title {
  // have to use !important due to our h3 styles
  font-size: 30px !important;
  // margin-bottom: !important;
}

.customer-service-callout__text {
  font-family: $optimaregular;
  font-size: 16px;
}

.customer-service-callout__action-link {
  font-weight: bold;
  font-size: 12px;
  text-decoration: underline;
  text-transform: uppercase;
}

///
/// Customer Service / Quick info header
///
// Many elements for quickinfo only show on the landing page
@mixin landing-only($section: '.customer-service--landing', $display: block) {
  display: block;
  @media #{$medium-up} {
    #{$section} & {
      display: $display;
    }
  }
}

.cs-quick-info {
  @include clearfix;
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
  @include swap_direction(padding, 15px 0 !important); // revisit
  border-top: 1px solid $color-light-gray;
  border-bottom: 1px solid $color-light-gray;
}

.cs-quick-info__block {
  @include swap_direction(margin, 15px 0);

  // post-mobile
  @media #{$medium-up} {
    // show inline not landing
    @include inline-block;
    margin-#{$ldirection}: 10px;
    margin-#{$rdirection}: 10px;

    // Show grids on landing page
    .customer-service--landing & {
      display: block;
      margin-#{$ldirection}: 0;
      margin-#{$rdirection}: 0;
      width: 50%;
      float: $ldirection;
      // clear third to make grid
      &:nth-child(3) {
        clear: $ldirection;
      }
    } // .customer-service--landing
  } // medium-up
  // hide list dots
  .customer-service ul & {
    list-style-position: outside;
  }
}

.cs-quick-info__text {
  @include landing-only; // show only on landing
  @include swap_direction(margin, 0 auto);
  max-width: 192px;
  font-size: 15px;
}

.cs-quick-info__link {
  @include landing-only; // show only on landing
  font-size: 12px;
  line-height: 2;
  text-decoration: underline;
  text-transform: uppercase;
}

.cs-quick-info__link--strong {
  font-weight: bold;
}

.cs-quick-info__title {
  font-family: 'AkzidenzGrotesk W1G';
  font-size: 12px;
  line-height: 25px;
  padding-#{$ldirection}: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block; // hide/show blow

  // title icons
  .cs-quick-info__block--call-us & {
    background-size: auto auto;
    background-position: 0 -1938px;
  }
  .cs-quick-info__block--live-chat & {
    background-size: auto auto;
    background-position: 0 -1788px;
  }
  .cs-quick-info__block--email-us & {
    background-size: auto auto;
    background-position: 0 -1888px;
  }
  .cs-quick-info__block--text-advisor & {
    background-image: url('/media/export/cms/liveperson/icons/fa-bubble-darkblue.png');
    background-repeat: no-repeat;
    background-size: 25px 17px;
  }
  .cs-quick-info__block--store-locator & {
    background-size: auto auto;
    background-position: 0 -1838px;
  }
  .customer-service & a {
    text-decoration: none;
  }
}

// show/hide for both landing and
.cs-quick-info__title--mini,
.sms-chat-button {
  @include inline-block;
}

.customer-service {
  .cs-quick-info__title--full {
    @media #{$small-up} {
      @include inline-block;
    }
  }
  .cs-quick-info__title--mini {
    @media #{$small-up} {
      display: none;
    }
  }
}
// Customer service / landing
.customer-service--landing {
  .sidebar-page__sidebar {
    li:first-child {
      display: none; // hide customer service link on landing
    }
  }
}
// Customer service / Shipping
.shipping-time-table th {
  width: 33%;
}
// Customer service / Orders
.missing-package-figure {
  @include clearfix;
  > li {
    width: 25%;
    float: $ldirection;
    position: relative;
    @include swap_direction(padding, 0 5px);
    &:first-child {
      padding-#{$ldirection}: 0;
    }
    &:last-child {
      padding-#{$rdirection}: 0;
    }
  }
  li {
    list-style-type: none !important;
  }
  .icon {
    display: block;
    @include swap_direction(margin, 0 auto 15px);
  }
  .num {
    // position: absolute; // remove icons for amanda
    top: 0;
    #{$ldirection}: 0;
    display: block;
  }
}

.icon {
  .missing-package-figure--step-1 & {
    background-size: auto auto;
    background-position: 0 -4809px;
    height: 43px;
    width: 43px;
  }
  .missing-package-figure--step-2 & {
    background-size: auto auto;
    background-position: 0 -4877px;
    height: 43px;
    width: 43px;
  }
  .missing-package-figure--step-3 & {
    background-size: auto auto;
    background-position: 0 -4945px;
    height: 43px;
    width: 43px;
  }
  .missing-package-figure--step-4 & {
    background-size: auto auto;
    background-position: 0 -5013px;
    height: 43px;
    width: 43px;
  }
}

// Customer service / FAQs
.faq__question {
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
  &:after {
    background-size: auto auto;
    background-position: 100% -4419px;
    height: 15px;
    width: 15px;
    margin-top: -(sprites-sprite-height('menu-plus-faq') / 2);
  }
  .faq--active &:after {
    background-size: auto auto;
    background-position: 100% -4299px;
    height: 15px;
    width: 15px;
    // assume height same as plus
  }
  // shared pseudo styles
  &:after,
  .faq--active &:after {
    content: '';
    position: absolute;
    #{$rdirection}: 0%;
    top: 50%;
  }
  .faq--active & {
    font-weight: bold;
  }
}

.faq__answer {
  display: none;
  margin-#{$ldirection}: 20px;
  .faq--active & {
    display: block;
  }
}

// Customer service pages (ie. shipping) may appear in modals with the sidebar
// and header nav stripped out.
#colorbox {
  .customer-service {
    margin-top: 0;
    .customer-service-quick-info-block-elc-nodeblock,
    .sidebar-page__sidebar {
      display: none;
    }
    .sidebar-page__content {
      width: 100%;
      @include swap_direction(padding, 0);
      &.replenish {
        text-align: center;
        @media #{$medium-down} and (orientation: landscape) {
          min-height: 515px;
        }
      }
    }
    .replenish {
      &__heading {
        margin: 0 auto 20px;
        font-size: 40px;
        line-height: 0.8;
        letter-spacing: 0.001em;
        width: 70%;
        @media #{$medium-up} {
          @include swap_direction(margin, 0 0 20px);
          font-size: 50px;
          width: 100%;
        }
      }
      &__subheading {
        @include swap_direction(margin, 0);
        line-height: 20px;
        @media #{$medium-up} {
          line-height: 22px;
        }
      }
    }
    .content_container {
      margin-top: 8px;
      padding-bottom: 20px;
      @media #{$medium-up} {
        padding-bottom: 0;
      }
    }
    .content_section {
      @include swap_direction(padding, 5px 0);
      width: 100%;
      &:first-child {
        @media #{$large-up} {
          width: 23%;
        }
      }
      &:nth-child(2) {
        @media #{$large-up} {
          width: 25%;
        }
      }
      @media #{$large-up} {
        @include swap_direction(padding, 0 8px);
        width: 22%;
        float: #{$ldirection};
      }
      &__option {
        font-size: 40px;
        line-height: 0.8em;
        @media #{$medium-up} {
          font-size: 50px;
          line-height: 1em;
        }
      }
      &__option-heading {
        font-family: $akzidenz;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        @media #{$medium-up} {
          font-size: 16px;
        }
      }
      &__option-desc {
        @include swap_direction(margin, 0);
        line-height: 20px;
        @media #{$medium-up} {
          line-height: 22px;
        }
      }
    }
  }
  .device-mobile & {
    &.generic-helplinks-overlay {
      #cboxClose {
        background: url(/media/images/checkout/close_button.jpg) no-repeat;
        background-size: contain;
        background-position: initial;
      }
    }
  }
}

.contact-us-page .address-form__address-fields .address-form__address-type-container.radios span,
.contact-us-page .address-form__address-fields .address-form__registered-container.radios span {
  #{$ie8} & {
    display: inline-block;
  }
}

.contact-us-page .address-form__address-fields .address-form__address-type-container.radios input[type='radio'],
.contact-us-page .address-form__address-fields .address-form__registered-container.radios input[type='radio'] {
  #{$ie8} & {
    @include swap_direction(margin, 5px);
  }
}

// AfterPay Customer Service FAQ

.afterpay-faq {
  max-width: 1340px;
  margin: 0 auto;
  a {
    text-decoration: underline;
  }
  a.cta {
    text-decoration: none;
  }
  .headline_content {
    &__subcopy {
      transform: translate(0%, -200%);
      padding: 0 10px;
      @media #{$medium-up} {
        width: 540px;
        text-align: center;
        transform: translate(-34%, 185%);
      }
      @media #{$xlarge-up} {
        transform: translate(-21.5%, 420%);
      }
      span {
        font-size: 12px;
        @media #{$xlarge-up} {
          font-size: 14px;
        }
      }
    }
  }
  .chat-parallax {
    min-height: 175px;
    height: 175px;
    text-align: center;
    margin: 0;
    .chat-parallax__text-under {
      padding-top: 30px;
    }
    .chat-parallax__subtitle {
      width: 100%;
      margin: 0;
    }
  }
  .promo-section-title {
    padding: 0 40px;
    font-size: 20px;
    font-weight: 700;
    @media #{$medium-up} {
      padding: 0 100px;
      font-size: 40px;
      font-weight: 400;
    }
  }
  &--how-it-works,
  &--create-account,
  &--account-access,
  &--common-faq {
    padding: 0 10px;
  }
  &--banner {
    max-height: 440px;
  }
  &--how-it-works {
    .promo-blocks {
      padding-top: 35px;
      @media #{$medium-up} {
        display: flex;
      }
      .services-carousel {
        &__carousel-slide {
          @media #{$medium-up} {
            padding: 0 50px;
          }
          flex: 33.33%;
          padding: 0 40px;
          text-align: center;
          .carousel-slide {
            &__title,
            &__description {
              padding-bottom: 10px;
            }
            &__image {
              padding-bottom: 30px;
              img {
                width: 50px;
                height: 40px;
              }
            }
          }
        }
      }
    }
  }
  .sidebar-page {
    margin-top: 0;
    &__content {
      width: 100%;
      margin: 0 auto;
    }
    &__content-title {
      display: none;
    }
  }
}
