.careers-page {
  @media #{$small-only} {
    .sidebar-page__sidebar {
      @include swap_direction(padding, 20px);
    }
  }
  .careers-page__landing-link {
    @include text--bold;
    display: block;
    margin-bottom: 30px;
    @media #{$medium-up} {
      margin-top: 50px;
    }
  }
  .headline--page,
  .headline--primary,
  .headline--section {
    -webkit-font-smoothing: antialiased;
  }
  .careers-landing {
    @media #{$medium-up} {
      padding-top: 47px;
    }
    .headline--primary {
      font-size: 55px;
      line-height: 55px;
      letter-spacing: 0px;
      @media #{$medium-only} {
        line-height: 0.85;
      }
    }
    .video_youtube__content {
      background-position: 0 18px;
      background-repeat: no-repeat;
      .text-promo {
        color: $color-navy;
        width: 310px;
        line-height: 24px;
        bottom: 117px;
        #{$ldirection}: 25px;
      }
      .video-play {
        bottom: 50px;
      }
    }
    .career-landing__profile-link {
      @include link--bold;
    }
  }
  .careers-quote {
    font-size: 25px;
    -webkit-font-smoothing: antialiased;
    @media #{$medium-up} {
      font-size: 30px;
      line-height: 44px;
      text-align: center;
      @include swap_direction(margin, 23px auto 0);
      width: 580px;
      letter-spacing: 0;
    }
  }
  .careers-quote__citation {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 28px;
    .careers-quote__author-img {
      margin-top: 7px;
    }
    .careers-quote__author {
      font-size: 12px;
      margin-top: 22px;
      letter-spacing: 0.15em;
    }
  }
  .careers-page__content {
    li {
      display: list-item;
      list-style-type: disc;
      list-style-position: outside;
      @include swap_direction(margin, 6px 21px);
    }
  }
  .careers-page__copy {
    margin-bottom: 25px;
  }
}

// Application:
.career-application {
  @media #{$medium-up} {
    margin-top: 47px;
  }
  a {
    text-decoration: underline;
  }
  .career-application__screening-question-label {
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 1em;
  }
  .field {
    width: 100%;
  }
  .career-application__weekday-checkbox {
    margin-bottom: 1em;
  }
  @media #{$medium-up} {
    .career-application__section {
      margin-bottom: 42px;
      max-width: 720px;
    }
    .career-application__screening-question-label {
      margin-bottom: 0;
    }
    .career-application__profile {
      .form-item {
        float: $ldirection;
        width: 33%;
        @include swap_direction(padding, 0 10px);
        &:first-child,
        &:nth-child(4n) {
          padding-#{$ldirection}: 0;
        }
        &:nth-child(3n) {
          padding-#{$rdirection}: 0;
        }
        // &:nth-child(n+4) {
        //   margin-bottom: 0;
        // }
        .field {
          width: 100%;
        }
      }
    }
    .career-application__screening-question {
      @include clearfix;
      .career-application__screening-question-label {
        padding-#{$rdirection}: 160px;
      }
      .career-application__screening-question-fields {
        float: $rdirection;
        width: 120px;
      }
    }
    .career-application__days-available-table {
      text-align: $ldirection;
      th {
        font-weight: normal;
        font-size: 12px;
      }
      tbody th {
        padding-#{$rdirection}: 15px;
      }
      thead .text--bold {
        border-bottom: solid 1px $color-light-gray;
        @include swap_direction(margin, 0 18px 20px 0);
        display: block;
        @include swap_direction(padding, 0 0 20px);
      }
      .career-application__weekday-checkbox {
        margin-bottom: 0;
        label {
          @include hide-text;
        }
      }
    }
    #form--career--field--UNABLE_TO_WORK {
      width: 410px;
    }
    #form--career--field--ESIGNATURE {
      width: 226px;
    }
    .career-application__certifications,
    .career-application__unable-to-work {
      .career-application__screening-question-label {
        margin-bottom: 13px;
      }
    }
    .career-application__screening-question--textbox {
      .career-application__screening-question-label {
        float: $ldirection;
        padding-#{$rdirection}: 20px;
        width: 68.5%;
      }
      .field {
        float: $rdirection;
        width: 31.5%;
      }
    }
    .career-application__resume {
      margin-bottom: 30px;
    }
  }
  .label-imposter-container {
    position: relative;
    display: block;
  }
  .label-imposter {
    position: absolute;
    background: $color-white;
    top: 0;
    #{$ldirection}: 24px;
    padding-top: 3px;
    font-size: 12px;
    letter-spacing: 0.05em;
    cursor: pointer;
  }
}

// Confirmation pages:
.careers-confirmation {
  text-align: center;
  min-height: 600px;
  margin-top: 47px;
}
