.my-feed-summary__welcome-back {
  @include headline--quinary--plain;
  line-height: 25px;
}

.my-feed-summary__item-count {
  @include swap_direction(margin, 7px 0);
}

.my-feed-summary__signout-wrapper {
  margin-bottom: 18px;
}

.my-feed-summary__signout {
  @include link--bold;
  display: inline-block;
  text-decoration: underline;
}
