.extended-mpp .mpp__product-grid {
  .product__inventory-status {
    margin-top: 5px;
  }
  .product__inventory-status {
    li {
      font-size: 10px;
    }
  }
  .product-brief__price {
    font-size: 12px;
    font-weight: bold;
    margin-top: 4px;
  }
  .product_brief__shade-name {
    font-size: 12px;
    font-weight: bold;
    margin-top: 4px;
    text-transform: uppercase;
    &.sized {
      text-transform: none;
    }
  }
  .quickshop-inline__price-size {
    font-size: 12px;
    font-weight: bold;
    margin-top: 4px;
  }
  .thumb.product-brief__image {
    background: none;
    padding-bottom: 0;
    height: auto;
    min-height: 155px;
    &.loader {
      margin-bottom: 14%;
    }
  }
  .rollover_plus_mobile {
    position: absolute;
    #{$rdirection}: 10px;
    top: 120px;
    @media #{$iphone6-portrait} {
      top: 145px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
      top: 205px;
    }
    @media #{$iphone6-landscape} {
      top: 275px;
      #{$rdirection}: 15px;
    }
    img {
      height: 25px;
      width: 25px;
    }
  }
  .rollover_minus_mobile {
    position: absolute;
    #{$rdirection}: 10px;
    top: 120px;
    @media #{$iphone6-portrait} {
      top: 145px;
    }
    @media #{$iphone6-landscape} {
      top: 275px;
      #{$rdirection}: 15px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
      top: 205px;
    }
    img {
      height: 25px;
      width: 25px;
    }
  }
}
