// Column widths
$col1-width: 454px;
$col2-width: 469px;
$col3-width: 443px;
$col2-col3: $col2-width + $col3-width;
$cols-width: $col1-width + $col2-width + $col3-width; //1366

$col1-width: percentage($col1-width / $cols-width);
//$col2-width: percentage($col2-width / $cols-width);
//$col3-width: percentage($col3-width / $cols-width);

$dual-col-width: percentage($col2-col3 / $cols-width);
$dual-col1-width: percentage($col2-width / $col2-col3);
$dual-col2-width: percentage($col3-width / $col2-col3);

@mixin feed_sub_header {
  font-family: $akzidenz;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.my-feed-tray--loyalty {
  // bg for dual column
  &:before {
    background: url($base-theme-image-path + 'rgbapng/9eafff1a-10.png');
    background: rgba(158, 175, 255, 0.1);
    content: '';
    position: absolute;
    width: $dual-col-width;
    height: 100%;
    top: 0;
    #{$rdirection}: 0;
    z-index: 1;
  }

  // use .elc-user-state-anonymous & {
  &.anon {
    .is_auth {
      display: none; // hide all authenticated content
      &.loyalty-offer__link {
        display: inline-block;
      }
    }
  }
  &.auth {
    .is_anon {
      display: none; // hide all anon content
    }
    .loyalty-offer__terms {
      @include swap_direction(margin, 23px 0 0 0);
    }
  }
  &.loyal {
    .is_loyal {
      display: block;
    }
    .not_loyal {
      display: none;
    }
  }
  &.level__3 {
    .my-feed-loyalty__status-display {
      //border-#{$rdirection}: none;
      //width: 100%;
    }
    .my-feed-loyalty__status-next {
      display: none;
    }
    .my-feed-loyalty__status-offer__tier3 {
      display: block;
    }
  }
  &.my-feed-tray--0 {
    .my-feed-loyalty__status-next {
      display: none;
    }
    .my-feed-loyalty__zero-points {
      display: block;
    }
    // .my-feed-loyalty__status-container {
    //   margin-bottom: 14px;
    // }
  }
  &.my-feed-tray--newsletter {
    .loyalty-offer__terms {
      @include swap_direction(margin, 4px 0 0 0);
    }
  }
  .my-feed-tray__inner {
    @include clearfix;
    position: relative;
    z-index: 2; // clear dual column bg
    .my-feed-tray__col {
      float: $ldirection;
    }
    .my-feed-tray__dual-col {
      float: $ldirection;
      width: $dual-col-width;
      @include swap_direction(padding, 30px 0 10px 0); //padding: 42px 0 10px 0;
    }
    .my-feed-tray__dual-col-inner {
      @include clearfix;
    }
  }

  // summary
  .my-feed-summary {
    width: $col1-width;
    @include swap_direction(padding, 45px 50px 20px 58px); //padding: 60px 50px 0 58px;
  }
  .my-feed-tray__col .my-feed-summary__header {
    @include headline--quinary--plain;
    @include swap_direction(margin, 3px 0 0 0);
  }
  .my-feed-summary__copy {
    font-family: $optimaregular;
    font-size: 16px;
    line-height: 20px;
    @include swap_direction(margin, 7px 0 15px);
  }
  .my-feed-summary__link {
    font-size: 12px;
    letter-spacing: 0.15em;
    text-decoration: underline;
    font-weight: bold;
    text-transform: uppercase;
  }

  // loyalty
  .my-feed-loyalty-wrapper {
    width: $dual-col1-width;
    text-align: $ldirection;
    @include swap_direction(padding, 0 15px 0 22px);
    @media #{$large-up} {
      // 769
      @include swap_direction(padding, 0 20px 0 32px);
    }
    // @media #{$xlarge-up} { // 1024
    //   @include swap_direction(padding, 0 30px 0 42px);
    // }
    // @media #{$xxlarge-up} { // 1280
    //   @include swap_direction(padding, 0 40px 0 62px);
    // }
  }
  .my-feed-loyalty__join {
    @include swap_direction(padding, 15px 70px 10px 0); //padding: 20px 70px 0 0;
    width: $dual-col2-width;
  }
  .my-feed-loyalty__sub-header {
    font-family: $optimaregular;
    font-size: 24px;
    line-height: 26px;
  }
  .my-feed-loyalty__header {
    font-family: $optimaregular;
    font-size: 22px;
    line-height: 30px;
    text-transform: lowercase;
    &--joined {
      text-transform: none;
      margin-bottom: 3px;
    }
  }
  .my-feed-loyalty__joined {
    display: none;
    @include swap_direction(padding, 14px 0 10px 0); //padding: 14px 0 40px 0;
    line-height: normal;
  }
  .my-feed-loyalty__loyalty-status {
    @include clearfix;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-size: 12px;
    .level-text {
      text-transform: none;
      display: block;
      float: $ldirection;
    }
  }
  .my-feed-loyalty__status-container {
    @include clearfix;
    @include swap_direction(margin, 9px 0);
    .next-level-points {
      font-weight: bold;
    }
  }
  .my-feed-loyalty__status-display {
    float: $ldirection;
    width: 33%;
    border-#{$rdirection}: 1px solid $color-white;
    font-family: $optimaregular;
    line-height: 30px;
    @include swap_direction(padding, 7px 5px 7px 0);
    font-size: 16px;
    @media #{$xlarge-up} {
      //1024
      @include swap_direction(padding, 7px 10px 7px 0);
      font-size: 20px;
    }
    // @media #{$xxlarge-up} { //1280
    //   @include swap_direction(padding, 7px 15px 7px 0);
    //   font-size: 22px;
    // }
  }
  .my-feed-loyalty__status-next,
  .my-feed-loyalty__zero-points,
  .my-feed-loyalty__status-offer__tier3 {
    float: $ldirection;
    width: 66%;
    font-size: 12px;
    line-height: 22px;
    text-transform: uppercase;
    @include swap_direction(padding, 1px 0 0 12px);
  }
  .my-feed-loyalty__zero-points {
    display: none;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.15em;
    &-offer {
      color: $color-periwinkle-blue;
    }
  }
  .my-feed-loyalty__status-offer__tier3 {
    display: none;
  }
  .my-feed-loyalty {
    &-container {
      max-width: 410px;
      position: relative;
      .bold {
        font-weight: bold;
      }
    }
    &__promo {
      @include swap_direction(margin, 6px 0 14px 0);
      font-size: 12px;
      letter-spacing: 0.15em;
      line-height: normal;
      text-transform: uppercase;
    }
    &__form {
      @include clearfix;
      &-container {
        @include clearfix;
        margin-top: 3px;
        .elc-user-state-logged-in & {
          display: none;
        }
        .elc-user-state-anonymous & {
          display: block;
        }
      }
      input[type='text'] {
        font-size: 13px;
        float: $ldirection;
        width: 69%; //268px;
        @include swap_direction(margin, 0 9px 4px 0);
      }
      input[type='submit'] {
        float: $ldirection;
        width: 96px;
        margin-#{$ldirection}: 0;
        background: transparent;
      }
    }
    &__checkbox {
      &-container {
        margin-top: 6px;
      }
    }
    &__label {
      font-size: 12px;
      line-height: normal;
      letter-spacing: normal;
      text-transform: none;
    }
    &__link {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      color: $color-periwinkle-blue;
      text-decoration: underline;
      &-container {
        margin-top: 4px;
      }
    }
    &__terms {
      font-size: 10px;
      line-height: normal;
      letter-spacing: normal;
    }
    &__points {
      text-decoration: underline;
    }
    &__account {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      color: $color-periwinkle-blue;
      text-decoration: underline;
    }
    &__zero-points {
      display: none;
    }
    &__join-btn {
      width: 96px;
      &-container {
        .elc-user-state-logged-in & {
          display: block;
          @include swap_direction(margin, 10px 0 18px 0);
        }
        .elc-user-state-anonymous & {
          display: none;
        }
      }
    }
  }

  // loyalty offer
  .loyalty-offer {
    max-width: 370px;
    @include swap_direction(padding, 0 0 40px 0);
  }
  .loyalty-offer__header-image {
    display: block;
    margin-bottom: 8px;
  }
  .loyalty-offer__header {
    font-family: $optimaregular;
    font-size: 18px;
    margin-bottom: 10px;
    letter-spacing: -0.01em;
  }
  .loyalty-offer__text {
    .loyalty-offer__text-inner {
      font-family: $akzidenz;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      max-width: 270px;
    }
  }
  .loyalty-offer__terms {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    @include swap_direction(margin, 9px 0 0 0);
    a {
      color: $color-periwinkle-blue;
      text-decoration: underline;
    }
  }
  .loyalty-offer__link {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: $color-periwinkle-blue;
    text-decoration: underline;
    &-container {
      margin-top: 4px;
    }
    &.is_anon {
      display: none;
    }
  }
  .my-feed-tray__col.special-offer {
    @include clearfix;
    width: $dual-col2-width;
    min-height: auto;
    @include swap_direction(padding, 0 20px 0 0);
    @include swap_direction(margin, 0);
    .page-sticky-footer .drawer-formatter__content & {
      background: transparent;
    }
    .special-offer__img {
      @include swap_direction(margin, 1px 20px 0 0);
      float: $ldirection;
      width: 44%;
      max-width: 164px;
      // add percentage and min-wdth
    }
    .special-offer__container {
      overflow: hidden;
    }
    .special-offer__header {
      font-family: $optimaregular;
      font-size: 22px;
      font-weight: normal;
      letter-spacing: 0;
      @include swap_direction(margin, 12px 0 0 0);
      line-height: 35px;
    }
    .special-offer__sub-header {
      margin-top: 3px;
      font-family: $optimaregular;
      font-size: 22px;
      line-height: 30px;
    }
    .special-offer__description {
      margin-top: 3px;
      font-family: $optimaregular;
      font-size: 16px;
      line-height: 24px;
    }
    .special-offer__description-container {
      font-family: $optimaregular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0;
      @include swap_direction(margin, 7px 0 2px);
      span {
        font-size: inherit !important;
      }
    }
    .special-offer__code {
      margin-top: 10px;
      font-size: 12px;
      line-height: 17px;
      text-transform: uppercase;
      .offer_code {
        color: $color-periwinkle-blue;
      }
    }
    .special-offer__button {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      color: $color-periwinkle-blue;
      text-decoration: underline;
    }
    .special-offer__button-container {
      margin-top: 22px;
      .button {
        position: static;
      }
    }
  }
}

.my-feed-drawer .drawer-formatter__trigger {
  text-transform: uppercase;
}
