// carousel formatter specific to estee edit landing
.ee-carousel-formatter {
  width: 100%;
  @media #{$medium-up} {
    max-width: 1024px;
    margin-bottom: 48px;
  }
  @include swap_direction(margin, 0 auto);
  text-align: center;
  .ee-tout__logo {
    display: none;
  }
  .ee-slick-carousel__logo {
    position: fixed;
  }
  [type='button'] {
    @media #{$small-only} {
      display: none !important;
    }
  }
  .slick-prev,
  .slick-next {
    @media #{$medium-up} {
      margin-top: 10px;
    }
  }
}

.ee-slick-carousel__logo,
.ee-tout__logo {
  position: absolute;
  z-index: 5;
  #{$ldirection}: 50%;
  width: 250px;
  margin-#{$ldirection}: -125px;
  @media #{$medium-up} {
    width: 334px;
    margin-#{$ldirection}: -162px;
    &.logo-scale {
      width: 167px;
      margin-#{$ldirection}: -83px;
    }
  }
}

// add a margin
// only when outside the carousel
.ee-tout-wrapper {
  margin-bottom: 100px;
  -webkit-transform-style: preserve-3d;
  .ee-carousel-formatter & {
    margin-bottom: 0;
  }
}

// EE tout slides or standalone components
// 800x460 - 1024
// 600x390 - 768
// 500x325 - 640
// 250x162 - 320
.ee-tout-inner {
  max-width: 100%;
  min-height: 230px;
  position: relative;
  @include swap_direction(margin, 0 auto);
  @media #{$medium-up} {
    max-width: 800px;
    min-height: 520px;
  }
}

.ee-tout {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
}

.ee-tout__content {
  position: relative;
  text-align: center;
  top: 50%;
  #{$ldirection}: 0;
  #{$rdirection}: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: 30px;
  .text-promo__content {
    .text-promo__subcopy {
      @include swap_direction(padding, 30px 0 0 0);
    }
  }
  .eyebrow {
    font-weight: bold;
    margin-bottom: 1.25em;
    @media #{$small-only} {
      display: none;
    }
  }
  a.ee-tout__link {
    display: block;
    padding-top: 30px;
  }
  .header__headline {
    font-size: 50px;
    @media #{$medium-up} {
      font-size: 116px;
    }
    &.headline--medium {
      font-size: 32px;
      @media #{$medium-up} {
        font-size: 90px;
      }
    }
  }
  .subcopy {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    padding-top: 10px;
    @media #{$medium-up} {
      font-size: 14px;
      line-height: 24px;
      @include swap_direction(padding, 30px 0 20px 0);
    }
  }
}

.ee-tout__image {
  position: absolute;
  top: 45px;
  width: 100%;
  z-index: 1;
  @media #{$medium-up} {
    top: 60px;
  }
}

.ee-tout__link.light {
  color: $color-white;
}
