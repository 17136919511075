$ee-step__img-width: 308px;
$ee-step__img-height: 260px;
$ee-step__img-margin: 19px;
$step-selector: '.ee-how-to__step';
#{$step-selector} {
  @include clearfix();
  margin-bottom: 80px;
  border-top: 1px solid $color-light-gray;
  padding-top: 60px;
  & ~ & {
    // all steps after first one. similar to `:not(:first-child` but doesn't require a wrapper.
    border-top: 0;
    padding-top: 0;
  }
  @media #{$medium-up} {
    position: relative;
    padding-#{$ldirection}: $ee-step__img-width + $ee-step__img-margin;
    min-height: $ee-step__img-margin;
    .ee-how-to__step__image {
      position: absolute;
      top: 60px;
      #{$ldirection}: 0;
      #{$step-selector} ~ & {
        top: 0;
      }
      @at-root (without: media) {
        @include swap_direction(margin, 0 auto);
        display: block;
      }
    }
    &.has-img-on-right {
      padding-#{$ldirection}: 81px; // I believe they will one day want this removed; replace with a "0".
      padding-#{$rdirection}: $ee-step__img-width + $ee-step__img-margin;
      .ee-how-to__step__image {
        #{$ldirection}: auto;
        #{$rdirection}: 0;
      }
    }
  }
}

.ee-how-to__step__image {
}

.ee-how-to__step__headline {
}

.ee-how-to__step__caption {
  @include swap_direction(margin, auto);
}

.ee-how-to__step__copy {
  a {
    @include link--bold;
  }
}
