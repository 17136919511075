.special-offers-page {
  width: 100%;
  max-width: 1366px;
  @include swap_direction(margin, 0 auto);
}

.promo-section-wrapper {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
  h3.promo-section-title {
    text-align: center;
    margin-bottom: 20px;
  }
  .promo-blocks {
    &.two-columns {
      & > div {
        width: 50%;
        min-height: 180px;
        float: $ldirection;
      }
    }
    &.three-columns {
      & > div {
        width: 33%;
        min-height: 180px;
        float: $ldirection;
        p {
          padding-#{$rdirection}: 20px;
        }
      }
    }
  }
}

.promo-section-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  &.more-special-offers {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-top: 50px;
    margin-bottom: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    .promo-blocks.two-columns {
      .promo-unit--onecol + div {
        .promo-unit {
          border-#{$ldirection}: 1px solid $color-light-gray;
          padding-#{$ldirection}: 20px;
        }
      }
    }
  }
}
