// Column widths:
$email-signup-width: 292px;
$todays-offer-width: 426px;
$benefits-width: 306px;
$cols-width: $email-signup-width + $todays-offer-width + $benefits-width;

$email-signup-width: percentage($email-signup-width / $cols-width);
$todays-offer-width: percentage($todays-offer-width / $cols-width);
$benefits-width: percentage($benefits-width / $cols-width);

.special-offers-tray {
  @include clearfix;
  .special-offers-tray__col {
    float: $ldirection;
  }
  // Display Logic
  .offers-register-form,
  .offers-sign-in-form,
  &.register .offers-email-signup-form,
  &.sign-in .offers-email-signup-form {
    display: none;
  }
  &.register .offers-register-form,
  &.sign-in .offers-sign-in-form {
    display: block;
  }

  // Don't ever show any of these forms if user is authenticated:
  .elc-user-state-logged-in & {
    .offers-email-signup-form,
    .offers-sign-in-form,
    .offers-register-form {
      display: none;
    }
  }

  // Sign up, Register, Sign in:
  .offers-email-signup-form,
  .offers-register-form,
  .offers-sign-in-form {
    width: $email-signup-width;
    @include swap_direction(padding, 20px 20px 0 30px);
    .text--form-help p {
      @include swap_direction(margin, 0);
    }
    .headline--offers-tray {
      @include headline--quinary;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .form-text,
    .button {
      width: 70%;
    }
    .form-item {
      @include swap_direction(margin, 0 0 8px);
      @include swap_direction(padding, 0);
    }
    .form-text {
      margin-bottom: 0;
    }
  }

  // Sign Up
  .offers-email-signup-form {
    .offers-email-signup-form__errors {
      margin-bottom: 12px;
    }
    .offers-email-signup-form__email {
      float: $ldirection;
    }
    .button {
      display: block;
      float: $rdirection;
    }
    .offers-email-signup-form__sign-in-wrapper {
      @include text--short;
      padding-top: 5px;
      clear: both;
      text-transform: uppercase;
      a {
        @include link--bold;
        text-decoration: underline;
      }
    }
    .offers-email-signup-form__message {
      @include text--short;
    }
  }

  // Register

  .offers-register-form {
    .offers-register-form__success {
      margin-bottom: 8px;
    }
    .offers-register-form__success,
    .offers-register-form__terms,
    .offers-register-form__terms p {
      letter-spacing: -0.02em;
      line-height: 20px;
    }
    .offers-register-form__terms p {
      @include swap_direction(margin, 0);
    }
  }

  // Sign in
  .offers-sign-in-form {
  }
  .special-offer {
    width: $todays-offer-width;
  }
  .gift-wrap {
    .elc-user-state-anonymous & {
      display: none;
    }
    width: $email-signup-width;
  }
  .special-offer-benefits {
    width: $benefits-width;
  }
  .gift-wrap,
  .special-offer-benefits {
    @include swap_direction(padding, 60px 30px 0 20px);
    .gift-wrap__content,
    .special-offer-benefits__content {
      @include headline--quinary;
      line-height: 1.6em;
      margin-bottom: 5px;
    }
    .gift-wrap__link-wrapper a,
    .special-offer-benefits__link-wrapper a {
      @include link--bold;
      text-decoration: underline;
    }
  }
}

.special-offers-drawer .drawer-formatter__trigger {
  font-weight: bold;
}
