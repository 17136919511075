.contact-form {
  .contact-form__section {
    @include swap_direction(margin, 0 0 10px);
    max-width: 569px;
    @media #{$medium-up} {
      @include swap_direction(margin, 0 0 50px);
    }
  }
  .error_messages {
    li {
      display: block;
      list-style-type: none;
      @include swap_direction(margin, 0);
    }
  }
  .contact-form__address-label {
    text-transform: none;
    letter-spacing: 0.05em;
  }
  .contact-form__required-copy {
    @include swap_direction(margin, 0 0 10px);
  }
  .contact-form__section--question-types {
    @media #{$medium-up} {
      width: 100%;
      max-width: 100%;
    }
    .question-1-desc,
    .form-item {
      margin-bottom: 13px;
    }
    .form-item.contact-form__question-type {
      & > span {
        @media #{$medium-up} {
          margin-#{$rdirection}: 20px;
          display: inline-block;
        }
      }
    }
    .contact-form__sub-question-types {
      display: none;
      @include swap_direction(padding, 13px 0 0 27px);
    }
    .contact-form__sub-question-help {
      @include swap_direction(margin, 13px 0 0 25px);
    }
    .contact-form__sub-question-subtype {
      @include swap_direction(margin, 13px 0 0 25px);
      .sub-question-subtype1 {
        margin-#{$rdirection}: 88px;
      }
      .sub-question-subtype2 {
        margin-#{$rdirection}: 60px;
      }
      .sub-question-subtype3 {
        margin-#{$rdirection}: 5px;
      }
    }
  }
  .address-form__address-fields {
    .required-mark {
      display: none;
    }
    &.required .required-mark {
      display: inline;
    }
  }
  .address-form__fieldset {
    @include swap_direction(padding, 0);
    .text--checkbox-label {
      @include swap_direction(margin, 0);
    }
    input[type='radio'] {
      @include swap_direction(margin, 0 5px);
    }
    .button-loading {
      width: 100%;
      float: $rdirection;
      @media #{$medium-up} {
        width: auto;
      }
    }
  }
  .main-button-wrapper {
    @include swap_direction(padding, 0);
    clear: both;
  }
}

.contact-form-confirm {
  display: none;
  .contact-form-confirm__faqs {
    margin-top: 20px;
  }
  .contact-form-confirm__faqs-list {
    li {
      display: block;
      list-style-type: none;
    }
    a {
      text-transform: uppercase;
    }
  }
}
