///
/// @file drupal_modules/esteelauder_endeca/_pc_block_search.scss
///
/// \brief styles for the PC search block
///
/// used in template:
/// esteelauder_endeca/templates/endeca_search_block_v1/
/// and various theme based endeca tpl files
///

.el-search-block {
  width: 100%;
  position: absolute;
  #{$ldirection}: 0;
  background-color: $color-navy;
  color: $color-white;
  z-index: 200;
  @include swap_direction(padding, 80px 0 20px 0);
  // starting hidden state
  display: none;
  // show with active search class
  #{$search} & {
    display: block;
  }
  #{$alt-color-white} & {
    background-color: $color-white;
    color: $color-navy;
  }
  .loading {
    display: none;
    position: absolute;
    top: 1px;
    #{$ldirection}: 0;
    text-transform: none;
  }
  .results-header {
    @include swap_direction(padding, 20px 0);
  }
  a {
    color: $color-white;
    #{$alt-color-white} & {
      color: $color-navy;
      font-size: 15px;
      font-family: $akzidenz;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
    &:hover {
      color: $color-periwinkle-blue;
      #{$alt-color-white} & {
        color: $color-navy;
        text-decoration: underline;
      }
    }
  }
  input[type='text'].form-text {
    @include swap_direction(border, none);
    border-bottom: 1px solid $color-medium-navy;
    box-shadow: none;
    color: $color-white;
    font-size: 80px;
    font-family: $optimalight;
    letter-spacing: -0.02em;
    height: 100px;
    line-height: 100px;
    @include swap_direction(padding, 0 75px 5px 0);
    width: 100%;
    background-color: transparent;
    #{$alt-color-white} & {
      @include input-placeholder {
        color: $color-navy;
        background-color: $color-white;
        text-transform: inherit;
      }
      padding: 0;
      font-size: 24px;
      height: 64px;
      border: none;
      border-bottom: 1px solid $color-medium-navy;
      color: $color-navy;
    }
    #{$alt-color-white}#{$alt} & {
      @include box-shadow(none);
      @include input-placeholder {
        color: $color-navy;
        background-color: transparent;
      }
      border: none;
      border-bottom: 1px solid $color-medium-navy;
      color: $color-navy;
      background: $color-white;
      padding: 0;
      margin-top: -10px;
      &.filled-in {
        color: $color-navy;
        border: none;
        border-bottom: 1px solid $color-medium-navy;
      }
    }
    &:focus {
      border-bottom: 1px solid #3e446b;
    }
  }
  #{$alt-color-white}#{$search} & {
    @include swap_direction(padding, 0 46px 32px 24px);
  }
}

.el-search-block__input-container {
  position: relative;
}

.el-search-block__content,
.el-search-block__links {
  // above ipad portrait
  @media #{$large-up} {
    width: 800px;
    @include swap_direction(margin, 0 auto);
    #{$alt-color-white} & {
      width: 100%;
    }
  }
}

.el-search-block__links {
  padding-top: 20px;
  #{$alt-color-white} & {
    padding: 0;
  }
  h5 {
    font-family: $akzidenz;
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: 20px;
    #{$alt-color-white} & {
      font-weight: bold;
      line-height: 28px;
      padding-bottom: 0;
      letter-spacing: 0.15em;
    }
  }
  a {
    @include print-size-tracking(50px, -20);
    font-family: $optimalight;
  }
}

.see-all-results-container {
  text-transform: uppercase;
  float: $rdirection;
  padding-top: 20px;
}

a.see-all-results {
  display: none;
  text-decoration: underline;
}

.el-search-block__btn-submit {
  background-size: auto auto;
  background-position: 0 -8196px;
  width: 43px;
  height: 100px;
  display: block;
  line-height: 100px;
  cursor: pointer;
  position: absolute;
  #{$rdirection}: 0;
}

.el-search-block__product-results {
  @include swap_direction(margin, 0 auto);
  @include pie-clearfix;
  width: 768px;
  @media #{$large-up} {
    width: 800px;
    @include swap_direction(margin, 0 auto);
    @include pie-clearfix;
  }
  .product-result {
    width: 144px;
    margin-#{$rdirection}: 48px;
    position: relative;
    float: $ldirection;
    @media #{$large-up} {
      margin-#{$rdirection}: 74px;
      &:nth-child(4n + 4) {
        //clear every row at 4th product
        clear: $rdirection;
        margin-#{$rdirection}: 0;
      }
    }
  }
  .product-result.last {
    clear: $rdirection;
    margin-#{$rdirection}: 0;
  }
}

.product-result__content {
  text-align: center;
}

.product-result__image {
  @media #{$large-up} {
    width: 144px;
    height: 198px;
    background: $color-light-gray;
    // @todo: temp bg color
    background: $color-medium-gray;
  }
}

///
/// typeahead
///
.typeahead-wrapper {
  @include pie-clearfix;
  color: $color-white;
  background: $color-navy;
  z-index: 1000;
  #{$alt-color-white} & {
    color: $color-navy;
    background: $color-white;
    @include swap_direction(padding, 0 75px 0 24px);
  }
  .typeahead-product-results {
    @include pie-clearfix;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-medium-navy;
    @include swap_direction(margin, 38px 0 16px 0);
  }
  .product-result__info {
    @include min-height(130px);
    font-family: $optimaregular;
    font-size: 12px;
  }
  .product-result__name {
    @include headline--quinary;
    margin-top: 16px;
  }
  .product-result__sub-name {
    @include headline--senary;
    margin-top: 8px;
  }
  .product-result__price {
    margin-top: 6px;
  }
  .product-result__button {
    margin-top: 8px;
    #{$alt-color-white} & {
      a {
        color: $color-white;
        font-size: 12px;
      }
    }
  }
}

.typeahead-header {
  @include pie-clearfix;
}

.typeahead-summary {
  float: $ldirection;
  text-transform: uppercase;
  .no-results .user-search-term {
    display: inline;
  }
  p {
    @include text--short;
    @include swap_direction(margin, 0);
  }
}

.typeahead-see-results {
  float: $rdirection;
  a {
    @include link;
  }
}

.is-search {
  .page-utilities__search-button {
    .page-utilities__search-icon {
      mask: url('/sites/esteelauder/themes/estee_base/img/icons/src/close.svg') center center no-repeat;
      -webkit-mask: url('/sites/esteelauder/themes/estee_base/img/icons/src/close.svg') center center no-repeat;
      background-size: auto auto;
      width: 20px;
      height: 20px;
      margin-top: 1px;
    }
  }
  .search-input-container .page-utilities__search-button {
    height: 40px;
    border-#{$ldirection}: 0;
  }
  .page-navigation.active-menu-toogle {
    display: none;
  }
}
