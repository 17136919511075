///
/// @file esteelauder_custom_brand/_aerin_tout_footer.scss
///
/// \brief styles for template field module at:
///
///  esteelauder_custom_brand/templates/aerin_tout_footer_v1
///  @todo may need to add a color selection for headline

.aerin-footer-tout {
  @include swap_direction(margin, 0 auto 100px auto);
  max-width: 1300px;
  text-align: center;
  position: relative;
  -webkit-transform-style: preserve-3d;
}

.aerin-footer-tout__content {
  position: absolute;
  z-index: 3;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  #{$ldirection}: 0;
  #{$rdirection}: 0;
  .cta {
    font-size: 16px;
    height: 56px;
    line-height: 56px;
    @include swap_direction(padding, 0 56px);
  }
}

.aerin-footer-tout__headline {
  color: $color-white;
  font-family: 'NarzissRegular';
  font-size: 70px;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
}

.aerin-footer-tout__bg {
  //position: absolute;
  z-index: 1;
}

.aerin-footer-tout__img {
  max-width: 100%;
}
