/**
 * @file drupal_modules/esteelauder_product/_sku_brief_editorial.scss
 * homepage template style b
 *
 * \brief Styling for template field partial at:
 *    esteelauder_product/templates/_partials/sku_brief_editorial.mustache
 */

.sku-brief-editorial__image {
  width: 144px;
  height: 200px;
  background-color: #eaebef;
  @include swap_direction(margin, 0 auto 1em auto);
}

.sku-brief-editorial__shade {
  text-transform: uppercase;
  line-height: 16px;
  .swatch {
    display: inline-block;
    @include border-radius(8px, 8px);
    height: 16px;
    width: 16px;
    @include swap_direction(margin, 2px 8px 0 0);
    vertical-align: bottom;
  }
}
