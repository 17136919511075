.cart-item-table {
  .dw__order-history {
    &__cart-item {
      &__cell {
        display: block;
        .shade {
          width: 20px;
          height: 20px;
          border-radius: 20px;
          display: inline-block;
          &-name {
            position: relative;
            bottom: 4px;
          }
        }
        .cart-kit-contents {
          border-bottom: 1px solid $color-light-gray;
          @media #{$large-up} {
            margin-top: -40px;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    &__sku {
      &__shadename {
        @media #{$xlarger-only} {
          width: 81%;
        }
        width: 71%;
      }
    }
    &__shade {
      height: 10px;
    }
  }
}

.order-history-purchases__bundled {
  .past-purchases {
    &__cart-item {
      text-align: #{$ldirection};
      padding-#{$ldirection}: 34.5%;
    }
  }
}
