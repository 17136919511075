.signin-signup-update {
  .checkout {
    margin-top: 0;
    @media #{$medium-up} {
      margin-top: 26px;
    }
    &__content {
      border-#{$rdirection}: 0;
      float: none;
      width: 100%;
      .sign-in-panel {
        width: 100%;
        @media #{$medium-up} {
          border-bottom: 0;
          width: 562px;
        }
        &__wrapper {
          margin: 0 auto;
          width: 100%;
          @media #{$medium-up} {
            border: 1px solid $color-grey-border;
            width: 514px;
          }
        }
        &__link {
          border-bottom: 4px solid $color-light-gray;
          color: $color-black-dark-transparent;
          cursor: pointer;
          float: #{$ldirection};
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 1.25px;
          line-height: 1;
          margin: 0;
          outline: none;
          padding: 30px 0 12px;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          width: 50%;
          &.active {
            border-bottom-color: $color-black;
            color: $color-blue-dark;
            font-weight: bold;
          }
          @media #{$medium-up} {
            padding: 15px 0;
          }
        }
        .form-item {
          height: 46px;
          margin-bottom: 20px;
          .field {
            border-radius: 0;
            border-color: $color-grey-text;
            color: $color-grey-text;
            font-size: 12px;
            height: 46px;
            letter-spacing: 1.8px;
            margin-bottom: 15px;
            padding: 10px 15px;
            width: 100%;
            @media #{$medium-up} {
              width: 400px;
            }
            &.filled-in {
              border-color: $color-black;
              color: $color-black;
            }
          }
          .label-content {
            color: $color-blue-dark;
            font-size: 12px;
            line-height: 1.5;
          }
          &.email-promotions {
            height: auto;
            margin-top: 20px;
            margin-bottom: 15px;
          }
        }
        .guest-user-disclaimer {
          line-height: 1.5;
          margin-bottom: 20px;
          a {
            text-decoration: underline;
          }
        }
        #forgot-pw-note {
          display: initial;
        }
        #forgot_password {
          display: inline-block;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0;
          margin-top: 5px;
          margin-bottom: 15px;
          text-transform: capitalize;
        }
        .btn.form-submit {
          color: $color-white;
          font-size: 12px;
          font-weight: bold;
          height: 48px;
          letter-spacing: 1.8px;
          line-height: 1.5;
          width: 100%;
          @media #{$medium-up} {
            width: 400px;
          }
        }
      }
      .substate-new-account {
        #new-account {
          padding: 0 16px;
          width: 100%;
          @media #{$medium-up} {
            padding: 0 57px;
          }
        }
        .social-login {
          margin-top: 15px;
          &__divider {
            display: none;
          }
          &__terms {
            margin-bottom: 0;
          }
        }
      }
      .substate-return-user {
        .checkout__return-user {
          padding: 0 16px;
          @media #{$medium-up} {
            padding: 0 57px;
            width: 100%;
          }
          #return-account-h {
            display: block;
          }
          .form-item {
            margin-bottom: 20px;
            &.password {
              margin-bottom: 15px;
              .field {
                &::-webkit-credentials-auto-fill-button {
                  #{$rdirection}: 22px;
                  position: relative;
                }
              }
            }
          }
        }
        .social-login {
          #facebook_signin {
            display: none;
          }
        }
      }
      .panel {
        &.edit {
          #messages {
            padding: 0;
          }
        }
      }
    }
    &__subtitle {
      color: $color-blue-dark;
      font-size: 24px;
      font-family: $optimalight;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1;
      margin: 25px 0;
      text-align: center;
      text-transform: none;
      @media #{$medium-up} {
        margin: 30px 0 15px;
      }
    }
    .continue_with_checkout {
      color: $color-blue-dark;
      font-size: 14px;
      letter-spacing: 0;
      margin-bottom: 15px;
      text-align: center;
    }
  }
  &.page-wrapper-checkout {
    .adpl {
      &#checkout_signin,
      &#checkout_signin_new_user {
        .field {
          & + label {
            font-size: 12px;
            line-height: 2;
            letter-spacing: 1.8px;
            pointer-events: none;
            text-transform: uppercase;
            &::before {
              color: $color-grey-text;
              margin: 0 15px;
            }
          }
        }
        .cart-label-mode {
          & + label {
            &::before {
              transform: translateY(-22px) scale(0.8, 0.8);
            }
          }
        }
      }
    }
    .checkout {
      &__content {
        .checkout-header {
          display: none;
        }
      }
      &__sidebar {
        display: none;
      }
      .order-summary-section {
        display: none;
      }
    }
    .our-guarantee-panel {
      display: none !important;
    }
  }
  .password-eye-icon {
    #{$rdirection}: 10px;
    cursor: pointer;
    position: absolute;
    top: 18px;
    .show-password-icon,
    .hide-password-icon {
      background: url('/media/images/checkout/show_password_icon.svg') no-repeat 0 2px;
      color: $color-black;
      cursor: pointer;
      display: block;
      height: 24px;
      opacity: 0.54;
      width: 24px;
    }
    .hide-password-icon {
      background: url('/media/images/checkout/hide_password_icon.svg') no-repeat;
    }
  }
  .social-login {
    margin: 0 auto;
    padding: 0 16px 35px;
    width: 100%;
    @media #{$medium-up} {
      margin: 0 57px;
      padding: 0 0 40px;
      width: 400px;
    }
    .fb-login-button {
      height: 48px;
      opacity: 0;
      width: 100%;
    }
    .custom-fb {
      display: block;
      position: relative;
      width: 100%;
      &__content {
        background: $color-fb-blue;
        cursor: pointer;
        height: 48px;
        line-height: 2.8;
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
      &__icon {
        border-#{$rdirection}: 1px solid $color-danube-blue;
        float: $ldirection;
        padding: 12px;
        width: 49px;
      }
      &__desc {
        color: $color-white;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1.8px;
        line-height: 4.3;
        text-transform: uppercase;
        width: 280px;
        @media #{$medium-up} {
          width: 352px;
        }
      }
    }
    &__container {
      padding: 0;
      @media #{$medium-up} {
        margin: 0;
      }
      .email-optin {
        display: none;
      }
    }
    &__terms {
      padding: 0;
      margin-top: 0;
      margin-bottom: 20px;
      p {
        color: $color-blue-dark;
        font-size: 12px;
        font-style: normal;
        letter-spacing: 0;
        line-height: 1.5;
      }
    }
    &__divider {
      margin: 20px 0 15px;
      position: relative;
      &::before {
        background: $color-grey-border;
        top: 45%;
      }
      .inner {
        color: $color-blue-dark;
        font-family: $akzidenz;
        font-size: 12px;
        font-weight: bold;
        height: 20px;
        letter-spacing: 1.8px;
        line-height: 2;
        padding: 0;
        text-transform: uppercase;
        width: 34px;
      }
    }
  }
  .sign-in-page {
    .sign-in-panel {
      margin-bottom: 20px;
      padding: 0;
      @media #{$medium-up} {
        padding: 45px 0;
      }
      .sign-in-component {
        @media #{$medium-up} {
          border: 1px solid $color-grey-border;
          padding: 0;
          width: 514px;
        }
        &__header {
          font-size: 24px;
          margin: 10px auto 25px;
          letter-spacing: 0;
          text-align: center;
          text-transform: initial;
          width: auto;
          @media #{$medium-up} {
            margin: 25px auto 30px;
            width: 360px;
          }
        }
        &__subheader {
          display: none;
        }
        &__form {
          margin-top: 30px;
          padding: 0 16px;
          @media #{$medium-up} {
            border-top: 0;
            padding: 0;
          }
          .form-item {
            height: 46px;
            margin-bottom: 20px;
            text-align: center;
            &.password-field {
              margin-bottom: 5px;
              overflow: initial;
              .form-text {
                float: none;
                &::-webkit-credentials-auto-fill-button {
                  #{$rdirection}: 22px;
                  position: relative;
                }
              }
              .password-eye-icon {
                #{$rdirection}: 3%;
                color: $color-grey-text;
                top: 15px;
                @media #{$medium-up} {
                  #{$rdirection}: 13%;
                  top: 10px;
                }
              }
            }
            &.text--form-help {
              clear: both;
              color: $color-blue-dark;
              font-size: 14px;
              height: 32px;
              line-height: 2.2;
              letter-spacing: 0;
              margin: 0 auto;
              text-align: $ldirection;
              width: 360px;
              @media #{$medium-up} {
                width: 78%;
              }
            }
            .form-text,
            .field {
              border-radius: 0;
              color: $color-grey-text;
              float: none;
              font-size: 12px;
              height: 46px;
              letter-spacing: 1.8px;
              margin-bottom: 15px;
              padding: 10px 15px;
              width: 100%;
              @media #{$medium-up} {
                width: 400px;
              }
              &.filled-in {
                border-color: $color-black;
                color: $color-black;
              }
              &.error {
                border-color: $color-red-error-border;
              }
            }
            .label {
              #{$ldirection}: 0;
              bottom: 16px;
              color: $color-grey-text;
              font-size: 12px;
              letter-spacing: 1.8px;
              line-height: 2.5;
              position: relative;
              pointer-events: none;
              text-align: $ldirection;
              width: 66%;
              @media #{$medium-up} {
                #{$ldirection}: 60px;
              }
            }
          }
        }
        #registration {
          .password-field {
            &__info {
              border: 0;
              display: block;
              position: relative;
              top: 0;
              width: 100%;
              @media #{$medium-up} {
                #{$ldirection}: 9%;
                max-width: 400px;
                overflow: hidden;
                padding: 0 10px;
              }
            }
            &__rules {
              column-count: 2;
              margin: 10px 0 25px;
              padding: 0;
              .pass {
                &::before {
                  background: url('/media/images/account/Password_check.svg') no-repeat -2px 1px;
                  background-size: 100%;
                  content: '';
                  display: block;
                  height: 18px;
                  width: 18px;
                }
              }
            }
          }
          .password-rules {
            font-size: 14px;
            font-weight: bold;
            text-align: $ldirection;
          }
        }
        &__fpw-link {
          font-size: 14px;
          text-align: $ldirection;
        }
        &__form-content {
          .form-submit {
            border-radius: 0;
            display: block;
            font-size: 12px;
            height: 48px;
            letter-spacing: 1.8px;
            line-height: 1.2;
            margin: 13px auto 0;
            width: 100%;
            @media #{$medium-up} {
              width: 400px;
              margin: 10px 57px 0;
            }
          }
        }
        &.sign-in,
        &.registration {
          padding: 0;
          .signin-overlay__toggle-form {
            margin: 0;
          }
          .signin-links-toggle {
            margin-bottom: 50px;
          }
        }
        &.registration {
          &.sign-in {
            .return-user-disclaimer {
              display: block;
              margin-top: 20px;
            }
          }
          .return-user-disclaimer {
            display: none;
          }
          .social-login {
            margin-top: 16px;
            &__divider {
              display: none;
            }
            &__terms {
              margin-bottom: 0;
            }
          }
        }
        &.sign-in {
          .password-field__info {
            display: none;
          }
        }
        &__registration-options {
          #signin & {
            margin-top: 0;
            .text--checkbox-label {
              margin-bottom: 15px;
              width: 100%;
              p {
                display: block;
                letter-spacing: 0;
                line-height: 1.5;
                margin: 0 auto;
                width: 100%;
                @media #{$medium-up} {
                  width: 400px;
                }
              }
            }
            .label {
              margin: 5px auto 10px;
              width: 100%;
              @media #{$medium-up} {
                width: 400px;
              }
              .label-content {
                color: $color-blue-dark;
                font-size: 12px;
                font-weight: normal;
                letter-spacing: 0;
                line-height: 1.5;
              }
            }
          }
        }
        &__subtitle {
          color: $color-blue-dark;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 1;
          margin-top: 20px;
          overflow: auto;
          text-transform: uppercase;
          @media #{$medium-up} {
            width: 400px;
            margin: 20px auto 0;
          }
        }
        .error_messages {
          &.error-messages-container {
            margin: 30px auto 0;
            &.multiple-error-message {
              @if $signin_error_messages {
                background: $color-red-background;
                border: 1px solid $color-red-error-border;
                padding: 12px;
              }
              li {
                padding-#{$ldirection}: 15px;
                position: relative;
                @if $signin_error_messages {
                  @include swap_direction(padding, 0 0 0 15px);
                  border: none;
                }
                &::before {
                  #{$ldirection}: 0;
                  background: $color-red-error-border;
                  border-radius: 5px;
                  content: '';
                  display: block;
                  height: 6px;
                  position: absolute;
                  top: 5px;
                  width: 6px;
                }
              }
            }
          }
        }
        .error-messages-content {
          margin: 0 16px;
        }
        .signin-links-toggle {
          overflow: auto;
        }
      }
      .password-field {
        &__info {
          float: #{$ldirection};
          margin-top: 0;
        }
        &__rules {
          padding-top: 0;
        }
      }
      .signin-overlay__toggle-form {
        display: block;
        margin: 0;
        padding: 0;
        text-align: center;
        a {
          &.sign-in-panel__link {
            background: none;
            border-bottom: 4px solid $color-grey-text;
            color: $color-black-dark-transparent;
            display: inline-table;
            font-size: 12px;
            font-weight: bold;
            float: #{$ldirection};
            height: 16px;
            letter-spacing: 1.8px;
            line-height: 1.25;
            margin-top: 0;
            outline: none;
            text-decoration: none;
            text-transform: uppercase;
            text-align: center;
            width: 50%;
            padding-top: 28px;
            padding-bottom: 12px;
            @media #{$medium-up} {
              padding: 16px 0;
            }
            &.active {
              border-bottom: 4px solid $color-black;
              color: $color-blue-dark;
              font-weight: bold;
            }
          }
        }
      }
      .social-login {
        max-width: initial;
        width: 100%;
        @media #{$medium-up} {
          padding-bottom: 40px;
          width: 400px;
        }
        &__container {
          margin: 0 auto;
          padding: 0;
          width: 100%;
          @media #{$medium-up} {
            width: auto;
          }
        }
        &__divider {
          margin: 20px 0 15px;
          &::before {
            height: 1px;
            top: 45%;
          }
          .inner {
            color: $color-blue-dark;
            font-size: 12px;
            font-weight: bold;
            height: 20px;
            letter-spacing: 1.8px;
            line-height: 2;
            padding: 0 7px;
            text-transform: uppercase;
            width: 34px;
          }
        }
        &__email-opt-in {
          padding-top: 0;
          width: 100%;
          .email-optin {
            display: none;
          }
        }
        &__terms {
          padding-top: 0;
          margin-top: 0;
          p {
            color: $color-blue-dark;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 1.5;
            margin-bottom: 20px;
            opacity: 0.88;
            padding-bottom: 0;
            text-align: $ldirection;
            width: 100%;
          }
        }
      }
    }
    .adpl {
      .form-text {
        & + label {
          line-height: 2.5;
          &::before {
            margin: 0 15px;
          }
        }
        &.cart-label-mode {
          & + label {
            &::before {
              transform: translateY(-22px) scale(0.8, 0.8);
            }
          }
        }
      }
    }
  }
  .single-message,
  .error-messages-container {
    background: $color-red-background;
    border: 1px solid $color-red-error-border;
    color: $color-red-error-border;
    display: block;
    font-size: 14px;
    height: auto;
    letter-spacing: 0.25px;
    line-height: 1.25;
    padding: 12px;
    text-align: $ldirection;
    width: 100%;
    @if $signin_error_messages {
      border: none;
      padding: 0;
      #relogin\.\. {
        background: $color-blue-background;
        color: $color-bright_navy;
        border: none;
        padding: 12px;
      }
      li {
        border: 1px solid $color-red-error-border;
        padding: 12px;
      }
    }
    @media #{$medium-up} {
      width: 400px;
    }
  }
}

.custom-fb {
  display: none;
}
