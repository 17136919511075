.sign-in-component {
  @include clearfix;
  min-height: 420px;
  @include swap_direction(margin, 0 auto);
  @media #{$medium-up} {
    width: $sign-in-form-wrapper-width;
  }

  // All this to get a border to go full width on the mobile registration
  // confirmation:
  .sign-in-component__form--registration,
  .sign-in-component__form--sign-in,
  .sign-in-component__confirm--sign-in,
  .sign-in-component__confirm--registration .sign-in-component__header,
  .sign-in-component__confirm--registration .sign-in-component__confirm-registration-fields,
  .sign-in-component__confirm--registration .sign-in-component__confirm-options {
    @include swap_direction(padding, 0 $mobile-outer-gutter);
    @media #{$medium-up} {
      @include swap_direction(padding, 0);
    }
  }

  // Display logic:
  &.sign-in .sign-in-component__form--registration,
  &.sign-in-confirmation .sign-in-component__form--registration,
  &.registration-confirmation .sign-in-component__form--registration,
  .sign-in-component__form--sign-in,
  .sign-in-component__confirm--sign-in,
  .sign-in-component__confirm--registration {
    display: none;
  }
  &.sign-in .sign-in-component__form--sign-in,
  &.sign-in-confirmation .sign-in-component__confirm--sign-in,
  &.registration-confirmation .sign-in-component__confirm--registration {
    display: block;
  }
  .sign-in-component__form--sign-in,
  .sign-in-component__form--registration {
    input.form-text::-webkit-input-placeholder {
      font-size: 12px !important;
    }
    input.form-text::-moz-placeholder {
      font-size: 12px !important;
    }
    input.form-text:-ms-input-placeholder {
      font-size: 12px !important;
    }
    input.form-text:-moz-placeholder {
      font-size: 12px !important;
    }
    @media #{$medium-up} {
      .form-text {
        font-size: 15px;
      }
    }
  }

  // Common styles:
  .sign-in-component__header {
    @include text--bold;
    @include swap_direction(margin, 0 0 10px);
    @media #{$medium-up} {
      @include headline--quaternary--plain;
      font-size: 30px;
      font-weight: normal;
      @include swap_direction(margin, 0 0 24px);
      text-align: $ldirection;
    }
  }
  a {
    text-decoration: underline;
  }
  a.button {
    text-decoration: none;
  }

  // Forms:
  .sign-in-component__form {
    @media #{$medium-up} {
      @include swap_direction(margin, 0 0 0 $sign-in-form-margin-left);
    }
    .sign-in-component__header {
      text-transform: none;
      @media #{$medium-up} {
        width: $sign-in-form-width;
      }
    }
  }
  .signin-overlay__toggle-form {
    font-size: 15px;
  }
  .sign-in-component__birthday-program label,
  .sign-in-component__sms-signup label,
  .sign-in-component__label {
    @include text--bold;
    display: block;
    line-height: 22px;
    margin-bottom: 10px;
    @media #{$medium-up} {
      margin-bottom: 15px;
    }
  }
  .sign-in-component__birthday-program-copy {
    margin-top: 15px;
  }
  .form-item {
    @include clearfix;
    margin-bottom: 10px;
    &.text--form-help {
      margin-bottom: 15px;
    }
    &.sign-in-component__sms-signup {
      margin-bottom: 20px;
    }
  }
  .field[type='text'],
  .field[type='email'],
  .field[type='password'],
  .field[type='tel'],
  .form-text,
  .form-submit {
    width: 100%;
  }
  .form-submit {
    margin-bottom: 20px;
  }
  @media #{$medium-up} {
    .field[type='text'],
    .field[type='email'],
    .field[type='password'],
    .field[type='tel'],
    .form-text,
    .form-submit {
      width: $sign-in-form-width;
    }
    .form-submit {
      margin-bottom: 27px;
    }
  }
  .text--form-help p {
    // Since cms uses rich text, unnecessary p tags are inevitable
    @include swap_direction(margin, 0);
  }
  .sign-in-component__confirm-options {
    @include clearfix;
    @include swap_direction(margin, 0 auto);
    .button,
    .form-submit,
    .sign-in-component__close {
      display: block;
      @media #{$medium-up} {
        float: $ldirection;
      }
    }
  }
  .sign-in-component__password-wrapper {
    margin-bottom: 10px;
  }

  // Confirmations:
  .sign-in-component__confirm {
    .sign-in-component__header {
      text-transform: none;
      width: auto;
    }
  }

  // Registration form
  .sign-in-component__form--registration {
    .sign-in-component__terms,
    .sign-in-component__terms p {
      @include form-help;
      display: inline;
      @include swap_direction(margin, 0);
    }
    .sign-in-component__password-wrapper {
      .field[type='password'],
      .form-text {
        margin-bottom: 15px;
      }
    }
    @media #{$medium-up} {
      .sign-in-component__password-wrapper {
        @include clearfix;
        margin-bottom: 20px;
        .field[type='password'],
        .form-text {
          float: $ldirection;
          margin-bottom: 0;
        }
        .sign-in-component__show-password-wrapper {
          float: $ldirection;
          @include swap_direction(margin, 6px 0 0 10px);
        }
      }
    }
    .sign-in-component__registration-options {
      @media #{$medium-up} {
        width: $sign-in-form-overflow-width;
      }
      margin-bottom: 20px;
      .text--form-help {
        label {
          margin-bottom: 14px;
        }
        &,
        & p {
          line-height: 16px;
        }
      }
    }
  }

  // Sign in form
  .sign-in-component__sign-in-form {
    .sign-in-component__fpw-link {
      display: block;
      margin-bottom: 30px;
    }
  }

  // Registration confirmation
  .sign-in-component__confirm--registration {
    @include swap_direction(padding, 12px 0 0);
    .sign-in-component__header {
      @media #{$small-only} {
        @include headline--tertiary;
        line-height: 40px;
        text-align: center;
        .sign-in-component__registration-confirmation-header,
        .sign-in-component__registration-confirmation-copy {
          display: block;
        }
        .sign-in-component__registration-confirmation-header {
          letter-spacing: 0;
          margin-bottom: 2px;
        }
        .sign-in-component__registration-confirmation-copy {
          font-size: 20px;
          line-height: 23px;
          letter-spacing: 0;
        }
      }
      @media #{$medium-up} {
        margin-bottom: 43px;
      }
    }
    .sign-in-component__confirm-registration-fields {
      @include clearfix;
      border-top: solid 1px $color-light-gray;
      @include swap_direction(margin, 25px auto 15px);
      @include swap_direction(padding, 15px 25px 0);
      @media #{$medium-up} {
        width: $sign-in-form-registration-confirmation-width;
        border-top: none;
        border-bottom: solid 1px $color-light-gray;
        @include swap_direction(margin, 0 auto 28px);
        @include swap_direction(padding, 0 0 34px);
      }
    }
    @media #{$medium-up} {
      .sign-in-component__confirm-registration-field-wrapper {
        float: $ldirection;
        width: 50%;
      }
    }
    .sign-in-component__sms-opt-in {
      margin-bottom: 20px;
      @media #{$medium-up} {
        @include swap_direction(padding, 0 38px 0 58px);
        .field[type='text'],
        .field[type='tel'],
        .form-text {
          width: $sign-in-form-width;
        }
      }
    }
    .sign-in-component__birthday-program {
      .select-month,
      .select-year {
        width: 40%;
      }
      .select-month {
        margin-#{$rdirection}: 20px;
      }
      @media #{$medium-up} {
        @include swap_direction(padding, 0 45px 0 60px);
        .selectbox {
          margin-#{$rdirection}: 20px;
        }
        .select-month {
          width: 96px !important;
        }
        .select-year {
          width: 82px !important;
        }
      }
    }
    .sign-in-component__close {
      @include swap_direction(margin, 0 0 15px);
    }
    @media #{$medium-up} {
      .sign-in-component__confirm-options {
        width: $sign-in-form-registration-confirmation-width;
      }
      .sign-in-component__close {
        width: 50%;
        @include swap_direction(padding, 8px 0 0 58px);
      }
      .button {
        @include swap_direction(margin, 0 0 0 60px);
      }
    }
  }

  // Sign in confirmation
  .sign-in-component__confirm--sign-in {
    padding-top: 78px;
    .sign-in-component__header {
      margin-bottom: 88px;
    }
  }
  .button {
    width: 100%;
  }
  @media #{$medium-up} {
    .button {
      width: 224px;
    }
    .sign-in-component__confirm-options {
      width: 555px;
      .sign-in-component__view-account-button {
        float: $rdirection;
      }
    }
  }
}

// Overlay-specific:
.device-mobile {
  #colorbox.signin-overlay-wrapper {
    @media #{$small-only} {
      height: 100% !important;
      overflow: scroll !important;
    }
  }
}

.signin-overlay-wrapper {
  #cboxContent {
    @include swap_direction(padding, 50px 0);
    @media #{$medium-up} {
      @include swap_direction(padding, 78px 25px);
    }
    div {
      // Not sure why colorbox is so lame about this...
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

// Mobile overlay shouldn't look like an overlay:
@media #{$small-only} {
  #cboxOverlay.signin-overlay-wrapper {
    opacity: 1 !important;
    background: #fff !important;
  }
}

// Page-specific:
.sign-in-page {
  min-height: 600px;
  .sign-in-component {
    height: auto;
    .js & {
      display: none; // Hide the form until js can process it.
      @media #{$small-only} {
        display: block;
      }
    }
  }
  .error_messages {
    text-align: center;
    @include swap_direction(margin, 20px 0);
    @include swap_direction(padding, 0 $mobile-outer-gutter);
    font-size: 14px;
  }
}

.sign-in-component {
  .error_messages {
    @include swap_direction(margin, 20px 0);
    @include swap_direction(padding, 0 $mobile-outer-gutter);
    text-align: center;
    font-size: 14px;
  }
}
