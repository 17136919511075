@media screen and (min-width: 768px) {
  .el_boutique_wrapper div.hideModules {
    opacity: 0;
    padding-top: 5%;
  }
  #belle_mod1 {
    width: 100%;
    .belle_mod1__bg {
      vertical-align: top;
    }
    .belle__hero-block {
      position: absolute;
      z-index: 1;
    }
    .boutique_vid_play_img {
      bottom: 7.2%;
      position: absolute;
      width: 6.6%;
      left: 2.4%;
      cursor: pointer;
      z-index: 5;
      &:hover {
        opacity: 0.5;
      }
      @media screen and (max-width: 1365px) {
        width: 80px;
      }
    }
    .boutique_vid_play_cta {
      position: absolute;
      bottom: 3.6%;
      left: 2.3%;
      cursor: pointer;
      z-index: 5;
      font-size: 12px;
      letter-spacing: 0.15em;
      line-height: 100%;
    }
    .el_boutique_link:hover {
      opacity: 0.5;
    }
    #belle__hero-block_1 {
      top: 34.5%;
      left: 11%;
      z-index: 1;
      font-family: 'Futura Now Medium';
      opacity: 0;
      letter-spacing: 0.32em;
      font-size: 2.05128vw;
      @media screen and (min-width: 1366px) {
        font-size: 28px;
      }
    }
    #belle__hero-block_2 {
      top: 10%;
      left: 0;
      z-index: 1;
      opacity: 0;
    }
    #belle__hero-block_3 {
      top: 59.5%;
      left: 17.8%;
      font-size: 1.31868vw;
      font-family: 'Futura Now Medium';
      letter-spacing: 0.15em;
      opacity: 0;
      &.belle__hero-block {
        .el_boutique_body:nth-of-type(2) {
          padding-top: 3%;
        }
        .el_boutique_body:nth-of-type(3) {
          padding-top: 3%;
        }
      }
      @media screen and (min-width: 1366px) {
        font-size: 18px;
      }
    }
    #belle__hero-block_4 {
      top: 62.5%;
      opacity: 0;
      @media screen and (min-width: 768px) {
        left: 16.5%;
        @media screen and (min-width: 870px) {
          left: 17.5%;
          @media screen and (min-width: 940px) {
            left: 18%;
            @media screen and (min-width: 980px) {
              left: 18.5%;
              @media screen and (min-width: 1040px) {
                left: 19%;
                @media screen and (min-width: 1180px) {
                  left: 20%;
                  @media screen and (min-width: 1270px) {
                    left: 20.5%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .belle__hero-block {
      .el_boutique_body {
        padding-top: 6%;
      }
    }
    #belle_hero__qs {
      margin-top: 17%;
      height: 41px;
      line-height: 41px;
      padding: 0 30px;
      border: 1px solid #fff;
    }
  }
  #belle_mod2 {
    .belle_mod2__img-left {
      width: 66.692%;
      height: 52.16112vw;
      float: left;
      @media screen and (min-width: 1270px) {
        height: 712px;
      }
      img {
        margin-top: 0;
      }
    }
    .belle_mod2__img-right {
      width: 31.113%;
      float: right;
    }
    .belle_mod2__img-left,
    .belle_mod2__img-right {
      display: block;
      overflow: hidden;
    }
    .belle_mod2__copy {
      position: absolute;
      right: 4.8%;
      z-index: 1;
      top: 36.45%;
      @media screen and (max-width: 1270px) {
        right: 3.8%;
        @media screen and (max-width: 1180px) {
          right: 2.8%;
          @media screen and (max-width: 1080px) {
            right: 2%;
            @media screen and (max-width: 980px) {
              right: 1%;
              @media screen and (max-width: 910px) {
                right: 0.5%;
                @media screen and (max-width: 880px) {
                  right: 0;
                  @media screen and (max-width: 850px) {
                    right: -2%;
                    @media screen and (max-width: 800px) {
                      right: -2.5%;
                      @media screen and (max-width: 768px) {
                        right: -3.5%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .belle_mod2__header {
        font-size: 2.34432vw;
        font-family: 'Futura Now Medium';
        letter-spacing: 0.5em;
        line-height: 135%;
        @media screen and (min-width: 1270px) {
          font-size: 32px;
        }
      }
      .belle_mod2__header,
      .belle_mod2__price,
      .belle_mod2__desc {
        text-align: center;
      }
      .belle_mod2__price,
      .belle_mod2__desc {
        padding-top: 5.5%;
      }
      .belle_mod2__price {
        line-height: 100%;
      }
      .belle_mod2__desc {
        letter-spacing: 0.14em;
        line-height: 134%;
        @media screen and (max-width: 1080px) {
          width: 80%;
          margin: 0 auto;
        }
      }
      .belle_mod2__qsWrapper {
        position: relative;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        padding-top: 6.3%;
        .belle_mod2__qs {
          height: 41px;
          line-height: 41px;
          padding: 0 30px;
          border: 1px solid #fff;
        }
      }
    }
  }
  #belle_mod5 {
    .belle__quiz-header {
      position: absolute;
      font-family: 'OptimaDisplayLight';
      font-size: 3.95604vw;
      width: 100%;
      text-align: center;
      line-height: 104%;
      padding-top: 3.75%;
      letter-spacing: 0.01em;
      @media screen and (min-width: 1366px) {
        font-size: 54px;
      }
    }
    .belle__quiz-subheader {
      position: absolute;
      width: 100%;
      text-align: center;
      margin-top: 13.4%;
    }
    .belle__quiz-section {
      position: relative;
      .belle__quiz-qs {
        position: absolute;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        .belle__quiz-btnNext {
          display: block;
          margin: 2.2% auto 0 auto;
          padding: 0 4.312%;
          border: 1px solid transparent;
          @media screen and (max-width: 1365px) {
            height: 2.9304vw;
            line-height: 2.9304vw;
            @media screen and (max-width: 1126px) {
              height: 33px;
              line-height: 33px;
            }
          }
          &.belle__quiz-btnInactive {
            background-color: #a4a4ab;
            border: 1px solid #fff;
            pointer-events: none;
          }
        }
        .belle__quiz-question {
          font-family: 'OptimaDisplayLight';
          font-size: 2.63736vw;
          width: 100%;
          text-align: center;
          line-height: 104%;
          padding: 1.5% 0 2.4% 0;
          letter-spacing: -0.01em;
          @media screen and (min-width: 1366px) {
            font-size: 36px;
          }
        }
        .belle__quiz-option {
          display: block;
          font-family: 'OptimaDisplayLight';
          font-size: 1.75824vw;
          width: 100%;
          text-align: center;
          letter-spacing: -0.01em;
          text-transform: inherit;
          margin: 0 auto 1.4% auto;
          font-weight: normal;
          padding: 0;
          max-width: 54.7%;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          height: 3.00366vw;
          line-height: 3.00366vw;
          @media screen and (min-width: 1366px) {
            font-size: 24px;
            height: 41px;
            line-height: 41px;
          }
        }
        .belle__quiz-q-1,
        .belle__quiz-q-2,
        .belle__quiz-q-3,
        .belle__quiz-q-4 {
          margin-top: 16.6%;
          position: absolute;
          width: 100%;
        }
        .belle__quiz-q-2,
        .belle__quiz-q-3,
        .belle__quiz-q-4 {
          display: none;
        }
        .belle__quiz-q-4 {
          .belle__quiz-question {
            padding: 2.7% 0 3.9% 0;
          }
          .belle__quiz-btnNext {
            padding: 0 1.4%;
          }
        }
      }
    }
    .belle__quiz-ans {
      height: 48.86442vw;
      position: absolute;
      width: 100%;
      margin: 0 auto;
      @media screen and (min-width: 1366px) {
        height: 667px;
      }
      .belle__quiz-ansImg {
        width: 33.529%;
        position: absolute;
        left: 16.55%;
        top: 3%;
      }
      a {
        font-weight: bold;
        text-decoration: underline;
        text-transform: uppercase;
        font-size: 1.0989vw;
        letter-spacing: 0.14em;
        @media screen and (min-width: 1366px) {
          font-size: 15px;
        }
      }
      .belle__ans-header {
        position: absolute;
        font-family: 'OptimaDisplayLight';
        font-size: 3.95604vw;
        text-align: center;
        line-height: 98%;
        z-index: 1;
        top: 72.9%;
        @media screen and (min-width: 1366px) {
          font-size: 54px;
        }
      }
      .belle__quiz-ans-1 {
        display: none;
        .belle__quiz-ansCopy {
          @media screen and (max-width: 900px) {
            top: 5.1%;
            @media screen and (max-width: 850px) {
              top: 3.1%;
            }
          }
        }
        .belle__ans-header {
          left: 24%;
        }
        .belle__quiz-ansSection1_1 {
          margin-bottom: 4%;
          @media screen and (max-width: 850px) {
            margin-bottom: 3%;
          }
        }
        .belle__quiz-ansSection1_2 {
          margin-bottom: 4.9%;
          @media screen and (max-width: 850px) {
            margin-bottom: 3.8%;
          }
        }
        .belle__quiz-ansSection1_3 {
          margin-bottom: 4.8%;
          @media screen and (max-width: 850px) {
            margin-bottom: 3.8%;
          }
        }
        .belle__quiz-ansSection1_4 {
          margin-bottom: 4.8%;
          @media screen and (max-width: 850px) {
            margin-bottom: 3.8%;
          }
        }
        .belle__quiz-ansSection1_5 {
          margin-bottom: 4.9%;
          @media screen and (max-width: 850px) {
            margin-bottom: 3.9%;
          }
        }
        .belle__quiz-ansSection1_6 {
          margin-bottom: 4.6%;
          @media screen and (max-width: 850px) {
            margin-bottom: 3.6%;
          }
        }
        .belle__quiz-ansSection1_7 {
          margin-bottom: 4.5%;
          @media screen and (max-width: 850px) {
            margin-bottom: 2.7%;
          }
        }
        .belle__quiz-ansSection1_8 {
          margin-bottom: 4.5%;
        }
      }
      .belle__quiz-ans-2 {
        display: none;
        .belle__ans-header {
          left: 20%;
        }
        .belle__quiz-ansSection2_2 {
          margin-top: 3.8%;
          @media screen and (max-width: 850px) {
            margin-top: 2.8%;
          }
        }
        .belle__quiz-ansSection2_3 {
          margin-top: 4.6%;
          @media screen and (max-width: 850px) {
            margin-top: 3.6%;
          }
        }
        .belle__quiz-ansSection2_4 {
          margin-top: 4.3%;
          @media screen and (max-width: 850px) {
            margin-top: 3.3%;
          }
        }
        .belle__quiz-ansSection2_5 {
          margin-top: 4.2%;
          @media screen and (max-width: 850px) {
            margin-top: 3.2%;
          }
        }
        .belle__quiz-ansSection2_6 {
          margin-top: 4.5%;
          @media screen and (max-width: 850px) {
            margin-top: 3.5%;
          }
        }
        .belle__quiz-ansSection2_7 {
          margin-top: 4.4%;
          @media screen and (max-width: 850px) {
            margin-top: 3.4%;
          }
        }
      }
      .belle__quiz-ans-3 {
        display: none;
        .belle__ans-header {
          left: 21.7%;
        }
        .belle__quiz-ansSection3_2 {
          margin-top: 3.7%;
        }
        .belle__quiz-ansSection3_3 {
          margin-top: 4.3%;
        }
        .belle__quiz-ansSection3_4 {
          margin-top: 4.5%;
        }
        .belle__quiz-ansSection3_5 {
          margin-top: 4.4%;
        }
        .belle__quiz-ansSection3_6 {
          margin-top: 3.6%;
        }
        .belle__quiz-ansSection3_7 {
          margin-top: 4.1%;
        }
      }
      .belle__quiz-restartBtn {
        margin: 0 auto;
        text-align: center;
        position: absolute;
        width: 100%;
        bottom: 8.8%;
        .belle__quiz-restart {
          padding: 0 2.5%;
          @media screen and (max-width: 1365px) {
            height: 2.9304vw;
            line-height: 2.9304vw;
            @media screen and (max-width: 1126px) {
              height: 33px;
              line-height: 33px;
            }
          }
        }
        @media screen and (max-width: 1140px) {
          bottom: 6.8%;
          @media screen and (max-width: 980px) {
            bottom: 4.8%;
            @media screen and (max-width: 820px) {
              bottom: 3.8%;
            }
          }
        }
      }
      .belle__quiz-ansCopy {
        position: absolute;
        left: 51.4%;
        top: 7.1%;
        .belle__quiz-ansSection {
          line-height: 120%;
        }
      }
    }
    .inactive {
      pointer-events: none;
    }
  }
}

#belle_mod3 {
  .belle_mod3__header {
    font-family: 'OptimaDisplayLight';
    font-size: 3.95604vw;
    width: 100%;
    text-align: center;
    line-height: 98%;
    padding-top: 3.15%;
    @media screen and (min-width: 1366px) {
      font-size: 54px;
    }
  }
  .belle_mod3__subheader {
    font-weight: normal;
    width: 100%;
    text-align: center;
    line-height: 150%;
    padding: 0.65% 0 1.8% 0;
  }
  .belle_mod3__tile {
    max-height: 404px;
    position: relative;
    float: left;
    width: calc(100% / 3);
    padding: 0;
    overflow: hidden;
    &.js-ytPlayer__icon {
      cursor: pointer;
    }
    .belle_mod3__tile-copy {
      position: absolute;
      font-family: 'Futura Now Medium';
      letter-spacing: 0.26em;
      line-height: 135%;
      font-size: 4.68864vw;
      text-align: center;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      @media screen and (min-width: 1366px) {
        font-size: 64px;
      }
    }
    .boutique_vid_play_img {
      position: absolute;
      z-index: 2;
      bottom: 2%;
      left: 2.3%;
      width: 20%;
      backface-visibility: hidden;
    }
  }
}

#belle_mod4 {
  .belle_mod4__header {
    font-family: 'OptimaDisplayLight';
    font-size: 3.95604vw;
    width: 100%;
    text-align: center;
    line-height: 98%;
    padding: 3.15% 0 1.6% 0;
    @media screen and (min-width: 1366px) {
      font-size: 54px;
    }
  }
  .belle_mod4__slide {
    width: 66.911%;
    margin: 0 auto;
    position: relative;
    .belle_mod4__slide-copy {
      position: absolute;
      bottom: 4.4%;
      left: 1.4%;
    }
    .boutique_vid_play_img {
      position: absolute;
      bottom: 10%;
      width: 9.847%;
      left: 1.5%;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
    .boutique_vid_play_cta {
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
    .boutique__video_play_btn {
      margin-top: 0;
    }
  }
  .basic_infinite_carouselnav.basic_infinite_carouselnav_1 {
    left: 6.9%;
  }
  .basic_infinite_carouselnav.basic_infinite_carouselnav_2 {
    right: 6.9%;
  }
  .basic_infinite_carouselnav_btn {
    width: 30%;
  }
  .basic_infinite_carousel_pagination_dot {
    width: 9px;
    height: 9px;
    margin: 0 5px 0 6px;
  }
  .basic_infinite_carousel_pagination {
    margin-top: -1%;
  }
  .basic_infinite_carousel_pagination_dot:not(.selected) {
    border: 1px solid #040a2b;
  }
  .basic_infinite_carouselOuter:not(.basic_infinite_carousel_header) {
    padding-bottom: 3.663vw;
    @media screen and (min-width: 1366px) {
      padding-bottom: 50px;
    }
  }
}

#belle_mod6 {
  .belle_mod6__esStories-carousel {
    margin-top: 1.9%;
  }
  .belle_mod6__slide-header {
    font-family: 'OptimaDisplayLight';
    font-size: 3.95604vw;
    width: 100%;
    text-align: center;
    line-height: 98%;
    padding: 1.4% 0 0.3% 0;
    letter-spacing: -0.04em;
    position: relative;
    @media screen and (min-width: 1366px) {
      font-size: 54px;
    }
  }
  .belle_mod6__slide {
    width: 66.911%;
    margin: 0 auto;
    position: relative;
    .belle_mod6__slide-copy {
      position: relative;
      letter-spacing: 0.025em;
    }
    .belle_mod6__slideLogo {
      margin-bottom: -3.9%;
      z-index: 2;
      position: relative;
      width: 35.6%;
      margin-left: 2.5%;
    }
    .belle_mod6__slide-link {
      position: relative;
      font-size: 1.02564vw;
      margin-top: 1.6%;
      letter-spacing: 0.028em;
      border-bottom: 0;
      cursor: default;
      a {
        text-decoration: underline;
      }
      @media screen and (min-width: 1366px) {
        font-size: 14px;
      }
      @media screen and (max-width: 1194px) {
        font-size: 12px;
      }
    }
  }
  .basic_infinite_carouselnav {
    top: -6%;
  }
  .basic_infinite_carouselnav.basic_infinite_carouselnav_1 {
    left: 6.9%;
  }
  .basic_infinite_carouselnav.basic_infinite_carouselnav_2 {
    right: 6.9%;
  }
  .basic_infinite_carouselnav_btn {
    width: 30%;
  }
  .basic_infinite_carousel_pagination_dot {
    width: 9px;
    height: 9px;
    margin: 0 5px 0 6px;
  }
  .basic_infinite_carousel_pagination {
    margin-top: -1%;
  }
  .basic_infinite_carousel_pagination_dot:not(.selected) {
    border: 1px solid #040a2b;
  }
  .basic_infinite_carouselOuter:not(.basic_infinite_carousel_header) {
    padding-bottom: 0;
  }
}

.belle_formatter {
  #r4_mod5 {
    margin-top: -1.2%;
    #r4_mod5_mpp {
      .mpp__header {
        font-size: 3.95604vw;
        @media screen and (min-width: 1366px) {
          font-size: 54px;
        }
      }
      .mpp__product-grid {
        margin-top: 1.2%;
      }
      #r4_mod5_h1 {
        display: none;
      }
      #r4_mod5_subheader {
        padding: 0;
      }
    }
  }
}
/* RTL Support */

html[dir='rtl'] {
  #belle__hero-block_1,
  #belle__hero-block_3,
  #belle__hero-block_4 {
    direction: rtl;
  }
}

html[dir='rtl'] {
  #belle_mod2 {
    .belle_mod2__copy {
      direction: rtl;
    }
  }
}
