.dw__foundation__sampling {
  @media #{$xxlarge-up} {
    @include dw-foundation-sampling-layout;
  }
  position: relative;
  &__img {
    @media #{$device-iphone-landscape-only} {
      width: 100%;
    }
    @media #{$xlarge-up} {
      width: 100%;
    }
  }
  &__container {
    @media #{$medium-up} {
      padding-top: 5%;
    }
    @media #{$device-iphone-landscape-only} {
      padding-top: 12%;
    }
    padding-top: 7%;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    color: $color-light-navy;
    &__header {
      padding-bottom: 15px;
      &__title {
        @media #{$medium-up} {
          font-size: 50px;
          margin-bottom: 5px;
        }
        margin-bottom: 7px;
        display: block;
        font-size: 52px;
        letter-spacing: 0px;
      }
    }
    &__shades {
      &__title {
        @media #{$medium-up} {
          font-size: 9px;
          padding-bottom: 3px;
        }
        font-family: $akzidenz;
        font-weight: bold;
        letter-spacing: 2.5px;
        font-size: 19px;
        display: block;
        padding-bottom: 6px;
      }
    }
    &__listing {
      &__options {
        @media #{$medium-up} {
          @include swap_direction(padding, 0 34% 0.8%);
          font-size: 13px;
        }
        padding: 0 16px;
        font-size: 23px;
        list-style-type: square;
        list-style-position: inside;
        &__content {
          @media #{$medium-up} {
            padding-bottom: 0;
          }
          position: relative;
          #{$rdirection}: 3px;
          padding-bottom: 7px;
        }
      }
    }
    &__heading {
      @media #{$medium-up} {
        font-size: 50px;
      }
      letter-spacing: 1px;
      font-size: 48px;
    }
    &__subheading {
      @media #{$medium-up} {
        font-size: 30px;
      }
      font-size: 34px;
    }
  }
}

.olapic-widget {
  &__subhead {
    @media #{$medium-up} {
      font-size: 11px;
    }
    font-size: 15px;
    color: $color-navy;
  }
  &__heading {
    @media #{$medium-up} {
      font-size: 50px;
      letter-spacing: 1px;
    }
    font-size: 46px;
    letter-spacing: 0.5px;
    color: $color-navy;
  }
}
