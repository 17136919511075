/* Lauder 404 page */
.page-not-found {
  width: 270px;
  @include swap_direction(margin, 0 auto);
  @include swap_direction(padding, 162px 0 135px);
  text-align: center;
  @media #{$small-only} {
    @include swap_direction(padding, 53px 0 53px);
  }
  &__header {
    font-family: $optimalight;
    font-size: 49px;
    letter-spacing: -0.05em;
  }
  &__subheader {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    @include swap_direction(padding, 26px 0 33px);
    @media #{$small-only} {
      @include swap_direction(padding, 15px 0 23px);
    }
  }
  &__bestseller,
  &__stories {
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 17px;
    a {
      border-bottom: 2px solid $color-light-gray;
    }
  }
}
