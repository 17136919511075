///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules. Don't use a mixin where you could use
/// @extend instead.
///

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

///
// box-sizing
///
@mixin box-sizing($bs) {
  $bs: unquote($bs);
  @include experimental(box-sizing, $bs, -moz, -webkit, not -o, not -ms, not -khtml, official);
}

///
// Many of our fonts and letter-spacing are handled by pt's
// tracking() exists in _functions.scss, converts tracking to letter-spacing
///
@mixin tracking($font-size, $tracking: 0) {
  letter-spacing: tracking($font-size, $tracking);
}

///
// Use the print terminology for "line-height"
///
@mixin leading($leading: 1) {
  line-height: $leading;
}

///
// Combine a lot of print terms together in one CSS declaration
///
@mixin print-size-tracking($font-size, $tracking: 0) {
  font-size: $font-size;
  @include tracking($font-size, $tracking);
}

///
// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
// Calculate column width based off of a 12 column grid
// $column-count (x of whole) = how large the column divides into the whole
// with a 12 column grid (3 columns is = 4th = 4), (4 = 3rd = 3)
// (6 columns = half = 2), etc
///
@mixin column-width($column-count) {
  width: (100% / $column-count);
  @include swap_direction(padding, 0 1%);
}

@mixin column-margin-right($column-count) {
  margin-#{$rdirection}: ($column-grid/$column-count) * (100% / $column-grid);
}

@mixin inline-image-with-size($img, $overrides: false) {
  background-image: image-url($img);
  width: image-width($img);
  height: image-height($img);
  @if $overrides == true {
    background-color: transparent;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: auto;
  }
}

@mixin arrow(
  $color: map-get($select-settings, arrow-color),
  $width: map-get($select-settings, arrow-width),
  $height: map-get($select-settings, arrow-height),
  $direction: down
) {
  margin-top: (-0.5 * $height);
  border-style: solid;
  border-color: transparent;
  @if $direction == $ldirection {
    border-top-width: round($height / 2);
    border-bottom-width: round($height / 2);
    border-#{$ldirection}-width: 0;
    border-#{$rdirection}-width: $width;
    border-#{$rdirection}-color: $color;
  } @else if $direction == $rdirection {
    border-top-width: round($height / 2);
    border-bottom-width: round($height / 2);
    border-#{$rdirection}-width: 0;
    border-#{$ldirection}-width: $width;
    border-#{$ldirection}-color: $color;
  } @else if $direction == up {
    border-#{$ldirection}-width: round($width / 2);
    border-#{$rdirection}-width: round($width / 2);
    border-top-width: 0;
    border-bottom-width: $height;
    border-bottom-color: $color;
  } @else {
    // $direction == down
    border-#{$ldirection}-width: round($width / 2);
    border-#{$rdirection}-width: round($width / 2);
    border-top-width: $height;
    border-bottom-width: 0;
    border-top-color: $color;
  }
}

// For text-shadow in aerin carousel slide text
@mixin textshadow-aerin($h, $v, $b, $color) {
  text-shadow: $h $v $b $color;
}

// 2018 Creative refresh
@mixin gluxre_update {
  @include gluxre_update__navigation;
  @include gluxre_update__utility_nav;
}

// 2019 CSS split

@mixin color-states {
  &.light {
    color: $color-white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &.dark {
    color: $color-navy;
  }
}

@mixin headline--primary {
  @include color-states;
  font-family: $optimalight, Helvetica, Arial, sans-serif;
  letter-spacing: -0.05em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 50px;
  line-height: 0.85;
  #{$aerin} .page-main & {
    font-family: 'Futura Now Regular';
  }
  #{$estee_edit} .page-main & {
    font-family: $ffmarkweb;
  }
  #{$renutriv} & {
    font-family: $bb-roman;
    letter-spacing: normal;
    font-size: 50px;
    @media #{$large-up} {
      font-size: 60px;
    }
  }
  @media #{$large-up} {
    font-size: 135px;
  }
}

// minus the brand styles
@mixin headline--primary--plain {
  @include color-states;
  font-family: $optimalight;
  letter-spacing: -0.05em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 50px;
  line-height: 0.85;
  @media #{$large-up} {
    font-size: 135px;
  }
}

///
/// Secondary headlines (h2)
/// Called "Medium Headlines" in batch 1 styleguide, pg.13
///
@mixin headline--secondary {
  font-size: 70px;
  font-family: $optimalight;
  letter-spacing: -0.02em;
  @include color-states;
  #{$aerin} .page-main & {
    font-family: 'Futura Now Regular';
    font-size: 55px;
  }
  #{$renutriv} & {
    font-family: $bb-roman;
    letter-spacing: normal;
  }
}

// minus brans styles
@mixin headline--secondary--plain {
  font-size: 70px;
  font-family: $optimalight;
  letter-spacing: -0.02em;
  @include color-states;
}

///
/// Tertiary headlines (h3)
/// Called "Product Names" (Main) in batch 1 styleguide pg.13
/// Called "Primary Headline" in batch 2 styleguide pg. 8
///
@mixin headline--tertiary {
  @include color-states;
  font-family: $optimalight;
  font-size: 40px;
  @media #{$medium-up} {
    font-size: 50px;
    letter-spacing: -0.02em;
  }
  #{$aerin} .page-main & {
    font-family: 'Futura Now Regular';
    font-size: 30px;
    @media #{$medium-up} {
      font-size: 40px;
    }
  }
}

// minus the brand styles
@mixin headline--tertiary--plain {
  @include color-states;
  font-family: $optimalight;
  font-size: 40px;
  @media #{$medium-up} {
    font-size: 50px;
    letter-spacing: -0.02em;
  }
}

///
/// Quaternary headlines (h4)
/// Called "Product Names" (Sub) in batch 1 styleguide pg.13
/// Called "Medium Headline" in batch 2 styleguide pg. 8
///
@mixin headline--quaternary {
  @include color-states;
  font-family: $optimalight;
  font-size: 25px;
  #{$aerin} .page-main & {
    font-family: 'Futura Now Regular';
    font-size: 22px;
    @media #{$medium-up} {
      font-size: 28px;
    }
  }
  @media #{$medium-up} {
    font-size: 30px;
    letter-spacing: -0.005em;
  }
}

// minus brand styles
@mixin headline--quaternary--plain {
  @include color-states;
  font-family: $optimalight;
  font-size: 25px;
  @media #{$medium-up} {
    font-size: 30px;
    letter-spacing: -0.005em;
  }
}

/// Quinary headlines (h5)
/// Called "Product Listing Names" (Main) in batch 1 styleguide pg.13
@mixin headline--quinary {
  font-size: 21px;
  font-family: $optimaregular;
  letter-spacing: 0;
  @include color-states;
  #{$aerin} .page-main & {
    font-family: 'Futura Now Regular';
    font-size: 21px;
  }
  @media #{$medium-up} {
    #{$aerin} .page-main & {
      font-size: 22px;
    }
  }
  @media #{$medium-up} {
    font-size: 22px;
  }
}

// minus brand styles
@mixin headline--quinary--plain {
  font-size: 21px;
  font-family: $optimaregular;
  letter-spacing: 0;
  @include color-states;
  @media #{$medium-up} {
    font-size: 22px;
  }
}

/// Senary headlines (h6)
/// Called "Product Listing Names" (Sub) in batch 1 styleguide pg.13
@mixin headline--senary {
  font-size: 15px;
  font-family: $optimaregular;
  letter-spacing: 0;
  @include color-states;
  @media #{$medium-up} {
    font-size: 16px;
  }
  #{$aerin} .page-main & {
    font-family: 'Futura Now Regular';
  }
}

// minus brand styles
@mixin headline--senary--plain {
  font-size: 15px;
  font-family: $optimaregular;
  letter-spacing: 0;
  @include color-states;
  @media #{$medium-up} {
    font-size: 16px;
  }
}

// Modal header: Batch 1 styleguide, pg. 15
@mixin header--modal {
  //@font-face: Optima Display Book
  font-size: 30px;
  letter-spacing: 0.05em;
}

/// Short Text
/// Batch 1 styleguide pg.14
@mixin text--short {
  font-size: 12px;
  // NOTE: DO NOT SET LINE HEIGHT HERE.
  // The idea is "text--short" may have any line height to fit the container, while
  // text--short-copy has a specific line height.
  font-family: $akzidenz, Helvetica, Arial, sans-serif;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  #{$aerin} .page-main & {
    font-family: 'Futura Now Regular';
  }
}

// minus brand styles
@mixin text--short--plain {
  font-size: 12px;
  font-family: $akzidenz;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

/// Short Body Copy
/// Batch 1 styleguide pg.14
@mixin text--short-copy {
  @include text--short--plain;
  line-height: 24px;
}

/// Tiny Text
@mixin text--tiny {
  font-size: 11px;
  // NOTE: DO NOT SET LINE HEIGHT HERE.
  // The idea is "text--tiny" may have any line height to fit the container, while
  // text--tiny-copy has a specific line height.
  font-family: $akzidenz;
  letter-spacing: 0.15em;
  #{$aerin} .page-main & {
    font-family: 'Futura Now Regular';
  }
  #{$renutriv} & {
    font-family: $roboto-condensed;
  }
}

// minus brand styles
@mixin text--tiny--plain {
  font-size: 11px;
  font-family: $akzidenz;
  letter-spacing: 0.15em;
}

/// Tiny Body Copy
@mixin text--tiny-copy {
  @include text--tiny;
  line-height: 14px;
}

/// Long Body Copy
/// Batch 1 styleguide pg.15
@mixin text--long-copy {
  font: 15px/24px $akzidenz;
  letter-spacing: 0.05em;
  #{$aerin} .page-main & {
    font-family: 'Futura Now Regular';
  }
}

// minus brand styles
@mixin text--long-copy--plain {
  font: 15px/24px $akzidenz;
  letter-spacing: 0.05em;
}

/// Bold text
@mixin text--bold {
  @include text--short--plain;
  font-weight: bold;
}

@mixin text-promo {
  @include text--short;
  @include color-states;
}

/// Links
// Normal link: Batch 2 styleguide, pg. 8
@mixin link {
  @include text--short--plain;
  text-decoration: underline;
  cursor: pointer;
}

// Bold link: Batch 1 styleguide, pg. 14
@mixin link--bold {
  @include link;
  font-weight: bold;
}

/// Index Navigation
/// Smallest size of Optima used on the site. Used for index nav and callouts
/// for New, Limited Edition and Your Perfect Match. styleguide pg.14
@mixin index-navigation {
  font-size: 14px;
  font-family: $optimaregular;
  letter-spacing: 0.02em;
}

@mixin text-product-anchor {
  font: 14px $akzidenz;
  letter-spacing: 0.02em;
  #{$aerin} .page-main & {
    font-family: 'Futura Now Regular';
  }
  #{$renutriv} & {
    font: 16px $helvetica-neue;
  }
  #{$estee_edit} & {
    font-family: $ffmarkweb;
  }
}

/// Form Text
@mixin form-text {
  @include text--short--plain;
  text-transform: none;
}

/// Form Help Text
/// (see in house account psds):
@mixin form-help {
  font-size: 12px;
  font-family: $akzidenz;
  line-height: 21px;
  letter-spacing: 0.05em;
}

/// Form Text Submit
/// Batch 2 styleguide pg. 15:
@mixin form-submit-button {
  @include text--bold;
  vertical-align: middle;
  background: $color-navy;
  color: $color-white;
  text-align: center;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
}

@mixin headline--products {
  font-size: 40px;
  font-family: $optimalight;
  letter-spacing: -0.02em;
  @include color-states;
  #{$aerin} .page-main & {
    font-family: 'Futura Now Regular';
    font-size: 34px;
  }
  #{$renutriv} & {
    font-family: $bb-roman;
    text-transform: uppercase;
    letter-spacing: normal;
  }
}

// minus brand styles
@mixin headline--products--plain {
  font-size: 40px;
  font-family: $optimalight;
  letter-spacing: -0.02em;
  @include color-states;
}

@mixin renutriv-header {
  font-family: $bb-roman;
  letter-spacing: normal;
}

@mixin renutriv-copy {
  font-family: $roboto-condensed;
  letter-spacing: normal;
}

@mixin estee_edit-copy {
  font-family: $ffmarkweb;
  letter-spacing: normal;
}

@mixin hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}

@mixin fontname--Monog-Engr {
  //Engraving Font Name
  &.Optima {
    font-family: $optima;
  }
  &.Roman {
    font-family: $roman;
  }
  &.Script {
    font-family: $script;
  }
  //Monogramming Font Name
  &.Roman_L_Monog_abc,
  &.Roman_L_Monog_aBc {
    font-family: $roman-l-monog-abc;
  }
  &.Interlock_M_Script_abc {
    font-family: $interlock-m-script-abc;
  }
  &.ArialOSRegular {
    font-family: $arialosregular;
  }
}

@mixin pg-content-wrapper {
  padding-#{$ldirection}: $mobile-outer-gutter;
  padding-#{$rdirection}: $mobile-outer-gutter;
  @media #{$medium-up} {
    padding-#{$ldirection}: 0;
    padding-#{$rdirection}: 0;
  }
}

@mixin max-width {
  max-width: $max-width;
  @include swap_direction(margin, 0 auto);
}

@mixin mobile_hidden {
  display: none;
  @media #{$medium-up} {
    display: block;
  }
}

@mixin pc_hidden {
  display: block;
  @media #{$medium-up} {
    display: none;
  }
}

@mixin hidden {
  display: none !important;
}

@mixin animationDuration($duration) {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -ms-animation-duration: $duration;
  -o-animation-duration: $duration;
  animation-duration: $duration;
}

@mixin animation-name($name) {
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
  -o-animation-name: $name;
  animation-name: $name;
}

@mixin voiceMicrophoneAnimation {
  animation: microphoneAnimation 2s linear infinite 0.4s;
}

@mixin ajaxSpinner($color, $width, $height, $borderwidth) {
  width: $width;
  height: $height;
  @include swap_direction(border, $borderwidth solid $color);
  border-#{$rdirection}-color: transparent;
  @include swap_direction(border-radius, 50%);
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  -ms-animation: spin 1s linear infinite;
  -o-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@mixin opacityTransition($duration) {
  transition: opacity $duration ease-in-out;
  -moz-transition: opacity $duration ease-in-out;
  -webkit-transition: opacity $duration ease-in-out;
  -o-transition: opacity $duration ease-in-out;
  -ms-transition: opacity $duration ease-in-out;
}

@mixin real-list {
  margin-bottom: 12px;
  li {
    list-style-position: inside;
  }
}

@mixin real-ul-list {
  @include real-list;
  li {
    list-style-type: disc;
  }
}

@mixin real-ol-list {
  @include real-list;
  li {
    list-style-type: decimal;
  }
}

@mixin move-up-into-nav {
  @media #{$medium-up} {
    margin-top: -$header-collapsed-height;
  }
}

@mixin popup_social_icons {
  position: absolute;
  top: 242px;
  img {
    float: $ldirection;
    margin-#{$rdirection}: 28px;
    width: 32px;
    height: 32px;
  }
}

@mixin carousel_arrows {
  .slick-next,
  .slick-prev {
    #{$ldirection}: 0;
    height: 100px;
    width: 45px;
    display: block;
    background-image: url(/media/images/global/arrow-prev.png);
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position: center center;
    background-color: $color-medium-gray-blue;
    z-index: 91;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: 38% 0 auto;
    @media #{$medium-up} {
      margin: 11% 0 auto;
    }
    @media #{$large-up} {
      transform: translateY(-39%);
      background-color: $color-lighter-gray;
      margin: 20% 0 auto;
    }
    &.slick-disabled {
      opacity: 0;
      cursor: default;
    }
  }
  .slick-next {
    #{$ldirection}: auto;
    #{$rdirection}: 0;
    transform: rotate(180deg);
    @media #{$large-up} {
      transform: rotate(180deg) translateY(39%);
    }
  }
  .slick-next:before,
  .slick-prev:before {
    content: '';
  }
}

@mixin ternary($condition, $property, $true-value, $false-value) {
  @if ($condition) {
    #{$property}: $true-value;
  } @else {
    #{$property}: $false-value;
  }
}

// Container related width rules
@mixin container-center {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
}

@mixin sd-spp-show-ppu {
  .elc-size-picker-container {
    vertical-align: top;
  }
  .elc-product-price-row-wrapper {
    display: block;
  }
  .elc-product-prices-wrapper,
  .elc-product-prices-clickable-wrapper {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    .elc-product-price-row-wrapper {
      bottom: 26px;
      #{$ldirection}: 55px;
      margin-#{$rdirection}: 6px;
      @media #{$medium-up} {
        #{$ldirection}: 50px;
        margin-#{$rdirection}: 6px;
      }
      .elc-product-original-price,
      .elc-product-price,
      .elc-product-price-range {
        padding: 0;
      }
    }
    .elc-product-price-per-unit-row-wrapper {
      display: inline-block;
      margin-bottom: 10px;
    }
  }
  .single-size {
    margin-bottom: 0;
    margin-#{$rdirection}: 10px;
    @media #{$medium-up} {
      margin: 0;
    }
    + .elc-product-prices-clickable-wrapper {
      display: inline;
      .elc-product-price-row-wrapper {
        display: inline-block;
      }
      .elc-product-prices-wrapper {
        display: inline;
      }
      .elc-product-price-per-unit-row-wrapper {
        display: block;
        margin-bottom: 10px;
        margin-#{$ldirection}: 30px;
        @media #{$medium-up} {
          margin-#{$ldirection}: 0;
        }
      }
      + .elc-quantity-selector-button {
        padding: 0;
      }
    }
  }
}

@mixin sd-spp-installments-price {
  .elc-product-installments-price {
    padding-top: 15px;
    @media #{$medium-up} {
      padding-top: 0;
    }
  }
}

@mixin sd-spp-info-content {
  .elc-info-content-section {
    margin: 30px auto;
    @media #{$medium-up} {
      margin: 0 auto;
    }
  }
}

@mixin sd-spp-afterpay-popup {
  .elc-iframe-modal-mask {
    .elc-iframe-modal-wrapper {
      max-height: 613px;
    }
  }
}

@mixin sd-spp-favorite-button {
  .elc-favorite-button {
    @media #{$medium-up} {
      display: inline-block;
      margin-#{$ldirection}: 20px;
      width: auto;
    }
  }
}

@mixin sd-spp-olapic-widget {
  .olapic-wall-widget {
    .olapic-loadmore-container {
      z-index: 1;
    }
  }
}

// SWATCH MIXIN - SINGLE TO MULTI SWATCH
// NOTE: duplicate exists _shade_picker.scss
@mixin swatches($swatch-total, $swatch-half, $swatch-third, $swatch-two-third, $swatch-offset) {
  .swatch__container {
    @include border-radius($swatch-half); /* top left, top right, bottom right, bottom left */
    #{$ie8} & {
      @include border-radius($swatch-half);
      behavior: url(/sites/esteelauder/themes/estee_base/js/lib/pie/PIE.htc);
    }
    position: relative;
    @include swap_direction(margin, $swatch-offset 0 0 $swatch-offset);
    width: $swatch-total;
    height: $swatch-total;
    overflow: hidden;
    z-index: 6;
    > div {
      position: absolute;
    }
    .swatch--1 {
      z-index: 1;
    }
    .swatch--2 {
      z-index: 2;
    }
    .swatch--3 {
      z-index: 3;
    }
    .swatch--4 {
      z-index: 4;
    }
    .swatch--5 {
      z-index: 5;
    }
  }
  .swatches--single {
    .swatch--2,
    .swatch--3,
    .swatch--4,
    .swatch--5 {
      display: none;
    }
  }
  .swatch--1 {
    @include border-radius($swatch-half);
    #{$ie8} & {
      @include border-radius($swatch-half);
    }
    top: 0;
    #{$ldirection}: 0;
    height: $swatch-total;
    width: $swatch-total;
  }
  .swatches--duo {
    .swatch--2 {
      top: 0;
      #{$rdirection}: 0;
      height: $swatch-total;
      width: $swatch-half;
    }
    .swatch--3,
    .swatch--4,
    .swatch--5 {
      display: none;
    }
  }
  .swatches--trio {
    .swatch--2 {
      top: 0;
      #{$rdirection}: 0;
      height: $swatch-total;
      width: $swatch-two-third;
    }
    .swatch--3 {
      #{$rdirection}: 0;
      height: $swatch-total;
      width: $swatch-third;
    }
    .swatch--4,
    .swatch--5 {
      display: none;
    }
  }
  .swatches--quad {
    .swatch--2 {
      top: 0;
      #{$ldirection}: 0;
      height: $swatch-half;
      width: $swatch-total;
    }
    .swatch--3 {
      top: 0;
      #{$rdirection}: 0;
      height: $swatch-half;
      width: $swatch-half;
    }
    .swatch--4 {
      bottom: 0;
      #{$rdirection}: 0;
      height: $swatch-half;
      width: $swatch-half;
    }
    .swatch--5 {
      display: none;
    }
  }
  .swatches--quint {
    .swatch--2 {
      top: 0;
      #{$ldirection}: 0;
      height: $swatch-third;
      width: $swatch-total;
    }
    .swatch--3 {
      top: 0;
      #{$rdirection}: 0;
      height: $swatch-total;
      width: $swatch-third;
    }
    .swatch--4 {
      bottom: 0;
      #{$rdirection}: 0;
      height: $swatch-third;
      width: $swatch-total;
    }
    .swatch--5 {
      bottom: 0;
      #{$ldirection}: 0;
      height: $swatch-two-third;
      width: $swatch-third;
    }
  }
}

@mixin heart-shape($width: 15px, $height: 14px) {
  position: relative;
  &::before {
    @include svg-icon-inline-mask('heart--filled.svg');
    background-color: $color-white;
    content: '';
    height: $height;
    #{$ldirection}: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: $width;
  }
}

@mixin best-shade-message() {
  .elc-shade-image.elc-best-shade .elc-absolute-shade-wrapper {
    @include heart-shape(18px, 18px);
    @media #{$medium-up} {
      @include heart-shape();
    }
  }
  .elc-dropdown-icon-container .elc-best-shade {
    @include heart-shape();
  }
  .elc-shade-name-with-best-shade-message {
    align-items: center;
    color: $color-navy;
    display: flex;
    font-family: $akzidenz;
    font-size: 12px;
    text-transform: uppercase;
    width: 100%;
    .elc-best-shade-name {
      flex: 1 1 60%;
    }
    .elc-best-shade-message {
      flex: 1 1 35%;
      font-weight: bold;
    }
  }
  .elc-dropdown-option[data-best-shade] {
    display: flex;
    flex-direction: row;
    .elc-best-shade {
      @include heart-shape();
    }
  }
}

// Hover Zoom Styles.
@mixin hover-zoom-styles() {
  .elc-vto-makeup-button,
  .elc-vto-foundation-button {
    .elc-vto-button.elc-button {
      z-index: 1;
    }
  }
  .elc-vto-makeup-button,
  .elc-vto-foundation-button {
    @media #{$device-tablet-up-landscape} {
      position: absolute;
      #{$ldirection}: 134px;
    }
  }
  .elc-product-full {
    .elc-left-column-section {
      .elc-product-zoom {
        display: none;
        align-items: flex-start;
        height: 100%;
        justify-content: center;
        #{$ldirection}: 0;
        position: absolute;
        overflow: hidden;
        top: 0;
        width: 100%;
        @media #{$device-tablet-up-landscape} {
          align-items: center;
          width: 100%;
          height: 600px;
          top: 10px;
        }
        @media #{$device-tablet-up-landscape} {
          display: block;
          #{$ldirection}: 10px;
        }
      }
    }
  }
  .elc-product-zoom-item-full {
    width: 100%;
    @media #{$device-tablet-up-landscape} {
      width: 600px;
      height: 600px;
      position: relative;
      display: block;
      overflow: hidden;
    }
    .elc-img {
      height: auto;
      margin: 0 auto;
      width: auto;
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      &.elc-placeholder {
        height: 100%;
      }
    }
  }
  .elc-product-zoom-button {
    box-sizing: border-box;
    display: block;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    #{$ldirection}: 2px;
    top: 2px;
    margin: 0;
    padding: 0;
    position: absolute;
    outline: none;
    background-color: transparent;
    border: 0;
  }
  .elc-product-zoom-inner {
    @media #{$device-tablet-up-landscape} {
      max-width: 1281px;
      @include swap_direction(margin, 0 auto 0 108px);
      position: absolute;
      top: 0;
    }
    @media #{$boutiquelarge-up} {
      max-width: 1366px;
      @include swap_direction(margin, 0 auto 0 135px);
    }
  }
  .elc-product-zoom-inner-wrap {
    @media #{$device-tablet-up-landscape} {
      width: 600px;
      height: 600px;
      overflow: auto;
    }
  }
}
