///
/// @file components/_pc_slick_carousel.scss
///
/// \brief Styles for our automated carousel
///

.slick-carousel {
  .slick-prev,
  .slick-next {
    width: 30px;
    height: 40px;
    &:before {
      content: '';
    }
  }
  .slick-prev {
    #{$ldirection}: 32px;
    background-size: auto auto;
    background-position: 0 -8820px;
    height: 40px;
    width: 30px;
    #{$renutriv} & {
      background-size: auto auto;
      background-position: 0 -8930px;
      height: 40px;
      width: 30px;
    }
  }
  .slick-next {
    #{$rdirection}: 32px;
    background-size: auto auto;
    background-position: 0 -8600px;
    height: 40px;
    width: 30px;
    #{$renutriv} & {
      background-size: auto auto;
      background-position: 0 -8710px;
      height: 40px;
      width: 30px;
    }
  }
  // light variant
  &.slick-carousel--light {
    .slick-prev {
      background-size: auto auto;
      background-position: 0 -8930px;
      height: 40px;
      width: 30px;
    }
    .slick-next {
      background-size: auto auto;
      background-position: 0 -8710px;
      height: 40px;
      width: 30px;
    }
  }
}

.slick-dots {
  bottom: -25px;
  li {
    width: auto;
    @include swap_direction(margin, 0 0 0 3px);
    &:first-child {
      margin-#{$ldirection}: 0;
    }
    button {
      width: 12px;
      &:before {
        // regular dot
        #{$renutriv} &,
        .slick-carousel--light & {
          opacity: 1;
          color: hsl(0, 0%, 34.9%);
        }
      }
    }
    &.slick-active {
      button:before {
        // active dot
        #{$renutriv} &,
        .slick-carousel--light & {
          opacity: 1;
          color: $color-white;
        }
      }
    }
  }
}

[data-slick-paging='no'] .slick-dots {
  #{$ldirection}: -9999px;
}

.pc-carousel-formatter {
  margin-bottom: 100px;
}
