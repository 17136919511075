// landing page elements

.ee-logo-mobile {
  text-align: center;
  padding-top: 6px;
}

// typography
.ee-landing__tile {
  .ee-landing__text {
    margin-top: 20px;
    .text-short {
      margin-bottom: 15px;
    }
    h4 {
      margin-bottom: 15px;
    }
    .ee-subhead {
      margin-bottom: 15px;
    }
  }
}

.ee-hero__caption {
  font-size: 14px;
}

// desktop - different nav when scrolled
.ee-landing__filters__side__wrapper {
  @include transition-property(left, width);
  @include transition-duration(0.5s);
  @include transition-timing-function(ease-in);
  #{$ldirection}: 0;
  width: 84px;
  overflow: hidden;
  font-family: $optimalight;
  display: none;
  &.off-side {
    #{$ldirection}: -100%;
  }
  @media #{$medium-up} {
    display: block;
    position: fixed;
    top: 140px;
    height: 44px;
    background-color: $color-navy;
    width: auto;
    &.collapsed {
      li:not(.filter-trigger) {
        display: none;
      }
    }
    &.expanded {
      width: 100%;
      .filter-trigger {
        display: none;
      }
    }
  }
}

.ee-landing__filters__side {
  color: $color-white;
  .expanded & {
    position: absolute;
    #{$ldirection}: 50%;
    @include transform(translateX(-50%));
  }
  li {
    display: inline;
  }
  .ee-landing__filter-link,
  .ee-landing__filter-control {
    color: $color-white;
    display: inline-block;
    height: 44px;
    line-height: 44px;
    @include swap_direction(padding, 0 1em);
    &:hover {
      color: $color-periwinkle-blue;
    }
    &.term-active {
      color: $color-periwinkle-blue;
    }
  }
  .filter-trigger a {
    position: relative;
    margin-#{$rdirection}: 14px;
    &:after {
      background-size: auto auto;
      background-position: -427px -6851px;
      height: 25px;
      width: 44px;
      position: absolute;
      content: '';
      top: 8px;
      #{$rdirection}: -35px;
    }
  }
  .filter-trigger-close a {
    @include squish-text;
    background-size: auto auto;
    background-position: 0 -6801px;
    height: 25px;
    width: 44px;
    position: relative;
    top: -18px;
    #{$ldirection}: -12px;
  }
}

// Layout
@media #{$small-only} {
  .ee-landing__filters {
    @include headline--senary;
    background-color: $color-navy;
    color: $color-white;
    @include swap_direction(margin, 1em auto);
    max-width: 270px;
    text-align: $ldirection;
    .mobile-trigger {
      a {
        color: $color-white;
        text-align: center;
      }
    }
    &.collapsed {
      li:not(.mobile-trigger) {
        height: 0;
        @include swap_direction(border, none);
        overflow: hidden;
      }
    }
    li:not(.mobile-trigger) {
      border-top: $color-mobile-separator solid 1px;
    }
    a {
      color: $color-periwinkle-blue;
      display: block;
      height: 44px;
      line-height: 44px;
      @include swap_direction(padding, 0 1em);
      &:hover {
        color: $color-white;
      }
    }
  }
}

@media #{$medium-up} {
  .ee-landing__filters {
    text-align: center;
    padding-bottom: 10px;
    .mobile-trigger {
      display: none;
    }
  }
  .ee-landing__filters li {
    display: inline;
    @include swap_direction(padding, 0 1em);
    font-size: 18px;
    font-family: $optimalight;
  }
  .ee-landing__filter-link {
    color: $color-medium-gray;
    white-space: nowrap;
    &.term-active {
      color: $color-black;
    }
  }
  .term-active {
    font-weight: bold;
  }
}

.ee-landing-tiles {
  max-width: 800px;
  @include swap_direction(margin, 0 auto);
  padding-top: 40px;
  @media #{$medium-up} {
    border-top: 1px solid $color-medium-gray;
  }
  @media #{$small-only} {
    @include swap_direction(margin, $mobile-outer-gutter);
    padding-top: 0;
  }
}

.ee-landing__tile {
  text-align: center;
  margin-bottom: 40px;
  @media #{$large-up} {
    width: 50%;
    float: $ldirection;
    min-height: 650px;
    &.ee-landing__tile--brand-only {
      width: 100%;
      min-height: 50px;
    }
  }
}

.ee-landing__tile-brand-only {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
  min-height: 50px;
  img {
    @media #{$small-only} {
      width: 100%;
    }
  }
}

.ee-landing__tile--large {
  img {
    @media #{$small-only} {
      width: 100%;
    }
  }
  @media #{$large-up} {
    width: 100%;
    float: none;
    clear: both;
  }
}

.ee-landing__tile--med {
  img {
    @media #{$small-only} {
      width: 100%;
    }
  }
  .ee-landing__text {
    @media #{$large-up} {
      max-width: 390px;
      @include swap_direction(margin, 20px auto 0 auto);
    }
  }
}

.ee-landing__tile--small {
  @media #{$large-up} {
    padding-top: 80px;
    .ee-landing__text {
      max-width: 226px;
      @include swap_direction(margin, 20px auto 0 auto);
    }
  }
  img {
    @media #{$small-only} {
      width: 100%;
    }
  }
}
