.footer-links-sitewide-footer-menu-grouping-elc-nodeblock {
  .footer-find-us-nodeblock {
    // Social links: 2 levels down
    .menu-item-container .menu-item-container {
      @include clearfix;
      li {
        // @TODO: should use a class
        float: $ldirection;
      }
    }
  }
}
