$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;
$base-theme-image-path: '/sites/esteelauder/themes/estee_base/img/';
$svg-path: '/sites/esteelauder/themes/estee_base/img/icons/src/';
$netstorage-font-path: '/_fonts/esteelauder/'; // Netstorage path.
$container-max-width: 1150px;

// Define variables before compass imports
@import 'global/variables';
@import 'breakpoint';
@import 'compass';

/// Global
/// Global Sass/Compass utilities, and variables
/// These are universally needed files that are SCSS-specific. Most of the
/// following DO NOT directly result in rendered CSS
@import 'overrides';
@import 'global/functions';
@import 'global/mixins';
@import 'global/icons-mixins';
@import 'global/buttons';
@import 'global/media-queries';
@import 'compass/css3/animation';
@import 'global/adaptive-placeholders';
@import 'global/gluxre_update/gluxre_update__layout';
