.page-products .page-wrapper {
}

.js-mpp-wrapper {
  clear: both;
}

.mpp-missing {
  color: #71cc73;
}

.mpp {
  position: relative;
  padding-bottom: 100px;
  @include swap_direction(margin, 0 auto);
  @media #{$product-normal-only} {
    max-width: 800px;
    min-width: 730px;
    width: 100%;
    #{$estee_edit} & {
      max-width: 1024px;
      min-width: 730px;
    }
  }
  @media #{$product-wide-up} {
    max-width: 1066px;
    min-width: 730px;
    width: 100%;
    #{$estee_edit} & {
      max-width: 1024px;
      min-width: 730px;
    }
  }
}

.mpp__header {
  @include headline--products;
  text-align: center;
  border-bottom: 1px solid $color-light-gray;
  #{$aerin} & {
    border-color: $color-border-aerin;
    color: $color-periwinkle-blue--aerin;
  }
  #{$renutriv} & {
    border-color: $color-gold--renutriv;
    text-transform: uppercase;
    font-size: 35px;
    letter-spacing: 0.03em;
    font-weight: lighter;
  }
  &.off {
    @include swap_direction(border, 0);
  }
}

.mpp__product-grid {
  @include pie-clearfix;
  margin-top: 30px;
  .mpp__product:nth-child(3n + 1) {
    // clear every row at 3rd product
    @media #{$medium-up} {
      clear: #{$ldirection};
    }
    @media #{$medium-only} {
      margin-#{$ldirection}: 25px;
    }
  }
  .product-mpp__tout {
    .product-tout {
      .module_block__hero {
        position: relative;
        .module_block__imgs {
          width: 100%;
        }
      }
      &__img {
        opacity: 1;
        @media #{$product-wide-up} {
          height: 412px;
        }
        &.product-tout__medium {
          display: none;
        }
      }
      &--flexi-height-over,
      &--flexi-height {
        position: absolute;
        width: 100%;
        display: block;
        min-height: 649px;
        @media #{$device-ipad_only} {
          min-height: 350px;
        }
        .product-tout__img {
          height: 100%;
        }
        .product-tout__text {
          position: absolute;
          bottom: 0;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          width: 100%;
          margin: auto;
          padding-top: 2%;
          @media #{$device-ipad_only} {
            @include swap_direction(padding, 0 20px 0 25px);
          }
          &--center {
            top: 0;
            display: table-cell;
            height: 151px;
          }
          &--top {
            top: 30px;
            bottom: auto;
          }
          &--bottom {
            bottom: 30px;
            top: auto;
          }
        }
      }
      &--flexi-height-over {
        &:hover {
          .product-tout__text {
            display: table;
            &--custom {
              display: block;
            }
          }
        }
        .product-tout__text {
          display: none;
          color: $color-navy;
          .product-tout__copy {
            margin-top: 0;
          }
          .product-tout__cta {
            margin: 0;
            padding: 5px 0 10px;
          }
        }
      }
    }
    .product-tout__text {
      position: absolute;
      top: auto;
      padding: 0;
      width: 100%;
      &--navy * {
        color: $color-navy;
      }
      &--white * {
        color: $color-white;
      }
      .product-tout__title {
        @include swap_direction(padding, 15px 0 5px 10px);
        text-transform: capitalize;
        font-size: 30px;
        margin: 0;
        font-family: $optimalight;
        letter-spacing: -0.005em;
        @media #{$device-ipad_only} {
          padding-top: 0;
        }
        p {
          margin: 0;
        }
      }
      .product-tout__eyebrow {
        margin-top: 0;
        padding-top: 20px;
        font-size: 12px;
        letter-spacing: 0.15em;
        font-family: $akzidenz;
      }
      .product-tout {
        &__copy--bold {
          font-weight: bold;
        }
        &__copy,
        &__copy--bold {
          margin-top: 5px;
          font-size: 15px;
          letter-spacing: 0.05em;
          font-family: $akzidenz;
          p {
            margin: 0;
          }
        }
        &__uppercase {
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 0.15em;
          p {
            font-size: 12px;
          }
        }
        &__uppercase--bold {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 0.15em;
          p {
            font-size: 12px;
          }
        }
      }
      .product-tout__cta {
        margin-top: 15px;
        &--text {
          display: inline-block;
          background: transparent;
          font-size: 12px;
          font-family: $akzidenz;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          cursor: pointer;
          font-weight: bold;
          margin-bottom: 20px;
        }
        &--cta {
          @include background($color-navy);
          color: $color-white;
          height: 40px;
          line-height: 40px;
          padding: 0 20px;
        }
        &--link {
          color: $color-navy;
          text-decoration: underline;
          &.product-tout__cta--dark {
            background: transparent;
            color: $color-navy;
          }
        }
        &--dark {
          @include background($color-navy);
          color: $color-white;
        }
        &--light {
          @include background($color-periwinkle-blue);
          color: $color-white;
        }
        &--transparent-light {
          border: 1px solid $color-white;
          color: $color-white;
          background: transparent;
        }
        &--transparent-dark {
          border: 1px solid $color-navy;
          color: $color-navy;
          background: transparent;
        }
      }
    }
    .product-tout--flexi-height {
      .product-tout__eyebrow {
        padding: 0 0 10px;
      }
    }
    .autoplay_video {
      .looping_vidContent {
        position: absolute;
        z-index: 1;
        width: 100%;
        display: block;
        opacity: 0;
      }
      button {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
    .fadeElemIn {
      @include animationDuration(0.8s);
      @include animation-name(fadeElemIn);
    }
    .fadeElemOut {
      @include animationDuration(0.8s);
      @include animation-name(fadeElemOut);
    }
    .module_block__imgs .module_block__img.desktopImgSrsSet,
    .module_block__imgs .module_block__img.desktopMobImgSrsSet {
      height: 36.631vw;
      text-indent: -100vw;
    }
    .autoplay_video.mob_auto_loop {
      display: none;
    }
    .video_content_player {
      .el_vcv1_controls_wrapper {
        .el_vcv1_controls {
          margin-top: 45%;
        }
      }
    }
  }
  .mpp__product:first-child {
    @media #{$medium-only} {
      margin-#{$ldirection}: 13px;
    }
  }
  .mpp__product:nth-child(2) {
    @media #{$medium-up} {
      @include swap_direction(margin, 0 5.75% 60px);
    }
    @media #{$large-up} {
      @include swap_direction(margin, 0 7.6% 60px);
    }
  }
  .mpp__product:nth-child(3n + 1) {
    @media #{$medium-only} {
      margin-#{$ldirection}: 13px;
    }
  }
  .mpp__product:nth-child(3n + 2) {
    @media #{$medium-up} {
      margin: 0 5.75% 133px;
    }
    @media #{$large-up} {
      margin: 0 7.6% 133px;
    }
  }
}

.middle-grid {
  .mpp__product:nth-child(3n + 2) {
    //add margin to middle row
    @include swap_direction(margin, 0 5.75% 60px);
    @media #{$large-up} {
      @include swap_direction(margin, 0 7.6% 60px);
    }
    @media #{$medium-only} {
      margin: 0 4% 60px;
    }
  }
}
//Estee Edit MPP custom grid with one feature product
.custom-mpp-estee-edit-tem-wrapper {
  .product_brief__headers__container {
    display: inline-block;
    min-height: 86px;
  }
  & .mpp {
    padding-bottom: 0;
  }
  // adjustments for background
  margin-top: -30px; // top of product grids removing space between grids
  .main-content & {
  }
  .parallax-window {
    background: transparent;
  }
  img.full-width {
    width: 100%;
  }
  .parallaxfx__container.full-width {
    background-size: 100%;
  }
  .parallaxfx__slider.full-width {
    background-size: 100%;
  }
  .parallax__ee-container {
    .parallaxfx__ee.full-width {
      background-size: 100%;
    }
  }
  .pollaroid_image {
    position: absolute;
    top: 275px;
    &.main-section {
      top: 50px;
      left: 20px;
    }
  }
  .logo_image {
    position: absolute;
    top: 775px;
    left: 130px;
  }
  .feature_product_header_copy {
    &.main-section {
      left: 75px;
      top: 553px;
    }
    &.white {
      color: $color-white;
    }
    top: 563px;
    min-width: 450px;
    @media #{$medium-only} {
      width: 283px;
    }
    position: absolute;
    z-index: 1;
    @media #{$medium-only} {
      left: auto;
    }
    &.left-top {
      left: 6%;
      @media #{$medium-up} {
        left: -6%;
        top: 33px;
      }
      @media #{$medium-only} {
        left: 5px;
      }
      @media #{$xxlarge-up} {
        left: 6%;
        top: 33px;
      }
    }
    &.right-top {
      top: 63px;
      float: right;
      position: relative;
      right: 5%;
      @media #{$large-up} {
        top: 63px;
      }
      @media #{$xxlarge-up} {
        top: 63px;
      }
    }
    &.left-lower {
      left: 6%;
      @media #{$medium-up} {
        left: -6%;
      }
      @media #{$medium-only} {
        left: 5px;
      }
      @media #{$xxlarge-up} {
        left: 7%;
      }
    }
    &.right-lower {
      @media #{$medium-only} {
        right: 5px;
      }
    }
    &.center-top {
      top: 33px;
      @media #{$medium-up} {
        left: 31%;
      }
      @media #{$xxlarge-up} {
        left: 31%;
      }
    }
    &.center-lower {
      top: 663px;
      @media #{$medium-up} {
        left: 31%;
      }
      @media #{$xxlarge-up} {
        left: 31%;
      }
    }
  } // feature product
  position: relative;

  // for Estee Edit filtered products grid
  #{estee_edit} & {
    .product_grid_filter__content.mpp__product {
      width: 28.2%;
    }
    .product_grid_filter__content.mpp__product-grid {
      min-height: 711px;
    }
  }
  //.no-feature-product class for no feature product
  .mpp__product-grid:not(.mpp__product__feature) {
    .mpp__product {
      @media #{$medium-up} {
        width: 22.25%;
      }
      @media #{$xxlarge-up} {
        width: 28.25%;
      }
    }
    .mpp__product:nth-child(3n + 1) {
      @media #{$medium-up} {
        margin-left: 9%;
      }
      @media #{$xxlarge-up} {
        margin-left: 3.2%;
      }
    }
    .mpp__product:nth-child(3n + 2) {
      //add margin to left row
      @media #{$medium-up} {
        margin: 0 7.6% 60px;
      }
    }
    //adjustments for background
    .mpp__product:nth-child(3n + 2) {
      //add margin to middle row
      @media #{$xxlarge-up} {
        margin: 0 4% 60px;
      }
    }
    // for Filter content grid
    & .mpp__product.middle_pos {
      //add margin to left row
      clear: none;
      @media #{$medium-only} {
        margin: 0 3% 60px;
      }
    }
    //adjustments for background
    .mpp__product.middle_pos {
      //add margin to middle row
      clear: none;
      margin: 0 7.6% 60px;
      @media #{$xxlarge-up} {
        margin: 0 4% 60px;
      }
    }
    //adjustments for background
    & .mpp__product.left_pos {
      //add margin to left row
      clear: left;
      margin: 0;
      @media #{$medium-up} {
        margin-left: 9%;
      }
      @media #{$xxlarge-up} {
        margin-left: 3.2%;
        margin-right: 0;
      }
    }
    & .mpp__product.right_pos {
      //add margin to left row
      clear: none;
      margin: 0;
      @media #{$xxlarge-up} {
        margin-left: 0;
      }
      @media #{$medium-only} {
        margin: 0;
      }
    }
    // for Filter content grid
  }

  //.no-feature-product class for no feature product
  .mpp__product__feature {
    #{$estee_edit} & {
      position: relative;
      padding-top: 0;
      @include pie-clearfix;
      margin-top: 30px;
    }
  }
  .mpp__product-grid {
    .single__product {
      //custom estee edit featured product in grid
      height: 800px;
      margin: 0 0 50px 0;
      width: inherit;
      background: none;
      &.desc-white {
        color: $color-white;
        a:not(.product-brief__button-quickshop),
        h,
        h2,
        h3,
        h4,
        h5 & {
          color: $color-white;
        }
      }
      &.left-top {
        left: 3%;
        @media #{$medium-only} {
          left: -7%;
        }
        @media #{$large-up} {
          left: -10%;
        }
        @media #{$xxlarge-up} {
          left: 4%;
        }
      }
      &.left-lower {
        top: 220px;
        .feature_product_header_copy {
          left: 75px;
        }
      }
      &.right-lower {
        top: 220px;
        float: right;
        .feature_product_header_copy {
          top: 63px;
          float: right;
          position: relative;
          right: 5%;
        }
      }
      &.right-lower-short {
        height: 500px;
        top: 220px;
        float: right;
        .feature_product_header_copy {
          top: 63px;
          float: right;
          position: relative;
          right: 5%;
        }
      }
      .mpp__header {
        border: none;
      }
    }
  }
}

//Estee Edit MPP custom grid video btns
.quickshop_with_video {
  width: 170px;
  margin: 0 auto;
  .video-play {
    display: block;
    width: 33px;
    height: 33px;
    border: 1px solid;
    border-color: $color-bright-blue--estee_edit;
    float: right;
    background: url('/media/export/cms/dev/estee_edit_video_btn.jpg');
    margin: 0 0 0 8px;
  }
  //for Filter
  &.product_grid_filter__content {
    .mpp__product {
      @media #{$medium-only} {
        width: 30%;
      }
    }
    &.mpp__product.middle_pos {
      //add margin to left row
      @media #{$medium-only} {
        margin: 0 3% 60px;
      }
    }
    //adjustments for background
    //mpp__product:nth-child(3n+2)

    &.mpp__product.middle_pos {
      //add margin to middle row
      @media #{$xxlarge-up} {
        margin: 0 3% 60px;
      }
    }
    //adjustments for background
    &.mpp__product.left_pos {
      //add margin to left row
      @media #{$xxlarge-up} {
        margin-left: 3.2%;
      }
      @media #{$medium-only} {
        margin-left: 2%;
      }
      @include swap_direction(margin, 0 2% 60px);
    }
  }
}

.mpp-2-column {
  .mpp__product-grid {
    @include pie-clearfix;
    margin-top: 30px;
    .product_brief {
      .product_brief__buttons {
        display: inline-block;
      }
    }
    .mpp__product:nth-child(3n + 1) {
      //clear every row at 3rd product
      @media #{$medium-up} {
        clear: none;
      }
      @media #{$medium-only} {
        margin-#{$ldirection}: auto;
      }
    }
    .mpp__product:nth-child(2n + 1) {
      //clear every row at 3rd product
      @media #{$medium-up} {
        clear: both;
      }
      @media #{$medium-only} {
        margin-#{$ldirection}: auto;
      }
    }
    .mpp__product:nth-child(3n + 2) {
      //add margin to middle row
      @include swap_direction(margin, 0 auto 60px);
      @media #{$medium-only} {
        @include swap_direction(margin, 0 auto 60px);
      }
    }
  }
}
/*  nth child support for IE8 */
#{$ie8} {
  .nth-child-3np2 {
    @include swap_direction(margin, 0 0% 60px);
  }
}

.mpp__product {
  float: $ldirection;
  text-align: center;
  color: $color-navy;
  #{$renutriv} & {
    color: white;
  }
  #{$aerin} & {
    color: $color-periwinkle-blue--aerin;
  }
  @media #{$medium-up} {
    position: relative;
    width: 28.25%;
    margin-bottom: 108px;
    padding-bottom: 129px; //49 + 80 + room for abs pos buttons and reviews
    min-height: 640px;
    .product_brief__image {
      width: 100%;
    }
  }
}

.page-main .mpp__product-sort-container {
  position: absolute;
  top: 6px;
  #{$rdirection}: 0;
  .mpp__product-sort {
    @include min-width(175px);
    border-color: $color-white;
    #{$renutriv} & {
      border-color: $color-black;
    }
    &.selectBox-active {
      border-color: $color-white;
      #{$renutriv} & {
        border-color: $color-black;
      }
      &.selectBox-menuShowing {
        border-color: $color-navy;
        #{$renutriv} & {
          border-color: $color-white;
        }
        #{$aerin} & {
          border-color: $color-periwinkle-blue--aerin;
        }
      }
    }
    .selectBox-label {
      display: inline-block;
      width: 100%;
      text-align: $rdirection;
    }
  }
}

.mpp__product-sort-selectBox-dropdown-menu {
  text-transform: uppercase;
  #{$renutriv} & {
    li a {
      line-height: 24px;
    }
  }
}

// Temporary fix for AU MPP to hide filters
.domain-au {
  .node-elc-mpp {
    #mpp__filter-tab {
      display: none;
    }
    #mpp__filter {
      display: none;
    }
  }
}

.hero-block-wrapper.popup15 {
  .cta-button-wrapper {
    a.cta__button {
      cursor: pointer;
    }
  }
}

// Temporary hide Sort By on Aerin Holiday MPP
.page-node-38920.brand-aerin {
  .mpp.mpp--brand-aerin {
    padding-bottom: 30px;
  }
  .mpp__product-sort-container {
    display: none;
  }
  ul.mpp__product-grid {
    margin-top: 15px;
  }
}

//Engraving Button Style
.mpp__product {
  .product-full__engraving-button {
    margin-bottom: 10px;
    width: 100%;
  }
}
