$largePlus: '#{$screen} and (min-width: 770px) and (max-width: 934px)' !default; //larger than large, but smaller than xlarge

.pc-love-swatches {
  font-family: 'AkzidenzGrotesk W1G';
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  @media #{$iphone5-portrait} {
    font-size: 12px;
  }
  color: $color-navy;
  &__header {
    text-align: center;
    &--pre-title {
      letter-spacing: 1.6px;
    }
    &--title {
      font-size: 124px;
      font-family: 'OptimaDisplayLight';
      text-transform: none;
      letter-spacing: -7px;
      font-weight: normal;
      line-height: 0.8em;
      margin-top: 10px;
      @media #{$medium-down} {
        font-size: 92px;
        line-height: 0.8em;
        letter-spacing: -5px;
        margin: 0.3em 0;
      }
      @media #{$iphone5-portrait} {
        font-size: 75px;
        letter-spacing: -4px;
      }
    }
    &--copy {
      font-weight: normal;
      width: 610px;
      font-size: 12px;
      margin: 1.8em auto;
      line-height: 1.75em;
      letter-spacing: 1.6px;
      @media #{$medium-down} {
        display: none;
      }
    }
    &--filters {
      text-transform: uppercase;
      height: 1.1em;
      letter-spacing: 1.6px;
      z-index: 50;
      position: relative;
    }
    &--filter {
      display: inline-block;
      margin-right: 11px;
      @media #{$iphone5-portrait} {
        margin-right: 7px;
      }
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.active,
      &:hover {
        border-bottom: 1px solid $color-navy;
      }
      &-all {
        @media #{$medium-down} {
          display: block;
          margin: 0 auto 1em;
        }
        &.active,
        &:hover {
          @media #{$medium-down} {
            border-bottom: 0;
          }
        }
        &-mobile {
          width: auto;
          margin: 0 auto;
          &.active,
          &:hover {
            border-bottom: 1px solid $color-navy;
          }
        }
      }
    }
  }
  &__body {
    margin: 50px auto;
    max-width: 972px;
    position: relative;
    text-align: center;
    @media #{$medium-down} {
      margin-top: 0;
    }
    //hide flex controls
    .flex-next,
    .flex-prev {
      display: none;
    }
  }
  &__product {
    display: inline-block;
    @media #{$medium-down} {
      top: 750px;
      position: relative;
      width: 80%;
      margin: 0 auto;
    }
    @media #{$medium-up} {
      top: 950px;
    }
    @media #{$iphone5-portrait} {
      top: 650px;
    }
    @media #{$large-up} {
      margin-right: 100px;
      top: 0;
    }
    &--image {
      background-size: cover;
      @media #{$large-up} {
        width: 280px;
        height: 550px;
      }
      @media #{$xlarge-up} {
        width: 317px;
        height: 622px;
      }
      @media #{$largePlus} {
        width: 200px;
        height: 392px;
      }
      @media #{$medium-down} {
        display: none;
      }
    }
    &--name {
      text-align: center;
      letter-spacing: 1.6px;
    }
    &--buttons {
      margin-top: 20px;
      @media #{$medium-down} {
        margin: 20px 0 40px;
      }
    }
    &--shop-now {
      @media #{$medium-down} {
        width: 100%;
      }
    }
  }
  &__swatches {
    top: -60px;
    @media #{$xlarge-up} {
      height: 590px;
      width: 590px;
    }
    @media #{$medium-up} {
      height: 550px;
      width: 550px;
      display: inline-block;
    }
    position: relative;
    @media #{$large-down} {
      margin: 0 auto;
    }
    @media #{$largePlus} {
      width: 420px;
      height: 420px;
      top: -40px;
    }
    @media #{$small-only} {
      height: 360px;
      width: 360px;
    }
    @media #{$iphone5-portrait} {
      height: 250px;
      width: 250px;
    }
  }
  &__swatch {
    @media #{$iphone5-portrait} {
      font-size: 33px;
    }
    font-size: 47px;
    @media #{$xlarge-up} {
      font-size: 80px;
    }
    @media #{$medium-up} {
      font-size: 70px;
    }
    @media #{$largePlus} {
      font-size: 50px;
    }
    width: 1em;
    height: 1em;
    background-size: contain;
    position: absolute;
    cursor: pointer;
    &.greyed {
      opacity: 0.2;
    }
  }
  &__carousel {
    margin-bottom: 100px;
    position: relative;
    top: -30px;
    z-index: 50;
    @media #{$large-up} {
      display: none;
    }
  }
  &__carousel--slide {
    img {
      position: relative;
      bottom: -25px;
      width: 84px;
      height: 284px;
      transition: 0.5s;
      padding: 0 7%;
      &.active {
        bottom: 0;
      }
    }
  }

  //position hearts in absolute positions
  &__swatch {
    //row one
    &--0 {
      top: 0;
      left: 0.75em;
    }
    &--1 {
      top: 0;
      left: 2.2em;
    }
    &--2 {
      top: 0;
      left: 4.2em;
    }
    &--3 {
      top: 0;
      left: 5.65em;
    }
    //row 2
    &--4 {
      top: 1em;
      left: 1.35em;
    }
    &--5 {
      top: 1em;
      left: 2.6em;
    }
    &--6 {
      top: 1em;
      left: 3.85em;
    }
    &--7 {
      top: 1em;
      left: 5.1em;
    }
    //row 3 outer corners
    &--8 {
      top: 1.3em;
      left: 0em;
    }
    &--9 {
      top: 1.3em;
      left: 6.6em;
    }
    // row 4
    &--10 {
      top: 2.1em;
      left: 1.1em;
    }
    &--11 {
      top: 2.1em;
      left: 2.2em;
    }
    &--12 {
      top: 2.1em;
      left: 3.3em;
    }
    &--13 {
      top: 2.1em;
      left: 4.4em;
    }
    &--14 {
      top: 2.1em;
      left: 5.5em;
    }
    // row 5 outer corners
    &--15 {
      top: 2.7em;
      left: 0;
    }
    &--16 {
      top: 2.7em;
      left: 6.6em;
    }
    //row 6
    &--17 {
      top: 3.2em;
      left: 1.1em;
    }
    &--18 {
      top: 3.2em;
      left: 2.2em;
    }
    &--19 {
      top: 3.2em;
      left: 3.3em;
    }
    &--20 {
      top: 3.2em;
      left: 4.4em;
    }
    &--21 {
      top: 3.2em;
      left: 5.5em;
    }
    //row 7
    &--22 {
      top: 4.3em;
      left: 1.1em;
    }
    &--23 {
      top: 4.3em;
      left: 2.2em;
    }
    &--24 {
      top: 4.3em;
      left: 3.3em;
    }
    &--25 {
      top: 4.3em;
      left: 4.4em;
    }
    &--26 {
      top: 4.3em;
      left: 5.5em;
    }

    //row 8 (only three)
    &--27 {
      top: 5.4em;
      left: 2.2em;
    }
    &--28 {
      top: 5.4em;
      left: 3.3em;
    }
    &--29 {
      top: 5.4em;
      left: 4.4em;
    }
    //row 9 (just the one)
    &--30 {
      top: 6.5em;
      left: 3.3em;
    }
  }
}
