///
/// @file components/input_types/_home_up_next.scss
///
/// Mainly resetting default flexslider styles
///

// undo default flexslider styles
.flexslider {
  @include border-radius(0);
  @include box-shadow(none);
  @include swap_direction(border, none);
  background: none;
  margin-bottom: 0;
  .flex-control-nav {
    position: static;
    margin-top: 22px;
    li {
      a {
        width: 5px;
        height: 5px;
        &.flex-active {
          background: black;
        }
      }
    }
  }
}
// remove use of font font-emphasize-style: accent
.flex-direction-nav a:before,
.flex-direction-nav a.flex-next:before {
  content: '';
}

.flex-direction-nav a {
  top: 50%;
  margin-top: -39px;
}

.flexslider:hover {
  .flex-next {
    #{$rdirection}: 0;
  }
  .flex-prev {
    #{$ldirection}: 0;
  }
}
