.country-chooser {
  position: relative;
  @media #{$small-only} {
    border-bottom: 1px solid $color-medium-navy;
    padding: 5px 25px 25px 25px;
  }
}

.country_chooser__header {
  margin-bottom: 20px;
  @media #{$small-only} {
    margin-bottom: 10px;
  }
}

.country-chooser__selector {
  text-transform: none;
  width: 100%;
  display: block;
  border: 1px solid $color-light-gray;
  text-align: $ldirection;
  @include form-text;
  @include swap_direction(padding, 7px 32px 7px 10px);
  position: relative;
  &:focus {
    outline: 0;
  }
  &::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    margin: 8px 12px;
    #{$rdirection}: 0;
    background-color: $color-periwinkle-blue;
    @include svg-icon-inline-mask('caret.svg');
  }
  &.active::after {
    transform: rotate(180deg);
  }
}

// override needed for footer
.country-chooser a.country-chooser__selector {
  color: $color-white;
  text-decoration: none;
}

.country-chooser .menu {
  display: none;
  position: absolute;
  #{$ldirection}: 0;
  background: $color-navy;
  @include swap_direction(border, 1px solid $color-light-gray);
  border-bottom: 0;
  width: 100%;
  max-height: 276px; //12 open
  overflow: scroll;
}

.country-chooser .menu li {
  display: block;
  text-align: $ldirection;
}

.country-chooser .menu li a {
  display: block;
  border-bottom: 1px solid $color-light-gray;
  @include swap_direction(padding, 0 12px);
  line-height: 22px;
}

.country-chooser {
  .country-chooser__section {
    .field-menu {
      @media #{$small-only} {
        position: relative;
      }
    }
  }
}
