.colorbox--social-share {
  .social-share {
    > .title {
      text-align: center;
      + ul {
        margin-top: 20px;
      }
    }
    > ul {
      @include swap_direction(margin, 44px auto 20px);
      text-align: center;
      > li {
        display: inline-block;
        margin-#{$ldirection}: 27px;
      }
    }
  }
  .social-share__link {
    @include squish-text;
    display: inline-block;
    &.facebook {
      background-size: auto auto;
      background-position: 0 -9122px;
      height: 36px;
      width: 20px;
    }
    &.twitter {
      background-size: auto auto;
      background-position: 0 -9509px;
      height: 34px;
      width: 42px;
    }
    &.pinterest {
      background-size: auto auto;
      background-position: 0 -9316px;
      height: 36px;
      width: 37px;
    }
  }
}

.ie-8 {
  .colorbox--social-share {
    overflow: visible;
    #cboxContent {
      overflow: visible !important;
      #cboxLoadedContent {
        overflow: visible !important;
      }
    }
  }
}
