///
/// @file us_pc.scss
///
/// \brief Pull all parts of the US-PC theme together into one file
///
@import 'overrides/overrides';

@import 'sections/variable';
@import '../../estee_base/scss/estee-base';
@import '../../estee_base/scss/components/header/_page_header_alt';
@import '../../estee_base/scss/components/header/page_header_estee_edit_v2';
@import '../../estee_base/scss/drupal_modules/esteelauder_endeca/pc_block_search';
@import '../../estee_base/scss/drupal_modules/esteelauder_endeca/pc_page_search';

// SMACSS/components
@import '../../estee_base/scss/components/pc_slick_carousel';
@import '../../estee_base/scss/components/chat/pc_livechat';
// SMACSS/drupal_modules/esteelauder_homepage
@import '../../estee_base/scss/drupal_modules/esteelauder_homepage/home_formatter';
@import '../../estee_base/scss/drupal_modules/esteelauder_homepage/home_product';
@import '../../estee_base/scss/drupal_modules/esteelauder_homepage/home_basic';
@import '../../estee_base/scss/drupal_modules/esteelauder_homepage/home_up_next';
// SMACSS/drupal_modules/esteelauder_product
@import '../../estee_base/scss/drupal_modules/esteelauder_product/pc_spp';
@import '../../estee_base/scss/drupal_modules/esteelauder_product/pc_product_full';
@import '../../estee_base/scss/drupal_modules/esteelauder_product/pc_mpp';
@import '../../estee_base/scss/drupal_modules/esteelauder_product/pc_mpp_compare';
@import '../../estee_base/scss/drupal_modules/esteelauder_product/magic_smoky_header';
@import '../../estee_base/scss/drupal_modules/esteelauder_product/pc_product_brief';
@import '../../estee_base/scss/drupal_modules/esteelauder_product/pc_quickshop';
@import '../../estee_base/scss/drupal_modules/esteelauder_product/spp_howtouse_formatter_v1';
// SMACSS/drupal_modules/esteelauder_common
@import '../../estee_base/scss/drupal_modules/esteelauder_common/video_youtube';
// SMACSS/drupal_modules/esteelauder_custom_brand
@import '../../estee_base/scss/drupal_modules/esteelauder_custom_brand/aerin_tout_footer';
@import '../../estee_base/scss/drupal_modules/esteelauder_custom_brand/aerin_tout_header';
@import '../../estee_base/scss/sections/_gwp_pc';
@import 'sections/_samples_pc';
@import 'sections/_page_navigation';
@import 'sections/social_login';

// SMACSS/drupal_modules/esteelauder_foundation_finder
@import '../../estee_base/scss/drupal_modules/esteelauder_foundation_finder/foundation_finder_quiz';

// US specific for Loyalty Project
@import 'loyalty/loyalty_global';
@import '../../estee_base/scss/drupal_modules/esteelauder_global/my_feed_loyalty';
@import 'sections/loyalty/_loyalty-offers';

// Search result page
@import 'base/pc_page_search';

// common css
@import 'base/generic';

// Engraving US specific
@import '../../estee_base/scss/sections/engraving';

// US specific Foundation Finder Page
@import 'sections/_ff_quiz';

//One Trust Cookie Stylings
@import '../../estee_base/scss/sections/one_trust_cookie/cookie_settings_ot';

// Refer A Friend
@import 'sections/refer-friend';

//Account, Checkout Signin Signup update
@import '../../estee_base/scss/sections/account-checkout-signin';

// Hide QTY Picker
@import 'sections/us_sd_spp_pwp';

// Track My Order.
@import 'standalone/elc-order-tracking';

// Fixes Site Margin when Zooming
.page-wrapper,
.page-footer {
	overflow-x: hidden !important;
}
