/// @file drupal_modules/esteelauder_common/_multi_hero.scss
///
/// \brief Styling for template field module at:
///
/// esteelauder_common/templates/multi_hero_basic_v1
/// esteelauder_common/templates/multi_hero_product_v1

/// brand and custom classes variable classes specific to multi hero
// hero-block--aerin
// hero-block--renutriv
// hero-block--holiday
//
// $baskerville-italic is holiday font

$hero-aerin: '.hero-block--aerin';
$hero-renutriv: '.hero-block--renutriv';
$hero-holiday: '.hero-block--holiday';

.hero-block {
  width: 100%;
  max-width: 1500px;
  position: relative;
  clear: both;
  overflow: hidden;
  @include swap_direction(margin, -$header-collapsed-height auto 30px auto);
  .home-formatter & {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.hero-block__hero-image {
  width: 100%;
}

// headline styles
.hero-block__headline {
  top: 10%;
  position: absolute;
  z-index: 5;
  text-align: center;
  width: 100%;
  .promo-eyebrow-text {
    font-weight: bold;
    line-height: 12px;
    padding-bottom: 0.75em;
    #{$hero-holiday} & {
      padding-bottom: 1em;
    }
  }
  &.hero-block__headline--left {
    text-align: $ldirection;
    width: auto;
    .promo-eyebrow-text {
      padding-#{$ldirection}: 12px;
    }
  }
  &.hero-block__headline--right {
    text-align: $rdirection;
    width: auto;
  }
  .header__headline {
    line-height: 90px;
    font-size: 90px;
    @media #{$large-up} {
      font-size: 101px;
      line-height: 101px;
    }
    @media #{$xlarge-up} {
      font-size: 135px;
      line-height: 135px;
    }
    &.headline--medium {
      @media #{$large-up} {
        font-size: 75px;
        line-height: 75px;
      }
      @media #{$xlarge-up} {
        font-size: 101px;
        line-height: 101px;
      }
    }
    #{$hero-aerin} & {
      font-family: 'NarzissRegular';
      font-size: 70px;
      line-height: 70px;
      letter-spacing: normal;
      &.dark {
        color: $color-periwinkle-blue--aerin;
      }
    }
    #{$hero-renutriv} & {
      font-family: $bb-roman;
      letter-spacing: normal;
      font-size: 50px;
      line-height: 50px;
      @media #{$large-up} {
        font-size: 60px;
        line-height: 60px;
      }
    }
    #{$hero-holiday} & {
      font-family: $linotype-didot-roman;
      letter-spacing: normal;
      font-size: 50px;
      line-height: 50px;
      @media #{$medium-up} {
        font-size: 63px;
        line-height: 63px;
      }
      @media #{$large-up} {
        font-size: 76px;
        line-height: 76px;
      }
    }
  }
}
// promo copy styles
.hero-block__promo-copy {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .text-promo__subhead {
    font-weight: bold;
    line-height: 1.5em;
    @include swap_direction(margin, 0 0 3px 0);
    @media #{$large-up} {
      line-height: 1.8em;
    }
  }
  .text-promo__subcopy {
    line-height: 1.5em;
    @media #{$large-up} {
      line-height: 1.8em;
    }
  }
}
// promo layout
.hero-block__promo {
  position: absolute;
  z-index: 2;
  text-align: center;
  width: 100%;
  .hero-block__promo-copy {
    z-index: 3;
  }
  &.hero-block__promo--left {
    text-align: $ldirection;
    width: auto;
    padding-#{$ldirection}: 12px;
  }
  &.hero-block__promo--right {
    text-align: $rdirection;
    width: auto;
  }
  img {
    width: 100%;
  }
  .cta-button-wrapper {
    .cta__button {
      &.button--plus-sign {
        @include button-plus-with-text;
      }
    }
  }
  .text-promo__content {
    #{$hero-aerin} & {
      text-transform: none;
      font: 15px/24px 'FuturaStd-Light';
      letter-spacing: 0.05em;
      &.dark {
        color: $color-periwinkle-blue--aerin;
      }
    }
    #{$hero-renutriv} & {
      font-family: $roboto-condensed;
      text-transform: none;
      font-size: 16px;
    }
  }
}

.hero-block__boutique,
.hero-block__cta {
  margin-top: 10px;
  @media #{$large-up} {
    margin-top: 20px;
  }
}

#{$hero-aerin} {
  .cta__button,
  &.button-dark {
    background: $color-periwinkle-blue--aerin;
    color: $color-white;
    font-family: 'Futura Now Medium';
    &.button--light {
      color: $color-periwinkle-blue--aerin;
      background: $color-white;
    }
  }
  .hero-block__boutique {
    .hero-block__boutique-link {
      background-size: auto auto;
      background-position: -7px -3042px;
    }
  }
  .hero-block__container {
    margin-top: 35px;
  }
}
// holiday custom CTA color
#{$hero-holiday} {
  .cta__button {
    &.button--cta-custom {
      background: #94001a;
      color: $color-white;
    }
  }
}

// c and d product promo styles
.hero-block__promo-inner--c {
  text-align: center;
  width: 210px;
  @media #{$large-up} {
    width: 310px;
  }
  .hero-block__promo-copy {
    margin-top: -85px;
  }
}

.hero-block__promo-inner--d {
  width: 146px;
  @media #{$large-up} {
    width: 246px;
    margin-top: -20px;
  }
}

.hero-block__promo-image--d {
  margin-bottom: 10px;
  @media #{$large-up} {
    margin-bottom: 20px;
  }
}

// expander button positioning
// can be overridden with inline styles
.hero-block__exp-button {
  bottom: 0;
  position: absolute;
  z-index: 5;
  text-align: center;
}

// styles for the buttons
.button-expand {
  @include button-plus;
  &.open {
    background-size: auto auto;
    background-position: -7px -2964px;
    height: 53px;
    width: 53px;
    &.light,
    &.inverted {
      background-size: auto auto;
      background-position: 0 -2801px;
      height: 53px;
      width: 53px;
    }
  }
  &.light {
    background-size: auto auto;
    background-position: 0 -3113px;
    height: 53px;
    width: 53px;
    background-color: $color-black;
    @include swap_direction(border, 1px solid $color-white);
  }
  &.inverted {
    background-size: auto auto;
    background-position: 0 -3113px;
    height: 53px;
    width: 53px;
    background-color: transparent;
    @include swap_direction(border, 1px solid $color-white);
  }
}

.hero-block__boutique-link {
  @include button-plus;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  .hero-block--b &,
  .hero-block--d &,
  .hero-block--f &,
  .hero-block--h & {
    margin-#{$ldirection}: 0;
  }
}

.hero-block__boutique-link--label {
  @include text--short--plain;
  text-indent: 0;
  font-weight: bold;
  width: 150px;
  padding-#{$ldirection}: 60px;
  position: relative;
  display: inline-block;
  &:before {
    content: '';
    position: absolute;
    #{$ldirection}: 40px;
    height: 40px;
    border-#{$rdirection}: 1px solid $color-navy;
  }
}

.custom-link-wrapper {
  margin-top: 10px;
  @media #{$large-up} {
    margin-top: 15px;
  }
  .hero-block__custom-link {
    font-weight: bold;
    font-size: 12px;
    text-decoration: underline;
    letter-spacing: 0.15em;
    text-transform: none;
    &.link-color-light {
      color: $color-white;
    }
    &.link-color-dark {
      color: $color-navy;
    }
  }
}

//special classes for multi-use hero positioning
.margin-bottom-150 {
  margin-bottom: 150px;
}

.margin-top-150 {
  margin-top: 150px;
}

.header__headline {
  &.headline--small,
  &.headline--small2,
  &.headline--small3 {
    @media #{$xxlarge-up} {
      font-size: 75px; /* general fallback */
      font-size: 8vm; /* IE9 fallback */
      font-size: 8vmin;
      line-height: 66px; /* general fallback */
      line-height: 7vm; /* IE9 fallback */
      line-height: 7vmin;
    }
    @media #{$xlarge-only} {
      font-size: 66px; /* general fallback */
      font-size: 7vm; /* IE9 fallback */
      font-size: 7vmin;
      line-height: 56px; /* general fallback */
      line-height: 6vm; /* IE9 fallback */
      line-height: 6vmin;
    }
    @media #{$large-only} {
      font-size: 51px; /* general fallback */
      font-size: 6vm; /* IE9 fallback */
      font-size: 6vmin;
      line-height: 43px; /* general fallback */
      line-height: 5vm; /* IE9 fallback */
      line-height: 5vmin;
    }
    @media #{$medium-only} {
      font-size: 51px; /* general fallback */
      font-size: 6vm; /* IE9 fallback */
      font-size: 6vmin;
      line-height: 43px; /* general fallback */
      line-height: 5vm; /* IE9 fallback */
      line-height: 5vmin;
    }
    @media #{$small-only} {
      font-size: 66px; /* general fallback */
      font-size: 7vm; /* IE9 fallback */
      font-size: 7vmin;
      line-height: 56px; /* general fallback */
      line-height: 7vm; /* IE9 fallback */
      line-height: 7vmin;
      font-family: $optimalight;
      letter-spacing: -0.02em;
    }
  }
  &.headline--small {
    margin-top: 10%;
  }
  &.headline--small2 {
    margin-top: 0%;
  }
  &.headline--small3 {
    margin-top: -5%;
  }
}

.product-hero-tout-wrapper {
  .header__headline {
    &.headline--small {
      margin-top: 5%;
    }
    &.headline--small2 {
      margin-top: 0%;
    }
    &.headline--small3 {
      margin-top: -5%;
    }
  }
}

.device-mobile {
  .header__headline {
    &.headline--small,
    &.headline--small2,
    &.headline--small3 {
      @media #{$small-only} {
        font-size: 33px;
        line-height: 30px;
        font-family: $optimalight;
        letter-spacing: -0.02em;
      }
    }
  }
  .product-hero-tout-wrapper {
    .header__headline {
      &.headline--small {
        margin-top: 5%;
      }
      &.headline--small2 {
        margin-top: 0%;
      }
      &.headline--small3 {
        margin-top: -5%;
      }
    }
  }
}
