@mixin button-base {
  @include inline-block;
  @include swap_direction(padding, 0 20px);
  text-transform: uppercase;
  text-decoration: none;
  @include swap_direction(border, none);
  text-align: center;
  @include swap_direction(border-radius, 0); // iPhone wants to round
  &:focus {
    outline: 0;
  }
  &.button--block {
    display: block;
  }
}

@mixin button {
  @include button-base;
  height: 40px;
  line-height: 40px;
  #{$estee_edit} {
    padding: 0 33px;
  }
}

@mixin button--dark {
  @include background($color-navy);
  @include link--bold;
  text-decoration: none; // override from .link--bold
  color: $color-white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// light
@mixin button--light {
  @include background($color-white);
  @include link--bold;
  color: $color-navy;
  text-decoration: none;
}

// light with navy border
@mixin button--light-navy-border {
  @include background($color-white);
  @include link--bold;
  color: $color-navy;
  text-decoration: none;
  @include swap_direction(border, 1px solid $color-navy);
}

// inverted
@mixin button--inverted {
  background: none;
  @include swap_direction(border, solid 1px $color-white);
  text-decoration: none;
}

@mixin button--inverted-dark {
  background: none;
  @include swap_direction(border, solid 1px $color-navy);
  text-decoration: none;
}

@mixin button--light-border {
  @include background($color-white);
  @include link--bold;
  color: $color-navy;
  @include swap_direction(border, solid 1px $color-light-gray);
  text-decoration: none;
}

// smaller variation used in multi heroes
@mixin button-plus {
  @include hide-text;
  background-color: $color-white;
  @include swap_direction(border, 1px solid $color-navy);
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  cursor: pointer;
}

@mixin button-plus-with-text {
  background-color: $color-white;
  @include swap_direction(border, 1px solid $color-navy);
  color: $color-navy;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  cursor: pointer;
  padding-#{$ldirection}: 60px;
  position: relative;
  &:before {
    border-#{$rdirection}: 1px solid $color-navy;
    content: '';
    height: 40px;
    #{$ldirection}: 40px;
    position: absolute;
  }
}

@mixin button--secondary {
  @include button-base;
  height: 33px;
  line-height: 33px;
  @include swap_direction(border, 1px solid $color-navy);
  #{$renutriv} & {
    border-color: $color-gold--renutriv;
  }
  #{$estee_edit} & {
    border-color: $color-bright-blue--estee_edit;
  }
  #{$aerin} & {
    @include swap_direction(border, none);
  }
}

// Form submit buttons:
@mixin form-submit {
  @include form-submit-button; // See _typography.scss
  @include inline-block;
  -webkit-appearance: none;
  @include swap_direction(border-radius, 0);
  @include swap_direction(padding, 0 30px);
  height: 44px;
  line-height: 44px;
  @include swap_direction(border, none);
  cursor: pointer;
  #{$alt} & {
    background: $color-navy;
    @include swap_direction(border, 1px solid $color-periwinkle-blue);
    color: $color-periwinkle-blue;
  }
  @media #{$medium-up} {
    height: 40px;
    line-height: 40px;
  }
}

@mixin form-submit--light {
  @include form-submit;
  @include swap_direction(border, solid 1px $color-navy);
  background: none;
  color: $color-navy;
}
