///
/// @file components/_carousel_responsive_nav_v1.scss
///
/// Header responsive carousel
/// styleguide pg 27
///

.page-navigation__supplemental {
  color: $color-periwinkle-blue;
  a {
    color: $color-periwinkle-blue;
  }
}

.responsive-carousel-nav__full {
  background-color: $color-medium-navy;
  @include swap_direction(padding, 32px 20px 28px 20px);
}
// 5 wide 865 / 953
// 4 wide 692 / 780
// 3 wide 519 / 607
// 2 wide 346 / 434
// 1 wide 173 / 261
// add 44 each side for arrows
//  $small-range: (0, 640px); // iPhone: $small-up, $small-only
//  $medium-range: (641px, 768px); // iPad portrait: $medium-up, $medium-only
//  $large-range: (769px, 1024px); // iPad landscape: $large-up, $large-only
//  $xlarge-range: (1025px, 1279px); // Desktop interim: $xlarge-up, $xlarge-only
//  $xxlarge-range: (1280px); // Everything above: $xxlarge-up

.responsive-carousel-nav__container {
  //  max-width: 100%;
  max-width: 173px + 44px + 44px;
  @media #{$medium-only} {
    max-width: 519px + 44px + 44px;
  }
  @media #{$large-only} {
    max-width: 692px + 44px + 44px;
  }
  @media #{$xlarge-up} {
    max-width: 865px + 44px + 44px;
  }
  @include swap_direction(margin, 0 auto);
}

.responsive-carousel-nav {
  // hide
  height: 0;
  overflow: hidden;
  .flexslider {
    @include swap_direction(margin, 0 44px 0 44px);
    &.js-flex-centered {
      @include swap_direction(margin, 0 0 0 0);
    }
  }
  .slide__content {
    width: 143px;
    text-align: center;
    @include swap_direction(margin, 0 15px 0 15px);
  }
  .slide__image {
    margin-bottom: 10px;
    display: block;
  }
  .flex-control-nav {
    bottom: -20px;
  }
  .flex-direction-nav a {
    top: 39%;
    opacity: 1;
  }
  .js-flex-centered .slides {
    width: 100% !important;
    text-align: center;
  }
  .js-flex-centered .slides li {
    display: inline-block !important;
    *display: inline !important;
    float: none !important;
    max-width: 173px !important;
  }
}

.responsive-carousel-nav--visible {
  @extend %inflated; // _state.scss
}
