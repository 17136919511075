.product_brief__image-container {
  @include transition(height $default-fade ease-in);
  display: block;
  position: relative;
  overflow: hidden;
  width: 226px;
  height: 311px;
  @include swap_direction(margin, 0 auto);
  @media #{$medium-up} {
    width: 100%;
  }
  @media #{$product-wide-up} {
    width: 300px;
    height: 412px;
  }
}

.product_brief__image {
  @media #{$product-normal-only} {
    @include transition(all 0.3s ease-in);
    display: block;
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    background-color: #eaebef;
    background-repeat: none;
    background-size: cover;
    background-position: 50% bottom;
    width: 226px;
    height: 311px;
  }
  @media #{$product-wide-up} {
    width: 300px;
    height: 412px;
  }
}

.product_brief__image-container.is-open {
  height: 206px;
  @media #{$product-wide-up} {
    height: 271px;
  }
  .product_brief__image {
    #{$ldirection}: -5px; //-11px
    width: 237px; //248px 5% + 10%
    height: 326px; //342px
    @media #{$product-wide-up} {
      #{$ldirection}: -8px;
      width: 315px;
      height: 434px;
    }
    @media #{$medium-up} {
      #{$ldirection}: 0;
      width: 100%;
      height: 311px;
    }
  }
}

.product_brief__description {
  position: relative;
}

.product_brief__panel {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: center;
  .review-snippet {
    flex-grow: 1;
    width: 100%;
  }
}

.product_brief__headers {
  padding-top: 15px;
}

.product_brief__header {
  @include headline--quinary;
  @include swap_direction(padding, 0 3px 0 3px);
  #{$aerin} & {
    padding-top: 30px;
    margin-bottom: 28px;
  }
  #{$renutriv} & {
    font-family: $bb-roman;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: lighter;
  }
  #{$estee_edit} & {
    padding-top: 13px;
    font-family: $ffmarkweb-medium;
    text-transform: uppercase;
    font-size: 14px;
    padding-top: 2px;
    margin-bottom: 0px;
  }
}

.product_brief__sub-header {
  @include headline--senary;
  margin-top: 2px;
  #{$renutriv} & {
    font-family: $roboto-condensed;
  }
  #{$estee_edit} & {
    font-family: $ffmarkweb;
    text-transform: uppercase;
    font-weight: lighter;
    font-size: 14px;
    margin: 0 0 6px;
  }
}

.product_brief__desc1 {
  font-size: 12px;
  text-transform: uppercase;
  #{$renutriv} & {
    // hiding for launch
    display: none;
  }
  #{$estee_edit} & {
    font-size: 11px;
    margin-bottom: 7px;
    min-height: 42px;
  }
}

.product_brief .product__inventory-status {
  margin-top: 10px;
  #{$estee_edit} & {
    min-height: 16px;
    @media #{$device-tablet} {
      @media #{$portrait} {
        min-height: 32px;
      }
    }
    @media #{$device-ipad_only} {
      @media #{$portrait} {
        min-height: 32px;
      }
    }
  }
}

.product_brief__price {
  @include text--bold;
  margin-top: 8px;
  #{$renutriv} & {
    @include renutriv-copy;
    font-size: 14px;
  }
  #{$estee_edit} & {
    @include estee_edit-copy;
    font-size: 14px;
    margin: 0;
  }
  #{$aerin} .page-main & {
    font-family: FuturaStd-Heavy;
  }
}

.product_brief__misc-flag-references {
  display: none;
}

.product_brief__misc-flag {
  font-size: 12px;
  font-family: 'AkzidenzGrotesk W1G';
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-bottom: 3px;
  #{$renutriv} &, .brand-style-renutriv & {
    font-family: $roboto-condensed;
  }
  #{$aerin} .page-main & {
    font-family: FuturaStd-Heavy;
    font-size: 12px;
  }
  #{$estee_edit} .page-main & {
    font-family: $ffmarkweb;
    font-size: 11px;
    letter-spacing: 0;
    margin-top: 8px;
  }
}

.product__inventory-status {
  li {
    font-size: 12px;
    line-height: normal;
    vertical-align: text-bottom;
    color: $color-red;
    display: none;
    text-transform: uppercase;
    white-space: normal;
    .pre-order-container & {
      display: none !important; // to override JS inline style
    }
  }
}

.pre-order {
  &-message-display {
    float: left;
    margin: 10px 0;
    display: inline-block;
  }
  &_status_display {
    margin: 10px 0;
    font-size: 15px;
    .pre-order_msg {
      text-transform: none !important; // Overriding Boutique CSS
    }
  }
  &-container {
    .product_brief__misc-flag-sku {
      display: none;
    }
  }
}

#vb_container {
  .extended-mpp {
    .mpp__product {
      .product_brief__buttons--non-shaded {
        .product_brief__button--benefits {
          @include swap_direction(border, solid 1px $color-white);
        }
      }
      .product_brief__button {
        &--benefits,
        &--add-to-bag {
          line-height: 40px;
          height: 40px;
          font-size: 12px;
          padding: 0;
        }
      }
      .product_brief__buttons-container {
        bottom: 0px;
        height: 65px;
      }
      .product_brief__price {
        margin-top: 6px;
      }
    }
  }
}

.product_brief__subline {
}

.product_brief__reviews {
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  margin-top: 5px;
  #{$aerin} & {
    margin-bottom: 5px;
  }
  #{$estee_edit} & {
    margin-bottom: 5px;
  }
}

.product_brief__desc1-reviews {
  #{$estee_edit} & {
    min-height: 65px;
  }
}

.product_brief__reviews-count {
  padding-#{$ldirection}: 6px;
}

.product_brief__sub-panel-close {
  background-size: auto auto;
  background-position: 0 -6599px;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 7px;
  #{$rdirection}: 0;
  cursor: pointer;
  #{$renutriv} & {
    background-image: image-url('../themes/estee_base/img/sprites/search_close_x_white.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
  }
}

.product_brief__label2 {
  @include text--bold;
  margin-top: 20px;
}

.product_brief__desc2 {
  margin-top: 4px;
}

.product_brief__buttons-container {
  bottom: 20px;
  width: 100%;
  position: absolute;
  height: 75px;
  padding-top: 15px;
  @media #{$medium-only} {
    .custom-mpp-estee-edit-tem-wrapper & {
      bottom: 0px;
    }
  }
  @media #{$large-only} {
    .custom-mpp-estee-edit-tem-wrapper & {
      bottom: 0px;
    }
  }
  .product_brief__desc1-reviews {
    height: 75px;
  }
  .mpp__product__feature & {
    bottom: -60px;
  }
  .product_brief__sub-panel {
    @include pie-clearfix;
    .product_brief__price-size-select {
      width: 202px;
    }
    .product-brief__skintype-select {
      width: 202px;
    }
  }
  .product_brief__reviews-spacer {
    padding-top: 10px;
  }
}

//Estee Edit MPP custom grid video btns
.product_brief__quickshop__video {
  width: 170px;
  margin: 0 auto;
  .video-play {
    display: block;
    width: 33px;
    height: 33px;
    border: 1px solid;
    border-color: $color-bright-blue--estee_edit;
    float: right;
    background: url('/media/export/cms/dev/estee_edit_video_btn.jpg');
    margin: 0 0 0 8px;
  }
}

#product_brief__quickshop__container {
  clear: both;
  position: relative;
  top: -50px;
  z-index: 10;
  width: 100%;
  .product_brief__quickshop__content {
  }
  .product_brief__quickshop__close {
    background-size: auto auto;
    background-position: 0 -921px;
    height: 27px;
    width: 27px;
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 11;
    border: none;
    -webkit-appearance: none;
    #{$estee_edit} & {
      cursor: pointer;
    }
  }
  .quickshop {
    width: 100%;
  }
}

.product-brief__view-details {
  #{$aerin} .page-main & {
    font-family: 'Futura Now Medium';
  }
}

.product_brief__buttons {
  @include pie-clearfix;
}
%product_brief__button,
.product_brief__button {
  display: block;
  float: $ldirection;
  @include swap_direction(padding, 0);
}

.product-brief__button-quickshop {
  #{$aerin} & {
    margin-bottom: 17px;
  }
  #{$estee_edit} & {
    color: $color-white;
    background-color: $color-bright-blue--estee_edit;
    font-family: $ffmarkweb;
    letter-spacing: 0;
  }
}

.product_brief .button--secondary {
  font-weight: bold;
  text-decoration: none;
  #{$renutriv} & {
    color: $color-gold--renutriv;
    font-family: $roboto-condensed;
  }
}

.product_brief__buttons--non-shaded {
  margin-top: 16px;
  @media #{$product-wide-up} {
    margin: 0 auto;
    width: 174px;
    padding-top: 16px;
  }
  .product_brief__button--shop-now {
    @extend %product_brief__button;
    margin-#{$ldirection}: 26px;
    width: 133px;
    @media #{$product-wide-up} {
      margin-#{$ldirection}: 0;
    }
    #{$renutriv} & {
      color: $color-gold--renutriv;
      text-transform: uppercase;
      font-family: $roboto-condensed;
      // we hide benefits button so center this
      @include swap_direction(margin, 0 auto);
      float: none;
    }
  }
  .product_brief__button--benefits {
    @extend %product_brief__button;
    background-size: auto auto;
    background-position: 0 -400px;
    width: 34px;
    margin-#{$ldirection}: 7px;
    @include swap_direction(border, 1px solid $color-light-gray);
    #{$renutriv} & {
      // we hide benefits button for renutriv
      display: none;
    }
  }
}

.product_brief__buttons--shaded {
  width: 173px;
  padding-top: 16px;
  @include swap_direction(margin, 16px 0 0 26px);
  @media #{$product-wide-up} {
    margin: 0 auto;
  }
  .product_brief__button--shop-now {
    @extend %product_brief__button;
    width: 132px;
    #{$renutriv} & {
      color: $color-gold--renutriv;
      text-transform: uppercase;
      font-family: $roboto-condensed;
      // we hide benefits button so center this
      @include swap_direction(margin, 0 auto);
      float: none;
    }
  }
  .button-color-picker {
    display: block;
    float: $ldirection;
    margin-#{$ldirection}: 7px;
    position: relative;
    #{$renutriv} & {
      // we hide benefits button for renutriv
      display: none;
    }
    div.tooltip {
      background-color: #fff;
      position: absolute;
      #{$rdirection}: 0;
      top: -40px;
      z-index: 1000000;
      width: 150px;
      @include swap_direction(border, 1px solid $color-navy);
    }
    div.tooltip p {
      @include swap_direction(margin, 5px);
      color: #040a2b;
      font-family: 'AkzidenzGrotesk W1G';
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      font-weight: bold;
    }
  }
}

.product_brief__quickshop__video .product_brief__buttons--shaded {
  width: 215px;
}

.product_brief__sub-panel,
.product_brief__sub-panel--shaded,
.product_brief__sub-panel--non-shaded {
  display: none;
}

.product_brief__swatch-list {
  width: 226px;
  overflow: hidden;
  @media #{$product-wide-up} {
    @include swap_direction(margin, 0 auto);
  }
}

.product_brief__sku-price {
  margin-top: 9px;
  li {
    font-size: 12px;
    display: none;
    &:first-child {
      display: block;
    }
  }
}

.product_brief__sub-panel-buttons-container {
  margin-top: 10px;
  @media #{$product-wide-up} {
    @include swap_direction(margin, 10px auto 0 auto);
    width: 202px;
  }
  #{$ie8} & {
    width: auto;
    display: inline-block;
  }
}

.product_brief__quantity-container {
  float: $ldirection;
  margin-#{$ldirection}: 21px;
  @media #{$product-wide-up} {
    margin-#{$ldirection}: 0;
  }
}

.product_brief__button--add-to-bag {
  float: $ldirection;
  margin-#{$ldirection}: 6px;
  width: 132px;
  @include swap_direction(padding, 0);
  text-align: center;
}

.product_brief__full-details {
  clear: both;
  display: block;
  padding-top: 16px;
  #{$link} {
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-size: 12px;
  }
}

// ipad / android tablets portrait
.product_brief__desc1 {
  height: 30px;
  padding-bottom: 17px;
  width: 100%;
  align-self: flex-start;
  #{$estee_edit} & {
    @media #{$device-tablet} {
      min-height: 56px;
    }
    @media #{$device-ipad_only} {
      min-height: 56px;
    }
  }
}

.product_brief__description {
  #{$estee_edit} & {
    @media #{$device-tablet} {
      min-height: 162px;
    }
    @media #{$device-ipad_only} {
      min-height: 162px;
    }
  }
}

.product_brief__desc1-reviews {
  #{$estee_edit} & {
    @media #{$device-tablet} {
      min-height: 80px;
    }
    @media #{$device-ipad_only} {
      min-height: 80px;
    }
  }
}

//custom selects
.product_brief__quantity {
  width: 64px;
  &.selectBox-dropdown > .selectBox-arrow {
    @include arrow($color: map-get($select-settings, arrow-color), $width: 5px, $height: 5px, $direction: down);

    #{$renutriv} & {
      @include arrow(
        $color: map-get($select-settings--renutriv, arrow-color),
        $width: 5px,
        $height: 5px,
        $direction: down
      );
    }

    #{$aerin} & {
      @include arrow(
        $color: map-get($select-settings--aerin, arrow-color),
        $width: 5px,
        $height: 5px,
        $direction: down
      );
    }
  }
  &.selectBox-menuShowing-top > .selectBox-arrow {
    .no-csstransforms & {
      @include arrow($color: map-get($select-settings, arrow-color), $width: 5px, $height: 5px, $direction: up);
    }
    .no-csstransforms #{$aerin} & {
      @include arrow($color: map-get($select-settings--aerin, arrow-color), $width: 5px, $height: 5px, $direction: up);
    }
    .no-csstransforms #{$renutriv} & {
      @include arrow(
        $color: map-get($select-settings--renutriv, arrow-color),
        $width: 5px,
        $height: 5px,
        $direction: up
      );
    }
  }
}

.product-brief__skintype-select {
  width: 182px;
  margin-top: 10px;
}

.product-brief select option[disabled] {
  display: none;
}

.product_brief__price-size-select-selectBox-dropdown-menu {
  .selectBox-disabled {
    display: none;
  }
}

.product_brief__price-size-select {
  width: 182px;
}

.product_brief__swatch-select-container {
  display: none;
  @include swap_direction(margin, 16px 0 8px 0);
  &.is_intensity,
  &.is_color-family {
    display: block;
  }
  @media #{$product-wide-up} {
    @include swap_direction(margin, 16px auto 8px auto);
    max-width: 226px;
  }
}

.product_brief__swatch-select {
  width: 100%;
}

.product_brief__swatch-select.selectBox-dropdown {
  @include swap_direction(border, solid 1px $color-medium-gray-blue);
  line-height: 31px;
  height: 33px;
  #{$aerin} & {
    border-color: map-get($select-settings--aerin, border-color);
  }
  #{$renutriv} & {
    border-color: map-get($select-settings--renutriv, border-color);
  }
  .selectBox-label {
    width: auto;
    @include swap_direction(padding, 0 0 0 10px);
  }
}

// color family selects// color family dimensions
.product_brief__shadename {
  margin-top: 5px;
}

.filtered-swatch {
  width: 16px;
  height: 16px;
  @include swap_direction(border-radius, 8px);
  display: block;
  float: $ldirection;
  @include swap_direction(margin, 7px 10px 0 10px);
}

// COLOR FAMILY
// coral to red
.filter-coral_to_red {
  background: #ee2a25;
  border-#{$rdirection}: 8px solid #ae1123;
}
// gold to green
.filter-gold_to_green {
  background: #efc8a7;
  border-#{$rdirection}: 8px solid #6e885c;
}
// mauve to blue
.filter-mauve_to_blue {
  background: #805666;
  border-#{$rdirection}: 8px solid #374b69;
}
// mauve to plum
.filter-mauve_to_plum {
  background: #93004c;
  border-#{$rdirection}: 8px solid #a23d5b;
}
// nude to brown
.filter-nude_to_brown {
  background: #b0625e;
  border-#{$rdirection}: 8px solid #70342f;
}
// pink to berry
.filter-pink_to_berry {
  background: #f72f5e;
  border-#{$rdirection}: 8px solid #b3223d;
}

// INTENSITY
.filter-light {
  background: #fdd6b1;
  border-#{$rdirection}: 8px solid #e9d0ac;
}

.filter-light-medium {
  background: #ddb491;
  border-#{$rdirection}: 8px solid #edbe98;
}

.filter-medium {
  background: #e5ad8a;
  border-#{$rdirection}: 8px solid #d59a71;
}

.filter-medium-deep {
  background: #b56e38;
  border-#{$rdirection}: 8px solid #aa6533;
}

.filter-deep {
  background: #aa6533;
  border-#{$rdirection}: 8px solid #753c2b;
}

.filter-extra-deep {
  background: #753d1c;
  border-#{$rdirection}: 8px solid #753d1c;
}

.filter-ultra-deep {
  background: #623b22;
  border-#{$rdirection}: 8px solid #623b22;
}

.mpp-2-column {
  .mpp__product-grid {
    margin-#{$ldirection}: 7%;
    margin-#{$rdirection}: 7%;
    .mpp__product {
      width: 50%;
      .product_brief__image-container {
        @include transition(height $default-fade ease-in);
        display: block;
        position: relative;
        overflow: hidden;
        width: 271px;
        height: 373px;
        @include swap_direction(margin, 0 auto);
        @media #{$product-wide-up} {
          width: 370px;
          height: 508px;
        }
      }
      .product_brief__image {
        background-size: cover;
        @media #{$product-normal-only} {
          @include transition(all 0.3s ease-in);
          display: block;
          position: absolute;
          top: 0;
          #{$ldirection}: 0;
          background-color: #eaebef;
          background-repeat: none;
          background-size: cover;
          background-position: 50% bottom;
          width: 271px;
          height: 373px;
        }
        @media #{$product-wide-up} {
          width: 370px;
          height: 508px;
        }
      }
      .product_brief__image-container.is-open {
        height: 206px;
        @media #{$product-wide-up} {
          height: 271px;
        }
        .product_brief__image {
          #{$ldirection}: -5px; //-11px
          width: 237px; //248px 5% + 10%
          height: 326px; //342px
          @media #{$product-wide-up} {
            #{$ldirection}: -8px;
            width: 315px;
            height: 434px;
          }
          @media #{$medium-up} {
            #{$ldirection}: 0;
            width: 100%;
            height: 311px;
          }
        }
      }
    }
  }
}

//Estee Edit MPP Custom template with one feature product
.custom-mpp-estee-edit-tem-wrapper {
  #{$estee_edit} & {
    .mpp__product-grid {
      .single__product {
        .product_brief__desc1 {
          @media #{$medium-only} {
            padding-left: 70px;
            padding-right: 70px;
          }
        }
        .product_brief__headers__container {
          @media #{$medium-only} {
            padding-left: 70px;
            padding-right: 70px;
          }
        }
        .product_brief__image-container {
          margin-left: 50px;
          @media #{$medium-up} {
            margin-left: 85px;
          }
          @media #{$xxlarge-up} {
            margin-left: 50px;
          }
        }
      }
    }
    .mpp__product-grid {
      .product_brief__desc1 {
        @media #{$medium-only} {
          padding-left: 0;
          padding-right: 0;
        }
        @media #{$large-only} {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media #{$xxlarge-up} {
          padding-left: 30px;
          padding-right: 30px;
        }
        // ipad / tablets adjustment
        @media #{$device-tablet} {
          @media #{$portrait} {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
        @media #{$device-ipad_only} {
          @media #{$portrait} {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
// MPP Preorder Message (Normal & Custom)
.mpp {
  .mpp__product {
    .product_brief__sub-panel-buttons-container {
      .pre-order_status_display {
        font-size: 12px;
        display: inline-block;
      }
    }
  }
  .basic-reorder__sold-out {
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
  }
  .product___button--notify-me {
    @include swap_direction(margin, 0 auto);
  }
}

// Style for img tag - accessibility_enable global variable
.product-brief__image--accessibility-enabled {
  width: 100%;
  height: auto;
}
