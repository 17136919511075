$select-settings: (
  background-color: $color-white,
  border-color: $color-medium-gray-blue,
  border-color--focus: $color-navy,
  color: $color-navy,
  arrow-color: $color-navy,
  arrow-width: 7px,
  arrow-height: 8px,
  arrow-offset: 7px
) !global;
$select-settings--aerin: (
  background-color: $color-white,
  border-color: $color-gray--aerin,
  border-color--focus: $color-periwinkle-blue--aerin,
  color: $color-black,
  arrow-color: $color-periwinkle-blue--aerin
) !global;
$select-settings--renutriv: (
  background-color: $color-black,
  border-color: $color-gray--renutriv,
  border-color--focus: $color-white,
  color: $color-white,
  arrow-color: $color-white
) !global;
$select-settings--estee-edit: (
  background-color: $color-white,
  border-color: $color-border--estee_edit,
  border-color--focus: $color-navy,
  color: $color-navy,
  arrow-color: $color-navy
) !global;
$select-settings--compare: (
  background-color: $color-navy,
  border-color: $color-darker-gray,
  border-color--focus: $color-white,
  color: $color-white,
  arrow-color: $color-white
) !global;

// Must be on the same element as `.selectBox`
// Will get styling for Product Pages
$product-selects: '.product-full__shade-select', '.product-full__price-size-select',
  '.spp-product__mini-bag-shade-select', '.spp-product__mini-bag-quantity', '.spp-product__mini-price-size-select',
  '.product-full__skintype-select', '.product-full__quantity', '.search-product__shade-select',
  '.search-product__quantity', '.search-product__size-select', '.search-product__skintype-select',
  '.product-quantity__dropdown';

// default styling for all <select> form elements
.select-markdown,
select, // no-JS state & mobile state: we want native-UI to take over there
a.selectBox, // the element that replaces <select> that we mainly work with
a.selectbox {
  // yes, apparently the differences in the capital 'B' make a difference; it's a @todo to fix that, but better safe than sorry in case something comes in from perlgem. the correct selector is `.selectBox`.
  @include text--short;
  @include border-radius(0);
  background-color: map-get($select-settings, background-color);
  border-color: map-get($select-settings, border-color);
  color: map-get($select-settings, color);
  border-width: 1px;
  border-style: solid;
  min-width: 44px;
  line-height: 43px;
  height: 45px;
  padding-#{$rdirection}: 25px;
  padding-top: 0;
  padding-bottom: 0;
  padding-#{$ldirection}: 0;
  text-transform: uppercase;
  box-shadow: none;
  text-decoration: none;
  text-align: $ldirection;
  position: relative;
  outline: 0;
  z-index: 2;
  vertical-align: middle;
  display: inline-block;
  cursor: default;
  overflow: hidden;
  @media #{$medium-up} {
    line-height: 33px;
    height: 33px;
  }

  #{$ie8} & {
    line-height: 33px;
    height: 33px;
  }

  // State: Renutriv Brand
  #{$renutriv} & {
    background-color: map-get($select-settings--renutriv, background-color);
    border-color: map-get($select-settings--renutriv, border-color);
    color: map-get($select-settings--renutriv, color);
    font: 14px/33px $helvetica-neue;
  }

  // State: Aerin Brand
  #{$aerin} & {
    background-color: map-get($select-settings--aerin, background-color);
    border-color: map-get($select-settings--aerin, border-color);
    color: map-get($select-settings--aerin, color);
    font-family: 'Futura Now Medium';
  }

  #{$estee_edit} & {
    background-color: map-get($select-settings--estee-edit, background-color);
    border-color: map-get($select-settings--estee-edit, border-color);
    color: map-get($select-settings--estee-edit, color);
    font-family: $ffmarkweb-medium;
  }

  // State: MPP Compare
  #{$compare} & {
    background-color: map-get($select-settings--compare, background-color);
    border-color: map-get($select-settings--compare, border-color);
    color: map-get($select-settings--compare, color);
    //font: 14px/33px $helvetica-neue;
  }

  // State: Focus
  &:focus,
  &.selectBox-menuShowing {
    outline: 0;
    border-color: map-get($select-settings, border-color--focus);

    #{$renutriv} & {
      border-color: map-get($select-settings--renutriv, border-color--focus);
    }

    #{$aerin} & {
      border-color: map-get($select-settings--aerin, border-color--focus);
    }

    #{$estee_edit} & {
      border-color: map-get($select-settings--estee-edit, border-color--focus);
    }

    #{$compare} & {
      border-color: map-get($select-settings--compare, border-color--focus);
    }
  }

  // State: Error
  &.error {
    border-color: $color-red !important;
  }

  // Disabled state
  @at-root a.selectBox-disabled,
    select:disabled {
    color: #888 !important;
    .selectBox-arrow {
      @include opacity(0.5);
    }
    .selectBox-options a {
      background-color: transparent !important;
    }
  }

  // State: Inline
  @at-root a.selectBox-inline {
    min-width: 150px;
    outline: none;
    @include swap_direction(border, solid 1px $color-medium-gray-blue);
    background: #fff;
    display: inline-block;
    overflow: auto;
  }

  // Specific Page/State Overrides
  @each $selector in $product-selects {
    &#{$selector} {
      @extend %product-select-styles;
    }
  }
  @at-root %product-select-styles {
    border-color: $color-navy;
    line-height: 38px;
    height: 40px;
    white-space: nowrap;

    #{$aerin} & {
      border-color: map-get($select-settings--aerin, border-color);
    }
    #{$renutriv} & {
      border-color: map-get($select-settings--renutriv, border-color);
    }

    #{$estee_edit} & {
      border-color: map-get($select-settings--estee-edit, border-color);
    }

    #{$compare} & {
      border-color: map-get($select-settings--compare, border-color);
    }
  }
  #colorbox.colorbox__quickshop &,
  .product_brief__quickshop__content & {
    @extend %product-select-styles;
  }

  // State: Specific Overrides
  &.product-full__mini-bag-quantity,
  &.product-full__quantity,
  &.search-product__quantity {
    float: $ldirection;
    width: 100px;
  }
  &.product-full__quantity,
  &.quickshop__quantity {
    #{$estee_edit} & {
      width: 48%;
    }
  }
}

// non-JS & mobile "arrow" styling. have to stick to background images b/c we can't use `:after`
.select-markdown,
select {
  background-image: url('/media/images/background-images/background-image13.png');
  background-size: 23px 43px;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: right center;
  padding-#{$ldirection}: 12px;

  #{$renutriv} & {
    background-image: url('/media/images/background-images/background-image14.png');
  }
  #{$aerin} & {
    background-image: url('/media/images/background-images/background-image15.png');
  }
  #{$compare} & {
    background-image: url('/media/images/background-images/background-image14.png');
  }
}

// main arrow styles
.selectBox-arrow {
  display: block;
  position: absolute;
  #{$rdirection}: map-get($select-settings, arrow-offset);
  top: 50%;
  width: 0 !important; // to override any attempts to increase the `right` value using the old way
  @include arrow(
    $color: map-get($select-settings, arrow-color),
    $width: map-get($select-settings, arrow-width),
    $height: map-get($select-settings, arrow-height),
    $direction: down
  );
  @include single-transition(transform, 0.1s, ease-in);
  #colorbox.colorbox__quickshop & {
    #{$rdirection}: 15px;
  }

  #{$renutriv} & {
    @include arrow(
      $color: map-get($select-settings--renutriv, arrow-color),
      $width: map-get($select-settings, arrow-width),
      $height: map-get($select-settings, arrow-height),
      $direction: down
    );
  }
  #{$aerin} & {
    @include arrow(
      $color: map-get($select-settings--aerin, arrow-color),
      $width: map-get($select-settings, arrow-width),
      $height: map-get($select-settings, arrow-height),
      $direction: down
    );
  }
  #{$estee_edit} & {
    @include arrow(
      $color: map-get($select-settings--estee-edit, arrow-color),
      $width: map-get($select-settings, arrow-width),
      $height: map-get($select-settings, arrow-height),
      $direction: down
    );
  }
  #{$compare} & {
    @include arrow(
      $color: map-get($select-settings--compare, arrow-color),
      $width: map-get($select-settings, arrow-width),
      $height: map-get($select-settings, arrow-height),
      $direction: down
    );
  }

  //state override: when menu is open
  .selectBox-menuShowing-top > & {
    // default direction
    @include rotate(180deg);
    .no-csstransforms & {
      @include arrow(
        $color: map-get($select-settings, arrow-color),
        $width: map-get($select-settings, arrow-width),
        $height: map-get($select-settings, arrow-height),
        $direction: up
      );
    }
    .no-csstransforms #{$aerin} & {
      @include arrow(
        $color: map-get($select-settings--aerin, arrow-color),
        $width: map-get($select-settings, arrow-width),
        $height: map-get($select-settings, arrow-height),
        $direction: up
      );
    }
    .no-csstransforms #{$renutriv} & {
      @include arrow(
        $color: map-get($select-settings--renutriv, arrow-color),
        $width: map-get($select-settings, arrow-width),
        $height: map-get($select-settings, arrow-height),
        $direction: up
      );
    }
    .no-csstransforms #{$estee_edit} & {
      @include arrow(
        $color: map-get($select-settings--estee-edit, arrow-color),
        $width: map-get($select-settings, arrow-width),
        $height: map-get($select-settings, arrow-height),
        $direction: up
      );
    }
    .no-csstransforms #{$compare} & {
      @include arrow(
        $color: map-get($select-settings--renutriv, arrow-color),
        $width: map-get($select-settings, arrow-width),
        $height: map-get($select-settings, arrow-height),
        $direction: up
      );
    }
  }
  .selectBox-menuShowing-bottom & {
    // when there's not enough room above, menu goes down. let's not rotate the triangle.
  }
  @each $selector in $product-selects {
    #{$selector} & {
      @extend %product-arrow-styles;
    }
  }
  @at-root %product-arrow-styles,
    .page-product & {
    #{$rdirection}: (map-get($select-settings, arrow-offset) + 3);
  }
}

.selectBox-label {
  @include swap_direction(padding, 0 20px 0 10px);
  overflow: hidden;
  .page-product & {
    width: auto;
  }
  @each $selector in $product-selects {
    #{$selector} & {
      @extend %product-label-styles;
    }
  }
  @at-root %product-label-styles {
    width: auto;
    font-size: 12px;
    font-weight: bold;
  }
  #{$estee_edit} & {
    font-family: $ffmarkweb-medium;
    font-size: 12px;
    letter-spacing: 0.15em;
    font-weight: normal;
  }
  .search-product .shade-select.selectBox-dropdown &,
  .quickshop .shade-select.selectBox-dropdown &,
  body.page-product .shade-select.selectBox-dropdown & {
    padding-#{$ldirection}: 0; //by design, label flush with swatch. For custom shade-select only
  }
}
/* Dropdown menu */
.selectBox-dropdown-menu {
  position: absolute;
  z-index: 99999;
  max-height: 225px;
  min-height: 1em;
  border-style: solid;
  border-width: 1px;
  border-color: map-get($select-settings, border-color--focus);
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @media #{$medium-up} {
    max-height: 200px;
  }
  #{$renutriv} & {
    background: black;
    border-color: map-get($select-settings--renutriv, border-color--focus);
  }
  #{$aerin} & {
    border-color: map-get($select-settings--aerin, border-color--focus);
  }
  #{$estee_edit} & {
    border-color: map-get($select-settings--estee-edit, border-color--focus);
    // add z index so drop down does not overlap site nav
    z-index: 99;
  }
  #{$compare} & {
    background: $color-navy;
    border-color: map-get($select-settings--compare, border-color--focus);
  }
  @each $selector in $product-selects {
    @at-root #{$selector}-selectBox-dropdown-menu {
      @extend %product-dropdown-menu-styles;
    }
  }
  @at-root %product-dropdown-menu-styles {
    border-color: $color-navy;

    #{$aerin} & {
      border-color: map-get($select-settings--aerin, border-color);
    }
    #{$renutriv} & {
      border-color: map-get($select-settings--renutriv, border-color);
    }
    #{$estee_edit} & {
      border-color: map-get($select-settings--estee-edit, border-color);
    }
    #{$compare} & {
      border-color: map-get($select-settings--compare, border-color);
    }
  }
  .selectBox-disabled {
    display: none;
  }
}
/* Options */
.selectBox-options,
.selectBox-options li,
.selectBox-options li a {
  list-style: none;
  display: block;
  cursor: default;
  @include swap_direction(padding, 0);
  @include swap_direction(margin, 0);
}

.selectBox-options.selectBox-options-top {
  border-bottom: 0;
  margin-top: 1px;
}

.selectBox-options.selectBox-options-bottom {
  border-top: 0;
}

.selectBox-options {
  li {
    &.selectBox-hover a {
      background-color: $color-light-gray-blue;
      #{$renutriv} & {
        color: $color-black;
      }
      #{$compare} & {
        color: $color-black;
      }
    }
    &.selectBox-disabled a {
      color: #888;
      background-color: transparent;
    }
    &.selectBox-selected a {
      background-color: #c8def4;
      #{$renutriv} & {
        color: $color-black;
      }
      #{$compare} & {
        color: $color-black;
      }
    }
    a {
      @include text--long-copy;
      // text-transform: uppercase;
      font-size: 12px;
      @include swap_direction(padding, 3px 10px);
      white-space: nowrap;
      overflow: hidden;
      background: 6px center no-repeat;
      #{$renutriv} & {
        font: 14px/43px $helvetica-neue;
        letter-spacing: normal;
        color: $color-white;
      }
      #{$estee_edit} & {
        font-family: $ffmarkweb;
      }
      #{$compare} & {
        color: $color-white;
      }
      &:focus {
        outline: 0;
      }
    }
  }
  &.product-full__shade-select-selectBox-dropdown-menu,
  &.product-full__price-size-select-selectBox-dropdown-menu,
  &.spp-product__mini-bag-shade-select-selectBox-dropdown-menu,
  &.spp-product__mini-price-size-select-selectBox-dropdown-menu,
  &.search-product__shade-select-selectBox-dropdown-menu,
  &.search-product__size-select-selectBox-dropdown-menu,
  &.quickshop__shade-select-selectBox-dropdown-menu,
  body.page-product &.selectBox-dropdown-menu {
    li a {
      line-height: 43px;
      @media #{$medium-up} {
        line-height: 38px;
      }
    }
  }
  &.product-full__shade-select-selectBox-dropdown-menu,
  &.spp-product__mini-bag-shade-select-selectBox-dropdown-menu,
  &.search-product__shade-select-selectBox-dropdown-menu,
  &.quickshop__shade-select-selectBox-dropdown-menu {
    li a {
      @include swap_direction(padding, 0);
    }
  }
  &.product_brief__swatch-select-selectBox-dropdown-menu,
  &.product_brief__price-size-select-selectBox-dropdown-menu,
  &.product_brief__quantity-selectBox-dropdown-menu {
    li a {
      line-height: 31px;
      @include swap_direction(padding, 0);
      text-indent: 8px;
    }
  }
  .selectBox-optgroup {
    color: #666;
    background: #eee;
    font-weight: bold;
    line-height: 1.5;
    @include swap_direction(padding, 0 0.3em);
    white-space: nowrap;
  }
}
