// Column widths
$col1-width: 292px;
$col2-width: 426px;
$col3-width: 306px;
$cols-width: $col1-width + $col2-width + $col3-width;

$col1-width: percentage($col1-width / $cols-width);
$col2-width: percentage($col2-width / $cols-width);
$col3-width: percentage($col3-width / $cols-width);

.my-feed-tray {
  // Shared (both auth and anon)
  .my-feed-tray__inner {
    @include clearfix;
    .my-feed-tray__col {
      float: $ldirection;
    }
  }

  // Anonymous
  .my-feed-tray__anon {
    display: none;
    .elc-user-state-anonymous & {
      display: block;
    }
    .my-feed-summary {
      width: $col1-width;
      @include swap_direction(padding, 50px 20px 0 30px);
    }
    .my-feed-summary__header {
      @include headline--quinary--plain;
    }
    .my-feed-summary__copy {
      @include text--long-copy--plain;
      letter-spacing: -0.02em;
      @include swap_direction(margin, 11px 0 30px);
    }
    .special-offer {
      width: $col2-width;
    }
    .my-feed-summary__copy {
      @include headline--senary--plain;
      line-height: 24px;
    }
  }

  // Authenticated
  .my-feed-tray__auth {
    .elc-user-state-anonymous & {
      display: none;
    }
    .my-feed-summary {
      width: $col1-width;
      @include swap_direction(padding, 30px 23px 0 30px);
    }
    .special-offer {
      width: $col2-width;
    }
    .last-purchased {
      width: $col3-width;
      padding-#{$ldirection}: 23px;
      @include swap_direction(padding, 30px 30px 0 23px);
    }
  }
  .my-feed-tray__video-wrapper {
    width: $col3-width;
    @include swap_direction(padding, 30px 0 0 27px);
    text-align: center;
  }
  .my-feed-tray__video-container {
    position: relative;
    width: 226px;
    @include swap_direction(margin, 0 auto 20px);
    cursor: pointer;
    .my-feed-tray__video-play-icon {
      background-size: auto auto;
      background-position: 0 -7164px;
      height: 35px;
      width: 30px;
      @include hide-text;
      position: absolute;
      top: 65px;
      #{$ldirection}: 20px;
    }
  }
  .my-feed-tray__video {
    width: 226px;
    height: 120px;
  }
  .my-feed-tray__video-header {
    @include text--bold;
    letter-spacing: 0.15em;
    font-size: 12px;
  }
  .my-feed-tray__video-title {
    @include text--long-copy--plain;
  }
}

.my-feed-drawer {
  .item-count {
    @include border-radius(9px);
    line-height: 18px;
    display: inline-block;
    background-color: $color-white;
    color: $color-navy;
    @include swap_direction(margin, 0 0 0 7px);
    @include swap_direction(padding, 0 4px 0 5px);
  }
}
