// Media queries
$expand-carousel: '#{$screen} and (min-width: 900px)' !default;

.ee-disco-more {
  max-width: 90%;
  @include swap_direction(margin, 40px auto 0);
  text-align: center;
  clear: both;
  @media #{$expand-carousel} {
    @include swap_direction(margin, 40px auto);
  }
  .ee-disco-more__headline-main {
    font-size: 21px;
    margin-bottom: 40px;
    @media #{$expand-carousel} {
      font-size: 40px;
      #{$aerin} & {
        font-size: 30px;
      }
    }
  }
  .ee-disco-more__headline {
    font-size: 40px;
    margin-bottom: 20px;
    @media #{$expand-carousel} {
      font-size: 30px;
    }
    #{$aerin} & {
      font-size: 24px;
    }
  }
  [class*='--short'] {
    #{$aerin} & {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
  .slick-dots {
    bottom: -15px;
  }
  .slick-prev {
    @include hide-text;
    background-size: auto auto;
    background-position: 0 -8820px;
    height: 40px;
    width: 30px;
    @media #{$small-only} {
      #{$ldirection}: -15px;
    }
  }
  .slick-next {
    @include hide-text;
    background-size: auto auto;
    background-position: 0 -8600px;
    height: 40px;
    width: 30px;
    @media #{$small-only} {
      #{$rdirection}: -15px;
    }
  }
  .slick-disabled {
    opacity: 0;
  }
}

.ee-disco-more__item {
  display: block;
  width: 230px;
  @media #{$expand-carousel} {
    float: $ldirection;
    &:nth-of-type(3n + 1) {
      clear: $ldirection;
    }
  }
}

.ee-disco-more__image {
  margin-bottom: 20px;
  img {
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
  }
}
