$OptimaDisplayLight: 'OptimaDisplayLight';
$AkzidenzGroteskW1G: 'AkzidenzGrotesk W1G';
$power-max-width: 2372px;
$power-max-break: 1366px;
$power-min-width: 767px;

.power-night-ipad__in,
.power-night-ipad__out {
  max-width: $power-max-width;
  .power-night-ipad {
    &__container {
      position: relative;
      display: inline-block;
      .power-night-ipad__scroll-for-more,
      .power-night-ipad__scroll-top {
        position: absolute;
        #{$rdirection}: 3.5vw;
        bottom: 3.5vw;
        .power-night-ipad__scroll-for-more-text,
        .power-night-ipad__scroll-top-text {
          display: block;
          position: absolute;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          top: 30%;
          text-align: center;
          font-family: $AkzidenzGroteskW1G;
          color: $color-white;
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 16px;
          letter-spacing: 0.02em;
        }
      }
      .power-night-ipad__scroll-top {
        position: fixed;
        z-index: 1;
        display: none;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
      }
      .power-night-ipad__item {
        width: 50%;
        position: relative;
        max-width: 1186px;
        height: 100%;
        margin-bottom: 1.68vw;
        h1.power-night-ipad__item-title {
          font-size: 2.7vw;
        }
        h2.power-night-ipad__item-title {
          font-size: 6vw;
          margin-bottom: 1.2vw;
          letter-spacing: -0.002em;
          padding-#{$ldirection}: 1vw;
          &--review {
            font-size: 2.8vw;
            margin-#{$ldirection}: 1vw;
            text-align: #{$ldirection};
          }
        }
        .power-night-ipad__item-teaser {
          font-family: $AkzidenzGroteskW1G;
          font-size: 2.196vw;
          letter-spacing: 0.08em;
          @media screen and (min-width: $power-max-break) {
            font-size: 2vw;
            line-height: 2.5vw;
          }
        }
        &--left {
          float: #{$ldirection};
          padding-#{$rdirection}: 0.84vw;
          @media screen and (max-width: $power-min-width) {
            padding-#{$rdirection}: 0;
          }
        }
        &--right {
          float: #{$rdirection};
          padding-#{$ldirection}: 0.84vw;
          @media screen and (max-width: $power-min-width) {
            padding-#{$ldirection}: 0;
          }
        }
        &--half {
          width: 50%;
          float: #{$ldirection};
          position: relative;
          .power-night-ipad__item-overtext--middle {
            h2.power-night-ipad__item-title {
              font-size: 3.66vw;
              letter-spacing: -0.05em;
              margin-bottom: 0;
              @media screen and (min-width: $power-max-break) {
                font-size: 7vw;
              }
              @media screen and (max-width: $power-min-width) {
                font-size: 7.5vw;
                letter-spacing: 0em;
              }
            }
            .power-night-ipad__item-teaser {
              font-family: $OptimaDisplayLight;
              font-size: 2.196vw;
              letter-spacing: -0.03em;
              @media screen and (min-width: $power-max-break) {
                font-size: 4vw;
              }
              @media screen and (max-width: $power-min-width) {
                display: none;
              }
            }
          }
        }
        &--full {
          width: 100%;
          max-width: 2372px;
          float: #{$ldirection};
          .power-night-ipad__item-overtext {
            h1 {
              font-size: 4.53vw;
              letter-spacing: -0.075em;
              padding-bottom: 0.5vw;
              @media screen and (min-width: $power-max-break) {
                font-size: 5vw;
              }
              @media screen and (max-width: $power-min-width) {
                font-size: 7.81vw;
                letter-spacing: -0.05em;
              }
            }
          }
        }
        &-img {
          vertical-align: top;
        }
        &-overtext {
          @include swap_direction(margin, 0 auto);
          position: absolute;
          bottom: 4.1vw;
          text-align: center;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          &.white {
            color: $color-white;
          }
          &.black {
            color: $color-black;
          }
          &.navy {
            color: $color-navy;
          }
          &--review {
            bottom: 2vw;
            font-size: 3vw;
            text-align: #{$ldirection};
            @media screen and (max-width: $power-min-width) {
              padding-bottom: 5.5%;
            }
          }
          h1 {
            font-size: 2.92vw;
            letter-spacing: 0.03em;
            padding-bottom: 0.7vw;
            @media screen and (min-width: $power-max-break) {
              font-size: 5vw;
            }
            @media screen and (max-width: $power-min-width) {
              font-size: 7.81vw;
              letter-spacing: -0.05em;
            }
          }
          &--middle {
            margin: auto;
            top: 51.5%;
            bottom: auto;
            transform: translateY(-50%);
            margin-top: -1%;
            padding-#{$rdirection}: 0.84vw;
            .power-night-ipad__in-qs {
              opacity: 0;
              transition: all 0.25s ease-in-out;
              @media screen and (max-width: $power-min-width) {
                @include swap_direction(padding, 0 4.5vw);
                opacity: 1;
                height: 7vw;
                font-size: 2.4vw;
                line-height: 7vw;
              }
            }
          }
          @media screen and (max-width: $power-min-width) {
            font-size: 3.125vw;
          }
        }
        &-title {
          @include swap_direction(margin, 0 auto);
          text-align: center;
          letter-spacing: 0.005em;
          margin-bottom: 0.7vw;
          @media screen and (max-width: $power-min-width) {
            font-size: 7.81vw;
            margin-bottom: 2.8vw;
          }
          &--review {
            opacity: 0;
            transition: opacity 0.4s ease-in-out;
            &.active {
              opacity: 1;
            }
            &.DisplayOff {
              display: none;
            }
            p {
              text-align: #{$ldirection};
              padding-#{$ldirection}: 5.85%;
              padding-#{$rdirection}: 33%;
              @media screen and (max-width: $power-min-width) {
                padding-#{$rdirection}: 5.85%;
                font-size: 5.5vw;
                line-height: 5.8vw;
              }
            }
            p + p {
              font-size: 12px;
              letter-spacing: 0.2em;
              font-weight: 600;
              font-family: $AkzidenzGroteskW1G;
            }
          }
        }
        &-teaser {
          @media screen and (max-width: $power-min-width) {
            font-size: 3.125vw;
          }
        }
        &-link {
          border: none;
          margin-top: 2.7vw;
          font-size: 2vw;
          @media screen and (max-width: $power-min-width) {
            font-size: 2.5vw;
            line-height: 5.9375vw;
            margin-bottom: 3.8vw;
            font-family: $AkzidenzGrotesk;
          }
          a {
            text-decoration: none;
            color: inherit;
            span {
              border-bottom: 1.8px solid $color-white;
              @media screen and (max-width: $power-min-width) {
                border-bottom: 1px solid $color-white;
                font-size: 2.5vw;
              }
            }
          }
          a.button--light-border {
            color: $color-navy;
          }
        }
        &-undertext {
          padding-top: 0.658vw;
        }
        &-qs {
          @media screen and (max-width: $power-min-width) {
            .power-night-ipad__item-overtext {
              bottom: 1%;
              top: auto;
              &--review {
                bottom: 5.5%;
              }
              .product-brief {
                height: auto !important;
                float: none;
                width: 100%;
                padding-bottom: 0;
                list-style: none;
                a {
                  display: none;
                  &.js-quickshop-show,
                  &.js-quickshop-hide {
                    display: block;
                    line-height: 2.7;
                    #{$ldirection}: 50%;
                    width: 138px;
                    margin-#{$ldirection}: -69px;
                    height: 33px;
                    background-color: $color-white;
                    font-family: $akzidenz;
                    font-weight: bold;
                    text-indent: 0;
                    background-image: none;
                    bottom: 15px;
                  }
                }
                &__price {
                  display: none;
                }
                &__reviews {
                  display: none;
                }
              }
            }
          }
          .js-quick_shop {
            @media screen and (max-width: $power-min-width) {
              display: none;
            }
          }
        }
        &-qs:hover {
          .power-night-ipad__in-qs {
            opacity: 1;
          }
        }
        &-play {
          cursor: pointer;
          width: 6vw;
          max-width: 150px;
          @media screen and (max-width: $power-min-width) {
            width: 19vw;
            max-width: 137px;
          }
        }
        video {
          max-width: 1186px;
          width: 99.7%;
          overflow: hidden;
          display: inline-block;
          vertical-align: top;
          @media screen and (max-width: $power-min-width) {
            width: 100%;
            max-width: 100%;
          }
        }
      }
      .mpp__product-grid {
        .product-brief {
          display: none;
        }
        .product-brief__quickshop-container {
          .quickshop-inline-top {
            margin-#{$ldirection}: -10px;
            #{$ldirection}: 50%;
          }
          .quickshop-inline {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .moisturizer-repromote-quiz {
    .mr-intro__cta {
      background-color: $color-navy;
    }
    .mr-intro__copy-heading {
      @media screen and (min-width: 768px) {
        font-size: 40px;
      }
      @media screen and (max-width: 1365px) and (min-width: 1023px) {
        font-size: 3.05vw;
      }
      @media screen and (max-width: 1023px) and (min-width: 768px) {
        font-size: 32px;
      }
    }
    .mr-intro__copy {
      @media screen and (min-width: 768px) {
        top: 6vw;
      }
      @media screen and (max-width: 880px) and (min-width: 768px) {
        top: 2.2vw;
      }
    }
  }
}

.get-the-look-ipad {
  display: none;
  .get-the-look-ipad__header {
    @include swap_direction(margin, 0 auto);
    position: relative;
    width: 100%;
    max-width: 2372px;
    height: 6vw;
    overflow: hidden;
    z-index: 2;
    .get-the-look-ipad__header-backround {
      display: block;
      width: 100%;
    }
    .get-the-look-ipad__header-back {
      z-index: 2;
      position: absolute;
      #{$ldirection}: 0;
      top: 50%;
      transform: translateY(-50%);
      color: $color-white;
      white-space: nowrap;
    }
    .get-the-look-ipad__header-back-arrow {
      display: inline-block;
      padding-#{$ldirection}: 40px;
      height: 2.7vw;
      vertical-align: middle;
    }
    .get-the-look-ipad__header-back-text {
      display: inline-block;
      vertical-align: middle;
      font-size: 1.244vw;
    }
    .get-the-look-ipad__header-title {
      position: absolute;
      top: 50%;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      transform: translateY(-50%);
      line-height: 1;
      text-align: center;
      font-size: 2.7vw;
      color: $color-white;
    }
  }
  .ytPlayer.js-ytPlayer.playing {
    display: none;
    opacity: 0;
  }
  .get-the-look-ipad-panel {
    .lookmod__slide--bgimg {
      position: absolute;
      z-index: 0;
      width: 100%;
      overflow: hidden;
    }
    .lookmod__slide-imgcontainer {
      width: 56%;
      margin-top: -1vw;
      margin-#{$ldirection}: -8.5vw;
      margin-#{$rdirection}: 7%;
      display: inline-block;
      .lookmod__slide-img {
        width: 100%;
        transform: rotate(-9deg);
        border: 1.8vw solid $color-white;
      }
    }
    .lookmod__slide-play {
      position: absolute;
      top: 45vw;
      #{$ldirection}: 3vw;
      width: 6.5vw;
      z-index: 99;
    }
    .lookmod__slide-btm {
      padding-top: 2.5vw;
      font-size: 1.7vw;
      display: inline-block;
      width: 42%;
      color: $color-white;
      vertical-align: top;
      z-index: 99;
      position: relative;
      .lookmod__header-btminfo {
        font-size: 4.0264vw;
        font-family: $OptimaDisplayLight;
        letter-spacing: -0.02em;
      }
      .lookmod__subheader {
        margin-bottom: 25px;
      }
      .lookmod__slide-btminfo {
        padding: 0;
        margin-bottom: 20px;
      }
      .lookmod__slide-content {
        ol {
          margin-left: 28px;
          li {
            margin-bottom: 2.2vw;
          }
        }
      }
    }
  }
  .get-the-look-ipad-panel.get_the_look_goout {
    .lookmod__slide-imgcontainer {
      margin-top: -4vw;
      margin-#{$ldirection}: 2vw;
      width: 51.5%;
      margin-#{$rdirection}: 6.5vw;
      .lookmod__slide-img {
        transform: rotate(15deg);
      }
    }
    .lookmod__slide-btm {
      width: 38%;
      padding-top: 9vw;
      .lookmod__slide-content {
        font-family: $OptimaDisplayLight;
        font-size: 2.3vw;
        letter-spacing: 0.035em;
        line-height: 3vw;
      }
    }
  }
}

.power-night-ipad-products-v1 {
  @include swap_direction(margin, 0 40px);
  visibility: hidden;
  clear: both;
  margin-top: -50vw;
  opacity: 0;
  &.shown {
    visibility: visible;
    margin-top: 3vw;
    opacity: 1;
  }
  .tabbed-products-block {
    .grid--mpp__carousel .grid--mpp__items .grid--mpp__item {
      .product-brief {
        .down_price,
        .product-brief__shades {
          display: none;
        }
        .product-brief__description .product-brief__desc1-reviews {
          visibility: hidden;
        }
      }
      .product-brief__reviews {
        visibility: hidden;
      }
    }
  }
}

.power-night-ipad-product-popup-v1 {
  display: none;
  .power-night-ipad-products-v1__mask {
    position: fixed;
    top: 0;
    #{$ldirection}: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-black-opacic;
    z-index: 100;
  }
  .power-night-ipad-product-popup__content {
    position: fixed;
    width: 39vw;
    top: 15%;
    #{$ldirection}: 50%;
    transform: translateX(-50%);
    padding: 40px;
    background-color: $color-white;
    z-index: 101;
    font-size: 1.317vw;
    color: $color-navy;
    .power-night-ipad-product-popup__close {
      width: 1.93vw;
      #{$rdirection}: 1vw;
      top: 1vw;
      position: absolute;
      cursor: pointer;
    }
    .power-night-ipad-product-popup__title {
      font-size: 2.63vw;
      font-family: $OptimaDisplayLight;
      letter-spacing: -0.001em;
    }
    .power-night-ipad-product-popup__subtitle {
      font-size: 2.196vw;
      font-family: $OptimaDisplayLight;
      letter-spacing: -0.001em;
    }
  }
}

.power-night-ipad__screensaver {
  video {
    display: none;
    &.power-night-ipad__screensaver--fullscreen {
      display: inline-block;
      position: fixed;
      right: 0;
      top: 0;
      min-width: 100%;
      min-height: 100%;
      z-index: 100;
      background-color: $color-black;
    }
  }
}
