.spp-missing {
  color: #d385e1;
}

.page-product .page-wrapper {
}

.page-product .page-main {
  @include swap_direction(padding, 0);
}

.spp:not(.button) {
  @include move-up-into-nav;
  overflow: hidden;

  // Need to override for spp with carousel since we're already accounting for
  // header nav over there. see: _pc_product_full.scss
  .product-full {
    margin-top: $header-collapsed-height;
    @media #{$large-up} {
      margin-top: 0;
    }
  }
}

// anchor
.spp-product__anchor {
  display: none;
  @media #{$large-up} {
    display: block;
  }
  @include text-product-anchor;
  position: fixed;
  top: 290px;
  #{$ldirection}: 0;
  z-index: 10;
  width: 206px;
  &.is_expanded {
    .spp-product__anchor-nav {
      border-top: 1px solid $color-medium-gray;
      border-#{$rdirection}: 1px solid $color-medium-gray;
      border-bottom: 1px solid $color-medium-gray;
      background: $color-white;
      #{$renutriv} & {
        background: black;
      }
      li.spp-product__anchor-help {
        @include opacity(1);
      }
    }
    .spp-product__anchor-tab {
      @include opacity(1);
    }
  }
  &.is_closed {
    #{$ldirection}: -151px;
  }
  a:focus {
    outline: 0;
  }
}

.spp-product__anchor-tab {
  background-size: auto auto;
  background-position: 0 -9818px;
  @include opacity(0);
  position: absolute;
  top: 35%;
  #{$rdirection}: 0;
  width: 56px;
  height: 56px;
  z-index: 11;
  #{$aerin} & {
    background-size: auto auto;
    background-position: 0 -9737px;
  }
  #{$renutriv} & {
    background-size: auto auto;
    background-position: 0 -9656px;
    @include swap_direction(border, 1px solid $color-gray--renutriv);
  }
  .is_closed & {
    background-size: auto auto;
    background-position: 0 -10061px;
    #{$aerin} & {
      background-size: auto auto;
      background-position: 0 -9980px;
    }
    #{$renutriv} & {
      background-size: auto auto;
      background-position: 0 -9899px;
    }
  }
}

.spp-product__anchor-nav {
  width: 151px;
  padding-top: 30px;
  border-top: 1px solid transparent;
  border-#{$rdirection}: 1px solid transparent;
  border-bottom: 1px solid transparent;
  li {
    @include swap_direction(margin, 0 0 13px);
    @include swap_direction(padding, 0 5px 0 30px);
    &.spp-product__anchor-help {
      @include opacity(0);
      background: $color-light-gray-blue;
      @include swap_direction(padding, 20px 20px 20px 30px);
      @include swap_direction(margin, 0);
      #{$renutriv} & {
        background: lighten(black, 7);
      }
      a {
        text-decoration: underline;
        font-weight: bold;
        text-transform: uppercase;
        #{$renutriv} & {
          color: $color-gold--renutriv;
        }
      }
    }
  }
}

// spp mini bag
.spp-product__mini-bag {
  position: fixed;
  bottom: $sticky-footer-height;
  #{$rdirection}: 0;
  width: 258px;
  @include swap_direction(padding, 5px 9px 9px 11px);
  background: $color-white;
  @include swap_direction(border, 1px solid $color-medium-gray);
  z-index: -1;
  pointer-events: none;
  @include transition(visibility $back-to-top-fadein ease-in-out, $back-to-top-fadein ease-in-out);
  @include opacity(0);
  visibility: hidden;
  #{$renutriv} & {
    background: black;
  }
}

.spp-product__mini-bag-show,
body.page-product .spp-product__mini-bag-quantity-selectBox-dropdown-menu.spp-product__mini-bag-show,
body.page-product .spp-product__mini-bag-shade-select-selectBox-dropdown-menu.spp-product__mini-bag-show,
body.page-product .spp-product__mini-price-size-select-selectBox-dropdown-menu.spp-product__mini-bag-show {
  @include opacity(1);
}

.spp-product__mini-bag.spp-product__mini-bag-show {
  visibility: visible;
  z-index: 10;
  pointer-events: auto;
}

.spp-product__mini-bag-section {
  @include pie-clearfix;
  @include index-navigation;
  color: $color-navy;
  #{$renutriv} & {
    color: white;
  }
  #{$aerin} & {
    color: $color-periwinkle-blue--aerin;
  }
  .spp-product__mini-bag-image-container {
    float: $ldirection;
    width: 64px;
    height: 84px;
    text-align: center;
    #{$aerin} & {
      background: white;
    }
    #{$renutriv} & {
      background: black;
    }
    #{$estee_edit} & {
      background: url(/media/export/cms/products/bg/product_bg_tee_85px.jpg) repeat-x 0 0 $color-white;
    }
  }
  .spp-product__mini-bag-image {
    height: 84px;
    width: auto;
  }
  .spp-product__mini-bag-description {
    float: $ldirection;
    width: 165px;
    margin-#{$ldirection}: 5px;
  }
}

.spp-product__mini-bag-header,
.spp-product__mini-bag-sub-header {
  @include index-navigation;
}

.spp-product__mini-bag-price-size {
  font-size: 12px;
  margin-top: 2px;
}

.spp-product__mini-bag-button-container {
  @include pie-clearfix;
  position: relative;
  margin-top: 11px;
}

.spp-product__mini-bag-shade-select-container,
.spp-product__mini-price-size-select-container {
  margin-bottom: 9px;
}

.spp-product__mini-bag-selecter {
  margin-top: 11px;
  width: 100%;
  text-align: center;
}

.spp-product__mini-bag-add-button {
  float: $ldirection;
  display: block;
  margin-#{$ldirection}: 10px;
  @include swap_direction(border, 0);
  @include swap_direction(padding, 0);
  width: 130px;
}

// spp product
.spp-product {
  @include pie-clearfix;
  @include text-product-anchor;
}

.spp-product h3 {
  @include pie-clearfix;
  margin-bottom: 0px;
}

.spp-product__image-container {
  width: 888px;
  height: 768px;
  position: relative;
  overflow: hidden;
  .slick-dots {
    z-index: 2;
    bottom: 80px;
  }
}

.spp-product__images {
  .spp-product__image {
    &.smoosh {
      background: $color-white;
    }
    img {
      width: 558px;
      height: 768px;
      @include swap_direction(margin, 0 165px);
    }
  }
}

.spp-product__description {
  position: absolute;
  color: $color-navy;
  #{$rdirection}: 112px;
  top: 0px;
  width: 344px;
  //inner = 340px
  margin-top: 25px;
  padding-top: $header-height-mobile;
  // right after mobile
  #{$renutriv} & {
    color: $color-white;
  }
  #{$aerin} & {
    color: $color-periwinkle-blue--aerin;
  }
  @media #{$medium-up} {
    padding-top: $header-collapsed-height;
  }
}

.spp-product__description-section {
  @include swap_direction(padding, 0 2px);
  width: 100%;
}

.spp-product__review {
  @include text--short;
  margin-top: 20px;
}

.spp-product__price-size {
  @include swap_direction(padding, 26px 0);
}

.spp-product__attribute {
  margin-top: 30px;
}

.spp-product__attribute h5 {
  @include text--bold;
  margin-bottom: 0px;
}

.spp-product__attribute p {
  @include text--short;
  margin-top: 5px;
  #{$renutriv} & {
    font-family: $roboto-condensed;
  }
}

.spp-product__intensity {
  @include pie-clearfix;
  width: 100%;
  @include swap_direction(padding, 28px 0 8px 0);
  border-bottom: 1px solid $color-light-gray;
  li {
    text-transform: uppercase;
    float: $ldirection;
    font-size: 12px;
    margin-#{$rdirection}: 22px;
    cursor: pointer;
    &:last-child {
      margin-#{$rdirection}: 0;
    }
    &.is_selected {
      font-weight: bold;
    }
  }
}

.spp-product__sku-menu {
  display: block;
  margin-top: 10px;
}

.spp-product__button-container {
  @include pie-clearfix;
  margin-top: 11px;
}

.spp-product__add-button {
  float: $ldirection;
  display: block;
  margin-#{$ldirection}: 10px;
  @include swap_direction(border, 0);
}

//custom selects

.spp-product__mini-bag-shade-select,
.spp-product__mini-price-size-select {
  width: 236px;
}

.spp-product__mini-bag-quantity {
  float: $ldirection;
  width: 125px;
}

.spp-product__mini-bag-quantity {
  width: 96px;
}

body.page-product .spp-product__mini-bag-shade-select-selectBox-dropdown-menu {
  &.selectBox-options li a {
    padding-#{$ldirection}: 0;
  }
}

body.page-product .spp-product__mini-bag-quantity-selectBox-dropdown-menu,
body.page-product .spp-product__mini-bag-shade-select-selectBox-dropdown-menu,
body.page-product .spp-product__mini-price-size-select-selectBox-dropdown-menu {
  @include single-transition(opacity, $back-to-top-fadein, ease-in-out);
  @include opacity(0);
}

.spp-product__personal {
  padding-top: 15px;
}

.spp-product__detail-link,
.spp-product__share,
.spp-product__wishlist {
  @include inline-block;
  @include link--bold;
  margin-#{$rdirection}: 30px;
}

// product details
.spp-product__details-container {
  width: 100%;
  max-width: 1024px;
  #{$estee_edit} .page-main & {
    font-family: $ffmarkweb;
    padding: 0 20px;
    p {
      font-family: $ffmarkweb;
    }
  }
  @include swap_direction(margin, 0 auto 100px auto);
}

.spp-product__details {
  @include pie-clearfix;
  width: 100%;
  @include swap_direction(padding, 50px 0 0 0);
  font-size: 14px;
  @media #{$large-up} {
    @include swap_direction(padding, 50px 0);
  }
  b {
    font-size: 14px;
  }
  &.spp-product__details--estee-edit {
    font-size: 14px;
    line-height: 24px;
    b {
      font-family: $ffmarkweb-medium;
      font-size: 12px;
      line-height: 24px;
      text-transform: uppercase;
    }
    .spp-product__details-column02 {
      .spp-product__details-attribute {
        text-transform: uppercase;
        p {
          letter-spacing: 0.05em;
          font-size: 14px;
          line-height: 24px;
          #{$estee_edit} & {
            font: 15px/24px $ffmarkweb;
            margin: 15px 0;
          }
        }
      }
      @media #{$large-up} {
        padding: 0 0 0 10%;
        width: 38.75%;
      }
      .spp-product__details-attribute__label {
        @include headline--quaternary;
        padding-bottom: 6px;
        margin: 0 0 38px 0;
        border-bottom: 1px solid $color-light-gray;
        font-family: $ffmarkweb-medium;
        font-size: 20px;
        text-transform: uppercase;
      }
    }
  }
  ul li {
    list-style: list-item;
    list-style-type: disc;
    list-style-position: inside;
    font-size: 12px;
    letter-spacing: 0.15em;
  }
  .spp-product__details-header {
    @include headline--quaternary;
    padding-bottom: 6px;
    border-bottom: 1px solid $color-light-gray;
    @include swap_direction(margin, 0 8% 38px 8%);
    #{$aerin} & {
      border-color: $color-periwinkle-blue--aerin;
    }
    #{$renutriv} & {
      border-color: $color-gold--renutriv;
      padding-bottom: 2px;
      font-family: $bb-roman;
      text-transform: uppercase;
      font-size: 35px;
      letter-spacing: 0.03em;
      font-weight: lighter;
    }
    #{$estee_edit} .page-main & {
      font-family: $ffmarkweb-medium;
      font-size: 20px;
      text-transform: uppercase;
      margin: 0 0 38px 0;
      width: 100%;
      @media #{$large-up} {
        margin: 0 0 38px 0;
        width: 100%;
      }
    }
    @media #{$large-up} {
      width: 54%;
      margin-#{$rdirection}: 0;
    }
  }
  .spp-product__details-description {
    margin-bottom: 20px;
    font-size: 15px;
    #{$aerin} & {
      @include text--long-copy;
    }
    #{$renutriv} & {
      font: 16px/26px $helvetica-neue;
    }
    p {
      #{$renutriv} & {
        font: 16px/26px $helvetica-neue;
      }
      #{$estee_edit} & {
        font: 15px/24px $ffmarkweb;
        margin: 15px 0;
      }
    }
  }
  .spp-product__details-attribute__label {
    font-size: 12px;
    font-family: 'AkzidenzGrotesk W1G';
    letter-spacing: 0.15em;
    @include text--bold;
    #{$renutriv} & {
      font: 16px $helvetica-neue;
    }
  }
  .spp-product__details-attribute {
    #{$renutriv} & {
      font: 16px $helvetica-neue;
    }
    h5 {
      font-size: 14px;
    }
    p {
      #{$renutriv} & {
        font: 16px/26px $helvetica-neue;
      }
    }
  }
  .spp-product__details-column {
    float: $ldirection;
  }
  .spp-product__details-column01 {
    width: 100%;
    @include swap_direction(padding, 0 8%);
    margin-bottom: 20px;
    @media #{$large-up} {
      width: 66%; //54 +12
      padding-#{$rdirection}: 0;
      margin-bottom: 0;
    }
    #{$estee_edit} & {
      @media #{$large-up} {
        padding: 0;
        width: 51.25%;
      }
    }
    ul li {
      font-size: 15px;
      letter-spacing: inherit;
    }
  }
  .spp-product__details-column02 {
    width: 100%;
    @include swap_direction(padding, 0 8%);
    .spp-product__details-attribute {
      text-transform: uppercase;
      p {
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.15em;
        @include swap_direction(margin, 6px 0);
      }
      .spp-product__details-attribute__label {
        margin-top: 16px;
      }
      &:first-child {
        .spp-product__details-attribute__label {
          margin-top: 0;
        }
      }
    }
    @media #{$large-up} {
      width: 34%; //22 + 3 + 9
      @include swap_direction(padding, 0 3% 0 9%);
    }
  }
  &-ingredients {
    & h5 {
      cursor: pointer;
    }
    &-toggle {
      display: inline-block;
      height: 11px;
      width: 11px;
    }
    &-more {
      background-image: url('/media/export/cms_2.0/SPP/ingredient/product_details_open.png');
    }
    &-less {
      background-image: url('/media/export/cms_2.0/SPP/ingredient/product_details_close.png');
    }
    &-disclaimer {
      font-style: italic;
      padding-top: 0.75em;
    }
  }
}

.spp-product__benefits,
.spp-product__reviews {
  height: 800px;
  border-bottom: $color-navy;
}

.spp-product__benefits {
  background: #cdcdcd;
}

.spp-product__reviews {
  margin-bottom: 100px;
  #{$renutriv} & {
    background: #131313;
  }
}

.spp-product__review-image {
  margin-bottom: 2px;
}

// product 13141 specific css
.page-product-13141 {
  .product-full {
    .product-full__image {
      background-image: none;
    }
  }
  .spp-product__anchor {
    display: none;
  }
  .product-full__description {
    .product-full__detail-link {
      display: none;
    }
  }
  .product-full__description-section {
    .product-full__price-size,
    .product-full__button-container,
    .product-full__personal {
      display: none;
    }
  }
  .spp-product__mini-bag {
    display: none !important;
  }
  .spp-product__details-container {
    display: none;
  }
}

//Best For Reviees
.spp-product__reviews {
  .bv_best_for_header {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.15em;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  .bv_best_for_container {
    width: 800px;
    @include swap_direction(padding, 0 30px 30px);
    @include swap_direction(margin, 0 auto);
  }
  .bv_best_for_container li {
    font-family: $optimalight;
    font-size: 70px;
    letter-spacing: -0.04em;
    line-height: 1;
  }
  .bv_best_for_container li:last-child .reviw_data_comma {
    display: none;
  }
}

.product-full {
  &__description {
    #{$estee_edit} & {
      .BVRROverallRatingContainer,
      .BVRRRatingSummaryLinks {
        display: inline-block;
        vertical-align: top;
        float: none;
      }
    }
  }
}

.spp-popup-overlay-form__content {
  &.success-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .address-form {
    margin-top: 20px;
    .full {
      padding-left: 20px;
    }
  }
}

.product-full__title {
  margin-bottom: 5px;
  line-height: 1;
  font-size: 50px;
  font-family: $optimaregular;
}