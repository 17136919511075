// variables

$header-alt-vertical-padding: 9px;
$header-alt-menu-height: 24px;

// same
//$header-item-height: 52px;

// new height = 9 + 52 + 24 = 85px total
$header-alt-combined-height: $header-alt-vertical-padding + $header-item-height +
  $header-alt-menu-height;
$header-alt-collapsed-height-pc: $header-alt-combined-height;
$header-alt-collapsed-height: $header-alt-combined-height;

// layout/_page_header
.page-header {
  padding: $header-alt-vertical-padding 0 0;
  @media #{$medium-up} {
    min-height: $header-alt-collapsed-height;
  }
}

//components/header/_page_branding
///
// Branding
///
.page-branding {
  float: $ldirection;
  margin-#{$ldirection}: 56px; // mobile-first
  height: 52px;
  // make sure padding top adjusts with image height
  padding-top: 6px;
  // after phone
  @media #{$medium-up} {
    margin-#{$ldirection}: $menu-toggle-width + $header-item-padding;
    position: relative;
    // handle search positioning
    #{$search} & {
      margin-#{$ldirection}: $header-item-padding;
    }
  }
  // desktop
  @media #{$xlarge-up} {
    float: none;
    margin-#{$ldirection}: 0;
    position: absolute;
    top: 0;
    #{$ldirection}: 20px;
  }
  // minimum state for checkout
  #{$min-nav} &,
  .elc-gnav-minimal & {
    margin-#{$ldirection}: $mobile-outer-gutter;
  }
  // trust mark / override locales
  // top position only
  .trust-mark-elc-nodeblock {
    .trust_mark_header .trust_mark_header__link {
      @media #{$medium-up} {
        top: -13px;
      }
    }
  }
}
// trust mark / override locales
.is-alternate .trust-mark-elc-nodeblock {
  .trust_mark_header .trust_mark_header__link {
    @media #{$medium-up} {
      z-index: 999;
    }
  }
}

///
// Logo
//
// styleguide pg.82
///

%mobile-logo--estee {
  background: url($svg-path + 'logo-estee.svg') no-repeat;
}
%mobile-logo--aerin {
  background: url($svg-path + 'logo-aerin.svg') no-repeat;
}
%mobile-logo--renutriv {
  background: url($svg-path + 'logo-renutriv.svg') no-repeat;
}

.page-navigation__menu-toggle {
  // after ipad landscape, when alternate
  @media #{$xlarge-up} {
    display: none;
    #{$alt}:not(#{$search}) & {
      z-index: 202; //clear page-navigation
    }
  }
}

// components/header/_page_navigation
.page-navigation {
  // above iphone portrait
  @media #{$xlarge-up} {
    clear: both;
    margin-top: -3px;
    cursor: default;
    #{$alt} & {
      z-index: 201; //clear search
      position: relative;
    }
  }
  .level-1 {
    line-height: 16px;
    vertical-align: top;
  }
  .gnav_menu_estee_edit__spacer {
    padding-bottom: 246px;
    @media #{$device-tablet-down} {
      padding-bottom: 0px;
    }
  }
}

// components/header/_page_utilities

.page-utilities__account {
  @media #{$medium-up} {
    position: relative;
    margin-top: -10px;
  }
  .user-loyalty-state {
    @media #{$medium-up} {
      #{$rdirection}: 20px;
    }
    @media #{$medium-only} {
      #{$rdirection}: 20px;
    }
    @media #{$large-only} {
      #{$rdirection}: 20px;
    }
  }
}
