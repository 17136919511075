$rgbawhite-50: rgba(255, 255, 255, 0.5);
$rgbablack-50: rgba(0, 0, 0, 0.5);
$rgbablack-90: rgba(0, 0, 0, 0.9);
$rgbablack-20: rgba(0, 0, 0, 0.2);
$rgbawhite-90: rgba(255, 255, 255, 0.9);
$rgbawhite-30: rgba(255, 255, 255, 0.3);
$medium-max-width: 767px;
$medium-min-width: 768px;
$lrg-max-width: 1366px;

%services-carousel__button {
  @media screen and (max-width: $medium-max-width) {
    top: 25vw;
    height: 15.625vw;
    width: 6.25vw;
    padding: 0;
  }
  position: absolute;
  top: 25%;
  display: inline-block;
  height: 4.7rem;
  width: 2.5rem;
  outline: 0;
  border: 0;
  transition: background 0.25s;
  background: $rgbawhite-50;
  z-index: 2;
  padding: 20px 5px;
  svg {
    transition: color 0.3s;
    stroke: $rgbablack-50;
    fill: none;
  }
  &:hover {
    background: $rgbawhite-90;
    svg {
      stroke: $rgbablack-90;
    }
  }
  &.disabled,
  &.disabled:hover {
    background: $rgbawhite-30;
    cursor: default;
    svg {
      stroke: $rgbablack-20;
    }
  }
  &.carousel_inactive {
    display: none;
  }
}

.services-menu {
  padding: 0 6vw;
  margin: 0 auto;
  @media screen and (min-width: $medium-min-width) {
    max-width: 950px;
    padding: 0 60px;
  }
  > h4 {
    text-transform: uppercase;
    font-size: 8.2vw;
    letter-spacing: 0;
    line-height: 105%;
    @media screen and (min-width: $medium-min-width) {
      font-size: 29px;
    }
  }
  .services-menu-header {
    color: $color-navy;
    text-align: center;
    margin: 0;
    & + .services-menu-item {
      margin: 5vw 0 0;
      @media screen and (min-width: $medium-min-width) {
        margin: 35px 0 0;
      }
    }
    &__title {
      text-transform: uppercase;
      font-size: 8.125vw;
      letter-spacing: 0;
      margin-top: 9%;
      @media screen and (min-width: $medium-min-width) {
        font-size: 50px;
        letter-spacing: -1px;
        margin-top: 80px;
      }
      @media screen and (max-width: $lrg-max-width) {
        margin-top: 5.839vw;
      }
    }
    &__subtitle {
      font-family: $akzidenz;
      font-weight: bold;
      font-size: 4.6vw;
      line-height: 123%;
      margin: 3.2% 0;
      @media screen and (min-width: $medium-min-width) {
        font-family: $optimalight;
        font-size: 29px;
        margin: 2.6% 0 1.6%;
      }
    }
    &__description,
    &__description p {
      font-size: 4.375vw;
      line-height: 125%;
      @media #{$medium-up} {
        font-size: 16px;
        line-height: 160%;
      }
    }
  }
  .services-menu-item {
    color: $color-navy;
    border-top: 1px solid $color-navy;
    margin: 11.5vw 0 4vw;
    padding: 5vw 0 1vw;
    @media screen and (min-width: $medium-min-width) {
      margin: 40px 0 0;
      padding: 35px 0 0;
    }
    .service-header {
      text-align: center;
      &__title {
        text-transform: uppercase;
        font-size: 8.2vw;
        letter-spacing: 0;
        line-height: 105%;
        @media screen and (min-width: $medium-min-width) {
          font-size: 29px;
        }
      }
      &__subtitle {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 3.7vw;
        letter-spacing: 0;
        line-height: 100%;
        margin: 1vw 0 0;
        @media screen and (min-width: $medium-min-width) {
          font-size: 16px;
          margin: 7px 0 0 0;
          letter-spacing: 2px;
        }
      }
      &__description,
      &__description p {
        font-size: 4.375vw;
        line-height: 5.625vw;
        @media #{$medium-up} {
          margin-top: 22px;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 160%;
        }
      }
    }
    .service-body {
      display: block;
      text-align: center;
      margin: 6.5vw 0 0;
      position: relative;
      @media screen and (min-width: $medium-min-width) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 35px 0;
        text-align: #{$ldirection};
        &__section {
          width: 46%;
        }
        &__content {
          padding: 0;
        }
      }
      &__image {
        padding: 0 12vw;
        align-self: flex-start;
        @media screen and (min-width: $medium-min-width) {
          padding: 0;
        }
        img {
          width: 100%;
        }
        &--full-width {
          padding: 0 3vw;
        }
      }
      &__content {
        padding-top: 4.7vw;
        @media screen and (min-width: $medium-min-width) {
          padding: 0;
          @at-root [dir='rtl'] & {
            text-align: #{$rdirection};
          }
        }
        .cta__button {
          width: 95%;
          height: 45px;
          line-height: 45px;
          @media screen and (min-width: $medium-min-width) {
            width: inherit;
          }
          &--light {
            @media screen and (min-width: $medium-min-width) {
              background: transparent;
              color: $color-white;
              border: 1px solid $color-white;
            }
          }
        }
        h3 {
          font-size: 7.6vw;
          @media screen and (min-width: $medium-min-width) {
            font-size: 50px;
          }
        }
        h4 {
          font-size: 6.6vw;
          letter-spacing: -0.04em;
          & + h4 {
            margin-top: 1.5vw;
          }
          @media screen and (min-width: $medium-min-width) {
            font-size: 30px;
          }
        }
        p {
          font-size: 4.375vw;
          line-height: 125%;
          margin: 1.1em 0;
          @media screen and (min-width: $medium-min-width) {
            font-size: inherit;
            line-height: 20px;
            margin-bottom: 1.6em;
            + .service-body__cta-wrapper {
              margin-top: 30px;
            }
          }
          &:first-of-type {
            margin-top: 0;
          }
          strong {
            text-transform: uppercase;
            font-size: 0.88em;
          }
          + .service-body__cta-wrapper {
            margin-top: 10px;
          }
        }
        ul {
          @media screen and (min-width: $medium-min-width) {
            margin-bottom: 20px;
          }
          li {
            font-size: 4.375vw;
            line-height: 125%;
            margin-bottom: 15px;
            &:before {
              content: '\2022';
              display: inline-block;
              margin-#{$ldirection}: -10px;
              width: 10px;
              @at-root [dir='rtl'] & {
                margin-#{$ldirection}: 0;
                margin-#{$rdirection}: -10px;
              }
            }
            @media screen and (min-width: $medium-min-width) {
              font-size: inherit;
              line-height: 20px;
              margin-bottom: 15px;
              padding-#{$ldirection}: 10px;
              @at-root [dir='rtl'] & {
                padding-#{$ldirection}: 0;
                padding-#{$rdirection}: 10px;
              }
            }
          }
        }
      }

      &--text-#{$ldirection} {
        flex-direction: row-reverse;
        @at-root [dir='rtl'] & {
          flex-direction: row;
        }
      }

      &--text-#{$rdirection} {
        flex-direction: row;
        @at-root [dir='rtl'] & {
          flex-direction: row-reverse;
        }
      }
      &--text-dark {
        .service-body__content {
          color: $color-navy;
        }
      }
      &--text-light {
        @media screen and (min-width: $medium-min-width) {
          .service-body__content {
            color: $color-white;
          }
        }
      }
      &--with-full-width-image {
        margin: 5.2vw 0;
        &::before {
          content: '';
        }
        @media screen and (min-width: $medium-min-width) {
          margin: 35px 0;
          .service-body {
            &__content {
              position: absolute;
              padding: 0 13px;
              @media screen and (max-width: $lrg-max-width) {
                h3 {
                  font-size: 3.663vw;
                }
                h4 {
                  font-size: 2.1978vw;
                }
                p {
                  font-size: 1.0989vw;
                }
              }
              @media screen and (max-width: 1091px) {
                p {
                  font-size: 12px;
                }
              }
            }
            &__image {
              position: relative;
              padding: 0;
              width: 100%;
              z-index: -1;
            }
          }
        }
        &.service-body--text-left {
          .service-body {
            &__content {
              #{$ldirection}: 0;
              @media screen and (min-width: $medium-min-width) {
                padding-#{$ldirection}: 46px;
              }
            }
          }
        }
        &.service-body--text-right {
          .service-body {
            &__content {
              #{$rdirection}: 0;
              @media screen and (min-width: $medium-min-width) {
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }
  .services-menu-item.services-carousel {
    @media screen and (min-width: $medium-min-width) {
      overflow: hidden;
    }
    .service-body {
      position: relative;
      display: block;
      margin: 5.5vw 0 0;
      @media screen and (min-width: $medium-min-width) {
        overflow: hidden;
        margin: 35px 0 0;
      }
      &__content {
        text-align: center;
        @media screen and (min-width: $medium-min-width) {
          margin: 10px 0 0;
        }
      }
    }
    .carousel-slide {
      @media screen and (max-width: $medium-max-width) {
        width: 64.84375vw;
        margin: 0;
        margin-#{$rdirection}: 6.25vw;
        float: #{$ldirection};
        &:last-child {
          margin-#{$rdirection}: 0;
        }
        .carousel-slide__image {
          .img {
            width: 100%;
          }
        }
        &.current {
          .carousel-slide__title,
          .carousel-slide__description {
            transition: all 500ms ease;
            -moz-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            -webkit-transition: all 500ms ease;
            opacity: 1;
          }
        }
        &:not(.current) {
          .carousel-slide__title,
          .carousel-slide__description {
            transition: all 500ms ease;
            -moz-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            -webkit-transition: all 500ms ease;
            opacity: 0;
          }
        }
      }
      width: 256px;
      margin: 0;
      margin-#{$rdirection}: 31px;
      float: #{$ldirection};
      @at-root [dir='rtl'] & {
        margin-#{$ldirection}: 31px;
        margin-#{$rdirection}: 0;
      }
      &:last-child {
        margin-#{$rdirection}: 0;
      }
      &__title {
        @media screen and (max-width: $medium-max-width) {
          letter-spacing: 0.05em;
          margin: 5% 0 0 0;
          font-size: 4.0625vw;
          line-height: 5.625vw;
          width: 100vw;
          position: relative;
          #{$ldirection}: 50%;
          #{$rdirection}: 50%;
          margin-#{$ldirection}: -50vw;
          margin-#{$rdirection}: -50vw;
        }
        font-weight: bold;
        letter-spacing: 3px;
        line-height: 22px;
        margin-bottom: 5px;
        margin-top: 1.3vw;
      }
      &__description {
        @media screen and (max-width: $medium-max-width) {
          width: 100vw;
          position: relative;
          #{$ldirection}: 50%;
          #{$rdirection}: 50%;
          margin-#{$ldirection}: -50vw;
          margin-#{$rdirection}: -50vw;
          margin-top: 2.3%;
          padding: 0 2vw;
        }
        p {
          @media screen and (max-width: $medium-max-width) {
            font-size: 4.375vw;
            line-height: 5.625vw;
            margin: 0;
          }
          margin: 5px 0 0;
          line-height: 160%;
        }
      }
    }
    .services-carousel {
      &__track {
        position: relative;
        width: 500%;
        will-change: transform;
      }
      &__previous {
        @extend %services-carousel__button;
        #{$ldirection}: 0;
      }
      &__next {
        @extend %services-carousel__button;
        #{$rdirection}: 0;
      }
    }
  }
}
