@import 'pc_desire_video';
@import 'pc_desire_shades_carousel';

body.html {
  #desire_mod3 {
    .button__quickshop {
      @media screen and (max-width: 767px) {
        display: block !important;
      }
    }
  }
}

.desire__module-dark-bg {
  background-color: $color-black;
}

.desire__module-light-bg {
  .desire_mod5__header.el_boutique_light,
  .desire_mod2__copy .el_boutique_light,
  .desire_mod3__copy.desire_mod3__section .el_boutique_light {
    color: #0c0f20;
  }
}

.desire__boutique-formatter {
  .page-wrapper {
    .page-navigation-second {
      background: $color-black;
      &.is-alternate {
        background: $color-white;
        .page-navigation__menu .menu-ref:hover .menu-ref__title:before {
          background: $color-black;
        }
      }
      .page-navigation__menu .menu-ref--has-children .menu-ref__title,
      .page-navigation__menu .menu-ref__title a {
        color: $color-white;
      }
      .page-navigation__menu .menu-ref:hover .menu-ref__title:before {
        background: $color-white;
      }
    }
    .page-header {
      background: $color-black;
      &.is-alternate {
        .page-navigation__menu {
          background: $color-white;
        }
        .search-input-container .page-utilities__search-button {
          border-#{$ldirection}: none;
        }
        .page-utilities__search-button {
          border-#{$ldirection}: 1px solid $color-navy;
        }
        .page-utilities__cart-button {
          border-#{$ldirection}: 1px solid $color-navy;
        }
        .page-utilities__account,
        .user-loyalty-state .user-logged-out__link,
        .user-loyalty-state .user-logged-in__link,
        .page-utilities__cart .page-utilities__cart-count,
        .page-utilities__help-text,
        .page-utilities__account-text a,
        .page-utilities__signin-text,
        .page-utilities__cart-text,
        .utility-item-text {
          color: $color-navy;
        }
        .page-utilities__search-icon {
          background-image: url(/media/export/cms/gnav/search_blue.png);
        }
        .utility-nav__cart {
          .utility-nav__cart-icon {
            background: url($svg-path + 'cart.svg') no-repeat;
          }
          .utility-nav__cart-count {
            color: $color-navy;
          }
        }
        .page-navigation__menu {
          @media only screen and (min-width: 1025px) {
            .menu-ref:hover .menu-ref__title:before {
              background: $color-navy;
            }
          }
          .menu-ref__title,
          .menu-ref--has-children .menu-ref__title,
          .menu-ref__title a {
            color: $color-navy;
          }
        }
        .page-branding a.page-branding__logo {
          background: url(/sites/esteelauder/themes/estee_base/img/sprites/elc-logo-large.png);
        }
      }
      .page-navigation__menu {
        background: $color-black;
      }
      .page-navigation__menu {
        @media only screen and (min-width: 1025px) {
          .menu-ref:hover .menu-ref__title:before {
            background: $color-white;
          }
        }
        .menu-ref--has-children .menu-ref__title,
        .menu-ref__title a {
          color: $color-white;
        }
      }
      .page-branding {
        a.page-branding__logo {
          background: url(/sites/esteelauder/themes/estee_base/img/sprites/elc-logo-large-alt.png);
          background-size: cover;
          background-repeat: no-repeat;
          width: 243px;
          height: 32px;
        }
      }
      .page-utilities__account,
      .user-loyalty-state .user-logged-out__link,
      .user-loyalty-state .user-logged-in__link,
      .page-utilities__cart .page-utilities__cart-count,
      .page-utilities__help-text,
      .page-utilities__account-text a,
      .page-utilities__signin-text,
      .page-utilities__cart-text,
      .utility-item-text {
        color: $color-white;
      }
      .utility-nav__cart {
        .utility-nav__cart-icon {
          background-image: url($base-theme-image-path + 'sprites/cart_white.png');
          background-size: 24px 22px;
          width: 24px;
          height: 22px;
        }
        .utility-nav__cart-count {
          color: $color-white;
        }
      }
      .page-utilities__search-icon {
        background-image: url(/media/boutiques/common/search-white.png);
        background-size: cover;
        width: 22px;
        height: 22px;
      }
      .page-navigation__menu-toggle .page-navigation__menu_text {
        color: $color-white;
      }
      .page-utilities__cart-button {
        border-#{$ldirection}: 1px solid $color-white;
      }
      .page-utilities__search-button {
        border-#{$ldirection}: 1px solid $color-white;
      }
    }

    // Styles to account for the old gnav.
    .page-header.gnav-old {
      background: $color-black;
      &.is-alternate {
        .header-items {
          background-color: $color-navy;
        }
        .page-navigation__menu-toggle {
          border-color: transparent;
        }
      }
      &.is-alternate.is-search {
        .page-utilities__search-icon {
          background-image: url('/media/images/global/sprites-s38a9885fce.png');
          background-position: 0 -7894px;
          background-size: auto;
        }
        .page-utilities__search {
          .el-search-block {
            background-color: $color-black;
            #typeahead-wrapper {
              background-color: $color-black;
              .typeahead-header {
                background-color: $color-black;
              }
              .typeahead-product-results {
                border-bottom: 1px solid $color-white;
              }
            }
          }
          .el-search-block input[type='text'].form-text {
            border-bottom: 1px solid $color-white;
          }
        }
      }
      .page-navigation .level-1 {
        color: $color-white;
      }
      .page-branding a.page-branding__logo {
        background: url(/sites/esteelauder/themes/estee_base/img/sprites/elc-logo-large-alt.png);
        background-size: cover;
        background-repeat: no-repeat;
        width: 243px;
        height: 32px;
      }
      .page-navigation__menu-toggle .page-navigation__menu_text {
        color: $color-white;
      }
      .page-utilities__account,
      .user-loyalty-state .user-logged-out__link,
      .user-loyalty-state .user-logged-in__link,
      .page-utilities__cart .page-utilities__cart-count,
      .page-utilities__help-text,
      .page-utilities__account-text,
      .page-utilities__signin-text,
      .page-utilities__cart-text,
      .utility-item-text {
        color: $color-white;
      }
      .page-utilities__cart-button {
        border-#{$ldirection}: 1px solid $color-white;
      }
      .page-utilities__cart-icon {
        background-image: url(/media/boutiques/common/bag-white.png);
        background-size: cover;
      }
      .page-utilities__search-button {
        border-#{$ldirection}: 1px solid $color-white;
      }
      .page-utilities__search-icon {
        background-image: url(/media/boutiques/common/search-white.png);
        background-size: cover;
        width: 22px;
        height: 22px;
      }
    }
  }
}

.desire_formatter {
  .boutique__section {
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
    background-color: $color-black;
    margin-top: -3%;
    padding-bottom: 7%;
    .boutique__product_link {
      @media screen and (max-width: 767px) {
        width: 84%;
        height: 14.063vw;
        font-size: 3.75vw;
        line-height: 14.2vw;
        letter-spacing: 0.12em;
      }
      border: 1px solid $color-white;
      height: 40px;
      width: 160px;
      line-height: 40px;
      background-color: $color-black;
      margin: auto;
      position: relative;
      text-align: center;
      a {
        color: $color-white;
      }
    }
  }
}

#desire_mod1 {
  position: relative;
  width: 100%;
  .desire_mod1__bg {
    vertical-align: top;
  }
  .desire__hero-block {
    position: absolute;
    z-index: 1;
  }
  .boutique_vid_play_img {
    position: absolute;
    opacity: 0;
    width: 6.6%;
    top: 39%;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    cursor: pointer;
    z-index: 5;
    margin: 0 auto;
    &:hover {
      opacity: 0.5;
    }
    @media screen and (max-width: $max-width) {
      width: 89px;
    }
    @media screen and (max-width: 767px) {
      width: 21.5%;
      top: 38.8vw;
    }
  }
  #desire__hero-block_1 {
    opacity: 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 4.09vw;
    letter-spacing: 0.002em;
    top: 34.5%;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    z-index: 3;
    @media screen and (max-width: 767px) {
      font-size: 8.9vw;
    }
    @media screen and (min-width: $max-width) {
      font-size: 56px;
    }
  }
  .desire_mod1__bg {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1366px;
  }
  .desire__hero-block {
    .el_boutique_body {
      padding-top: 6%;
    }
  }
  .desire__hero-texture {
    position: relative;
    #desire__hero-block_2 {
      position: absolute;
      top: 23%;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      letter-spacing: 0.0005em;
      text-transform: uppercase;
      text-align: center;
      font-size: 3.95vw;
      letter-spacing: 0.005em;
      z-index: 2;
      @media screen and (max-width: 767px) {
        font-size: 9vw;
        top: 15.8%;
        #{$ldirection}: 10%;
        #{$rdirection}: 10%;
      }
      @media screen and (min-width: $max-width) {
        font-size: 54px;
      }
    }
    .desire_mod1__texture {
      margin-top: -10.8%;
      margin-#{$ldirection}: 100%;
      z-index: 1;
      position: relative;
      background-color: $color-black;
      @media screen and (max-width: 767px) {
        margin-top: -16.3%;
      }
    }
  }
}

#desire_mod3 {
  margin: 1.8vw 0;
  position: relative;
  z-index: 1;
  @media screen and (min-width: $max-width) {
    margin: 11px 0;
  }
  .desire_mod3__section {
    display: inline-block;
    vertical-align: middle;
    max-width: 50%;
    @media screen and (max-width: 767px) {
      display: block;
      vertical-align: middle;
      max-width: 100%;
      text-align: center;
    }
  }
  .el_boutique_img_wrapper.desire_mod3__section {
    position: relative;
    text-align: #{$rdirection};
    @include swap_direction(padding-#{$rdirection}, 1.46vw);
    overflow: hidden;
    height: 47.58vw;
    @media screen and (max-width: 767px) {
      @include swap_direction(padding-#{$rdirection}, 0);
      text-align: center;
      height: 101.56vw;
    }
    @media screen and (min-width: $max-width) {
      @include swap_direction(padding-#{$rdirection}, 20px);
      height: 650px;
    }
    img.desire_mod3__product-single-img {
      max-width: 534px;
      width: 84%;
      top: 0;
      #{$rdirection}: 0;
      position: absolute;
      z-index: 2;
      @media screen and (max-width: 767px) {
        text-align: center;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        margin: 0 auto;
      }
    }
    img.desire_mod3__productImg {
      max-width: 534px;
      width: 84%;
      top: 0%;
      #{$rdirection}: 0;
      position: absolute;
      @media screen and (max-width: 767px) {
        text-align: center;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        margin: 0 auto;
      }
    }
  }
  .desire_mod3__copy {
    @include swap_direction(padding-#{$ldirection}, 1.02vw);
    @media screen and (max-width: 767px) {
      @include swap_direction(padding-top, 5.8vw);
    }
    @media screen and (min-width: $max-width) {
      @include swap_direction(padding-#{$ldirection}, 14px);
    }
    #desire__product-block_1 {
      font-size: 1.75vw;
      line-height: 2.19vw;
      letter-spacing: -0.03em;
      @media screen and (max-width: 980px) {
        font-size: 2.35vw;
        line-height: 4.69vw;
      }
      @media screen and (max-width: 767px) {
        font-size: 4.9vw;
      }
      @media screen and (min-width: $max-width) {
        font-size: 24px;
        line-height: 30px;
      }
    }
    #desire__product-block_2 {
      font-size: 3.66vw;
      line-height: 3.66vw;
      letter-spacing: -0.015em;
      @media screen and (max-width: 980px) {
        font-size: 4.11vw;
        line-height: 4.11vw;
      }
      @media screen and (max-width: 767px) {
        font-size: 9.4vw;
        line-height: 10.5vw;
      }
      @media screen and (min-width: $max-width) {
        font-size: 50px;
        line-height: 50px;
      }
    }
    #desire__product-block_3 {
      font-size: 2.489vw;
      line-height: 3.66vw;
      letter-spacing: -0.03em;
      padding-bottom: 0.292vw;
      @media screen and (max-width: 980px) {
        font-size: 3.79vw;
        line-height: 5.46vw;
      }
      @media screen and (max-width: 767px) {
        font-size: 6.4vw;
        line-height: 6.46vw;
      }
      @media screen and (min-width: $max-width) {
        font-size: 34px;
        line-height: 50px;
        padding-bottom: 4px;
      }
    }
    p.desire_mod3__desc {
      font-size: 0.878vw;
      letter-spacing: 0.133em;
      padding-bottom: 2.19vw;
      @media screen and (max-width: 980px) {
        font-size: 1.2vw;
      }
      @media screen and (max-width: 767px) {
        font-size: 3.7vw;
        line-height: 5vw;
        letter-spacing: 0.147em;
        padding-top: 2vw;
      }
      @media screen and (min-width: $max-width) {
        font-size: 12px;
        padding-bottom: 30px;
      }
    }
    #desire__product-block_5 {
      padding-bottom: 20px;
      @media screen and (min-width: $max-width) {
        padding-bottom: 1.46vw;
      }
      #desire_product__qs {
        border: 1px solid $color-white;
        height: 40px;
        width: 160px;
        line-height: 40px;
        color: $color-white;
        background-color: $color-black;
      }
    }
  }
  .desire_mod3_mpp {
    @media screen and (max-width: 767px) {
      min-height: 14vw;
      position: relative;
    }
    .shop_now_centered {
      border: 1px solid $color-white;
      @media screen and (max-width: 767px) {
        background-color: $color-black;
        color: $color-white;
      }
    }
  }
  .mpp_qs_detached {
    .mpp__product-grid {
      .quickshop-inline {
        @media screen and (max-width: 767px) {
          background-color: $color-white;
        }
      }
      .quickshop-inline-top {
        @media screen and (max-width: 767px) {
          #{$ldirection}: 48.4%;
          top: -12px;
        }
      }
    }
    .mpp__header,
    .mpp__product-sort-container {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}

#desire_mod5 {
  .desire_mod5__header {
    font-family: $optimalight;
    font-size: 3.95604vw;
    width: 100%;
    text-align: center;
    line-height: 98%;
    padding: 2.8% 0 0.5%;
    letter-spacing: 0.008em;
    @media screen and (min-width: $max-width) {
      font-size: 54px;
    }
    @media screen and (max-width: 767px) {
      font-size: 9.375vw;
      padding: 11.1% 0 3% 0;
      letter-spacing: -0.02em;
      line-height: 99%;
    }
  }
  .desire_mod5__sub-header {
    font-family: $optimalight;
    font-size: 2.48901vw;
    width: 100%;
    text-align: center;
    line-height: 98%;
    letter-spacing: -0.005em;
    padding-bottom: 1.9%;
    @media screen and (min-width: $max-width) {
      font-size: 34px;
    }
    @media screen and (max-width: 767px) {
      font-size: 7.875vw;
      letter-spacing: -0.02em;
      line-height: 99%;
      padding-bottom: 3%;
    }
  }
  .desire_mod5__content {
    width: 67.6%;
    margin: 0 auto 9.5%;
    position: relative;
    @media screen and (max-width: 767px) {
      width: 84.375%;
    }
    .desire_mod5__content-copy {
      position: absolute;
      bottom: 4.4%;
      #{$ldirection}: 1.4%;
    }
    .boutique_vid_play_img {
      position: absolute;
      bottom: 10%;
      width: 9.847%;
      #{$ldirection}: 1.5%;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
      @media screen and (max-width: 767px) {
        margin: 0 auto;
        text-align: center;
        #{$ldirection}: 37.4%;
        bottom: 0;
        top: 22%;
        width: 25.556%;
      }
    }
    .boutique_vid_play_cta {
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
      @media screen and (max-width: 767px) {
        font-size: 3.4375vw;
        text-align: center;
        bottom: 22.1%;
        #{$ldirection}: 34.1%;
      }
    }
    .boutique__video_play_btn {
      margin-top: 0;
    }
  }
  .boutique__video_close_x {
    @media screen and (max-width: 767px) {
      width: 7.032%;
      #{$rdirection}: 3%;
    }
  }
  .basic_infinite_carouselnav.basic_infinite_carouselnav_1 {
    #{$ldirection}: 6.9%;
    @media screen and (max-width: 767px) {
      #{$ldirection}: 0;
      margin-#{$ldirection}: 0;
    }
  }
  .basic_infinite_carouselnav.basic_infinite_carouselnav_2 {
    #{$rdirection}: 6.9%;
    @media screen and (max-width: 767px) {
      #{$rdirection}: 0;
      margin-#{$rdirection}: 0;
    }
  }
  .basic_infinite_carouselnav {
    @media screen and (max-width: 767px) {
      width: 7.8%;
      top: -4%;
    }
  }
  .basic_infinite_carouselnav_btn {
    width: 30%;
    @media screen and (max-width: 767px) {
      width: 50%;
    }
  }
  .basic_infinite_carousel_pagination_dot {
    width: 9px;
    height: 9px;
    margin: 0 5px 0 6px;
    @media screen and (max-width: 767px) {
      width: 10px;
      height: 10px;
      margin: 0 8px 0 10px;
    }
  }
  .basic_infinite_carousel_pagination {
    margin-top: -1%;
    @media screen and (max-width: 767px) {
      margin-top: 3.15%;
    }
  }
  .basic_infinite_carousel_pagination_dot:not(.selected) {
    border: 1px solid $color-white;
  }
  .basic_infinite_carouselOuter:not(.basic_infinite_carousel_header) {
    padding-bottom: 3.663vw;
    @media screen and (min-width: $max-width) {
      padding-bottom: 50px;
    }
    @media screen and (max-width: 767px) {
      padding-bottom: 0;
    }
  }
  .basic_infinite_carouselOuter:not(.basic_infinite_carousel_header) {
    .mob_pag_1 .basic_infinite_carousel_pagination {
      @media screen and (max-width: 767px) {
        padding-bottom: 0;
      }
    }
  }
}
/* RTL Support */
html[dir='rtl'] {
  #desire__hero-block_1 {
    direction: rtl;
  }
}
