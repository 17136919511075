.sample-mini-bar-page .field-mobile-template {
  .min-bar-header-section {
    width: 100%;
    @include swap_direction(margin, 30px 0);
    .min-bar-header {
      font-size: 80px;
      line-height: 80px;
      span {
        font-size: 24px;
        &.highlight {
          font-size: inherit;
        }
      }
    }
    .min-bar-content {
      font-size: 12px;
      @include swap_direction(padding, 0);
    }
  }
  .mpp h2.mpp__header {
    border-bottom: 1px solid $color-light-gray;
    margin-top: 0;
    @include swap_direction(padding, 0 0 5px);
  }
}

.field-mobile-template .min-bar-head-section {
  width: 320px;
  @include swap_direction(margin, 0 auto);
}
