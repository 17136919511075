///
/// @file components/formatters/_home_formatter_v1.scss
///
/// \brief Combine the 'bricks' and stacked content on the front page into
/// a usable interaction
///

/// v1
.homepage-bricks {
  margin-top: -$header-height-mobile;
  // right after mobile
  @media #{$medium-up} {
    margin-top: -$header-collapsed-height;
  }
  // full height: taller
  @media #{$medium-up-tall} {
    margin-top: -$header-full-height;
  }
  @media (orientation: portrait) {
    margin-top: 0;
  }
}

.homepage__button-explore--wrapper {
  cursor: pointer;
  display: none;
  height: 53px;
  width: 53px;
  @include swap_direction(margin, -27px auto 27px auto);
  z-index: 2;
}

.button-boutique-toggle {
  cursor: pointer;
  display: block;
  position: relative;
}

/// v2
.home-formatter {
  max-width: 1500px;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  margin-top: -$header-height-mobile;
  //margin-bottom: 335px;
  position: relative;
  // right after mobile
  @media #{$medium-up} {
    margin-top: -$header-collapsed-height;
  }
  @media (orientation: portrait) {
    margin-top: 0;
  }
  .home-block {
    @include swap_direction(margin, 0 auto 30px auto);
    &:last-child {
      @include swap_direction(margin, 0 auto 0 auto);
    }
  }
}

.home-formatter__scroller {
  position: fixed;
  top: 30%;
  #{$rdirection}: 0;
  width: 34px;
  z-index: 90;
}

.home-scrollto {
  display: block;
  width: 16px;
  height: 16px;
  background: #c1c1c1;
  outline: 0;
  @include border-radius(8px);
  opacity: 0.3;
  margin-bottom: 20px;
  transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  &.block-active {
    opacity: 1;
  }
}
