///
/// @file drupal_modules/esteelauder_endeca/_global_search.scss
///
/// \brief styles for the responsive elements search page
///
/// used in template:
/// esteelauder_endeca/templates/endeca_search_v1/endeca_search_v1.html
/// esteelauder_endeca/templates/endeca_search_v1/endeca_search_mobile_v1.html
/// mobile search block
/// pc search block
/// and various theme based endeca tpl files
///

.search-loading {
  padding-#{$ldirection}: 30px;
  display: none;
}
