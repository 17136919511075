.sd-product-spp {
  // Hide QTY Picker for Specific SKU
  &[data-sku-base-id='155807'],
  &[data-sku-base-id='155808'] {
    .elc-quantity-selector-button {
      display: none;
    }
    .elc-product-full-cta-wrapper {
      padding: 0;
    }
  }
}

.page-utilities {
  .utility-nav__account {
    @media #{$medium-up} {
      left: -8px;
    }
    @media #{$xlarge-up} {
      left: -10px;
    }
  }
  .utility-nav__cart-button {
    left: 0;
  }
  .sd-search-gnav-input-field {
    @media #{$xlarge-up} {
      margin-right: 0; 
    }
    .elc-search-active-screen-wrapper {
      .elc-search-gnav-box .elc-search-close-icon-wrapper {
        @media #{$medium-up} {
          right: 70px;
        }
        @media #{$xlarge-up} {
          right: 81px;
        }
      }
    }
  }
}
