@import '../../../scss/theme-bootstrap';

.tabbed-products-block {
  &__header {
    letter-spacing: -0.02em;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 40px;
    line-height: 0.85;
    .tabbed-products-font & {
      font-family: $optimalight;
    }
    @media #{$large-up} {
      font-size: 58px;
    }
  }
  &__sub-header {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
    h1,
    h2,
    h3,
    h4,
    h5,
    div,
    p {
      font-family: inherit;
    }
    @media #{$large-up} {
      margin-top: 12px;
    }
  }
  &__tabs {
    margin: 10px auto 30px auto;
    text-align: center;
    width: 100%;
    @media #{$medium-up} {
      margin: 20px auto;
    }
  }
  &__tab {
    border-#{$rdirection}: 1px solid $color-black;
    color: $color-black;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 0.05em;
    line-height: 12px;
    padding: 0 10px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    @media #{$medium-up} {
      font-size: 12px;
      padding: 0 16px;
      line-height: 14px;
    }
    &:last-child {
      border-#{$rdirection}: none;
    }
    &:visited {
      color: $color-black;
      text-decoration: none;
    }
    &:not(.current):hover {
      color: $color-black;
      font-weight: bold;
      text-decoration: none;
    }
    &.current {
      color: $color-black;
      font-weight: bold;
      text-decoration: none;
    }
  }
  &__content-item {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 1s ease-in-out;
    width: 100%;
    &.current {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
  }
  .product-brief-carousel {
    border-top: none;
  }
  .grid--mpp__carousel {
    animation-duration: 1.5s;
    position: relative;
    .carousel-controls {
      @include carousel_arrows;
    }
    .grid--mpp__items {
      padding-#{$rdirection}: 18%;
      touch-action: pan-y;
      @media #{$medium-up} {
        padding: 0 43px;
      }
      .slick-list {
        overflow: visible;
        @media #{$medium-up} {
          overflow: hidden;
        }
        &.swiping {
          .grid--mpp__item .product-brief__description {
            visibility: visible;
          }
        }
      }
      .mpp-grid__wrapper {
        margin: 0;
      }
      .grid--mpp__item {
        display: block;
        max-width: 100%;
        width: 100%;
        @media #{$large-up} {
          position: relative;
          width: 20%;
          padding: 1em 0 0;
          min-height: auto;
          float: $ldirection;
          vertical-align: top;
          margin-bottom: 0;
        }
        &:not(.slick-active) {
          .product-brief__description {
            visibility: visible;
          }
        }
        .product_brief__sub-panel,
        .product_brief__sub-panel--non-shaded,
        .product_brief__sub-panel--shaded {
          display: none;
        }
        .product-brief {
          @include swap_direction(padding, 0 20px 0 0);
          width: 100%;
          @media #{$medium-up} {
            padding: 0 12px;
          }
          .product_brief__reviews {
            width: 100%;
          }
          &__shades {
            display: block;
            padding: 8px 0;
          }
          .down_price {
            display: inline-block;
            @media #{$medium-up} {
              padding-#{$rdirection}: 10px;
            }
          }
          &__image-container {
            @include transition(height $default-fade ease-in);
            @include swap_direction(margin, 0 auto);
            display: block;
            position: relative;
            overflow: hidden;
            @media #{$medium-up} {
              width: 100%;
            }
          }
          &__image-content {
            position: relative;
            .qs_link {
              display: none;
              @media #{$medium-up} {
                display: block;
              }
            }
            @media #{$medium-up} {
              &--has-alt-image:hover {
                .product-brief__image {
                  opacity: 0;
                  visibility: hidden;
                }
                .product-brief__image--alt {
                  opacity: 1;
                  visibility: visible;
                }
              }
              &:hover {
                .qs_link {
                  opacity: 1;
                  visibility: visible;
                }
              }
              .qs_link {
                opacity: 0;
                top: 50%;
                #{$ldirection}: 50%;
                transform: translate(-50%, -50%);
                visibility: hidden;
                position: absolute;
                min-width: 140px;
                width: auto;
                white-space: nowrap;
                z-index: 1;
                @media #{$device-ipad-tablet-portrait-landscape} {
                  display: none;
                }
              }
            }
          }
          &__image {
            height: auto;
            width: 100%;
            display: block;
            padding: 0;
            margin: 0;
          }
          &__buttons-container {
            bottom: 20px;
            width: 100%;
            position: absolute;
            height: 75px;
            padding-top: 15px;
            @media #{$medium-up} {
              padding: 15px 20px 0;
            }
          }
          &__misc-flag {
            text-transform: uppercase;
            position: absolute;
            #{$ldirection}: 28px;
            font-size: 12px;
            font-family: $akzidenz;
            font-weight: 700;
            z-index: 2;
            top: 10px;
            @media #{$medium-up} {
              #{$ldirection}: 10px;
            }
          }
          &__description {
            padding-#{$ldirection}: 28px;
            padding-top: 25px;
            position: relative;
            text-align: initial;
            @media #{$medium-up} {
              padding-#{$ldirection}: 0;
              padding-top: 10px;
            }
          }
          .pr-snippet,
          &__reviews {
            width: 100%;
          }
          .p-w-r .pr-snippet {
            padding-bottom: 2px;
            text-align: $ldirection;
            .pr-snippet-stars-png .pr-star-v4 {
              height: 10px;
              width: 11px;
              margin-right: 4px;
            }
            .pr-category-snippet__total {
              display: none;
            }
          }
          &__shades-desc1 {
            margin: 5px auto;
            .product-brief__desc1 {
              display: inline;
            }
          }
          &__desc1 {
            width: 100%;
            font-size: 15px;
            margin: 5px auto;
          }
          &__price {
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
          }
          &__header {
            @include headline--quinary;
            padding: 0;
          }
          &__sub-header {
            @include headline--senary;
            margin-top: 2px;
          }
          &__swatch-list {
            position: relative;
            margin: 10px 0;
            .swatch {
              @include border-radius(20px);
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              #{$ldirection}: 0;
              height: 20px;
              width: 20px;
            }
            .product-brief__shadename {
              font-size: 12px;
              margin-#{$ldirection}: 25px;
              text-transform: uppercase;
            }
          }
        }
      }
      &.button-gray--white-font {
        .grid--mpp__item {
          .cta {
            background-color: $color-periwinkle-blue--aerin;
            color: $color-white;
          }
        }
      }
      &.button-navy--white-font {
        .grid--mpp__item {
          .cta {
            background-color: $color-navy;
            color: $color-white;
          }
        }
      }
      &.button-white--navy-font-border {
        .grid--mpp__item {
          .cta {
            background-color: $color-white;
            color: $color-navy;
            border: 1px solid $color-navy;
          }
        }
      }
      &.button-white--grey-font-border {
        .grid--mpp__item {
          .cta {
            background-color: $color-white;
            color: $color-periwinkle-blue--aerin-v2;
            border: 1px solid $color-periwinkle-blue--aerin;
          }
        }
      }
      &.button-black--gradient-border {
        .grid--mpp__item {
          .cta {
            background-color: $color-black;
            color: $color-white;
            border: 1px solid;
            border-image-slice: 1;
            font-weight: 500;
            border-image-source: linear-gradient(
              to left,
              $color-gold--renutriv,
              $color-gold--renutriv-v2
            );
          }
        }
      }
      .cta {
        &.font--akzidenzregularbq,
        &.font--akzidenz-bold {
          font-family: $akzidenz;
          font-weight: bold;
        }
        &.font--akzidenzregular {
          font-family: $akzidenz;
        }
        &.font--optima-light {
          font-family: $optimalight;
        }
        &.font--optima-regular {
          font-family: $optimaregular;
        }
        &.font--essonnes {
          font-family: $essonnes;
        }
        &.font--futurabook,
        &.font--futura-now-medium {
          font-family: $futura_now_md;
        }
        &.font--helvetica-neue {
          font-family: $roboto-condensed;
        }
        &.font--bb-roman,
        &.font--bodoni-moda {
          font-family: $bb-roman;
        }
      }
      &:not(:has(.slick-list)) {
        display: flex;
        @media #{$medium-up} {
          display: block;
        }
        .grid--mpp__item {
          flex: 0 0 100vw;
          @media #{$medium-up} {
            flex: unset;
          }
        }
        .product-brief {
          float: unset;
        }
      }
    }
  }
  &.font--futurabook,
  &.font--futura-now-medium {
    .tabbed-products-block__header {
      font-family: $futura_now_md;
    }
    .tabbed-products-block {
      &__header {
        font-family: $futura_now_md;
      }
      &__sub-header {
        font-family: $futura_now_md;
      }
      &__tabs {
        font-family: $futura_now_md;
      }
    }
    .grid--mpp__carousel {
      .grid--mpp__items {
        .grid--mpp__item {
          .product-brief__misc-flag {
            font-family: $futura_now_md;
          }
          .product-brief__header {
            font-family: $futura_now_md;
          }
          .product-brief__sub-header {
            font-family: $futura_now_md;
          }
          .product-brief__desc1 {
            font-family: $futura_now_md;
          }
          .product-brief__panel {
            &.down_price {
              .product-brief__price {
                font-family: $futura_now_md;
              }
            }
          }
        }
      }
    }
  }
  &.font--essonnes {
    .tabbed-products-block__header {
      font-family: $essonnes;
    }
    .tabbed-products-block {
      &__header {
        font-family: $essonnes;
      }
      &__sub-header {
        font-family: $essonnes;
      }
      &__tabs {
        font-family: $essonnes;
      }
    }
    .grid--mpp__carousel {
      .grid--mpp__items {
        .grid--mpp__item {
          .product-brief__misc-flag {
            font-family: $essonnes;
          }
          .product-brief__header {
            font-family: $essonnes;
          }
          .product-brief__sub-header {
            font-family: $essonnes;
          }
          .product-brief__desc1 {
            font-family: $essonnes;
          }
          .product-brief__panel {
            &.down_price {
              .product-brief__price {
                font-family: $essonnes;
              }
            }
          }
        }
      }
    }
  }
  &.font--helvetica-neue {
    .tabbed-products-block__header {
      font-family: $roboto-condensed;
    }
    &__header {
      font-family: $roboto-condensed;
    }
    &__sub-header {
      font-family: $roboto-condensed;
    }
    &__tabs {
      font-family: $roboto-condensed;
    }
    .grid--mpp__carousel {
      .grid--mpp__items {
        .grid--mpp__item {
          .product-brief__misc-flag {
            font-family: $roboto-condensed;
          }
          .product-brief__header {
            font-family: $roboto-condensed;
          }
          .product-brief__sub-header {
            font-family: $roboto-condensed;
          }
          .product-brief__desc1 {
            font-family: $roboto-condensed;
          }
          .product-brief__panel {
            &.down_price {
              .product-brief__price {
                font-family: $roboto-condensed;
              }
            }
          }
        }
      }
    }
  }
  &.font--bb-roman,
  &.font--bodoni-moda {
    .tabbed-products-block__header {
      font-family: $bb-roman;
    }
    &__header {
      font-family: $bb-roman;
    }
    &__sub-header {
      font-family: $bb-roman;
    }
    &__tabs {
      font-family: $bb-roman;
    }
    .grid--mpp__carousel {
      .grid--mpp__items {
        .grid--mpp__item {
          .product-brief__misc-flag {
            font-family: $bb-roman;
          }
          .product-brief__header {
            font-family: $bb-roman;
          }
          .product-brief__sub-header {
            font-family: $bb-roman;
          }
          .product-brief__desc1 {
            font-family: $bb-roman;
          }
          .product-brief__panel {
            &.down_price {
              .product-brief__price {
                font-family: $bb-roman;
              }
            }
          }
        }
      }
    }
  }
}
