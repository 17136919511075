.min-bar-header-section {
  @include swap_direction(margin, 0 auto 50px);
  text-align: center;
  width: 600px;
  .min-bar-content {
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    padding-#{$ldirection}: 20px;
    @include swap_direction(margin, 0);
  }
  .min-bar-header {
    font-size: 160px;
    line-height: 160px;
    span {
      font-size: 40px;
      vertical-align: super;
      letter-spacing: 0;
      &.highlight {
        font-size: inherit;
        vertical-align: inherit;
        letter-spacing: inherit;
      }
    }
  }
}
.sample-mini-bar.extended-mpp a.js-add-to-cart {
  display: none !important;
}

.min-bar-head-section {
  width: 600px;
  @include swap_direction(margin, 0 auto);
}

.extended-mpp.sample-mini-bar .mpp__product {
  .product_brief__buttons {
    &.product_brief__buttons--non-shaded {
      width: 100%;
    }
    &.product_brief__buttons--shaded {
      margin-#{$ldirection}: 0;
      width: 100%;
    }
  }
  .product_brief__headers {
    .product_brief__shadename.sized {
      font-family: 'AkzidenzGrotesk W1G';
      font-weight: normal;
      letter-spacing: 0.15em;
    }
    .product_brief__shadename {
      font-weight: normal;
    }
  }
  .rollover_plus {
    font-family: 'optimadisplaylight';
    position: absolute;
    #{$rdirection}: 12px;
    top: 362px;
    cursor: pointer;
    @media #{$product-normal-only} {
      #{$rdirection}: 6px;
      top: 266px;
    }
  }
  .rollover_minus {
    font-family: 'optimadisplaylight';
    position: absolute;
    #{$rdirection}: 12px;
    top: 362px;
    display: none;
    @media #{$product-normal-only} {
      #{$rdirection}: 6px;
      top: 266px;
    }
  }
  .product_brief__image-container .product_brief__image {
    height: auto;
  }
}

.sample-mini-bar.mpp {
  padding-bottom: 0;
}

.mpp-header-sample-mini-bar-elc-nodeblock .hero-tout-mobile__content--a {
  margin-top: 0;
  min-height: 250px;
}
