.section-esearch {
  .review-count {
    display: inline-block;
    margin-left: 10px;
  }
  #product_rating_image {
    float: left;
    padding-top: 4px;
  }
  .rating {
    margin-bottom: 8px;
  }
}
