.homepage-subscribe {
  background: $color-light-gray-blue;
  @include swap_direction(padding, 46px 25px);
  .homepage-subscribe__header {
    @include text--short;
    font-weight: bold;
    text-align: center;
    margin-bottom: 22px;
  }
  .form-text {
    width: 100%;
  }
  .form-submit {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    height: 45px;
    line-height: 45px;
  }
  .sms-signup {
    margin-top: 30px;
    .sms-signup__terms {
      padding-top: 20px;
    }
    .sms-signup__terms-text {
      height: auto;
    }
    .sms-signup__error {
      margin-top: 20px;
    }
    .form-checkbox {
      // checkbox aspect
      &:not(:checked) + label:before,
      &:checked + label:before {
        @include swap_direction(border, 1px solid $color-navy);
        background: $color-white;
      }
      // checked mark aspect
      &:not(:checked) + label:after,
      &:checked + label:after {
        background-size: auto auto;
        background-position: 0 -1025px;
        color: $color-navy;
        @include swap_direction(border, solid 1px $color-navy);
      }
    }
  }
  .email-signup__error {
    margin-top: 20px;
  }
}
