.page-wrapper-checkout {
  .checkout {
    .dw__view-cart {
      &:last-child {
        .color {
          &.column {
            border-bottom: none;
          }
        }
      }
      margin: 10px 0;
      .color {
        &.column {
          @media #{$xlarger-only} {
            width: 68%;
          }
          @media #{$medium-only} {
            width: 73%;
          }
          width: 100%;
          padding-bottom: 15px;
          border-bottom: 1px solid $color-light-gray;
          .swatch {
            border-radius: 20px;
            width: 20px;
            height: 20px;
            display: inline-block;
          }
          .shade {
            display: inline-block;
            position: relative;
            bottom: 5px;
            padding-#{$ldirection}: 8px;
          }
        }
      }
      &__shaded {
        margin: 0 0 16px;
        .cart-item {
          &__desc {
            float: #{$ldirection};
          }
          &__product-subname {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

#index-checkout {
  .viewcart-panel__content {
    .dw__view-cart {
      @media #{$medium-up} {
        padding: 0;
      }
      &__shaded {
        @media #{$medium-up} {
          padding: 0;
        }
        .cart-item {
          &__desc {
            @media #{$medium-up} {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
