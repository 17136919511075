///
/// @file esteelauder_custom_brand/_aerin_sig_quote.scss
///
/// \brief styles for template field module at:
///
///  esteelauder_custom_brand/templates/aerin_sig_quote_v1
///

.aerin-signature-quote {
  background: url('/media/export/cms/aerin/aerin_signature/aerinscript_logo_mobile.png') bottom center no-repeat;
  padding-bottom: 30px;
  min-height: 100px;
  margin-bottom: 20px;
  @media #{$medium-up} {
    background: url('/media/export/cms/aerin/aerin_signature/aerinscript_logo_sm.png') bottom center no-repeat;
    padding-bottom: 40px;
    min-height: 175px;
    margin-bottom: 40px;
  }
}
