///
/// Formatter for pages with full sidebars
/// Example: customer services page
///
$sidebar-link-padding: 15px;

// WRAPPER
.sidebar-page {
  @include max-width;
  position: relative;

  // sidebar
  @media #{$medium-up} {
    // We need a responsive sidebar that fills vertical height if sidebar OR
    // content is taller
    &:before {
      content: '';
      position: absolute;
      width: 25%;
      height: 100%;
      top: 0;
      #{$ldirection}: 0;
      z-index: -1;
    }
  }
  @media #{$large-up} {
    margin-top: -$header-full-height;
  }
}
// SIDEBAR
.sidebar-page__sidebar {
  @include swap_direction(padding, 0 20px);
  background-color: $color-gray-cs-side;
  @media #{$medium-up} {
    background-color: none; // let psuedo element handle responsive bg
    width: 25%;
    float: $ldirection;
  }
  // nav offset
  @media #{$large-up} {
    padding-top: $header-full-height;
  }
  .menu a {
    color: $color-navy;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    @include swap_direction(padding, $sidebar-link-padding $sidebar-link-padding $sidebar-link-padding 0);
    letter-spacing: 1.5px;
  }
  .level-1 {
    font-weight: bold;
  }
  .level-2 {
    margin-#{$ldirection}: 5px;
  }
}
// CONTENT
.sidebar-page__content {
  padding: 0 20px 30px;
  @media #{$medium-up} {
    padding: 0 20px 80px;
    width: 75%;
    float: $ldirection;
    .sidebar-page--no-menu & {
      margin-#{$ldirection}: 0;
      padding-top: 0;
    }
  }
  // nav offset
  @media #{$large-up} {
    padding-top: $header-full-height;
  }
}

.sidebar-page__content-title {
  font-size: 50px;
  margin-bottom: 25px;
  text-align: center;
}
