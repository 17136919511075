#loyalty__page__about {
  .loyalty_join_signin {
    .btn_sign_in {
      display: none;
    }
    .btn_join {
      @include swap_direction(margin, 0 auto);
    }
  }
}

#loyalty__content {
  .is-member {
    .loyalty_tiers a, //update with classname
    .loyalty_join_signin {
      display: none;
    }
  }
}
