///
/// @file esteelauder_custom_brand/_aerin_tout_header.scss
///
/// \brief styles for template field module at:
///
///  esteelauder_custom_brand/templates/aerin_tout_header_v1
///

.aerin-header-tout {
  margin-top: -$header-collapsed-height;
  max-width: 1300px;
  text-align: center;
  margin-bottom: 50px;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
}

.aerin-header-tout__img {
  max-width: 100%;
}
