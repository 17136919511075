#loyalty__page__faq .sidebar-page__content {
  @media #{$small-only} {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

#loyalty__panel__faq {
  @media #{$small-only} {
    @include swap_direction(padding, 0 20px);
  }
  .cs-quick-info,
  .faq_sections,
  .sidebar-page__content-title,
  .sidebar-page__sidebar,
  #node-106 {
    display: none;
  }
  .faq_sections.loyalty_faq {
    display: block;
    h2#loyalty {
      @media #{$small-only} {
        font-size: 27px;
      }
    }
  }
  .sidebar-page:before {
    background-color: $color-white;
  }
  .faq__question::after,
  .faq--active .faq__question::after {
    @media #{$small-only} {
      top: 75%;
    }
  }
  .faq__answer {
    @media #{$small-only} {
      margin-#{$ldirection}: 10px;
    }
  }
  .loyalty__page__index__breadcrumb {
    padding-bottom: 10px;
  }
}
