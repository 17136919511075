.dw__fs__sppmg {
  &__filter {
    @media #{$medium-up} {
      width: 67.5%;
      float: #{$ldirection};
    }
    @media #{$medium-only} {
      width: auto;
      float: none;
    }
    @media #{$large-only} {
      width: 87%;
    }
  }
  &__undertone {
    @media #{$medium-up} {
      width: 32.5%;
      text-align: #{$ldirection};
      float: #{$rdirection};
      margin: 14px 0 0;
    }
    @media #{$medium-only} {
      margin: 6px 0 14px;
      float: none;
    }
    @media #{$large-only} {
      float: none;
      margin: 14px 0;
    }
    margin: 20px 0;
    text-align: center;
    &__link {
      @media #{$medium-up} {
        font-size: 13px;
      }
      font-size: 18px;
      text-decoration: underline;
    }
  }
}

.dw__sppmg__v1 {
  padding-bottom: 0;
  .sppmg__carousel {
    @media #{$medium-up} {
      top: 0;
    }
    top: 11vw;
  }
  .sppmg__content {
    .sppmg__inner {
      .sppmg__filter {
        .dw__shade {
          &-list {
            @include swap_direction(padding, 0 15% 3% 0);
            border-bottom: 1px solid $color-dw-grey;
            margin-top: 4%;
          }
          &-filter {
            display: inline;
            padding-#{$rdirection}: 2%;
          }
        }
        .dw__undertone {
          &-list {
            margin-top: 4%;
            border-bottom: 1px solid $color-dw-grey;
            padding-bottom: 3%;
          }
          &-filter {
            display: inline;
            padding-#{$rdirection}: 2%;
          }
        }
        .dw__filter-by {
          li {
            color: $color-medium-gray;
            &.sppmg__filterLabel,
            &.selected {
              color: $color-navy;
            }
          }
        }
      }
      .sppmg__grid {
        .sppmg__hov {
          .sppmg__hovEyebrow {
            letter-spacing: 1px;
            font-size: 0.8vw;
          }
          .sppmg__copy {
            font-size: 1.45vw;
            p {
              display: inline;
            }
          }
          .sppmg__hovShop {
            font-size: 0.8vw;
          }
        }
        .remove__shade__link {
          font-size: 0.8vw;
          text-transform: uppercase;
          text-decoration: underline;
          color: $color-navy;
          font-weight: 700;
        }
      }
    }
  }
  .sppmg__carouselContainer {
    .sppmg__slideInner {
      .remove__shade__button {
        @media only screen and (max-width: 767px) {
          line-height: 14.063vw;
          height: 14.063vw;
          font-size: 3.75vw;
          padding: 0 4%;
        }
        @media only screen and (max-width: 375px) {
          line-height: 11.063vw;
          height: 11.063vw;
          font-size: 3.75vw;
          padding: 0 3%;
        }
        @media #{$medium-up} {
          @include swap_direction(margin, 0 0 4.5% 6%);
          display: inline-block;
          float: none;
          padding: 0 16px;
        }
        @media #{$xlarge-up} {
          @include swap_direction(margin, 0 0 4.5% 2%);
        }
        &:empty {
          display: none;
        }
      }
    }
  }
}
