.messages {
  @include background($color-periwinkle-blue);
  color: $color-white;
  @include swap_direction(padding, 20px);
  border-width: 1px;
  border-style: solid;
  border-color: darken($color-periwinkle-blue, 10%);
  &.error {
    @include background($color-red);
    border-color: darken($color-red, 10%);
  }
  &.warning {
    @include background($color-gold);
    border-color: darken($color-gold, 10%);
  }
}
