// buttons for both
#loyalty__panel__offers,
#loyalty__panel__offers__sku {
  .button--offers {
    @include button--secondary;
    font-size: 12px;
    font-family: $akzidenz;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-weight: bold;
    vertical-align: middle;
    text-align: center;
  }

  // offers
  // loyalty__panel__offers__button
  // loyalty__panel__offers__remove
  // loyalty__panel__offers__redeemed

  // offers-sku
  // loyalty__panel__product__button
  // loyalty__panel__offers__sku__remove
  // loyalty__panel__offers__sku__redeemed

  // select button - white
  .loyalty__panel__offers__button,
  .loyalty__panel__product__button {
  }
  // remove button - blue
  .loyalty__panel__offers__remove,
  .loyalty__panel__offers__sku__remove {
    color: $color-white;
    background: $color-navy;
    border-color: $color-navy;
  }
  // redeemed button - gray
  .loyalty__panel__offers__redeemed,
  .loyalty__panel__offers__sku__redeemed {
    display: none;
    color: $color-white;
    background: $color-light-gray;
    border-color: $color-light-gray;
  }
  &.is-selected {
    .loyalty__panel__offers__button,
    .loyalty__panel__product__button {
      color: $color-white;
      background: $color-light-gray;
      border-color: $color-light-gray;
    }
  }
  // buttons for automatic offers stay as-is. Revert to original styles
  &.is-selected .automatic {
    .loyalty__panel__offers__button,
    .loyalty__panel__product__button {
      @include button--secondary;
      color: $color-navy;
      background: none;
    }
  }
}

// loyalty offers
#loyalty__panel__offers {
  @extend %loyalty-panel;
  clear: both;
  .loyalty__panel__offers__title {
    @extend %loyalty-panel-title;
    font-family: $optimaregular;
    font-size: 22px;
    letter-spacing: 0;
    @media #{$medium-up} {
      font-family: $optimalight;
      font-size: 30px;
      letter-spacing: 0.01em;
    }
  }
  .loyalty__panel__offers__error {
    .error {
      @include swap_direction(margin, 20px 0);
    }
  }
  .loyalty__panel__offers__select {
    font: $akzidenz;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.05em;
    span {
      display: block;
      @media #{$medium-up} {
        display: inline;
      }
    }
  }
  .loyalty__panel__offers__messages {
    font-size: 12px;
    letter-spacing: 0.05em;
    line-height: 18px;
    @media #{$medium-up} {
      font-size: 15px;
      line-height: normal;
    }
  }
  .loyalty__panel__offers__clear-link {
    text-decoration: underline;
  }

  // offers
  .loyalty__panel__offers__list-container {
    position: relative;
  }
  .loyalty__panel__offers__list {
    @include clearfix;
    &.slick-slider {
      margin-bottom: 0;
      & .slick-dots {
        bottom: 0;
        & li button::before {
          font-size: 11px;
          opacity: 0.3;
        }
        & li.slick-active button::before {
          opacity: 1;
        }
        @media #{$medium-up} {
          display: none;
        }
      }
    }
  }
  .loyalty__panel__offers__offer-wrapper {
    position: relative;
    width: 135px;
    @include swap_direction(padding, 30px 10px 82px 10px);
    @include swap_direction(margin, 0 auto);
    color: $color-navy;
    height: 100%;
    text-align: center;
    @include min-height(456px);
    @media #{$medium-up} {
      @include min-height(450px);
    }
  }
  .loyalty__panel__offers__loader {
    @extend %loyalty__panel__loader;
    background-position: center 1px;
  }
  .loyalty__panel__offers__offer {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    @media #{$medium-up} {
      @include min-width(220px);
      width: 33.3%;
      float: $ldirection;
      &:nth-child(3n + 1) {
        clear: $ldirection;
      }
      @media (max-width: 964px) {
        width: 49%;
        &:nth-child(3n + 1) {
          clear: none;
        }
        &:nth-child(2n + 1) {
          clear: $ldirection;
        }
      }
    }
    &.loyalty__panel__offers__offer--used {
      .loyalty__panel__offers__button {
        display: none;
      }
      .loyalty__panel__offers__redeemed {
        display: inline-block;
        cursor: default;
      }
    }
  }
  .loyalty__panel__offers__offer-image-container {
    margin-bottom: 18px;
  }
  .loyalty__panel__offers__offer-image {
    @include swap_direction(margin, 0 auto);
    display: none;
    @media #{$medium-up} {
      @include swap_direction(margin, 0 auto);
      display: block;
    }
    &_mobile {
      width: 100%;
      height: 198px;
      display: block;
      @media #{$medium-up} {
        display: none;
      }
    }
  }
  .loyalty__panel__offers__offer-header {
    font-family: $optimaregular;
    font-size: 22px;
  }
  .loyalty__panel__offers__offer-description {
    font-family: $optimaregular;
    font-size: 16px;
    line-height: 20px;
  }
  .loyalty__panel__offers__offer-valid {
    margin-top: 20px;
    font-size: 12px;
    letter-spacing: 0.05em;
  }
  .loyalty__panel__offers__offer-code-wrap {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    @include swap_direction(margin, 6px 0);
    font-family: $akzidenz;
    @media #{$medium-up} {
      font-family: $optimaregular;
    }
  }
  .loyalty__panel__offers__offer-code {
    font-weight: bold;
  }
  .loyalty__panel__offers__offer-expires {
    font-size: 12px;
    letter-spacing: 0.05em;
  }
  .loyalty__panel__offers__offer-terms {
    text-decoration: underline;
  }
  .loyalty__panel__offers__cta {
    position: absolute;
    bottom: 0;
    #{$ldirection}: 0;
    width: 100%;
    padding-bottom: 30px;
  }
  .loyalty__panel__offers__button,
  .loyalty__panel__offers__button_remove {
    margin-top: 12px;
  }
  .loyalty__panel__offers__button,
  .loyalty__panel__offers__remove,
  .loyalty__panel__offers__redeemed {
    height: 45px;
    line-height: 43px;
    @media #{$medium-up} {
      height: 33px;
      line-height: 33px;
    }
  }
  .loyalty__panel__offers__link-container {
    position: absolute;
    bottom: 0;
    #{$ldirection}: 0;
    width: 100%;
  }
  .loyalty__panel__offers__link {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: underline;
  }
}

.loyalty__panel__offers__selected,
.loyalty__panel__offers__sku__selected {
  color: $color-white;
  background: $color-navy;
  border-color: $color-navy;
  pointer-events: none;
}

.loyalty__panel__offers__sku__product.loyalty__panel__offers__sku__disable__product {
  opacity: 0.5;
  &.visible-product {
    opacity: 1;
  }
}

.is-selected {
  .loyalty__panel__offers__sku__remove {
    visibility: hidden;
  }
}

// buttons for automatic offers stay as-is. Revert to original styles
.is-selected .automatic {
  .loyalty__panel__offers__sku__remove,
  .loyalty__panel__offers__button.button--offers,
  .loyalty__panel__product__button.button--offers {
    visibility: visible;
  }
}

#loyalty__page__index #loyalty__panel__offers__sku {
  &.is-selected .loyalty__panel__offers__sku__cta .loyalty__panel__offers__sku__remove {
    background: none;
    @include swap_direction(border, none);
    color: $color-navy;
    display: block !important;
    height: 0;
    @include swap_direction(padding, 0);
    text-decoration: underline;
  }
}

#loyalty__page__index #loyalty__panel__offers {
  &.is-selected .loyalty__panel__offers__cta .loyalty__panel__offers__remove {
    background: none;
    @include swap_direction(border, none);
    color: $color-navy;
    display: block !important;
    height: 0;
    @include swap_direction(padding, 0);
    text-decoration: underline;
  }
}

.loyalty__panel__offers__sku__list-border .offer_valid_details {
  text-align: center;
}
