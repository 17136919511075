/* GWP LANDING PAGE STYLES

* INCLUDED IN /promotions/gwp.tmpl, as of this writing

*/
body#gwp {
  #main_content_controls {
    z-index: 1000;
  }
  #breadcrumb {
    padding-bottom: 1px;
  }
  #sidebar_left {
    margin-top: 11px;
  }
  #main_content_controls {
    margin-top: 42px !important;
    width: 960px;
  }
  .bodycopy a:hover {
    text-decoration: none;
    color: #888888;
  }
  #main_content_wrapper {
    padding-#{$ldirection}: 3px;
  }
  .gwp-cms-content-container a:hover {
    color: #0d0a1b !important;
    opacity: 0.6;
  }
  #gwp-buttoncontinuecheck {
    top: -36px;
    #{$ldirection}: -51px;
  }
  .gwp-promo-copy-container {
    height: 130px;
    margin-#{$ldirection}: 0px;
    margin-top: 0px;
    width: 971px;
    z-index: 3;
    position: absolute;
    top: 1137px;
    display: none;
  }
  .gwp-promo-copy-gray {
    color: #999999;
    font-size: 10px;
    #{$ldirection}: 27px;
    letter-spacing: 0.1px;
    line-height: 12px;
    position: absolute;
    text-align: $ldirection;
    top: 40px;
    padding-#{$rdirection}: 20px;
  }
  .lower-gwp-container #choiceError {
    #{$ldirection}: -52px;
    top: -490px;
  }
  .overlay-background {
    background-color: #000000 !important;
    opacity: 0.5;
  }
  #product_id_b #gwp #content_wrapper #main_content {
    margin-top: -311px;
    margin-bottom: 0px;
  }
  .available_not_chosen {
  }
  .available_not_chosen.over {
    cursor: pointer;
  }
  .available_chosen {
  }
  #even1 .available_chosen {
    background-image: url('/images/promotions/gwp/spring_2014/gwp_sp14_btn_age_even_selected.gif');
  }
  #antiwrinkle1 .available_chosen {
    background-image: url('/images/promotions/gwp/spring_2014/gwp_sp14_btn_antiwrinkle_selected.gif');
  }
  #lifting1 .available_chosen {
    background-image: url('/images/promotions/gwp/spring_2014/gwp_sp14_btn_lifting_selected.gif');
  }
  #gwp .bccs_container {
    bottom: 8px;
  }
  .gwp_opaque {
    opacity: 0.3;
  }
  .gwp-cms-content-container {
    margin-top: -82px;
  }
  #layer1378844358930 {
    top: 926px !important;
  }
  .lower-gwp-container {
    margin-#{$ldirection}: 135px;
    position: absolute;
    top: 1020px;
  }
}
