#spp-howtouse-formatter-steps {
  margin-#{$ldirection}: 81px;
  margin-#{$rdirection}: 81px;
  @media #{$medium-only} {
    margin-#{$ldirection}: 8%;
    margin-#{$rdirection}: 8%;
  }
  @media #{$small-only} {
    margin-#{$ldirection}: 2%;
    margin-#{$rdirection}: 2%;
  }
}

.spp-product__how-to-use__steps {
  @include swap_direction(margin, -75px auto 100px);
  max-width: 1024px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  #spp-howtouse-steps-headline {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 6px;
    font-size: 30px;
    letter-spacing: -0.005em;
    font-family: $optimalight;
    font-weight: normal;
    text-transform: inherit;
    text-align: $ldirection;
    margin-bottom: 50px;
  }
  .spp__steps {
    @include swap_direction(margin, 0 auto);
    width: 100%;
    .spp-howtouse-steps {
      margin-bottom: 30px;
      display: inline-block;
      width: 100%;
    }
    .spp_howtouse_content {
      text-align: $ldirection;
      a.video-play.video-launcher {
        @include swap_direction(border, 0);
        border-width: 0;
        width: 100%;
        height: auto;
        outline: none;
      }
      img {
        &.step-image-position-left {
          float: $ldirection;
        }
        &.step-image-position-right {
          float: $rdirection;
        }
      }
      .step-headline {
        font-family: $optimalight;
        font-size: 45px;
        margin-top: 20px;
        width: 54%;
        float: $ldirection;
        margin-#{$ldirection}: 50px;
        &:first-child {
          margin-top: 45px;
        }
        &:last-child {
          margin-top: 10px;
        }
        @media #{$medium-only} {
          width: 50%;
          font-size: 40px;
          margin-#{$ldirection}: 10px;
        }
        @media #{$small-only} {
          width: 100%;
          margin-#{$ldirection}: 0px;
        }
      }
      .step-copy,
      .step-copy p {
        font-family: $akzidenz;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 1.5px;
        width: 54%;
        float: $ldirection;
        margin-#{$ldirection}: 50px;
        @media #{$small-only} {
          width: 100%;
          margin-#{$ldirection}: 0px;
        }
        @media #{$medium-only} {
          width: 50%;
          font-size: 12px;
          margin-#{$ldirection}: 10px;
        }
      }
      &.image-full-width {
        padding-bottom: 20px;
        img {
          max-width: 100%;
          width: 100%;
        }
        .step-copy {
          margin-top: 15px;
        }
      }
    }
  }
}
