.store-locator-block {
  @include swap_direction(margin, 46px 0 0);
  @include swap_direction(padding, 46px 25px);
  text-align: center;
  border-top: solid 1px $color-light-gray;
  &.loading {
    .store-locator-block__link {
      display: none;
    }
  }
  .store-locator-block__header {
    @include text--bold;
    @include swap_direction(margin, 0 0 20px);
  }
  .store-locator-block__errors {
    @include text--short--plain;
    @include swap_direction(margin, 0 0 20px);
    display: none;
  }
  .store-locator-block__map {
    width: 100%;
    height: 180px;
    background: $color-light-gray;
  }
  .store-locator-block__loading-message {
    @include text--bold;
    height: 180px;
    line-height: 180px;
    text-align: center;
    color: $color-white;
  }
  .store-locator-block__results {
    .store-locator-block__results-header {
      @include text--short--plain;
      @include swap_direction(margin, 20px 0 18px);
    }
    .store-locator-block__result-doorname {
      @include headline--quinary--plain;
      @include swap_direction(margin, 0 0 4px);
    }
    .store-locator-block__result-address {
      @include headline--senary--plain;
      @include swap_direction(margin, 0 0 20px);
    }
    .store-locator-block__link a {
      @include link--bold;
    }
  }
}
