// estee edit white header
$tee-header-height-mobile: 54px;

.page-branding {
  #{$estee-edit} & {
    height: $tee-header-height-mobile;
  }

  // after phone
  @media #{$medium-up} {
    #{$estee-edit} & {
      margin-left: $menu-toggle-width + 12px;
      margin-top: -6px;
    }
  }

  // menu hidden at this breakpoint
  @media #{$xlarge-up} {
    #{$estee-edit} & {
      margin-left: 0;
      margin-top: -4px;
    }

    #{$alt} & {
      #{$estee-edit} & {
        margin-top: 0;
      }
    }
  }
}

%mobile-logo--estee-edit {
  @include inline-image-with-size(
    '../themes/estee_base/img/sprites/tee_pc_logo.png',
    $overrides: true
  );
  $logo-width--estee-edit: image-width('../themes/estee_base/img/sprites/tee_pc_logo.png') !global;
}

// override entire mixin
@mixin estee-edit-logo($brand: estee, $alt: false, $center: true) {
  @if $center == true {
    $logo-width: '2px'; //just declaring something so we avoid compile errors; true width set below
    @if $brand == estee-edit {
      $logo-width: $logo-width--estee-edit;
    }
    @extend %mobile-logo-center-base;
    margin-left: (-0.5 * $logo-width);
    @at-root %mobile-logo-center-base {
      position: absolute;
      left: 50%;
      @media #{$medium-up} {
        //undoing center for above mobile
        position: static;
        margin-left: 0;
      }
    }
  }
  @if $brand == estee-edit {
    @if $alt == false {
      @extend %mobile-logo--estee-edit;
    }
    // @if $alt == true {
    //   @extend %mobile-logo--estee-edit;
    // }
  } // end estee-edit
} // /mixin mobile-logo

@mixin estee-mobile-logo() {
  position: absolute;
  top: 3px;
  left: 50%;
  //background-image: url("svg/tee_logo.svg");
  background-image: image-url('../themes/estee_base/img/svg/tee_logo.svg');
  width: image-width('../themes/estee_base/img/sprites/tee_pc_logo.png'); //148
  height: image-height('../themes/estee_base/img/sprites/tee_pc_logo.png'); //48
  background-color: transparent;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: auto;
  margin-left: (-0.53 * image-width('../themes/estee_base/img/sprites/tee_pc_logo.png'));
  margin-top: 0;
}

// estee edit
.page-branding__logo {
  #{$estee-edit} & {
    @include estee-edit-logo($brand: estee-edit, $alt: false);
    @media #{$small-only} {
      // override for estee-edit-logo
      @include estee-mobile-logo();
    }
  }
  #{$alt} & {
    @media #{$small-only} {
      #{$estee-edit} & {
        //@include estee-mobile-logo();
        top: auto;
      }
    }
    @media #{$medium-up} {
      #{$estee-edit} & {
        background-size: auto auto;
        background: url('/media/images/global/sprites-s38a9885fce.png') 0 -2048px no-repeat;
        width: 243px;
        height: 32px;
      }
    }
    @media #{$medium-up-tall} {
      // be careful here
      #{$estee-edit} & {
        background-size: auto auto;
        background: url('/media/images/global/sprites-s38a9885fce.png') 0 -2048px no-repeat;
        width: 243px;
        height: 32px;
      }
    }
  }

  #{$sticky} & {
    #{$estee-edit} & {
      @include estee-edit-logo($brand: estee-edit, $alt: false);
      @media #{$small-only} {
        @include estee-mobile-logo();
      }
    }
  }
}

// components/header/_page_navigation
.level-1 {
  .page-navigation & {
    //#{$renutriv} &,
    @media #{$medium-up} {
      #{$estee-edit} & {
        color: $color-black;
      }
      #{$alt} & {
        #{$estee-edit} & {
          color: $color-periwinkle-blue;
        }
      }
    }
  }
}

#{$estee-edit} #{$alt} .page-navigation #{$active} .level-1 {
  color: $color-white;
}

.page-navigation__menu-icon {
  //#{$renutriv} &,
  #{$estee-edit} & {
    background-size: auto auto;
    background-position: 0 -4499px;
    height: 14px;
    width: 21px;
  }
  #{$alt} & {
    //#{$renutriv} &,
    #{$estee-edit} & {
      background-size: auto auto;
      background-position: 0 -7894px;
      height: 20px;
      width: 21px;
    }
    @media #{$xlarge-up} {
      //#{$renutriv} &,
      #{$estee-edit} & {
        background-size: auto auto;
        background: url('/media/images/global/sprites-s38a9885fce.png') 0 -742px no-repeat;
        width: 28px;
        height: 28px;
      }
    }
  }
}

.page-navigation__menu_text {
  #{$estee-edit} & {
    color: $color-black;
  }
}

// components/header/_page_utilities.scss
%utility-item-text,
.utility-item-text {
  #{$estee-edit} & {
    color: $color-black;
  }
}

// account
.page-utilities__account {
  #{$estee-edit} & {
    a {
      color: $color-black;
    }
  }
}

.page-utilities__cart-count {
  #{$estee-edit} & {
    color: $color-black;
  }
}

.page-utilities__cart-button,
.page-utilities__cart-count,
.page-utilities__search-button {
  #{$estee-edit} & {
    border-left: 1px solid $color-light-gray--estee-edit;
    @media #{$small-only} {
      border-left: none;
    }
  }
}

.page-navigation__menu-toggle {
  #{$estee-edit} & {
    border-right: 1px solid $color-light-gray--estee-edit;
    @media #{$small-only} {
      border-right: none;
      height: $tee-header-height-mobile;
    }
  }
}

.page-utilities__cart-icon {
  //#{$renutriv} &,
  #{$estee-edit} & {
    background-image: url('/media/images/background-images/background-image46.png');
  }

  #{$alt} &,
  #{$dark-nav} & {
    // opened menu shows default estee brand. mobile bg stays white, desktop bg turns dark
    // #{$aerin} &,
    // #{$renutriv} &,
    #{$estee-edit} & {
      background-image: url('/media/images/background-images/background-image46.png');
    }
    @media #{$medium-up} {
      // &,
      // #{$renutriv} &,
      // #{$aerin} &,
      #{$estee-edit} & {
        background-image: url('/media/images/background-images/background-image1.png');
        background-size: 24px 22px;
        width: 24px;
        height: 22px;
      }
    }
  }
}

.no-backgroundsize .brand-estee_edit .page-utilities__cart-icon {
  background-image: url($base-theme-image-path + 'sprites/tee_mobile_cart_icon.png');
  width: 24px;
  height: 21px;
}

.no-backgroundsize .brand-estee_edit .is-alternate .page-utilities__cart-icon,
.no-backgroundsize .brand-estee_edit .is-dark-nav .page-utilities__cart-icon {
  background-image: url($base-theme-image-path + 'sprites/tee_mobile_cart_icon.png');
  width: 24px;
  height: 21px;
}

@media only screen and (min-width: 737px) {
  .no-backgroundsize .brand-estee_edit .is-alternate .page-utilities__cart-icon,
  .no-backgroundsize .brand-estee_edit .is-dark-nav .page-utilities__cart-icon {
    background-image: url($base-theme-image-path + 'sprites/cart_white.png');
    width: 24px;
    height: 22px;
  }
}

.page-utilities__search-icon {
  //#{$renutriv} &,
  #{$estee-edit} & {
    background-image: url('/media/images/background-images/background-image47.png');
    background-position: 0 0;
    width: 26px;
    height: 21px;
  }
}

// drupal_modules/esteelauder_endeca/_mobile_block_search.scss
.el-mobile-search-block {
  #{$alt} & {
    #{$estee-edit} & {
      background: inherit;
    }
  }
}

.el-mobile-search-block__btn-submit {
  #{$estee-edit} & {
    background-image: url('/media/images/background-images/background-image48.png');
    background-size: 21px 22px;
    width: 21px;
    height: 22px;
  }
}

.no-backgroundsize .brand-estee_edit .el-mobile-search-block__btn-submit {
  background-image: url($base-theme-image-path + 'sprites/search_tee.png');
  width: 21px;
  height: 22px;
}

.page-header {
  &:not(#{$alt}) {
    #{$estee-edit} & {
      background: url($base-theme-image-path + 'rgbapng/ffffffff-10.png');
      background: white;
      // @media #{$medium-up} {
      //   @include rgba-background(rgba($color-blue, 0.65));
      // }
    }
  }
} //.page-header
