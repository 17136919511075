@if $loyalty-redesign {
  #loyalty__content {
    color: $color-navy;
    .chart {
      p {
        color: $color-navy;
        font-size: 15px;
      }
    }
    .submit__receipt__section {
      @media #{$medium-up} {
        margin: 60px 15px 0;
        text-indent: 0;
      }
      margin: 0;
      text-indent: 20px;
      &__content {
        @media #{$medium-up} {
          padding: 0;
        }
        padding: 0 20px;
      }
      &__title {
        @media #{$medium-up} {
          border-bottom: 1px solid $color-light-gray;
          border-top: none;
          padding: 0 0 15px;
        }
        font-family: $optimalight;
        font-size: 25px;
        letter-spacing: 0;
        border-top: 1px solid $color-light-gray;
        padding: 15px 0;
      }
      &__earn-points__description,
      &__submit__receipt__description {
        text-indent: 0;
        font-size: 14px;
      }
      &__earn-points__description {
        @media #{$medium-up} {
          width: 51%;
        }
        .authorized__retailers__link {
          text-decoration: underline;
        }
        .store__details {
          @media #{$medium-up} {
            display: inline;
          }
          display: block;
        }
      }
      &__accordian {
        width: 45px;
        height: 45px;
        display: inline-block;
        float: #{$rdirection};
        position: relative;
        margin-#{$rdirection}: 20px;
        bottom: 15px;
        background: url(/media/loyalty/sprites-icons.png);
        background-position: 0 -2957px;
        &.open {
          background-position: 0 -3269px;
        }
        @media #{$medium-up} {
          display: none;
        }
      }
      .submit__receipt__btn {
        @media #{$medium-up} {
          display: inline-block;
          margin-bottom: 0;
          font-size: 10px;
        }
        margin-bottom: 20px;
        color: $color-white;
        background-color: $color-navy;
        display: block;
        zoom: 1;
        padding: 0 20px;
        text-decoration: none;
        text-align: center;
        border-radius: 0;
        height: 33px;
        line-height: 33px;
        border: 1px solid $color-navy;
        font-size: 12px;
        font-family: $akzidenz;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
    .refer__friend__section {
      @media #{$medium-up} {
        margin: 40px 15px 0;
        width: auto;
        border-bottom: none;
      }
      border-bottom: 1px solid $color-light-gray;
      width: 100%;
      margin: 0;
      padding: 0;
      &__content {
        @media #{$medium-up} {
          padding: 0;
        }
        padding: 0 20px;
      }
      &__accordian {
        width: 45px;
        height: 45px;
        display: inline-block;
        float: #{$rdirection};
        position: relative;
        margin-#{$rdirection}: 20px;
        bottom: 15px;
        background: url(/media/loyalty/sprites-icons.png);
        background-position: 0 -2957px;
        &.open {
          background-position: 0 -3269px;
        }
        @media #{$medium-up} {
          display: none;
        }
      }
      &__title {
        @media #{$medium-up} {
          text-indent: 0;
          border-bottom: 1px solid $color-light-gray;
          border-top: none;
          padding: 0 0 15px;
        }
        text-indent: 20px;
        font-family: $optimalight;
        font-size: 25px;
        letter-spacing: 0;
        padding: 15px 0;
        border-top: 1px solid $color-light-gray;
      }
      &__description {
        font-size: 14px;
      }
      .refer__friend__btn {
        @media #{$medium-up} {
          display: inline-block;
          margin-bottom: 0;
          font-size: 10px;
        }
        color: $color-white;
        background-color: $color-navy;
        display: block;
        zoom: 1;
        padding: 0 20px;
        text-decoration: none;
        text-align: center;
        border-radius: 0;
        height: 33px;
        line-height: 33px;
        border: 1px solid $color-navy;
        font-size: 12px;
        font-family: $akzidenz;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 20px;
      }
    }
    #loyalty__panel__notification {
      &.is-user {
        &.is-member {
          background: url('/media/loyalty/account-page-loyal-mobile.jpg');
          height: 47vw;
          background-size: cover;
          @if $loyalty-banner-cr24update {
            background: url('/media/loyalty/cr24/account-page-loyal-mobile-banner.jpg') no-repeat;
            background-size: contain;
          } @else if $loyalty-banner-cr23update {
            background: url('/media/loyalty/account-page-loyal-mobile-banner.jpg') no-repeat;
            background-size: contain;
          }
          @media #{$medium-up} {
            @if $loyalty-banner-cr24update {
              background: url('/media/loyalty/cr24/account-page-loyal-pc-banner.jpg') no-repeat;
              background-size: cover;
            } @else if $loyalty-banner-cr23update {
              background: url('/media/loyalty/account-page-loyal-pc-banner.jpg') no-repeat;
              background-size: cover;
            } @else {
              background: url('/media/loyalty/account-page-loyal-pc.jpg') no-repeat;
              background-size: contain;
            }
            height: 26vw;
          }
          @media #{$xxlarge-up} {
            max-width: 1366px;
            height: 360px;
          }
        }
      }
    }
    .points_to_next_earn_before {
      display: none;
      @media #{$medium-up} {
        display: block;
        clear: both;
        padding-top: 25px;
        width: 100%;
        font-size: 12px;
        text-transform: uppercase;
      }
      a {
        @media #{$medium-up} {
          display: block;
          text-transform: uppercase;
          margin-top: 10px;
          letter-spacing: 1px;
        }
        display: none;
      }
    }
    #loyalty__panel__transactions {
      padding: 0 20px;
      td {
        border: none;
        border-bottom: 1px solid $color-light-gray;
      }
      @media #{$medium-up} {
        margin-bottom: 30px;
      }
      .loyalty__panel__transactions {
        &__current-tier,
        &__available-amount {
          display: none;
        }
        &__list__column {
          &-activity {
            @media #{$medium-up} {
              width: 64%;
            }
            display: table-cell;
            &__points-earned {
              @media #{$medium-up} {
                display: none;
              }
            }
            &--level {
              background-color: $color-white;
            }
          }
          &-date {
            @media #{$medium-up} {
              width: 18%;
            }
          }
        }
        &__title {
          border-bottom: 0;
          border-top: 1px solid $color-light-gray;
          padding-top: 15px;
          margin-bottom: 0;
          font-size: 25px;
          @media #{$medium-up} {
            border-top: 0;
            border-bottom: 1px solid $color-light-gray;
          }
        }
        &__table {
          margin-bottom: 20px;
          @media #{$medium-up} {
            margin-bottom: 0;
          }
          th {
            font-size: 12px;
            text-align: #{$ldirection};
            border-bottom: 1px solid $color-light-gray;
            letter-spacing: 0.15em;
            display: table-cell;
            color: $color-navy;
          }
          .loyalty__panel__transactions {
            &__list__column-total,
            &__list__column-plus {
              display: none;
              @media #{$medium-up} {
                display: table-cell;
              }
            }
            &__list__column-total {
              @media #{$medium-up} {
                text-align: #{$ldirection};
              }
            }
            &__list__column-earned {
              display: none;
              @media #{$medium-up} {
                width: auto;
                display: table-cell;
              }
              &__points-earned {
                @media #{$medium-up} {
                  text-align: #{$ldirection};
                }
              }
            }
            &__transaction--level {
              background-color: $color-white;
            }
          }
        }
        &__accordian {
          width: 45px;
          height: 45px;
          display: inline-block;
          float: #{$rdirection};
          position: relative;
          margin-#{$rdirection}: 50px;
          bottom: 15px;
          background: url(/media/loyalty/sprites-icons.png);
          background-position: 0 -2957px;
          &.open {
            background-position: 0 -3269px;
          }
          @media #{$medium-up} {
            display: none;
          }
        }
      }
    }
    #loyalty__panel__benefits {
      padding: 0 20px;
      .loyalty__panel__benefits {
        &__description {
          @include swap_direction(padding, 0 20px 25px);
          background: $color-white;
          min-height: auto;
          border-bottom: 1px solid $color-navy;
          @media #{$medium-up} {
            border-bottom: 0;
            padding: 0 16px 25px;
          }
          &-list {
            li {
              list-style: square;
              line-height: 1.8;
              padding: 0;
              &.tier__first__item {
                position: relative;
                list-style-type: none;
                #{$rdirection}: 16px;
              }
              .benefits {
                &__details {
                  font-weight: bold;
                }
                &__order {
                  @media #{$medium-up} {
                    display: block;
                  }
                  display: inline;
                }
              }
              .purchase {
                &__shipping {
                  @media #{$medium-up} {
                    display: inline;
                  }
                  display: block;
                }
              }
            }
            .loyalty__panel__benefits__unlock {
              display: none;
            }
          }
          &-list__overlay {
            position: static;
            padding: 0;
            .loyalty__panel__benefits__unlock {
              padding: 0;
            }
          }
        }
        &__container {
          display: block;
          margin-top: 0px;
        }
        &__tier {
          width: 100%;
          opacity: 1;
          text-align: #{$ldirection};
          @media #{$xlarge-up} {
            width: 33.3%;
            padding-#{$rdirection}: 40px;
          }
          &-level {
            text-transform: uppercase;
            padding: 10px 0;
            color: $color-navy;
            font-family: $font-bauerbodoni-roman;
            letter-spacing: 0.02em;
            @media #{$medium-up} {
              @include swap_direction(padding, 0 0 8px);
              border-bottom: 1px solid $color-navy;
              margin-bottom: 18px;
              letter-spacing: -0.01em;
            }
          }
          &-header {
            padding-bottom: 5px;
            &.default_tier,
            &.on_hover {
              background: none;
              padding-bottom: 5px;
            }
          }
          &.next_tier {
            opacity: 1;
          }
          &--3 {
            .loyalty__panel__benefits__description {
              border-bottom: 0;
              padding: 0 18px 25px;
            }
          }
        }
        &__title {
          @media #{$medium-up} {
            border-top: 0;
            border-bottom: 1px solid $color-light-gray;
            font-size: 28px;
          }
          padding-top: 20px;
          border-top: 1px solid $color-light-gray;
          border-bottom: 0;
          font-size: 25px;
        }
        &__accordian {
          width: 45px;
          height: 45px;
          display: inline-block;
          float: #{$rdirection};
          position: relative;
          margin-#{$rdirection}: 50px;
          bottom: 15px;
          background: url('/media/loyalty/sprites-icons.png');
          background-position: 0 -2957px;
          &.open {
            background-position: 0 -3269px;
          }
          @media #{$medium-up} {
            display: none;
          }
        }
      }
    }
    .loyalty-landing-menu {
      display: none;
    }
    #loyalty__page__index {
      background-image: none;
      margin-top: 0;
      padding: 0;
      .account-breadcrumb-wrapper {
        top: 35vw;
      }
      .loyalty__page__index {
        &__breadcrumb {
          padding: 0;
          height: 0;
        }
      }
      #loyalty__panel {
        &__offers__sku {
          &.is-selected {
            .loyalty__panel__offers {
              &__sku__cta {
                .loyalty__panel__offers {
                  &__sku__remove {
                    background: $color-navy;
                    color: $color-white;
                    height: 35px;
                    line-height: 3;
                    width: 120px;
                    text-decoration: none;
                    margin: 0 auto;
                  }
                }
              }
            }
            .loyalty__panel {
              &__product__button {
                color: $color-white;
                background: $color-medium-gray;
                border-color: $color-medium-gray;
                visibility: visible;
                position: relative;
                top: 35px;
              }
            }
          }
        }
      }
    }
    .e-list_not_signed {
      margin-top: 0;
      .title_main {
        visibility: visible;
        font-size: 2.9vw;
        color: $color-navy;
        font-family: $font-bauerbodoni-roman;
        letter-spacing: 0.01em;
        line-height: 3.9vw;
        text-transform: uppercase;
        padding-top: 4vw;
        b {
          font-weight: normal;
        }
        @media #{$medium-up} {
          @if $loyalty-banner-cr23update {
            padding-top: 3vw;
          }
        }
        @media #{$xxlarge-up} {
          font-size: 30px;
          line-height: 1.2;
          @if $loyalty-banner-cr23update {
            font-size: 35px;
            margin-#{$ldirection}: 0;
          }
        }
        [data-theme='dark'] & {
          @if $loyalty-banner-cr23update == false {
            color: $color-white;
          }
        }
      }
      .title_subheader {
        visibility: visible;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.2;
        @if $loyalty-banner-cr23update == false {
          letter-spacing: 0.15em;
          font-size: 13px;
        }
      }
      .sub_title {
        font-size: 12px;
        font-family: $akzidenz;
        text-transform: uppercase;
        line-height: 1.3;
        width: 100%;
        letter-spacing: 0.15em;
        padding-top: 20px;
        @media #{$medium-up} {
          font-weight: bold;
          font-size: 0.88vw;
          line-height: 2vw;
          padding-top: 5px;
          letter-spacing: 0.15em;
          @if $loyalty-banner-cr23update == false {
            padding-top: 8px;
          }          
        }
        @media #{$xxlarge-up} {
          font-size: 12px;
          line-height: 1.2;
          @if $loyalty-banner-cr23update {
            padding-top: 25px;
          }
        }
      }
      .e-list_not_signed__text {
        display: block;
        font-size: 15px;
        width: 100%;
        line-height: 1.2;
        margin-top: 0;
        @media #{$medium-up} {
          @if $loyalty-banner-cr23update {
            margin-top: 5px;
            width: 100%;
          } @else {
            margin: 0 auto;
            font-size: 13px;
            line-height: 1.2;
            width: 78%;
          }
        }
        @media #{$xxlarge-up} {
          @if $loyalty-banner-cr23update {
            line-height: 1.5;
            width: 78%;
          }
        }
      }
      .e-list_not_signed-right__text {
        display: block;
        font-size: 15px;
        line-height: 1.2;
        margin: 0 10px;
        padding-bottom: 15px;
        text-transform: initial;
        @media #{$medium-up} {
          letter-spacing: 0.05em;
          @if $loyalty-banner-cr23update {
            padding-bottom: 0;
            margin: 0;
          } @else {
            margin: 0 10px;
            font-size: 13px;
            line-height: 1.2;
            padding-bottom: 10px;
          }
        }
        @media #{$xxlarge-up} {
          @if $loyalty-banner-cr23update {
            line-height: 1.5;
          }
        }
      }
      .btn_join {
        color: $color-white;
        background: $color-navy;
        border: 0;
        padding: 30px;
        font-size: 12px;
        line-height: 0;
        margin: 10px 0;
        [data-theme='dark'] & {
          @if $loyalty-banner-cr23update == false {
            background: $color-white;
            color: $color-navy;
          }
        }
        @media #{$medium-up} {
          padding: 0 1vw;
          font-size: 0.9vw;
          height: 3vw;
          line-height: 3vw;
          margin: 0.3vw;
          @if $loyalty-banner-cr23update {
            margin-#{$ldirection}: 0;
          }
        }
        @media #{$xxlarge-up} {
          padding: 0 20px;
          font-size: 12px;
          height: 40px;
          line-height: 3;
          margin: 4px;
          @if $loyalty-banner-cr23update {
            margin: 20px 0;
          }
        }
      }
      &-right {
        .footnote {
          display: none;
        }
        &__button-container {
          padding-top: 0.7vw;
          @media #{$xxlarge-up} {
            padding-top: 10px;
          }
          .loading {
            background-color: transparent;
          }
        }
      }
      &__link {
        font-size: 12px;
        @media #{$medium-up} {
          font-size: 0.8vw;
          line-height: 1.5vw;
        }
        @media #{$xxlarge-up} {
          font-size: 12px;
          line-height: 1.6;
        }
      }
      &__link-container {
        @if $loyalty-banner-cr23update {
          margin-top: 0;
        } @else {
          margin-top: 0.7vw;
        }
      }
    }
    .e-list_not_signed_wrapper {
      background-color: transparent;
      text-align: center;
      @media #{$medium-up} {
        @if $loyalty-banner-cr24update {
          background: url('/media/loyalty/cr24/account-page-non-pc-banner.jpg') no-repeat;
          text-align: #{$ldirection};
        } @else if $loyalty-banner-cr23update {
          background: url('/media/loyalty/account-page-non-pc-banner.jpg') no-repeat;
          text-align: #{$ldirection};
        } @else {
          background: url('/media/loyalty/account-page-non-pc.jpg') no-repeat;
        }
        height: 31vw;
        background-size: contain;
        @media #{$xxlarge-up} {
          height: 461px;
          max-width: 1052px;
        }
      }
      @if $loyalty-banner-cr23update {
        @media #{$device-ipad_mini} {
          height: 34vw;
          background-size: cover;
        }
      }
      .e-list_not_signed-left {
        width: 100%;
        padding: 0 20px;
        @media #{$medium-up} {
          @if $loyalty-banner-cr23update {
            margin-#{$ldirection}: 15px;
          } @else {
            @include swap_direction(margin, 0 0 0 56%);
          }
          background: none;
          float: none;
          width: 44%;
          padding: 0;
          position: static;
        }
        @if $loyalty-banner-cr23update {
          @media #{$xxlarge-up} {
            margin-#{$ldirection}: 50px;
          }
          @media #{$device-ipad_only} and (orientation: portrait) {
            width: 55%;
          }
          @media #{$medium-only} {
            width: 60%;
          }
        }
        &__inner {
          @media #{$medium-up} {
            background: none;
            min-height: auto;
            padding-bottom: 10px;
          }
        }
      }
      .e-list_not_signed-right {
        width: 100%;
        @media #{$medium-up} {
          @if $loyalty-banner-cr23update {
            margin-#{$ldirection}: 15px;
          } @else {
            @include swap_direction(margin, 0 0 0 56%);
          }
          background: none;
          float: none;
          width: 44%;
          padding: 0;
          position: static;
        }
        @if $loyalty-banner-cr23update {
          @media #{$xxlarge-up} {
            margin-#{$ldirection}: 50px;
          }
          @media #{$device-ipad_only} and (orientation: portrait) {
            width: 55%;
          }
          @media #{$medium-only} {
            width: 60%;
          }
        }
      }
      .footnote__terms {
        @media #{$medium-up} {
          margin: 0 7%;
        }
        margin: 10px 25px 0;
        font-size: 13px;
      }
    }
    .e-list_signed-left {
      padding: 10px 0;
      .learn__more__btn {
        @media #{$medium-up} {
          font-weight: normal;
        }
        font-weight: bold;
      }
      .disclaimer__points {
        @media #{$medium-up} {
          font-size: 11px;
        }
        font-size: 17px;
        line-height: 1.5;
      }
      .anniversary {
        @media #{$medium-up} {
          font-size: 12px;
        }
        font-size: 17px;
        &__header {
          @media #{$medium-up} {
            display: inline;
            font-size: 11px;
          }
          font-size: 14px;
          display: block;
        }
      }
      .points_next {
        &__header {
          @media #{$medium-up} {
            font-size: 12px;
          }
          font-size: 14px;
        }
      }
      .earn__points {
        &__anniversary {
          font-size: 14px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
      }
    }
    .info-tier-status-3 {
      .new_points {
        &_disclaimer {
          display: none;
        }
      }
    }
  }
  /* NON LOYALTY */
  #loyalty__panel__points {
    .disclaimer {
      display: block;
    }
    &.account-e-list {
      padding: 0;
      clear: both;
      &.is-user {
        &.is-member {
          padding: 0 25px;
          @media #{$medium-up} {
            padding: 0 20px;
          }
          .disclaimer {
            display: none;
          }
          .loyalty__panel__points__title--loyalty {
            width: 100%;
            margin: 0;
            text-indent: 0;
            padding: 15px 0;
          }
        }
      }
    }
  }
  #loyalty__page__tout {
    #loyalty__panel__points {
      padding: 0 25px;
      @media #{$medium-up} {
        padding: 0;
      }
      .loyalty__panel__points__title {
        &--loyalty {
          @media #{$medium-up} {
            padding: 0 0 5px;
            margin-top: 15px;
          }
          border-bottom: 1px solid $color-light-gray;
          padding-bottom: 5px;
          display: block;
          margin: 0;
          text-indent: 0;
          width: 100%;
        }
        &--account {
          display: none;
        }
      }
    }
  }
  #index {
    @media #{$large-down} {
      margin-#{$ldirection}: 0;
      width: 100%;
      &.elc-user-state-loyalty {
        .page-navigation__menu-toggle,
        .page-utilities__search-button,
        .page-utilities.clearfix {
          display: inline-block;
        }
      }
    }
  }
  #loyalty__panel__offers {
    @media #{$medium-up} {
      padding-#{$ldirection}: 20px;
    }
    padding: 10px 0;
    .loyalty__panel__offers {
      &__offer-wrapper {
        @media #{$medium-up} {
          @include swap_direction(padding, 30px 10px 82px 10px);
          text-align: inherit;
          min-height: 420px;
          width: auto;
        }
        @include swap_direction(padding, 30px 17px 82px 17px);
        width: 150px;
        display: flex;
        flex-direction: column;
        margin: inherit;
        min-height: 498px;
      }
      &__select {
        padding-top: 10px;
        .order__select {
          @media #{$medium-up} {
            display: block;
          }
          display: inline;
        }
      }
      &__offer-header {
        font-family: $akzidenz;
        font-size: 15px;
        line-height: 1.6;
        letter-spacing: 0.15em;
        text-align: center;
        padding-top: 15px;
        order: 3;
      }
      &__button-container {
        text-align: center;
      }
      &__offer-image-container {
        width: 100%;
        margin: 0 auto;
        order: 1;
        .loyalty__panel__offers__offer-image_mobile {
          height: auto;
        }
        @media #{$medium-up} {
          width: 144px;
        }
        .checkout__offer-code__wallet-item__image {
          width: 120px;
          height: 198px;
          background: $color-navy;
          &__title {
            color: $color-white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 198px;
            font-size: 20px;
            font-family: $font-bauerbodoni-roman;
            text-transform: uppercase;
            line-height: 1.1;
            letter-spacing: 0.02em;
            padding: 0 5px;
            word-break: break-word;
            @media #{$medium-up} {
              font-size: 24px;
              letter-spacing: 0.01em;
            }
          }
          @media #{$medium-up} {
            width: 144px;
            order: 1;
          }
        }
      }
      &__list {
        &.slick-slider {
          @media #{$medium-up} {
            padding: 0;
          }
          padding: 0 4.5%;
        }
        .slick-list {
          @media #{$medium-up} {
            overflow: visible;
          }
        }
        .slick-prev,
        .slick-next {
          @media #{$medium-up} {
            width: 25px;
          }
          width: 21px;
          opacity: 0.5;
          top: 26%;
        }
        .slick-prev {
          @media #{$medium-up} {
            #{$ldirection}: 20px;
          }
          #{$ldirection}: 0;
        }
        .slick-next {
          @media #{$medium-up} {
            #{$rdirection}: 20px;
          }
          #{$rdirection}: 0;
          background-position: -9px -8600px;
        }
        .slick-dots {
          display: none;
        }
      }
      &__title {
        @media #{$medium-up} {
          margin: 0;
        }
        margin: 0 25px;
        width: 100%;
        text-indent: 0;
      }
      &__button {
        @media #{$medium-up} {
          width: auto;
        }
        width: 85%;
        color: $color-white;
        background-color: $color-navy;
      }
      &__offer {
        &-code-wrap {
          font-family: $akzidenz;
        }
        &-description {
          font-size: 15px;
          line-height: 1.6;
          letter-spacing: 0.05em;
          font-family: $akzidenz;
          text-align: center;
          padding-top: 15px;
          order: 2;
        }
        &-code {
          font-family: $akzidenz;
          font-weight: bold;
        }
      }
      &__messages {
        @media #{$medium-up} {
          padding: 0;
        }
        padding: 0 25px;
      }
    }
  }
  .checkout__index-content {
    .social-login__divider {
      position: relative;
    }
  }
  .not_loyalty_member {
    #loyalty__content {
      .account-e-list {
        background: none;
        .e-list_not_signed {
          &_mobile_banner {
            @if $loyalty-banner-cr24update {
              background: url('/media/loyalty/cr24/account-page-non-mobile-banner.jpg') no-repeat;
              height: 65vw;
              background-size: contain;
            } @else if $loyalty-banner-cr23update {
              background: url('/media/loyalty/account-page-non-mobile-banner.jpg') no-repeat;
              height: 65vw;
              background-size: contain;
            } @else {
              background: url('/media/loyalty/account-page-non-mobile.jpg');
              height: 43vw;
              background-size: cover;
            }
            @media #{$medium-up} {
              display: none;
            }
          }
          &-right {
            @media #{$medium-up} {
              margin-top: 0;
            }
            margin-top: 25px;
            &__button-container {
              padding: 0 24px;
              @if $loyalty-banner-cr23update {
                @media #{$medium-up} {
                  padding: 2vw 0 1vw;
                }
                @media #{$xxlarge-up} {
                  padding: 0;
                }
              }
            }
            .footnote {
              display: none;
            }
          }
        }
      }
      #loyalty__panel__points {
        padding: 0;
      }
    }
  }
  .account-body {
    &.sidebar-page__content {
      .account-page__header {
        @media #{$medium-up} {
          border-bottom: none;
          padding-bottom: 5px;
        }
        margin-bottom: 0;
      }
    }
  }
  .account-page__loyalty {
    #loyalty__panel__points {
      .loyalty__panel__points__cta {
        @media #{$medium-up} {
          padding: 0;
        }
        display: block;
        padding: 0 25px;
        margin: 25px 0;
      }
    }
  }
  .account-wishlist {
    .section-head {
      @media #{$medium-up} {
        padding-bottom: 15px;
      }
    }
  }
  #loyalty__panel {
    &__offers__sku {
      padding-#{$ldirection}: 20px;
    }
  }
  .account-utilities {
    .account-utilities {
      &__welcome-msg {
        letter-spacing: 0.05em;
      }
      &__header {
        font-size: 11px;
      }
      &__section {
        .sidebar-menu__item {
          .account-utilities__link {
            font-size: 11px;
          }
        }
        .account-profile {
          &__connected-accounts {
            font-size: 11px;
          }
        }
      }
    }
  }
  #loyalty__panel__offers__sku {
    .button--offers {
      background: $color-navy;
      color: $color-white;
      &.loyalty__panel__offers {
        &__sku__selected {
          display: none !important;
        }
      }
      &.button--disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }
    .loyalty__panel__offers__sku {
      &__title {
        @media #{$medium-up} {
          font-size: 30px;
        }
        font-size: 25px;
      }
      &__sub-title {
        @media #{$medium-up} {
          letter-spacing: 0.05em;
        }
        letter-spacing: 0.15em;
      }
    }
    .offer_valid_details {
      @media #{$medium-up} {
        font-size: 15px;
      }
      font-size: 12px;
      &:last-child {
        @media #{$medium-up} {
          padding-bottom: 0;
        }
        padding-bottom: 10px;
      }
    }
  }
  .elc-user-state-loyalty {
    .loyalty__content-banner {
      background: url('/media/loyalty/account-page-loyal-mobile.jpg');
      height: 47vw;
      background-size: cover;
      @if $loyalty-banner-cr24update {
        background: url('/media/loyalty/cr24/account-page-loyal-mobile-banner.jpg') no-repeat;
        background-size: contain;
      } @else if $loyalty-banner-cr23update {
        background: url('/media/loyalty/account-page-loyal-mobile-banner.jpg') no-repeat;
        background-size: contain;
      }
      @media #{$medium-up} {
        background: url('/media/loyalty/account-page-loyal-pc.jpg') no-repeat;
        background-size: contain;
        height: 26vw;
        @if $loyalty-banner-cr24update {
          background: url('/media/loyalty/cr24/account-page-loyal-pc-banner.jpg') no-repeat;
        } @else if $loyalty-banner-cr23update {
          background: url('/media/loyalty/account-page-loyal-pc-banner.jpg') no-repeat;
        }
      }
      @media #{$xxlarge-up} {
        max-width: 1366px;
        height: 360px;
      }
    }
  }
  .loyalty-account-page {
    padding: 0;
    .account__mobile__landing__chat {
      background-color: $color-gray-cs-side;
      border-top: 1px solid $color-light-gray;
      padding-bottom: 14px;
      padding-top: 29px;
      text-align: center;
      .need__help {
        &__header {
          text-align: center;
          font-family: $optimalight;
          font-size: 30px;
          padding-bottom: 8px;
        }
        &__copy {
          text-decoration: underline;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }
}
