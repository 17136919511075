///
/// @file components/_back_to_top.scss
///
/// \brief Back to top widget
///
/// Styleguide pg.168
///
///

.back-to-top {
  background-size: auto auto;
  background-position: 0 -316px;
  height: 59px;
  width: 70px;
  @include text--short--plain; // 20131007_EL_HP.psd, layer comp: CYBER WHITE

  // CSS3 fade in/out transition
  @include single-transition(opacity, $back-to-top-fadein, ease-in-out);
  position: fixed;
  bottom: 65px;
  #{$rdirection}: 20px;
  text-align: center;
  opacity: 0;
  #{$renutriv} & {
    background-size: auto auto;
    background-position: 0 -232px;
  }
  // hide on front page?
  #{$landing} & {
    display: none;
  }
  #{$ie8} & {
    display: none;
  }
  .vto-active & {
    @media #{$small-only} {
      display: none;
    }
  }
}

.back-to-top-show {
  cursor: pointer;
  opacity: 1;
  z-index: 100;
  #{$ie8} & {
    display: block;
  }
}
